import './style.scss';
import React, { Fragment } from 'react';
import { Button, Form, Input } from 'antd';
import { store } from '../../redux/store';
import authActions from '../../redux/auth/actions';
import {getAppDetail, openArticleInMessenger} from '../../helpers/utility';

const { TextArea } = Input;

class SubcriptionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOk = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        store.dispatch({
          type: authActions.ENABLE_EMAIL_SENDING_DOMAIN,
          payload: values,
        });
      }
    });
  };
  render() {
    const { form, emailSendingLoader } = this.props;
    const { getFieldDecorator } = form;

    const title = (
      <Fragment>
        2. How did you build your contact lists and does it meet <a href="#/" data-id="243" onClick={e => openArticleInMessenger(e)}>minimum standards</a>?
      </Fragment>
    );
    return (
      <Form>
        <Form.Item
          label={
            `1. Briefly tell us about your business and what types of messages do you intend to send with ${getAppDetail().name}.`
          }
        >
          {getFieldDecorator('business_info', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: `Tell us how you plan to use ${getAppDetail().name}`,
              },
              {
                max: 700,
                message:
                  'Must be less than 700 characters',
              },
            ],
          })(<TextArea rows={4} />)}
        </Form.Item>
        <Form.Item label={title}>
          {getFieldDecorator('contacts_info', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Tell us how you built your list',
              },
              {
                max: 700,
                message:
                  'Must be less than 700 characters',
              },
            ],
          })(<TextArea rows={4} />)}
        </Form.Item>
        <Form.Item label={'3. What web domain will you be sending email from?'}>
          {getFieldDecorator('domain', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Enter your sending domain',
              },
              {
                max: 40,
                message:
                  'Must be less than 40 characters',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item
          label={
            '4. Which marketing or sales tools have you used in the past to grow your business?'
          }
        >
          {getFieldDecorator('used_marketing_tools', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Tell us which marketing or sales tools you\'ve used',
              },
              {
                max: 700,
                message:
                  'Must be less than 700 characters',
              },
            ],
          })(<TextArea rows={4} />)}
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={emailSendingLoader}
          disabled={emailSendingLoader}
          onClick={this.handleOk}
        >
          Send
        </Button>
      </Form>
    );
  }
}
export default Form.create()(SubcriptionForm);
