import React from 'react';
import { timeDifference } from '../../../../helpers/utility';
import { Tooltip } from 'antd';

function SnippetContentHeader(props) {
  const { snippetData, isFromSettings } = props;
  return (
    <div className="meta-info">
      {isFromSettings && (
        <>
          Created by{' '}
          <span className="profile-avatar sm">
            <img
              src={snippetData.user && snippetData.user.avatar_url}
              alt="Avatar"
            />
          </span>
          {` ${snippetData.user && snippetData.user.display_name} · `}
          <Tooltip placement="top" title={'Created at'} key={'email'}>
            {timeDifference(snippetData.created_at, true)}
          </Tooltip>
          {' · '}
        </>
      )}
      Updated by{' '}
      <span className="profile-avatar sm">
        <img
          src={
            snippetData.last_updated_user &&
            snippetData.last_updated_user.avatar_url
          }
          alt="Avatar"
        />
      </span>
      {` ${snippetData.last_updated_user &&
        snippetData.last_updated_user.display_name} · `}
      <Tooltip placement="top" title={'Updated at'} key={'email'}>
        {timeDifference(snippetData.updated_at, true)}
      </Tooltip>
      {` · Used  ${snippetData.usage_count || 0} time${
        (snippetData.usage_count || 0) > 1 ? 's' : ''
      }`}
    </div>
  );
}

export default SnippetContentHeader;
