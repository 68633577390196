const actions = {
  FETCH_TOUR_TABLE_LISTS: 'FETCH_TOUR_TABLE_LISTS',
  FETCH_TOUR_TABLE_LISTS_SUCCESS: 'FETCH_TOUR_TABLE_LISTS_SUCCESS',
  FETCH_TOUR_TABLE_LISTS_FAILURE: 'FETCH_TOUR_TABLE_LISTS_FAILURE',
  APPEND_TABLE_LISTS_IN_TOUR: 'APPEND_TABLE_LISTS_IN_TOUR',
  APPEND_TABLE_LISTS_IN_TOUR_SUCCESS: 'APPEND_TABLE_LISTS_IN_TOUR_SUCCESS',
  APPEND_TABLE_LISTS_IN_TOUR_FAILURE: 'APPEND_TABLE_LISTS_IN_TOUR_FAILURE',
  CREATE_TOUR_DRAWER: 'CREATE_TOUR_DRAWER',
  CREATE_TOUR: 'CREATE_TOUR',
  CREATE_TOUR_SUCCESS: 'CREATE_TOUR_SUCCESS',
  CREATE_TOUR_FAILURE: 'CREATE_TOUR_FAILURE',
  DUPLICATE_TOUR: 'DUPLICATE_TOUR',
  DUPLICATE_TOUR_SUCCESS: 'DUPLICATE_TOUR_SUCCESS',
  DUPLICATE_TOUR_FAILURE: 'DUPLICATE_TOUR_FAILURE',
  DELETE_TOUR_TABLE_DATA: 'DELETE_TOUR_TABLE_DATA',
  FETCH_TOUR_DETAILS: 'FETCH_TOUR_DETAILS',
  FETCH_TOUR_DETAILS_SUCCESS: 'FETCH_TOUR_DETAILS_SUCCESS',
  FETCH_TOUR_DETAILS_FAILURE: 'FETCH_TOUR_DETAILS_FAILURE',
  UPDATE_TABLE_ELEMENT_STATUS_IN_TOUR: 'UPDATE_TABLE_ELEMENT_STATUS_IN_TOUR',
  UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_TOUR:
    'UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_TOUR',
  SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES:
    'SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES',
  SET_CREATED_MESSAGE_TAG: 'SET_CREATED_MESSAGE_TAG',
  APPLY_TOUR_TAG: 'APPLY_TOUR_TAG',
  REMOVE_TOUR_TAG: 'REMOVE_TOUR_TAG',
  TOUR_SAVE_BUTTON_LOADER_STATUS: 'TOUR_SAVE_BUTTON_LOADER_STATUS',
  SET_CONFIRM_MODEL_IN_TOUR: 'SET_CONFIRM_MODEL_IN_TOUR',
  SET_TOUR_ONGOING_SORT_VALUES: 'SET_TOUR_ONGOING_SORT_VALUES',
  SET_TOUR_ONGOING_SEARCH_VALUE: 'SET_TOUR_ONGOING_SEARCH_VALUE',
  SET_TOUR_ONGOING_STATUS: 'SET_TOUR_ONGOING_STATUS',
  SET_TOUR_ONGOING_SELECTED_ROWS: 'SET_TOUR_ONGOING_SELECTED_ROWS',
  SET_TOUR_ONGOING_TAB_KEY: 'SET_TOUR_ONGOING_TAB_KEY',
  SET_TOUR_ONGOING_TAG_ID: 'SET_TOUR_ONGOING_TAG_ID',
  REMOVE_DELETED_ONGOING_TOUR_ID: 'REMOVE_DELETED_ONGOING_TOUR_ID',
  SET_TOUR_ONGOING_SCHEDULE_TIME: 'SET_TOUR_ONGOING_SCHEDULE_TIME',
  TOUR_ONGOING_SCHEDULE_DETAILS: 'TOUR_ONGOING_SCHEDULE_DETAILS',
  TOUR_ONGOING_SCHEDULE_DETAILS_SUCCESS:
    'TOUR_ONGOING_SCHEDULE_DETAILS_SUCCESS',
  REORDER_TOURS_LIST: 'REORDER_TOURS_LIST',
  SET_TOUR_LOADER: 'SET_TOUR_LOADER',
  UPDATE_TOUR_SCHEDULE_DETAILS: 'UPDATE_TOUR_SCHEDULE_DETAILS',
  CHANGE_TOUR_POSITION: 'CHANGE_TOUR_POSITION',
  SET_TOUR_TABLE_DATA: 'SET_TOUR_TABLE_DATA',
  UPDATE_TOUR_DEADLINE_DETAILS: 'UPDATE_TOUR_DEADLINE_DETAILS',
  SET_TOUR_NAME: 'SET_TOUR_NAME',
  UPDATE_TOUR_STATUS: 'UPDATE_TOUR_STATUS',
  UPDATE_TOUR_STATUS_SUCCESS: 'UPDATE_TOUR_STATUS_SUCCESS',
  UPDATE_TOUR_STATUS_FAILURE: 'UPDATE_TOUR_STATUS_FAILURE',
  UPDATE_SELECTED_TOUR_DATA: 'UPDATE_SELECTED_TOUR_DATA',
  SET_SETTINGS_PANEL_VISIBILITY: 'SET_SETTINGS_PANEL_VISIBILITY',
  SET_SETTINGS_PANEL_DATA: 'SET_SETTINGS_PANEL_DATA',
  SET_TOUR_EDITOR_TAB_KEY: 'SET_TOUR_EDITOR_TAB_KEY',
  UPDATE_TOUR_SAVED_DETAILS: 'UPDATE_TOUR_SAVED_DETAILS',
  UPDATE_TOUR_PORT_HIGHLIGHT_DATA: 'UPDATE_TOUR_PORT_HIGHLIGHT_DATA',
  UPDATE_TOUR_LINK_MODE_DATA: 'UPDATE_TOUR_LINK_MODE_DATA',
  TOUR_EXTENSION_ENABLED_STATUS: 'TOUR_EXTENSION_ENABLED_STATUS',
  TOUR_VIDEO_FILE_ATTACHMENT: 'TOUR_VIDEO_FILE_ATTACHMENT',
  TOUR_VIDEO_FILE_ATTACHMENT_SUCCESS: 'TOUR_VIDEO_FILE_ATTACHMENT_SUCCESS',
  TOUR_VIDEO_FILE_ATTACHMENT_FAILURE: 'TOUR_VIDEO_FILE_ATTACHMENT_FAILURE',
  ADD_TOUR_LAUNCH_URL: 'ADD_TOUR_LAUNCH_URL',
  ADD_TOUR_LAUNCH_URL_SUCCESS: 'ADD_TOUR_LAUNCH_URL_SUCCESS',
};

export default actions;
