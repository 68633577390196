const actions = {
  FETCH_RULES_LIST: 'FETCH_RULES_LIST',
  FETCH_RULES_LIST_SUCCESS: 'FETCH_RULES_LIST_SUCCESS',
  APPEND_RULES_LIST: 'APPEND_RULES_LIST',
  APPEND_RULES_LIST_SUCCESS: 'APPEND_RULES_LIST_SUCCESS',
  ADD_RULE_TABLE_CONTENT: 'ADD_RULE_TABLE_CONTENT',
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHANGE_STATUS_SUCCESS: 'CHANGE_STATUS_SUCCESS',
  DELETE_TABLE_CONTENT: 'DELETE_TABLE_CONTENT',
  DELETE_TABLE_CONTENT_SUCCESS: 'DELETE_TABLE_CONTENT_SUCCESS',
  FETCH_TRIGGER_AND_ACTION: 'FETCH_TRIGGER_AND_ACTION',
  FETCH_TRIGGER_AND_ACTION_SUCCESS: 'FETCH_TRIGGER_AND_ACTION_SUCCESS',
  FETCH_CAMPAIGNS_LIST: 'FETCH_CAMPAIGNS_LIST',
  FETCH_CAMPAIGNS_LIST_SUCCESS: 'FETCH_CAMPAIGNS_LIST_SUCCESS',
  FETCH_FORMS_LIST: 'FETCH_FORMS_LIST',
  FETCH_FORMS_LIST_SUCCESS: 'FETCH_FORMS_LIST_SUCCESS',
  FETCH_EMAILS_LIST: 'FETCH_EMAILS_LIST',
  FETCH_EMAILS_LIST_SUCCESS: 'FETCH_EMAILS_LIST_SUCCESS',
  FETCH_EMAIL_LINKS: 'FETCH_EMAIL_LINKS',
  FETCH_EMAIL_LINKS_SUCCESS: 'FETCH_EMAIL_LINKS_SUCCESS',
  FETCH_RULES_TAGS_LIST: 'FETCH_RULES_TAGS_LIST',
  FETCH_RULES_TAGS_LIST_SUCCESS: 'FETCH_RULES_TAGS_LIST_SUCCESS',
  FETCH_CONTACT_LIST: 'FETCH_CONTACT_LIST',
  FETCH_CONTACT_LIST_SUCCESS: 'FETCH_CONTACT_LIST_SUCCESS',
  FETCH_RULES_WORKFLOW_LIST: 'FETCH_RULES_WORKFLOW_LIST',
  FETCH_RULES_WORKFLOW_LIST_SUCCESS: 'FETCH_RULES_WORKFLOW_LIST_SUCCESS',
  SET_TRIGGER_NAME: 'SET_TRIGGER_NAME',
  ADD_TRIGGER_SECTION: 'ADD_TRIGGER_SECTION',
  ADD_ACTION_SECTION: 'ADD_ACTION_SECTION',
  UPDATE_TRIGGER_SECTION: 'UPDATE_TRIGGER_SECTION',
  UPDATE_PERFORM_MULTIPLE: 'UPDATE_PERFORM_MULTIPLE',
  UPDATE_MODAL_SECTION: 'UPDATE_MODAL_SECTION',
  UPDATE_MODAL_INDEX: 'UPDATE_MODAL_INDEX',
  UPDATE_ACTION_MODAL: 'UPDATE_ACTION_MODAL',
  DELETE_TRIGGER_SECTION: 'DELETE_TRIGGER_SECTION',
  DELETE_ACTION_SECTION: 'DELETE_ACTION_SECTION',
  UPDATE_ACTION_DATA: 'UPDATE_ACTION_DATA',
  ADD_ACTION_PROPERTY: 'ADD_ACTION_PROPERTY',
  CREATE_EMAIL_COMPOSER: 'CREATE_EMAIL_COMPOSER',
  DELETE_ACTION_PROPERTY: 'DELETE_ACTION_PROPERTY',
  RULES_SET_EMAIL_SUBJECT: 'RULES_SET_EMAIL_SUBJECT',
  RULES_SET_FROM_EMAIL_USER_ID: 'RULES_SET_FROM_EMAIL_USER_ID',
  RULES_SET_TO_EMAIL_USER_ID: 'RULES_SET_TO_EMAIL_USER_ID',
  RULES_SET_EMAIL_TEXT: 'RULES_SET_EMAIL_TEXT',
  RULES_FILTER_CHANGE: 'RULES_FILTER_CHANGE',
  SET_RULES_SORT_KEY_AND_VALUE: 'SET_RULES_SORT_KEY_AND_VALUE',
  SET_RULES_SEARCH_VALUE: 'SET_RULES_SEARCH_VALUE',
  FETCH_RULES_EDITOR: 'FETCH_RULES_EDITOR',
  FETCH_RULES_EDITOR_SUCCESS: 'FETCH_RULES_EDITOR_SUCCESS',
  PATCH_RULES_EDITOR: 'PATCH_RULES_EDITOR',
  PATCH_RULES_EDITOR_SUCCESS: 'PATCH_RULES_EDITOR_SUCCESS',
  FETCH_FROM_USER: 'FETCH_FROM_USER',
  FETCH_FROM_USER_SUCCESS: 'FETCH_FROM_USER_SUCCESS',
  FETCH_CUSTOM_LIST: 'FETCH_CUSTOM_LIST',
  FETCH_CUSTOM_LIST_SUCCESS: 'FETCH_CUSTOM_LIST_SUCCESS',
  RULES_FETCH_RECIPIENTS_SUCCESS: 'RULES_FETCH_RECIPIENTS_SUCCESS',
  FETCH_RULES_FILTER_RECIPIENTS_COUNT: 'FETCH_RULES_FILTER_RECIPIENTS_COUNT',
  CLEAR_ACTION_MODAL: 'CLEAR_ACTION_MODAL',
  UPDATE_PROPERTY_DATA_RULES: 'UPDATE_PROPERTY_DATA_RULES',
  CAMPAIGN_EMAIL_LIST: 'CAMPAIGN_EMAIL_LIST',
  FETCH_LINK: 'FETCH_LINK',
  FETCH_LINK_SUCCESS: 'FETCH_LINK_SUCCESS',
  VALIDATION_CHECK: 'VALIDATION_CHECK',
  LOADER_STATUS: 'LOADER_STATUS',
  SEND_EMAIL_VALIDATION: 'SEND_EMAIL_VALIDATION',
  RESET_ACTION_DATA: 'RESET_ACTION_DATA',
  RULE_FILTER_VALIDATION: 'RULE_FILTER_VALIDATION',
  FETCH_TO_USERS: 'FETCH_TO_USERS',
  FETCH_TO_USERS_SUCCESS: 'FETCH_TO_USERS_SUCCESS',
  FETCH_ACTIONS: 'FETCH_ACTIONS',
  FETCH_ACTIONS_SUCCESS: 'FETCH_ACTIONS_SUCCESS',
  SHOW_EMPTY_PAGE_LOADER: 'SHOW_EMPTY_PAGE_LOADER',
  CLEAR_DELETED_ARRAY: 'CLEAR_DELETED_ARRAY',
  CLEAR_TRIGGER_MODAL: 'CLEAR_TRIGGER_MODAL',
  APPEND_RULES_TAG_LIST: 'APPEND_RULES_TAG_LIST',
  RULES_GET_PRODUCTS_LIST: 'RULES_GET_PRODUCTS_LIST',
  RULES_GET_PRODUCTS_LIST_SUCCESS: 'RULES_GET_PRODUCTS_LIST_SUCCESS',
  RULES_GET_PRODUCT_CATEGORIES_LIST: 'RULES_GET_PRODUCT_CATEGORIES_LIST',
  RULES_GET_PRODUCT_CATEGORIES_LIST_SUCCESS: 'RULES_GET_PRODUCT_CATEGORIES_LIST_SUCCESS',
  PATCH_RULES_EDITOR_FAILURE: 'PATCH_RULES_EDITOR_FAILURE',
  UPDATE_ACTION_PERFORM_MULTIPLE: 'UPDATE_ACTION_PERFORM_MULTIPLE',
  RULES_UPDATE_PRODUCTS_LIST_SUCCESS: 'RULES_UPDATE_PRODUCTS_LIST_SUCCESS',
  RULES_UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS:
    'RULES_UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS',
  RULES_PRODUCTS_CATEGORIES_SEARCH: 'RULES_PRODUCTS_CATEGORIES_SEARCH',
  RULES_PRODUCTS_LIST_SEARCH: 'RULES_PRODUCTS_LIST_SEARCH',
};

export default actions;
