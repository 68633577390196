import { message } from 'antd';
import { all, takeEvery, put, call } from 'redux-saga/effects';

import actions from './actions';
import chatActions from '../chat/actions';
import settings from '../../settings/index';
import {
  fetchProjectSecretKey,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../helpers/utility';

export function* sendMessage(params) {
  try {
    const { payload, inboxViewMode, isFromElasticSearch } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations.json`;
    const response = yield call(() => postPromise(url, payload.data));
    yield put({ type: actions.PROACTIVE_SEND_MESSAGE_SUCCESS });
    if (response.data && response.data.message) {
      let { conversation } = response.data.message;
      yield put({
        type: chatActions.SET_SELECTED_CHAT_ROOM,
        chatRoom: conversation,
      });
      if (
        !isFromElasticSearch &&
        inboxViewMode &&
        inboxViewMode.toLowerCase() !== 'table'
      ) {
        yield put({
          type: chatActions.FETCH_CONVERSATIONS,
          chatRoomActivation: true,
          emptyChatRoom: true,
          conversationIdentifier: conversation.conversation_identifier,
          filter: 'IB4',
        });
      }
      yield put({
        type: chatActions.FETCH_MESSAGES,
        cid: conversation.conversation_identifier,
        unread_count: conversation.user_unread_message_count,
      });
      if (inboxViewMode === '' || isFromElasticSearch) {
        yield put({
          type: chatActions.SHOW_ADVANCED_SEARCH,
          payload: true,
        });
        yield put({
          type: chatActions.CONVERSATION_DRAWER_STATUS,
          payload: false,
        });
        updateBrowserHistory('conversations/search');
      } else if (inboxViewMode && inboxViewMode.toLowerCase() === 'table') {
        yield put({
          type: chatActions.SET_INBOX_MODE,
          payload: 'Table',
        });
        yield put({
          type: chatActions.CONVERSATION_DRAWER_STATUS,
          payload: false,
        });
        updateBrowserHistory('conversations/table');
      } else {
        updateBrowserHistory(
          `conversations/${conversation.conversation_identifier}`,
        );
      }
      yield put({
        type: chatActions.SHOW_TO_USER,
        payload: false,
      });
      message.success('Your message has been sent successfully');
    } else {
      message.success(response.message);
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Message send failed');
    yield put({ type: actions.PROACTIVE_SEND_MESSAGE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.PROACTIVE_SEND_MESSAGE, sendMessage)]);
}
