const actions = {
  GET_TEMPLATES: 'GET_TEMPLATES',
  GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_FAILURE: 'GET_TEMPLATES_FAILURE',
  SET_TEMPLATE_DETAILS: 'SET_TEMPLATE_DETAILS',
  UPDATE_FORM_TEMPLATE: 'UPDATE_FORM_TEMPLATE',
  UPDATE_FORM_TEMPLATE_SUCCESS: 'UPDATE_FORM_TEMPLATE_SUCCESS',
  UPDATE_FORM_TEMPLATE_FAILURE: 'UPDATE_FORM_TEMPLATE_FAILURE',
  CREATE_FORM_TEMPLATE: 'CREATE_FORM_TEMPLATE',
  CREATE_FORM_TEMPLATE_SUCCESS: 'CREATE_FORM_TEMPLATE_SUCCESS',
  CREATE_FORM_TEMPLATE_FAILURE: 'CREATE_FORM_TEMPLATE_FAILURE',
  GET_FORM_TYPES: 'GET_FORM_TYPES',
  GET_FORM_TYPES_SUCCESS: 'GET_FORM_TYPES_SUCCESS',
  GET_FORM_TYPES_FAILURE: 'GET_FORM_TYPES_FAILURE',
  SET_FORM_TYPE: 'SET_FORM_TYPE',
  FORM_TEMPLATE_DRAWER_VISIBLE: 'FORM_TEMPLATE_DRAWER_VISIBLE',
  EMPTY_TEMPLATE_DETAILS: 'EMPTY_TEMPLATE_DETAILS',
  DELETE_FORM_TEMPLATE: 'DELETE_FORM_TEMPLATE',
  DELETE_FORM_TEMPLATE_SUCCESS: 'DELETE_FORM_TEMPLATE_SUCCESS',
  DELETE_FORM_TEMPLATE_FAILURE: 'DELETE_FORM_TEMPLATE_FAILURE',
  UPLOAD_TEMPLATE_IMAGE: 'UPLOAD_TEMPLATE_IMAGE',
  UPLOAD_TEMPLATE_IMAGE_SUCCESS: 'UPLOAD_TEMPLATE_IMAGE_SUCCESS',
  UPLOAD_TEMPLATE_IMAGE_FAILURE: 'UPLOAD_TEMPLATE_IMAGE_FAILURE',
};

export default actions;
