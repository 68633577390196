import React from 'react';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

const intilValue = {
  later_today: 'later that day',
  tomorrow: 'next day',
  next_monday: 'next Monday',
};

export class Delay extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className={'header'}>
          <h3>
            <span>
              <i className={'fas fa-clock'} />
            </span>
            Delay
          </h3>
        </div>
        <div className={'body'}>
          <div className={'message-text-component'} data-port-id={1}>
            <jtk-source port-id={1} endpoint={true} />

            {data.delay_type === 'specific_time' ? (
              <span>
                Wait for{' '}
                <b>
                  {data.interval_value} {data.interval_unit}
                </b>{' '}
                {data.stop_on_user_response ? <>and end if user respond</> : ''}
              </span>
            ) : (
              <span>
                Wait until <b>{intilValue[data.wait_until_value]}</b>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
