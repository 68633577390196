import React from 'react';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class LinkBot extends BaseEditableComponent {
  render() {
    let { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons />
        <jtk-target />

        <div className={'header'}>
          <h3>
            <span>
              <i className={'fas fa-link'} />
            </span>
            Pass to another bot
          </h3>
        </div>
        <div className={'body'}>
          Pass to bot{' '}
          <b>{data.bot_data ? data.bot_data.bot_name : 'another bot'}</b>
        </div>
      </div>
    );
  }
}
