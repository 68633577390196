import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import formActions from '../forms/actions';
import settings from '../../settings';
import {
  fetchProjectSecretKey,
  getPromise, isElasticSearch,
  unAuthorisedRedirection, unProcessableEntity,
} from '../../helpers/utility';
import { message } from 'antd';

export function* fetchSegments() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/segments.json?project_secret_key=${fetchProjectSecretKey()}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_SEGMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Segments fetch failed');
    }
  }
}

export function* fetchProperties(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_PROPERTIES_SUCCESS,
      payload: data,
    });
    if (!!params.isFromFormsEditor) {
      yield put({
        type: formActions.GET_OPT_IN_FIELDS_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Properties fetch failed');
    }
  }
}

export function* fetchPropertiesMetaData() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/property-meta-data`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_PROPERTY_META_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Properties meta data fetch failed');
    }
  }
}

export function* fetchEventsMetaData() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/event-meta-data`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_EVENTS_META_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Events meta data fetch failed');
    }
  }
}

export function* fetchEvents() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people-events`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Events fetch failed');
    }
  }
}

export function* fetchFiltersDropDown() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/dropdown-list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FILTERS_DROP_DOWN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch filters failed');
    }
  }
}

export function* fetchRecipientsCount(params) {
  const stringifyFilters = JSON.stringify(
    params.payload && params.payload.new_filters,
  );
  let filterParams = '';
  params.filterParams &&
    params.filterParams.forEach(data => {
      filterParams += `&${data.paramsKey}=${data.id}`;
    });
  yield put({
    type: actions.FILTER_SET_RECIPIENT_LOADER,
    payload: true,
  });
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/get_filtered_people_count?filter_data=${encodeURIComponent(
      stringifyFilters,
    )}&with_data=${
      params.isGetContacts
    }${filterParams}&is_es=${isElasticSearch()}`;
    if (window.location.pathname.includes('contacts')) {
      url += '&segment_filter=true';
    }
    if (params.isFromBroadcastEmail) {
      url += '&include_email_filters=true';
    }
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_RECIPIENTS_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.FILTER_SET_RECIPIENT_LOADER,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch recipients count failed');
    yield put({
      type: actions.FILTER_SET_RECIPIENT_LOADER,
      payload: false,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FILTER_FETCH_SEGMENT, fetchSegments),
    takeEvery(actions.FILTER_FETCH_PROPERTIES, fetchProperties),
    takeEvery(
      actions.FILTER_FETCH_PROPERTIES_META_DATA,
      fetchPropertiesMetaData,
    ),
    takeEvery(actions.FILTER_FETCH_EVENTS_META_DATA, fetchEventsMetaData),
    takeEvery(actions.FILTER_FETCH_EVENTS, fetchEvents),
    takeEvery(actions.FILTER_FETCH_FILTERS_DROP_DOWN, fetchFiltersDropDown),
    takeEvery(actions.FILTER_FETCH_RECIPIENTS_COUNT, fetchRecipientsCount),
  ]);
}
