import { canWeAccess, onPlanChange } from '../../helpers/utility';
import React, { useState } from 'react';
import AddonModal from './AddonModal';
import { useSelector } from 'react-redux';
import { userRestriction } from './constants';
import { Tooltip } from 'antd';

function RestrictedFeatureNotification(props) {
  const { currentContent } = props,
    [isAddonModalOpen, setAddonModalOpen] = useState(false),
    owner = useSelector(({ Auth }) => Auth.owner),
    canUserAccessAddon = owner || canWeAccess(userRestriction.billingSettings);

  return (
    <>
      <div className="notification-layout update-banner">
        <p>
          Create and save as many <b>{currentContent.value}</b> as you want.{' '}
          {currentContent.message}.
          {currentContent.restriction === 'support_bot' ? (
            canUserAccessAddon ? (
              <span className="action" onClick={() => setAddonModalOpen(true)}>
                Upgrade
              </span>
            ) : (
                <Tooltip
                  title={
                    <>
                      <b>You do not have the permission to upgrade.</b>
                      Try accessing from workspace owner's account.
                    </>
                  }
                >
                  <span className="action cursor-not-allowed">Upgrade</span>
                </Tooltip>
            )
          ) : (
            <span className="action" onClick={onPlanChange}>
              Upgrade
            </span>
          )}
        </p>
      </div>
      {isAddonModalOpen && (
        <AddonModal
          addonContent={currentContent}
          isModalOpen={isAddonModalOpen}
          setModalOpen={setAddonModalOpen}
        />
      )}
    </>
  );
}

export default RestrictedFeatureNotification;
