import React from 'react';
import { Popover, Tooltip } from 'antd';
import Gif from './Gif';

const GifUpload = props => (
  <Tooltip title="Gif" placement={'top'}>
    <Popover
      content={
        <Gif
          gifResource={props.gifResource}
          postReply={props.postReply}
          postNote={props.postNote}
          composerState={props.composerState}
          hideGif={props.hideGif}
          isEmptyGif={props.emptyGif}
          gifOffset={props.gifOffset}
          visible={props.visible}
        />
      }
      trigger="click"
      visible={props.visible}
      onVisibleChange={props.onVisibleChange}
    >
      GIF
    </Popover>
  </Tooltip>
);

export default GifUpload;
