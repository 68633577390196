import React, { useState } from 'react';
import AddonModal from './AddonModal';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { canWeAccess } from '../../helpers/utility';
import { userRestriction } from './constants';

function SubscriptionAddonNotification(props) {
  const { addonContent } = props,
    [isModalOpen, setModalOpen] = useState(false),
    owner = useSelector(({ Auth }) => Auth.owner),
    canUserAccessAddon = owner || canWeAccess(userRestriction.billingSettings);

  return (
    <>
      <div className="notification-layout update-banner">
        <p>
          {addonContent.message}
          {canUserAccessAddon ? (
            <span className="action" onClick={() => setModalOpen(true)}>
              Add on
            </span>
          ) : (
            <Tooltip
              title={
                <>
                  <b>You do not have the permission to upgrade.</b>
                  Try accessing from workspace owner's account.
                </>
              }
            >
              <span className="action cursor-not-allowed">Add on</span>
            </Tooltip>
          )}
        </p>
      </div>
      {isModalOpen && (
        <AddonModal
          addonContent={addonContent}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
}

export default SubscriptionAddonNotification;
