import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from '../tours/actions';
import { getTableValues } from '../../components/Messages/Tours/Shared/Helper';
import settings from '../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  trackEvent,
  unAuthorisedRedirection,
  unProcessableEntity,
  updateBrowserHistory,
} from '../../helpers/utility';
import { message } from 'antd';
import displayRuleActions from '../displayRules/actions';
import filterActions from "../filters/actions";
import {filterVariables} from "../constants";

let defaultOnGoingDisplayConditions = [
  {
    criteria: [
      {
        key: 'current_url_path',
        unit: null,
        condition: 'is_any_page',
        conditions: [
          {
            label: 'is any page',
            value: 'is_any_page',
          },
          {
            label: 'is the homepage',
            value: 'is_homepage',
          },
          {
            label: 'is not the homepage',
            value: 'is_not_homepage',
          },
          {
            label: 'is exactly',
            value: 'equals',
          },
          {
            label: 'is not exactly',
            value: 'not_equals',
          },
          {
            label: 'contains',
            value: 'contains',
          },
          {
            label: 'does not contain',
            value: 'not_contains',
          },
          {
            label: 'starts with',
            value: 'starts_with',
          },
          {
            label: 'does not starts with',
            value: 'does_not_starts_with',
          },
          {
            label: 'ends with',
            value: 'ends_with',
          },
          {
            label: 'does not ends with',
            value: 'does_not_ends_with',
          },
          {
            label: 'matches the pattern',
            value: 'matches_the_pattern',
          },
        ],
        selectedType: 'url',
      },
    ],
    criteria_operator: 'AND',
  },
  {
    criteria: [
      {
        key: 'time_on_page',
        unit: 'secs',
        value: 5,
        condition: 'greater_than',
        conditions: [
          {
            label: 'is greater than',
            value: 'greater_than',
          },
          {
            label: 'is less than',
            value: 'less_than',
          },
          {
            label: 'is equal to',
            value: 'equals',
          },
          {
            label: 'is less than or equal to',
            value: 'less_or_equal',
          },
          {
            label: 'is greater than or equal to',
            value: 'greater_or_equal',
          },
        ],
        selectedType: 'number',
      },
    ],
  },
];

export function* fetchTourTableLists(params) {
  const tourType = getTableValues().tourType;
  try {
    let searchValue = '';
    let sortKey = '';
    let sortOrder = '';
    let status = '';
    let tagID = '';
    let lastTourId = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = getTableValues().searchValue;
    }
    if (params && params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = getTableValues().sortKey;
      sortOrder = getTableValues().sortOrder;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = getTableValues().status;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = getTableValues().tagIds;
    }
    if (params.lastTourId) {
      lastTourId = params.lastTourId;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours?status=${status}&tour_type=${tourType}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&last_tour_id=${lastTourId}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TOUR_TABLE_LISTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch tour list failed');
    yield put({
      type: actions.FETCH_TOUR_TABLE_LISTS_FAILURE,
      payload: false,
    });
  }
}

export function* appendTourTableLists(params) {
  const tourType = getTableValues().tourType;
  try {
    let searchValue = '';
    let sortKey = '';
    let sortOrder = '';
    let status = '';
    let tagID = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = getTableValues().searchValue;
    }
    if (params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = getTableValues().sortKey;
      sortOrder = getTableValues().sortOrder;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = getTableValues().tagIds;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = getTableValues().status;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours?status=${status}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&tour_type=${tourType}&last_tour_id=${
      params.payload.getLastId
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_TABLE_LISTS_IN_TOUR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch tour list failed');
    yield put({
      type: actions.APPEND_TABLE_LISTS_IN_TOUR_FAILURE,
      payload: false,
    });
  }
}

export function* createTour(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/tours`;
    const data = yield call(() => postPromise(url, params.data));
    trackEvent('Created tour');
    yield put({ type: actions.CREATE_TOUR_SUCCESS });
    updateBrowserHistory(`tours/${data.data.tour.id}`, {
      tour_id: data.data.tour.id,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Create tour failed');
    yield put({ type: actions.CREATE_TOUR_FAILURE });
  }
}

export function* duplicateTour(params) {
  try {
    yield put({ type: actions.SET_TOUR_LOADER, payload: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.duplicateId}/duplicate`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({ type: actions.DUPLICATE_TOUR_SUCCESS });
    updateBrowserHistory(`tours/${data.data.tour.id}`);
    const { tour, recent_urls } = data.data;
    yield put({
      type: actions.UPDATE_SELECTED_TOUR_DATA,
      payload: { ...tour.content, id: tour.id, name: tour.name },
      isFromInitialTourData: true,
    });
    yield put({
      type: actions.FETCH_TOUR_DETAILS_SUCCESS,
      payload: tour,
      tourTabKey: '1',
      recentUrls: recent_urls,
    });
    yield put({
      type: displayRuleActions.SET_DISPLAY_RULES,
      payload: tour.display_conditions,
    });
    yield put({ type: actions.SET_TOUR_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Duplicate tour failed');
    yield put({ type: actions.DUPLICATE_TOUR_FAILURE });
    yield put({ type: actions.SET_TOUR_LOADER, payload: false });
  }
}

export function* deleteTourTableData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.id}`;
    let data = yield call(() => deletePromise(url));
    if (params.isRedirect) {
      updateBrowserHistory('messages/tours');
    } else {
      yield put({
        type: actions.FETCH_TOUR_TABLE_LISTS,
      });
    }
    yield put({
      type: actions.REMOVE_DELETED_ONGOING_TOUR_ID,
      deletedTourId: params.id,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Delete tour failed');
  }
}

export function* fetchTourDetails(params) {
  try {
    yield put({ type: actions.SET_TOUR_LOADER, payload: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.id}`;
    const data = yield call(() => getPromise(url));
    const { tour, recent_urls } = data.data;

    yield put({
      type: actions.UPDATE_SELECTED_TOUR_DATA,
      payload: { ...tour.content, id: tour.id, name: tour.name },
      isFromInitialTourData: true,
    });
    yield put({
      type: actions.FETCH_TOUR_DETAILS_SUCCESS,
      payload: { ...tour, content: tour.content },
      tourTabKey: '1',
      recentUrls: recent_urls,
    });
    if (tour.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: tour.message_goal.goal_filter,
        filterName: filterVariables.tourKeyGoal,
      });
    }
    yield put({
      type: displayRuleActions.SET_DISPLAY_RULES,
      payload: tour.display_conditions || defaultOnGoingDisplayConditions,
    });
    yield put({ type: actions.SET_TOUR_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch tour details failed');
    yield put({ type: actions.SET_TOUR_LOADER, payload: false });
  }
}

export function* applyTourTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/apply_tag`;
    const responseData = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.FETCH_TOUR_TABLE_LISTS,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Tour tags apply failed');
  }
}

export function* removeTourTags(param) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/remove_tag?feature_type=Tour&feature_id=${
      param.payload.id
    }&tag_id=${param.payload.tagId}`;
    const responseData = yield call(() => postPromise(url));
    yield put({
      type: actions.FETCH_TOUR_TABLE_LISTS,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Remove tour tags failed');
  }
}

export function* changeTourPosition(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.id}/update_priority`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SET_TOUR_TABLE_DATA,
      payload: params.data,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Tour update priority failed');
  }
}

export function* updateTourStatus(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_TOUR_STATUS_SUCCESS,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Tour update failed');
    yield put({
      type: actions.UPDATE_TOUR_STATUS_FAILURE,
      payload: params.payload.tour.status,
    });
  }
}

export function* updateTourDetails(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.id}`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    const { tour } = data.data;
    if (params.table) {
      yield put({
        type: actions.FETCH_TOUR_TABLE_LISTS,
      });
    }
    yield put({
      type: actions.UPDATE_TOUR_SAVED_DETAILS,
      payload: tour,
    });
    yield put({
      type: actions.TOUR_SAVE_BUTTON_LOADER_STATUS,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Tour update failed');
    yield put({
      type: actions.TOUR_SAVE_BUTTON_LOADER_STATUS,
      payload: false,
    });
    yield put({
      type: actions.UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_TOUR,
    });
  }
}

export function* videoFileAttachment(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tours/${params.id}/upload_file`;
    let data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.TOUR_VIDEO_FILE_ATTACHMENT_SUCCESS,
      payload: data.data.image_url,
      stepId: params.stepId,
      isForSnapShot: params.isForSnapShot,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Upload video file failed');
    yield put({
      type: actions.TOUR_VIDEO_FILE_ATTACHMENT_FAILURE,
    });
  }
}

export function* addTourLaunchUrl(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/add_launch_url`;
    yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.ADD_TOUR_LAUNCH_URL_SUCCESS,
      payload: params.payload.launch_url,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Add launch URL failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TOUR_TABLE_LISTS, fetchTourTableLists),
    takeEvery(actions.APPEND_TABLE_LISTS_IN_TOUR, appendTourTableLists),
    takeEvery(actions.CREATE_TOUR, createTour),
    takeEvery(actions.DUPLICATE_TOUR, duplicateTour),
    takeEvery(actions.DELETE_TOUR_TABLE_DATA, deleteTourTableData),
    takeEvery(actions.FETCH_TOUR_DETAILS, fetchTourDetails),
    takeEvery(actions.APPLY_TOUR_TAG, applyTourTags),
    takeEvery(actions.REMOVE_TOUR_TAG, removeTourTags),
    takeEvery(actions.CHANGE_TOUR_POSITION, changeTourPosition),
    takeEvery(actions.UPDATE_TOUR_STATUS, updateTourStatus),
    takeEvery(actions.UPDATE_TABLE_ELEMENT_STATUS_IN_TOUR, updateTourDetails),
    takeEvery(actions.TOUR_VIDEO_FILE_ATTACHMENT, videoFileAttachment),
    takeEvery(actions.ADD_TOUR_LAUNCH_URL, addTourLaunchUrl),
  ]);
}
