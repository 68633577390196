import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import {
  canWeAccess,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
} from '../../helpers/utility';
import { message } from 'antd';
import settings from '../../settings';
import { history } from '../store';
import appActions from '../../redux/apps/actions';
import chatActions from '../../redux/chat/actions';
import { userRestriction } from '../../components/TopNavBar/constants';

export function* createApp(params) {
  try {
    const url = `${settings.ROOT_URL}developer_apps`;
    const data = yield call(() => postPromise(url, params.payload));

    yield put({
      type: actions.CREATE_APP_SUCCESS,
      payload: data.data.developer_app,
    });

    message.success(data.message);
    history.push({
      pathname: `/developer/app/${data.data.developer_app.id}`,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('App creation failed');
    }
  }
}

export function* updateApp(params) {
  try {
    const url = `${settings.ROOT_URL}developer_apps/${params.app_id}`;
    const data = yield call(() => patchPromise(url, params.payload));

    yield put({
      type: actions.UPDATE_APP_DATA_SUCCESS,
      payload: data.data.developer_app,
      isRequiredModal: params.isRequiredModal,
      currentStepFormState: params.currentStepFormState
        ? params.currentStepFormState
        : 1,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_APP_DATA_FAILURE,
    });
    if (error.response && error.response.status === 422)
      message.error(error.response.data.error.message);
  }
}

export function* fetchAppsList() {
  try {
    const url = `${settings.ROOT_URL}developer_apps`;
    const data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_DEVELOPER_APPS_LIST_SUCCESS,
      payload: data.data.developer_apps,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch apps list failed');
    }
  }
}

export function* fetchAppData(params) {
  try {
    const url = `${settings.ROOT_URL}developer_apps/${params.payload.app_id}`;
    const data = yield call(() => getPromise(url, params.payload));

    yield put({
      type: actions.FETCH_DEVELOPER_APP_DATA_SUCCESS,
      payload: data.data.developer_app,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch app failed');
    }
  }
}

export function* fetchOAuthScopesAndCategories() {
  try {
    const url = `${settings.ROOT_URL}developer_apps/scopes_and_categories`;
    const data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_O_AUTH_SCOPES_AND_CATEGORIES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch scopes and categories failed');
    }
  }
}

export function* installApp(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${
      params.app_id
    }/install`;
    const data = yield call(() => postPromise(url));
    if (data.action === 'redirect') {
      window.location.replace(data.url);
    } else message.success(data.data);

    yield put({
      type: actions.FETCH_PUBLIC_APPS,
      changeLoaderState: false,
    });
    yield put({
      type: appActions.CHANGE_DEVELOPER_APP_MODAL_VISIBILITY,
      payload: data.action === 'redirect',
    });
    yield put({
      type: actions.APP_INSTALLED_STATE_LOADING,
      payload: data.action === 'redirect',
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('App installation failed');
    }
  }
}

export function* uninstallApp(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${
      params.app_id
    }/uninstall`;
    const data = yield call(() => postPromise(url));

    message.success(data.message);
    yield put({
      type: actions.FETCH_PUBLIC_APPS,
      changeLoaderState: false,
    });
    yield put({
      type: appActions.CHANGE_DEVELOPER_APP_MODAL_VISIBILITY,
      payload: false,
    });
    yield put({
      type: actions.APP_INSTALLED_STATE_LOADING,
      payload: data.action === 'redirect',
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('App installation failed');
    }
  }
}

export function* fetchInstalledDeveloperApps(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/installed_apps?visible_location=${params.visibleLocation ||
      'inbox'}`;
    const { data } = yield call(() => getPromise(url));
    if (params.visibleLocation === 'messenger') {
      yield put({
        type: actions.FETCH_INSTALLED_DEVELOPER_APPS_SUCCESS,
        payload: data.developer_apps,
      });
    } else {
      let allWidget = data.developer_apps;
      if (!params.isForRolesAndPermissions) {
        if (canWeAccess(userRestriction.inboxAppAccess).no_access) {
          allWidget = [];
        } else if (canWeAccess(userRestriction.inboxAppAccess).custom) {
          allWidget = allWidget.filter(widget =>
            canWeAccess(userRestriction.customApps).includes(widget.id.toString()),
          );
        }
      }
      yield put({
        type: chatActions.FETCH_INSTALLED_APPS_SUCCESS,
        payload: allWidget,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch apps list failed');
    }
  }
}

export function* fetchPublicApps() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/public_apps`;
    const data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_PUBLIC_APPS_SUCCESS,
      payload: data.developer_apps,
    });
    yield put({
      type: appActions.SET_PAGE_LOADER,
      pageLoader: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch apps list failed');
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_APP, createApp)]);
  yield all([takeEvery(actions.UPDATE_APP_DATA, updateApp)]);
  yield all([takeEvery(actions.FETCH_DEVELOPER_APPS_LIST, fetchAppsList)]);
  yield all([takeEvery(actions.FETCH_DEVELOPER_APP_DATA, fetchAppData)]);
  yield all([takeEvery(actions.INSTALL_APP, installApp)]);
  yield all([takeEvery(actions.UNINSTALL_APP, uninstallApp)]);
  yield all([
    takeEvery(
      actions.FETCH_INSTALLED_DEVELOPER_APPS,
      fetchInstalledDeveloperApps,
    ),
  ]);
  yield all([
    takeEvery(
      actions.FETCH_O_AUTH_SCOPES_AND_CATEGORIES,
      fetchOAuthScopesAndCategories,
    ),
  ]);
  yield all([takeEvery(actions.FETCH_PUBLIC_APPS, fetchPublicApps)]);
}
