const actions = {
  FETCH_INBOX_RULES_LIST: 'FETCH_INBOX_RULES_LIST',
  FETCH_INBOX_RULES_LIST_SUCCESS: 'FETCH_INBOX_RULES_LIST_SUCCESS',
  UPDATE_RULES_LIST_ORDER: 'UPDATE_RULES_LIST_ORDER',
  UPDATE_RULES_LIST_ORDER_SUCCESS: 'UPDATE_RULES_LIST_ORDER_SUCCESS',
  ADD_TRIGGER_ITEM: 'ADD_TRIGGER_ITEM',
  UPDATE_TRIGGER_DATA: 'UPDATE_TRIGGER_DATA',
  DELETE_TRIGGER_ITEM: 'DELETE_TRIGGER_ITEM',
  ADD_ACTION_ITEM: 'ADD_ACTION_ITEM',
  UPDATE_RULE_ACTION_DATA: 'UPDATE_RULE_ACTION_DATA',
  DELETE_ACTION_ITEM: 'DELETE_ACTION_ITEM',
  FETCH_SINGLE_RULE_DATA: 'FETCH_SINGLE_RULE_DATA',
  FETCH_SINGLE_RULE_DATA_SUCCESS: 'FETCH_SINGLE_RULE_DATA_SUCCESS',
  ADD_CONDITION_ITEM: 'ADD_CONDITION_ITEM',
  ADD_CONDITION_BLOCK: 'ADD_CONDITION_BLOCK',
  SET_RULE_CONDITION_INITIAL_DATA: 'SET_RULE_CONDITION_INITIAL_DATA',
  UPDATE_RULE_CONDITION_DATA: 'UPDATE_RULE_CONDITION_DATA',
  DELETE_CONDITION_ITEM: 'DELETE_CONDITION_ITEM',
  FETCH_ACTION_CHAT_TAGS: 'FETCH_ACTION_CHAT_TAGS',
  FETCH_ACTION_CHAT_TAGS_SUCCESS: 'FETCH_ACTION_CHAT_TAGS_SUCCESS',
  FETCH_ACTION_ASSIGNEE_LIST: 'FETCH_ACTION_ASSIGNEE_LIST',
  FETCH_ACTION_ASSIGNEE_LIST_SUCCESS: 'FETCH_ACTION_ASSIGNEE_LIST_SUCCESS',
  FETCH_CONTACT_TAGS_LIST: 'FETCH_CONTACT_TAGS_LIST',
  FETCH_CONTACT_TAGS_LIST_SUCCESS: 'FETCH_CONTACT_TAGS_LIST_SUCCESS',
  FETCH_CONDITION_CONTACT_SEGMENTS_LIST:
    'FETCH_CONDITION_CONTACT_SEGMENTS_LIST',
  FETCH_CONDITION_CONTACT_SEGMENTS_LIST_SUCCESS:
    'FETCH_CONDITION_CONTACT_SEGMENTS_LIST_SUCCESS',
  FETCH_RULE_CONDITION_PROPERTIES: 'FETCH_RULE_CONDITION_PROPERTIES',
  FETCH_RULE_CONDITION_PROPERTIES_SUCCESS:
    'FETCH_RULE_CONDITION_PROPERTIES_SUCCESS',
  FETCH_RULE_CONDITION_PROPERTIES_META_DATA:
    'FETCH_RULE_CONDITION_PROPERTIES_META_DATA',
  FETCH_RULE_CONDITION_PROPERTIES_META_DATA_SUCCESS:
    'FETCH_RULE_CONDITION_PROPERTIES_META_DATA_SUCCESS',
  EMPTY_RULE_DATA: 'EMPTY_RULE_DATA',
  UPDATE_RULE_NAME: 'UPDATE_RULE_NAME',
  CREATE_INBOX_RULE: 'CREATE_INBOX_RULE',
  CREATE_INBOX_RULE_SUCCESS: 'CREATE_INBOX_RULE_SUCCESS',
  DELETE_INBOX_RULE: 'DELETE_INBOX_RULE',
  DELETE_INBOX_RULE_SUCCESS: 'DELETE_INBOX_RULE_SUCCESS',
  UPDATE_DEFAULT_ASSIGNEE: 'UPDATE_DEFAULT_ASSIGNEE',
  UPDATE_DEFAULT_ASSIGNEE_SUCCESS: 'UPDATE_DEFAULT_ASSIGNEE_SUCCESS',
  UPDATE_RULE_LIST_FILTER: 'UPDATE_RULE_LIST_FILTER',
  UPDATE_RULE_LIST_STATUS: 'UPDATE_RULE_LIST_STATUS',
  UPDATE_RULE_STATUS: 'UPDATE_RULE_STATUS',
  UPDATE_RULE_STATUS_SUCCESS: 'UPDATE_RULE_STATUS_SUCCESS',
  UPDATE_RULE_STOP_PROCESSING_OPTION: 'UPDATE_RULE_STOP_PROCESSING_OPTION',
  CHANGE_RULE_LOADER_STATUS: 'CHANGE_RULE_LOADER_STATUS',
  CHANGE_RULES_LIST_LOADER_STATUS: 'CHANGE_RULES_LIST_LOADER_STATUS',
  CHANGE_SWITCH_LOADER_STATUS: 'CHANGE_SWITCH_LOADER_STATUS',
  CHANGE_BUTTON_LOADER_STATUS: 'CHANGE_BUTTON_LOADER_STATUS',
  APPEND_RULE_CONTACT_TAGS: 'APPEND_RULE_CONTACT_TAGS',
  APPEND_RULE_CHAT_TAGS: 'APPEND_RULE_CHAT_TAGS',
};

export default actions;
