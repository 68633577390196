import React, { Component } from 'react';
import '../../components/Settings/settings.scss';
import actions from '../../redux/settings/actions';
import { connect } from 'react-redux';
import * as queryString from 'query-string/index';
import { Button, Spin } from 'antd';
import {getAppDetail} from '../../helpers/utility';

class wooCommerceCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { pageLoader, location } = this.props,
      { success } = queryString.parse(location.search);
    if (pageLoader) {
      return (
        <div className={'stripe-callback-container loader'}>
          <Spin tip="Please wait..." />
        </div>
      );
    } else if (success === '1' && !pageLoader) {
      return (
        <div className={'stripe-callback-container'}>
          <h1>{`You’ve now linked ${getAppDetail().name} to WooCommerce store`}</h1>
          <Button
            type={'primary'}
            className={'btn btn-primary btn-sm'}
            onClick={() => window.close()}
          >
            OK
          </Button>
        </div>
      );
    } else {
      return (
        <div className={'stripe-callback-container'}>
          <h1>Something went wrong.</h1>
          <p>Please try again later!</p>
          <Button
            type={'primary'}
            className={'btn btn-secondary-outline btn-sm'}
            onClick={() => window.close()}
          >
            Close
          </Button>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { pageLoader } = state.Settings;
  return {
    pageLoader,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(wooCommerceCallback);
