import actions from './actions';
import {
  getFromUserData,
  getToUserData,
} from '../../../components/Email/helper';

let initState = {
  selectedEmailCount: [],
  autoEmailData: [],
  autoEmailTypeList: [],
  autoEmailTableLoading: true,
  autoEmailStatus: 'all',
  searchValue: '',
  emailTemplates: [],
  emailTemplatesLoader: true,
  autoEmailName: '',
  autoEmailType: '',
  selectedTemplateId: '',
  autoEmailID: '',
  emailComposerData: [],
  customisedEmailTemplate: [],
  mailText: '',
  mailSubject: '',
  sendTestEmailLoader: false,
  testEmailModalVisible: false,
  scheduleDetails: '',
  fromUserID: '',
  toUserID: '',
  openCreateAutoEmailDrawer: false,
  duplicateAutoEmailName: '',
  duplicateAutoEmailType: '',
  editorContentJson: '',
  openTemplateDrawer: false,
  autoEmailTabKey: '1',
  preHeaderText: '',
  autoSaved: false,
  customFields: [],
  templateContent: '',
  addedPropertyAndEvent: {
    new_filters: [],
  },
  propertiesMetaData: {},
  eventsMetaData: [],
  events: [],
  filters: {},
  allKeys: [],
  segments: [],
  sortKey: '',
  sortValue: '',
  autoEmailRecipientsCount: 0,
  lastSavedTime: null,
  autoEmailRecipientsLoader: false,
  templateType: 'gist_templates',
  autoFilteredPeople: [],
  savedSettings: null,
  isAutoEmailLoading: false,
  openSetLiveModal: false,
  pauseEmailLoading: false,
  createAutoEmailLoading: false,
  deleteAutoEmailFolderLoading: false,
  subjectValidation: '',
  autoMailTagId: '',
  mailSubscriptionID: undefined,
  isComposerChange: false,
  isAutoEmailSavingFailure: false,
  autoEmailSendBtnDisable: false,
  createAutoEmailLoader: false,
  templateJson: '',
  beeToken: '',
};

export default function autoEmailReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_AUTO_EMAIL_SELECTED_ROW_KEYS:
      return {
        ...state,
        selectedEmailCount: action.selectedRowKeys,
      };
    case actions.FETCH_AUTO_EMAIL:
      return {
        ...state,
        autoEmailTableLoading: true,
      };
    case actions.FETCH_AUTO_EMAIL_SUCCESS:
      return {
        ...state,
        autoEmailData: action.payload.mail_details,
        autoEmailTypeList: action.payload.mail_type,
        autoEmailTableLoading: false,
      };
    case actions.APPEND_AUTO_EMAIL:
      return {
        ...state,
        autoEmailTableLoading: true,
      };
    case actions.APPEND_AUTO_EMAIL_SUCCESS:
      return {
        ...state,
        autoEmailData: state.autoEmailData.concat(action.payload.mail_details),
        autoEmailTableLoading: false,
      };
    case actions.SET_AUTO_EMAIL_STATUS:
      return {
        ...state,
        autoEmailStatus: action.status,
      };
    case actions.SET_AUTO_EMAIL_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.FETCH_AUTO_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
        emailTemplatesLoader: false,
        selectedTemplateId: state.duplicateData
          ? state.selectedTemplateId
          : action.payload.default_template_id,
      };
    case actions.SET_AUTO_EMAIL_NAME:
      return {
        ...state,
        autoEmailName: action.payload.name,
        autoEmailType: action.payload.type,
      };
    case actions.SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.templateId,
      };
    case actions.CREATE_AUTO_EMAIL:
      return {
        ...state,
        createAutoEmailLoader: true,
      };
    case actions.CREATE_AUTO_EMAIL_SUCCESS:
      return {
        ...state,
        autoEmailID: action.payload.id,
        selectedTemplateId: action.payload.template_id,
        autoEmailRecipientsCount: 0,
        autoFilteredPeople: [],
        createAutoEmailLoader: false,
      };
    case actions.CREATE_AUTO_EMAIL_FAILURE:
      return {
        ...state,
        createAutoEmailLoader: false,
      };
    case actions.FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON_SUCCESS:
      return {
        ...state,
        templateJson: action.payload,
      };
    case actions.FETCH_AUTO_EMAIL_DATA:
      return {
        ...state,
        templateContent: '',
      };
    case actions.FETCH_AUTO_EMAIL_DATA_SUCCESS:
      return {
        ...state,
        emailComposerData: action.payload.data,
        customisedEmailTemplate: action.payload.settings,
        fromUserID: getFromUserData(action.payload.data),
        toUserID: getToUserData(action.payload.data),
        mailText: action.payload.data.mail_text,
        mailSubject: action.payload.data.subject
          ? action.payload.data.subject
          : '',
        selectedTemplateId: action.payload.data.template_id,
        scheduleDetails: action.payload.data.schedule_details
          ? action.payload.data.schedule_details
          : '',
        autoEmailName: action.payload.data.title,
        autoEmailType: action.payload.data.composer_type,
        preHeaderText: action.payload.data.pre_header_text
          ? action.payload.data.pre_header_text
          : '',
        customFields: action.payload.data.custom_fields,
        templateContent: action.payload.data.template_content,
        autoEmailRecipientsCount: 0,
        autoFilteredPeople: [],
        mailSubscriptionID: action.payload.data.mail_subscription_id,
        isComposerChange: false,
        editorContentJson: action.payload.data.visual_builder_json,
      };
    case actions.SET_AUTO_EMAIL_TEXT:
      return {
        ...state,
        mailText: action.message,
        isComposerChange: true,
      };
    case actions.SET_AUTO_EMAIL_SUBJECT:
      return {
        ...state,
        mailSubject: action.subject,
      };
    case actions.SET_AUTO_EMAIL_PRE_HEADER_TEXT:
      return {
        ...state,
        emailComposerData: {
          ...state.emailComposerData,
          pre_header_text: action.pre_header_text,
        },
      };
    case actions.SEND_AUTO_TEST_EMAIL:
      return {
        ...state,
        sendTestEmailLoader: true,
      };
    case actions.SEND_AUTO_TEST_EMAIL_SUCCESS:
      return {
        ...state,
        sendTestEmailLoader: false,
        testEmailModalVisible: false,
      };
    case actions.TEST_EMAIL_MODAL_VISIBLE:
      return {
        ...state,
        testEmailModalVisible: action.visible,
      };
    case actions.SEND_AUTO_EMAIL_TEST_EMAIL_FAILURE:
      return {
        ...state,
        sendTestEmailLoader: false,
      };
    case actions.SET_AUTO_EMAIL_SORT_KEY_AND_VALUE:
      return {
        ...state,
        sortKey: action.sortKey,
        sortValue: action.sortOrder,
      };
    case actions.SET_AUTO_EMAIL_ID:
      return {
        ...state,
        autoEmailID: action.id,
      };
    case actions.SET_FROM_EMAIL_USER_ID:
      return {
        ...state,
        fromUserID: action.fromUserID,
      };
    case actions.SET_TO_EMAIL_USER_ID:
      return {
        ...state,
        toUserID: action.toUserID,
      };
    case actions.OPEN_CREATE_AUTO_EMAIL_DRAWER:
      return {
        ...state,
        openCreateAutoEmailDrawer: action.payload,
        duplicateAutoEmailName: '',
        duplicateAutoEmailType: '',
        duplicateData: '',
        selectedTemplateId: '',
        templateType: 'gist_templates',
      };
    case actions.SET_DUPLICATE_AUTO_EMAIL_DATA:
      return {
        ...state,
        duplicateAutoEmailName: `${action.payload.data.title}(Copy)`,
        duplicateAutoEmailType:
          action.payload.data.composer_type,
        duplicateData: action.payload.data,
        selectedTemplateId:
          action.payload.data.template_id ||
          action.payload.data.visual_builder_template_name,
        autoEmailRecipientsCount: 0,
        autoFilteredPeople: [],
        templateType: action.payload.data.template_type,
        editorContentJson: action.payload.data.visual_builder_json,
      };
    case actions.UPDATE_AUTO_EMAIL_VISUAL_BUILDER_JSON:
      return {
        ...state,
        editorContentJson: action.payload,
      };
    case actions.FETCH_AUTO_EMAIL_BEE_TOKEN_SUCCESS:
      return {
        ...state,
        beeToken: action.payload,
      };
    case actions.CLOSE_CREATE_AUTO_EMAIL_DRAWER:
      return {
        ...state,
        openCreateAutoEmailDrawer: action.payload,
      };
    case actions.OPEN_AUTO_EMAIL_TEMPLATE_DRAWER:
      return {
        ...state,
        openTemplateDrawer: action.payload,
      };
    case actions.SET_AUTO_EMAIL_TAB_KEY:
      return {
        ...state,
        autoEmailTabKey: action.payload,
      };
    case actions.SET_DUPLICATE_AUTO_EMAIL_NAME:
      return {
        ...state,
        duplicateAutoEmailName: action.payload.name,
        duplicateAutoEmailType: action.payload.type,
        autoEmailType: '',
      };
    case actions.SET_PRE_HEADER_TEXT:
      return {
        ...state,
        preHeaderText: action.preHeaderText,
      };
    case actions.SET_AUTO_EMAIL_LAST_SAVE_TIME:
      return {
        ...state,
        lastSavedTime: action.payload.updated_at,
      };
    case actions.RESET_EMAIL_COMPOSER_FORM:
      return {
        ...state,
        emailComposerData: '',
        toUserID: '',
        mailText: '',
        mailSubject: '',
        scheduleDetails: '',
        autoEmailName: '',
        preHeaderText: '',
        editorContentJson: '',
      };
    case actions.AUTO_EMAIL_CREATE_RECIPIENTS:
      return {
        ...state,
        isAutoEmailLoading: true,
        isAutoEmailSavingFailure: false,
      };
    case actions.AUTO_EMAIL_CREATE_RECIPIENTS_SUCCESS:
      return {
        ...state,
        autoEmailRecipientsCount: 0,
        autoFilteredPeople: [],
      };
    case actions.AUTO_EMAIL_FETCH_RECIPIENTS_SUCCESS:
      return {
        ...state,
        autoEmailRecipientsCount: action.payload.data.matched_people_count,
        autoFilteredPeople: action.payload.data.filtered_people,
        autoEmailSendBtnDisable: false,
      };
    case actions.AUTO_EMAIL_FETCH_RECIPIENTS_FAILURE:
      return {
        ...state,
        autoEmailSendBtnDisable: action.payload,
        autoEmailRecipientsCount: action.payload
          ? action.count.data.matched_people_count
          : state.autoEmailRecipientsCount,
      };
    case actions.AUTO_MAIL_SET_RECIPIENT_LOADER:
      return {
        ...state,
        autoEmailRecipientsLoader: action.payload,
      };
    case actions.AUTO_MAIL_FILTER_VALIDATION:
      return {
        ...state,
        autoEmailRecipientsCount: action.payload
          ? state.autoEmailRecipientsCount
          : 0,
        autoFilteredPeople: action.payload ? state.autoFilteredPeople : [],
      };
    case actions.AUTO_EMAIL_SATUS_CHANGE_SUCCESS:
      if (action.isFromReviewTab) {
        return {
          ...state,
          emailComposerData: {
            ...state.emailComposerData,
            status: action.status,
          },
          pauseEmailLoading: false,
        };
      } else {
        let autoEmailData = state.autoEmailData;
        autoEmailData = autoEmailData.map(data => {
          if (data.id === action.id) {
            data.status = action.status;
          }
          return data;
        });
        return {
          ...state,
          autoEmailData,
          pauseEmailLoading: false,
        };
      }
    case actions.AUTO_EMAIL_RECIPIENTS_CREATE_SUCCESS:
      return {
        ...state,
        autoEmailRecipientsCount:
          action.payload.new_filters.length !== 0
            ? state.autoEmailRecipientsCount
            : 0,
        autoFilteredPeople:
          action.payload.new_filters.length !== 0
            ? state.autoFilteredPeople
            : [],
      };
    case actions.SET_AUTO_EMAIL_TEMPLATE_TAB_KEY:
      return {
        ...state,
        templateType: action.payload,
      };
    case actions.SAVE_AUTO_EMAIL_SETTINGS:
      return {
        ...state,
        savedSettings: action.payload,
      };
    case actions.RESET_AUTO_EMAIL_COMPOSER_DATA:
      return {
        ...state,
        fromUserID: getFromUserData(state.emailComposerData),
        toUserID: getToUserData(state.emailComposerData),
        mailSubject: state.emailComposerData.subject,
        preHeaderText: state.emailComposerData.pre_header_text,
      };
    case actions.RESET_AUTO_EMAIL_SETTINGS:
      let savedSetting = state.savedSettings
        ? state.savedSettings.data.mail_filter
        : {};
      return {
        ...state,
        autoEmailName: state.emailComposerData.title,
        fromUserID: getFromUserData(state.emailComposerData),
        toUserID: getToUserData(state.emailComposerData),
        savedSettings: null,
        emailComposerData: {
          ...state.emailComposerData,
          has_deadline: savedSetting.has_deadline,
        },
      };
    case actions.SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS:
      return {
        ...state,
        isAutoEmailLoading: action.payload,
        isAutoEmailSavingFailure: false,
      };
    case actions.SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE:
      return {
        ...state,
        isAutoEmailLoading: false,
        isAutoEmailSavingFailure: action.payload,
      };
    case actions.UPDATE_COMPOSER_DATA:
      return {
        ...state,
        emailComposerData: {
          ...state.emailComposerData,
          mail_text: action.payload.mail_text
            ? action.payload.mail_text
            : state.emailComposerData.mail_text,
          subject: action.payload.subject,
          pre_header_text: action.payload.pre_header_text,
          from_address: action.payload.from_address,
          reply_to_address: action.payload.reply_to_address,
        },
      };
    case actions.UPDATE_RECIPIENTS_DATA:
      return {
        ...state,
        emailComposerData: {
          ...state.emailComposerData,
          new_filters: action.payload.new_filters,
        },
      };
    case actions.UPDATE_SETTINGS_DATA:
      if (action.isFromMessageGoal) {
        return {
          ...state,
          emailComposerData: {
            ...state.emailComposerData,
            message_goal: action.goalValues,
          },
        };
      }
      return {
        ...state,
        emailComposerData: {
          ...state.emailComposerData,
          postal_address: action.payload.postal_address,
          from_address: action.payload.from_address,
          reply_to_address: action.payload.reply_to_address,
          deadline_details: action.payload.deadline_details,
          has_deadline: action.payload.has_deadline,
          delivery_days: action.payload.delivery_days,
          delivery_time_details: action.payload.delivery_time_details,
          message_goal: action.payload.message_goal,
          subject: action.payload.subject,
        },
        autoEmailName: action.payload.title,
      };
    case actions.UPDATE_SETTING_DEADLINE:
      return {
        ...state,
        emailComposerData: {
          ...state.emailComposerData,
          has_deadline: action.payload,
        },
      };
    case actions.SET_LIVE_MODAL:
      return {
        ...state,
        openSetLiveModal: action.payload,
      };
    case actions.SCHEDULE_AUTO_EMAIL:
      return {
        ...state,
        pauseEmailLoading: true,
      };
    case actions.DELETE_AUTO_EMAIL_SUCCESS:
      return {
        ...state,
        selectedEmailCount: [],
      };
    case actions.DELETE_AUTO_EMAIL_FOLDER_LOADING:
      return {
        ...state,
        deleteAutoEmailFolderLoading: action.payload,
      };
    case actions.SET_AUTO_EMAIL_SUBJECT_VALIDATION:
      return {
        ...state,
        subjectValidation: action.payload,
      };
    case actions.AUTO_EMAIL_CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          has_schedule: false,
        },
      };
    case actions.SET_AUTO_MAIL_TAG_ID:
      return {
        ...state,
        autoMailTagId: action.payload,
      };
    case actions.SET_AUTO_EMAIL_SUBSCRIPTION_ID:
      return {
        ...state,
        mailSubscriptionID: action.subscriptionID,
      };
    case actions.SET_COMPOSER_VALUE_CHANGE:
      return {
        ...state,
        isComposerChange: action.payload,
      };
    case actions.CREATE_AUTO_EMAIL_COMPOSER:
      return {
        ...state,
        mailText: action.isFromBeeEditor
          ? action.object.mail_filter.mail_text
          : state.mailText,
        editorContentJson: action.isFromBeeEditor
          ? action.object.mail_filter.visual_builder_json
          : state.editorContentJson,
        isAutoEmailLoading: action.saveBtnLoader,
      };
    default:
      return state;
  }
}
