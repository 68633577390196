const actions = {
  FETCH_SURVEY_TEMPLATE_LIST: 'FETCH_SURVEY_TEMPLATE_LIST',
  FETCH_SURVEY_TEMPLATE_LIST_SUCCESS: 'FETCH_SURVEY_TEMPLATE_LIST_SUCCESS',
  FETCH_SURVEY_TEMPLATE_LIST_FAILURE: 'FETCH_SURVEY_TEMPLATE_LIST_FAILURE',
  GET_SURVEY_CATEGORIES_LISTS: 'GET_SURVEY_CATEGORIES_LISTS',
  GET_SURVEY_CATEGORIES_LISTS_SUCCESS: 'GET_SURVEY_CATEGORIES_LISTS_SUCCESS',
  SET_CREATE_SURVEY_TEMPLATE_DRAWER_STATUS:
    'SET_CREATE_SURVEY_TEMPLATE_DRAWER_STATUS',
  CREATE_SURVEY_TEMPLATE: 'CREATE_SURVEY_TEMPLATE',
  CREATE_SURVEY_TEMPLATE_SUCCESS: 'CREATE_SURVEY_TEMPLATE_SUCCESS',
  CREATE_SURVEY_TEMPLATE_FAILURE: 'CREATE_SURVEY_TEMPLATE_FAILURE',
  SET_SURVEY_TEMPLATE_ID: 'SET_SURVEY_TEMPLATE_ID',
  DELETE_SURVEY_TEMPLATE: 'DELETE_SURVEY_TEMPLATE',
  DELETE_SURVEY_TEMPLATE_SUCCESS: 'DELETE_SURVEY_TEMPLATE_SUCCESS',
  DELETE_SURVEY_TEMPLATE_FAILURE: 'DELETE_SURVEY_TEMPLATE_FAILURE',
  FETCH_SURVEY_TEMPLATE_DETAILS: 'FETCH_SURVEY_TEMPLATE_DETAILS',
  FETCH_SURVEY_TEMPLATE_DETAILS_SUCCESS:
    'FETCH_SURVEY_TEMPLATE_DETAILS_SUCCESS',
  UPDATE_SURVEY_TEMPLATE_DRAWER_VISIBLE:
    'UPDATE_SURVEY_TEMPLATE_DRAWER_VISIBLE',
  UPDATE_SURVEY_TEMPLATE: 'UPDATE_SURVEY_TEMPLATE',
  UPDATE_SURVEY_TEMPLATE_SUCCESS: 'UPDATE_SURVEY_TEMPLATE_SUCCESS',
  UPDATE_SURVEY_TEMPLATE_FAILURE: 'UPDATE_SURVEY_TEMPLATE_FAILURE',
  SET_SURVEY_TEMPLATE_STATUS: 'SET_SURVEY_TEMPLATE_STATUS',
  SET_SURVEY_TEMPLATE_STATUS_LOADER: 'SET_SURVEY_TEMPLATE_STATUS_LOADER',
  SET_EDIT_DETAILS_FORM_LOADER: 'SET_EDIT_DETAILS_FORM_LOADER',
  SET_FILTER_SURVEY_CATEGORY_ID: 'SET_FILTER_SURVEY_CATEGORY_ID',
  SET_CHOOSE_SURVEY_TEMPLATE_SEARCH_VALUE:
    'SET_CHOOSE_SURVEY_TEMPLATE_SEARCH_VALUE',
  FILTER_SURVEY_TEMPLATE_LIST: 'FILTER_SURVEY_TEMPLATE_LIST',
  SET_SURVEY_TEMPLATE_SEARCH_VALUE: 'SET_SURVEY_TEMPLATE_SEARCH_VALUE',
  DUPLICATE_SURVEY_TEMPLATE: 'DUPLICATE_SURVEY_TEMPLATE',
  DUPLICATE_SURVEY_TEMPLATE_SUCCESS: 'DUPLICATE_SURVEY_TEMPLATE_SUCCESS',
  DUPLICATE_SURVEY_TEMPLATE_FAILURE: 'DUPLICATE_SURVEY_TEMPLATE_FAILURE',
  SET_SURVEY_TEMPLATE_DETAILS: 'SET_SURVEY_TEMPLATE_DETAILS',
};

export default actions;
