import React, { useState } from 'react';
import { Button, Card, Checkbox, Form } from 'antd';
import { store } from '../../redux/store';
import actions from '../../redux/auth/actions';

function UnsubscriptionDetails(props) {
  const {
    unsubscriptionData,
    subscriptionTypes,
    contactSubscriptions,
    buttonLoader,
    linkLoader,
    isEmptySubscriptions,
    isResubscribeEmails,
    alertMessage,
    isFetchUnsubscriptionFailed,
  } = props;
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(
    contactSubscriptions,
  );

  const updateEmailPreference = (event, subscriptionID) => {
    if (event.target.checked) {
      setSelectedSubscriptions([...selectedSubscriptions, subscriptionID]);
    } else {
      setSelectedSubscriptions(
        selectedSubscriptions.filter(id => id !== subscriptionID),
      );
    }
  };
  const submitEmailPreferences = (isUnsubscribeAll) => {
    const {
        data: { person, project_secret_key },
      } = unsubscriptionData,
      data = {
        mail_subscription_ids: isUnsubscribeAll ? [] : selectedSubscriptions,
        person_id: person.id,
        unsubscribe_from_all_emails: isUnsubscribeAll,
      };
    store.dispatch({
      type: actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA,
      payload: data,
      secretKey: project_secret_key,
    });
    if (isUnsubscribeAll) {
      setSelectedSubscriptions([]);
    }
  };

  const resubscribeEmailPreferences = () => {
    const {
        data: { person, project_secret_key },
      } = unsubscriptionData,
      data = {
        person_id: person.id,
        unsubscribe_from_all_emails: true,
      };
    store.dispatch({
      type: actions.RESUBSCRIBE_EMAIL_PREFERENCES,
      payload: data,
      secretKey: project_secret_key,
    });
  };

  return (
    <div className="unsubscription-container-inner">
      {!isEmptySubscriptions && (
        <div className="unsubscription-header">
          <h1>Manage your email preferences</h1>
          <h3>
            {unsubscriptionData.data && unsubscriptionData.data.person.email}
          </h3>
          <p>
            If this is not your email address, please ignore this page since the
            email associated with this page was most likely forwarded to you.
          </p>
          <div className={'border-line'} />
          <p>
            Select which kind of email communication(s) you'd like to receive
            from us:
          </p>
        </div>
      )}
      <div
        className={`unsubscribe-alert-message ${isFetchUnsubscriptionFailed &&
          'error-messages'}`}
      >
        {!isFetchUnsubscriptionFailed ? (
          <i className={'fas fa-check-circle icon-align'} />
        ) : (
          <i className={'fas fa-times-circle icon-align danger-icon'} />
        )}
        {alertMessage}
      </div>
      {isEmptySubscriptions ? (
        isResubscribeEmails &&
        unsubscriptionData.data &&
        unsubscriptionData.data.person && (
          <div className={'resubscribe-link'}>
            Unsubscribe by accident?{' '}
            <Button type={'link'} onClick={resubscribeEmailPreferences}>
              <u>Resubscribe</u>
            </Button>
          </div>
        )
      ) : (
        <Card bordered={false} className="unsubscription-card">
          <div className="form">
            {subscriptionTypes.map(subscription => (
              <Form.Item key={subscription.id}>
                <Checkbox
                  onChange={event =>
                    updateEmailPreference(event, subscription.id)
                  }
                  className="form-item"
                  checked={!!selectedSubscriptions.includes(subscription.id)}
                >
                  <span>{subscription.name}</span>
                  <p>{subscription.description}</p>
                </Checkbox>
              </Form.Item>
            ))}
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => submitEmailPreferences(false)}
              loading={buttonLoader}
            >
              Update email preferences
            </Button>
          </div>
          {contactSubscriptions.length > 0 && (
            <div className="unsubscription-footer">
              <Button
                className={'unsubscribe-all-btn'}
                type={'link'}
                onClick={() => submitEmailPreferences(true)}
                loading={linkLoader}
              >
                Opt out from all marketing emails
              </Button>
            </div>
          )}
        </Card>
      )}
    </div>
  );
}

export default UnsubscriptionDetails;
