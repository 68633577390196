import help from '../../image/help-1.svg';
import bookMark from '../../image/book-with-bookmark.svg';
import catalog from '../../image/catalog-of-colors.svg';
import {getAppDetail} from '../../helpers/utility';

export const planList = {
  support_pro: {
    description:
      'Help your customers with real-time support across multiple channels, saved replies, and an integrated knowledge base.',
    icon: help,
  },
  marketing_pro: {
    description:
      'Grow traffic and convert more visitors with tools like forms, tooltips, marketing automation workflows, analytics, and more.',
    icon: catalog,
  },
  all_in_one_pro: {
    description:
      `Get everything ${getAppDetail().name} has to offer by combining both Support and Marketing plans and have a complete 360° of your contacts.`,
    icon: bookMark,
  },
  marketing_premium_gistmailt: {
    description:
      'Grow traffic and convert more visitors with tools like forms, tooltips, marketing automation workflows, analytics, and more.',
    icon: catalog,
  },
  marketing_premium_femailsend: {
    description:
      'Grow traffic and convert more visitors with tools like forms, tooltips, marketing automation workflows, analytics, and more.',
    icon: catalog,
  },
};

export const shopifyPlanList = {
  support_premium_shopify: {
    description:
      'Help your customers with real-time support across multiple channels, saved replies, and an integrated knowledge base.',
    icon: help,
  },
  marketing_premium_shopify: {
    description:
      'Grow traffic and convert more visitors with tools like forms, tooltips, marketing automation workflows, analytics, and more.',
    icon: catalog,
  },
  all_in_one_premium_shopify: {
    description:
      'Get everything Gist has to offer by combining both Support and Marketing plans and have a complete 360° of your contacts.',
    icon: bookMark,
  },
};
