import { all, call, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../helpers/utility';
import settings from '../../settings';
import { message } from 'antd';
import { store } from '../store';
import workFlowActions from '../automation/workflow/actions';

export function* fetchEventCategories(params) {
  try {
    let lastCategoryId = '';
    const {openKeyId} = store.getState().Events;
    if (params.page) {
      lastCategoryId = params.page;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/event_categories?last_category_id=${lastCategoryId}`;
    const data = yield call(() => getPromise(url));
    if (params.append) {
      yield put({
        type: actions.FETCH_EVENT_CATEGORIES_SUCCESS,
        payload: data,
        append: true,
      });
    } else {
      yield put({
        type: actions.FETCH_EVENT_CATEGORIES_SUCCESS,
        payload: data,
      });
    }
    if(!params.append && (openKeyId === null || openKeyId.length === 0)){
      yield put({
        type: actions.CHANGE_MENU_ID,
        payload: data.data.event_categories[0].id.toString(),
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_EVENT_CATEGORIES_FAILURE });
    message.error('events categories fetch failed');
  }
}

export function* fetchEventData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events/${params.id}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EVENT_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_EVENT_DATA_FAILURE });
    message.error('events fetch failed');
  }
}

export function* fetchEventEditData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events/${params.id}/edit`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EVENT_EDIT_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_EVENT_EDIT_DATA_FAILURE });
    message.error('events edit fetch failed');
  }
}

export function* fetchCategoryDropdown() {
  try {
    const url = `${
        settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/event_filter_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CATEGORY_DROPDOWN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('events dropdown fetch failed');
    yield put({
      type: actions.FETCH_CATEGORY_DROPDOWN_FAILURE,
    });
  }
}

export function* addEvent(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events`;
    const data = yield call(() => postPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.ADD_EVENT_SUCCESS,
    });
    yield put({
      type: actions.EVENT_DRAWER_CLOSE,
    });
    yield put({
      type: actions.CHANGE_CURRENT_STEP,
      payload: 0,
    });
    yield put({
      type: actions.FETCH_EVENT_CATEGORIES,
    });
    updateBrowserHistory(`events/${data.data.event.event_secret}`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.ADD_EVENT_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('add event failed');
    }
  }
}

export function* editEvent(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events/${params.id}`;
    const data = yield call(() => patchPromise(url, params.data));
    message.success(data.message);
    yield put({
      type: actions.FETCH_EVENT_CATEGORIES,
    });
    yield put({
      type: actions.FETCH_EVENT_DATA,
      id: params.id,
    });
    yield put({
      type: actions.EDIT_EVENT_SUCCESS,
    });
    yield put({
      type: actions.EVENT_DRAWER_CLOSE,
    });
    yield put({
      type: actions.CHANGE_CURRENT_STEP,
      payload: 0,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.EDIT_EVENT_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('edit event failed');
    }
  }
}

export function* deleteEvent(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.DELETE_EVENT_SUCCESS,
    });
    yield put({
      type: actions.EVENT_ID_CHANGE,
      payload: null,
    });
    updateBrowserHistory('events');
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_EVENT_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('delete event failed');
    }
  }
}

export function* createCategory(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}//event_categories?name=${params.name}`;
    const data = yield call(() => postPromise(url));
    message.success(data.message);
    yield put({
      type: actions.CREATE_CATEGORY_SUCCESS,
      payload: data.data.event_category,
    });
    yield put({
      type: actions.EVENT_CATEGORY_DRAWER,
      payload: false,
    });
    yield put({
      type: actions.FETCH_CATEGORY_DROPDOWN,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_CATEGORY_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('add event category failed');
    }
  }
}

export function* editCategory(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/event_categories/${params.id}?name=${
      params.name
    }`;
    const data = yield call(() => patchPromise(url));
    message.success(data.message);
    yield put({
      type: actions.EDIT_CATEGORY_NAME_SUCCESS,
      payload: {
        id: params.id,
        name: params.name,
      },
    });
    yield put({
      type: actions.EVENT_CATEGORY_DRAWER,
      payload: false,
    });
    yield put({
      type: actions.FETCH_CATEGORY_DROPDOWN,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.EDIT_CATEGORY_NAME_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('edit event category failed');
    }
  }
}

export function* deleteCategory(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/event_categories/${params.id}`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.DELETE_CATEGORY_SUCCESS,
      payload: params.id,
    });
    yield put({
      type: actions.FETCH_CATEGORY_DROPDOWN,
    });
    yield put({
      type: actions.FETCH_EVENT_CATEGORIES,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_CATEGORY_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('delete event category failed');
    }
  }
}

export function* fetchEventVisualiser() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/event_visualizer`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EVENT_VISUALISER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_EVENT_VISUALISER_FAILURE });
    message.error('event visualiser fetch failed');
  }
}

export function* addLaunchUrl(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/add_launch_url`;
    yield call(() => postPromise(url, params.data));
    if (params.data.launch_url.includes('http')) {
      window.open(
        `${params.data.launch_url}?cfVisualizerToken=${params.token}`,
        '_blank',
      );
    } else {
      message.error('Please enter valid url');
    }
    yield put({
      type: actions.ADD_LAUNCH_URL_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.ADD_LAUNCH_URL_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('events categories fetch failed');
    }
  }
}

export function* fetchSearchedEvent(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events?search=${params.searchValue}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SEARCHED_EVENT_SUCCESS,
      payload: data.data.events,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_SEARCHED_EVENT_FAILURE });
    message.error('events search failed');
  }
}
export function* fetchCustomEventsDropdown() {
  try {
    const url = `${
        settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events?search=&custom_events=true`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CUSTOM_EVENTS_DROPDOWN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('events dropdown fetch failed');
    yield put({
      type: actions.FETCH_CUSTOM_EVENTS_DROPDOWN_FAILURE,
    });
  }
}

export function* fetchCustomPropertiesList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/events/${
      params.eventSecret
    }/event_properties`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CUSTOM_FILTER_LIST_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: workFlowActions.SET_WORKFLOW_LOADER,
      loader: false
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('events dropdown fetch failed');
    yield put({
      type: actions.FETCH_CUSTOM_FILTER_LIST_FAILURE,
    });
    yield put({
      type: workFlowActions.SET_WORKFLOW_LOADER,
      loader: false
    });
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_EVENT_CATEGORIES, fetchEventCategories),
    takeEvery(actions.FETCH_EVENT_DATA, fetchEventData),
    takeEvery(actions.FETCH_EVENT_EDIT_DATA, fetchEventEditData),
    takeEvery(actions.FETCH_CATEGORY_DROPDOWN, fetchCategoryDropdown),
    takeEvery(actions.ADD_EVENT, addEvent),
    takeEvery(actions.EDIT_EVENT, editEvent),
    takeEvery(actions.DELETE_EVENT, deleteEvent),
    takeEvery(actions.CREATE_CATEGORY, createCategory),
    takeEvery(actions.EDIT_CATEGORY_NAME, editCategory),
    takeEvery(actions.DELETE_CATEGORY, deleteCategory),
    takeEvery(actions.FETCH_EVENT_VISUALISER, fetchEventVisualiser),
    takeEvery(actions.ADD_LAUNCH_URL, addLaunchUrl),
    takeEvery(actions.FETCH_SEARCHED_EVENT, fetchSearchedEvent),
    takeEvery(actions.FETCH_CUSTOM_EVENTS_DROPDOWN, fetchCustomEventsDropdown),
    takeEvery(actions.FETCH_CUSTOM_FILTER_LIST,fetchCustomPropertiesList),
  ]);
}
