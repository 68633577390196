import React, { Component } from 'react';
import { Popconfirm } from 'antd';

export class ActionButtons extends Component {
  confirm = () => {
    this.props.deleteNode();
  };

  cancel = () => {};

  render() {
    return (
      <div className="action-btn">
        {!this.props.isNotAllowToEdit && (
          <span className={'edit'} onClick={() => this.props.editNote()}>
            <i className={'fas fa-pen'} />
          </span>
        )}
        {!this.props.isNotAllowToDuplicate && (
          <span className={'duplicate'}>
            <i className={'far fa-file-lines font-primary'} />
          </span>
        )}
        {!this.props.isNotAllowToDelete && (
          <>
            <Popconfirm
              title="Delete this component?"
              onConfirm={this.confirm}
              onCancel={this.cancel}
              okText="Delete"
              okType="danger"
              cancelText="No"
            >
              <span className={'delete'}>
                <i className={'far fa-trash'} />
              </span>
            </Popconfirm>
          </>
        )}
      </div>
    );
  }
}
