export function isSetUpCompleted(data, type) {
  switch (type) {
    case 'csv_imported_at':
      return data.contacts && !!data.contacts.csv_imported_at;
    case 'teammate_invited_at':
      return data.settings && !!data.settings.teammate_invited_at;
    case 'user_status_updated_at':
      return data.settings && !!data.settings.user_status_updated_at;
    case 'user_chat_visibility_updated_at':
      return data.settings && !!data.settings.user_chat_visibility_updated_at;
    case 'tracking_script_installed_at':
      return data.settings && !!data.settings.tracking_script_installed_at;
    default:
      return false;
  }
}

export function moduleAllSetUpCompleted(data, setUps) {
  let isCompleted = true;
  if (data) {
    setUps.forEach(value => {
      if (!Object.keys(data).includes(value)) {
        isCompleted = false;
      }
    });
  }
  return isCompleted;
}
