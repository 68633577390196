import React from 'react';
import { ActionButtons } from '../ActionButtons';
import { getConditionData } from '../../../../../containers/Messages/Bots/helper';
import { BaseEditableComponent } from '../BaseComponent';
import { conditionalButtonList } from '../../../../../containers/Messages/Bots/constants';

export class Conditions extends BaseEditableComponent {
  render() {
    const { type, content, icon } = getConditionData(this.state);
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className={'header'}>
          <h3>
            <span>
              <i className={icon} />
            </span>
            {type}
          </h3>
        </div>
        <div className={'body'}>
          <div className={'message-text-component'}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>

          <div className="buttons-list">
            {conditionalButtonList.map((item, key) => (
              <div
                key={key}
                className={'button-component'}
                data-port-id={item.id}
              >
                <span
                  className={`outline-btn ${
                    conditionalButtonList.length - 1 === key
                      ? 'danger-btn'
                      : 'primary-btn'
                  }`}
                >
                  {item.message}
                </span>
                <jtk-source port-id={item.id} endpoint={true} class={'red'} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
