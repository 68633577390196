const actions = {
  GET_DISPLAY_RULES: 'GET_DISPLAY_RULES',
  GET_DISPLAY_RULES_SUCCESS: 'GET_DISPLAY_RULES_SUCCESS',
  DISPLAY_RULE_FILTER_AND_PROPERTY: 'DISPLAY_RULE_FILTER_AND_PROPERTY',
  DISPLAY_RULES_FILTER_ADD_INTERNAL_PROPERTY:
    'DISPLAY_RULES_FILTER_ADD_INTERNAL_PROPERTY',
  DISPLAY_RULES_UPDATE_FILTER_DATA: 'DISPLAY_RULES_UPDATE_FILTER_DATA',
  DISPLAY_RULES_FILTER_REMOVE_INTERNAL_PROPERTY:
    'DISPLAY_RULES_FILTER_REMOVE_INTERNAL_PROPERTY',
  SET_DISPLAY_RULES: 'SET_DISPLAY_RULES',
  FILTER_FETCH_TAGS_LIST: 'FILTER_FETCH_TAGS_LIST',
  FILTER_FETCH_TAGS_LIST_SUCCESS: 'FILTER_FETCH_TAGS_LIST_SUCCESS',
  APPEND_TAG_LIST: 'APPEND_TAG_LIST',
};

export default actions;
