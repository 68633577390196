import actions from '../pageBuilder/actions';
import _, { cloneDeep, set } from 'lodash';
import constantData from '../../components/PageBuilder/JSON/constantData.json';
import { updateElementsPosition } from '../../components/PageBuilder/utility';

const { PresetFields } = constantData;

export const initState = {
  builderData: {},
  builderDataReference: {},
  builderContext: {
    selected: {
      object_type: null,
      object_id: null,
      object_collapse: null,
      object_editing: false,
    },
    history: {
      changes: [],
      undos: 0,
      unsaved: null,
      unpublished: null,
    },
    device: 'desktop',
    new_element: {
      visible: false,
      section_id: null,
      column: null,
    },
  },
  form_preset_fields: PresetFields,
  previewContentLoader: false,
  fontOptions: [],
  isCreatedByDrag: false,
};

const addChangeToHistory = (builderContext, change) => {
  // add to updates if no undos in state
  if (builderContext.history.undos === 0) {
    builderContext.history.changes.unshift(change);
  }

  // if undos in state, replace undone changes with new changes, keeping undo history
  if (builderContext.history.undos > 0) {
    let new_changes = [];

    builderContext.history.changes.forEach((change, i) => {
      if (i >= builderContext.history.undos) {
        new_changes.unshift(change);
      }
    });

    new_changes.unshift(change);
    builderContext.history.changes = new_changes;
  }

  builderContext.history.undos = 0;
  builderContext.history.unsaved = true;
  return builderContext;
};

export default function PageBuilderReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDER_DATA: {
      return {
        ...state,
        previewContentLoader: true,
      };
    }
    case actions.FETCH_BUILDER_DATA_SUCCESS: {
      let getBuilderData = cloneDeep(
        !action.payload ? state.builderData : action.payload,
      );
      for (let [key, value] of Object.entries(getBuilderData.elements)) {
        let element = value;
        if (element && element.element_type === 'timer') {
          if (
            element.options['timer-padding'] !== undefined &&
            element.options['timer-padding-top'] === undefined
          ) {
            const paddingValue = element.options['timer-padding'];
            element.options['timer-padding-top'] = paddingValue / 2;
            element.options['timer-padding-bottom'] = paddingValue / 2;
            element.options['timer-padding-left'] = paddingValue;
            element.options['timer-padding-right'] = paddingValue;
          }
          if (
            element.options['timer-padding-mobile'] !== undefined &&
            element.options['timer-padding-mobile-top'] === undefined
          ) {
            const paddingValue = element.options['timer-padding-mobile'];
            element.options['timer-padding-mobile-top'] = paddingValue / 2;
            element.options['timer-padding-mobile-bottom'] = paddingValue / 2;
            element.options['timer-padding-mobile-left'] = paddingValue;
            element.options['timer-padding-mobile-right'] = paddingValue;
          }
          getBuilderData.elements[key] = element;
        }
      }
      if (
        getBuilderData.options['timer-padding'] !== undefined &&
        getBuilderData.options['timer-padding-top'] === undefined
      ) {
        const timerPaddingValue = getBuilderData.options['timer-padding'];
        getBuilderData.options['timer-padding-top'] = timerPaddingValue / 2;
        getBuilderData.options['timer-padding-bottom'] = timerPaddingValue / 2;
        getBuilderData.options['timer-padding-left'] = timerPaddingValue;
        getBuilderData.options['timer-padding-right'] = timerPaddingValue;
      }
      return {
        ...state,
        builderData: getBuilderData,
        builderDataReference: action.payload,
        builderContext: {
          ...state.builderContext,
          selected: {
            object_type: null,
            object_id: null,
            object_collapse: null,
            object_editing: false,
          },
        },
        previewContentLoader: false,
      };
    }
    case actions.RESET_BUILDER_DATA: {
      return {
        ...state,
        builderData: {},
        builderDataReference: {},
        builderContext: initState.builderContext,
      };
    }
    case actions.FETCH_BUILDER_DATA_FAILURE: {
      return {
        ...state,
        previewContentLoader: false,
      };
    }
    case actions.UPDATE_BUILDER_DATA: {
      return {
        ...state,
        builderData: action.payload,
      };
    }

    case actions.UPDATE_BUILDER_DATA_REFERENCE: {
      return {
        ...state,
        builderDataReference: { ...action.payload.builder_data },
      };
    }

    case actions.UPDATE_SELECTED_BUILDER_OBJECT: {
      let { object_type, object_id, object_collapse, object_editing } =
        action.payload;

      let updatedBuilderContext = cloneDeep(state.builderContext);
      updatedBuilderContext['selected']['object_type'] = object_type;
      updatedBuilderContext['selected']['object_id'] = object_id;
      updatedBuilderContext['selected']['object_editing'] = object_editing
        ? object_editing
        : false;
      updatedBuilderContext['selected']['object_collapse'] = object_collapse
        ? object_collapse
        : null;
      updatedBuilderContext['new_element']['visible'] = false;

      return {
        ...state,
        builderContext: updatedBuilderContext,
      };
    }

    case actions.UPDATE_NEW_ELEMENT_OBJECT: {
      let { visible, section_id, column } = action.payload;
      let updatedBuilderContext = cloneDeep(state.builderContext);

      updatedBuilderContext['new_element']['visible'] = visible;
      updatedBuilderContext['new_element']['section_id'] = section_id;
      updatedBuilderContext['new_element']['column'] = column;

      return {
        ...state,
        builderContext: updatedBuilderContext,
      };
    }

    case actions.UPDATE_BUILDER_OBJECT: {
      let { object_type, object, skip_history, object_id } = action.payload;
      let updatedBuilderData = cloneDeep(state.builderData);
      let updatedBuilderContext = cloneDeep(state.builderContext);

      if (state.isCreatedByDrag) {
        //to update positions of the elements following new element
        updatedBuilderData.elements = updateElementsPosition(
          updatedBuilderData.elements,
          object,
        );
      }
      updatedBuilderData[object_type][object.id] = object;
      let historyUpdates = [];

      if (skip_history !== true) {
        historyUpdates.push({
          object_type: object_type,
          object_id: object_id,
          setting_name: '[is_deleted]',
          value: false,
          old_value: true,
        });
      }
      if (historyUpdates.length) {
        updatedBuilderContext = addChangeToHistory(updatedBuilderContext, {
          updates: historyUpdates,
          timestamp: Date.now(),
        });
      }

      return {
        ...state,
        builderData: updatedBuilderData,
        builderContext: updatedBuilderContext,
      };
    }

    case actions.UPDATE_BUILDER_OBJECT_VALUE: {
      let updatedBuilderData = cloneDeep(state.builderData);
      let updatedBuilderContext = cloneDeep(state.builderContext);
      let updates = action.payload;
      let historyUpdates = [];
      let updatedFormFields = [];

      // Specifically check if vertical and horizontal spacing is updated.
      if (
        !updates[0].setting_name.includes('-shadow') &&
        (updates[0].setting_name.includes('-vertical') ||
          updates[0].setting_name.includes('-horizontal'))
      ) {
        let newUpdates = [],
          sides = [];

        if (updates[0].setting_name.includes('-vertical')) {
          sides = ['top', 'bottom'];
        } else if (updates[0].setting_name.includes('-horizontal')) {
          sides = ['left', 'right'];
        }

        sides.forEach((side, index) => {
          let update = cloneDeep(updates[0]);
          update.setting_name = update.setting_name
            .replace('-horizontal', `-${side}`)
            .replace('-vertical', `-${side}`);
          newUpdates.push(update);
        });

        updates = newUpdates;
      }

      if (updates.length && updates[0].field_value) {
        updatedFormFields = cloneDeep(
          state.builderData.elements[updates[0].object_id].options[
            'form-fields'
          ],
        );
      }
      updates.forEach((object) => {
        //adding and deleting the Form fields while enabling and disabling fields
        if (object.field_value) {
          let newFieldOptions = {};
          //If => Need to change the 'is_deleted' prop only when the field data already exists in Form-Options
          //else => Need to add the field values in Form-Options when data not exists
          let value;
          if (object.setting_key === 'is_deleted') {
            value = object.value === 'true' ? false : true;
          } else if (object.setting_key === 'position') {
            value = object.value;
          }
          if (updatedFormFields[object.field_value.attribute]) {
            newFieldOptions[object.field_value.attribute] = {
              ...updatedFormFields[object.field_value.attribute],
              [object.setting_key]: value,
            };
          } else {
            newFieldOptions[object.field_value.attribute] = {
              ...object.field_value,
            };
            newFieldOptions[object.field_value.attribute][object.setting_key] =
              value;
          }

          updatedFormFields = {
            ...updatedFormFields,
            ...newFieldOptions,
          };
        }

        let object_path = '';
        if (object.object_type !== '') {
          object_path += `[${object.object_type}]`;

          if (object.object_id) {
            object_path += `[${object.object_id}]`;
          }
        }

        const setting_path = object_path + object.setting_name;
        const old_value =
          object.old_value || object.skip_old_value_update
            ? object.old_value
            : _.get(state.builderData, setting_path);

        updatedBuilderData = set(
          updatedBuilderData,
          setting_path,
          object.field_value ? updatedFormFields : object.value,
        );
        if (object.skip_history !== true) {
          historyUpdates.push({
            object_type: object.object_type,
            object_id: object.object_id,
            setting_name: object.setting_name,
            value: object.field_value ? updatedFormFields : object.value,
            old_value: object.field_value
              ? state.builderData.elements[object.object_id].options[
                  'form-fields'
                ]
              : old_value,
          });
        }
      });

      if (historyUpdates.length && !action.updateOnlyPositions) {
        updatedBuilderContext = addChangeToHistory(updatedBuilderContext, {
          updates: historyUpdates,
          timestamp: Date.now(),
        });
      }

      return {
        ...state,
        builderData: updatedBuilderData,
        builderContext: updatedBuilderContext,
      };
    }

    case actions.DELETE_BUILDER_OBJECT: {
      let { object_id, object_type } = action.payload;
      let updatedBuilderData = cloneDeep(state.builderData);
      delete updatedBuilderData[object_type][object_id];

      return {
        ...state,
        builderData: updatedBuilderData,
      };
    }

    case actions.UPDATE_BUILDER_DEVICE: {
      let updatedBuilderContext = cloneDeep(state.builderContext);
      updatedBuilderContext.device = action.payload;

      return {
        ...state,
        builderContext: updatedBuilderContext,
      };
    }

    case actions.UNDO_BUILDER_HISTORY: {
      let updatedBuilderData = cloneDeep(state.builderData);
      let updatedBuilderContext = cloneDeep(state.builderContext);
      let undosIndex = updatedBuilderContext.history.undos;
      let change = updatedBuilderContext.history.changes[undosIndex];

      if (change) {
        change.updates.forEach((update, i) => {
          let object_path = '';

          if (update.object_type !== '') {
            object_path += `[${update.object_type}]`;
          }
          if (update.object_id) {
            object_path += `[${update.object_id}]`;
          }

          updatedBuilderData = _.set(
            updatedBuilderData,
            object_path + update.setting_name,
            update.old_value,
          );
        });
        updatedBuilderContext.history.undos =
          updatedBuilderContext.history.undos + 1;
        updatedBuilderContext.history.unsaved = true;

        return {
          ...state,
          builderData: updatedBuilderData,
          builderContext: updatedBuilderContext,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case actions.REDO_BUILDER_HISTORY: {
      let updatedBuilderData = cloneDeep(state.builderData);
      let updatedBuilderContext = cloneDeep(state.builderContext);
      let undosIndex = updatedBuilderContext.history.undos - 1;
      let change = updatedBuilderContext.history.changes[undosIndex];

      if (change) {
        change.updates.forEach((update, i) => {
          let object_path = '';

          if (update.object_type !== '') {
            object_path += `[${update.object_type}]`;
          }
          if (update.object_id) {
            object_path += `[${update.object_id}]`;
          }

          updatedBuilderData = _.set(
            updatedBuilderData,
            object_path + update.setting_name,
            update.value,
          );
        });
        updatedBuilderContext.history.undos =
          updatedBuilderContext.history.undos - 1;
        updatedBuilderContext.history.unsaved = true;
        return {
          ...state,
          builderData: updatedBuilderData,
          builderContext: updatedBuilderContext,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case actions.ADD_FORM_PRESET_FIELD: {
      return {
        ...state,
        form_preset_fields: [...state.form_preset_fields, action.payload],
      };
    }
    case actions.SET_FONT_OPTIONS: {
      return {
        ...state,
        fontOptions: action.payload,
      };
    }
    case actions.UPDATE_CREATE_NEW_ITEM_TYPE: {
      return {
        ...state,
        isCreatedByDrag: action.payload,
      };
    }

    default:
      return state;
  }
}
