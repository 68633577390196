import './style.scss';
import React from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Tooltip,
} from 'antd';
import { store } from '../../../redux/store';
import actions from '../../../redux/contacts/actions';
import moment from 'moment';
import _ from 'lodash';
import {
  openArticleInMessenger,
  isPropertySelected,
  fetchProjectSecretKey,
} from '../../../helpers/utility';
import { Link } from 'react-router-dom';

const Option = Select.Option;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
let id = 0;

const CreateContactModalForm = Form.create()(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        userType: '',
        selectedProperty: [],
        selectedCustomProperties: [],
      };
      id = 0;
      this.input = '';
    }

    userType = event => {
      this.setState({ userType: event.target.value });
    };

    remove = k => {
      const { form } = this.props;
      const keys = form.getFieldValue('keys');
      --id;
      form.setFieldsValue({
        keys: keys.filter(key => key !== k),
      });
      let currentState = this.state;
      currentState.selectedCustomProperties.splice(k, 1);
      this.setState(currentState);
    };

    add = () => {
      const { form, allPropertiesLists } = this.props;
      const keys = form.getFieldValue('keys');
      const nextKeys = keys.concat(id++);
      form.setFieldsValue({
        keys: nextKeys,
      });
      let selectedValue = this.selectInitialValue().value;
      const customProperty = {
        value: selectedValue,
        propertyType: _.find(allPropertiesLists, [
          'property_name',
          selectedValue,
        ]).property_type,
        propertyValue: '',
        id: id - 1,
      };
      this.setState(
        state => {
          state.selectedCustomProperties = state.selectedCustomProperties.concat(
            customProperty,
          );
        },
        () => {
          this.onSelectCustomProperties(selectedValue, id - 1);
        },
      );
    };

    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err && !this.props.visible) {
          let properties = {};
          if (values.property) {
            for (let i = 0; i < values.property.length; i++) {
              const { selectedCustomProperties } = this.state;
              let type = `property_${
                selectedCustomProperties[i].propertyType
              }_value`;
              if (moment.isMoment(values.property[i][type])) {
                properties[values.property[i].property_name] = moment(
                  values.property[i][type],
                ).format('X');
              } else {
                properties[values.property[i].property_name] =
                  values.property[i][type];
              }
            }
          }
          properties['email'] = values.email;
          properties['full_name'] = values.full_name;
          properties['user_id'] = values.user_id;
          properties['user_type'] = values.userType;
          let payload = {
            email: values.email,
            full_name: values.full_name,
            user_id: values.userId,
            identify_data: properties,
          };
          store.dispatch({
            type: actions.CREATE_CONTACT,
            payload: payload,
            emptyPage: this.props.emptyPage,
            onModalClose: this.props.handleCancel,
          });
        }
      });
    };

    onSelectCustomProperties = (selectedValue, index) => {
      let { allPropertiesLists } = this.props,
        currentState = this.state;
      if (index >= 0) {
        const customProperty = {
          value: selectedValue,
          propertyType: _.find(allPropertiesLists, [
            'property_name',
            selectedValue,
          ]).property_type,
          propertyValue: '',
          id: index,
        };
        let propertyIndex = _.findIndex(
          currentState.selectedCustomProperties,
          function(customProperty) {
            return customProperty.id === index;
          },
        );
        if (propertyIndex >= 0) {
          currentState.selectedCustomProperties[propertyIndex] = customProperty;
        }
        currentState.selectedProperty[index] = _.find(allPropertiesLists, [
          'property_name',
          selectedValue,
        ]).property_type;
        this.setState(currentState);
      }
    };
    selectInitialValue = () => {
      const { customProperties } = this.props,
        { selectedCustomProperties } = this.state;
      let selectedValue = _.differenceBy(
        customProperties,
        selectedCustomProperties,
        'value',
      );
      return selectedValue && selectedValue.length !== 0 && selectedValue[0];
    };

    componentDidUpdate() {
      if (this.props.visible) {
        this.input && this.input.focus();
      }
    }

    render() {
      const { getFieldDecorator, getFieldValue } = this.props.form,
        { customProperties } = this.props,
        { selectedProperty, selectedCustomProperties } = this.state;
      getFieldDecorator('keys', { initialValue: [] });
      const keys = getFieldValue('keys');
      const formItems = keys.map((key, index) => (
        <div className="flex justify-between gap-2" key={index}>
          <FormItem required={false}>
                {getFieldDecorator(`property[${index}][${'property_name'}]`, {
                  initialValue: this.state.selectedCustomProperties[index]
                    .value,
                })(
                  <Select
                    suffixIcon={<Icon type="caret-down" />}
                    onChange={value =>
                      this.onSelectCustomProperties(value, index)
                    }
                    showSearch
                    filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {customProperties.map(
                      (property, index) =>
                        property.value !== 'contact_owner' && (
                          <Option
                            value={property.value}
                            key={index}
                            disabled={isPropertySelected(
                              property.value,
                              selectedCustomProperties,
                            )}
                          >
                            {property.key}
                          </Option>
                        ),
                    )}
                  </Select>,
                )}
              </FormItem>
            {selectedProperty[index] === 'string' && (
            <FormItem required={false} className="w-full">
              {getFieldDecorator(
                `property[${index}][${'property_string_value'}]`,
                {
                  initialValue: '',
                },
              )(<Input />)}
            </FormItem>
          )}
          {selectedProperty[index] === 'number' && (
            <FormItem required={false} className="w-full">
              {getFieldDecorator(
                `property[${index}][${'property_number_value'}]`,
                {
                  initialValue: 0,
                },
              )(<InputNumber min={1} max={10} size="medium" />)}
            </FormItem>
          )}
          {selectedProperty[index] === 'boolean' && (
            <FormItem required={false} className="w-full">
              {getFieldDecorator(
                `property[${index}][${'property_boolean_value'}]`,
                {
                  initialValue: false,
                },
              )(<Checkbox>Set True/False (Default: False)</Checkbox>)}
            </FormItem>
          )}
          {selectedProperty[index] === 'date' && (
            <FormItem required={false} className="w-full">
              {getFieldDecorator(
                `property[${index}][${'property_date_value'}]`,
                {
                  initialValue: moment(),
                },
              )(<DatePicker format="MMM DD, YYYY" />)}
            </FormItem>
          )}
          {keys.length === index + 1 && (
            <FormItem>
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => this.remove(key)}
              />
            </FormItem>
          )}
        </div>
      ));
      return (
        <Form onSubmit={this.handleSubmit} id="add-contact-form">
          <FormItem>
            <span className="label">Name</span>
            {getFieldDecorator('full_name', {})(
              <Input
                ref={input => (this.input = input) && input.focus()}
                placeholder="John Doe"
              />,
            )}
          </FormItem>

          <FormItem>
            <span className="label">Email</span>
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Email is required.',
                },
              ],
            })(<Input placeholder="john.doe@example.com" />)}
          </FormItem>

          <FormItem>
            <span className="label mr-4">Contact Type</span>
            {getFieldDecorator('userType', { initialValue: 'lead' })(
              <RadioGroup onChange={this.userType}>
                <Radio value="lead">Lead</Radio>
                <Radio value="user">User</Radio>
              </RadioGroup>,
            )}
          </FormItem>
          {this.state.userType === 'user' && (
            <FormItem>
              <span className="label">User ID</span>
              {getFieldDecorator('userId', {})(
                <Input placeholder="12345678" />,
              )}
              <div class="ant-form-extra-desc">
                A unique{' '}
                <a
                  href="#/"
                  data-id="99"
                  onClick={e => openArticleInMessenger(e)}
                >
                  user identifier
                </a>{' '}
                that won't change.
              </div>
            </FormItem>
          )}

          <span className="label block mb-2">Properties</span>
          {formItems}

          {id < customProperties.length ? (
            <div className="link" onClick={this.add}>
              <Icon type="plus" /> Add property
            </div>
          ) : (
            <FormItem>
              <Tooltip title="No other properties are available">
                <Button className="tooltip-with-button" disabled>
                  <Icon type="plus" /> Add property
                </Button>
              </Tooltip>
            </FormItem>
          )}

          <p className="mt-4 mb-0">
            To add multiple contacts, use our{' '}
            <Link to={`/projects/${fetchProjectSecretKey()}/import_csv`}>
              import tool
            </Link>
            .
          </p>
        </Form>
      );
    }
  },
);

class CreateContactModal extends React.Component {
  render() {
    const { customProperties, handleCancel, emptyPage, contactModalLoading, allPropertiesLists } = this.props;
    return (
      <div>
        <Modal
          title="Add Contact"
          visible={this.props.visible}
          // onOk={this.handleOk}
          className="add-contact-modal"
          wrapClassName="add-contact-modal-wrap"
          destroyOnClose={true}
          maskClosable={true}
          onCancel={() => handleCancel()}
          footer={[
            <Button
              key="cancel"
              className="btn btn-secondary-outline btn-sm cancel"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              className="btn btn-primary btn-sm"
              form="add-contact-form"
              htmlType="submit"
              loading={contactModalLoading}
              disabled={contactModalLoading}
            >
              Create
            </Button>,
          ]}
          forceRender={true}
          width={550}
        >
          <button
            aria-label="Close"
            className="ant-modal-close"
            onClick={() => this.props.handleCancel()}
          >
            <span className="ant-modal-close-x">
              <Icon type="close-circle" theme="filled" />
            </span>
          </button>
          <CreateContactModalForm
            customProperties={customProperties}
            handleCancel={handleCancel}
            emptyPage={emptyPage}
            contactModalLoading={contactModalLoading}
            allPropertiesLists={allPropertiesLists}
          />
        </Modal>
      </div>
    );
  }
}

export default CreateContactModal;
