import React from 'react';

const GetMoreHelp = ({ t }) => (
  <>
    <h2>{t.requestReceivedSubject}</h2>
    <div className={'divider'}> </div>
    <p>{t.teamResponseExpected}</p>
    <p>{t.feedbackAcknowledgment}</p>
    <h1>{t.pageClosureMessage}</h1>
  </>
);

export default GetMoreHelp;
