const actions = {
  FETCH_ASSISTANT_LIST: 'FETCH_ASSISTANT_LIST',
  FETCH_ASSISTANT_LIST_SUCCESS: 'FETCH_ASSISTANT_LIST_SUCCESS',
  FETCH_ASSISTANT_LIST_FAILURE: 'FETCH_ASSISTANT_LIST_FAILURE',
  SET_CREATE_DRAWER_VISIBILITY_STATUS: 'SET_CREATE_DRAWER_VISIBILITY_STATUS',
  CREATE_ASSISTANT: 'CREATE_ASSISTANT',
  CREATE_ASSISTANT_SUCCESS: 'CREATE_ASSISTANT_SUCCESS',
  CREATE_ASSISTANT_FAILURE: 'CREATE_ASSISTANT_FAILURE',
  DUPLICATE_ASSISTANT: 'DUPLICATE_ASSISTANT',
  DUPLICATE_ASSISTANT_SUCCESS: 'DUPLICATE_ASSISTANT_SUCCESS',
  DUPLICATE_ASSISTANT_FAILURE: 'DUPLICATE_ASSISTANT_FAILURE',
  SET_ASSISTANT_SEARCH_VALUE: 'SET_ASSISTANT_SEARCH_VALUE',
  DELETE_ASSISTANT: 'DELETE_ASSISTANT',
  DELETE_ASSISTANT_SUCCESS: 'DELETE_ASSISTANT_SUCCESS',
  DELETE_ASSISTANT_FAILURE: 'DELETE_ASSISTANT_FAILURE',
  APPEND_ASSISTANT_LIST: 'APPEND_ASSISTANT_LIST',
  APPEND_ASSISTANT_LIST_SUCCESS: 'APPEND_ASSISTANT_LIST_SUCCESS',
  APPEND_ASSISTANT_LIST_FAILURE: 'APPEND_ASSISTANT_LIST_FAILURE',
  CHANGE_ASSISTANT_POSITION: 'CHANGE_ASSISTANT_POSITION',
  SET_ASSISTANT_STATUS: 'SET_ASSISTANT_STATUS',
  RE_ORDER_ASSISTANT_STATUS: 'RE_ORDER_ASSISTANT_STATUS',
  UPDATE_ASSISTANT_STATUS: 'UPDATE_ASSISTANT_STATUS',
  UPDATE_ASSISTANT_STATUS_SUCCESS: 'UPDATE_ASSISTANT_STATUS_SUCCESS',
  SET_ASSISTANT_SORT_VALUES: 'SET_ASSISTANT_SORT_VALUES',
  UPDATE_ASSISTANT: 'UPDATE_ASSISTANT',
  UPDATE_ASSISTANT_SUCCESS: 'UPDATE_ASSISTANT_SUCCESS',
  SET_ASSISTANT_TAB_KEY: 'SET_ASSISTANT_TAB_KEY',
  SET_ASSISTANT_EDITOR_STATUS_LOADING: 'SET_ASSISTANT_EDITOR_STATUS_LOADING',
  SET_ASSISTANT_EDITOR_STATUS_VALUE: 'SET_ASSISTANT_EDITOR_STATUS_VALUE',
  SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS:
    'SET_ASSISTANT_LOADER_FOR_SAVING_DETAILS',
  FETCH_ASSISTANT_BOT_DETAILS: 'FETCH_ASSISTANT_BOT_DETAILS',
  FETCH_ASSISTANT_BOT_DETAILS_SUCCESS: 'FETCH_ASSISTANT_BOT_DETAILS_SUCCESS',
  SET_OPEN_RESPONSE_DRAWER_STATUS: 'SET_OPEN_RESPONSE_DRAWER_STATUS',
  FETCH_ASSISTANT_USER_LIST: 'FETCH_ASSISTANT_USER_LIST',
  FETCH_ASSISTANT_USER_LIST_SUCCESS: 'FETCH_ASSISTANT_USER_LIST_SUCCESS',
  SET_APPLY_TAG_DRAWER_STATUS: 'SET_APPLY_TAG_DRAWER_STATUS',
  SET_ROUTE_CONVERSATION_DRAWER_STATUS: 'SET_ROUTE_CONVERSATION_DRAWER_STATUS',
  SET_CAPTURE_EMAIL_DRAWER_STATUS: 'SET_CAPTURE_EMAIL_DRAWER_STATUS',
  SET_SEND_MESSAGE_DRAWER_STATUS: 'SET_SEND_MESSAGE_DRAWER_STATUS',
  SET_ADD_BUTTON_DRAWER_STATUS: 'SET_ADD_BUTTON_DRAWER_STATUS',
  SET_WELCOME_MESSAGE_DRAWER_STATUS: 'SET_WELCOME_MESSAGE_DRAWER_STATUS',
  SET_CONTACT_PROPERTY_DRAWER_STATUS: 'SET_CONTACT_PROPERTY_DRAWER_STATUS',
  SET_CONVERSATION_PROPERTY_DRAWER_STATUS:
    'SET_CONVERSATION_PROPERTY_DRAWER_STATUS',
  SET_GOAL_DRAWER_STATUS: 'SET_GOAL_DRAWER_STATUS',
  FETCH_MEETING: 'FETCH_MEETING',
  FETCH_MEETING_SUCCESS: 'FETCH_MEETING_SUCCESS',
  SET_MEETINGS_DRAWER_STATUS: 'SET_MEETINGS_DRAWER_STATUS',
  SET_CONVERSATION_NOTE_DRAWER_STATUS: 'SET_CONVERSATION_NOTE_DRAWER_STATUS',
  SET_NODE: 'SET_NODE',
  UPDATE_ASSISTANT_SETTINGS: 'UPDATE_ASSISTANT_SETTINGS',
  UPDATE_ASSISTANT_SETTINGS_SUCCESS: 'UPDATE_ASSISTANT_SETTINGS_SUCCESS',
  UPDATE_ASSISTANT_DISPLAY_RULES: 'UPDATE_ASSISTANT_DISPLAY_RULES',
  UPDATE_ASSISTANT_DISPLAY_RULES_SUCCESS:
    'UPDATE_ASSISTANT_DISPLAY_RULES_SUCCESS',
  SET_ASSISTANT_SETTINGS_DATA: 'SET_ASSISTANT_SETTINGS_DATA',
  SET_AGENT_AVAILABILITY_DRAWER_STATUS: 'SET_AGENT_AVAILABILITY_DRAWER_STATUS',
  SET_BOT_CUSTOM_AUDIENCE_DRAWER_STATUS:
    'SET_BOT_CUSTOM_AUDIENCE_DRAWER_STATUS',
  SET_HANDOVER_TO_EMMA_DRAWER_STATUS: 'SET_HANDOVER_TO_EMMA_DRAWER_STATUS',
  RESET_ASSISTANTS: 'RESET_ASSISTANTS',
  SET_ASSISTANT_TYPE: 'SET_ASSISTANT_TYPE',
  SET_BOTS_SELECTED_ROWS: 'SET_BOTS_SELECTED_ROWS',
  APPLY_BOT_TAG: 'APPLY_BOT_TAG',
  APPLY_BOT_TAG_SUCCESS: 'APPLY_BOT_TAG_SUCCESS',
  REMOVE_BOT_TAG: 'REMOVE_BOT_TAG',
  SET_BOT_TAG_ID: 'SET_BOT_TAG_ID',
  FETCH_BOT_PREVIEW_DETAILS: 'FETCH_BOT_PREVIEW_DETAILS',
  FETCH_BOT_PREVIEW_SUCCESS: 'FETCH_BOT_PREVIEW_SUCCESS',
  UPDATE_BOT_TIME_STAMP: 'UPDATE_BOT_TIME_STAMP',
  RESET_ASSISTANT: 'RESET_ASSISTANT',
  SET_SUPPORT_BOT_DRAWER_STATUS: 'SET_SUPPORT_BOT_DRAWER_STATUS',
  FETCH_UNANSWERED_QUESTIONS_LIST: 'FETCH_UNANSWERED_QUESTIONS_LIST',
  FETCH_UNANSWERED_QUESTIONS_LIST_SUCCESS:
    'FETCH_UNANSWERED_QUESTIONS_LIST_SUCCESS',
  FETCH_UNANSWERED_QUESTIONS_LIST_FAILURE:
    'FETCH_UNANSWERED_QUESTIONS_LIST_FAILURE',
  APPEND_UNANSWERED_QUESTIONS_LIST: 'APPEND_UNANSWERED_QUESTIONS_LIST',
  APPEND_UNANSWERED_QUESTIONS_LIST_SUCCESS:
    'APPEND_UNANSWERED_QUESTIONS_LIST_SUCCESS',
  APPEND_UNANSWERED_QUESTIONS_LIST_FAILURE:
    'APPEND_UNANSWERED_QUESTIONS_LIST_FAILURE',
  FETCH_UNHELPFUL_RESPONSES_LIST: 'FETCH_UNHELPFUL_RESPONSES_LIST',
  FETCH_UNHELPFUL_RESPONSES_LIST_SUCCESS:
    'FETCH_UNHELPFUL_RESPONSES_LIST_SUCCESS',
  FETCH_UNHELPFUL_RESPONSES_LIST_FAILURE:
    'FETCH_UNHELPFUL_RESPONSES_LIST_FAILURE',
  APPEND_UNHELPFUL_RESPONSES_LIST: 'APPEND_UNHELPFUL_RESPONSES_LIST',
  APPEND_UNHELPFUL_RESPONSES_LIST_SUCCESS:
    'APPEND_UNHELPFUL_RESPONSES_LIST_SUCCESS',
  APPEND_UNHELPFUL_RESPONSES_LIST_FAILURE:
    'APPEND_UNHELPFUL_RESPONSES_LIST_FAILURE',
  FETCH_SUPPORT_BOTS_LIST: 'FETCH_SUPPORT_BOTS_LIST',
  FETCH_SUPPORT_BOTS_LIST_SUCCESS: 'FETCH_SUPPORT_BOTS_LIST_SUCCESS',
  FETCH_SUPPORT_BOTS_LIST_FAILURE: 'FETCH_SUPPORT_BOTS_LIST_FAILURE',
  TRAIN_BOT: 'TRAIN_BOT',
  TRAIN_BOT_SUCCESS: 'TRAIN_BOT_SUCCESS',
  TRAIN_BOT_FAILURE: 'TRAIN_BOT_FAILURE',
  DELETE_UNANSWERED_QUESTIONS: 'DELETE_UNANSWERED_QUESTIONS',
  DELETE_UNANSWERED_QUESTIONS_SUCCESS: 'DELETE_UNANSWERED_QUESTIONS_SUCCESS',
  DELETE_UNANSWERED_QUESTIONS_FAILURE: 'DELETE_UNANSWERED_QUESTIONS_FAILURE',
  CREATE_ASSISTANT_BOT_FROM_TEMPLATE: 'CREATE_ASSISTANT_BOT_FROM_TEMPLATE',
  CREATE_ASSISTANT_BOT_FROM_TEMPLATE_SUCCESS:
    'CREATE_ASSISTANT_BOT_FROM_TEMPLATE_SUCCESS',
  CREATE_ASSISTANT_BOT_FROM_TEMPLATE_FAILURE:
    'CREATE_ASSISTANT_BOT_FROM_TEMPLATE_FAILURE',
  UPDATE_BOT_DETAIL: 'UPDATE_BOT_DETAIL',
  UPDATE_ASSISTANT_CHAT_BUILDER_SUCCESS:
    'UPDATE_ASSISTANT_CHAT_BUILDER_SUCCESS',
  SET_APPLY_CONVERSATION_TAG_DRAWER_STATUS:
    'SET_APPLY_CONVERSATION_TAG_DRAWER_STATUS',
  SET_ENROLL_BOT_DRAWER_STATUS: 'SET_ENROLL_BOT_DRAWER_STATUS',
  CHECK_ENROLL_BOT_DATA: 'CHECK_ENROLL_BOT_DATA',
  CHECK_ENROLL_BOT_DATA_SUCCESS: 'CHECK_ENROLL_BOT_DATA_SUCCESS',
  CHECK_ENROLL_BOT_DATA_FAILURE: 'CHECK_ENROLL_BOT_DATA_FAILURE',
  SET_DELAY_DRAWER_STATUS: 'SET_DELAY_DRAWER_STATUS',

  //AI Bot
  FETCH_AI_BOT_DETAILS: 'FETCH_AI_BOT_DETAILS',
  FETCH_AI_BOT_DETAILS_SUCCESS: 'FETCH_AI_BOT_DETAILS_SUCCESS',
  FETCH_AI_BOT_DETAILS_FAILURE: 'FETCH_AI_BOT_DETAILS_FAILURE',
  UPDATE_AI_BOT_DETAILS: 'UPDATE_AI_BOT_DETAILS',
  UPDATE_AI_BOT_DETAILS_SUCCESS: 'UPDATE_AI_BOT_DETAILS_SUCCESS',

  //External sources
  FETCH_EMMA_SOURCES: 'FETCH_EMMA_SOURCES',
  FETCH_EMMA_SOURCES_SUCCESS: 'FETCH_EMMA_SOURCES_SUCCESS',
  SET_SOURCE_TYPE: 'SET_SOURCE_TYPE',
  SET_SOURCE_TABLE_LOADER: 'SET_SOURCE_TABLE_LOADER',
  CREATE_SOURCE: 'CREATE_SOURCE',
  RESET_SOURCE_VALUES: 'RESET_SOURCE_VALUES',
  APPEND_SOURCES_LIST: 'APPEND_SOURCES_LIST',
  APPEND_SOURCES_LIST_SUCCESS: 'APPEND_SOURCES_LIST_SUCCESS',
  SET_SOURCE_SORT_VALUES: 'SET_SOURCE_SORT_VALUES',
  SET_SOURCE_SEARCH_VALUE: 'SET_SOURCE_SEARCH_VALUE',
  FETCH_SOURCE_DETAILS: 'FETCH_SOURCE_DETAILS',
  FETCH_SOURCE_DETAILS_SUCCESS: 'FETCH_SOURCE_DETAILS_SUCCESS',
  FETCH_SOURCES_COUNT: 'FETCH_SOURCES_COUNT',
  FETCH_SOURCES_COUNT_SUCCESS: 'FETCH_SOURCES_COUNT_SUCCESS',
  UPDATE_SOURCE_DETAILS: 'UPDATE_SOURCE_DETAILS',
  SOURCE_STATUS_BULK_UPDATE: 'SOURCE_STATUS_BULK_UPDATE',
  DELETE_EMMA_SOURCES: 'DELETE_EMMA_SOURCES',
  UPDATE_SELECTED_SOURCE_ID: 'UPDATE_SELECTED_SOURCE_ID',
  UPDATE_SELECTED_SOURCE_DETAILS: 'UPDATE_SELECTED_SOURCE_DETAILS',
};

export default actions;
