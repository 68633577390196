import actions from './actions';
import _ from 'lodash';
import {
  devices,
  days,
  months,
  urlConditions,
} from '../../components/Forms/constants';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';

const initState = {
  displayRulesAudienceFilter: [],
  tourButtonDisabledDisplayRules: [],
  tourButtonHiddenDisplayRules: [],
  tourTriggerDisplayRules: [],
  tourBeaconHiddenDisplayRules: [],
  filters: [],
  propertiesMetaData: {},
  allKeysOfDisplayRules: [],
  allPropertiesListOfDisplayRules: [],
  audienceFilterValues: [],
  segments: [],
  tags: [],
};

function getValue(property) {
  if (property.value === 'visitors_device') {
    return devices[0].value;
  } else if (property.value === 'current_date') {
    return moment();
  } else if (property.value === 'current_day') {
    return days[0].value;
  } else if (property.value === 'current_month') {
    return months[0].value;
  } else if (property.value === 'current_time') {
    return undefined;
  } else if (property.value === 'on_exit_intent') {
    return true;
  } else {
    return '';
  }
}

function getStaticValue(key) {
  if (key === 'visitors_device') {
    return devices;
  } else if (key === 'current_day') {
    return days;
  } else {
    return months;
  }
}

function getAudienceFilters(filters) {
  let audienceFilterValues = cloneDeep(filters);
  audienceFilterValues.forEach((data, index) => {
    data.criteria.forEach((t, i) => {
      let filter = audienceFilterValues[index].criteria[i];
      if (
        urlConditions.includes(filter.condition) ||
        filter.selectedType === 'boolean'
      ) {
        delete audienceFilterValues[index].criteria[i].value;
      }
      if (filter.key === 'on_exit_intent') {
        delete audienceFilterValues[index].criteria[i].condition;
      }
      delete audienceFilterValues[index].criteria[i].selectedType;
      delete audienceFilterValues[index].criteria[i].conditions;
      delete audienceFilterValues[index].criteria[i].unit;
      if (
        ['current_day', 'current_month', 'visitors_device'].includes(filter.key)
      ) {
        delete audienceFilterValues[index].criteria[i].staticValues;
      }
    });
  });
  return audienceFilterValues;
}

export default function displayRuleReducer(state = initState, action) {
  switch (action.type) {
    case actions.FILTER_FETCH_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tags: action.data.data,
      };
    case actions.GET_DISPLAY_RULES_SUCCESS:
      let properties = [];
      for (let category of action.payload.segment_properties) {
        properties = _.concat(properties, category.properties);
      }
      return {
        ...state,
        allKeysOfDisplayRules: action.payload.segment_properties,
        propertiesMetaData: action.payload.data_types,
        allPropertiesListOfDisplayRules: properties,
      };
    case actions.DISPLAY_RULE_FILTER_AND_PROPERTY:
      let audienceFilter =
        state[action.ruleType || 'displayRulesAudienceFilter'];
      let addedFilter = audienceFilter.concat({
        criteria: [
          {
            key: state.allKeysOfDisplayRules[0].properties[0].value,
            condition:
              state.propertiesMetaData[
                state.allKeysOfDisplayRules[0].properties[0].property_type
              ][0].value,
            value: '',
            selectedType:
              state.allKeysOfDisplayRules[0].properties[0].property_type,
            conditions:
              state.propertiesMetaData[
                state.allKeysOfDisplayRules[0].properties[0].property_type
              ],
            unit: state.allKeysOfDisplayRules[0].properties[0].unit,
          },
        ],
      });
      if (addedFilter.length > 1) {
        addedFilter[addedFilter.length - 2].criteria_operator = 'AND';
      }
      return {
        ...state,
        [action.ruleType || 'displayRulesAudienceFilter']: addedFilter,
        audienceFilterValues: getAudienceFilters(addedFilter),
      };
    case actions.DISPLAY_RULES_FILTER_ADD_INTERNAL_PROPERTY:
      let audienceFilterCriteria =
        state[action.ruleType || 'displayRulesAudienceFilter'];
      let filters = audienceFilterCriteria.map((filter, index) => {
        if (index === action.payload.index) {
          let segment = state.allKeysOfDisplayRules[0].properties[0];
          filter.criteria = filter.criteria.concat({
            key: segment.value,
            condition: state.propertiesMetaData[segment.property_type][0].value,
            value: '',
            selectedType: segment.property_type,
            conditions: state.propertiesMetaData[segment.property_type],
            unit: segment.unit,
          });
        }
        return filter;
      });
      return {
        ...state,
        [action.ruleType || 'displayRulesAudienceFilter']: filters,
        audienceFilterValues: getAudienceFilters(filters),
      };
    case actions.DISPLAY_RULES_UPDATE_FILTER_DATA:
      let addedRules = cloneDeep(
        state[action.ruleType || 'displayRulesAudienceFilter'],
      );
      let { externalIndex, index, key, value, condition } = action.payload;
      let getProperty = _.find(state.allPropertiesListOfDisplayRules, function(
        property,
      ) {
        return (
          property.value ===
          (key ? key : addedRules[externalIndex].criteria[index].key)
        );
      });
      if (key) {
        addedRules[externalIndex].criteria[index] = {
          key: key,
          condition:
            state.propertiesMetaData[getProperty.property_type][0].value,
          value: getValue(getProperty),
          selectedType: getProperty.property_type,
          conditions: state.propertiesMetaData[getProperty.property_type],
          unit: getProperty.unit,
        };
      }
      if (condition || condition === false) {
        if (['has_any_value', 'has_no_value'].includes(condition)) {
          addedRules[externalIndex].criteria[index] = {
            key: addedRules[externalIndex].criteria[index].key,
            condition: condition,
            conditions: state.propertiesMetaData[getProperty.property_type],
            selectedType: getProperty.property_type,
            unit: getProperty.unit,
          };
        } else if (
          getProperty &&
          getProperty.length !== 0 &&
          getProperty.property_type === 'boolean'
        ) {
          addedRules[externalIndex].criteria[index] = {
            key: addedRules[externalIndex].criteria[index].key,
            value: condition,
            condition: 'equals',
            selectedType: getProperty.property_type,
            conditions: state.propertiesMetaData[getProperty.property_type],
            unit: getProperty.unit,
          };
        } else {
          addedRules[externalIndex].criteria[index].condition = condition;
        }
      }
      if (![undefined, null].includes(value)) {
        addedRules[externalIndex].criteria[index].value = value;
      }
      if (
        ['current_day', 'current_month', 'visitors_device'].includes(
          addedRules[externalIndex].criteria[index].key,
        )
      ) {
        addedRules[externalIndex].criteria[index].staticValues = getStaticValue(
          getProperty.value,
        );
      }
      return {
        ...state,
        [action.ruleType || 'displayRulesAudienceFilter']: addedRules,
        audienceFilterValues: getAudienceFilters(addedRules),
      };
    case actions.DISPLAY_RULES_FILTER_REMOVE_INTERNAL_PROPERTY:
      let filteredProperty = cloneDeep(
        state[action.ruleType || 'displayRulesAudienceFilter'],
      );
      filteredProperty[
        action.payload.externalIndex
      ].criteria = filteredProperty[
        action.payload.externalIndex
      ].criteria.filter(
        (criteria, index) => index !== action.payload.internalIndex,
      );
      if (
        filteredProperty[action.payload.externalIndex].criteria.length === 0
      ) {
        filteredProperty.splice(action.payload.externalIndex, 1);
      }
      return {
        ...state,
        [action.ruleType || 'displayRulesAudienceFilter']: filteredProperty,
        audienceFilterValues: getAudienceFilters(filteredProperty),
      };
    case actions.SET_DISPLAY_RULES:
      let editableFilters = action.payload;
      if (editableFilters.length > 0) {
        editableFilters.forEach((data, index) => {
          data.criteria.forEach((t, i) => {
            state.allPropertiesListOfDisplayRules.forEach((property, no) => {
              let filterProperty = editableFilters[index].criteria[i];
              if (property.value === filterProperty.key) {
                filterProperty.selectedType = property.property_type;
                filterProperty.conditions =
                  state.propertiesMetaData[property.property_type];
                filterProperty.unit = property.unit;
                if (
                  ['current_day', 'current_month', 'visitors_device'].includes(
                    filterProperty.key,
                  )
                ) {
                  filterProperty.staticValues = getStaticValue(
                    filterProperty.key,
                  );
                }
              }
            });
          });
        });
      }
      return {
        ...state,
        [action.ruleType || 'displayRulesAudienceFilter']: editableFilters,
        audienceFilterValues: editableFilters,
      };
    case actions.APPEND_TAG_LIST:
      const newTag = [
        {
          id: action.payload.id,
          display_name: action.payload.tags,
        },
      ];
      return {
        ...state,
        tags: [...newTag, ...state.tags],
      };
    default:
      return state;
  }
}
