import React, { useEffect, useRef } from 'react';
import ProfileAvatar from './ProfileAvatar';
import { agentProfileReconstruct } from '../../../helpers/utility';
import { resetTypingBubbleInterval } from './constants';
import actions from '../../../redux/chat/actions';
import { store } from '../../../redux/store';

const TypingDetail = props => {
  const { person, details } = props;
  const intervalID = useRef({});

  useEffect(() => {
    typingIntervalSet();
  }, []);

  useEffect(() => () => {
    clearTimeout(intervalID);
  });

  useEffect(() => {
    typingIntervalSet();
  }, []);

  const typingIntervalSet = () => {
    clearTimeout(intervalID);
    intervalID.current = setTimeout(
      updateTypingStatus,
      resetTypingBubbleInterval,
    );
  };

  const updateTypingStatus = () => {
    clearTimeout(intervalID);
    store.dispatch({
      type: actions.CLEAR_TYPING_DETAIL,
      payload: details,
    });
  };

  return (
    <>
      {details.type === 'agent' ? (
        <ProfileAvatar
          className={'ssm'}
          profile={agentProfileReconstruct(details.data)}
          displayProfile={true}
        />
      ) : (
        <ProfileAvatar
          className={'ssm'}
          profile={person}
          displayProfile={true}
        />
      )}
    </>
  );
};

export default TypingDetail;
