import React, { Component } from 'react';
// import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FroalaEditor from 'react-froala-wysiwyg';

// Require Editor CSS files.
// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// Require Editor JS files.
import './froala.scss';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

class TextEditor extends Component {
  componentDidUpdate() {
    const { config, model, onModelChange, id } = this.props,
      editor = document.getElementById(id ? id : 'froalaEditor');
    if (editor) {
      ReactDOM.render(
        <FroalaEditor
          config={config}
          model={model}
          onModelChange={(content) => {
            if (content !== model) {
              onModelChange(content);
            }
          }}
        />,
        editor,
      );
    }
  }

  componentDidMount() {
    const { config, model, onModelChange, id } = this.props,
      editor = document.getElementById(id ? id : 'froalaEditor');
    if (editor) {
      ReactDOM.render(
        <FroalaEditor
          config={config}
          model={model}
          onModelChange={(content) => {
            if (content !== model) {
              onModelChange(content);
            }
          }}
        />,
        editor,
      );
    }
  }

  render() {
    const { id } = this.props;
    return <div id={id ? id : 'froalaEditor'} className={'froalaEditor'} />;
  }
}

export default TextEditor;

// const TextEditor = props => {
//   const { config, model, onModelChange, id } = props;
//   useEffect(
//     () => {
//       const editor = document.getElementById(id ? id : 'froalaEditor');
//       if (editor) {
//         ReactDOM.render(
//           <FroalaEditor
//             config={config}
//             model={model}
//             onModelChange={onModelChange}
//           />,
//           editor,
//         );
//       } else {
//         console.log('editor not found');
//       }
//     },
//     [id, model],
//   );
//
//   return <div id={id ? id : 'froalaEditor'} className={'froalaEditor'} />;
// };
