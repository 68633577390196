import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../helpers/utility';
import { message } from 'antd';
import { store } from '../store';
import { cloneDeep } from 'lodash';

export function* fetchInboxRulesList() {
  try {
    yield put({
      type: actions.EMPTY_RULE_DATA,
    });
    yield put({
      type: actions.CHANGE_RULES_LIST_LOADER_STATUS,
      status: true,
    });
    let ruleFilters = store.getState().InboxAutomation.ruleFilters;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_automation?status=${
      ruleFilters.status
    }&trigger_name=${
      ruleFilters.trigger_name !== 'all' ? ruleFilters.trigger_name : ''
    }&action_name=${
      ruleFilters.action_name !== 'all' ? ruleFilters.action_name : ''
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_INBOX_RULES_LIST_SUCCESS,
      inboxRulesList: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch rules list failed');
    }
  }
}

export function* updateRulesListOrder(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_automation/${
      params.id
    }/update_priority`;
    let data = yield call(() => patchPromise(url, params.payload));

    yield put({
      type: actions.UPDATE_RULES_LIST_ORDER_SUCCESS,
      assignmentRules: params.assignmentRules,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Rules update failed');
    }
  }
}

export function* fetchSingleRuleData(params) {
  try {
    yield put({
      type: actions.EMPTY_RULE_DATA,
    });
    yield put({
      type: actions.CHANGE_RULE_LOADER_STATUS,
      status: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_automation/${params.id}`;
    let data = yield call(() => getPromise(url));
    let ruleData = data.data;

    ruleData.assignment_rule_triggers_attributes =
      ruleData.assignment_rule_triggers;
    ruleData.assignment_rule_actions_attributes =
      ruleData.assignment_rule_actions;

    delete ruleData['assignment_rule_triggers'];
    delete ruleData['assignment_rule_actions'];

    yield put({
      type: actions.FETCH_SINGLE_RULE_DATA_SUCCESS,
      ruleData: ruleData,
    });

    if (
      ruleData.assignment_rule_triggers_attributes &&
      ruleData.assignment_rule_triggers_attributes.length === 0
    ) {
      yield put({
        type: actions.ADD_TRIGGER_ITEM,
      });
    }
    if (
      ruleData.assignment_rule_actions_attributes &&
      ruleData.assignment_rule_actions_attributes.length === 0
    ) {
      yield put({
        type: actions.ADD_ACTION_ITEM,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch rule failed');
    }
  }
}

export function* createRule() {
  let { ruleData, deletedTriggerIds, deletedActionIds } = cloneDeep(
      store.getState().InboxAutomation,
    ),
    data;
  const currentRuleId = ruleData.id;

  try {
    yield put({
      type: actions.CHANGE_BUTTON_LOADER_STATUS,
      status: true,
    });
    if (deletedTriggerIds.length > 0) {
      ruleData.assignment_rule_triggers_attributes = ruleData.assignment_rule_triggers_attributes.concat(
        deletedTriggerIds,
      );
    }
    if (deletedActionIds.length > 0) {
      ruleData.assignment_rule_actions_attributes = ruleData.assignment_rule_actions_attributes.concat(
        deletedActionIds,
      );
    }
    ruleData = {
      assignment_rule: ruleData,
    };

    if (currentRuleId) {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/inbox_automation/${currentRuleId}`;
      data = yield call(() => patchPromise(url, ruleData));
    } else {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/inbox_automation`;
      data = yield call(() => postPromise(url, ruleData));
      updateBrowserHistory(`conversations/rule/${data.data.id}`);
    }

    ruleData = data.data;

    ruleData.assignment_rule_triggers_attributes =
      ruleData.assignment_rule_triggers;
    ruleData.assignment_rule_actions_attributes =
      ruleData.assignment_rule_actions;

    delete ruleData['assignment_rule_triggers'];
    delete ruleData['assignment_rule_actions'];

    yield put({
      type: actions.CREATE_INBOX_RULE_SUCCESS,
      ruleData: ruleData,
    });

    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.CHANGE_BUTTON_LOADER_STATUS,
      status: false,
    });
  }
}

export function* changeRuleStatus(params) {
  try {
    yield put({
      type: actions.CHANGE_SWITCH_LOADER_STATUS,
      status: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_automation/${
      params.id
    }/update_status`;
    let data = yield call(() => patchPromise(url, params.payload));
    if (params.indexPage) {
      yield put({
        type: actions.UPDATE_RULE_LIST_STATUS,
        payload: { status: data.data.assignment_rule.status, id: params.id },
      });
    } else {
      yield put({
        type: actions.UPDATE_RULE_STATUS_SUCCESS,
        status: data.data.assignment_rule.status,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error(error.response.data.errors);
    }
    yield put({
      type: actions.CHANGE_SWITCH_LOADER_STATUS,
      status: false,
    });
  }
}

export function* deleteRule(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_automation/${params.id}`;
    let data = yield call(() => deletePromise(url));

    yield put({
      type: actions.DELETE_INBOX_RULE_SUCCESS,
      ruleId: params.id,
      rulesCount: data.data,
    });

    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Rule delete failed');
    }
  }
}

export function* updateDefaultAssignee(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_automation/update_low_priority`;
    let data = yield call(() => patchPromise(url, params.payload));

    yield put({
      type: actions.UPDATE_DEFAULT_ASSIGNEE_SUCCESS,
      payload: data.data,
    });

    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Create rule failed');
    }
  }
}

export function* fetchActionChatTags() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/chat_tags`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ACTION_CHAT_TAGS_SUCCESS,
      chatTagsData: data.chat_tags,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch conversation tags failed');
    }
  }
}

export function* fetchActionAssigneeList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?include_current_user=true`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ACTION_ASSIGNEE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch assignee failed');
    }
  }
}

export function* fetchSegments() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/segments.json?project_secret_key=${fetchProjectSecretKey()}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONDITION_CONTACT_SEGMENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Segments fetch failed');
    }
  }
}

export function* fetchContactTagsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_TAGS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch tags list failed');
    }
  }
}

export function* fetchContactProperties() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_RULE_CONDITION_PROPERTIES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Properties fetch failed');
  }
}

export function* fetchContactPropertiesMetaData() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/property-meta-data`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_RULE_CONDITION_PROPERTIES_META_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Properties meta data fetch failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INBOX_RULES_LIST, fetchInboxRulesList),
    takeEvery(actions.UPDATE_RULES_LIST_ORDER, updateRulesListOrder),
    takeEvery(actions.FETCH_SINGLE_RULE_DATA, fetchSingleRuleData),
    takeEvery(actions.FETCH_ACTION_CHAT_TAGS, fetchActionChatTags),
    takeEvery(actions.FETCH_ACTION_ASSIGNEE_LIST, fetchActionAssigneeList),
    takeEvery(actions.FETCH_CONDITION_CONTACT_SEGMENTS_LIST, fetchSegments),
    takeEvery(actions.FETCH_CONTACT_TAGS_LIST, fetchContactTagsList),
    takeEvery(actions.FETCH_RULE_CONDITION_PROPERTIES, fetchContactProperties),
    takeEvery(
      actions.FETCH_RULE_CONDITION_PROPERTIES_META_DATA,
      fetchContactPropertiesMetaData,
    ),
    takeEvery(actions.CREATE_INBOX_RULE, createRule),
    takeEvery(actions.DELETE_INBOX_RULE, deleteRule),
    takeEvery(actions.UPDATE_DEFAULT_ASSIGNEE, updateDefaultAssignee),
    takeEvery(actions.UPDATE_RULE_STATUS, changeRuleStatus),
  ]);
}
