import React, { Component } from 'react';
// import {Button, Form, Input, message} from 'antd';
// import TextEditor from '../../../Shared/TextEditor';
// import settings from '../../../../settings';
// import {imageEditButtons, proactiveToolbarButtons, videoInsertButtons} from '../../../Settings/constant';
// import {convertHtmlToText, fetchProjectSecretKey} from '../../../../helpers/utility';
// import {trim} from 'lodash';

export class InputTextArea extends Component{
  constructor() {
    super();
    this.state = {
      test: '26888888',
    };
    //TODO: used in next build

    // this.config = {
    //     key: settings.FROALA_EDITOR_KEY,
    //     iconsTemplate: 'font_awesome_5r',
    //     // placeholderText: 'Type a message',
    //     pastePlain: true,
    //     toolbarBottom: true,
    //     attribution: false,
    //     //enter: Froalaeditor.ENTER_BR,
    //     charCounterCount: false,
    //     linkAlwaysBlank: true,
    //     htmlAllowedEmptyTags: ['textarea', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr'],
    //     linkAlwaysNoFollow: true,
    //     linkNoOpener: true,
    //     linkNoReferrer: true,
    //     htmlAllowedTags: ['p', 'a', 'strong', 'em', 'u', 'br', 'img', 'span', 'iframe'],
    //     videoResponsive: true,
    //     heightMin: 50,
    //     imageDefaultWidth: 0,
    //     imageDefaultAlign: 'left',
    //     imageDefaultMargin: 0,
    //     imageDefaultDisplay: 'block',
    //     imageStyles: {full_width: 'Full Width'},
    //     imageResize: false,
    //     toolbarButtons: proactiveToolbarButtons,
    //     imageEditButtons,
    //     videoInsertButtons,
    //     quickInsertEnabled: false,
    //     imageInsertButtons: ['imageUpload'],
    //     pluginsEnabled: ['draggable', 'emoticons', 'image', 'link', 'url', 'video'],
    //   };
  }

  onMessageContentChange = value => {
    // this.setState({ messageContent: value }, () => {console.log(this.state)});
  };

  render() {
    const { value } = this.props;
    return (
      <>
        <div
          className={`content ${!value ? 'error' : ''}`}
          dangerouslySetInnerHTML={{ __html: this.props.value }}
        />
        {/*<TextEditor*/}
        {/*  id={this.props.id}*/}
        {/*  config={this.config}*/}
        {/*  model={this.props.value}*/}
        {/*  onModelChange={this.onMessageContentChange}*/}
        {/*/>*/}
      </>
    );
  }
}