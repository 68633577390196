import React from 'react';
import './Feedbacks.scss';
import logo from '../../image/logo.png';
import { getQueryParamsValue } from '../../helpers/utility';
import translations from '../../components/FeedbacksContent/Email/Translation.json';
import { useParams } from 'react-router-dom';
import ThatsHelped from '../../components/FeedbacksContent/Email/ThatsHelped';
import GetMoreHelp from '../../components/FeedbacksContent/Email/GetMoreHelp';

const FeedbackContainer = () => {
  const isBrandingEnabled = getQueryParamsValue('branding_enabled') === 'true';
  const { type } = useParams();
  const locale = getQueryParamsValue('locale') || 'en';
  const referralCode = getQueryParamsValue('referral_code');

  const children =
    type === 'thanks' ? (
      <ThatsHelped t={translations[locale]} />
    ) : (
      <GetMoreHelp t={translations[locale]} />
    );
  return (
    <div className="feedback-page">
      <div className="email-feedback-content">{children}</div>
      {isBrandingEnabled && (
        <div className={'flex items-center branding '}>
          <img src={logo} className={'w-7'} />
          <a
            className={'underlined-link'}
            href={`https://getgist.com/?_go=${referralCode}`}
            target={'_blank'}
            rel="noreferrer"
          >
            {translations[locale].footerBranding}
          </a>
        </div>
      )}
    </div>
  );
};

export default FeedbackContainer;
