const actions = {
  SET_SIDEBAR_ACTIVE: 'SET_SIDEBAR_ACTIVE',
  RESET_TEAM_AND_TEAMMATE_NAME: 'RESET_TEAM_AND_TEAMMATE_NAME',
  UPDATE_INBOX_REPORT_FILTERS: 'UPDATE_INBOX_REPORT_FILTERS',

  // Conversations
  FETCH_NEW_CONVERSATION_REPORTS: 'FETCH_NEW_CONVERSATION_REPORTS',
  FETCH_NEW_CONVERSATION_REPORTS_SUCCESS:
    'FETCH_NEW_CONVERSATION_REPORTS_SUCCESS',
  FETCH_NEW_CONVERSATION_REPORTS_FAILURE:
    'FETCH_NEW_CONVERSATION_REPORTS_FAILURE',
  FETCH_CLOSED_CONVERSATION_REPORTS: 'FETCH_CLOSED_CONVERSATION_REPORTS',
  FETCH_CLOSED_CONVERSATION_REPORTS_SUCCESS:
    'FETCH_CLOSED_CONVERSATION_REPORTS_SUCCESS',
  FETCH_CLOSED_CONVERSATION_REPORTS_FAILURE:
    'FETCH_CLOSED_CONVERSATION_REPORTS_FAILURE',
  FETCH_REPLIED_CONVERSATION_REPORTS: 'FETCH_REPLIED_CONVERSATION_REPORTS',
  FETCH_REPLIED_CONVERSATION_REPORTS_SUCCESS:
    'FETCH_REPLIED_CONVERSATION_REPORTS_SUCCESS',
  FETCH_REPLIED_CONVERSATION_REPORTS_FAILURE:
    'FETCH_REPLIED_CONVERSATION_REPORTS_FAILURE',
  FETCH_CUSTOMERS_REACHED_REPORTS: 'FETCH_CUSTOMERS_REACHED_REPORTS',
  FETCH_CUSTOMERS_REACHED_REPORTS_SUCCESS:
    'FETCH_CUSTOMERS_REACHED_REPORTS_SUCCESS',
  FETCH_CUSTOMERS_REACHED_REPORTS_FAILURE:
    'FETCH_CUSTOMERS_REACHED_REPORTS_FAILURE',

  // Responsiveness
  FETCH_MEDIAN_FIRST_RESPONSE_REPORTS: 'FETCH_MEDIAN_FIRST_RESPONSE_REPORTS',
  FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_SUCCESS:
    'FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_SUCCESS',
  FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_FAILURE:
    'FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_FAILURE',
  FETCH_MEDIAN_CLOSE_REPORTS: 'FETCH_MEDIAN_CLOSE_REPORTS',
  FETCH_MEDIAN_CLOSE_REPORTS_SUCCESS: 'FETCH_MEDIAN_CLOSE_REPORTS_SUCCESS',
  FETCH_MEDIAN_CLOSE_REPORTS_FAILURE: 'FETCH_MEDIAN_CLOSE_REPORTS_FAILURE',

  // Team Performance
  FETCH_TEAMS_AND_TEAMMATES_LIST: 'FETCH_TEAMS_AND_TEAMMATES_LIST',
  FETCH_TEAMS_AND_TEAMMATES_LIST_SUCCESS:
    'FETCH_TEAMS_AND_TEAMMATES_LIST_SUCCESS',
  FETCH_TEAMS_AND_TEAMMATES_LIST_FAILURE:
    'FETCH_TEAMS_AND_TEAMMATES_LIST_FAILURE',

  // Article Feedback
  FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST: 'FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST',
  FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST_SUCCESS:
    'FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST_SUCCESS',
  FETCH_ARTICLE_FEEDBACK_REPORTS: 'FETCH_ARTICLE_FEEDBACK_REPORTS',
  FETCH_ARTICLE_FEEDBACK_REPORTS_SUCCESS:
    'FETCH_ARTICLE_FEEDBACK_REPORTS_SUCCESS',
  FETCH_ARTICLE_FEEDBACK_REPORTS_FAILURE:
    'FETCH_ARTICLE_FEEDBACK_REPORTS_FAILURE',
  UPDATE_ARTICLE_FEEDBACK_REPORT_FILTERS:
    'UPDATE_ARTICLE_FEEDBACK_REPORT_FILTERS',

  // Support Bot
  FETCH_SUPPORT_BOT_METRICS_REPORTS: 'FETCH_SUPPORT_BOT_METRICS_REPORTS',
  FETCH_SUPPORT_BOT_METRICS_REPORTS_SUCCESS:
    'FETCH_SUPPORT_BOT_METRICS_REPORTS_SUCCESS',
  FETCH_SUPPORT_BOT_METRICS_REPORTS_FAILURE:
    'FETCH_SUPPORT_BOT_METRICS_REPORTS_FAILURE',
  FETCH_SUPPORT_BOT_COVERAGE_REPORTS: 'FETCH_SUPPORT_BOT_COVERAGE_REPORTS',
  FETCH_SUPPORT_BOT_COVERAGE_REPORTS_SUCCESS:
    'FETCH_SUPPORT_BOT_COVERAGE_REPORTS_SUCCESS',
  FETCH_SUPPORT_BOT_COVERAGE_REPORTS_FAILURE:
    'FETCH_SUPPORT_BOT_COVERAGE_REPORTS_FAILURE',
  FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS:
    'FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS',
  FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_SUCCESS:
    'FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_SUCCESS',
  FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_FAILURE:
    'FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_FAILURE',
  FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS:
    'FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS',
  FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_SUCCESS:
    'FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_SUCCESS',
  FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_FAILURE:
    'FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_FAILURE',

  // Email Health
  FETCH_EMAIL_HEALTH_REPORTS: 'FETCH_EMAIL_HEALTH_REPORTS',
  FETCH_EMAIL_HEALTH_REPORTS_SUCCESS: 'FETCH_EMAIL_HEALTH_REPORTS_SUCCESS',
  FETCH_EMAIL_HEALTH_REPORTS_FAILURE: 'FETCH_EMAIL_HEALTH_REPORTS_FAILURE',
  FETCH_EMAIL_METRICS_REPORTS: 'FETCH_EMAIL_METRICS_REPORTS',
  FETCH_EMAIL_METRICS_REPORTS_SUCCESS: 'FETCH_EMAIL_METRICS_REPORTS_SUCCESS',
  FETCH_EMAIL_METRICS_REPORTS_FAILURE: 'FETCH_EMAIL_METRICS_REPORTS_FAILURE',

  FETCH_SATISFACTION_RATINGS_METRICS: 'FETCH_SATISFACTION_RATINGS_METRICS',
  FETCH_SATISFACTION_RATINGS_METRICS_SUCCESS:
    'FETCH_SATISFACTION_RATINGS_METRICS_SUCCESS',
  FETCH_SATISFACTION_RATINGS_METRICS_FAILURE:
    'FETCH_SATISFACTION_RATINGS_METRICS_FAILURE',
  FETCH_SATISFACTION_RATINGS_COUNT: 'FETCH_SATISFACTION_RATINGS_COUNT',
  FETCH_SATISFACTION_RATINGS_COUNT_SUCCESS:
    'FETCH_SATISFACTION_RATINGS_COUNT_SUCCESS',
  UPDATE_SATISFACTION_RATING_REPORTS_FILTERS:
    'UPDATE_SATISFACTION_RATING_REPORTS_FILTERS',
};

export default actions;
