import actions from './actions';
import { cloneDeep } from 'lodash';

export const initState = {
  companiesList: [],
  companiesListLoader: false,
  companiesDrawerStatus: false,
  createLoader: false,
  searchText: '',
  companyInfo: null,
  existingCompanyInfo: null,
  companyInfoLoader: false,
  pageView: 1,
};

export default function companiesReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_COMPANIES_LIST:
      return {
        ...state,
        companiesListLoader: true,
      };
    case actions.GET_COMPANIES_LIST_SUCCESS:
      let list = action.payload;
      if (action.isAppend) {
        list = [...state.companiesList, ...action.payload];
      }
      return {
        ...state,
        companiesList: list,
        companiesListLoader: false,
        pageView: action.isAppend ? action.pageView : state.pageView,
      };
    case actions.GET_COMPANIES_LIST_FAILURE:
      return {
        ...state,
        companiesListLoader: false,
      };
    case actions.COMPANY_DRAWER_STATUS:
      return {
        ...state,
        companiesDrawerStatus: action.payload,
        companyInfoLoader: false,
        companyInfo: null,
        existingCompanyInfo: null,
      };
    case actions.CREATE_COMPANY:
      return {
        ...state,
        createLoader: true,
      };
    case actions.CREATE_COMPANY_SUCCESS:
      let companyList = cloneDeep(state.companiesList);
      companyList.unshift(action.payload);
      return {
        ...state,
        createLoader: false,
        companiesDrawerStatus: false,
        companiesList: companyList,
      };
    case actions.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        createLoader: false,
      };
    case actions.SET_COMPANIES_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
        pageView: 1,
      };
    case actions.GET_COMPANY_INFO:
      return {
        ...state,
        companyInfoLoader: true,
      };
    case actions.GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: action.payload.company_datum,
        existingCompanyInfo:
          Object.keys(action.payload.existing_company).length === 0
            ? null
            : action.payload.existing_company,
        companyInfoLoader: false,
      };
    case actions.GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        companyInfoLoader: false,
      };
    case actions.RESET_PAGE_VIEW:
      return {
        ...state,
        pageView: 1,
      };
    default:
      return state;
  }
}
