import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Image from '../../image/rob.png';
import './style.scss';
import { Button } from 'antd';
import { fetchProjectSecretKey } from '../../helpers/utility';

class NotFound extends Component {
  constructor(props) {
    super(props);
    localStorage.removeItem('referrerPath');
  }
  render() {
    return (
      <div className="iso404Page">
        <div className="iso404Content">
          <h1>404</h1>
          <h3>Looks like you got lost</h3>
          <p>The page you are looking for doesn’t exist or has been moved.</p>
          <Button type="primary">
            {fetchProjectSecretKey() ? (
              <NavLink to={`/projects/${fetchProjectSecretKey()}/contacts`}>
                BACK HOME
              </NavLink>
            ) : (
              <a href={'/'}>BACK HOME</a>
            )}
          </Button>
        </div>
        <div className="iso404Artwork">
          <img alt="#" src={Image} />
        </div>
      </div>
    );
  }
}

export default NotFound;
