export const dateFormatWithTime = 'lll';

export const tableColumnSortOrder = [];
tableColumnSortOrder['descend'] = 'desc';
tableColumnSortOrder['ascend'] = 'asc';

export const triggerListIcon = {
  campaign: 'fas fa-megaphone',
  form: 'fas fa-pen',
  email: 'fas fa-envelope',
  events: 'fas fa-browser',
  eventsDropdown: 'fas fa-hand-pointer',
  tag: 'fas fa-tag',
  contact: 'fas fa-user',
  chat: 'fas fa-comment-lines',
  segment: 'fas fa-users',
  orders: 'fas fa-dollar-sign',
  date: 'far fa-calendar-days',
  meeting: 'far fa-calendar-days',
  viaAnAPI: 'fas fa-link',
  tours: 'fas fa-arrow-progress',
  emailReceived: 'fas fa-inbox-in',
  cancelMeeting: 'fas fa-calendar-xmark',
  rescheduledMeeting: 'fas fa-calendar-clock',
  chatMessage: 'fas fa-comment-lines',
  postMessage: 'fas fa-file-lines',
  surveys: 'fas fa-ballot',
  bots: 'fas fa-robot',
  workflow: 'fas fa-list-tree',
  forms: 'fas fa-chart-simple-horizontal',
  deals: 'fas fa-suitcase',
};

export const actionListIcon = {
  delay: { icon: 'fas fa-clock', color: '#ff657f' },
  fork: {
    icon: 'fas fa-arrows-up-to-line',
    color: '#e150b5',
  },
  goto: { icon: 'fal fa-arrow-right', color: '#f2a137' },
  goal: { icon: 'far fa-circle-dot', color: '#48ebab' },
  exit: { icon: 'far fa-right-from-bracket', color: '#848687' },
  enroll_into_workflow: { icon: 'far fa-network-wired', color: '#1bcf7b' },
  remove_from_workflow: { icon: 'far fa-network-wired', color: '#1bcf7b' },
  decision: { icon: 'fas fa-split', color: '#f2cd34' },
  send_an_email: { icon: 'fas fa-envelope', color: '#7a6ad1' },
  send_notification_mail: { icon: 'fas fa-envelope', color: '#7a6ad1' },
  apply_tag: { icon: 'fas fa-tag', color: '#b77ce4' },
  remove_tag: { icon: 'fas fa-tag', color: '#b77ce4' },
  set_contact_property: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  perform_math: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  delete_contact: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  unsubscribe_contact: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  assign_owner: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  unassign_owner: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  track_custom_event: { icon: 'fas fa-flag-pennant', color: '#00a4bd' },
  subscribe_to_campaign: { icon: 'fas fa-megaphone', color: '#f37b2b' },
  move_from_one_campaign_to_another: {
    icon: 'fas fa-megaphone',
    color: '#f37b2b',
  },
  restart_campaign: { icon: 'fas fa-megaphone', color: '#f37b2b' },
  trigger_webhook: { icon: 'fas fa-link', color: '#ffac59' },
  create_deal: { icon: 'fas fa-handshake', color: '#ffac59' },
  change_deal_stage: { icon: 'fas fa-handshake', color: '#ffac59' },
  add_to_fb_audience_list: { icon: 'fab fa-facebook', color: '#37a1f6' },
  remove_from_fb_audience_list: {
    icon: 'fab fa-facebook',
    color: '#37a1f6',
  },
  send_chat_message: {
    icon: 'fas fa-comment-lines',
    color: '#7a6ad1',
  },
  send_announcement_message: {
    icon: 'fas fa-file-lines',
    color: '#7a6ad1',
  },
};

export const primaryColor = '#37a1f6';

export const componentList = [
  {
    key: 'action',
    name: 'Action',
    description:
      'Perform actions such as send emails, update contact information or subscribe to campaigns etc.',
    icon: 'fas fa-bolt',
    color: '#37a1f6',
  },
  {
    key: 'decision',
    name: 'Decision',
    description: 'Split the path based on set segment conditions.',
    icon: 'fas fa-split',
    color: '#f2cd34',
  },
  {
    key: 'fork',
    name: 'Parallel Paths',
    description:
      'Fork the path into two parallel paths to perform several actions at once.',
    icon: 'fas fa-arrows-up-to-line',
    color: '#e150b5',
  },
  {
    key: 'goal',
    name: 'Goal',
    description:
      'Define a goal that will pull contacts to this point in the workflow when conditions are met.',
    icon: 'far fa-circle-dot',
    color: '#48ebab',
  },
  {
    key: 'delay',
    name: 'Delay',
    description:
      'Wait for the specified time or wait until specified conditions met before proceeding the next step.',
    icon: 'fas fa-clock',
    color: '#ff657f',
  },
  {
    key: 'goto',
    name: 'Go to action',
    description:
      'Move contacts from one point to another point of the workflow.',
    icon: 'fal fa-arrow-right',
    color: '#f2a137',
  },
  {
    key: 'exit',
    name: 'Exit',
    description: 'End the automation at this point.',
    icon: 'far fa-right-from-bracket',
    color: '#848687',
  },
];

export const triggerList = {
  campaign: ['subscribed_to_campaign', 'completed_campaign', 'exited_campaign'],
  email: [
    'clicked_link_from_email',
    'email_opened',
    'email_goal_reached',
    'email_bounced',
  ],
  tag: ['applied_tag', 'removed_tag'],
  form: ['submitted_form'],
  events: ['page_visited'],
  eventsDropdown: ['performed_event'],
  chat: ['chat_submitted_email'],
  contact: ['contact_property_updated', 'unsubscribed_contact'],
  segment: ['matches_custom_audience'],
  workflowTemplate: [
    'subscribed_to_campaign',
    'completed_campaign',
    'exited_campaign',
    'clicked_link_from_email',
    'email_opened',
    'unsubscribed_contact',
  ],
  orders: ['made_a_purchase', 'abandoned_a_cart'],
  date: ['trigger_on_particular_day'],
  meeting: ['booked_meeting'],
  viaAnAPI: ['webhook_listener'],
  tours: [
    'tour_viewed',
    'tour_started',
    'tour_completed',
    'tour_closed',
    'tour_errored',
    'tour_goal_reached',
  ],
  emailReceived: ['email_received'],
  cancelMeeting: ['cancelled_meeting'],
  rescheduledMeeting: ['rescheduled_meeting'],
  chatMessage: [
    'chat_message_sent',
    'chat_message_opened',
    'chat_message_replied_to',
    'chat_message_goal_reached',
  ],
  postMessage: [
    'post_message_sent',
    'post_message_opened',
    'post_message_replied_to',
    'post_message_goal_reached',
  ],
  surveys: [
    'survey_seen',
    'survey_started',
    'survey_completed',
    'survey_goal_reached',
  ],
  bots: ['bot_seen', 'bot_engaged', 'bot_completed', 'bot_goal_reached'],
  forms: [
    'form_seen',
    'form_started',
    'form_completed',
    'form_dismissed',
    'form_goal_reached',
  ],
  workflow: ['workflow_started', 'workflow_completed', 'workflow_goal_reached'],
  deals: [
    'deal_created',
    'deal_stage_changed',
    'deal_pipeline_changed',
    'deal_properties_updated',
  ],
};

export const fieldName = {
  campaign: 'campaign_id',
  form: 'form_id',
  email: 'mail_filter_id',
  events: 'page_detail',
  eventsDropdown: 'event_id',
  tag: 'tag_id',
  contact: null,
  chat: null,
  workflow: 'workflow_id',
  fb_ad_account_id: 'fb_ad_account_id',
  audience_list_id: 'audience_list_id',
  targetUrl: 'target_url',
  secretToken: 'secret_token',
};

export const emailTypeList = [
  { key: 'any_email', display_name: 'Any Email' },
  { key: 'auto', display_name: 'Ongoing Emails' },
  { key: 'manual', display_name: 'One-off Emails' },
  { key: 'campaign', display_name: 'Campaign Emails' },
  { key: 'automation', display_name: 'Automation Emails' },
];

export const actionList = {
  campaign: [
    'subscribe_to_campaign',
    'move_from_one_campaign_to_another',
    'restart_campaign',
  ],
  email: [
    'send_broadcast_mail',
    'send_notification_mail',
    'send_chat_message',
    'send_announcement_message',
  ],
  tag: ['apply_tag', 'remove_tag'],
  contact: [
    'set_contact_property',
    'delete_contact',
    'unsubscribe_contact',
    'perform_math',
    'assign_owner',
    'unassign_owner',
    'track_custom_event',
  ],
  workflow: ['enroll_into_workflow', 'remove_from_workflow'],
  integrations: ['add_to_fb_audience_list', 'remove_from_fb_audience_list'],
  webhook: ['trigger_webhook'],
  deal: ['create_deal'],
  hubspot: [
    'create_hubspot_lead',
    'create_hubspot_deal',
    'change_hubspot_deal_stage',
    'create_hubspot_task',
  ],
};

export const allEmailType = [
  { id: 'all_mailings', display_name: 'From all emails' },
  { id: 'campaign', display_name: 'From a specific campaign' },
];

export const toolbarButtons = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'subscript',
  'superscript',
  'fontFamily',
  'fontSize',
  'textColor',
  'backgroundColor',
  'color',
  'paragraphFormat',
  'lineHeight',
  'align',
  'formatOLSimple',
  'formatUL',
  'outdent',
  'indent',
  'leftToRight',
  'rightToLeft',
  'insertLink',
  'insertImage',
  'personalize',
  'insertHR',
  'insertButton',
  'selectAll',
  'clearFormatting',
  'undo',
  'redo',
  'html',
  'fullscreen',
];

export const pluginsEnabled = [
  'image',
  'link',
  'codeView',
  'codeBeautifier',
  'emoticons',
  'align',
  'colors',
  'fontFamily',
  'fontSize',
  'fullscreen',
  'lineBreaker',
  'lists',
  'save',
  'quote',
  'paragraphFormat',
  'video',
  'table',
  'lineHeight',
];

export const actionLists = {
  send_an_email: 'Send an email',
  send_chat_message: 'Send a chat',
  send_announcement_message: 'Send a post',
  send_notification_mail: 'Notify a teammate',
  apply_tag: 'Add a tag',
  remove_tag: 'Remove a tag',
  subscribe_to_campaign: 'Subscribe to a campaign',
  move_from_one_campaign_to_another: 'Move from one campaign to another',
  restart_campaign: 'Restart a campaign',
  enroll_into_workflow: 'Enroll into another workflow',
  remove_from_workflow: 'Remove from another workflow',
  set_contact_property: 'Set a contact property value',
  perform_math: 'Perform math',
  delete_contact: 'Delete the contact',
  unsubscribe_contact: 'Unsubscribe the contact',
  assign_owner: 'Assign a contact owner',
  unassign_owner: 'Unassign contact owner',
  track_custom_event: 'Track a custom event',
  add_to_fb_audience_list: 'Add to Facebook Custom Audience',
  remove_from_fb_audience_list: 'Remove from Facebook Custom Audience',
  trigger_webhook: 'Trigger a webhook',
  create_deal: 'Create a deal',
  create_hubspot_lead: 'Create a contact in Hubspot as lead',
  create_hubspot_deal: 'Create a deal in Hubspot',
  change_hubspot_deal_stage:
    'Change deal stage through the pipeline in Hubspot',
  create_hubspot_task: 'Create a task in Hubspot',
  change_deal_stage: 'Change deal stage',
  tour_viewed: 'Viewed a tour',
  tour_started: 'Started a tour',
  tour_completed: 'Completed a tour',
  tour_closed: 'Dismissed a tour',
  tour_errored: 'Issue occurred in a tour',
  tour_goal_reached: 'Tour goal reached',
};

export const triggerLists = {
  matches_custom_audience: 'Matched a segment',
  subscribed_to_campaign: 'Subscribed to a campaign',
  exited_campaign: 'Exited from a campaign"',
  completed_campaign: 'Completed a campaign',
  submitted_form: 'Submitted a form',
  email_opened: 'Opens/reads an email',
  clicked_link_from_email: 'Clicked a link from email',
  page_visited: 'Visited a page',
  performed_event: 'Performed an event',
  applied_tag: 'Added a tag',
  removed_tag: 'Removed a tag',
  contact_property_updated: 'Updated a contact property',
  unsubscribed_contact: 'Unsubscribed a contact',
  made_a_purchase: 'Made a purchase',
  abandoned_a_cart: 'Abandoned a cart',
  chat_submitted_email: 'Submitted email in chat',
  trigger_on_particular_day: 'Trigger on a particular day',
  booked_meeting: 'Booked a meeting',
  email_received: 'Received an email',
  email_bounced: 'Email bounced',
  email_goal_reached: 'Email goal reached',
  rescheduled_meeting: 'Reschedule meeting',
  cancelled_meeting: 'Cancelled meeting',
};

export const removeTriggerProperty = [
  'ip_city',
  'ip_country',
  'ip_country_code',
  'ip_time_zone',
  'subscribed_to',
  'first_page_seen',
  'first_seen',
  'first_referring_site',
  'first_conversion',
  'first_conversion_date',
  'contact_owner',
];
export const removeActionProperty = ['tags', 'contact_owner'];

export const triggerConfig = [
  { key: 'at_specific_date', value: 'Only once, on this date' },
  { key: 'every_month', value: 'Every month' },
  { key: 'every_year', value: 'Every year' },
];

export const conditions = [
  { key: 'on', value: 'on' },
  { key: 'before', value: 'before' },
  { key: 'after', value: 'after' },
];

export const hubspotColor = '#ff795a';

export const hubspotKeys = [
  'create_hubspot_lead',
  'create_hubspot_deal',
  'change_hubspot_deal_stage',
  'create_hubspot_task',
];

export const decisionType = {
  tagPresent: 'tag_present',
  pageVisited: 'page_visited',
  emailActivity: 'email_activity',
  dealStage: 'deal_stage',
  bookedMeeting: 'booked_meeting',
  postMessageActivity: 'post_message_activity',
  chatMessageActivity: 'chat_message_activity',
  surveyStatus: 'survey_status',
  tourStatus: 'tour_activity',
  formStatus: 'form_activity',
  workflowStatus: 'workflow_activity',
  botStatus: 'bot_activity',
  dealActivity: 'deal_activity',
};

export const emailCondition = [
  { name: 'Sent', value: 'sent' },
  { name: 'Delivered', value: 'delivered' },
  { name: 'Opened', value: 'opened' },
  { name: 'Clicked', value: 'clicked' },
  { name: 'Bounced', value: 'bounced' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const messageActivityConditions = [
  { name: 'Sent', value: 'sent' },
  { name: 'Opened', value: 'opened' },
  { name: 'Replied To', value: 'replied_to' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const SurveyStatusConditions = [
  { name: 'Seen', value: 'seen' },
  { name: 'Started', value: 'started' },
  { name: 'Completed', value: 'completed' },
  { name: 'Dismissed', value: 'dismissed' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const TourStatusCondition = [
  { name: 'Seen', value: 'seen' },
  { name: 'Started', value: 'started' },
  { name: 'Completed', value: 'completed' },
  { name: 'Dismissed', value: 'dismissed' },
  { name: 'Encountered failure', value: 'encountered_failure' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const FormStatusCondition = [
  { name: 'Seen', value: 'seen' },
  { name: 'Started', value: 'started' },
  { name: 'Completed', value: 'completed' },
  { name: 'Dismissed', value: 'dismissed' },
  { name: 'Goal reached', value: 'goal_reached' },
  { name: 'Email captured', value: 'email_captured' },
];

export const WorkflowStatusCondition = [
  { name: 'Started', value: 'started' },
  { name: 'Completed', value: 'completed' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const OutboundBotStatusCondition = [
  { name: 'Seen', value: 'seen' },
  { name: 'Engaged', value: 'engaged' },
  { name: 'Completed', value: 'completed' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const NewConversationBotStatusCondition = [
  { name: 'Engaged', value: 'engaged' },
  { name: 'Completed', value: 'completed' },
  { name: 'Goal reached', value: 'goal_reached' },
];

export const commonDealCondition = [
  { name: 'Any of', value: 'any_of' },
  { name: 'None of', value: 'none_of' },
];
export const dealAmountCondition = [
  { name: 'is equal to', value: 'equal_to' },
  { name: 'is greater than', value: 'greater_than' },
  { name: 'is less than', value: 'less_than' },
];

export const dealDateCondition = [
  {
    name: 'is in the last x days',
    value: 'greater_than',
  },
  {
    name: 'is at least x days ago',
    value: 'less_than',
  },
  {
    name: 'is in the next x days',
    value: 'less_than_future',
  },
  {
    name: 'is at least after x days',
    value: 'greater_than_future',
  },
  {
    name: 'is exactly x days ago',
    value: 'equals_date',
  },
  {
    name: 'is today',
    value: 'today',
  },
];

export const messagesTypeList = [
  { key: 'on_going', display_name: 'Ongoing ' },
  { key: 'one_off', display_name: 'One-off ' },
  { key: 'workflow_one_off', display_name: 'Automation ' },
];

export const decisionComponent = {
  tag_present: { name: 'Has a contact tag', description: 'contact has a tag' },
  page_visited: {
    name: 'Has visited a page',
    description: 'contact has visited a page',
  },
  email_activity: {
    name: 'Check email activity',
    description: 'email activity',
  },
  post_message_activity: {
    name: 'Check post message activity',
    description: 'post activity',
  },
  chat_message_activity: {
    name: 'Check chat message activity',
    description: 'chat activity',
  },
  survey_status: {
    name: 'Check survey activity',
    description: 'survey activity',
  },
  deal_stage: { name: 'Check Deal activity', description: 'deal activity' },
  deal_activity: { name: 'Check Deal activity', description: 'deal activity' },
  booked_meeting: { name: 'Booked a meeting', description: "meeting's booked" },
  tour_activity: {
    name: 'Check tour activity',
    description: 'Tour activity',
  },
  form_activity: {
    name: 'Check form activity',
    description: 'Form activity',
  },
  workflow_activity: {
    name: 'Check workflow activity',
    description: 'Workflow activity',
  },
  default: {
    name: 'Matches a segment',
    description: 'set segment conditions.',
  },
};

export const particularDateSelector = [
  { key: 'absolute_date', value: 'a specific date' },
  { key: 'property', value: 'a property' },
];

export const particularDateFormat = 'DD-MM-YYYY';

export const webHooksHttpMethods = [
  { key: 'post', value: 'POST' },
  { key: 'put', value: 'PUT' },
  { key: 'patch', value: 'PATCH' },
];

export const rangeItems = ['between_date'];

export const dealCreation = [
  { name: 'Pipeline', value: 'pipeline' },
  { name: 'Stage', value: 'stage' },
];
export const dealProperties = [
  { name: 'Owner', value: 'owner' },
  { name: 'Amount', value: 'amount' },
];
export const workflowReportsFilters = [
  { label: 'All', key: 'all' },
  { label: 'In progress', key: 'inprogress' },
  { label: 'Completed', key: 'completed' },
  { label: 'Removed', key: 'removed' },
];
export const componentsReportsFilters = [
  { label: 'All', key: 'all' },
  { label: 'Started', key: 'started' },
  { label: 'In progress', key: 'inprogress' },
  { label: 'Completed', key: 'completed' },
  { label: 'Skipped', key: 'skipped' },
];

export const workflowStatusSlugToDisplayText = {
  inprogress: 'In Progress',
  completed: 'Completed',
  started: 'Started',
  removed: 'Removed',
  skipped: 'Skipped',
};

export const headerData = [
  'Contact',
  'Enrolled at',
  'Completed at',
  'Status',
];
