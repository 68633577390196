import React, { Component } from 'react';
import './style.scss';
import { onPlanChange } from '../../helpers/utility';

class NotAuthorised extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { bgWhite, type, content, moduleFrom } = this.props;
    if (type === 'not-allowed') {
      return (
        <div className={`not-authorised-layout ${bgWhite ? 'white' : ''}`}>
          <div className="content">
            <div>
              <p>
                To view the {content || 'inbox'} {moduleFrom || 'reports'},{' '}
                <span className="link" onClick={onPlanChange}>
                  upgrade to a higher tier plan
                </span>
              </p>
            </div>
          </div>
        </div>
      );
    } else if (type === 'user-restriction') {
      return (
        <div className={`not-authorised-layout ${bgWhite ? 'white' : ''}`}>
          <div className="content">
            <div>
              <h1>This feature is restricted</h1>
              <p>Ask one of your teammates to give you permission.</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`not-authorised-layout ${bgWhite ? 'white' : ''}`}>
          <div className="content">
            <div>
              <h1>You are not allowed to access this feature</h1>
              <p>Please contact the workspace owner for access.</p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default NotAuthorised;
