import React from 'react';
import '../style.scss';

const TextAreaComponent = props => {
  const { component, onChange } = props;

  return (
    <div
      className={`textarea-container${component.disabled ? ' disabled' : ''}${
        component.error ? ' error' : ''
      }`}
    >
      <label className="textarea-label">{component.label}</label>
      <div className="input-container">
        <textarea
          {...(component.id ? { 'data-component-id': component.id } : {})}
          {...(component.disabled ? { disabled: true } : {})}
          {...(component.placeholder
            ? { placeholder: component.placeholder }
            : {})}
          {...(component.value ? { defaultValue: component.value } : {})}
          onChange={event => {
            onChange(component.id, event.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default TextAreaComponent;
