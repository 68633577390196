import React from 'react';
import { openLinkInNewTab } from '../../../../../helpers/utility';
import '../style.scss';

const ButtonComponent = ({ onSubmit, component, canvasLoading }) => {
  const handleClick = componentId => {
    if (componentId) {
      onSubmit(componentId);
    }
  };
  const isLoading = canvasLoading && canvasLoading[component.id];
  return (
    <button
      className={`${component.style}${component.disabled ? ' disabled' : ''}${
        isLoading ? ' loading' : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
      {...(component.action && component.action.type
        ? { 'data-action-type': component.action.type }
        : {})}
      {...(component.action && component.action.url
        ? { 'data-action-url': component.action.url }
        : {})}
      {...(component.action &&
      component.action.type === 'url' &&
      component.action.url
        ? {
            onClick: () => {
              openLinkInNewTab(component.action && component.action.url);
            },
          }
        : {})}
      {...(component.action && component.action.type === 'submit'
        ? {
            onClick: () => {
              handleClick(component.id);
            },
          }
        : {})}
    >
      {component.label}
      {isLoading && <div className="messenger-card-loader" />}
    </button>
  );
};

export default ButtonComponent;
