import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
} from '../../helpers/utility';
import settings from '../../settings';
import actions from './actions';
import { message } from 'antd';
import { getTableValues } from '../../components/Messages/Post/Shared/Helper';
import { store } from '../store';
import chatOnGoingActions from './onGoing/actions';
import chatOneOffActions from './oneOff/actions';
import messagesActions from '../messages/actions';

export function* fetchTableLists(params) {
  try {
    let searchValue = '';
    let sortKey = '';
    let sortOrder = '';
    let tagID = '';
    let status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = getTableValues().searchValue;
    }
    if (params && params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = getTableValues().sortKey;
      sortOrder = getTableValues().sortOrder;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = store.getState().chatOngoingPost.chatOnGoingTagId;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().chatOngoingPost.chatOnGoingStatus;
    }
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }?status=${status}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&message_type=${
      getTableValues().messageType
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TABLE_LISTS_SUCCESS_IN_POST,
      payload: data.data,
      tableState: getTableValues().tableState,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_TABLE_LISTS_FAILURE_IN_POST,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch ongoing messages failed');
    }
  }
}

export function* appendTableLists(params) {
  try {
    let searchValue = '';
    let sortKey = '';
    let sortOrder = '';
    let tagID = '';
    let status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = getTableValues().searchValue;
    }
    if (params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = getTableValues().sortKey;
      sortOrder = getTableValues().sortOrder;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = store.getState().chatOngoingPost.chatOnGoingTagId;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().chatOngoingPost.chatOnGoingStatus;
    }
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }?status=${status}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&message_type=${
      getTableValues().messageType
    }&${getTableValues().lastId}=${params.payload.lastId}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_TABLE_LISTS_SUCCESS_IN_POST,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.APPEND_TABLE_LISTS_FAILURE_IN_POST });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch ongoing messages failed');
    }
  }
}

export function* deleteTableData(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }/${params.id}`;
    let data = yield call(() => deletePromise(url));
    if (params.history) {
      params.history.push({
        pathname: `/projects/${fetchProjectSecretKey()}/messages/post`,
      });
    } else {
      if (getTableValues().messageType === 'on_going_announcement') {
        yield put({
          type: actions.FETCH_TABLE_LISTS_IN_POST,
        });
      } else if (getTableValues().messageType === 'one_off_announcement') {
        yield put({
          type: chatOneOffActions.FETCH_CHAT_ONEOFF_LIST_IN_POST,
        });
      }
    }
    if (
      params.requestFrom === 'on_going_announcement' ||
      getTableValues().messageType === 'on_going_announcement'
    ) {
      yield put({
        type: actions.REMOVE_DELETED_ON_GOING_CHAT_ID_IN_POST,
        deletedChatId: params.id,
      });
    } else if (
      params.requestFrom === 'one_off_announcement' ||
      getTableValues().messageType === 'one_off_announcement'
    ) {
      yield put({
        type: chatOneOffActions.REMOVE_DELETED_ONE_OFF_CHAT_ID_IN_POST,
        deletedChatId: params.id,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error(`Delete ${getTableValues().tableState} failed`);
    }
  }
}

export function* applyChatTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/apply_tag`;
    const responseData = yield call(() =>
      postPromise(url, params.payload.data),
    );
    if (params.payload.requestFrom === 'on_going_announcement') {
      yield put({
        type: actions.FETCH_TABLE_LISTS_IN_POST,
      });
    } else if (params.payload.requestFrom === 'one_off_announcement') {
      yield put({
        type: chatOneOffActions.FETCH_CHAT_ONEOFF_LIST_IN_POST,
      });
    }
    yield put({
      type: actions.APPLY_CHAT_TAG_SUCCESS_IN_POST,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Email tags apply failed');
    }
  }
}

export function* removeChatTags(param) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/remove_tag?feature_type=TriggeredChat&feature_id=${
      param.payload.id
    }&tag_id=${param.payload.tagId}`;
    const responseData = yield call(() => postPromise(url));
    if (param.payload.requestFrom === 'on_going_announcement') {
      yield put({
        type: actions.FETCH_TABLE_LISTS_IN_POST,
      });
    } else if (param.payload.requestFrom === 'one_off_announcement') {
      yield put({
        type: chatOneOffActions.FETCH_CHAT_ONEOFF_LIST_IN_POST,
      });
    }
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('remove email tags failed');
    }
  }
}

export function* createMessagesChat(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }?message_template_id=${params.selectedTemplateId || ''}`;
    const data = yield call(() => postPromise(url, params.data));
    trackEvent('Created post message');
    yield put({ type: actions.CREATE_MESSAGES_CHAT_SUCCESS_IN_POST });
    if (params.isFromContact) {
      yield put({
        type: messagesActions.SET_SELECT_CHANNEL_MODAL_STATUS,
        payload: false,
      });
    }
    updateBrowserHistory(`messages/post/${data.data.triggered_chat.id}`, {
      chat_id: data.data.triggered_chat.id,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.CREATE_MESSAGES_CHAT_FAILURE_IN_POST });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Create ${getTableValues().tableState} failed`);
    }
  }
}

export function* updateMessageChat(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }/${params.id}`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({ type: actions.SET_POST_CHANGE, payload: false });
    if (params.table) {
      if (getTableValues().messageType === 'on_going_announcement') {
        yield put({
          type: actions.FETCH_TABLE_LISTS_IN_POST,
        });
      } else {
        yield put({
          type: chatOneOffActions.FETCH_CHAT_ONEOFF_LIST_IN_POST,
        });
      }
    } else {
      yield put({
        type: chatOnGoingActions.GET_CHAT_PROMPT_DETAILS_IN_POST,
        id: params.id,
      });
      params.history.push({
        pathname: `/projects/${fetchProjectSecretKey()}/messages/post`,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_POST,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Update ${getTableValues().tableState} failed`);
    }
  }
}

export function* updateChatMessage(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }/${params.id}`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({ type: actions.SET_POST_CHANGE, payload: false });
    const {
      template,
      reply_type,
    } = store.getState().chatOngoingPost.chatPromptDetails;
    yield put({
      type: chatOnGoingActions.GET_CHAT_PROMPT_DETAILS_SUCCESS_IN_POST,
      payload: { ...data.data.triggered_chat, template, reply_type },
    });
    if (data.data.triggered_chat.new_status === 'live') {
      message.success(data.message);
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update chat failed');
    }
  }
}

export function* duplicateMessageChat(params) {
  try {
    yield put({
      type: chatOnGoingActions.SET_MESSAGE_CHAT_LOADER_IN_POST,
      payload: true,
    });
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }/${params.duplicateId}/duplicate`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({ type: actions.DUPLICATE_MESSAGE_CHAT_SUCCESS_IN_POST });
    yield put({
      type: chatOnGoingActions.GET_CHAT_PROMPT_DETAILS_SUCCESS_IN_POST,
      payload: data.data.triggered_chat,
      chatTabKey: '1',
    });
    yield put({
      type: chatOnGoingActions.SET_MESSAGE_CHAT_LOADER_IN_POST,
      payload: false,
    });
    updateBrowserHistory(`messages/post/${data.data.triggered_chat.id}`);
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.DUPLICATE_MESSAGE_CHAT_FAILURE_IN_POST });
    yield put({
      type: chatOnGoingActions.SET_MESSAGE_CHAT_LOADER_IN_POST,
      payload: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Duplicate ${getTableValues().tableState} failed`);
    }
  }
}

export function* changeTableElementPosition(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats/${
      params.id
    }/update_priority`;
    const data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(`${getTableValues().tableState} priority update failed`);
  }
}

export function* cancelChatSchedule(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/${
      getTableValues().api
    }/${params.id}/cancel_schedule`;
    const data = yield call(() => postPromise(url));
    yield put({
      type: chatOnGoingActions.GET_CHAT_PROMPT_DETAILS_IN_POST,
      id: params.id,
    });
    message.success(data.message);
    if (params.history) {
      params.history.push({
        pathname: `/projects/${fetchProjectSecretKey()}/messages/post`,
      });
    } else {
      if (getTableValues().messageType === 'on_going_announcement') {
        yield put({ type: actions.FETCH_TABLE_LISTS_IN_POST });
      } else if (getTableValues().messageType === 'one_off_announcement') {
        yield put({ type: chatOneOffActions.FETCH_CHAT_ONEOFF_LIST_IN_POST });
      }
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Chat message schedule cancel failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TABLE_LISTS_IN_POST, fetchTableLists),
    takeEvery(actions.APPEND_TABLE_LISTS_IN_POST, appendTableLists),
    takeEvery(actions.DELETE_TABLE_DATA_IN_POST, deleteTableData),
    takeEvery(actions.APPLY_CHAT_TAG_IN_POST, applyChatTags),
    takeEvery(actions.CREATE_MESSAGES_CHAT_IN_POST, createMessagesChat),
    takeEvery(actions.DUPLICATE_MESSAGE_CHAT_IN_POST, duplicateMessageChat),
    takeEvery(actions.UPDATE_TABLE_ELEMENT_STATUS_IN_POST, updateMessageChat),
    takeEvery(actions.REMOVE_CHAT_TAG_IN_POST, removeChatTags),
    takeEvery(actions.UPDATE_CHAT_MESSAGE_IN_POST, updateChatMessage),
    takeEvery(actions.CANCEL_CHAT_SCHEDULE_IN_POST, cancelChatSchedule),
  ]);
}
