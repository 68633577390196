import actions from './actions';
import { cloneDeep, find, map } from 'lodash';

const initState = {
  currentPipeline: null,
  pipelineList: [],
  stages: [],
  isOpenCreateDealDrawer: false,
  dealDetails: null,
  userID: null,
  stagesList: [],
  pipelineListData: [],
  stagesListLoader: false,
  pipelineListLoader: false,
  searchContactValue: '',
  contacts: [],
  dealsLoader: false,
  createDealBtnLoader: false,
  companyList: [],
  companyListLoader: false,
  defaultPipelineId: null,
  pipelines: [],
};

export default function CRM(state = initState, action) {
  switch (action.type) {
    case actions.DELETE_DEAL_SUCCESS:
      const { stageId, dealId } = action;
      return {
        ...state,
        currentPipeline: {
          ...state.currentPipeline,
          stages: state.currentPipeline.stages.map(
            stage =>
              stage.id === stageId
                ? {
                  ...stage,
                  total_amount: stage.total_amount - stage.deals.find(deal => deal.id === dealId).data.amount,
                  deals: stage.deals.filter(deal => deal.id !== dealId),
                }
                : stage,
          ),
        },
      };

    case actions.UPDATE_DEAL_DETAILS: {
      const {
        isFromDragEnd,
        data: { deal, stages },
      } = action.payload;
      const newStage = stages.find(stage => stage.id === deal.stage_id);
      const oldStage = stages.find(stage => stage.id !== newStage.id);
      return {
        ...state,
        currentPipeline: {
          ...state.currentPipeline,
          stages: state.currentPipeline.stages.map(stage => {
            if (stage.id === newStage.id) {
              return {
                ...stage,
                ...newStage,
                deals:
                  oldStage && !isFromDragEnd
                    ? [...stage.deals, deal]
                    : stage.deals.map(
                      data => (data.id === deal.id ? deal : data),
                    ),
              };
            } else if (oldStage && stage.id === oldStage.id) {
              return {
                ...stage,
                ...oldStage,
                deals: stage.deals.filter(({ id }) => id !== deal.id),
              };
            }
            return stage;
          }),
        },
      };
    }
    case actions.GET_PIPELINES:
      return {
        ...state,
        dealsLoader: true,
      };
    case actions.GET_STAGES:
      return {
        ...state,
        dealsLoader: true,
      };
    case actions.GET_PIPELINES_SUCCESS:
      let defaultPipeline = find(action.payload, { is_default: true });
      const pipelineList = map(action.payload, data => ({
        id: data.id,
        name: data.name,
        is_default: data.is_default,
      }));
      return {
        ...state,
        ...(action.isRestrictRedirect && { dealsLoader: false }),
        defaultPipelineId: defaultPipeline.id,
        currentPipeline: action.currentPipeline,
        pipelineList: pipelineList,
        pipelines: action.payload,
      };
    case actions.GET_PIPELINES_FAILURE:
      return {
        ...state,
        dealsLoader: false,
        pipelines: [],
      };
    case actions.GET_STAGES_SUCCESS:
      return {
        ...state,
        dealsLoader: false,
        currentPipeline: action.payload
          ? action.payload
          : state.currentPipeline,
        stages:
          action.payload && action.payload.stages
            ? action.payload.stages
            : state.stages,
        stagesList:
          action.payload && action.payload.stages
            ? action.payload.stages
            : state.stagesList,
      };
    case actions.GET_STAGES_FAILURE:
      return {
        ...state,
        dealsLoader: false,
      };
    case actions.UPDATE_PIPELINE_DATA:
      return {
        ...state,
        currentPipeline: action.payload,
      };
    case actions.OPEN_CREATE_DEAL_DRAWER:
      return {
        ...state,
        isOpenCreateDealDrawer: action.payload,
      };
    case actions.SET_DEAL_DETAILS:
      return {
        ...state,
        dealDetails: action.payload,
      };
    case actions.SET_DEAL_USER_ID:
      return {
        ...state,
        userID: action.userID,
      };
    case actions.GET_STAGES_LIST:
      return {
        ...state,
        stagesListLoader: true,
      };
    case actions.GET_STAGES_LIST_SUCCESS:
      return {
        ...state,
        stagesList: action.payload,
        stagesListLoader: false,
      };
    case actions.GET_STAGES_LIST_FAILURE:
      return {
        ...state,
        stagesListLoader: false,
      };
    case actions.GET_PIPELINE_LIST:
      return {
        ...state,
        pipelineListLoader: true,
      };
    case actions.GET_PIPELINE_LIST_SUCCESS:
      return {
        ...state,
        pipelineListData: action.payload,
        pipelineListLoader: false,
      };
    case actions.GET_PIPELINE_LIST_FAILURE:
      return {
        ...state,
        pipelineListLoader: false,
      };
    case actions.CREATE_DEAL:
      return {
        ...state,
        createDealBtnLoader: true,
      };
    case actions.CREATE_DEAL_SUCCESS:
      return {
        ...state,
        createDealBtnLoader: false,
      };
    case actions.CREATE_DEAL_FAILURE:
      return {
        ...state,
        createDealBtnLoader: false,
      };

    case actions.UPDATE_DEAL:
      return {
        ...state,
        createDealBtnLoader: true,
      };
    case actions.DEAL_BUTTON_LOADER_STATUS:
      return {
        ...state,
        createDealBtnLoader: action.payload,
      };
    case actions.UPDATE_DEAL_SUCCESS:
      return {
        ...state,
        createDealBtnLoader: false,
      };
    case actions.UPDATE_DEAL_FAILURE:
      return {
        ...state,
        createDealBtnLoader: false,
      };
    case actions.GET_CRM_CONTACT:
      return {
        ...state,
        contactListLoader: true,
        contacts: [],
      };
    case actions.GET_CRM_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload ? action.payload : [],
        contactListLoader: false,
      };
    case actions.GET_CRM_CONTACTS_FAILURE:
      return {
        ...state,
        contactListLoader: false,
      };
    case actions.GET_DEAL_COMPANY_LIST:
      return {
        ...state,
        companyListLoader: true,
      };
    case actions.GET_DEAL_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        companyListLoader: false,
      };
    case actions.GET_DEAL_COMPANY_LIST_FAILURE:
      return {
        ...state,
        companyListLoader: false,
      };
    case actions.UPDATE_DEFAULT_PIPELINE_SUCCESS:
      let pipelineListTemp = cloneDeep(state.pipelineList);

      pipelineListTemp = pipelineListTemp.map((pipeline, index) => {
        if (pipeline.id === state.defaultPipelineId) {
          pipeline.is_default = false;
        }
        if (pipeline.id === action.payload) {
          pipeline.is_default = true;
        }
        return pipeline;
      });
      return {
        ...state,
        defaultPipelineId: action.payload,
        pipelineList: pipelineListTemp,
        currentPipeline: { ...state.currentPipeline, is_default: true },
      };
    default:
      return state;
  }
}
