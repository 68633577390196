import React from 'react';
import '../style.scss';

const SpacerComponent = props => {
  const { component } = props;
  return (
    <div
      className={`spacer-${component.size}`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
    />
  );
};

export default SpacerComponent;
