const actions = {
  GET_COMPANY_DETAILS: 'GET_COMPANY_DETAILS',
  GET_COMPANY_DETAILS_SUCCESS: 'GET_COMPANY_DETAILS_SUCCESS',
  GET_COMPANY_DETAILS_FAILURE: 'GET_COMPANY_DETAILS_FAILURE',
  GET_COMPANY_ACTIVITIES: 'GET_COMPANY_ACTIVITIES',
  GET_COMPANY_ACTIVITIES_SUCCESS: 'GET_COMPANY_ACTIVITIES_SUCCESS',
  GET_COMPANY_ACTIVITIES_FAILURE: 'GET_COMPANY_ACTIVITIES_FAILURE',
  APPEND_COMPANY_ACTIVITIES: 'APPEND_COMPANY_ACTIVITIES',
  APPEND_COMPANY_ACTIVITIES_SUCCESS: 'APPEND_COMPANY_ACTIVITIES_SUCCESS',
  APPEND_COMPANY_ACTIVITIES_FAILURE: 'APPEND_COMPANY_ACTIVITIES_FAILURE',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',
  COMPANY_REMOVE_CONTACT: 'COMPANY_REMOVE_CONTACT',
  COMPANY_REMOVE_CONTACT_SUCCESS: 'COMPANY_REMOVE_CONTACT_SUCCESS',
  COMPANY_REMOVE_CONTACT_FAILURE: 'COMPANY_REMOVE_CONTACT_FAILURE',
  COMPANY_ADD_CONTACT: 'COMPANY_ADD_CONTACT',
  COMPANY_ADD_CONTACT_SUCCESS: 'COMPANY_ADD_CONTACT_SUCCESS',
  COMPANY_ADD_CONTACT_FAILURE: 'COMPANY_ADD_CONTACT_FAILURE',
  COMPANY_CONTACT_DRAWER_STATUS: 'COMPANY_CONTACT_DRAWER_STATUS',
  GET_COMPANY_PROPERTY_LIST: 'GET_COMPANY_PROPERTY_LIST',
  GET_COMPANY_PROPERTY_LIST_SUCCESS: 'GET_COMPANY_PROPERTY_LIST_SUCCESS',
  SET_COMPANY_DRAWER_STATUS: 'SET_COMPANY_DRAWER_STATUS',
  FETCH_COMPANY_NOTES: 'FETCH_COMPANY_NOTES',
  FETCH_COMPANY_NOTES_SUCCESS: 'FETCH_COMPANY_NOTES_SUCCESS',
  FETCH_COMPANY_NOTES_FAILURE: 'FETCH_COMPANY_NOTES_FAILURE',
  ACTIVATE_COMPANY_NOTES_LOADER: 'ACTIVATE_COMPANY_NOTES_LOADER',
  CREATE_COMPANY_NOTES: 'CREATE_COMPANY_NOTES',
  CREATE_COMPANY_NOTES_SUCCESS: 'CREATE_COMPANY_NOTES_SUCCESS',
  CREATE_COMPANY_NOTES_FAILURE: 'CREATE_COMPANY_NOTES_FAILURE',
  EDIT_COMPANY_NOTES: 'EDIT_COMPANY_NOTES',
  EDIT_COMPANY_NOTES_SUCCESS: 'EDIT_COMPANY_NOTES_SUCCESS',
  DELETE_COMPANY_NOTES: 'DELETE_COMPANY_NOTES',
  DELETE_COMPANY_NOTES_SUCCESS: 'DELETE_COMPANY_NOTES_SUCCESS',
  GET_COMPANY_MEETINGS: 'GET_COMPANY_MEETINGS',
  GET_COMPANY_MEETINGS_SUCCESS: 'GET_COMPANY_MEETINGS_SUCCESS',
  GET_COMPANY_MEETINGS_FAILURE: 'GET_COMPANY_MEETINGS_FAILURE',
  COMPANY_MEETING_MODAL_STATUS: 'COMPANY_MEETING_MODAL_STATUS',
  ADD_COMPANY_MEETING: 'ADD_COMPANY_MEETING',
  ADD_COMPANY_MEETING_SUCCESS: 'ADD_COMPANY_MEETING_SUCCESS',
  ADD_COMPANY_MEETING_FAILURE: 'ADD_COMPANY_MEETING_FAILURE',
  CANCEL_COMPANY_MEETING: 'CANCEL_COMPANY_MEETING',
  CANCEL_COMPANY_MEETING_SUCCESS: 'CANCEL_COMPANY_MEETING_SUCCESS',
  CANCEL_COMPANY_MEETING_FAILURE: 'CANCEL_COMPANY_MEETING_FAILURE',
  GET_COMPANY_EMAIL_LIST: 'GET_COMPANY_EMAIL_LIST',
  GET_COMPANY_EMAIL_LIST_SUCCESS: 'GET_COMPANY_EMAIL_LIST_SUCCESS',
  GET_COMPANY_EMAIL_LIST_FAILURE: 'GET_COMPANY_EMAIL_LIST_FAILURE',
  CREATE_COMPANY_EMAIL: 'CREATE_COMPANY_EMAIL',
  CREATE_COMPANY_EMAIL_SUCCESS: 'CREATE_COMPANY_EMAIL_SUCCESS',
  CREATE_COMPANY_EMAIL_FAILURE: 'CREATE_COMPANY_EMAIL_FAILURE',
  SET_COMPANY_ACTIVITIES_FILTER: 'SET_COMPANY_ACTIVITIES_FILTER',
  ADD_COMPANY_ACTIVITIES: 'ADD_COMPANY_ACTIVITIES',
  COMPANY_EMAIL_MODEL_STATUS: 'COMPANY_EMAIL_MODEL_STATUS',
  UPDATE_MODAL_COMPANY_ID: 'UPDATE_MODAL_COMPANY_ID',
};

export default actions;
