import actions from './actions';
import {
  getFromUserData,
  getToUserData,
} from '../../../components/Email/helper';

let initState = {
  openCreateCampaignDrawer: false,
  emailTemplates: {},
  emailTemplatesLoader: true,
  selectedTemplateId: '',
  campaignsDetails: {},
  currentCampaignId: '',
  campaignEmails: [],
  campaignSearchText: '',
  campaignStatus: 'all',
  emailSearchText: '',
  currentCampaignEmailId: '',
  emailComposerData: {},
  mailText: '',
  mailSubject: '',
  sendTestEmailLoader: false,
  testEmailModalVisible: false,
  campaignEmailName: '',
  scheduleDetails: '',
  fromUserID: '',
  toUserID: '',
  campaignName: '',
  preHeaderText: '',
  customFields: [],
  templateContent: '',
  lastSavedTime: null,
  campaignTableLoading: true,
  emailTableLoading: true,
  campaignTabKey: '1',
  duplicateCampaignName: '',
  isDuplicateCampaign: false,
  openSetLiveModal: false,
  isDisableCreateCampaign: false,
  sortKey: '',
  sortValue: '',
  emailsPosition: [],
  emailsDelay: {},
  updatedEmailID: '',
  emailStatus: null,
  timePeriod: 'days',
  afterTime: 1,
  isSettingsChanges: false,
  isDisableCreateEmail: false,
  recipientsCount: 0,
  setLiveLoader: false,
  setPauseLoader: false,
  saveAndPublishLoader: false,
  subjectValidation: '',
  isAutoSaving: false,
  isComposerChange: false,
  isAutoSaveFailure: false,
  campaignsData: {},
  recipientsSendBtnDisable: false,
  templateJson: '',
  editorContentJson: '',
  beeToken: '',
  templateType: 'gist_templates',
};

export default function campaignEmailReducer(state = initState, action) {
  switch (action.type) {
    case actions.OPEN_CREATE_CAMPAIGN_DRAWER:
      return {
        ...state,
        openCreateCampaignDrawer: action.payload,
        isDuplicateCampaign: action.isDuplicateCampaign,
        duplicateCampaignName: action.isDuplicateCampaign
          ? action.name
            ? `${action.name}(Copy)`
            : `${state.campaignsData.name}(Copy)`
          : '',
      };
    case actions.CAMPAIGN_FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
        emailTemplatesLoader: false,
        selectedTemplateId: action.payload.default_template_id,
      };
    case actions.CAMPAIGN_FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignsDetails: action.payload.data,
        campaignTableLoading: false,
        campaignTabKey: '1',
        campaignsData: {},
        campaignEmails: [],
        isSettingsChanges: false,
      };
    case actions.FETCH_CAMPAIGN_ERROR:
      return {
        ...state,
        campaignTableLoading: false,
      };
    case actions.SET_CAMPAIGN_ID:
      return {
        ...state,
        currentCampaignId: action.payload.id,
      };
    case actions.FETCH_CAMPAIGN_EMAILS: {
      return {
        ...state,
        emailTableLoading: true,
      };
    }
    case actions.FETCH_CAMPAIGN_EMAILS_SUCCESS:
      return {
        ...state,
        campaignEmails: action.payload.mail_details,
        emailTableLoading: false,
        emailComposerData: {},
        isComposerChange: false,
      };
    case actions.FETCH_CAMPAIGN_EMAILS_ERROR:
      return {
        ...state,
        emailTableLoading: false,
      };
    case actions.UPDATE_CAMPAIGN_FILTERS:
      return {
        ...state,
        campaignSearchText: ![null, undefined].includes(action.searchText)
          ? action.searchText
          : state.campaignSearchText,
        campaignStatus: action.status ? action.status : state.campaignStatus,
      };
    case actions.UPDATE_CAMPAIGN_EMAIL_FILTERS:
      return {
        ...state,
        emailSearchText: ![null, undefined].includes(action.searchText)
          ? action.searchText
          : '',
      };
    case actions.FETCH_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        campaignsData: action.payload,
        lastSavedTime: action.payload.updated_at,
      };
    case actions.SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.templateId,
      };
    case actions.SET_CAMPAIGN_EMAIL_ID:
      return {
        ...state,
        currentCampaignEmailId: action.payload.id,
      };
    case actions.FETCH_CAMPAIGN_EMAIL_DETAILS:
      return {
        ...state,
        templateContent: '',
      };
    case actions.FETCH_CAMPAIGN_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        campaignEmailName: action.payload.name,
        emailComposerData: action.payload,
        fromUserID: getFromUserData(action.payload),
        toUserID: getToUserData(action.payload),
        mailText: action.payload.mail_text,
        mailSubject: action.payload.subject ? action.payload.subject : '',
        selectedTemplateId: action.payload.template_id,
        scheduleDetails: action.payload.schedule_details
          ? action.payload.schedule_details
          : '',
        campaignName: action.payload.title,
        preHeaderText: action.payload.pre_header_text
          ? action.payload.pre_header_text
          : '',
        customFields: action.payload.custom_fields,
        templateContent: action.payload.template_content,
        lastSavedTime: action.payload.updated_at,
        emailStatus: action.payload.status,
        timePeriod: action.payload.campaign_details.time_period,
        afterTime: action.payload.campaign_details.after,
        editorContentJson: action.payload.visual_builder_json,
      };
    case actions.CAMPAIGN_SET_FROM_EMAIL_USER_ID:
      return {
        ...state,
        fromUserID: action.fromUserID,
      };
    case actions.CAMPAIGN_SET_TO_EMAIL_USER_ID:
      return {
        ...state,
        toUserID: action.toUserID,
      };
    case actions.CAMPAIGN_SET_CAMPAIGN_EMAIL_NAME:
      return {
        ...state,
        campaignEmailName: action.campaignEmailName,
      };
    case actions.CAMPAIGN_SET_CAMPAIGN_EMAIL_SUBJECT:
      return {
        ...state,
        mailSubject: action.subject,
      };
    case actions.CAMPAIGN_SET_PRE_HEADER_TEXT:
      return {
        ...state,
        preHeaderText: action.preHeaderText,
      };
    case actions.TEST_CAMPAIGN_EMAIL_MODAL_VISIBLE:
      return {
        ...state,
        testEmailModalVisible: action.visible,
      };
    case actions.SET_CAMPAIGN_EMAIL_TEXT:
      return {
        ...state,
        mailText: action.message,
        isComposerChange: true,
      };
    case actions.SET_CAMPAIGN_LAST_SAVE_TIME:
      return {
        ...state,
        lastSavedTime: action.payload.updated_at,
        emailComposerData: {
          ...state.emailComposerData,
          ...action.payload,
          ...action.campaignDetails,
          name: action.campaignDetails.title,
          from_address: action.campaignDetails.from_address,
          reply_to_address: action.campaignDetails.reply_to_address,
        },
      };
    case actions.SEND_CAMPAIGN_TEST_EMAIL_SUCCESS:
      return {
        ...state,
        sendTestEmailLoader: false,
        testEmailModalVisible: false,
      };
    case actions.SEND_CAMPAIGN_TEST_EMAIL_FAILURE:
      return {
        ...state,
        sendTestEmailLoader: false,
        testEmailModalVisible: false,
      };
    case actions.SET_CAMPAIGN_SORT_KEY_AND_VALUE:
      return {
        ...state,
        sortKey: action.sortKey,
        sortValue: action.sortValue,
      };
    case actions.APPEND_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignsDetails: {
          ...state.campaignsDetails,
          campaigns:
            state.campaignsDetails.campaigns &&
            state.campaignsDetails.campaigns.length >= 0 &&
            state.campaignsDetails.campaigns.concat(
              action.payload.data.campaigns,
            ),
        },
        campaignTableLoading: false,
      };
    case actions.CAMPAIGN_FETCH_CAMPAIGNS:
      return {
        ...state,
        campaignTableLoading: true,
      };
    case actions.APPEND_CAMPAIGNS:
      return {
        ...state,
        campaignTableLoading: true,
      };
    case actions.SET_CAMPAIGN_TAB_KEY:
      return {
        ...state,
        campaignTabKey: action.payload,
      };
    case actions.CHANGE_CAMPAIGN_STATUS_SUCCESS:
      let campaigns = state.campaignsDetails.campaigns;
      let updatedCampaigns =
        campaigns &&
        campaigns.map(campaign => {
          if (campaign.id === action.campaignID) {
            campaign.status = action.status;
          }
          return campaign;
        });
      return {
        ...state,
        campaignsDetails: {
          ...state.campaignsDetails,
          campaigns: updatedCampaigns,
        },
        campaignsData: {
          ...state.campaignsData,
          status: action.status,
        },
      };
    case actions.CHANGE_EMAIL_STATUS_SUCCESS:
      let campaignEmails = state.campaignEmails;
      let updatedCampaignEmails =
        campaignEmails &&
        campaignEmails.map(email => {
          if (email.id === action.emailID) {
            email.status = action.status;
          }
          return email;
        });
      return {
        ...state,
        campaignEmails: updatedCampaignEmails,
        emailComposerData: {
          ...state.emailComposerData,
          status: action.status,
        },
      };
    case actions.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        campaignsData: {
          ...state.campaignsData,
          name: action.payload.name,
          days: action.payload.days,
          global_exit_conditions: action.payload.global_exit_conditions,
          message_goal: action.messageGoal,
        },
        lastSavedTime: action.payload.updated_at,
      };
    case actions.SET_LIVE_MODAL:
      return {
        ...state,
        openSetLiveModal: action.payload,
      };
    case actions.CAMPAIGN_FETCH_RECIPIENTS_SUCCESS:
      return {
        ...state,
        recipientsCount: action.payload.matched_people_count,
        recipientsSendBtnDisable: false,
      };
    case actions.CAMPAIGN_FETCH_RECIPIENTS_FAILURE:
      return {
        ...state,
        recipientsSendBtnDisable: action.payload,
        recipientsCount: action.payload
          ? action.count.data.matched_people_count
          : state.recipientsCount,
      };
    case actions.EMPTY_CAMPAIGN_EMAILS:
      return {
        ...state,
        campaignEmails: [],
      };
    case actions.EMPTY_COMPOSER_DATA:
      return {
        ...state,
        fromUserID: '',
        toUserID: '',
        campaignName: '',
        mailText: '',
        mailSubject: '',
        preHeaderText: '',
        editorContentJson: '',
      };
    case actions.UPDATE_SETTINGS:
      return {
        ...state,
        campaignsData: {
          ...state.campaignsData,
          days: action.settings.campaign.days,
        },
      };
    case actions.SET_DISABLE_CREATE_CAMPAIGN:
      return {
        ...state,
        isDisableCreateCampaign: action.payload,
      };
    case actions.SET_AUTO_SAVE_LOADER:
      return {
        ...state,
        isAutoSaving: action.payload,
        isAutoSaveFailure: false,
      };
    case actions.SET_AUTO_SAVE_FAILURE:
      return {
        ...state,
        isAutoSaving: false,
        isAutoSaveFailure: action.payload,
      };
    case actions.CAMPAIGN_SET_TIME_PERIOD:
      return {
        ...state,
        timePeriod: action.timePeriod,
      };
    case actions.CAMPAIGN_SET_AFTER_TIME:
      return {
        ...state,
        afterTime: action.afterTime,
      };
    case actions.SET_VALUE_SETTINGS_CHANGES:
      return {
        ...state,
        isSettingsChanges: action.payload,
      };
    case actions.CREATE_CAMPAIGN_EMAIL:
      return {
        ...state,
        isDisableCreateEmail: true,
      };
    case actions.DISABLE_CAMPAIGN_EMAIL_CREATION:
      return {
        ...state,
        isDisableCreateEmail: action.payload,
      };
    case actions.SET_CAMPAIGN_DATA:
      return {
        ...state,
        campaignsData: {
          ...state.campaignsData,
          new_filters: action.payload.new_filters,
          exit_filters: action.payload.exit_filters,
        },
      };
    case actions.SEND_TEST_EMAIL:
      return {
        ...state,
        sendTestEmailLoader: true,
      };
    case actions.CHANGE_CAMPAIGN_EMAIL_STATUS_SUCCESS:
      return {
        ...state,
        emailStatus: action.status,
        emailComposerData: {
          ...state.emailComposerData,
          status: action.status,
        },
      };
    case actions.CHANGE_EMAIL_LIVE_STATUS:
      return {
        ...state,
        setLiveLoader: action.payload,
      };
    case actions.CHANGE_EMAIL_PAUSE_STATUS:
      return {
        ...state,
        pauseLoader: action.payload,
      };
    case actions.CHANGE_EMAIL_PUBLISH_STATUS:
      return {
        ...state,
        saveAndPublishLoader: action.payload,
      };
    case actions.SET_CAMPAIGN_EMAIL_SUBJECT_VALIDATION:
      return {
        ...state,
        subjectValidation: action.payload,
      };
    case actions.SET_COMPOSER_VALUE_CHANGE:
      return {
        ...state,
        isComposerChange: action.payload,
      };
    case actions.CREATE_CAMPAIGN_COMPOSER:
      return {
        ...state,
        mailText: action.isFromBeeEditor
          ? action.object.mail_filter.mail_text
          : state.mailText,
        editorContentJson: action.isFromBeeEditor
          ? action.object.mail_filter.visual_builder_json
          : state.editorContentJson,
        isAutoSaveFailure: false,
      };
    case actions.FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON_SUCCESS:
      return {
        ...state,
        templateJson: action.payload,
      };
    case actions.UPDATE_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON:
      return {
        ...state,
        editorContentJson: action.payload,
      };
    case actions.FETCH_CAMPAIGN_EMAIL_BEE_TOKEN_SUCCESS:
      return {
        ...state,
        beeToken: action.payload,
      };
    case actions.SET_CAMPAIGN_EMAIL_TEMPLATE_TAB_KEY:
      return {
        ...state,
        templateType: action.payload,
      };
    default:
      return state;
  }
}
