import socket from '../../helpers/socket';

export function joinRoom(livePeople) {
  livePeople.forEach(datum => {
    const livePersonRoom = `${datum.project_secret_key}/contacts/${
      datum.person_id
    }`;
    // TODO: Need to remove the leave room emitting by node server check
    socket.emit('leave_room', livePersonRoom);
    socket.emit('room', livePersonRoom);
  });
}

export function leaveRoom(livePeople) {
  livePeople.forEach(datum => {
    const livePersonRoom = `${datum.project_secret_key}/contacts/${
      datum.person_id
    }`;
    socket.emit('leave_room', livePersonRoom);
  });
}
