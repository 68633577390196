const actions = {
  FETCH_VISITORS: 'FETCH_VISITORS',
  FETCH_VISITORS_SUCCESS: 'FETCH_VISITORS_SUCCESS',
  FETCH_VISITORS_FAILURE: 'FETCH_VISITORS_FAILURE',
  APPEND_VISITORS: 'APPEND_VISITORS',
  APPEND_VISITORS_SUCCESS: 'APPEND_VISITORS_SUCCESS',
  APPEND_VISITORS_FAILURE: 'APPEND_VISITORS_FAILURE',
  PREPEND_VISITORS: 'PREPEND_VISITORS',
  UPDATE_VISITORS: 'UPDATE_VISITORS',
  OPEN_MESSAGE_MODAL: 'OPEN_MESSAGE_MODAL',
  CLOSE_MESSAGE_MODAL: 'CLOSE_MESSAGE_MODAL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',
  UPDATE_SEARCH_TEXT: 'UPDATE_SEARCH_TEXT',
  UPDATE_DISPLAYED_VISITORS_STATUS: 'UPDATE_DISPLAYED_VISITORS_STATUS',
};

export default actions;
