import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  unAuthorisedRedirection,
} from '../../helpers/utility';
import settings from '../../settings/index';
import actions from './actions';
import formsActions from '../forms/actions';
import { message } from 'antd';

export function* getFbAudienceList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/get_fb_audience_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_FB_AUDIENCE_LIST_SUCCESS,
      payload: data.data ? data.data : [],
    });
    if (params.id) {
      yield put({
        type: actions.GET_FB_CUSTOM_AUDIENCE_LIST,
        payload: params.id,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (
      error.response.data.data &&
      !error.response.data.data.fb_custom_audience.active
    ) {
      yield put({
        type: actions.GET_FB_AUDIENCE_LIST_FAILURE,
        payload: error.response.data.data.fb_custom_audience.active,
      });
    } else {
      message.error(error.response.data.error.message);
    }
  }
}

export function* createCustomAudienceList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/create_fb_audience_list`;
    let data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.GET_FB_AUDIENCE_LIST,
      id: params.object.fb_ad_account_id,
    });
    yield put({
      type: actions.CREATE_CUSTOM_AUDIENCE_SUCCESS,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_CUSTOM_AUDIENCE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* getCustomFieldList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/custom_fields_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORM_CUSTOM_FIELD_LIST_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: formsActions.FETCH_LIQUID_VARIABLES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Custom fields fetch failed');
  }
}

export function* getTeammateList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teammates_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORM_TEAMMATE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Teammate details fetch failed');
  }
}

export function* fetchTemplates() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/templates_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_TEMPLATES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch template failed');
  }
}

export function* fetchGoogleFonts() {
  try {
    const url = settings.GOOGLE_WEB_FONTS_URL;
    let response = yield call(() => getPromise(url, false));
    yield put({
      type: actions.FETCH_GOOGLE_FONTS_SHARED_REDUCER_SUCCESS,
      payload: response.items,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch google fonts failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_FB_AUDIENCE_LIST, getFbAudienceList),
    takeEvery(actions.CREATE_CUSTOM_AUDIENCE, createCustomAudienceList),
    takeEvery(actions.FETCH_FORM_CUSTOM_FIELD_LIST, getCustomFieldList),
    takeEvery(actions.FETCH_FORM_TEAMMATE_LIST, getTeammateList),
    takeEvery(actions.FETCH_EMAIL_TEMPLATES, fetchTemplates),
    takeEvery(actions.FETCH_GOOGLE_FONTS_SHARED_REDUCER, fetchGoogleFonts),
  ]);
}
