import React from 'react';
import { Modal } from 'antd';
import { handleNotAuthorizedModal } from '../../helpers/utility';

class NotAuthorizedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cancel = () => {
    handleNotAuthorizedModal(false);
  };

  render() {
    const { visible } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={this.cancel}
        footer={null}
        centered={true}
        closable={true}
        destroyOnClose={true}
        width={420}
        className={'not-authorized-modal'}
      >
        <div className={'content'}>
          <h2>This feature is restricted</h2>
          <p>Ask one of your teammates to give you permission.</p>
        </div>
      </Modal>
    );
  }
}

export default NotAuthorizedModal;
