import { orderBy } from 'lodash';
import { store } from '../../redux/store';
import actions from '../../redux/CRM/Deal/actions';
import { Spin } from 'antd';
import React from 'react';
import {extractCurrentUserId} from '../../helpers/utility';

export function dealsDefaultOrder(deals) {
  return orderBy(deals, deal => deal.data.close_at, ['asc']);
}

export function isCustomProperty(key) {
  const { dealProperties } = store.getState().CRMDeal;
  const customProperty = dealProperties.custom_properties.length
    ? dealProperties.custom_properties.map(data => data.name)
    : [];
  return customProperty.includes(key);
}

export function addActivity(data, type) {
  const { activitiesFilter } = store.getState().CRMDeal;
  let canAdd = false;
  if (activitiesFilter.length) {
    if (activitiesFilter.includes(type)) {
      canAdd = true;
    }
  } else {
    canAdd = true;
  }
  if (canAdd) {
    store.dispatch({
      type: actions.ADD_DEAL_ACTIVITIES,
      payload: data[0],
    });
  }
}

export function getLoaderText(params) {
  const { loader, data, searchValue, placeholder, list } = params;
  if (loader) {
    return <Spin size="small" />;
  } else if (!loader && searchValue === '' && list.length === 0) {
    return <div style={{ padding: '15px' }}> {placeholder} </div>;
  } else {
    return data;
  }
}

export function filterCurrentUserDeals(currentPipeline) {
  currentPipeline.stages = currentPipeline.stages.map(stage => {
    stage.deals = stage.deals.filter(
      deal => deal.user_id === extractCurrentUserId(),
    );
    return stage;
  });
  return currentPipeline;
}
