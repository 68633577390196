const actions = {
  UPDATE_TOTAL_AMOUNT: 'UPDATE_TOTAL_AMOUNT',
  GET_PIPELINES: 'GET_PIPELINES',
  GET_PIPELINES_SUCCESS: 'GET_PIPELINES_SUCCESS',
  GET_PIPELINES_FAILURE: 'GET_PIPELINES_FAILURE',
  GET_STAGES: 'GET_STAGES',
  GET_STAGES_SUCCESS: 'GET_STAGES_SUCCESS',
  GET_STAGES_FAILURE: 'GET_STAGES_FAILURE',
  SET_DEAL_DETAILS: 'SET_DEAL_DETAILS',
  UPDATE_PIPELINE_DATA: 'UPDATE_PIPELINE_DATA',
  OPEN_CREATE_DEAL_DRAWER: 'OPEN_CREATE_DEAL_DRAWER',
  CREATE_DEAL: 'CREATE_DEAL',
  CREATE_DEAL_SUCCESS: 'CREATE_DEAL_SUCCESS',
  CREATE_DEAL_FAILURE: 'CREATE_DEAL_FAILURE',
  UPDATE_DEAL: 'UPDATE_DEAL',
  UPDATE_DEAL_SUCCESS: 'UPDATE_DEAL_SUCCESS',
  UPDATE_DEAL_FAILURE: 'UPDATE_DEAL_FAILURE',
  DELETE_DEAL_SUCCESS: 'DELETE_DEAL_SUCCESS',
  UPDATE_CHANGE_DEAL_STAGE_TO_ANOTHER: 'UPDATE_CHANGE_DEAL_STAGE_TO_ANOTHER',
  SET_DEAL_USER_ID: 'SET_DEAL_USER_ID',
  GET_STAGES_LIST: 'GET_STAGES_LIST',
  GET_STAGES_LIST_SUCCESS: 'GET_STAGES_LIST_SUCCESS',
  GET_STAGES_LIST_FAILURE: 'GET_STAGES_LIST_FAILURE',
  GET_PIPELINE_LIST: 'GET_PIPELINE_LIST',
  GET_PIPELINE_LIST_SUCCESS: 'GET_PIPELINE_LIST_SUCCESS',
  GET_PIPELINE_LIST_FAILURE: 'GET_PIPELINE_LIST_FAILURE',
  GET_CRM_CONTACT: 'GET_CRM_CONTACT',
  GET_CRM_CONTACTS_SUCCESS: 'GET_CRM_CONTACTS_SUCCESS',
  GET_CRM_CONTACTS_FAILURE: 'GET_CRM_CONTACTS_FAILURE',
  DEAL_BUTTON_LOADER_STATUS: 'DEAL_BUTTON_LOADER_STATUS',
  GET_DEAL_COMPANY_LIST: 'GET_DEAL_COMPANY_LIST',
  GET_DEAL_COMPANY_LIST_SUCCESS: 'GET_DEAL_COMPANY_LIST_SUCCESS',
  GET_DEAL_COMPANY_LIST_FAILURE: 'GET_DEAL_COMPANY_LIST_FAILURE',
  UPDATE_DEFAULT_PIPELINE: 'UPDATE_DEFAULT_PIPELINE',
  UPDATE_DEFAULT_PIPELINE_SUCCESS: 'UPDATE_DEFAULT_PIPELINE_SUCCESS',
  UPDATE_DEAL_DETAILS: 'UPDATE_DEAL_DETAILS',
};

export default actions;
