const actions = {
  FETCH_CHATBOT_TEMPLATE_LIST: 'FETCH_CHATBOT_TEMPLATE_LIST',
  FETCH_CHATBOT_TEMPLATE_LIST_SUCCESS: 'FETCH_CHATBOT_TEMPLATE_LIST_SUCCESS',
  FETCH_CHATBOT_TEMPLATE_LIST_FAILURE: 'FETCH_CHATBOT_TEMPLATE_LIST_FAILURE',
  GET_CHATBOT_CATEGORIES_LISTS: 'GET_CHATBOT_CATEGORIES_LISTS',
  GET_CHATBOT_CATEGORIES_LISTS_SUCCESS: 'GET_CHATBOT_CATEGORIES_LISTS_SUCCESS',
  SET_CREATE_CHATBOT_TEMPLATE_DRAWER_STATUS:
    'SET_CREATE_CHATBOT_TEMPLATE_DRAWER_STATUS',
  CREATE_CHATBOT_TEMPLATE: 'CREATE_CHATBOT_TEMPLATE',
  CREATE_CHATBOT_TEMPLATE_SUCCESS: 'CREATE_CHATBOT_TEMPLATE_SUCCESS',
  CREATE_CHATBOT_TEMPLATE_FAILURE: 'CREATE_CHATBOT_TEMPLATE_FAILURE',
  SET_CHATBOT_TEMPLATE_ID: 'SET_CHATBOT_TEMPLATE_ID',
  DELETE_CHATBOT_TEMPLATE: 'DELETE_CHATBOT_TEMPLATE',
  DELETE_CHATBOT_TEMPLATE_SUCCESS: 'DELETE_CHATBOT_TEMPLATE_SUCCESS',
  DELETE_CHATBOT_TEMPLATE_FAILURE: 'DELETE_CHATBOT_TEMPLATE_FAILURE',
  FETCH_CHATBOT_TEMPLATE_DETAILS: 'FETCH_CHATBOT_TEMPLATE_DETAILS',
  FETCH_CHATBOT_TEMPLATE_DETAILS_SUCCESS:
    'FETCH_CHATBOT_TEMPLATE_DETAILS_SUCCESS',
  UPDATE_CHATBOT_TEMPLATE_DRAWER_VISIBLE:
    'UPDATE_CHATBOT_TEMPLATE_DRAWER_VISIBLE',
  UPDATE_CHATBOT_TEMPLATE: 'UPDATE_CHATBOT_TEMPLATE',
  UPDATE_CHATBOT_TEMPLATE_SUCCESS: 'UPDATE_CHATBOT_TEMPLATE_SUCCESS',
  UPDATE_CHATBOT_TEMPLATE_FAILURE: 'UPDATE_CHATBOT_TEMPLATE_FAILURE',
  SET_CHATBOT_TEMPLATE_STATUS: 'SET_CHATBOT_TEMPLATE_STATUS',
  SET_CHATBOT_TEMPLATE_STATUS_LOADER: 'SET_CHATBOT_TEMPLATE_STATUS_LOADER',
  SET_EDIT_DETAILS_FORM_LOADER: 'SET_EDIT_DETAILS_FORM_LOADER',
  SET_FILTER_CHATBOT_CATEGORY_ID: 'SET_FILTER_CHATBOT_CATEGORY_ID',
  SET_CHOOSE_CHATBOT_TEMPLATE_SEARCH_VALUE:
    'SET_CHOOSE_CHATBOT_TEMPLATE_SEARCH_VALUE',
  FILTER_CHATBOT_TEMPLATE_LIST: 'FILTER_CHATBOT_TEMPLATE_LIST',
  SET_CHATBOT_TEMPLATE_SEARCH_VALUE: 'SET_CHATBOT_TEMPLATE_SEARCH_VALUE',
  DUPLICATE_CHATBOT_TEMPLATE: 'DUPLICATE_CHATBOT_TEMPLATE',
  DUPLICATE_CHATBOT_TEMPLATE_SUCCESS: 'DUPLICATE_CHATBOT_TEMPLATE_SUCCESS',
  DUPLICATE_CHATBOT_TEMPLATE_FAILURE: 'DUPLICATE_CHATBOT_TEMPLATE_FAILURE',
  SET_SETTINGS_CHATBOT_TEMPLATE_DETAILS:
    'SET_SETTINGS_CHATBOT_TEMPLATE_DETAILS',
};

export default actions;
