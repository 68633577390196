import '../AutoEmails/style.scss';
import '../Broadcast/style.scss';
import React from 'react';
import { Tabs } from 'antd';
import AllTemplateTab from './AllTemplateTab';
import { store } from '../../../redux/store';
import autoEmailActions from '../../../redux/email/autoEmails/actions';
import broadcastActions from '../../../redux/email/broadcast/actions';
import campaignEmailActions from '../../../redux/email/campaign/actions';
import BeeTemplate from './BeeTemplate';

const TabPane = Tabs.TabPane;

function SelectTemplateContent(props) {
  const {
      isFromAutoEmails,
      isFromCampaignEmails,
      emailTemplates,
      emailTemplatesLoader,
      selectedTemplateId,
      restrictedFeatures,
      templateType,
      isFromWorkflow,
      emailType,
      isfromSelectTemplateContainer,
    } = props,
    actions = isFromAutoEmails
      ? autoEmailActions
      : isFromCampaignEmails
        ? campaignEmailActions
        : broadcastActions;

  const setTemplateTabKey = key => {
    store.dispatch({
      type:
        actions[
          `SET_${
            isFromAutoEmails ? 'AUTO_EMAIL' : isFromCampaignEmails ? 'CAMPAIGN_EMAIL' : 'BROADCAST'
          }_TEMPLATE_TAB_KEY`
        ],
      payload: key,
    });
  };

  return (
    <div className="drawer-content">
      <Tabs
        defaultActiveKey="gist_templates"
        activeKey={templateType}
        onChange={setTemplateTabKey}
        animated={false}
      >
        <TabPane tab="Gist Templates" key="gist_templates">
          {emailType === 'visual_builder' ? (
            <BeeTemplate
              isfromSelectTemplateContainer={isfromSelectTemplateContainer}
              emailTemplates={emailTemplates}
              emailTemplatesLoader={emailTemplatesLoader}
              selectedTemplateId={selectedTemplateId}
              isFromAutoEmails={isFromAutoEmails}
              isFromCampaignEmails={isFromCampaignEmails}
            />
          ) : (
            <AllTemplateTab
              isfromSelectTemplateContainer={isfromSelectTemplateContainer}
              emailTemplates={
                emailTemplates && emailTemplates.gist_templates
                  ? emailTemplates.gist_templates
                  : []
              }
              emailTemplatesLoader={emailTemplatesLoader}
              selectedTemplateId={selectedTemplateId}
              dynamicData={emailTemplates}
              restrictedFeatures={restrictedFeatures}
              isFromAutoEmails={isFromAutoEmails}
              isFromWorkflow={isFromWorkflow}
              isFromCampaignEmails={isFromCampaignEmails}
              emailType={emailType}
            />
          )}
        </TabPane>
        <TabPane tab="Custom" key="custom_templates">
          <AllTemplateTab
            isfromSelectTemplateContainer={isfromSelectTemplateContainer}
            emailTemplates={
              emailTemplates && emailTemplates.custom_templates
                ? emailTemplates.custom_templates
                : []
            }
            emailTemplatesLoader={emailTemplatesLoader}
            selectedTemplateId={selectedTemplateId}
            dynamicData={emailTemplates}
            restrictedFeatures={restrictedFeatures}
            isFromAutoEmails={isFromAutoEmails}
            isFromWorkflow={isFromWorkflow}
            isFromCampaignEmails={isFromCampaignEmails}
            emailType={emailType}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default SelectTemplateContent;
