import { store } from '../../redux/store';

export function getContactOwner(ownerList, ownerId) {
  return (
    ownerList.find(owner => owner.id.toString() === ownerId.toString()) || {}
  );
}

export function updateOwnerInContactsList(contactsList) {
  const { userList } = store.getState().Settings;
  return contactsList.map(contact => {
    contact.contact_owner =
      (contact.contact_owner &&
        getContactOwner(userList, contact.contact_owner)) ||
      {};
    return contact;
  });
}
