import actions from './actions';

let initState = {
  notificationLoader: false,
  getNotificationLoader: false,
  globalNotification: {},
  notifications: {},
};

export default function notificationReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_NOTIFICATION_CHANGES:
      return {
        ...state,
        notificationLoader: true,
      };
    case actions.UPDATE_NOTIFICATION_CHANGES_SUCCESS:
      return {
        ...state,
        notificationLoader: false,
      };
    case actions.UPDATE_NOTIFICATION_CHANGES_FAILURE:
      return {
        ...state,
        notificationLoader: false,
      };
    case actions.GET_NOTIFICATION_DETAILS:
      return {
        ...state,
        getNotificationLoader: true,
      };
    case actions.GET_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        globalNotification: action.payload.data.user.global_notification,
        notifications: action.payload.data.user,
        getNotificationLoader: false,
      };
    case actions.GET_NOTIFICATION_DETAILS_FAILURE:
      return {
        ...state,
        getNotificationLoader: false,
      };
    default:
      return state;
  }
}
