import actions from './actions';

let initState = {
  reportData: {
    emailPreview: null,
    openCount: null,
    clickCount: null,
    totalReport: null,
    linkActivity: [],
    recipient_count_detail: null,
    userData: [],
    isLoading: true,
    sortKey: '',
    sortOrder: '',
    searchText: '',
    title: '',
    isLinkEditable: false,
  },
};

export default function emailReportReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_EMAIL_PREVIEW_REPORT_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          emailPreview: action.payload,
        },
      };
    case actions.FETCH_OPEN_COUNT_REPORT_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          openCount: action.payload,
        },
      };
    case actions.FETCH_CLICK_COUNT_REPORT_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          clickCount: action.payload,
        },
      };
    case actions.FETCH_TOTAL_REPORT_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          totalReport: action.payload,
        },
      };
    case actions.FETCH_LINK_ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          linkActivity: action.payload.data,
          isLinkEditable: action.payload.link_editable || false,
        },
      };
    case actions.FETCH_REPORT_RECIPIENT_COUNT_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          recipient_count_detail: action.payload,
        },
      };
    case actions.FETCH_RECIPIENT_USER:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          isLoading: true,
        },
      };
    case actions.FETCH_RECIPIENT_USER_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          userData: action.payload,
          isLoading: false,
        },
      };
    case actions.RESET_REPORT:
      return {
        ...state,
        reportData: {
          emailPreview: null,
          openCount: null,
          clickCount: null,
          totalReport: null,
          linkActivity: [],
          recipient_count_detail: null,
          userData: [],
          sortKey: '',
          sortOrder: '',
          searchText: '',
        },
      };
    case actions.SET_RECIPIENT_SORT_KEY_AND_ORDER:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          sortKey: action.sortKey,
          sortOrder: action.sortOrder,
        },
      };
    case actions.SET_RECIPIENT_USER_SEARCH_TEXT:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          searchText: action.payload,
        },
      };
    case actions.UPDATE_EMAIL_NAME_SUCCESS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          emailPreview: {
            ...state.reportData.emailPreview,
            title: action.payload,
          },
        },
      };
    case actions.UPDATE_EMAIL_STATUS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          emailPreview: {
            ...state.reportData.emailPreview,
            status: action.payload,
          },
        },
      };
    case actions.UPDATE_EMAIL_LINK_SUCCESS: {
      const updateLinkActivity = state.reportData.linkActivity.map(data => {
        if (data.url === action.payload.old_url) {
          data.url = action.payload.new_url;
        }
        return data;
      });
      return {
        ...state,
        reportData: {
          ...state.reportData,
          linkActivity: updateLinkActivity,
        },
      };
    }
    default:
      return state;
  }
}
