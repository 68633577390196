const actions = {
  FETCH_EMAIL_PREVIEW_REPORT: 'FETCH_EMAIL_PREVIEW_REPORT',
  FETCH_EMAIL_PREVIEW_REPORT_SUCCESS: 'FETCH_EMAIL_PREVIEW_REPORT_SUCCESS',
  FETCH_OPEN_COUNT_REPORT: 'FETCH_OPEN_COUNT_REPORT',
  FETCH_OPEN_COUNT_REPORT_SUCCESS: 'FETCH_OPEN_COUNT_REPORT_SUCCESS',
  FETCH_CLICK_COUNT_REPORT: 'FETCH_CLICK_COUNT_REPORT',
  FETCH_CLICK_COUNT_REPORT_SUCCESS: 'FETCH_CLICK_COUNT_REPORT_SUCCESS',
  FETCH_TOTAL_REPORT: 'FETCH_TOTAL_REPORT',
  FETCH_TOTAL_REPORT_SUCCESS: 'FETCH_TOTAL_REPORT_SUCCESS',
  FETCH_LINK_ACTIVITY_REPORT: 'FETCH_LINK_ACTIVITY_REPORT',
  FETCH_LINK_ACTIVITY_REPORT_SUCCESS: 'FETCH_LINK_ACTIVITY_REPORT_SUCCESS',
  FETCH_REPORT_RECIPIENT_COUNT: 'FETCH_REPORT_RECIPIENT_COUNT',
  FETCH_REPORT_RECIPIENT_COUNT_SUCCESS: 'FETCH_REPORT_RECIPIENT_COUNT_SUCCESS',
  FETCH_RECIPIENT_USER: 'FETCH_RECIPIENT_USER',
  FETCH_RECIPIENT_USER_SUCCESS: 'FETCH_RECIPIENT_USER_SUCCESS',
  RESET_REPORT: 'RESET_REPORT',
  SET_RECIPIENT_SORT_KEY_AND_ORDER: 'SET_RECIPIENT_SORT_KEY_AND_ORDER',
  SET_RECIPIENT_USER_SEARCH_TEXT: 'SET_RECIPIENT_USER_SEARCH_TEXT',
  UPDATE_EMAIL_NAME: 'UPDATE_EMAIL_NAME',
  UPDATE_EMAIL_NAME_SUCCESS: 'UPDATE_EMAIL_NAME_SUCCESS',
  UPDATE_EMAIL_STATUS: 'UPDATE_EMAIL_STATUS',
  UPDATE_EMAIL_LINK: 'UPDATE_EMAIL_LINK',
  UPDATE_EMAIL_LINK_SUCCESS: 'UPDATE_EMAIL_LINK_SUCCESS',
};

export default actions;

