const actions = {
  FETCH_TABLE_LISTS: 'FETCH_TABLE_LISTS',
  FETCH_TABLE_LISTS_SUCCESS: 'FETCH_TABLE_LISTS_SUCCESS',
  FETCH_TABLE_LISTS_FAILURE: 'FETCH_TABLE_LISTS_FAILURE',
  APPEND_TABLE_LISTS: 'APPEND_TABLE_LISTS',
  APPEND_TABLE_LISTS_SUCCESS: 'APPEND_TABLE_LISTS_SUCCESS',
  APPEND_TABLE_LISTS_FAILURE: 'APPEND_TABLE_LISTS_FAILURE',
  CREATE_CHAT_DRAWER: 'CREATE_CHAT_DRAWER',
  DELETE_TABLE_DATA: 'DELETE_TABLE_DATA',
  DELETE_TABLE_DATA_SUCCESS: 'DELETE_TABLE_DATA_SUCCESS',
  DELETE_TABLE_DATA_FAILURE: 'DELETE_TABLE_DATA_FAILURE',
  SET_SELECTED_ROWS: 'SET_SELECTED_ROWS',
  APPLY_CHAT_TAG: 'APPLY_CHAT_TAG',
  APPLY_CHAT_TAG_SUCCESS: 'APPLY_CHAT_TAG_SUCCESS',
  CREATE_MESSAGES_CHAT: 'CREATE_MESSAGES_CHAT',
  CREATE_MESSAGES_CHAT_SUCCESS: 'CREATE_MESSAGES_CHAT_SUCCESS',
  CREATE_MESSAGES_CHAT_FAILURE: 'CREATE_MESSAGES_CHAT_FAILURE',
  DUPLICATE_MESSAGE_CHAT: 'DUPLICATE_MESSAGE_CHAT',
  DUPLICATE_MESSAGE_CHAT_SUCCESS: 'DUPLICATE_MESSAGE_CHAT_SUCCESS',
  DUPLICATE_MESSAGE_CHAT_FAILURE: 'DUPLICATE_MESSAGE_CHAT_FAILURE',
  UPDATE_MESSAGE_CHAT: 'UPDATE_MESSAGE_CHAT',
  UPDATE_MESSAGE_CHAT_FAILURE: 'UPDATE_MESSAGE_CHAT_FAILURE',
  CHANGE_TABLE_ELEMENT_POSITION: 'CHANGE_TABLE_ELEMENT_POSITION',
  RE_ORDER_MESSAGE_CHAT_STATUS: 'RE_ORDER_MESSAGE_CHAT_STATUS',
  UPDATE_TABLE_ELEMENT_STATUS: 'UPDATE_TABLE_ELEMENT_STATUS',
  UPDATE_TABLE_ELEMENT_STATUS_FAILURE: 'UPDATE_TABLE_ELEMENT_STATUS_FAILURE',
  REMOVE_CHAT_TAG: 'REMOVE_CHAT_TAG',
  SET_CONFIRM_MODEL: 'SET_CONFIRM_MODEL',
  UPDATE_CHAT_MESSAGE: 'UPDATE_CHAT_MESSAGE',
  CANCEL_CHAT_SCHEDULE: 'CANCEL_CHAT_SCHEDULE',
  SET_CHAT_TAB_KEY: 'SET_CHAT_TAB_KEY',
  SET_CHAT_NAME: 'SET_CHAT_NAME',
  REMOVE_DELETED_ON_GOING_CHAT_ID: 'REMOVE_DELETED_ON_GOING_CHAT_ID',
  SET_SELECT_CHANNEL_MODAL_STATUS: 'SET_SELECT_CHANNEL_MODAL_STATUS',
  SET_TABLE_LISTS: 'SET_TABLE_LISTS',
  SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES: 'SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES',
  SET_CREATED_MESSAGE_TAG: 'SET_CREATED_MESSAGE_TAG',
  SET_CHAT_CHANGE: 'SET_CHAT_CHANGE',
};

export default actions;