const actions = {
  TEMPLATE_DRAWER_STATUS_FORM_TEMPLATES: 'TEMPLATE_DRAWER_STATUS_FORM_TEMPLATES',
  SET_FORMS_TEMPLATE_STATUS: 'SET_FORMS_TEMPLATE_STATUS',
  FETCH_FORMS_TEMPLATE_LIST: 'FETCH_FORMS_TEMPLATE_LIST',
  FETCH_FORMS_TEMPLATE_LIST_SUCCESS: 'FETCH_FORMS_TEMPLATE_LIST_SUCCESS',
  FETCH_FORMS_TEMPLATE_LIST_FAILURE: 'FETCH_FORMS_TEMPLATE_LIST_FAILURE',
  SET_FORMS_TEMPLATE_SEARCH_VALUE: 'SET_FORMS_TEMPLATE_SEARCH_VALUE',
  DELETE_FORMS_TEMPLATE: 'DELETE_FORMS_TEMPLATE',
  DELETE_FORMS_TEMPLATE_SUCCESS: 'DELETE_FORMS_TEMPLATE_SUCCESS',
  DELETE_FORMS_TEMPLATE_FAILURE: 'DELETE_FORMS_TEMPLATE_FAILURE',
  SET_FORMS_TEMPLATE_ID: 'SET_FORMS_TEMPLATE_ID',
  CREATE_FORMS_TEMPLATE: 'CREATE_FORMS_TEMPLATE',
  CREATE_FORMS_TEMPLATE_SUCCESS: 'CREATE_FORMS_TEMPLATE_SUCCESS',
  CREATE_FORMS_TEMPLATE_FAILURE: 'CREATE_FORMS_TEMPLATE_FAILURE',
  DUPLICATE_FORMS_TEMPLATE: 'DUPLICATE_FORMS_TEMPLATE',
  DUPLICATE_FORMS_TEMPLATE_SUCCESS: 'DUPLICATE_FORMS_TEMPLATE_SUCCESS',
  DUPLICATE_FORMS_TEMPLATE_FAILURE: 'DUPLICATE_FORMS_TEMPLATE_FAILURE',
  FILTER_FORMS_TEMPLATE_LIST: 'FILTER_FORMS_TEMPLATE_LIST',
  UPDATE_FORM_TEMPLATE_DRAWER_VISIBLE: 'UPDATE_FORM_TEMPLATE_DRAWER_VISIBLE',
  UPDATE_EDIT_DETAILS: 'UPDATE_EDIT_DETAILS',
  UPDATE_TEMPLATE_EDIT_DETAILS: 'UPDATE_TEMPLATE_EDIT_DETAILS',
  UPDATE_EDIT_DETAILS_SUCCESS: 'UPDATE_EDIT_DETAILS_SUCCESS',
  UPDATE_EDIT_DETAILS_FAILURE: 'UPDATE_EDIT_DETAILS_FAILURE',
  SET_FORMS_TYPE_TEMPLATE_STATUS: 'SET_FORMS_TYPE_TEMPLATE_STATUS',
  GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE: 'GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE',
  GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE_SUCCESS:
    'GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE_SUCCESS',
};

export default actions;
