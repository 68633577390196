import Auth from './auth/reducer';
import Chat from './chat/reducer';
import Contacts from './contacts/reducer';
import LiveView from './liveView/reducer';
import ProactiveMessage from './proactiveMessage/reducer';
import CampaignEmail from './email/campaign/reducer';
import BroadcastEmail from './email/broadcast/reducer';
import AutoEmail from './email/autoEmails/reducer';
import EmailReport from './email/report/reducer';
import Rules from './automation/rules/reducer';
import Workflow from './automation/workflow/reducer';
import KnowledgeBase from './knowledgeBase/reducer';
import Settings from './settings/reducer';
import Meetings from './meetings/reducer';
import Notification from './notification/reducer';
import AccountDetail from './accountDetail/reducer';
import Events from './events/reducer';
import Filter from './filters/reducer';
import Email from './email/reducer';
import InboxReports from './reports/inboxReports/reducer';
import SharedReducers from './sharedReducers/reducer';
import WorkflowTemplateReducer from './automation/workflowTemplate/reducer';
import ChatbotTemplateReducer from './automation/chatbotTemplate/reducer';
import Forms from './forms/reducer';
import DisplayRules from './displayRules/reducer';
import Templates from './templates/reducers';
import MessagesReducer from './messages/reducer';
import ChatOngoingReducer from './messages/chats/onGoing/reducer';
import Assistants from './assistants/reducer';
import ChatOneOffReducer from './messages/chats/oneOff/reducer';
import chatOngoingPost from './post/onGoing/reducer';
import ChatOneOffPost from './post/oneOff/reducer';
import PostReducer from './post/reducer';
import SurveyReducer from './survey/reducer';
import CRM from './CRM/reducer';
import CRMDeal from './CRM/Deal/reducer';
import Companies from './companies/reducer';
import Company from './companies/company/reducer';
import InboxAutomation from './inboxAutomation/reducer';
import Apps from './apps/reducer';
import AppPlatform from './appPlatform/reducer';
import surveyOneOffReducer from './survey/oneOff/reducer';
import surveyOnGoingReducer from './survey/onGoing/reducer';
import SurveyTemplateReducer from './automation/surveyTemplate/reducer';
import ToursReducer from './tours/reducer';
import MessageTemplateReducer from './automation/messageTemplate/reducer';
import PageBuilderReducer from './pageBuilder/reducer';
import FormTemplateReducer from "./automation/formTemplate/reducer";

export default {
  Auth,
  Chat,
  Contacts,
  LiveView,
  CampaignEmail,
  BroadcastEmail,
  AutoEmail,
  EmailReport,
  Rules,
  KnowledgeBase,
  Settings,
  Workflow,
  Meetings,
  Notification,
  AccountDetail,
  Events,
  Filter,
  Email,
  InboxReports,
  SharedReducers,
  WorkflowTemplateReducer,
  ChatbotTemplateReducer,
  FormTemplateReducer,
  Forms,
  DisplayRules,
  Templates,
  ProactiveMessage,
  MessagesReducer,
  ChatOngoingReducer,
  ChatOneOffReducer,
  Assistants,
  chatOngoingPost,
  ChatOneOffPost,
  PostReducer,
  SurveyReducer,
  CRM,
  CRMDeal,
  Companies,
  Company,
  InboxAutomation,
  Apps,
  AppPlatform,
  surveyOneOffReducer,
  surveyOnGoingReducer,
  SurveyTemplateReducer,
  ToursReducer,
  PageBuilderReducer,
  MessageTemplateReducer,
};
