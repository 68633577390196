import { extractCurrentUserId } from '../../helpers/utility';
import { store } from '../../redux/store';
import moment from 'moment';

export const changeAppData = data => ({
  id: null,
  inbox_app_id: data.id.toString(),
  name: data.name,
  notifiable: false,
  icon_url: data.logo,
  installed_state: data.installed_state,
});

export const getEnabledAndDisabledWidget = (
  visibleWidgets,
  hiddenWidgets,
  allWidget,
) => {
  const disabledInboxWidgets = allWidget.filter(({ inbox_app_id }) =>
      hiddenWidgets.includes(inbox_app_id),
    ),
    enabledInboxWidgets = visibleWidgets.reduce((acc, data) => {
      let widget = allWidget.find(({ inbox_app_id }) => inbox_app_id === data);
      if (widget) return [...acc, widget];
      return acc;
    }, []),
    remainingWidgets = allWidget.filter(
      ({ inbox_app_id: id }) =>
        ![...disabledInboxWidgets, ...enabledInboxWidgets].some(
          ({ inbox_app_id }) => inbox_app_id === id,
        ),
    );

  return {
    enabledInboxWidgets: [...enabledInboxWidgets, ...remainingWidgets],
    disabledInboxWidgets,
  };
};

export const checkIsConversationMatchesCurrentFilter = conversation => {
  const {
      assigned_to_bot,
      member_id,
      team_id,
      mention_id,
      matching_inbox_view_ids,
      spam_status,
    } = conversation,
    { inboxFilter } = store.getState().Chat;

  if (inboxFilter === 'bot') {
    return assigned_to_bot;
  } else if (inboxFilter.includes('team_mate')) {
    return (
      member_id && inboxFilter.split('team_mate-')[1] === member_id.toString()
    );
  } else if (inboxFilter.includes('team')) {
    return team_id && inboxFilter.split('team-')[1] === team_id.toString();
  } else if (inboxFilter === 'IB1') {
    return member_id === extractCurrentUserId();
  } else if (inboxFilter === 'IB3') {
    return !assigned_to_bot && !member_id && !team_id;
  } else if (inboxFilter === 'IB2') {
    return (
      mention_id &&
      mention_id.length &&
      mention_id.includes(extractCurrentUserId())
    );
  } else if (inboxFilter === 'IB4') {
    return true;
  } else if (inboxFilter === 'IB5') {
    return !spam_status;
  } else if (inboxFilter.includes('custom-inbox-view')) {
    return (
      matching_inbox_view_ids &&
      matching_inbox_view_ids.includes(
        Number(inboxFilter.split('custom-inbox-view-')[1]),
      )
    );
  } else {
    return false;
  }
};

//To ignore the other messages and get the last person or agent message
export const findLastPersonOrAgentMessage = messages =>
  [...messages]
    .reverse()
    .find(
      message =>
        message.message_display_type !== 'status_message' &&
        message.message_type !== 'note' &&
        !message.is_attachment &&
        !message.is_gif &&
        ['person_message', 'agent_message'].includes(
          message.message_display_type,
        ),
    );

export const getNewStatusOptions = currentStatus => {
  if (currentStatus === 'closed') {
    return [{ label: 'open', value: 'Reopen' }];
  } else if (currentStatus === 'open') {
    return [{ label: 'closed', value: 'Close' }];
  } else {
    return [
      { label: 'open', value: 'Reopen' },
      { label: 'closed', value: 'Close' },
    ];
  }
};

export const formatUnixTimestamp = unixTimestamp => {
  const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}/${month}/${year}`;
};

export const formatUnixTimestampWithTime = unixTimestamp => {
  const dateTime = moment.unix(unixTimestamp);
  return dateTime.format('DD/MM/YYYY, h:mm:ss');
};
