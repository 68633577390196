import React, { useEffect, useState } from 'react';
import sharedActions from '../../redux/sharedReducers/actions';
import {
  extractCurrentPersonId,
  getLastPersonOrAgentMessage,
  openLinkInNewTab,
  setSnoozeCalculation,
} from '../../helpers/utility';
import chatActions from '../../redux/chat/actions';
import { Modal } from 'antd';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import '../Chat/Messages/style.scss';
import AskEmmaModal from '../Chat/Conversations/AskEmmaModal';
import ArticleWidget from './OmniSearch/ArticleWidget';
import AIAssistWidget from './OmniSearch/AIAssistWidget';
import DefaultWidget from './OmniSearch/DefaultWidget';
import SnoozeWidget from './OmniSearch/SnoozeWidget';
import AssignWidget from './OmniSearch/AssignWidget';
import TagConversationWidget from './OmniSearch/TagConversationWidget';
import TagContactWidget from './OmniSearch/TagContactWidget';
import SnippetWidget from './OmniSearch/SnippetWidget';
import MeetingWidget from './OmniSearch/MeetingWidget';

const ShortcutKeys = ({ shortcuts }) => (
  <span className="shortcut-indicator">
    {shortcuts.map((shortcut, key) => (
      <span
        className={shortcut !== '' ? 'shortcut-key' : 'no-shortcut-key'}
        key={key}
      >
        {shortcut !== '' ? shortcut : 'then'}
      </span>
    ))}
  </span>
);
const OmniSearch = props => {
  const dispatch = useDispatch();
  const currentConversationId = useSelector(
    ({ Chat }) => Chat.currentConversationId,
  );
  const displayOmniSearch = useSelector(
      ({ SharedReducers }) => SharedReducers.displayOmniSearch,
    ),
    showHideCustomSnoozeModal = useSelector(
      ({ SharedReducers }) => SharedReducers.displaySnoozeModal,
    ),
    currentOmniSearchState = useSelector(
      ({ SharedReducers }) => SharedReducers.currentOmniSearchState,
    ),
    showNewConversation = useSelector(({ Chat }) => Chat.showNewConversation),
    currentReplyEditor = useSelector(
      ({ SharedReducers }) => SharedReducers.replyEditor,
    ),
    replyEditor =
      currentReplyEditor &&
      currentReplyEditor[
        currentConversationId || (showNewConversation ? 'new_message' : null)
      ],
    currentNoteEditor = useSelector(
      ({ SharedReducers }) => SharedReducers.noteEditor,
    ),
    noteEditor = currentNoteEditor && currentNoteEditor[currentConversationId],
    isComposerUndo = useSelector(
      ({ SharedReducers }) => SharedReducers.isComposerUndo,
    ),
    currentConversation = useSelector(({ Chat }) =>
      Chat.chatRooms.find(
        (conversation) =>
          conversation.conversation_identifier === currentConversationId,
      ),
    ),
    activeConversationMessages = useSelector(
      ({ Chat }) => Chat.conversationsMessageData[currentConversationId],
    ),
    tabActiveKey = useSelector(({ Chat }) => Chat.activeComposerTab),
    activeComposerTab = useSelector(
      ({ Chat }) =>
        Chat.activeComposerTabList[currentConversationId] ||
        tabActiveKey ||
        'reply',
    ),
    askEmmaMatchFound = useSelector(({ Chat }) => Chat.askEmmaMatchFound),
    aiAssistMode = useSelector(({ Chat }) => Chat.aiAssistMode),
    agentTypedNoteMessages = useSelector(
      ({ Chat }) => Chat.agentTypedNoteMessages,
    ),
    agentTypedReplyMessages = useSelector(
      ({ Chat }) => Chat.agentTypedReplyMessages,
    ),
    isFromPreviousConversation = useSelector(
      ({ Chat }) => Chat.isFromPreviousConversation,
    ),
    selectedPreviousConversation = useSelector(
      ({ Chat }) => Chat.selectedPreviousConversation,
    );

  const activeConversation = isFromPreviousConversation
    ? selectedPreviousConversation
    : currentConversation || {};

  const supportedLanguages = useSelector(({KnowledgeBase}) => KnowledgeBase.supportedLanguages);
  const selectedArticleLanguage = useSelector(({SharedReducers}) => SharedReducers.selectedArticleLanguage);


  const [selectedCommand, setSelectedCommand] = useState(undefined);

  useEffect(() => {
    document.addEventListener('click', hideOmniSearch, true);

    return () => {
      removeOmniSearchListener();
      document.removeEventListener('click', hideOmniSearch, true);
    };
  }, []);

  useEffect(
    () => {
      if (displayOmniSearch) {
        omniSearchEventListener();
      }
    },
    [displayOmniSearch],
  );

  const onReplyValueChange = value => {
    dispatch({
      type: chatActions.SET_REPLY_COMPOSERS_VALUE,
      payload: value,
      conversationId: currentConversationId,
    });
  };

  const onNoteValueChange = value => {
    dispatch({
      type: chatActions.SET_NOTE_COMPOSERS_VALUE,
      payload: value,
      conversationId: currentConversationId,
    });
  };

  const pushContentIntoComposer = (value, withOutWhitespace = false) => {
    const tabActiveKey = activeComposerTab;
    const tabKeyBasedEditor =
      tabActiveKey === 'reply' ? replyEditor : noteEditor;
    tabKeyBasedEditor.edit.on();
    tabKeyBasedEditor.selection.restore();
    if (isComposerUndo) {
      tabKeyBasedEditor.commands.undo();
      dispatch({
        type: sharedActions.CHANGE_COMPOSER_UNDO_STATUS,
        payload: false,
      });
    }
    // if (articleLink) {
    //   value = `<a href="${value}" target="_blank" rel="noreferrer noopener nofollow">${value}<a/>`;
    // }
    value = value.replace(/\r?\n/g, '<br>');
    tabKeyBasedEditor.events.focus();
    tabKeyBasedEditor.html.insert(`${value}${withOutWhitespace ? '' : ' '}`);
    tabKeyBasedEditor.undo.saveStep();
    tabKeyBasedEditor.selection.save();
    tabKeyBasedEditor.selection.restore();
    if (tabActiveKey === 'reply') {
      onReplyValueChange(tabKeyBasedEditor.html.get(true));
    } else {
      onNoteValueChange(tabKeyBasedEditor.html.get(true));
    }
    // TODO Jitta - What is the purpose of this?
    // setTimeout(focusEditor, 50);
  };

  const handleSnippetVisibleChange = showSnippet => {
    const tabActiveKey = activeComposerTab;
    if (showSnippet) {
      setTimeout(() => {
        const search = document.getElementById('snippetSearch');
        if (search) {
          document.getElementById('snippetSearch').focus();
        }
      }, 100);
    }
    if (!showSnippet) {
      if (tabActiveKey === 'reply') {
        replyEditor && replyEditor.edit.on();
      } else {
        noteEditor && noteEditor.edit.on();
      }
    }
  };

  const getSVG = name => {
    // Mapping icons to the corresponding names for a more scalable approach.
    const iconMap = {
      AskEmma: 'fal fa-info',
      WriteReply: 'fal fa-reply',
      AddNote: 'fal fa-sticky-note',
      InsertSnippet: 'fal fa-bookmark',
      InsertArticle: 'fal fa-file-alt',
      Attachment: 'fal fa-paperclip',
      Image: 'fal fa-image',
      Calendar: 'fal fa-calendar',
      AssignTo: 'fal fa-users-medical',
      AssignToMe: 'fal fa-user-plus',
      Close: 'fal fa-inbox-in',
      Snooze: 'fal fa-clock',
      Reopen: 'fal fa-inbox-out',
      Priority: 'fal fa-star',
      TagMessage: 'fal fa-tag',
      TagContact: 'fal fa-user-tag',
      GoTo: 'fal fa-arrow-right',
      New: 'fal fa-plus',
      Help: 'fal fa-info-circle',
      API: 'fal fa-book',
      Keyboard: 'fal fa-keyboard',
      NewMessage: 'fal fa-keyboard',
      AiAssist: null,
    };

    if (name === 'AiAssist') {
      return <span className="ai-icon">AI</span>;
    }
    return <i className={iconMap[name] || ''} />;
  };

  const getGroupItem = (group, items) =>
    items.length > 0 &&
    group.id !== 'nogroup' && (
      <div>
        {/*<div className="command-separator-container">*/}
        {/*  <div className="command-separator" />*/}
        {/*</div>*/}
        <div className="list-heading-container">
          <span className="list-heading">{group.label}</span>
        </div>
      </div>
    );

  const getCommandItem = item => (
    <div className="command-item">
      <div className="command-item-inner">
        <div className="icon-container">{getSVG(item.icon)}</div>
        <span className="command-content">
          <span className="command-title">{item.name}</span>
          {item.shortcut_key && <ShortcutKeys shortcuts={item.shortcut_key} />}
          {item.custom_text && (
            <span className="custom-text">{item.custom_text}</span>
          )}
        </span>
      </div>
    </div>
  );

  const handleCommandSelect = command => {
    if (command.groupId === 5) {
      openLinkInNewTab(command.url);
    } else {
      if (
        command.groupId !== 1 ||
        (command.groupId === 1 &&
          (activeConversation.id || command.action === 'ask_emma'))
      ) {
        props.executeShortcutCommand(command.action);
        setSelectedCommand(command);
      }
    }

    if (
      ![
        'snooze_conversation',
        'assign_to',
        'snippet',
        'tag_conversation',
        'article',
        'meeting',
        'tag_contact',
        'ai_assist',
      ].includes(command.action)
    ) {
      hideOmniSearch();
    }
  };

  const hideOmniSearch = e => {
    if (
      !e ||
      e.keyCode === 27 ||
      e.target.className === 'omnisearch-component'
    ) {
      if (e) e.preventDefault();
      dispatch({
        type: sharedActions.SHOW_HIDE_OMNISEARCH,
        payload: false,
      });
      if (isComposerUndo) {
        pushContentIntoComposer('#', true);
      }
    }
  };

  const omniSearchEventListener = () => {
    let jungleDOM = document.getElementsByClassName(
      'jungle-select-controls',
    )[0];
    let inputDOM = jungleDOM && jungleDOM.getElementsByTagName('input')[0];
    if (inputDOM) {
      inputDOM.addEventListener('keydown', hideOmniSearch);
    }
  };

  const removeOmniSearchListener = () => {
    let inputDOM = document.getElementsByClassName('jungle-select-controls')[0];
    if (inputDOM) {
      let inputDOMTag = inputDOM.getElementsByTagName('input')[0];
      inputDOMTag.removeEventListener('keydown', hideOmniSearch);
    }
  };

  // Custom Snooze Modal Related functions
  const [customSnoozeTime, setCustomSnoozeTime] = useState(null),
    [snoozeButtonStatus, setSnoozeButtonStatus] = useState(false);

  const setSnoozeTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    if (selectedDate >= currentDate) {
      setSnoozeButtonStatus(false);
    } else {
      setSnoozeButtonStatus(true);
    }
    setCustomSnoozeTime(time);
  };

  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const hideCustomSnoozeModal = () => {
    dispatch({
      type: sharedActions.SHOW_HIDE_CUSTOM_SNOOZE_MODAL,
      payload: false,
    });
  };

  const snoozeConversation = value => {
    hideCustomSnoozeModal();
    let selectedTime =
      value !== 'custom'
        ? setSnoozeCalculation(value)
        : { format: 'custom', time: customSnoozeTime };
    dispatch({
      type: chatActions.SNOOZE_CONVERSATION,
      conversation_identifiers: [currentConversationId],
      select_all: false,
      time_key: selectedTime.format,
      time_value: selectedTime.time,
      currentStatus: activeConversation.status,
      isFromPreviousConversation: isFromPreviousConversation,
    });

    dispatch({
      type: chatActions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
      payload: {
        conversation_identifier: currentConversationId,
        status: 'pending',
        isFromPreviousConversation: isFromPreviousConversation,
      },
    });

    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  return (
    <>
      <AskEmmaModal
        pushContentIntoComposer={pushContentIntoComposer}
        currentConversationId={currentConversationId}
        showNewConversation={showNewConversation}
      />
      {displayOmniSearch && (
        <div className="omnisearch-component">
          <div className="omnisearch-modal">
            <div className="command-list-container">
              <div className="command-list-inner">
                {currentOmniSearchState === 'default' && (
                  <DefaultWidget
                    selectedCommand={selectedCommand}
                    activeConversation={activeConversation}
                    getCommandItem={getCommandItem}
                    getGroupItem={getGroupItem}
                    handleCommandSelect={handleCommandSelect}
                    activeConversationMessages={activeConversationMessages}
                    showNewConversation={showNewConversation}
                  />
                )}

                {currentOmniSearchState === 'snooze' && (
                  <SnoozeWidget
                    getCommandItem={getCommandItem}
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                    snoozeConversation={snoozeConversation}
                  />
                )}

                {currentOmniSearchState === 'assign_to' && (
                  <AssignWidget
                    getCommandItem={getCommandItem}
                    getGroupItem={getGroupItem}
                    activeConversation={activeConversation}
                    participants={
                      activeConversationMessages &&
                      activeConversationMessages.participant_ids
                        ? activeConversationMessages.participant_ids
                        : []
                    }
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                  />
                )}

                {currentOmniSearchState === 'tag_conversation' && (
                  <TagConversationWidget
                    activeConversation={activeConversation}
                    message={getLastPersonOrAgentMessage(
                      activeConversationMessages,
                    )}
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                  />
                )}

                {currentOmniSearchState === 'tag_contact' && (
                  <TagContactWidget
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                  />
                )}

                {currentOmniSearchState === 'snippet' && (
                  <SnippetWidget
                    getGroupItem={getGroupItem}
                    pushContentIntoComposer={pushContentIntoComposer}
                    personId={extractCurrentPersonId()}
                    tabActiveKey={activeComposerTab}
                    handleSnippetVisibleChange={handleSnippetVisibleChange}
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                    activeConversationId={
                      activeConversation.conversation_identifier
                    }
                  />
                )}

                {currentOmniSearchState === 'article' && (
                  <ArticleWidget
                    supportedLanguages={supportedLanguages}
                    selectedArticleLanguage={selectedArticleLanguage}
                    pushContentIntoComposer={pushContentIntoComposer}
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                  />
                )}

                {currentOmniSearchState === 'meeting' && (
                  <MeetingWidget
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                  />
                )}

                {currentOmniSearchState === 'ai_assist' && (
                  <AIAssistWidget
                    omniSearchEventListener={omniSearchEventListener}
                    removeOmniSearchListener={removeOmniSearchListener}
                    tabActiveKey={activeComposerTab}
                    agentTypedReplyMessages={agentTypedReplyMessages}
                    agentTypedNoteMessages={agentTypedNoteMessages}
                    activeConversationId={currentConversationId}
                    askEmmaMatchFound={askEmmaMatchFound}
                    replyEditor={replyEditor}
                    noteEditor={noteEditor}
                    aiAssistMode={aiAssistMode}
                    showNewConversation={showNewConversation}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
      )}
      <Modal
        visible={showHideCustomSnoozeModal}
        wrapClassName={'custom-snooze-modal'}
        destroyOnClose={true}
        onCancel={hideCustomSnoozeModal}
        okText="Snooze"
        onOk={() => snoozeConversation('custom')}
        okButtonProps={{ disabled: snoozeButtonStatus }}
      >
        <DatePicker
          selected={customSnoozeTime}
          onChange={date => setSnoozeTime(date)}
          filterTime={filterPassedTime}
          dateFormat="DD/MM/YYYY HH:mm Z"
          showTimeSelect
          inline
        />
      </Modal>
    </>
  );
};

export default OmniSearch;
