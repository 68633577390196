import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isSettingsRestrictedTeam } from '../../helpers/utility';
import NotAuthorised from '../Page/NotAuthorised';
import NotFound from '../Page/404';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() =>
      import('../../containers/AppPlatform/DeveloperHome'),
    ),
  },
  {
    path: 'apps',
    component: asyncComponent(() =>
      import('../../containers/AppPlatform/AppsList'),
    ),
  },
  {
    path: 'app/:app_id',
    component: asyncComponent(() =>
      import('../../containers/AppPlatform/AppEditorTabs'),
    ),
  },
  {
    path: 'canvas-builder',
    component: asyncComponent(() =>
      import('../../containers/AppPlatform/CanvasBuilder'),
    ),
  },
];

const DeveloperAppRouter = ({ url, projectBlocked }) => {
  if (projectBlocked) {
    return (
      <Route
        path={`${url}`}
        component={asyncComponent(() => import('../Page/ProjectBlocked.js'))}
      />
    );
  }
  return (
    <Fragment>
      <Switch>
        {routes.map(singleRoute => {
          let {
            path,
            exact,
            redirect,
            settingsRout = true,
            ...otherProps
          } = singleRoute;
          if (isSettingsRestrictedTeam() && settingsRout) {
            return (
              <Route
                exact={exact !== false}
                key={path}
                path={`${url}/${path}`}
                component={NotAuthorised}
              />
            );
          } else if (redirect !== undefined) {
            return (
              <Route
                exact={exact !== false}
                key={path}
                path={`${url}/${path}`}
                render={() => (
                  <Redirect from={`${url}/${path}`} to={`${url}/${redirect}`} />
                )}
              />
            );
          } else {
            return (
              <Route
                exact={exact !== false}
                key={path}
                path={`${url}/${path}`}
                {...otherProps}
              />
            );
          }
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    </Fragment>
  );
};

export default DeveloperAppRouter;
