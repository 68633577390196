import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../store';
import settings from '../../../settings';

export function* fetchMessageTemplateList(params) {
  try {
    let status = '',
      filterCategoryId = '';
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().MessageTemplateReducer.selectedTemplateStatus;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_templates?status=${status}&feature_type=${
      params.messageType
    }`;
    if (params.fromTemplateList) {
      filterCategoryId = store.getState().MessageTemplateReducer
        .filterCategoryId;
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/message_templates?status=published&feature_type=${
        params.messageType
      }&template_category_id=${filterCategoryId}`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_MESSAGE_TEMPLATE_LIST_SUCCESS,
      payload: data.data,
    });
    if (params.fromTemplateList) {
      let templateSearchValue = store.getState().MessageTemplateReducer
        .templateSearchValue;
      if (templateSearchValue) {
        yield put({
          type: actions.FILTER_MESSAGE_TEMPLATE_LIST,
          payload: templateSearchValue,
        });
      }
      yield put({
        type: actions.SET_MESSAGE_DEFAULT_TEMPLATE_DETAILS,
        payload: { ...data.data.default_content },
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_MESSAGE_TEMPLATE_LIST_FAILURE,
    });
    message.error('Fetch chat template list failed');
  }
}

export function* getCategoryList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/template_categories?feature_type=${
      params.messageType
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_MESSAGE_CATEGORIES_LISTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch chat category list failed');
  }
}

export function* createMessageTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_templates`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_MESSAGE_TEMPLATE_ID,
      id: data.data.message_template.id,
    });
    message.success(data.message);
    if (params.messageType === 'chat') {
      updateBrowserHistory(`chat-templates/${data.data.message_template.id}`);
    } else {
      updateBrowserHistory(`post-templates/${data.data.message_template.id}`);
    }
    yield put({
      type: actions.CREATE_MESSAGE_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_MESSAGE_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* deleteMessageTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_templates/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_MESSAGE_TEMPLATE_LIST,
      status: store.getState().MessageTemplateReducer.selectedTemplateStatus,
      messageType: params.messageType,
    });
    yield put({
      type: actions.DELETE_MESSAGE_TEMPLATE_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_MESSAGE_TEMPLATE_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* updateMessageTemplate(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_templates/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_MESSAGE_TEMPLATE_SUCCESS,
      payload: data.data.message_template,
      isPublishAction: params.isPublishAction,
    });
    if (params.isFromTemplateTable) {
      yield put({
        type: actions.FETCH_MESSAGE_TEMPLATE_LIST,
        status: store.getState().MessageTemplateReducer.selectedTemplateStatus,
        messageType: params.messageType,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_MESSAGE_TEMPLATE_FAILURE,
      changeStatus: params.changeStatus,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Chat template update failed');
    }
  }
}

export function* duplicateMessageTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_templates/${
      params.id
    }/duplicate`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_MESSAGE_TEMPLATE_ID,
      id: data.data.message_template.id,
    });
    message.success(data.message);
    if (params.messageType === 'chat') {
      updateBrowserHistory(`chat-templates/${data.data.message_template.id}`);
    } else {
      updateBrowserHistory(`post-templates/${data.data.message_template.id}`);
    }
    yield put({
      type: actions.DUPLICATE_MESSAGE_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_MESSAGE_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_MESSAGE_TEMPLATE_LIST, fetchMessageTemplateList),
    takeEvery(actions.GET_MESSAGE_CATEGORIES_LISTS, getCategoryList),
    takeEvery(actions.CREATE_MESSAGE_TEMPLATE, createMessageTemplate),
    takeEvery(actions.DELETE_MESSAGE_TEMPLATE, deleteMessageTemplate),
    takeEvery(actions.UPDATE_MESSAGE_TEMPLATE, updateMessageTemplate),
    takeEvery(actions.DUPLICATE_MESSAGE_TEMPLATE, duplicateMessageTemplate),
  ]);
}
