//This filter component is global component to generate filters,
// helper.js inside filters directory will contain functionality that are commonly used for filters.
// If you want to use this filter component to any module need to add constants in file 'src/redux/constants' file
// In this file need to update variables 'filterVariables' & 'filterActionNames' make sure filterVariables values and
// filterActionNames key should be same.
// The filters storing variable will be dynamically generated (eg. if you have 'contacts' is one of the value of 'filterVariables'
// then the variable 'contactsAudienceFilters' will be auto generated and will have filters JSON that have applied in contacts module).

//If you want to perform any action while updating the filters need to have method performFilterAction in your component,
// the filter validation(true/false) will be returned to this function as a parameter. and also the validation also stored in store with
// dynamic name (eg. for contacts module, variable name will be contactsFilterValidation)

// In your module, if you want to have filter need to import Filters/PerformedFilter.js & Filters/Action.js.
// need segment dropdown? import Filters/segmentList.js
// need recipients count? import Filters/TotalRecipientsCount.js
//need recipients count? and recipient list import Filters/TotalRecipients.js

// Method getAudienceFilterCount in 'src/Filters/helper.js' will be common function to get filtered count can use this function in
// performFilterAction method of your module, getAudienceFilterCount function needs filterName(eg.contacts(filterVariables.contactsKey))
// as parameter, to get the count of contacts module, filterName is needed to take filters from 'contactsAudienceFilters' variable.

import { filterActionNames, filterDefaultActions } from '../../redux/constants';
import { store } from '../../redux/store';
import filterActions from '../../redux/filters/actions';
import { fetchRecipientsTimeInterval } from '../Email/constants';
import _ from 'lodash';

//method to generate dynamic actions and variables required to use filter reducer in multiple modules
export function generateFilterActions() {
  let actions = {},
    audienceFilters = {},
    validations = {};
  Object.keys(filterActionNames).forEach(actionKey => {
    let actionValue = filterActionNames[actionKey];
    for (let filter of filterDefaultActions) {
      actions = {
        ...actions,
        [`${actionValue}_${filter}`]: `${actionValue}_${filter}`,
      };
      audienceFilters = {
        ...audienceFilters,
        [`${actionKey}AudienceFilters`]: {
          new_filters: [],
        },
      };
      validations = {
        ...validations,
        [`${actionKey}FilterValidation`]: false,
      };
    }
  });
  return { actions, audienceFilters, validations };
}

//method to check filter is valid or not
export function filterApplied(params) {
  let filters = params.property && params.property.new_filters,
    propertyList = params.propertyList,
    eventList = params.eventsList,
    isFilterApplied = true;
  if (filters && filters.length !== 0 && propertyList && eventList) {
    for (let filter of filters) {
      for (let criteria of filter.criteria) {
        let type = propertyList.filter(
          property => property.property_name === criteria.key,
        );
        if (type.length !== 0 && type[0].property_type === 'user_type') {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            !['', null, undefined, 'Invalid date'].includes(criteria.value);
        } else if (
          ['has_any_value', 'has_no_value'].includes(criteria.condition)
        ) {
          isFilterApplied =
            isFilterApplied && criteria.key && criteria.condition;
        } else if (
          type.length !== 0 &&
          type[0].property_type === 'email_list'
        ) {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            !['', null, undefined, 'Invalid date'].includes(criteria.value) &&
            criteria.condition &&
            !['', null, undefined].includes(criteria.mail_filter_id);
        } else if (type.length !== 0 && type[0].property_type === 'email_url') {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            criteria.condition &&
            !['', null, undefined, 'Invalid date'].includes(criteria.value);
        } else if (type.length !== 0 && type[0].property_type === 'time_zone') {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            criteria.time_zone_name &&
            criteria.condition;
        } else if (type.length !== 0 && type[0].property_type === 'campaign') {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            criteria.campaign_id &&
            criteria.condition;
        } else if (
          type.length !== 0 &&
          type[0].property_name === 'contact_owner'
        ) {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            criteria.condition &&
            (Array.isArray(criteria.value)
              ? !!criteria.value.length
              : !!criteria.value);
        } else if (type.length !== 0 && type[0].property_type === 'date') {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            criteria.condition &&
            validateCriteria(criteria);
        } else if (
          criteria.event_id !== undefined &&
          criteria.time_window &&
          criteria.time_window.condition
        ) {
          let eventValue = eventList.event_meta_data.filter(
            event => event.value === criteria.condition,
          );
          if (eventValue.length !== 0 && eventValue[0].has_value) {
            isFilterApplied =
              isFilterApplied &&
              criteria.key &&
              !['', null, undefined, 'Invalid date'].includes(criteria.value) &&
              criteria.condition &&
              criteria.event_id;
          } else {
            isFilterApplied =
              isFilterApplied &&
              criteria.key &&
              criteria.condition &&
              criteria.event_id;
          }
          if (criteria.time_window.condition === 'within') {
            isFilterApplied =
              isFilterApplied &&
              criteria.time_window.condition &&
              ![null, undefined].includes(criteria.time_window.value);
          } else if (criteria.time_window.condition === 'in_between') {
            isFilterApplied =
              isFilterApplied &&
              criteria.time_window.condition &&
              criteria.time_window.value_1 &&
              criteria.time_window.value_2;
          }
        } else {
          isFilterApplied =
            isFilterApplied &&
            criteria.key &&
            criteria.condition &&
            validateCriteria(criteria);
        }
      }
    }
  }
  return isFilterApplied;
}

export function getAudienceFilterCount(
  filterName,
  isGetContacts,
  filterParams,
  isFromBroadcastEmail,
) {
  fetchRecipientsCount(
    filterName,
    isGetContacts,
    filterParams,
    isFromBroadcastEmail,
  );
}

let fetchRecipientsCount = _.debounce(
  (
    filterName,
    isGetContacts = false,
    filterParams = false,
    isFromBroadcastEmail = false,
  ) => {
    let filter = store.getState().Filter[`${filterName}AudienceFilters`];
    if (store.getState().Filter[`${filterName}FilterValidation`]) {
      store.dispatch({
        type: filterActions.FILTER_FETCH_RECIPIENTS_COUNT,
        payload: filter,
        isGetContacts,
        filterParams,
        isFromBroadcastEmail,
      });
    }
  },
  fetchRecipientsTimeInterval,
);

export function validateFilters(filterName) {
  const filterData = store.getState().Filter;
  const recipientFilters = {
    property: filterData[`${filterName}AudienceFilters`],
    propertyList: filterData.allPropertiesLists,
    eventsList: filterData.eventsMetaData,
  };
  return filterApplied(recipientFilters);
}

export function updateFilterValidationInStore(filterName, data) {
  store.dispatch({
    type: filterActions.SET_FILTER_VALIDATION,
    payload: data,
    filterName,
  });
}

export function onFilterUpdate(
  filterName,
  performFilterAction,
  isFromRemoveProperty,
) {
  let isValid = validateFilters(filterName);
  updateFilterValidationInStore(filterName, isValid);
  performFilterAction && performFilterAction(isValid, isFromRemoveProperty);
}

export function isWorkflowTemplateRelated(addedPropertyAndEvent) {
  const { filters } = store.getState().Filter;
  if (addedPropertyAndEvent.new_filters) {
    let isWorkflowTemplateRelated = false;
    _.filter(addedPropertyAndEvent.new_filters, function(property) {
      _.filter(property.criteria, function(data) {
        if (
          ['subscribed_to', 'last_marketing_email_opened_date'].includes(
            data.key,
          )
        ) {
          if (data.mail_filter_id) {
            let mailStatus = _.find(filters.email, { id: data.mail_filter_id });
            isWorkflowTemplateRelated =
              mailStatus &&
              mailStatus.status === 'draft' &&
              mailStatus.mail_type !== 'automation';
          } else {
            let mailStatus = _.find(filters.campaign, { id: data.campaign_id });
            isWorkflowTemplateRelated =
              mailStatus && mailStatus.status === 'draft';
          }
        }
      });
    });
    return isWorkflowTemplateRelated;
  }
}

export function validateCriteria(criteria) {
  if (criteria.condition === 'between_date') {
    return (
      !['', null, undefined, 'Invalid date'].includes(criteria.from_date) &&
      !['', null, undefined, 'Invalid date'].includes(criteria.to_date)
    );
  }
  return !['', null, undefined, 'Invalid date'].includes(criteria.value);
}
