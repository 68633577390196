import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/auth/actions';
import './style.scss';
import { Icon, Spin } from 'antd';
import { store, history } from '../../redux/store';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';
import UnsubscriptionDetails from './UnsubscriptionDetails';

const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />;

class Unsubscription extends Component {
  constructor(props) {
    super(props);
    if (window.location) {
      const { token } = queryString.parse(window.location.search);
      if (token) {
        store.dispatch({
          type: actions.GET_UNSUBSCRIPTION_PERSON_DATA,
          token,
        });
      } else {
        history.push('/404');
      }
    }
  }

  render() {
    const { loader } = this.props;
    return (
      <div className="unsubscription-container">
        {loader ? (
          <Spin indicator={antIcon} className="spinner-align" />
        ) : (
          <UnsubscriptionDetails {...this.props} />
        )}
      </div>
    );
  }
}

Unsubscription.propTypes = {
  unsubscriptionData: PropTypes.object,
  subscriptionTypes: PropTypes.array,
  contactSubscriptions: PropTypes.array,
  loader: PropTypes.bool,
  buttonLoader: PropTypes.bool,
  linkLoader: PropTypes.bool,
  isEmptySubscriptions: PropTypes.bool,
  isResubscribeEmails: PropTypes.bool,
  alertMessage: PropTypes.string,
};

function mapStateToProps(state) {
  const {
    unsubscriptionData,
    subscriptionTypes,
    contactSubscriptions,
    loader,
    buttonLoader,
    linkLoader,
    isEmptySubscriptions,
    isResubscribeEmails,
    alertMessage,
    isFetchUnsubscriptionFailed,
  } = state.Auth;
  return {
    unsubscriptionData,
    subscriptionTypes,
    contactSubscriptions,
    loader,
    buttonLoader,
    linkLoader,
    isEmptySubscriptions,
    isResubscribeEmails,
    alertMessage,
    isFetchUnsubscriptionFailed,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(Unsubscription);
