import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
  unProcessableEntity,
} from '../../../helpers/utility';
import { message, notification } from 'antd';
import { store } from '../../../redux/store';
import { AUTO_EMAIL_TYPE, filterVariables } from '../../constants';
import filterActions from '../../filters/actions';

export function* fetchAutoEmail(params) {
  try {
    let searchValue = '',
      sortKey = '',
      sortValue = '',
      tagId = '',
      status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().AutoEmail.searchValue;
    }
    if (params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortValue = params.sortOrder;
    } else {
      sortKey = store.getState().AutoEmail.sortKey;
      sortValue = store.getState().AutoEmail.sortValue;
    }
    if (params.tagId) {
      tagId = params.tagId;
    } else {
      tagId = store.getState().AutoEmail.autoMailTagId;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().AutoEmail.autoEmailStatus;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters?mail_type=${
      params.mailType
    }&status=${status}&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${encodeURIComponent(
      searchValue,
    )}&tag_ids=${tagId}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AUTO_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch emails failed');
  }
}

export function* appendAutoEmail(params) {
  try {
    let searchValue = '';
    let sortKey = '';
    let sortValue = '';
    let tagId = '';
    const { payload } = params;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().AutoEmail.searchValue;
    }
    if (
      store.getState().AutoEmail.sortKey &&
      store.getState().AutoEmail.sortValue
    ) {
      sortKey = store.getState().AutoEmail.sortKey;
      sortValue = store.getState().AutoEmail.sortValue;
    }
    let lastAutoEmailId = '';
    if (payload && payload.data.lastAutoEmailId) {
      lastAutoEmailId = payload.data.lastAutoEmailId;
    }
    if (params.tagId) {
      tagId = params.tagId;
    } else {
      tagId = store.getState().AutoEmail.autoMailTagId;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters?mail_type=${AUTO_EMAIL_TYPE}&status=${
      store.getState().AutoEmail.autoEmailStatus
    }&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${encodeURIComponent(
      searchValue,
    )}&tag_ids=${tagId}&last_mail_filter_id=${lastAutoEmailId}`;
    const data = yield call(() => getPromise(url));
    yield put({ type: actions.APPEND_AUTO_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch emails failed');
  }
}

export function* fetchTemplates(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/templates_list?template_type=${params.templateType}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AUTO_TEMPLATES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch template failed');
  }
}

export function* createAutoEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/mail_create`;
    const data = yield call(() => postPromise(url, params.object));
    trackEvent('Created ongoing email');
    yield put({
      type: actions.CREATE_AUTO_EMAIL_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.FETCH_AUTO_EMAIL_DATA,
      id: data.id,
    });
    yield put({
      type: actions.OPEN_AUTO_EMAIL_TEMPLATE_DRAWER,
      payload: false,
    });
    params.history.push({
      pathname: `/projects/${fetchProjectSecretKey()}/email/behavioral/${
        data.id
      }`,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Create behavioral Email failed');
    }
    yield put({
      type: actions.CREATE_AUTO_EMAIL_FAILURE,
    });
  }
}

export function* fetchComposeEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/edit/${params.id}`;
    const data = yield call(() => getPromise(url));
    if (params.duplicateRequest) {
      yield put({
        type: actions.SET_DUPLICATE_AUTO_EMAIL_DATA,
        payload: data,
      });
    } else {
      yield put({
        type: actions.FETCH_AUTO_EMAIL_DATA_SUCCESS,
        payload: data,
      });
    }
    yield put({
      type: filterActions.SET_FILTERS,
      filterName: filterVariables.autoEmailKey,
      payload: data.data.new_filters,
    });
    if (data.data.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.data.message_goal.goal_filter,
        filterName: filterVariables.autoEmailKeyGoal,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch email failed');
  }
}

export function* sendTestEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/send_test_mail`;
    const data = yield call(() => postPromise(url, params.object));
    message.success(data.message);
    yield put({ type: actions.SEND_AUTO_TEST_EMAIL_SUCCESS });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Something went wrong! Please contact support');
    }
    yield put({ type: actions.SEND_AUTO_EMAIL_TEST_EMAIL_FAILURE });
  }
}

export function* createEmailComposer(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/mail_composer/${
      params.autoEmailId
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.SET_AUTO_EMAIL_LAST_SAVE_TIME,
      payload: data.data,
    });
    yield put({
      type: actions.SET_COMPOSER_VALUE_CHANGE,
      payload: false,
    });
    if (params.isLive) {
      message.success('Email details updated successfully');
    }
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS,
      payload: false,
    });

    store.dispatch({
      type: actions.UPDATE_COMPOSER_DATA,
      payload: params.object.mail_filter,
    });
  } catch (error) {
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE,
      payload: true,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Save email failed');
    }
  }
}

export function* scheduleAutoEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/send_mail/${
      params.autoEmailId
    }`;
    const data = yield call(() => patchPromise(url, params.object));
    message.success(data.data.message);
    store.dispatch({
      type: actions.AUTO_EMAIL_SATUS_CHANGE_SUCCESS,
      id: params.autoEmailId,
      status: params.object.status,
      isFromReviewTab: params.isFromReviewTab,
    });
    if (params.isFromReviewTab) {
      updateBrowserHistory('messages/email');
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      notification['error']({
        message: 'Required',
        description: error.response.data.error.message.join(),
      });
    } else {
      message.error('Scheduled email failed');
    }
  }
}

export function* deleteAutoEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${params.id}`;
    yield call(() => deletePromise(url));
    if (params.fromPage) {
      store.dispatch({
        type: actions.SET_AUTO_EMAIL_TAB_KEY,
        payload: '1',
      });
      params.history.push(
        `/projects/${fetchProjectSecretKey()}/messages/email`,
      );
    } else {
      yield put({
        type: actions.FETCH_AUTO_EMAIL,
        mailType: AUTO_EMAIL_TYPE,
        status: store.getState().AutoEmail.autoEmailStatus,
      });
      store.dispatch({
        type: actions.DELETE_AUTO_EMAIL_SUCCESS,
      });
    }
    message.success('Email deleted successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Delete behavioral email failed');
    }
  }
}

export function* createSettings(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/settings/${
      params.payload.autoEmailID
    }`;
    const data = yield call(() => patchPromise(url, params.payload.data));
    yield put({
      type: actions.SET_AUTO_EMAIL_LAST_SAVE_TIME,
      payload: data.data,
    });
    if (params.isLive) {
      message.success('Settings updated successfully');
    }
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS,
      payload: false,
    });
    if(params.isFromMessageGoal) {
      store.dispatch({
        type: actions.UPDATE_SETTINGS_DATA,
        goalValues: params.payload.data.mail_filter.message_goal.time_limit.period
            ? params.payload.data.mail_filter.message_goal
            : null,
        isFromMessageGoal: params.isFromMessageGoal,
      });
    }
    else {
      store.dispatch({
        type: actions.UPDATE_SETTINGS_DATA,
        payload: params.payload.data.mail_filter,
        isFromMessageGoal: false,
      });
    }
  } catch (error) {
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE,
      payload: true,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Settings update failed');
    }
  }
}

export function* createDuplicateAutoEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/duplicate`;
    const data = yield call(() => postPromise(url, params.payload.object));
    yield put({
      type: actions.OPEN_AUTO_EMAIL_TEMPLATE_DRAWER,
      payload: false,
    });
    yield put({
      type: actions.SET_AUTO_EMAIL_ID,
      id: data.data.id,
    });
    yield put({
      type: actions.FETCH_AUTO_EMAIL_DATA,
      id: data.data.id,
    });
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_TAB_KEY,
      payload: '1',
    });
    params.payload.history.push(
      `/projects/${fetchProjectSecretKey()}/email/behavioral/${data.data.id}`,
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Create ongoing email failed');
    }
  }
}

export function* createRecipients(params) {
  let recipientsData = {
    mail_filter: store.getState().Filter.autoEmailAudienceFilters,
  };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/recipient_create/${
      params.autoEmailID
    }`;
    const data = yield call(() => patchPromise(url, recipientsData));
    yield put({
      type: actions.SET_AUTO_EMAIL_LAST_SAVE_TIME,
      payload: data.data,
    });
    yield put({
      type: actions.AUTO_EMAIL_RECIPIENTS_CREATE_SUCCESS,
      payload: data.data,
    });
    if (params.isLive) {
      message.success('Recipients updated successfully');
    }
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS,
      payload: false,
    });
    store.dispatch({
      type: actions.UPDATE_RECIPIENTS_DATA,
      payload: data.data,
    });
  } catch (error) {
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE,
      payload: true,
    });
    unAuthorisedRedirection(error);
    message.error('Create recipient failed');
  }
}

export function* getAudienceCount(params) {
  yield put({ type: actions.AUTO_MAIL_SET_RECIPIENT_LOADER, payload: true });
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/get_filtered_people_count?mail_filter_id=${
      params.autoEmailId
    }&validate_email_sending=true`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.AUTO_EMAIL_FETCH_RECIPIENTS_SUCCESS,
      payload: data,
    });
    yield put({ type: actions.AUTO_MAIL_SET_RECIPIENT_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch recipients count failed');
    yield put({
      type: actions.AUTO_EMAIL_FETCH_RECIPIENTS_FAILURE,
      payload:
        error.response.data &&
        error.response.data.data &&
        Object.keys(error.response.data.data).length > 0,
      count: error.response.data,
    });
    yield put({ type: actions.AUTO_MAIL_SET_RECIPIENT_LOADER, payload: false });
  }
}

export function* createAutoEmailSchedule(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/update_schedule_details/${
      params.autoEmailID
    }`;
    const data = yield call(() => patchPromise(url, params.payload));
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS,
      payload: false,
    });
    yield put({
      type: actions.SET_AUTO_EMAIL_LAST_SAVE_TIME,
      payload: data.data,
    });
  } catch (error) {
    store.dispatch({
      type: actions.SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE,
      payload: true,
    });
    unAuthorisedRedirection(error);
    message.error('Create Schedule failed');
  }
}

export function* fetchVisualBuilderJson(params) {
  try {
    let json = yield call(() =>
      getPromise(
        `${settings.BEE_EMAIL_TEMPLATE}${params.templateId}.json`,
        false,
      ),
    );
    yield put({
      type: actions.FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON_SUCCESS,
      payload: json,
    });
  } catch {
    message.error('JSON fetch failed');
  }
}

export function* fetchBeeToken(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }bee_free/token?project_secret_key=${fetchProjectSecretKey()}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AUTO_EMAIL_BEE_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    message.error('Fetch bee token failed');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_AUTO_EMAIL, fetchAutoEmail)]);
  yield all([takeEvery(actions.APPEND_AUTO_EMAIL, appendAutoEmail)]);
  yield all([takeEvery(actions.FETCH_AUTO_TEMPLATES, fetchTemplates)]);
  yield all([takeEvery(actions.CREATE_AUTO_EMAIL, createAutoEmail)]);
  yield all([takeEvery(actions.FETCH_AUTO_EMAIL_DATA, fetchComposeEmail)]);
  yield all([takeEvery(actions.SEND_AUTO_TEST_EMAIL, sendTestEmail)]);
  yield all([
    takeEvery(actions.CREATE_AUTO_EMAIL_COMPOSER, createEmailComposer),
  ]);
  yield all([takeEvery(actions.SCHEDULE_AUTO_EMAIL, scheduleAutoEmail)]);
  yield all([takeEvery(actions.DELETE_AUTO_EMAIL, deleteAutoEmail)]);
  yield all([takeEvery(actions.CREATE_AUTO_EMAIL_SETTINGS, createSettings)]);
  yield all([
    takeEvery(actions.CREATE_DUPLICATE_AUTO_EMAIL, createDuplicateAutoEmail),
  ]);
  yield all([
    takeEvery(actions.AUTO_EMAIL_CREATE_RECIPIENTS, createRecipients),
  ]);
  yield all([
    takeEvery(actions.AUTO_EMAIL_GET_AUDIENCE_COUNT, getAudienceCount),
  ]);
  yield all([
    takeEvery(actions.CREATE_AUTO_EMAIL_SCHEDULE, createAutoEmailSchedule),
  ]);
  yield all([takeEvery(actions.FETCH_AUTO_EMAIL_BEE_TOKEN, fetchBeeToken)]);
  yield all([
    takeEvery(
      actions.FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON,
      fetchVisualBuilderJson,
    ),
  ]);
}
