export const INBOX_RULE_TRIGGER_LIMIT = 5;
export const INBOX_RULE_CONDITION_LIMIT = 5;
export const INBOX_RULE_CONDITION_BLOCK_LIMIT = 10;
export const INBOX_RULE_ACTION_LIMIT = 5;
export const NUMBER_OF_TRIGGERS_IN_PREVIEW = 3;

export const defaultRuleData = {
  assignment_rule: {
    rule_name: 'New rule',
    status: 'draft',
    assignment_rule_triggers_attributes: [
      {
        trigger_name: 'inbound_conversation_started',
        trigger_data: {},
      },
    ],
    conditions: [
      {
        criteria: [
          {
            value: [],
            key: 'conversation_channel',
            condition: 'any',
            selectedType: 'multi_select',
            conditions: [
              {
                label: 'is any of',
                value: 'any',
              },
              {
                label: 'is none of',
                value: 'none',
              },
            ],
            staticValues: [
              {
                name: 'Email',
                id: 1,
                key: 'email',
              },
              {
                name: 'Chat',
                id: 2,
                key: 'chat',
              },
              {
                name: 'Facebook',
                id: 3,
                key: 'facebook',
              },
            ],
          },
        ],
      },
    ],
    assignment_rule_actions_attributes: [
      {
        action_name: 'assign_conversation',
        action_data: {
          assignee_id: '',
          assignee_type: '',
        },
      },
    ],
    stop_processing_other_rules: true,
  },
};

export const conversationStatusMasterList = [
  { name: 'Open', id: 1, key: 'open' },
  { name: 'Snoozed', id: 2, key: 'snoozed' },
  { name: 'Closed', id: 3, key: 'close' },
];

export const conversationChannelMasterList = [
  { name: 'Email', id: 1, key: 'email' },
  { name: 'Chat', id: 2, key: 'chat' },
  { name: 'Facebook', id: 3, key: 'facebook' },
];

export const conversationRatingMasterList = [
  { name: 'Terrible', id: 1, key: 'terrible' },
  { name: 'Bad', id: 2, key: 'bad' },
  { name: 'Okay', id: 3, key: 'okay' },
  { name: 'Good', id: 4, key: 'good' },
  { name: 'Amazing', id: 5, key: 'amazing' },
];

export const conversationDayOfWeekMasterList = [
  { name: 'Sunday', id: 1, key: 'sunday' },
  { name: 'Monday', id: 2, key: 'monday' },
  { name: 'Tuesday', id: 3, key: 'tuesday' },
  { name: 'Wednesday', id: 4, key: 'wednesday' },
  { name: 'Thursday', id: 5, key: 'thursday' },
  { name: 'Friday', id: 6, key: 'friday' },
  { name: 'Saturday', id: 7, key: 'saturday' },
];

export const conversationBusinessHoursMasterList = [
  { name: 'During business hours', id: 1, key: 'during' },
  { name: 'Outside business hours', id: 2, key: 'outside' },
];

export const conversationSupportedLanguagesMasterList = [
  { name: 'English', id: 1, key: 'en' },
  { name: 'Spanish', id: 2, key: 'es' },
  { name: 'French', id: 3, key: 'fr' },
  { name: 'German', id: 4, key: 'de' },
  { name: 'Russian', id: 5, key: 'ru' },
  { name: 'Polish', id: 6, key: 'pl' },
  { name: 'Hebrew', id: 7, key: 'he' },
  { name: 'Greek', id: 8, key: 'el' },
  { name: 'Turkish', id: 9, key: 'tr' },
  { name: 'Italian', id: 10, key: 'it' },
  { name: 'Bahasa (Indonesian)', id: 11, key: 'id' },
  { name: 'Norwegian', id: 12, key: 'nn' },
  { name: 'Danish', id: 13, key: 'da' },
  { name: 'Dutch', id: 14, key: 'nl' },
  { name: 'Bulgarian', id: 15, key: 'bg' },
  { name: 'Czech', id: 16, key: 'cs' },
  { name: 'Slovak', id: 17, key: 'sk' },
  { name: 'Portuguese', id: 18, key: 'pt' },
  { name: 'Swedish', id: 19, key: 'sv' },
  { name: 'Croatian', id: 20, key: 'hr' },
  { name: 'Bosnian', id: 21, key: 'bs' },
  { name: 'Chinese (Simplified)', id: 22, key: 'zh-hans' },
  { name: 'Chinese (Traditional)', id: 23, key: 'zh-hant' },
  { name: 'Arabic', id: 24, key: 'ar' },
  { name: 'Estonian', id: 25, key: 'et' },
  { name: 'Finnish', id: 26, key: 'fi' },
  { name: 'Hungarian', id: 27, key: 'hu' },
  { name: 'Latvian', id: 28, key: 'lv' },
  { name: 'Lithuanian', id: 29, key: 'lt' },
  { name: 'Mongolian', id: 30, key: 'mn' },
  { name: 'Serbian', id: 31, key: 'sr' },
  { name: 'Slovenian', id: 32, key: 'sl' },
  { name: 'Japanese', id: 33, key: 'ja' },
  { name: 'Korean', id: 34, key: 'ko' },
  { name: 'Vietnamese', id: 35, key: 'vi' },
  { name: 'Thai', id: 36, key: 'th' },
  { name: 'Malay', id: 37, key: 'ms' },
  { name: 'Catalan', id: 38, key: 'ca' },
  { name: 'Afrikaans', id: 39, key: 'af' },
  { name: 'Hindi', id: 40, key: 'hi' },
  { name: 'Albanian', id: 41, key: 'sq' },
  { name: 'Bengali', id: 42, key: 'bn' },
  { name: 'Icelandic', id: 43, key: 'is' },
  { name: 'Georgian', id: 44, key: 'ge' },
];

export const triggersMasterList = [
  {
    category_name: 'Message',
    properties: [
      {
        type: 'inbound_conversation_started',
        display_name: 'A customer starts a new conversation',
        description:
          'Triggers when a customer has started a new conversation on any channel',
      },
      {
        type: 'reply_to_outbound',
        display_name: 'A customer replies to an in-app message',
        description:
          'Triggers when a customer first replies to any outbound chat, post or bot message',
      },
      {
        type: 'any_end_user_message',
        display_name: 'A customer sends any reply',
        description:
          'Triggers every time a customer replies during any conversation',
      },
      {
        type: 'awaiting_teammate_reply',
        display_name: 'A new conversation hasn’t received a reply',
        description:
          'Triggers when a new conversation hasn’t received its first reply in a specified time.',
      },
      {
        type: 'awaiting_end_user_reply',
        display_name: 'A customer has been unresponsive',
        description:
          'Triggers when a customer has been unresponsive for a specified time.',
      },
      {
        type: 'qualification_property_updated',
        display_name: 'A customer has submitted a qualification property',
        description:
          'Triggers when a customer has submitted a qualification property.',
      },
    ],
  },
  {
    category_name: 'Conversation status',
    properties: [
      {
        type: 'snooze_expired',
        display_name: 'A snooze has expired',
        description:
          'Triggers when a conversation has run out of its snoozed time',
      },
      {
        type: 'assignee_changed',
        display_name: 'An assignee has been changed',
        description:
          'Triggers when a conversation has been reassigned to a teammate, team or unassigned',
      },
    ],
  },
  {
    category_name: 'Conversation tag',
    properties: [
      {
        type: 'tag_added',
        display_name: 'A conversation tag has been added',
        description: 'Triggers when a tag has been added to a conversation',
      },
      {
        type: 'tag_removed',
        display_name: 'A conversation tag has been removed',
        description: 'Triggers when a tag has been removed from a conversation',
      },
    ],
  },
  {
    category_name: 'Satisfaction rating',
    properties: [
      {
        type: 'left_satisfaction_rating',
        display_name: 'A customer gives a satisfaction rating',
        description:
          'Triggers when a customer leaves a satisfaction rating in a conversation',
      },
    ],
  },
];

export const actionsMasterList = [
  {
    category_name: 'Conversation',
    properties: [
      {
        type: 'assign_conversation',
        display_name: 'Assign to',
      },
      {
        type: 'tag_conversation',
        display_name: 'Add a conversation tag',
      },
      {
        type: 'untag_conversation',
        display_name: 'Remove a conversation tag',
      },
    ],
  },
  {
    category_name: 'Status',
    properties: [
      {
        type: 'reopen_conversation',
        display_name: 'Reopen conversation',
      },
      {
        type: 'snooze_conversation',
        display_name: 'Snooze conversation',
      },
      {
        type: 'close_conversation',
        display_name: 'Close conversation',
      },
      {
        type: 'delete_conversation',
        display_name: 'Delete conversation',
      },
    ],
  },
  {
    category_name: 'Priority',
    properties: [
      {
        type: 'mark_conversation_priority',
        display_name: 'Mark as a priority',
      },
      {
        type: 'remove_conversation_priority',
        display_name: 'Remove priority',
      },
    ],
  },
  {
    category_name: 'Message',
    properties: [
      {
        type: 'reply_with_message',
        display_name: 'Reply with message',
      },
      {
        type: 'add_internal_note',
        display_name: 'Add an internal note',
      },
    ],
  },
  {
    category_name: 'Contact Tag',
    properties: [
      {
        type: 'tag_contact',
        display_name: 'Add a contact tag',
      },
      {
        type: 'untag_contact',
        display_name: 'Remove a contact tag',
      },
    ],
  },
  {
    category_name: 'Webhook',
    properties: [
      {
        type: 'trigger_webhook',
        display_name: 'Trigger a webhook',
      },
    ],
  },
];

export const conditionsMasterList = [
  {
    category_name: 'conversation',
    properties: [
      {
        property_name: 'Channel',
        value: 'conversation_channel',
        property_type: 'multi_select',
      },
      {
        property_name: 'Status',
        value: 'conversation_status',
        property_type: 'multi_select',
      },
      {
        property_name: 'Conversation tag',
        value: 'conversation_tag',
        property_type: 'multi_select',
      },
      {
        property_name: 'Assignee',
        value: 'conversation_assignee',
        property_type: 'multi_select',
      },
      {
        property_name: 'Conversation rating',
        value: 'conversation_rating',
        property_type: 'multi_select',
      },
    ],
  },
  {
    category_name: 'recipient',
    properties: [
      {
        property_name: 'From address',
        value: 'from_address',
        property_type: 'string',
      },
      {
        property_name: 'To address',
        value: 'to_address',
        property_type: 'string',
      },
    ],
  },
  {
    category_name: 'content',
    properties: [
      {
        property_name: 'Subject',
        value: 'conversation_subject',
        property_type: 'string',
      },
      {
        property_name: 'Message Content',
        value: 'message_content',
        property_type: 'string',
      },
    ],
  },
  {
    category_name: 'website',
    properties: [
      {
        property_name: 'Current URL',
        value: 'current_url',
        property_type: 'string',
      },
      {
        property_name: 'Current URL Path',
        value: 'current_url_path',
        property_type: 'url',
      },
    ],
  },
  {
    category_name: 'contact',
    properties: [
      {
        property_name: 'Contact segment',
        value: 'contact_segment',
        property_type: 'multi_select',
      },
      {
        property_name: 'Contact tag',
        value: 'contact_tag',
        property_type: 'multi_select',
      },
      {
        property_name: 'Contact property',
        value: 'contact_property',
        property_type: 'is_is_not',
      },
    ],
  },
  {
    category_name: 'time',
    properties: [
      {
        property_name: 'Waiting for teammate reply',
        value: 'unreplied_after',
        property_type: 'number',
      },
      {
        property_name: 'Business hours',
        value: 'business_hours',
        property_type: 'is_is_not',
      },
      {
        property_name: 'Day of the week',
        value: 'day_of_week',
        property_type: 'multi_select',
      },
      {
        property_name: 'Time is between specific hours',
        value: 'time_between_hours',
        property_type: 'time_range',
      },
    ],
  },
];

export const conditionsDataTypes = {
  time_range: [
    {
      label: 'is between',
      value: 'is_between',
    },
    {
      label: 'is outside',
      value: 'is_outside',
    },
  ],
  url: [
    {
      label: 'is any page',
      value: 'is_any_page',
    },
    {
      label: 'is the homepage',
      value: 'is_homepage',
    },
    {
      label: 'is not the homepage',
      value: 'is_not_homepage',
    },
    {
      label: 'is exactly',
      value: 'equals',
    },
    {
      label: 'is not exactly',
      value: 'not_equals',
    },
    {
      label: 'contains',
      value: 'contains',
    },
    {
      label: 'does not contain',
      value: 'not_contains',
    },
    {
      label: 'starts with',
      value: 'starts_with',
    },
    {
      label: 'does not starts with',
      value: 'does_not_starts_with',
    },
    {
      label: 'ends with',
      value: 'ends_with',
    },
    {
      label: 'does not ends with',
      value: 'does_not_ends_with',
    },
    {
      label: 'matches the pattern',
      value: 'matches_the_pattern',
    },
  ],
  number: [
    {
      label: 'is greater than',
      value: 'greater_than',
    },
    {
      label: 'is less than',
      value: 'less_than',
    },
    {
      label: 'is equal to',
      value: 'equals',
    },
    {
      label: 'is less than or equal to',
      value: 'less_or_equal',
    },
    {
      label: 'is greater than or equal to',
      value: 'greater_or_equal',
    },
  ],
  date_time: [
    {
      label: 'is on',
      value: 'on',
    },
    {
      label: 'is before',
      value: 'before',
    },
    {
      label: 'is after',
      value: 'after',
    },
    {
      label: 'is on or before',
      value: 'on_or_before',
    },
    {
      label: 'is on or after',
      value: 'on_or_after',
    },
  ],
  string: [
    {
      label: 'is exactly',
      value: 'equals',
    },
    {
      label: 'is not exactly',
      value: 'not_equals',
    },
    {
      label: 'contains',
      value: 'contains',
    },
    {
      label: 'does not contain',
      value: 'not_contains',
    },
    {
      label: 'starts with',
      value: 'starts_with',
    },
    {
      label: 'ends with',
      value: 'ends_with',
    },
    {
      label: 'has any value',
      value: 'has_any_value',
    },
    {
      label: 'is unknown',
      value: 'has_no_value',
    },
  ],
  multi_select: [
    {
      label: 'is any of',
      value: 'any',
    },
    {
      label: 'is none of',
      value: 'none',
    },
  ],
  is_is_not: [
    {
      label: 'is',
      value: 'equals',
    },
    {
      label: 'is not',
      value: 'not_equals',
    },
  ],
  boolean: [
    {
      label: 'true',
      value: 'true',
    },
    {
      label: 'false',
      value: 'false',
    },
  ],
};

export const removeActionProperty = ['tags'];
