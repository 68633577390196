import actions from './actions';

let initState = {
  isDrawerOpen: false,
  workflowTemplateNameSet: '',
  description: '',
  duplicateId: '',
  categoryId: undefined,
  isTemplateDrawerVisible: false,
  formTemplateNameSet: '',
  selectedTemplateStatus: 'all',
  searchValue: '',
  overview: '',
  selectedFormTypeStatus: 'all',
  formTemplateId: '',
  formTemplateUpdateId: '',
  formTemplateList: [],
  formTemplateStatusList: [],
  isFormTemplateTableLoading: true,
  choosedFormType: '',
  editDrawerFormTemplate: {},
  formTemplatesLoading: true,
  filteredFormTemplateList: '',
  loader: {
    createTemplateLoading: false,
    editDrawerFormTemplateLoading: false,
    publishFormTemplateLoading: false,
  },
};

export default function FormTemplateReducer(state = initState, action) {
  switch (action.type) {
    case actions.TEMPLATE_DRAWER_STATUS_FORM_TEMPLATES:
      const data = action.object;
      return {
        ...state,
        isDrawerOpen: action.payload,
        formTemplateNameSet: data ? `${data.template_name}(Copy)` : '',
        duplicateId: data ? data.id : '',
        description: data ? data.description : '',
        overview: data ? data.image_url : '',
      };
    case actions.SET_FORMS_TEMPLATE_STATUS:
      return {
        ...state,
        selectedTemplateStatus: action.payload,
      };
    case actions.SET_FORMS_TYPE_TEMPLATE_STATUS:
      return {
        ...state,
        selectedFormTypeStatus: action.payload,
      };
    case actions.CREATE_FORMS_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.CREATE_FORMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        editDrawerFormTemplate: action.payload,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
      };
    case actions.CREATE_FORMS_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
      };
    case actions.UPDATE_FORM_TEMPLATE_DRAWER_VISIBLE:
      return {
        ...state,
        isTemplateDrawerVisible: action.payload,
      };
    case actions.UPDATE_EDIT_DETAILS_SUCCESS:
      return {
        ...state,
        editDrawerFormTemplate: action.payload,
        isTemplateDrawerVisible: false,
        loader: {
          ...state.loader,
          editDrawerFormTemplateLoading: false,
          publishFormTemplateLoading: false,
        },
      };
    case actions.SET_FORMS_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.SET_FORMS_TEMPLATE_ID:
      return {
        ...state,
        formTemplateId: action.form_secret,
        formTemplateUpdateId: action.id,
      };
    case actions.FETCH_FORMS_TEMPLATE_LIST:
      return {
        ...state,
        isFormTemplateTableLoading: true,
        formTemplatesLoading: true,
        selectedTemplateStatus: action.status,
        selectedFormTypeStatus: action.formType,
      };
    case actions.FETCH_FORMS_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        formTemplateList: action.payload.form_templates,
        formTemplateStatusList:
          action.payload.form_template_status || state.formTemplateStatusList,
        isFormTemplateTableLoading: false,
        formTemplatesLoading: false,
      };
    case actions.FETCH_FORMS_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        isFormTemplateTableLoading: false,
        formTemplatesLoading: true,
      };
    case actions.GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE_SUCCESS:
      return {
        ...state,
        choosedFormType: action.chooseType,
        editDrawerFormTemplate: action.payload,
      };
    case actions.FILTER_FORMS_TEMPLATE_LIST:
      return {
        ...state,
        filteredFormTemplateList: state.formTemplateList.filter((template) =>
          template.template_name
            .toLowerCase()
            .includes(action.payload.toLowerCase()),
        ),
      };
    case actions.UPDATE_EDIT_DETAILS: {
      if (action.payload.form_template.isFromSave) {
        return {
          ...state,
          loader: {
            ...state.loader,
            editDrawerFormTemplateLoading: true,
          },
        };
      }
      return {
        ...state,
        loader: {
          ...state.loader,
          editDrawerFormTemplateLoading: true,
          publishFormTemplateLoading: true,
        },
      };
    }
    case actions.UPDATE_EDIT_DETAILS_FAILURE: {
      return {
        ...state,
        loader: {
          ...state.loader,
          editDrawerFormTemplateLoading: false,
          publishFormTemplateLoading: false,
        },
      };
    }
    case actions.DUPLICATE_FORMS_TEMPLATE: {
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    }
    case actions.DUPLICATE_FORMS_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
      };
    }
    case actions.DUPLICATE_FORMS_TEMPLATE_FAILURE: {
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    }
    default:
      return state;
  }
}
