import React from 'react';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';
import { InputTextArea } from '../Input';

export class Emma extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons />
        <jtk-target />

        <div className={'header'}>
          <h3>
            <span>
              <i className={'fas fa-database'} />
            </span>
            Let Emma AI answer
          </h3>
        </div>
        <div className={'body'}>
          {data.messages &&
            data.messages.map((item, key) => (
              <div className="message-text-component" key={key}>
                <InputTextArea value={item.text} />
              </div>
            ))}
          <div className="buttons-list">
            {data.buttons &&
              data.buttons.map(
                (item, key) =>
                  item.key === 'that_helped' ? (
                    <div key={item.id} className="button-component">
                      <span className={'default-btn'}>{item.text}</span>
                    </div>
                  ) : (
                    <div
                      key={item.id}
                      className="button-component"
                      data-port-id={item.id}
                    >
                      <span className="primary-btn">{item.text}</span>
                      <jtk-source port-id={item.id} endpoint={true} />
                    </div>
                  ),
              )}
          </div>
        </div>
      </div>
    );
  }
}
