import React, { useState } from 'react';
import '../style.scss';

const CheckboxComponent = props => {
  const { component, onChange } = props;
  const [selectedCheckbox, setSelectedCheckbox] = useState(
    component.value || [],
  );
  const handleChangeValue = option => ({ target }) => {
    let updatedSelectedCheckbox = [...selectedCheckbox];
    if (!target.checked) {
      const index = updatedSelectedCheckbox.indexOf(option.id);
      updatedSelectedCheckbox.splice(index, 1);
    } else {
      updatedSelectedCheckbox.push(option.id);
    }
    setSelectedCheckbox(updatedSelectedCheckbox);
    onChange(component.id, updatedSelectedCheckbox);
  };

  return (
    <div
      className={`checkbox-container${
        component.save_state === 'failed' ? ' checkbox-failed' : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
    >
      <label className="label-checkbox-container">{component.label}</label>

      <div className="checkbox-options">
        {component.options &&
          component.options.map((option, index) => (
            <div className="checkbox-option-block" key={index}>
              <input
                className="checkbox-option-box"
                type="checkbox"
                {...(option.id ? { 'data-component-id': option.id } : {})}
                {...(component.id ? { name: component.id } : {})}
                {...(option.disabled || component.disabled
                  ? { disabled: true }
                  : {})}
                {...(component.value && component.value.includes(option.id)
                  ? { defaultChecked: true }
                  : {})}
                onChange={handleChangeValue(option)}
              />

              <label
                className={`checkbox-option-label${
                  option.disabled || component.disabled ? ' disabled' : ''
                }`}
              >
                {option.text}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CheckboxComponent;
