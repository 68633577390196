import actions from './actions';

let initState = {
  appsListData: [],
  selectedAppData: {},
  oAuthScopes: [],
  appCategoryOptions: [],
  installedDeveloperApps: [],
  getInstalledAppsLoader: false,
  publicApps: [],
  publicAppsLoader: false,
  saveLoader: false,
  saveError: '',
  buttonLoading: false,
  createAppBtnLoading: false,
  installedStateLoading: false,
  showRequiredModal: false,
  requiredModalLoader: false,
  currentStepFormState: 1,
  webhookEvents: [],
};

export default function appPlatformReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREATE_APP_SUCCESS:
      return {
        ...state,
        selectedAppData: action.payload,
        createAppBtnLoading: false,
      };
    case actions.FETCH_DEVELOPER_APPS_LIST_SUCCESS:
      return {
        ...state,
        appsListData: action.payload,
      };

    case actions.FETCH_DEVELOPER_APP_DATA_SUCCESS:
      return {
        ...state,
        selectedAppData: action.payload,
      };
    case actions.FETCH_O_AUTH_SCOPES_AND_CATEGORIES_SUCCESS:
      return {
        ...state,
        oAuthScopes: action.payload.scopes,
        appCategoryOptions: action.payload.categories,
        webhookEvents: action.payload.webhook_events,
      };
    case actions.UPDATE_APP_DATA:
      return {
        ...state,
        saveLoader: true,
        saveError: false,
        buttonLoading: true,
      };
    case actions.UPDATE_APP_DATA_SUCCESS:
      return {
        ...state,
        selectedAppData: action.payload,
        saveLoader: false,
        saveError: false,
        buttonLoading: false,
        showRequiredModal: action.currentStepFormState
          ? [2, 3].includes(action.currentStepFormState)
          : false,
        requiredModalLoader: false,
        currentStepFormState: action.currentStepFormState
          ? action.currentStepFormState
          : 1,
      };
    case actions.UPDATE_APP_DATA_FAILURE:
      return {
        ...state,
        saveLoader: false,
        saveError: true,
      };
    case actions.FETCH_INSTALLED_DEVELOPER_APPS:
      return {
        ...state,
        getInstalledAppsLoader: true,
      };
    case actions.FETCH_INSTALLED_DEVELOPER_APPS_SUCCESS:
      return {
        ...state,
        getInstalledAppsLoader: false,
        installedDeveloperApps: action.payload,
      };
    case actions.FETCH_INSTALLED_DEVELOPER_APPS_FAILURE:
      return {
        ...state,
        getInstalledAppsLoader: false,
        installedDeveloperApps: [],
      };
    case actions.FETCH_PUBLIC_APPS:
      return {
        ...state,
        publicAppsLoader: action.changeLoaderState,
      };
    case actions.FETCH_PUBLIC_APPS_SUCCESS:
      return {
        ...state,
        publicApps: action.payload,
        publicAppsLoader: false,
      };
    case actions.UPDATE_INSTALLED_STATE:
      return {
        ...state,
        publicApps: state.publicApps.map(
          app =>
            app.key === action.key
              ? { ...app, installed_data: action.payload }
              : app,
        ),
      };
    case actions.CREATE_APP:
      return {
        ...state,
        createAppBtnLoading: true,
      };
    case actions.APP_INSTALLED_STATE_LOADING:
      return {
        ...state,
        installedStateLoading: action.payload,
      };
    case actions.CHANGE_REQUIRED_MODAL_STATUS:
      return {
        ...state,
        showRequiredModal: action.payload,
      };
    case actions.CHANGE_REQUIRED_MODAL_LOADER:
      return {
        ...state,
        requiredModalLoader: action.payload,
      };
    case actions.CHANGE_CURRENT_STEP_STATE:
      return {
        ...state,
        currentStepFormState: action.payload,
        requiredModalLoader: false,
      };
    default:
      return state;
  }
}
