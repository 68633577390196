import actions from './actions';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { allUsersFilters, filterActionNames } from '../constants';
import { isAllUserSegment } from '../../helpers/utility';
import { generateFilterActions } from '../../components/Filters/helper';
import _ from 'lodash';

let { audienceFilters, validations } = generateFilterActions();

const initState = {
  audienceFilters: {
    new_filters: [],
  },
  segments: [],
  filterRecipientsCount: 0,
  recipientsLoader: false,
  filters: [],
  propertiesMetaData: {},
  eventsMetaData: [],
  events: [],
  allKeys: [],
  filteredPeople: [],
  ...audienceFilters,
  ...validations,
  allPropertiesLists: [],
  goalFilterVisibility: false,
};

export default function filterReducer(state = initState, action) {
  switch (action.type) {
    case actions.FILTER_FETCH_SEGMENTS_SUCCESS:
      return {
        ...state,
        segments: action.payload.data,
      };
    case actions.FILTER_ON_SEGMENT_CHANGE:
      let filterData, getNewFilters;
      if (isAllUserSegment(state.segments, action.id)) {
        filterData = {
          ...state[`${action.filterName}AudienceFilters`],
          new_filters: allUsersFilters,
        };
      } else {
        getNewFilters = _.find(state.segments, { id: parseInt(action.id) });
        filterData = {
          ...state.audienceFilters,
          new_filters: getNewFilters && getNewFilters.new_filters,
        };
      }
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: filterData,
        filterRecipientsCount: 0,
        filteredPeople: [],
        recipientsLoader:
          filterData.new_filters && filterData.new_filters.length !== 0
            ? true
            : false,
      };
    case actions.FILTER_FILTERS_DROP_DOWN_SUCCESS:
      return {
        ...state,
        filters: action.payload.data,
      };
    case actions.RESET_CONTACTS_AUDIENCE_FILTERS:
      return {
        ...state,
        contactsAudienceFilters: { new_filters: [] },
      };
    case actions.FILTER_FETCH_PROPERTY_META_DATA_SUCCESS:
      return {
        ...state,
        propertiesMetaData: action.payload.data,
      };
    case actions.FILTER_FETCH_EVENTS_META_DATA_SUCCESS:
      return {
        ...state,
        eventsMetaData: action.payload.data,
      };
    case actions.FILTER_FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.data,
      };
    case actions.FILTER_FETCH_PROPERTIES_SUCCESS:
      let properties = [];
      for (let category of action.payload.data.user_properties) {
        properties = _.concat(properties, category.properties);
      }
      return {
        ...state,
        allKeys: action.payload.data.user_properties,
        allPropertiesLists: properties,
      };
    case actions[`${filterActionNames[action.filterName]}_FILTER_ADD_PROPERTY`]:
      let propertyEvent =
        state[`${action.filterName}AudienceFilters`].new_filters;
      let addedFilter = propertyEvent.concat({
        criteria: [
          {
            key: state.allKeys[0].properties[0].property_name,
            condition: state.propertiesMetaData['string'][0].value,
            value: '',
          },
        ],
      });
      if (addedFilter.length > 1) {
        addedFilter[addedFilter.length - 2].criteria_operator = 'AND';
      }
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: action.value || addedFilter,
        },
      };
    case actions[`${filterActionNames[action.filterName]}_FILTER_ADD_EVENT`]:
      let event = state[`${action.filterName}AudienceFilters`].new_filters;
      let addedEvent = event.concat({
        criteria: [
          {
            event_id: null,
            key: state.eventsMetaData.event_meta_data[0].value,
            condition: state.eventsMetaData.event_meta_data[0].value,
            value: 1,
            time_window: {
              condition: state.eventsMetaData.time_frame[0].value,
              value: 7,
              time_period: 'days',
            },
          },
        ],
      });
      if (addedEvent.length > 1) {
        addedEvent[addedEvent.length - 2].criteria_operator = 'AND';
      }
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: addedEvent,
        },
      };
    case actions.GOAL_FILTER_VISIBILITY:
      return {
        ...state,
        goalFilterVisibility: action.payload,
      };
    case actions[
      `${
        filterActionNames[action.payload && action.payload.filterName]
      }_UPDATE_FILTER_DATA`
    ]:
      let addedPropertyEvents = cloneDeep(
        state[`${action.payload.filterName}AudienceFilters`],
      );
      let {
        externalIndex,
        index,
        key,
        value,
        condition,
        name,
        mailFilterId,
        timeZoneName,
        campaignId,
        url,
        eventId,
        eventMetaData,
        timeWindowCondition,
        betweenValue,
        withinValue,
        eventValue,
      } = action.payload;
      if (key) {
        addedPropertyEvents.new_filters[externalIndex].criteria[index] = {
          key: key,
        };
      }
      if (condition) {
        let property = state.allPropertiesLists.filter(
          property =>
            property.property_name ===
            addedPropertyEvents.new_filters[externalIndex].criteria[index].key,
        );
        if (
          addedPropertyEvents.new_filters[externalIndex].criteria[index].key ===
          'user_type'
        ) {
          addedPropertyEvents.new_filters[externalIndex].criteria[index] = {
            key:
              addedPropertyEvents.new_filters[externalIndex].criteria[index]
                .key,
            value: condition,
          };
        } else if (['has_any_value', 'has_no_value'].includes(condition)) {
          addedPropertyEvents.new_filters[externalIndex].criteria[index] = {
            key:
              addedPropertyEvents.new_filters[externalIndex].criteria[index]
                .key,
            condition: condition,
          };
        } else if (
          property &&
          property.length !== 0 &&
          property[0].property_type === 'boolean'
        ) {
          addedPropertyEvents.new_filters[externalIndex].criteria[index] = {
            key:
              addedPropertyEvents.new_filters[externalIndex].criteria[index]
                .key,
            value: condition,
            condition: 'equals',
          };
        } else {
          addedPropertyEvents.new_filters[externalIndex].criteria[
            index
          ].condition = condition;
        }
      }
      if (![undefined, null].includes(value)) {
        if (
          (condition ||
            addedPropertyEvents.new_filters[externalIndex].criteria[index]
              .condition) === 'between_date'
        ) {
          const { from_date, to_date } = value;
          delete addedPropertyEvents.new_filters[externalIndex].criteria[index].value;
          addedPropertyEvents.new_filters[externalIndex].criteria[
            index
          ].from_date = from_date;
          addedPropertyEvents.new_filters[externalIndex].criteria[
            index
          ].to_date = to_date;
        } else {
          delete addedPropertyEvents.new_filters[externalIndex].criteria[index].from_date;
          delete addedPropertyEvents.new_filters[externalIndex].criteria[index].to_date;
          addedPropertyEvents.new_filters[externalIndex].criteria[
            index
          ].value = value;
        }
      }
      if (name) {
        addedPropertyEvents.name = name;
      }
      if (!['', null, undefined].includes(mailFilterId)) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].mail_filter_id = mailFilterId;
      }
      if (timeZoneName) {
        addedPropertyEvents.new_filters[externalIndex].criteria[index] = {
          time_zone_name: timeZoneName,
          key:
            addedPropertyEvents.new_filters[externalIndex].criteria[index].key,
          condition: 'time_zone_filter',
        };
      }
      if (campaignId) {
        addedPropertyEvents.new_filters[externalIndex].criteria[index] = {
          campaign_id: campaignId,
          key:
            addedPropertyEvents.new_filters[externalIndex].criteria[index].key,
          condition: 'campaign_filter',
        };
      }
      if (![null, undefined].includes(url)) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].clicked_link_url = url;
      }
      if (eventId) {
        addedPropertyEvents.new_filters[externalIndex].criteria[index][
          'event_id'
        ] = eventId;
      }
      if (eventMetaData) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].condition = eventMetaData;
        addedPropertyEvents.new_filters[externalIndex].criteria[index].key =
          state.eventsMetaData.event_key;
      }
      if (![null, undefined].includes(eventValue)) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].value = eventValue;
      }
      if (timeWindowCondition) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].time_window = { condition: timeWindowCondition };
      }
      if (betweenValue) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].time_window = {
          ...addedPropertyEvents.new_filters[externalIndex].criteria[index]
            .time_window,
          value_1: moment(betweenValue[0]).format('YYYY-MM-DD'),
          value_2: moment(betweenValue[1]).format('YYYY-MM-DD'),
        };
      }
      if (![null, undefined].includes(withinValue)) {
        addedPropertyEvents.new_filters[externalIndex].criteria[
          index
        ].time_window = {
          ...addedPropertyEvents.new_filters[externalIndex].criteria[index]
            .time_window,
          value: withinValue,
        };
      }
      return {
        ...state,
        [`${action.payload.filterName}AudienceFilters`]: addedPropertyEvents,
      };
    case actions[
      `${filterActionNames[action.filterName]}_FILTER_ADD_INTERNAL_PROPERTY`
    ]:
      let propertyAndEvent =
        state[`${action.filterName}AudienceFilters`].new_filters;
      let filters = propertyAndEvent.map((filter, index) => {
        if (index === action.payload.index) {
          filter.criteria =
            action.payload.type === 'property'
              ? filter.criteria.concat({
                key: state.allKeys[0].properties[0].property_name,
                condition: state.propertiesMetaData['string'][0].value,
                value: '',
              })
              : filter.criteria.concat({
                event_id: null,
                key: state.eventsMetaData.event_meta_data[0].value,
                condition: state.eventsMetaData.event_meta_data[0].value,
                value: 1,
                time_window: {
                  condition: state.eventsMetaData.time_frame[0].value,
                  value: 7,
                  time_period: 'days',
                },
              });
        }
        return filter;
      });
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: filters,
        },
      };

    case actions[`${filterActionNames[action.filterName]}_RESET_DATA`]:
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: [],
        },
      };

    case actions[
      `${filterActionNames[action.filterName]}_FILTER_REMOVE_INTERNAL_PROPERTY`
    ]:
      let filteredProperty = cloneDeep(
        state[`${action.filterName}AudienceFilters`].new_filters,
      );
      filteredProperty[
        action.payload.externalIndex
      ].criteria = filteredProperty[
        action.payload.externalIndex
      ].criteria.filter(
        (criteria, index) => index !== action.payload.internalIndex,
      );
      if (
        filteredProperty[action.payload.externalIndex].criteria.length === 0
      ) {
        filteredProperty.splice(action.payload.externalIndex, 1);
      }
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: filteredProperty,
        },
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: action.payload ? action.payload : [],
        },
      };

    case actions.FILTER_FETCH_RECIPIENTS_SUCCESS:
      return {
        ...state,
        filterRecipientsCount: action.payload.data.matched_people_count,
        filteredPeople: action.payload.data.filtered_people,
      };
    case actions.FILTER_SET_RECIPIENT_LOADER:
      return {
        ...state,
        recipientsLoader: action.payload,
      };
    case actions.SET_FILTER_VALIDATION:
      return {
        ...state,
        filterRecipientsCount: action.payload ? state.filterRecipientsCount : 0,
        filteredPeople: action.payload ? state.filteredPeople : [],
        [`${action.filterName}FilterValidation`]: action.payload,
      };
    case actions.FILTER_ON_SUBSCRIPTION_CHANGE:
      let property = state[`${action.filterName}AudienceFilters`].new_filters,
        hasProperty = false;
      if (action.subscriptionID) {
        for (let item of property) {
          for (let criteria of item.criteria) {
            if (
              criteria.key === 'subscription_types' &&
              criteria.is_default_subscription
            ) {
              criteria.value = action.subscriptionID;
              if (action.defaultContact !== undefined) {
                criteria.include_default_people = !!action.defaultContact;
              }
              hasProperty = true;
            }
          }
        }
      } else {
        for (let item = 0; item < property.length; item++) {
          property[item].criteria = property[item].criteria.filter(
            value => !value.is_default_subscription,
          );
          if (property[item].criteria.length === 0) {
            property.splice(item, 1);
          }
        }
      }
      if (!hasProperty && action.subscriptionID) {
        property = property.concat({
          criteria: [
            {
              key: 'subscription_types',
              value: action.subscriptionID,
              condition: state.propertiesMetaData['string'][0].value,
              is_default_subscription: true,
              include_default_people: !!action.defaultContactSubscriptionTypes,
            },
          ],
        });
        if (property.length > 1) {
          property[property.length - 2].criteria_operator = 'AND';
        }
      }
      return {
        ...state,
        [`${action.filterName}AudienceFilters`]: {
          new_filters: property,
          mail_subscription_id: action.subscriptionID
            ? action.subscriptionID
            : null,
        },
      };
    default:
      return state;
  }
}
