import React, { Component } from 'react';
import '../../components/Settings/settings.scss';
import actions from '../../redux/apps/actions';
import { connect } from 'react-redux';
import { store } from '../../redux/store';
import * as queryString from 'query-string/index';
import { Button, Spin } from 'antd';
import {getAppDetail} from '../../helpers/utility';

class ZoomIntegrationCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location) {
      const { code, state } = queryString.parse(location.search);
      if (code) {
        store.dispatch({
          type: actions.CONNECT_ZOOM,
          payload: { projectID: state, token: code },
        });
      }
    }
  }

  render() {
    const { pageLoader, zoomCallback, zoomErrorMessage } = this.props;
    if (pageLoader) {
      return (
        <div className={'stripe-callback-container loader'}>
          <Spin tip="Please wait..." />
        </div>
      );
    } else if (zoomCallback === true && !pageLoader) {
      return (
        <div className={'stripe-callback-container'}>
          <h1>{`You've now linked ${getAppDetail().name} to Zoom`}</h1>
          <p>Lets start to sync your Zoom meetings.</p>
          <Button
            type={'primary'}
            className={'btn btn-primary btn-sm'}
            onClick={() => window.close()}
          >
            OK
          </Button>
        </div>
      );
    } else if (zoomErrorMessage) {
      return (
        <div className={'stripe-callback-container'}>
          <h1>{zoomErrorMessage}</h1>
          <p>Please try with different zoom account!</p>
          <Button
            type={'primary'}
            className={'btn btn-secondary-outline btn-sm'}
            onClick={() => window.close()}
          >
            Close
          </Button>
        </div>
      );
    } else {
      return (
        <div className={'stripe-callback-container'}>
          <h1>Something went wrong.</h1>
          <p>Please try again later!</p>
          <Button
            type={'primary'}
            className={'btn btn-secondary-outline btn-sm'}
            onClick={() => window.close()}
          >
            Close
          </Button>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { pageLoader, zoomCallback, zoomErrorMessage } = state.Apps;
  return {
    pageLoader,
    zoomCallback,
    zoomErrorMessage,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(ZoomIntegrationCallback);
