import actions from './actions';

let initState = {
  isSurveyTemplateTableLoading: true,
  surveyTemplateList: '',
  surveyTemplateStatusList: [],
  surveyCategoryList: [],
  isDrawerOpen: false,
  searchValue: '',
  loader: {
    workflowTemplateCategory: false,
    createTemplateLoading: false,
  },
  surveyTemplateNameSet: '',
  description: '',
  duplicateId: '',
  categoryId: '',
  overview: '',
  selectedTemplateStatus: 'all',
  isDisable: false,
  surveyTemplateId: '',
  botDetail: [],
  isTemplateDrawerVisible: false,
  surveyTemplateLoader: {
    publishLoader: false,
    editDetailsFormLoader: false,
  },
  defaultImage: '',
  filterCategoryId: '',
  templateSearchValue: '',
  filteredSurveyTemplateList: '',
  setSurveyTemplate: '',
};

export default function SurveyTemplateReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_SURVEY_TEMPLATE_LIST: {
      return {
        ...state,
        isSurveyTemplateTableLoading: true,
      };
    }
    case actions.FETCH_SURVEY_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        surveyTemplateList: action.payload.survey_templates,
        surveyTemplateStatusList:
          action.payload.survey_template_status ||
          state.surveyTemplateStatusList,
        isSurveyTemplateTableLoading: false,
        defaultImage: action.payload.build_from_scratch_overview,
      };
    }
    case actions.FETCH_SURVEY_TEMPLATE_LIST_FAILURE: {
      return {
        ...state,
        isSurveyTemplateTableLoading: false,
      };
    }
    case actions.GET_SURVEY_CATEGORIES_LISTS_SUCCESS:
      return {
        ...state,
        surveyCategoryList: action.payload.categories,
      };
    case actions.SET_CREATE_SURVEY_TEMPLATE_DRAWER_STATUS:
      let data = action.object;
      return {
        ...state,
        isDrawerOpen: action.payload,
        surveyTemplateNameSet: data ? `${data.name}(Copy)` : '',
        duplicateId: data ? data.id : '',
        description: data ? data.description : '',
        overview: data ? data.overview_url : '',
        categoryId: data ? data.template_category_id : undefined,
      };
    case actions.CREATE_SURVEY_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.CREATE_SURVEY_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.CREATE_SURVEY_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.SET_SURVEY_TEMPLATE_ID:
      return {
        ...state,
        surveyTemplateId: action.id,
      };
    case actions.DELETE_SURVEY_TEMPLATE:
      return {
        ...state,
        isDisable: true,
      };
    case actions.DELETE_SURVEY_TEMPLATE_SUCCESS:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.DELETE_SURVEY_TEMPLATE_FAILURE:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.UPDATE_SURVEY_TEMPLATE_DRAWER_VISIBLE:
      return {
        ...state,
        isTemplateDrawerVisible: action.payload,
      };
    case actions.UPDATE_SURVEY_TEMPLATE_SUCCESS:
      return {
        ...state,
        isTemplateDrawerVisible: false,
        surveyTemplateLoader: {
          ...state.surveyTemplateLoader,
          editDetailsFormLoader: false,
          publishLoader: action.isPublishAction
            ? false
            : state.surveyTemplateLoader.publishLoader,
        },
        setSurveyTemplate: action.payload,
      };
    case actions.UPDATE_SURVEY_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateUpdate: false,
        isTemplateDrawerVisible: !action.changeStatus,
        surveyTemplateLoader: {
          publishLoader: false,
          editDetailsFormLoader: false,
        },
      };
    case actions.UPDATE_SURVEY_TEMPLATE:
      return {
        ...state,
        isTemplateUpdate: true,
      };
    case actions.SET_SURVEY_TEMPLATE_STATUS_LOADER:
      return {
        ...state,
        surveyTemplateLoader: {
          ...state.surveyTemplateLoader,
          publishLoader: action.payload,
        },
      };
    case actions.SET_EDIT_DETAILS_FORM_LOADER:
      return {
        ...state,
        surveyTemplateLoader: {
          ...state.surveyTemplateLoader,
          editDetailsFormLoader: true,
        },
      };
    case actions.SET_FILTER_SURVEY_CATEGORY_ID:
      return {
        ...state,
        filterCategoryId: action.payload,
      };
    case actions.SET_CHOOSE_SURVEY_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        templateSearchValue: action.payload,
      };
    case actions.FILTER_SURVEY_TEMPLATE_LIST:
      return {
        ...state,
        filteredSurveyTemplateList: state.surveyTemplateList.filter(template =>
          template.name.toLowerCase().includes(action.payload.toLowerCase()),
        ),
      };
    case actions.SET_SURVEY_TEMPLATE_STATUS:
      return {
        ...state,
        selectedTemplateStatus: action.status,
      };
    case actions.SET_SURVEY_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.DUPLICATE_SURVEY_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.DUPLICATE_SURVEY_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.DUPLICATE_SURVEY_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.SET_SURVEY_TEMPLATE_DETAILS:
      return {
        ...state,
        setSurveyTemplate: action.payload,
      };
    default:
      return state;
  }
}
