import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import {
  canWeAccess,
  fetchProjectSecretKey,
  isRestricted,
  fetchRestrictedFeatures,
  isPrimeCouponUser,
} from '../../../helpers/utility';
import { NavLink } from 'react-router-dom';
import { store } from '../../../redux/store';
import actions from '../../../redux/settings/actions';
import {
  menu,
  restrictedPage,
  userRestriction,
} from '../../TopNavBar/constants';
import settings from '../../../settings/index';

const { Sider } = Layout;
const { SubMenu } = Menu;

class SideBar extends Component {
  constructor(props) {
    super(props);
    let key = [];
    const { owner } = store.getState().Auth;

    this.workspaceSetup = [
      {
        title: 'General',
        subMenu: [
          { label: 'General settings', url: 'workspace-defaults' },
          { label: 'Teammates', url: 'users' },
          { label: 'Teams & Roles', url: 'teams' },
        ],
        key: 'General',
      },

      {
        title: 'Your preferences',
        subMenu: [
          { label: 'General', url: 'general' },
          { label: 'Profile & calendar', url: 'profile' },
          { label: 'Notifications', url: 'notifications' },
        ],
        key: 'Profile',
      },
      {
        title: 'API & webhooks',
        subMenu: [
          { label: 'API key', url: 'api-key' },
          {
            label: 'Webhooks',
            url: 'webhooks',
          },
        ],
        key: 'Integrations',
      },
      {
        title: 'Billing',
        subMenu: [
          { label: 'Subscription', url: 'subscription' },
          { label: 'Invoice', url: 'invoice' },
          ...(isPrimeCouponUser &&
          ['free', 'prime_lifetime'].includes(props.plan)
            ? [{ label: 'Redeem coupon', url: 'redeem-coupon' }]
            : []),
        ],
        key: 'Billing',
      },
    ];

    this.dataManagementSettings = [
      {
        title: 'Properties',
        subMenu: [
          { label: 'Contact properties', url: 'contact-properties' },
          { label: 'Conversation properties', url: 'conversation-properties' },
          { label: 'Deal properties', url: 'deal-properties' },
          { label: 'Contact segments', url: 'contact-segments' },
          { label: 'Qualification data', url: 'qualification-data' },
        ],
        key: 'Properties',
      },
      {
        title: 'Tags',
        subMenu: [
          { label: 'Contact tags', url: 'contact-tags' },
          !isRestricted(restrictedPage.conversation) && {
            label: 'Conversation tags',
            url: 'conversation-tags',
          },
          !isRestricted(restrictedPage.conversation) && {
            label: 'Message tags',
            url: 'message-tags',
          },
        ],
        key: 'Tags',
      },
      {
        title: 'Tracking & events',
        subMenu: [
          { label: 'Tracking code', url: 'tracking-code' },
          { label: 'Events', url: 'events' },
          { label: 'Mobile App', url: 'mobile-app' },
          ...(!fetchRestrictedFeatures().includes(menu.identityVerification)
            ? [{ label: 'Security', url: 'security' }]
            : []),
        ],
        key: 'Tracking',
      },
      {
        title: 'Import & export',
        subMenu: [
          { label: 'Import history', url: 'imports' },
          { label: 'Export history', url: 'exports' },
        ],
        key: 'ImportsAndExports',
      },
    ];

    this.toolSettings = [
      {
        title: 'Messenger',
        subMenu: [
          { label: 'Appearance', url: 'appearance' },
          { label: 'Availability', url: 'availability' },
          { label: 'Visibility', url: 'visibility' },
          { label: 'Languages', url: 'language' },
          { label: 'Data and Privacy', url: 'data-and-privacy' },
          { label: 'Advanced', url: 'advanced' },
          { label: 'Apps', url: 'messenger-apps' },
        ],
        key: 'Messenger',
      },
      {
        title: 'Inbox',
        subMenu: [
          {
            label: 'Email forwarding',
            url: 'shared-team-email-address',
          },
          {
            label: 'Conversation snippets',
            url: 'snippets',
          },
          { label: 'Spam settings', url: 'spam' },
        ],
        key: 'Inbox',
      },
      {
        title: 'Bots',
        subMenu: [
          { label: 'Custom bot', url: 'bot' },
          { label: 'Qualification bot', url: 'qualification-bot' },
        ],
        key: 'Bots',
      },
      {
        title: 'Knowledge base',
        subMenu: [
          { label: 'General', url: 'knowledge-base' },
          { label: 'Themes', url: 'kb-themes' },
        ],
        key: 'KnowledgeBase',
      },
      {
        title: 'Email',
        subMenu: [
          { label: 'General', url: 'email' },
          { label: 'Email sending addresses', url: 'email-sending-domain' },
          { label: 'Custom email templates', url: 'email-templates' },
          { label: 'Subscription types', url: 'subscription-types' },
          { label: 'Forms', url: 'forms' },
        ],
        key: 'Email',
      },
      {
        title: 'CRM',
        subMenu: [
          { label: 'Pipelines & stages', url: 'pipelines-and-stages' },
          { label: 'Companies', url: 'company-settings' },
        ],
        key: 'CRM',
      },
    ];

    this.workspaceSetup = this.workspaceSetup.filter(data => {
      if (
        data.title === 'Billing' &&
        (owner || canWeAccess(userRestriction.billingSettings))
      ) {
        return true;
      } else return !['Billing'].includes(data.title);
    });

    this.toolSettings = this.toolSettings.filter(data => {
      if (data.key === 'Inbox') {
        return !isRestricted(restrictedPage.conversation);
      } else if (data.key === 'Chatbot') {
        return !isRestricted(restrictedPage.chatBot);
      } else if (data.key === 'Support') {
        return !isRestricted(restrictedPage.knowledgeBase);
      } else if (data.key === 'Messenger') {
        return !isRestricted(restrictedPage.conversation);
      } else {
        return true;
      }
    });

    this.workspaceSetup.map(
      (data, i) =>
        data.subMenu &&
        data.subMenu.map(el => {
          if (el.url === this.props.sideBarActiveKey[0]) {
            key = this.workspaceSetup[i].key;
          }
          return null;
        }),
    );

    this.dataManagementSettings.map(
      (data, i) =>
        data.subMenu &&
        data.subMenu.map(el => {
          if (el.url === this.props.sideBarActiveKey[0]) {
            key = this.dataManagementSettings[i].key;
          }
          return null;
        }),
    );

    this.toolSettings.map(
      (data, i) =>
        data.subMenu &&
        data.subMenu.map(el => {
          if (el.url === this.props.sideBarActiveKey[0]) {
            key = this.toolSettings[i].key;
          }
          return null;
        }),
    );

    this.state = {
      defaultOpenKeys: key ? [key] : [],
    };
  }

  menuClick = e => {
    store.dispatch({
      type: actions.SET_SIDEBAR_ACTIVE,
      key: e.keyPath,
    });
    localStorage.setItem('currentSettingsUrl', e.key);
  };

  subMenuCollapseHandler = openKeys => {
    this.setState(state => ({
      defaultOpenKeys: openKeys.filter(
        el => !state.defaultOpenKeys.includes(el),
      ),
    }));
  };

  getMenuList = menu => {
    const { subMenu, title, key, url } = menu;
    return subMenu ? (
      <SubMenu key={key} title={<span> {title}</span>}>
        {subMenu.map(
          data =>
            data.label ? (
              <Menu.Item key={data.url} onClick={this.menuClick}>
                <NavLink
                  to={`/projects/${fetchProjectSecretKey()}/settings/${
                    data.url
                  }`}
                />
                {data.label}
              </Menu.Item>
            ) : null,
        )}
      </SubMenu>
    ) : (
      <Menu.Item key={url} onClick={this.menuClick}>
        <NavLink to={`/projects/${fetchProjectSecretKey()}/settings/${url}`} />
        {title}
      </Menu.Item>
    );
  };

  render() {
    const { sideBarActiveKey } = this.props;
    return (
      <Sider className="settings-sider">
        <div className="heading">Settings</div>
        <Menu
          mode="inline"
          selectedKeys={sideBarActiveKey}
          style={{ height: '100%', borderRight: 0 }}
          // onOpenChange={this.subMenuCollapseHandler}
          defaultOpenKeys={this.state.defaultOpenKeys}
        >
          <p className="sub-menu-title">Workspace Setup</p>
          {this.workspaceSetup.map(data => this.getMenuList(data))}
          <p className="sub-menu-title">Data Management</p>
          {this.dataManagementSettings.map(data => this.getMenuList(data))}
          <p className="sub-menu-title">Tools</p>
          {this.toolSettings.map(data => this.getMenuList(data))}
        </Menu>
      </Sider>
    );
  }
}

export default SideBar;
