import actions from './actions';
import {
  AUTO_EMAIL_TYPE,
  AUTOMATION_TYPE,
  BROADCAST_MAIL_TYPE,
  CAMPAIGN_TYPE,
} from '../../constants';
import _, { cloneDeep } from 'lodash';
import { removeUnderscore } from '../../../helpers/utility';
import {
  getFromUserData,
  getToUserData,
} from '../../../components/Email/helper';
import { handleHighlightConnections } from '../../../containers/Automation/Workflow/helper';

let initState = {
  workflowList: [],
  actionWorkflowList: [],
  isTableLoading: true,
  workflowTypeList: [],
  workflowStatus: 'all',
  searchValue: '',
  workflowID: '',
  isDrawerOpen: false,
  sortKey: '',
  sortValue: '',
  triggerList: [],
  actionList: [],
  isTriggerDrawerOpen: false,
  isComponentDrawerOpen: false,
  isTriggerFormDrawerOpen: false,
  isActionCreateOrEditDrawerOpen: false,
  triggerDetail: null,
  campaignList: [],
  formList: [],
  tagList: [],
  linkList: [],
  isComponentCreateLoading: false,
  actionDetail: null,
  edge: {},
  property: {
    data: [],
    metaData: null,
    userProperties: [],
    nonEditableKeys: [],
    allProperties: [],
  },
  teammateList: [],
  usersList: [],
  customFieldList: [],
  allEmailList: [],
  broadcastEmailList: [],
  behaviouralEmailList: [],
  campaignEmailList: [],
  isDelayDrawerOpen: false,
  isDecisionDrawerOpen: false,
  decisionDrawerData: {},
  triggerData: null,
  actionData: null,
  delayData: null,
  decisionData: null,
  isForkDrawerOpen: false,
  isExitDrawerOpen: false,
  isGotoDrawerOpen: false,
  node: null,
  isTriggerViewDrawerOpen: false,
  triggersDetail: [],
  workflowDetail: null,
  isWorkflowStatusUpdateLoader: false,
  isWorkflowNameLoader: false,
  delayType: 'timed_delay',
  delayCreated: { isEdited: true, isAppliedExistingContact: 'disabled' },
  showPathConsent: null,
  showEmptyPageLoader: true,
  isSegmentDrawerOpen: false,
  segmentData: null,
  isGoalDrawerOpen: false,
  goalData: null,
  listLoader: true,
  createWorkflowDetail: {},
  isTemplateUpdate: false,
  isTemplateDrawerVisible: false,
  workflowNameSet: '',
  duplicateId: '',
  workflowLoader: {
    publishLoader: false,
  },
  allComponentsConfigured: true,
  isFromError: false,
  templateId: '',
  emailActionVisible: false,
  emailTemplates: [],
  emailTemplatesLoader: true,
  selectedTemplateId: '',
  openCreateBroadcastDrawer: false,
  broadCastTemplateId: '',
  broadCastMailText: 'Hi {{contact.first_name}},',
  broadcastTemplateContent: '',
  automationMailAttributes: {
    broadCastTemplateName: '',
    broadCastSubject: '',
    emailFromUserId: undefined,
    emailReplyToUserId: undefined,
    broadCastPreHeaderText: '',
    postalAddress: '',
    multipleTimes: false,
    formReset: false,
  },
  automationEmailList: [],
  validateEMailLoader: false,
  productLists: [],
  productCategoryList: [],
  categorySearch: '',
  productSearch: '',
  isChatNameDrawerOpen: false,
  isChatDrawerOpen: false,
  chatDetails: null,
  chatNameLoader: false,
  isPostNameDrawerOpen: false,
  postDetails: null,
  isPostDrawerOpen: false,
  postNameLoader: false,
  hubspotDetailsLoader: false,
  hubspotStatus: false,
  hubspotDetails: {},
  messageLoader: false,
  messageData: [],
  surveyLoader: false,
  surveyData: [],
  selectedWebhookData: {},
  selectedCustomEventData: [],
  workflowZoom: 70,
  tourData: [],
  tourLoader: false,
  formData: [],
  formLoader: false,
  workflowData: [],
  workflowConditionLoader: false,
  instantBotData: [],
  newConversationBotData: [],
  botLoader: false,
  stages: [],
  troubleShootPersonFlow: {},
  troubleShootPersonActivities: {},
  isTroubleShootModeOn: false,
  currentFlowId: '',
  skipFlowLoader: false,
  updatedNodes: [],
  workflowContactReports: [],
  componentContactReports: [],
  reportsLoader: false,
  contactReportDrawer: false,
  gotoData: {},
};

function constructProperties(value) {
  return {
    key: removeUnderscore(value.property_name),
    value: value.property_name,
  };
}

export default function workflowReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_WORKFLOW_LIST:
      return {
        ...state,
        isTableLoading: true,
      };
    case actions.FETCH_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        workflowList: action.payload.workflows,
        workflowTypeList: action.payload.workflows_status,
        isTableLoading: false,
      };
    case actions.APPEND_WORKFLOW_LIST:
      return {
        ...state,
        isTableLoading: true,
      };
    case actions.APPEND_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        workflowList: state.workflowList.concat(action.payload.workflows),
        isTableLoading: false,
      };
    case actions.SET_WORKFLOW_ID:
      return {
        ...state,
        workflowID: action.id,
      };
    case actions.SET_WORKFLOW_STATUS:
      return {
        ...state,
        workflowStatus: action.status,
      };
    case actions.SET_WORKFLOW_DRAWER_STATUS:
      return {
        ...state,
        isDrawerOpen: action.payload,
        workflowNameSet: action.name ? `${action.name}(Copy)` : '',
        duplicateId: action.id ? action.id : '',
        templateId: action.templateId ? action.templateId : '',
      };
    case actions.CREATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        isDrawerOpen: false,
        isComponentCreateLoading: false,
        isFromError: false,
      };
    case actions.SET_WORKFLOW_SORT_KEY_AND_VALUE:
      return {
        ...state,
        sortKey: action.sortKey,
        sortValue: action.sortOrder,
      };
    case actions.SET_WORKFLOW_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.FETCH_WORKFLOW_TRIGGER_LIST_SUCCESS:
      return {
        ...state,
        triggerList: action.payload,
      };
    case actions.FETCH_WORKFLOW_ACTION_LIST_SUCCESS:
      return {
        ...state,
        actionList: action.payload,
      };
    case actions.SET_WORKFLOW_TRIGGER_DRAWER_STATUS:
      return {
        ...state,
        isTriggerDrawerOpen: action.payload,
      };
    case actions.SET_WORKFLOW_COMPONENT_DRAWER_STATUS:
      return {
        ...state,
        isComponentDrawerOpen: action.payload.status,
        edge: action.payload.edge,
      };
    case actions.SET_WORKFLOW_TRIGGER_FORM_DRAWER_STATUS:
      return {
        ...state,
        isTriggerFormDrawerOpen: action.payload.status,
        triggerData: action.payload.data,
      };
    case actions.SET_WORKFLOW_TRIGGER_TYPE:
      return {
        ...state,
        triggerDetail: {
          ...state.triggerDetail,
          type: action.triggerType,
          category: action.categoryName,
        },
      };
    case actions.FETCH_WORKFLOW_CAMPAIGN_LIST:
      return {
        ...state,
        listLoader: false,
      };
    case actions.FETCH_WORKFLOW_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        campaignList: action.payload,
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_CAMPAIGN_LIST_FAILURE:
      return {
        ...state,
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_FORM_LIST:
      return {
        ...state,
        listLoader: false,
      };
    case actions.SET_WORKFLOW_LOADER:
      return {
        ...state,
        listLoader: !action.loader,
      };
    case actions.FETCH_WORKFLOW_FORM_LIST_SUCCESS:
      return {
        ...state,
        formList: action.payload,
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_FORM_LIST_FAILURE:
      return {
        ...state,
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_EMAIL_LIST_SUCCESS:
      const { type, data } = action.payload;
      if (type === BROADCAST_MAIL_TYPE) {
        return {
          ...state,
          broadcastEmailList: data,
        };
      } else if (type === AUTO_EMAIL_TYPE) {
        return {
          ...state,
          behaviouralEmailList: data,
        };
      } else if (type === CAMPAIGN_TYPE) {
        return {
          ...state,
          campaignEmailList: data,
        };
      } else if (type === AUTOMATION_TYPE) {
        return {
          ...state,
          automationEmailList: data,
        };
      } else {
        return {
          ...state,
          allEmailList: data,
        };
      }
    case actions.FETCH_WORKFLOW_TAG_LIST:
      return {
        ...state,
        listLoader: false,
      };
    case actions.FETCH_WORKFLOW_TAG_LIST_SUCCESS: {
      return {
        ...state,
        tagList: action.payload,
        listLoader: true,
      };
    }
    case actions.FETCH_WORKFLOW_TAG_LIST_FAILURE:
      return {
        ...state,
        listLoader: false,
      };
    case actions.FETCH_ACTION_WORKFLOW_LIST:
      return {
        ...state,
        listLoader: false,
      };
    case actions.FETCH_ACTION_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        actionWorkflowList: action.payload,
        listLoader: true,
      };
    case actions.FETCH_ACTION_WORKFLOW_LIST_FAILURE:
      return {
        ...state,
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_EMAIL_LINK_LIST_SUCCESS:
      return {
        ...state,
        linkList: action.payload,
      };
    case actions.CREATE_WORKFLOW:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.UPDATE_COMPONENT:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.CREATE_COMPONENT:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.CREATE_COMPONENT_SUCCESS:
      return {
        ...state,
        isComponentCreateLoading: false,
      };
    case actions.UPDATE_COMPONENT_SUCCESS:
      return {
        ...state,
        isComponentCreateLoading: false,
      };

    case actions.SET_WORKFLOW_ACTION_TYPE:
      return {
        ...state,
        actionDetail: {
          ...state.actionDetail,
          type: action.actionType,
          category: action.categoryName,
        },
      };

    case actions.SET_WORKFLOW_ACTION_CREATE_AND_EDIT_DRAWER_STATUS:
      return {
        ...state,
        isActionCreateOrEditDrawerOpen: action.payload.status,
        actionData: action.payload.data,
      };
    case actions.FETCH_WORKFLOW_PROPERTIES:
      return {
        ...state,
        listLoader: false,
      };
    case actions.FETCH_WORKFLOW_PROPERTIES_SUCCESS:
      let customProperties = _.map(action.payload.custom_properties, (value) =>
        constructProperties(value),
      );
      let allProperties = [];
      for (let category of action.payload.user_properties) {
        allProperties = _.concat(allProperties, category.properties);
      }
      allProperties = _.concat(allProperties, customProperties);
      return {
        ...state,
        property: {
          ...state.property,
          data: [...action.payload.custom_properties],
          userProperties: [...action.payload.user_properties],
          nonEditableKeys: [...action.payload.non_editable_properties],
          allProperties: allProperties,
        },
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_PROPERTIES_FAILURE:
      return {
        ...state,
        listLoader: true,
      };
    case actions.FETCH_WORKFLOW_PROPERTIES_META_DATA_SUCCESS:
      return {
        ...state,
        property: {
          ...state.property,
          metaData: action.payload,
        },
      };
    case actions.FETCH_WORKFLOW_TEAMMATE_LIST_SUCCESS:
      if (action.idSet) {
        let userId = action.payload;
        const emailReplyToUserId = userId.out_bound_address[0]
          ? {
              type: 'reply_to_custom_team_mate',
              data: { assignee_id: userId.out_bound_address[0].id },
            }
          : {
              type: 'reply_to_user',
              data: { assignee_id: userId.team_mates[0].id },
            };
        const emailFromUserId = userId.out_bound_address[0]
          ? {
              type: 'from_custom_team_mate',
              from_user_id: userId.out_bound_address[0].id,
            }
          : {
              type: 'from_user',
              from_user_id: userId.team_mates[0].id,
            };
        return {
          ...state,
          teammateList: action.payload,
          automationMailAttributes: {
            broadCastTemplateName:
              state.automationMailAttributes.broadCastTemplateName,
            broadCastSubject: state.automationMailAttributes.broadCastSubject,
            emailFromUserId,
            emailReplyToUserId,
            broadCastPreHeaderText:
              state.automationMailAttributes.broadCastPreHeaderText,
            postalAddress: state.automationMailAttributes.postalAddress,
            multipleTimes: state.automationMailAttributes.multipleTimes,
            formReset: state.automationMailAttributes.formReset,
          },
        };
      } else {
        return {
          ...state,
          teammateList: action.payload,
        };
      }
    case actions.FETCH_WORKFLOW_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
      };
    case actions.FETCH_WORKFLOW_CUSTOM_FIELD_LIST_SUCCESS:
      return {
        ...state,
        customFieldList: action.payload,
      };
    case actions.SET_WORKFLOW_DELAY_DRAWER_STATUS:
      return {
        ...state,
        isDelayDrawerOpen: action.payload.status,
        delayData: action.payload.data,
        delayType: action.payload.data
          ? action.payload.data.properties.delay_type
          : 'timed_delay',
      };
    case actions.SET_WORKFLOW_DECISION_DRAWER_STATUS:
      return {
        ...state,
        isDecisionDrawerOpen: action.payload.status,
        decisionData: action.payload.data,
        showPathConsent: action.payload.showPathConsent,
        decisionDrawerData: action.payload.drawerData,
      };
    case actions.SET_WORKFLOW_FORK_DRAWER_STATUS:
      return {
        ...state,
        isForkDrawerOpen: action.payload,
        showPathConsent: action.showPathConsent,
      };
    case actions.SET_WORKFLOW_EXIT_DRAWER_STATUS:
      return {
        ...state,
        isExitDrawerOpen: action.payload,
      };
    case actions.SET_WORKFLOW_GOTO_DRAWER_STATUS:
      return {
        ...state,
        isGotoDrawerOpen: action.payload,
      };
    case actions.SET_WORKFLOW_COMPONENT_DELETE_DRAWER_STATUS:
      return {
        ...state,
        isDeleteDrawerOpen: action.payload.status,
        node: action.payload.data,
        showPathConsent: action.payload.showPathConsent,
      };
    case actions.DELETE_WORKFLOW_EDITOR_COMPONENT:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.DELETE_WORKFLOW_EDITOR_COMPONENT_SUCCESS:
      return {
        ...state,
        isComponentCreateLoading: false,
      };
    case actions.RESET_WORKFLOW_CREATE_LOADER_STATUS:
      return {
        ...state,
        isComponentCreateLoading: action.payload,
      };
    case actions.SET_WORKFLOW_TRIGGER_VIEW_DRAWER_STATUS:
      return {
        ...state,
        isTriggerViewDrawerOpen: action.payload,
      };
    case actions.UPDATE_WORKFLOW_TRIGGERS_DETAIL:
      return {
        ...state,
        triggersDetail: action.payload,
      };
    case actions.SET_WORKFLOW_DETAIL:
      return {
        ...state,
        workflowDetail: action.payload,
      };
    case actions.UPDATE_WORKFLOW:
      return {
        ...state,
        isWorkflowStatusUpdateLoader: action.isStatusLoader,
        isWorkflowNameLoader: action.isWorkflowNameLoader,
      };
    case actions.UPDATE_WORKFLOW_SUCCESS:
      // TODO: Need to confirm with Jitta while applied the filters(draft, pause) Need to refresh the table.
      let workflowList = state.workflowList;
      const workFlowStatus = state.workflowStatus;
      if (workFlowStatus === 'all') {
        workflowList = workflowList.map((workflow) => {
          if (workflow.id === action.payload.id) {
            workflow.status = action.payload.status;
            workflow.updated_at = action.payload.updated_at;
          }
          return workflow;
        });
      } else {
        workflowList = workflowList.filter(
          (workflow) => workflow.id !== action.payload.id,
        );
      }
      return {
        ...state,
        isWorkflowStatusUpdateLoader: false,
        workflowList: workflowList,
        workflowDetail: {
          ...state.workflowDetail,
          name: action.payload.name,
          status: action.payload.status,
        },
      };
    case actions.UPDATE_WORKFLOW_STATUS_CHANGE_LOADER:
      return {
        ...state,
        isWorkflowStatusUpdateLoader: action.payload,
      };
    case actions.UPDATE_WORKFLOW_NAME_CHANGE_LOADER:
      return {
        ...state,
        isWorkflowNameLoader: action.payload,
      };
    case actions.SET_DELAY_TYPE:
      return {
        ...state,
        delayType: action.payload,
      };
    case actions.CLEAR_WORKFLOW_CUSTOM_FIELDS:
      return {
        ...state,
        customFieldList: [],
      };
    case actions.DELAY_CREATED:
      return {
        ...state,
        delayCreated: action.payload,
      };
    case actions.SHOW_EMPTY_PAGE_LOADER:
      return {
        ...state,
        showEmptyPageLoader: true,
      };
    case actions.SET_WORKFLOW_TRIGGER_MATCHES_A_CUSTOM_AUDIENCE:
      return {
        ...state,
        isSegmentDrawerOpen: action.payload.status,
        triggerData: action.payload.data,
        triggerOption: action.payload.data
          ? action.payload.data.properties.trigger_option
          : 'only_existing',
      };
    case actions.TRIGGER_OPTION:
      return {
        ...state,
        triggerOption: action.payload,
      };
    case actions.SET_WORKFLOW_GOAL_DRAWER:
      return {
        ...state,
        isGoalDrawerOpen: action.payload.status,
        goalData: action.payload.data,
      };
    case actions.APPEND_WORKFLOW_TAG_LIST:
      const newTag = [
        {
          id: action.payload.id,
          display_name: action.payload.tags,
        },
      ];
      return {
        ...state,
        tagList: [...newTag, ...state.tagList],
      };
    case actions.SET_WORKFLOW_TEMPLATE_STATUS_LOADER:
      return {
        ...state,
        workflowLoader: {
          ...state.workflowLoader,
          publishLoader: action.payload,
        },
      };
    case actions.UPDATE_WORKFLOW_TEMPLATE:
      return {
        ...state,
        isTemplateUpdate: true,
      };
    case actions.UPDATE_WORKFLOW_TEMPLATE_DRAWER_VISIBLE:
      return {
        ...state,
        isTemplateDrawerVisible: action.payload,
      };
    case actions.UPDATE_WORKFLOW_TEMPLATE_SUCCESS:
      let workflowTemplateList = state.workflowList;
      workflowTemplateList = workflowTemplateList.map((workflow) => {
        if (workflow.id === action.payload.id) {
          workflow.status = action.payload.status;
        }
        return workflow;
      });
      return {
        ...state,
        isTemplateUpdate: false,
        workflowList: workflowTemplateList,
        workflowDetail: {
          ...state.workflowDetail,
          name: action.payload.name,
          status: action.payload.status,
          description: action.payload.description,
          workflow_template_category_id:
            action.payload.workflow_template_category_id,
        },
        isTemplateDrawerVisible: false,
        workflowLoader: {
          publishLoader: false,
        },
      };
    case actions.UPDATE_WORKFLOW_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateUpdate: false,
        isTemplateDrawerVisible: !action.changeStatus,
        workflowLoader: {
          publishLoader: false,
        },
      };
    case actions.DUPLICATE_WORKFLOW:
      return {
        ...state,
        isComponentCreateLoading: true,
      };
    case actions.DUPLICATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        isComponentCreateLoading: false,
        isDrawerOpen: false,
      };
    case actions.DUPLICATE_WORKFLOW_FAILURE:
      return {
        ...state,
        isComponentCreateLoading: false,
        isDrawerOpen: true,
      };
    case actions.SET_ALL_COMPONENTS_CONFIGURED:
      return {
        ...state,
        allComponentsConfigured: action.payload,
      };
    case actions.CREATE_WORKFLOW_FAILURE:
      return {
        ...state,
        isDrawerOpen: true,
        workflowNameSet: action.payload,
        duplicateId: '',
        isFromError: true,
      };
    case actions.EMAIL_ACTION_VISIBLE:
      return {
        ...state,
        emailActionVisible: action.payload,
      };
    case actions.WORKFLOW_FETCH_TEMPLATES_SUCCESS:
      let templateContent = _.find(
        action.payload.gist_templates,
        (template) => template.id === action.payload.default_template_id,
      )
        ? _.find(
            action.payload.gist_templates,
            (template) => template.id === action.payload.default_template_id,
          )
        : _.find(
            action.payload.custom_templates,
            (template) => template.id === action.payload.default_template_id,
          );
      return {
        ...state,
        emailTemplates: action.payload,
        emailTemplatesLoader: false,
        broadCastTemplateId: action.payload.default_template_id,
        broadcastTemplateContent: templateContent.email_content,
      };
    case actions.WORKFLOW_CREATE_BROADCAST_DRAWER:
      return {
        ...state,
        openCreateBroadcastDrawer: action.payload,
      };
    case actions.SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        broadCastTemplateId: action.templateId,
      };
    case actions.SET_SELECTED_TEMPLATE_CONTENT:
      return {
        ...state,
        broadcastTemplateContent: action.payload,
      };
    case actions.SAVE_BROADCAST_DETAILS:
      return {
        ...state,
        broadCastMailText:
          action.broadCastMailText !== undefined
            ? action.broadCastMailText
            : state.broadCastMailText,
        automationMailAttributes: {
          broadCastTemplateName:
            action.broadCastTemplateName !== undefined
              ? action.broadCastTemplateName
              : state.automationMailAttributes.broadCastTemplateName,
          broadCastSubject:
            action.broadCastSubject !== undefined
              ? action.broadCastSubject
              : state.automationMailAttributes.broadCastSubject,
          emailFromUserId: action.emailFromUserId
            ? action.emailFromUserId
            : state.automationMailAttributes.emailFromUserId,
          emailReplyToUserId: action.emailReplyToUserId
            ? action.emailReplyToUserId
            : state.automationMailAttributes.emailReplyToUserId,
          broadCastPreHeaderText:
            action.broadCastPreHeaderText !== undefined
              ? action.broadCastPreHeaderText
              : state.automationMailAttributes.broadCastPreHeaderText,
          postalAddress:
            action.postalAddress !== undefined
              ? action.postalAddress
              : state.automationMailAttributes.postalAddress,
          multipleTimes:
            action.multipleTimes !== undefined
              ? action.multipleTimes
              : state.automationMailAttributes.multipleTimes,
          formReset: action.formReset
            ? action.formReset
            : state.automationMailAttributes.formReset,
        },
      };
    case actions.RESET_BROADCAST_DETAILS: {
      const teammateList = state.teammateList;
      let emailReplyToUserId,
        emailFromUserId = undefined;
      if (teammateList.out_bound_address || teammateList.team_mates) {
        emailReplyToUserId = teammateList.out_bound_address[0]
          ? {
              type: 'reply_to_custom_team_mate',
              data: { assignee_id: teammateList.out_bound_address[0].id },
            }
          : {
              type: 'reply_to_user',
              data: { assignee_id: teammateList.team_mates[0].id },
            };
        emailFromUserId = teammateList.out_bound_address[0]
          ? {
              type: 'from_custom_team_mate',
              from_user_id: teammateList.out_bound_address[0].id,
            }
          : {
              type: 'from_user',
              from_user_id: teammateList.team_mates[0].id,
            };
      }
      return {
        ...state,
        broadCastMailText: 'Hi {{contact.first_name}},',
        automationMailAttributes: {
          broadCastTemplateName: undefined,
          broadCastSubject: '',
          emailFromUserId,
          emailReplyToUserId,
          broadCastPreHeaderText: undefined,
          postalAddress: undefined,
          multipleTimes: false,
          formReset: false,
        },
        actionData: null,
        validateEMailLoader: false,
        openCreateBroadcastDrawer: false,
        emailActionVisible: false,
      };
    }
    case actions.EDIT_BROADCAST_DETAILS:
      return {
        ...state,
        emailTemplates: [],
      };
    case actions.EDIT_BROADCAST_DETAILS_SUCCESS:
      return {
        ...state,
        broadCastMailText: action.payload.mail_text,
        emailTemplates: action.payload,
        automationMailAttributes: {
          broadCastTemplateName: action.payload.title,
          broadCastSubject: action.payload.subject,
          emailFromUserId: getFromUserData(action.payload),
          emailReplyToUserId: getToUserData(action.payload),
          broadCastPreHeaderText: action.payload.pre_header_text,
          postalAddress: action.payload.postal_address,
          multipleTimes: action.data.properties.send_multiple_times,
          formReset: state.automationMailAttributes.formReset,
        },
        broadcastTemplateContent: action.payload.template_content,
        actionData: action.data,
        broadCastTemplateId: action.payload.template_id,
      };
    case actions.VALIDATE_EMAIL_NAME:
      return {
        ...state,
        validateEMailLoader: true,
      };
    case actions.VALIDATE_EMAIL_NAME_SUCCESS:
      return {
        ...state,
        validateEMailLoader: false,
      };
    case actions.VALIDATE_EMAIL_NAME_FAILURE:
      return {
        ...state,
        validateEMailLoader: false,
      };
    case actions.GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productLists: action.payload,
      };
    case actions.UPDATE_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productLists: [...state.productLists, ...action.payload],
      };
    case actions.GET_PRODUCTS_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        productCategoryList: action.payload,
      };
    case actions.UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        productCategoryList: [...state.productCategoryList, ...action.payload],
      };
    case actions.PRODUCTS_CATEGORIES_SEARCH:
      return {
        ...state,
        categorySearch: action.value,
      };
    case actions.PRODUCTS_LIST_SEARCH:
      return {
        ...state,
        productSearch: action.value,
      };
    case actions.SET_WORKFLOW_CHAT_NAME_DRAWER_STATUS:
      return {
        ...state,
        isChatNameDrawerOpen: action.payload.status,
        actionData: action.payload.data,
      };
    case actions.CREATE_WORKFLOW_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        isChatNameDrawerOpen: false,
        chatDetails: action.payload,
        isChatDrawerOpen: true,
        chatNameLoader: false,
      };
    case actions.CREATE_WORKFLOW_CHAT_MESSAGE:
      return {
        ...state,
        chatNameLoader: true,
      };
    case actions.SET_WORKFLOW_CHAT_DRAWER_STATUS:
      return {
        ...state,
        isChatDrawerOpen: action.payload.status,
        chatDetails: action.payload.chatDetails ? state.chatDetails : null,
        actionData: action.payload.data,
      };
    case actions.GET_WORKFLOW_CHAT_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        chatDetails: action.payload,
        chatNameLoader: false,
      };
    case actions.UPDATE_WORKFLOW_CHAT_MESSAGE_DETAILS:
      return {
        ...state,
        chatDetails: { ...state.chatDetails, ...action.payload },
      };
    case actions.CREATE_WORKFLOW_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        chatNameLoader: false,
      };
    case actions.SET_WORKFLOW_POST_NAME_DRAWER_STATUS:
      return {
        ...state,
        isPostNameDrawerOpen: action.payload.status,
        actionData: action.payload.data,
      };
    case actions.CREATE_WORKFLOW_POST_MESSAGE_SUCCESS:
      return {
        ...state,
        isPostNameDrawerOpen: false,
        postDetails: action.payload,
        isPostDrawerOpen: true,
        postNameLoader: false,
      };
    case actions.CREATE_WORKFLOW_POST_MESSAGE:
      return {
        ...state,
        postNameLoader: true,
      };
    case actions.SET_WORKFLOW_POST_DRAWER_STATUS:
      return {
        ...state,
        isPostDrawerOpen: action.payload.status,
        postDetails: action.payload.postDetails ? state.postDetails : null,
        actionData: action.payload.data,
      };
    case actions.GET_WORKFLOW_POST_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        postDetails: action.payload,
        postNameLoader: false,
      };
    case actions.UPDATE_WORKFLOW_POST_MESSAGE_DETAILS:
      return {
        ...state,
        postDetails: { ...state.postDetails, ...action.payload },
      };
    case actions.CREATE_WORKFLOW_POST_MESSAGE_FAILURE:
      return {
        ...state,
        postNameLoader: false,
      };
    case actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS: {
      return {
        ...state,
        hubspotDetailsLoader: true,
      };
    }
    case actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_SUCCESS: {
      return {
        ...state,
        hubspotDetailsLoader: false,
        hubspotStatus: true,
        hubspotDetails: action.payload,
      };
    }
    case actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS_FAILURE: {
      return {
        ...state,
        hubspotDetailsLoader: false,
        hubspotStatus: false,
      };
    }
    case actions.GET_MESSAGES: {
      return {
        ...state,
        messageLoader: true,
      };
    }
    case actions.GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messageLoader: false,
        messageData: action.payload,
      };
    }
    case actions.GET_MESSAGES_FAILURE: {
      return {
        ...state,
        messageLoader: false,
      };
    }
    case actions.GET_SURVEY: {
      return {
        ...state,
        surveyLoader: true,
      };
    }
    case actions.GET_SURVEY_SUCCESS: {
      return {
        ...state,
        surveyLoader: false,
        surveyData: action.payload,
      };
    }
    case actions.GET_SURVEY_FAILURE: {
      return {
        ...state,
        surveyLoader: false,
      };
    }
    case actions.UPDATE_TRIGGER_SELECTED_WEBHOOK_DATA: {
      return {
        ...state,
        selectedWebhookData: action.payload,
      };
    }
    case actions.UPDATE_ACTION_SELECTED_TRACK_CUSTOM_EVENT_DATA: {
      return {
        ...state,
        selectedCustomEventData: action.payload,
      };
    }
    case actions.UPDATE_WORKFLOW_ZOOM: {
      return {
        ...state,
        workflowZoom: action.payload,
      };
    }
    case actions.GET_TOUR:
      return {
        ...state,
        tourLoader: true,
      };
    case actions.GET_TOUR_SUCCESS:
      return {
        ...state,
        tourData: action.payload,
        tourLoader: false,
      };
    case actions.GET_TOUR_FAILURE:
      return {
        ...state,
        tourLoader: false,
      };
    case actions.GET_FORM:
      return {
        ...state,
        formLoader: true,
      };
    case actions.GET_FORM_SUCCESS:
      return {
        ...state,
        formData: action.payload,
        formLoader: false,
      };
    case actions.GET_FORM_FAILURE:
      return {
        ...state,
        formLoader: false,
      };
    case actions.GET_WORKFLOW:
      return {
        ...state,
        workflowConditionLoader: true,
      };
    case actions.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        workflowData: action.payload,
        workflowConditionLoader: false,
      };
    case actions.GET_WORKFLOW_FAILURE:
      return {
        ...state,
        workflowConditionLoader: false,
      };
    case actions.GET_BOTS:
      return {
        ...state,
        botLoader: true,
      };
    case actions.GET_BOTS_SUCCESS:
      return {
        ...state,
        newConversationBotData:
          action.botType === 'new_conversation_bot'
            ? action.payload
            : state.newConversationBotData,
        instantBotData:
          action.botType === 'instant_bot'
            ? action.payload
            : state.instantBotData,
        botLoader: false,
      };
    case actions.GET_BOTS_FAILURE:
      return {
        ...state,
        botLoader: false,
      };
    case actions.GET_DEAL_STAGES_SUCCESS:
      return {
        ...state,
        stages: action.payload,
      };
    case actions.START_TROUBLESHOOT_SUCCESS:
      return {
        ...state,
        isTroubleShootModeOn: true,
        currentFlowId: action.workflowId,
      };
    case actions.FETCH_PERSON_FLOWS_SUCCESS:
      return {
        ...state,
        troubleShootPersonFlow: action.payload || {},
      };
    case actions.FETCH_WORKFLOW_PERSON_ACTIVITIES_SUCCESS:
      return {
        ...state,
        troubleShootPersonActivities: {
          ...state.troubleShootPersonActivities,
          [action.flowId]: action.payload,
        },
        currentFlowId: action.flowId,
        isTroubleShootModeOn: true,
      };
    case actions.STOP_TROUBLESHOOT:
      let completedNodes = [];
      const nodes = cloneDeep(state.updatedNodes);
      nodes.map((node) => {
        completedNodes.push(node.id);
        toolkit.updateNode(node.id, {
          ...node,
          data: { ...node.data, nodeStatus: '' },
        });
      });
      handleHighlightConnections(completedNodes, 'remove');
      const workflowURL = window.location.href.split('?')[0];
      window.history.pushState({}, '', workflowURL);
      return {
        ...state,
        troubleShootPersonActivities: {},
        troubleShootPersonFlow: {},
        isTroubleShootModeOn: false,
        currentFlowId: '',
      };
    case actions.UPDATE_PERSON_WORKFLOW_ACTIVITIES:
      const flowId = action.payload.workflow_person_id;
      let activityPresent = false;
      let flowActivities = cloneDeep(
        state.troubleShootPersonActivities[flowId] || [],
      );
      if (flowActivities.length) {
        if (
          flowActivities[flowActivities.length - 1].workflow_component_id ===
          action.payload.workflow_component_id
        ) {
          flowActivities[flowActivities.length - 1] = action.payload;
          activityPresent = true;
        }
      }
      if (!activityPresent) {
        flowActivities.push(action.payload);
      }
      return {
        ...state,
        troubleShootPersonActivities: {
          ...state.troubleShootPersonActivities,
          [flowId]: flowActivities,
        },
      };
    case actions.RESET_WORKFLOW_EDIT_MODE:
      return {
        ...state,
        troubleShootPersonActivities: {},
        troubleShootPersonFlow: {},
        isTroubleShootModeOn: false,
        currentFlowId: '',
        workflowDetail: null,
      };
    case actions.GET_UPDATED_NODES:
      return {
        ...state,
        updatedNodes: action.payload,
      };
    case actions.FETCH_WORKFLOW_CONTACT_REPORT:
      return {
        ...state,
        reportsLoader: true,
      };
    case actions.FETCH_WORKFLOW_CONTACT_REPORT_SUCCESS:
      return {
        ...state,
        reportsLoader: false,
        workflowContactReports: action.payload,
      };
    case actions.FETCH_WORKFLOW_CONTACT_REPORT_FAILURE:
      return {
        ...state,
        reportsLoader: false,
      };
    case actions.FETCH_COMPONENT_CONTACT_REPORT:
      return {
        ...state,
        reportsLoader: true,
      };
    case actions.FETCH_COMPONENT_CONTACT_REPORT_FAILURE:
      return {
        ...state,
        reportsLoader: false,
      };
    case actions.FETCH_COMPONENT_CONTACT_REPORT_SUCCESS:
      return {
        ...state,
        reportsLoader: false,
        componentContactReports: action.payload,
      };
    case actions.APPEND_WORKFLOW_CONTACT_REPORT_SUCCESS:
      let workflowReports = cloneDeep(state.workflowContactReports);
      return {
        ...state,
        reportsLoader: false,
        workflowContactReports: [...workflowReports, ...action.payload],
      };
    case actions.APPEND_COMPONENT_CONTACT_REPORT_SUCCESS:
      let componentContactReports = cloneDeep(state.componentContactReports);
      return {
        ...state,
        reportsLoader: false,
        componentContactReports: [
          ...componentContactReports,
          ...action.payload,
        ],
      };
    case actions.SHOW_CONTACT_REPORT_DRAWER:
      return {
        ...state,
        contactReportDrawer: action.payload,
        gotoData: action.data || {},
      };
    case actions.SET_TROUBLESHOOT_MODE:
      return {
        ...state,
        isTroubleShootModeOn: action.payload,
      };
    default:
      return state;
  }
}
