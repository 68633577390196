import React, { useEffect } from 'react';
import JungleSelect from 'react-jungle-select';

const JunglePicker = props => {
  const {
    jungleSelectProps,
    baseJungleSelectProps,
    omniSearchEventListener,
    removeOmniSearchListener,
  } = props;

  useEffect(() => {
    omniSearchEventListener();

    return () => {
      removeOmniSearchListener();
    };
  }, []);
  return <JungleSelect {...jungleSelectProps} {...baseJungleSelectProps} />;
};
export default JunglePicker;
