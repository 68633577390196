const actions = {
  FETCH_DEVELOPER_APPS_LIST: 'FETCH_DEVELOPER_APPS_LIST',
  FETCH_DEVELOPER_APPS_LIST_SUCCESS: 'FETCH_DEVELOPER_APPS_LIST_SUCCESS',
  CREATE_APP: 'CREATE_APP',
  CREATE_APP_SUCCESS: 'CREATE_APP_SUCCESS',
  UPDATE_APP_DATA: 'UPDATE_APP_DATA',
  UPDATE_APP_DATA_SUCCESS: 'UPDATE_APP_DATA_SUCCESS',
  UPDATE_APP_DATA_FAILURE: 'UPDATE_APP_DATA_FAILURE',
  FETCH_DEVELOPER_APP_DATA: 'FETCH_DEVELOPER_APP_DATA',
  FETCH_DEVELOPER_APP_DATA_SUCCESS: 'FETCH_DEVELOPER_APP_DATA_SUCCESS',
  FETCH_O_AUTH_SCOPES_AND_CATEGORIES: 'FETCH_O_AUTH_SCOPES_AND_CATEGORIES',
  FETCH_O_AUTH_SCOPES_AND_CATEGORIES_SUCCESS:
    'FETCH_O_AUTH_SCOPES_AND_CATEGORIES_SUCCESS',
  INSTALL_APP: 'INSTALL_APP',
  UNINSTALL_APP: 'UNINSTALL_APP',
  FETCH_INSTALLED_DEVELOPER_APPS: 'FETCH_INSTALLED_DEVELOPER_APPS',
  FETCH_INSTALLED_DEVELOPER_APPS_SUCCESS:
    'FETCH_INSTALLED_DEVELOPER_APPS_SUCCESS',
  FETCH_INSTALLED_DEVELOPER_APPS_FAILURE:
    'FETCH_INSTALLED_DEVELOPER_APPS_FAILURE',
  FETCH_PUBLIC_APPS: 'FETCH_PUBLIC_APPS',
  FETCH_PUBLIC_APPS_SUCCESS: 'FETCH_PUBLIC_APPS_SUCCESS',
  APP_INSTALLED_STATE_LOADING: 'APP_INSTALLED_STATE_LOADING',
  CHANGE_REQUIRED_MODAL_STATUS: 'CHANGE_REQUIRED_MODAL_STATUS',
  CHANGE_REQUIRED_MODAL_LOADER: 'CHANGE_REQUIRED_MODAL_LOADER',
  CHANGE_CURRENT_STEP_STATE: 'CHANGE_CURRENT_STEP_STATE',
  UPDATE_INSTALLED_STATE: 'UPDATE_INSTALLED_STATE',
};

export default actions;
