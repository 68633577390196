import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import sharedActions from '../../../redux/sharedReducers/actions';
import moment from 'moment';
import JungleSelect from 'react-jungle-select';

const SnoozeWidget = props => {
  const dispatch = useDispatch();
  const [snoozeSearchText, setSnoozeSearchText] = useState('');
  const {
    omniSearchEventListener,
    removeOmniSearchListener,
    snoozeConversation,
    getCommandItem,
  } = props;

  useEffect(() => {
    omniSearchEventListener();

    return () => {
      removeOmniSearchListener();
    };
  }, []);

  const handleSnoozeFilter = value => {
    setSnoozeSearchText(parseInt(value));
  };

  const handleCustomSnoozeSelect = () => {
    dispatch({
      type: sharedActions.SHOW_HIDE_CUSTOM_SNOOZE_MODAL,
      payload: true,
    });
    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  const handleSnoozeSelect = command => {
    snoozeConversation(command.action);
  };

  const defaultSnoozeList = [
    {
      name: 'Later today',
      action: 'later',
      custom_text: moment()
        .add(4, 'hour')
        .format('LT'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: 'Tomorrow',
      action: 'tomorrow',
      custom_text: moment()
        .add(1, 'day')
        .set({
          hour: 7,
          minute: 0,
          seconds: 0,
        })
        .format('ddd h A'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: 'Monday',
      action: 'monday',
      custom_text: 'Mon 7 AM',
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: 'One week',
      action: 'week',
      custom_text: moment()
        .add(1, 'week')
        .format('ddd, MMM D'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: 'One month',
      action: 'month',
      custom_text: moment()
        .add(1, 'month')
        .format('ddd, MMM D'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: 'Custom',
      action: 'custom',
      custom_text: '',
      icon: 'Snooze',
      show_in_omni_search: true,
    },
  ];

  const customSnoozeList = [
    {
      name: `for ${snoozeSearchText > 24 ? '24' : snoozeSearchText} hour${
        snoozeSearchText > 1 ? 's' : ''
      }`,
      action: `${snoozeSearchText}_hour`,
      custom_text: moment()
        .add(snoozeSearchText, 'hour')
        .format('ddd, MMM D, LT'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: `for ${snoozeSearchText > 7 ? '7' : snoozeSearchText} day${
        snoozeSearchText > 1 ? 's' : ''
      }`,
      action: `${snoozeSearchText}_day`,
      custom_text: moment()
        .add(snoozeSearchText, 'day')
        .format('ddd, MMM D, LT'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: `for ${snoozeSearchText > 4 ? '4' : snoozeSearchText} week${
        snoozeSearchText > 1 ? 's' : ''
      }`,
      action: `${snoozeSearchText}_week`,
      custom_text: moment()
        .add(snoozeSearchText, 'week')
        .format('ddd, MMM D, LT'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
    {
      name: `for ${snoozeSearchText > 2 ? '2' : snoozeSearchText} month${
        snoozeSearchText > 1 ? 's' : ''
      }`,
      action: `${snoozeSearchText}_month`,
      custom_text: moment()
        .add(snoozeSearchText, 'month')
        .format('ddd, MMM D, LT'),
      icon: 'Snooze',
      show_in_omni_search: true,
    },
  ];

  return (
    <>
      <JungleSelect
        items={snoozeSearchText ? customSnoozeList : defaultSnoozeList}
        renderItem={item => getCommandItem(item)}
        placeholder="Try: 1 hour, 1 day, 1 week"
        searchable={true}
        searchableAttributes={['name']}
        listWrapper={list => <div className="command-list">{list}</div>}
        onFilter={value => handleSnoozeFilter(value)}
        onChange={value =>
          value.action === 'custom'
            ? handleCustomSnoozeSelect()
            : handleSnoozeSelect(value)
        }
        mode="list"
        selectFirstItem={true}
        autofocus={true}
      />
    </>
  );
};

export default SnoozeWidget;
