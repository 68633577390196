import React from 'react';
import { Button, Divider, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import SnippetContentHeader from '../../Settings/Conversations/Snippets/SnippetContentHeader';

const SnippetPreview = ({ handleClick }) => {
  const snippet = useSelector(({ Chat }) => Chat.singleSnippetData);
  return (
    <div className="snippet-content-preview">
      <div className="header">
        <div className="title-container">
          <Tooltip placement="top" title={snippet.title}>
            <div className="title">{snippet.title}</div>
          </Tooltip>
          <Button
            className={'btn btn-primary btn-sm'}
            onClick={() => {
              handleClick(snippet);
            }}
          >
            Insert
          </Button>
        </div>
        {snippet && <SnippetContentHeader snippetData={snippet} />}
      </div>

      <Divider className="divider" />

      <div className="content">
        {snippet.notes && <div className="notes">{snippet.notes}</div>}
        <div dangerouslySetInnerHTML={{ __html: snippet.message }} />
      </div>
    </div>
  );
};

export default SnippetPreview;
