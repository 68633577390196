import actions from './actions';
import _ from 'lodash';

let initState = {
  eventsCategories: [],
  loader: {
    pageLoader: false,
    buttonLoader: false,
    deleteLoader: false,
    dashBoardLoader: false,
  },
  eventsData: [],
  eventsEditData: [],
  eventsDropdown: [],
  eventsDropdownLoader: true,
  customEventsDropdown: [],
  customEventPropertiesDropdown: {},
  eventId: '',
  drawerVisible: false,
  visualiserDrawer: false,
  visualiserData: [],
  selectedRecentUrl: '',
  eventsSearch: '',
  searchedEvents: [],
  currentStepKey: 0,
  eventTotalCount: null,
  openKeyId: null,
};

export default function eventsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_EVENT_CATEGORIES:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: true,
        },
      };
    case actions.FETCH_EVENT_CATEGORIES_SUCCESS:
      let categories = [];
      if (action.append) {
        categories = state.eventsCategories.concat(
          action.payload.data.event_categories,
        );
      } else {
        categories = action.payload.data.event_categories;
      }
      return {
        ...state,
        eventsCategories: categories,
        eventTotalCount: action.payload.data.total_events,
        loader: {
          ...state.loader,
          pageLoader: false,
        },
      };
    case actions.FETCH_EVENT_CATEGORIES_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
        },
      };
    case actions.FETCH_EVENT_DATA:
      return {
        ...state,
        loader: {
          ...state.loader,
          dashBoardLoader: true,
        },
      };
    case actions.FETCH_EVENT_DATA_SUCCESS:
      return {
        ...state,
        eventsData: action.payload,
        loader: {
          ...state.loader,
          dashBoardLoader: false,
        },
      };
    case actions.FETCH_EVENT_DATA_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          dashBoardLoader: false,
        },
      };
    case actions.FETCH_EVENT_EDIT_DATA:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: true,
        },
      };
    case actions.FETCH_EVENT_EDIT_DATA_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
        },
        eventsEditData: action.payload,
      };
    case actions.FETCH_EVENT_EDIT_DATA_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
        },
      };
    case actions.FETCH_CATEGORY_DROPDOWN: {
      return {
        ...state,
        eventsDropdownLoader: true,
      };
    }
    case actions.FETCH_CATEGORY_DROPDOWN_SUCCESS: {
      return {
        ...state,
        eventsDropdown: action.payload,
        eventsDropdownLoader: false,
      };
    }
    case actions.FETCH_CUSTOM_EVENTS_DROPDOWN_SUCCESS: {
      return {
        ...state,
        customEventsDropdown: action.payload,
      };
    }
    case actions.FETCH_CUSTOM_FILTER_LIST_SUCCESS: {
      return {
        ...state,
        customEventPropertiesDropdown: action.payload,
      };
    }
    case actions.FETCH_CATEGORY_DROPDOWN_FAILURE: {
      return {
        ...state,
        eventsDropdownLoader: false,
      };
    }
    case actions.EVENT_ID_CHANGE:
      return {
        ...state,
        eventId: action.payload,
      };
    case actions.EDIT_EVENT:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: true,
          buttonLoader: true,
        },
      };
    case actions.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
          buttonLoader: false,
        },
      };
    case actions.EDIT_EVENT_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
          buttonLoader: false,
        },
      };
    case actions.ADD_EVENT:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: true,
          buttonLoader: true,
        },
      };
    case actions.ADD_EVENT_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
          buttonLoader: false,
        },
      };
    case actions.ADD_EVENT_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
          buttonLoader: false,
        },
      };
    case actions.DELETE_EVENT:
      return {
        ...state,
        loader: {
          ...state.loader,
          deleteLoader: true,
        },
      };
    case actions.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          deleteLoader: false,
        },
      };
    case actions.DELETE_EVENT_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          deleteLoader: false,
        },
      };
    case actions.EVENT_DRAWER_CLOSE:
      return {
        ...state,
        drawerVisible: false,
      };
    case actions.EVENT_DRAWER_OPEN:
      return {
        ...state,
        drawerVisible: true,
      };
    case actions.EVENT_CATEGORY_DRAWER:
      return {
        ...state,
        categoryDrawer: action.payload,
      };
    case actions.CLEAR_DRAWER_DATA:
      return {
        ...state,
        eventsEditData: [],
      };
    case actions.CREATE_CATEGORY:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: true,
        },
      };
    case actions.CREATE_CATEGORY_SUCCESS:
      let eventsCategories = _.cloneDeep(state.eventsCategories);
      eventsCategories.unshift(action.payload);
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
        eventsCategories,
      };
    case actions.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.EDIT_CATEGORY_NAME:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.EDIT_CATEGORY_NAME_SUCCESS:
      let editedCategories = [...state.eventsCategories];
      editedCategories = editedCategories.map(data => {
        if (data.id === action.payload.id) {
          data.name = action.payload.name;
        }
        return data;
      });
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
        eventsCategories: editedCategories,
      };
    case actions.EDIT_CATEGORY_NAME_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.DELETE_CATEGORY:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: true,
        },
      };
    case actions.DELETE_CATEGORY_SUCCESS:
      let deletedData = [...state.eventsCategories];
      deletedData = deletedData.filter(data => data.id !== action.payload);
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
        eventsCategories: deletedData,
      };
    case actions.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.EVENT_VISUALISER_DRAWER:
      return {
        ...state,
        visualiserDrawer: action.payload,
      };
    case actions.FETCH_EVENT_VISUALISER:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: true,
        },
      };
    case actions.FETCH_EVENT_VISUALISER_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
        },
        visualiserData: action.payload,
      };
    case actions.FETCH_EVENT_VISUALISER_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          pageLoader: false,
        },
      };
    case actions.SET_RECENT_URL:
      return {
        ...state,
        selectedRecentUrl: action.payload,
      };
    case actions.ADD_LAUNCH_URL:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: true,
        },
      };
    case actions.ADD_LAUNCH_URL_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.ADD_LAUNCH_URL_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.SET_EVENTS_SEARCH:
      return {
        ...state,
        eventsSearch: action.payload,
      };
    case actions.FETCH_SEARCHED_EVENT:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: true,
        },
      };
    case actions.FETCH_SEARCHED_EVENT_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
        searchedEvents: action.payload,
      };
    case actions.FETCH_SEARCHED_EVENT_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          buttonLoader: false,
        },
      };
    case actions.CHANGE_CURRENT_STEP:
      return {
        ...state,
        currentStepKey: action.payload,
      };
    case actions.CHANGE_MENU_ID:
      let openKeys = state.openKeyId ? state.openKeyId : [];
      if (openKeys.includes(action.payload)) {
        openKeys = openKeys.filter(data => data !== action.payload);
      } else {
        openKeys = openKeys.concat(action.payload);
      }
      return {
        ...state,
        openKeyId: openKeys,
      };
    default:
      return state;
  }
}
