import actions from './actions';
import {
  questionSampleData,
  surveySummary,
} from '../../components/Messages/Surveys/constants';
import { cloneDeep } from 'lodash';

const initState = {
  createSurveyDrawer: false,
  createSurveyLoader: false,
  openTagApplyModal: false,
  openSuggestion: false,
  setSurveyName: '',
  setSurveyDuplicateId: '',
  openConfirmPopup: false,
  showDrawerFrom: 'on_going_survey',
  surveyTabKey: 'surveyOnGoing',
  activeSurveyName: '',
  isSelectChannelModalVisible: false,
  messageTagsListLastId: '',
  messageTagsListPage: 1,
  currentMessageTag: { id: '' },
  isSurveyChange: false,
  surveyData: null,
  initialSurveyData: null,
  surveyResponses: [],
  surveyQuestions: [],
  surveyLastResponseId: '',
  surveyResponsesLoader: false,
  surveySummary: surveySummary,
  googleFontsList: [],
  surveyDetails: {},
  surveyDetailsLoader: false,
  surveyCustomThemesList: [],
  setSurveyTheme: null,
  setSelectedSurveyThemeId: '',
  showThemeEditor: false,
  surveyLogicsData: {},
  surveyVariables: [],
  surveySaveButtonLoader: false,
  surveyExportCSVLoader: false,
  isExportCSVModalVisible: false,
  selectedTemplateId: null,
  surveyDefaultTemplateData: null,
};

export default function SurveyReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREATE_SURVEY_DRAWER:
      return {
        ...state,
        createSurveyDrawer: action.payload,
        setSurveyName: action.name ? `${action.name}(Copy)` : '',
        setSurveyDuplicateId: action.id ? action.id : '',
        showDrawerFrom: action.requestFrom,
        selectedTemplateId: action.templateId,
      };
    case actions.CREATE_SURVEY:
      return {
        ...state,
        createSurveyLoader: true,
      };
    case actions.CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        createSurveyLoader: false,
        createSurveyDrawer: false,
      };
    case actions.CREATE_SURVEY_FAILURE:
      return {
        ...state,
        createSurveyLoader: false,
      };
    case actions.DUPLICATE_SURVEY:
      return {
        ...state,
        createSurveyLoader: true,
      };
    case actions.DUPLICATE_SURVEY_SUCCESS:
      return {
        ...state,
        createSurveyLoader: false,
        createSurveyDrawer: false,
      };
    case actions.DUPLICATE_SURVEY_FAILURE:
      return {
        ...state,
        createSurveyLoader: false,
      };
    case actions.UPDATE_TABLE_ELEMENT_STATUS_IN_SURVEY:
      return {
        ...state,
        tableLoading: true,
      };
    case actions.UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_SURVEY:
      return {
        ...state,
        tableLoading: false,
      };

    case actions.SURVEY_SAVE_BUTTON_LOADER_STATUS:
      return {
        ...state,
        surveySaveButtonLoader: action.payload,
      };

    case actions.SET_SURVEY_TAB_KEY:
      return {
        ...state,
        chatTabKey: action.payload,
      };

    case actions.SET_CONFIRM_MODEL_IN_SURVEY:
      return {
        ...state,
        openConfirmPopup: action.payload,
      };

    case actions.UPDATE_LOCAL_SURVEY_DATA: {
      const { survey } = action.payload;
      return {
        ...state,
        surveyData: survey,
        setSurveyTheme: survey.theme,
        surveyLogicsData: action.isInitialSurveyLogics
          ? survey.logics
          : state.surveyLogicsData,
        surveyVariables:
          survey.variables && survey.variables.length
            ? survey.variables
            : [
              {
                id: Math.random()
                  .toString(16)
                  .substr(2, 12),
                key: '',
                value: '',
              },
            ],
        initialSurveyData: action.isInitialSurveyLogics
          ? survey
          : state.initialSurveyData,
      };
    }
    case actions.UPDATE_QUESTIONS_LIST_ORDER: {
      let updatedSurveyData = cloneDeep(state.surveyData);
      updatedSurveyData.steps.forEach(step => {
        if (step.id === action.payload.stepId) {
          step.questions = action.payload.questions;
          return step;
        }
      });

      return {
        ...state,
        surveyData: updatedSurveyData,
      };
    }
    case actions.UPDATE_LOCAL_SINGLE_STEP_DATA: {
      let updatedSurveyData = cloneDeep(state.surveyData);
      let stepIndex = updatedSurveyData.steps.findIndex(
        stepTemp => stepTemp.id === action.payload.step.id,
      );
      updatedSurveyData.steps[stepIndex] = action.payload.step;

      return {
        ...state,
        surveyData: updatedSurveyData,
      };
    }
    case actions.DELETE_LOCAL_SINGLE_STEP_DATA: {
      let updatedSurveyData = cloneDeep(state.surveyData);
      let stepIndex = updatedSurveyData.steps.findIndex(
        stepTemp => stepTemp.id === action.payload.step.id,
      );
      let updateSurveyLogicData = cloneDeep(state.surveyLogicsData);
      let defaultDeletedStep =
        updateSurveyLogicData[action.payload.step.id] &&
        updateSurveyLogicData[action.payload.step.id].default;
      delete updateSurveyLogicData[action.payload.step.id];
      Object.keys(updateSurveyLogicData).map(step => {
        if (updateSurveyLogicData[step].default === action.payload.step.id) {
          if (step === updatedSurveyData.steps[0].id) {
            updateSurveyLogicData[step].default =
              stepIndex === 1
                ? updatedSurveyData.steps[2].id
                : updatedSurveyData.steps[1].id;
          } else {
            updateSurveyLogicData[step].default = defaultDeletedStep;
          }
        }
        updateSurveyLogicData[step].conditions.map((condition, index) => {
          if (condition.jump_step === action.payload.step.id) {
            updateSurveyLogicData[step].conditions.splice(index, 1);
          }
          return condition;
        });
        return step;
      });

      return {
        ...state,
        surveyData: {
          ...state.surveyData,
          logics: updateSurveyLogicData,
          steps: [
            ...state.surveyData.steps.slice(0, stepIndex),
            ...state.surveyData.steps.slice(stepIndex + 1),
          ],
        },
        surveyLogicsData: updateSurveyLogicData,
      };
    }
    case actions.INSERT_SINGLE_QUESTION_DATA: {
      let updatedSurveyData = cloneDeep(state.surveyData);
      let stepIndex = updatedSurveyData.steps.findIndex(
        stepTemp => stepTemp.id === action.payload.step.id,
      );
      var getQuestion = {
        ...questionSampleData[action.payload.questionType],
        id: action.payload.questionId,
      };
      updatedSurveyData.steps[stepIndex].questions.push(getQuestion);

      return {
        ...state,
        surveyData: updatedSurveyData,
      };
    }
    case actions.UPDATE_LOCAL_SINGLE_QUESTION_DATA: {
      let updatedSurveyData = cloneDeep(state.surveyData);
      let stepIndex = updatedSurveyData.steps.findIndex(
        stepTemp => stepTemp.id === action.payload.stepId,
      );
      let questionIndex = updatedSurveyData.steps[
        stepIndex
        ].questions.findIndex(
        questionTemp => questionTemp.id === action.payload.question.id,
      );
      updatedSurveyData.steps[stepIndex].questions[questionIndex] =
        action.payload.question;

      return {
        ...state,
        surveyData: updatedSurveyData,
      };
    }
    case actions.ADD_NEW_STEP_LOGICS: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      updatedSurveyData[action.stepId] = action.payload;
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.ADD_SURVEY_LOGIC_RULE: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      if (!updatedSurveyData[action.stepId]) {
        updatedSurveyData[action.stepId] = {
          default: '',
          conditions: [],
        };
      }
      updatedSurveyData[action.stepId].conditions.push(action.payload);
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.REMOVE_SURVEY_LOGIC_RULE: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      updatedSurveyData[action.stepId].conditions.splice(
        action.conditionIndex,
        1,
      );
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.UPDATE_SURVEY_LOGIC_RULE: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      updatedSurveyData[action.stepId].conditions[action.conditionIndex][
        action.key
        ] = action.value;
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.ADD_LOCAL_SINGLE_STEP_LOGIC: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      updatedSurveyData[action.stepId].conditions[
        action.conditionIndex
        ].actions.push(action.payload);
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.REMOVE_LOCAL_SINGLE_STEP_LOGIC: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      updatedSurveyData[action.stepId].conditions[
        action.conditionIndex
        ].actions.splice(action.actionIndex, 1);
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.UPDATE_LOCAL_SINGLE_STEP_LOGIC: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      updatedSurveyData[action.stepId].conditions[
        action.conditionIndex
        ].actions[action.actionIndex][action.key] = action.value;
      if (action.key === 'question') {
        ['condition', 'answer'].map(
          key =>
            (updatedSurveyData[action.stepId].conditions[
              action.conditionIndex
              ].actions[action.actionIndex][key] = null),
        );
      }
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.UPDATE_DEFAULT_SINGLE_STEP_LOGIC: {
      let updatedSurveyData = cloneDeep(state.surveyLogicsData);
      if (updatedSurveyData[action.stepId]) {
        updatedSurveyData[action.stepId].default = action.payload;
      }
      return {
        ...state,
        surveyLogicsData: updatedSurveyData,
      };
    }
    case actions.REMOVE_DELETED_QUESTION_LOGIC: {
      let updateSurveyLogicData = cloneDeep(state.surveyLogicsData);
      for (let step of Object.keys(updateSurveyLogicData)) {
        for (let rule in updateSurveyLogicData[step].conditions) {
          for (let actions in updateSurveyLogicData[step].conditions[rule]
            .actions) {
            if (
              updateSurveyLogicData[step].conditions[rule].actions[actions]
                .question === action.questionId
            ) {
              updateSurveyLogicData[step].conditions[rule].actions.splice(
                actions,
                1,
              );
            }
          }
          if (
            updateSurveyLogicData[step].conditions[rule].actions &&
            !updateSurveyLogicData[step].conditions[rule].actions.length
          ) {
            updateSurveyLogicData[step].conditions.splice(rule, 1);
          }
        }
      }
      return {
        ...state,
        surveyLogicsData: updateSurveyLogicData,
        surveyData: { ...state.surveyData, logics: updateSurveyLogicData },
      };
    }
    case actions.FETCH_GOOGLE_FONTS_SUCCESS:
      let fontsList = [];
      action.payload.forEach(font => {
        fontsList.push(font.family);
      });

      return {
        ...state,
        googleFontsList: fontsList,
      };
    case actions.FETCH_SURVEY_DETAILS: {
      return {
        ...state,
        surveyDetailsLoader: true,
      };
    }
    case actions.FETCH_SURVEY_CUSTOM_THEMES_SUCCESS:
      return {
        ...state,
        surveyCustomThemesList: action.payload,
      };
    case actions.CREATE_SURVEY_CUSTOM_THEMES_SUCCESS:
      const appendSurveyTheme = state.surveyCustomThemesList.concat(
        action.payload,
      );
      return {
        ...state,
        showThemeEditor: false,
        surveyCustomThemesList: appendSurveyTheme,
      };
    case actions.DELETE_SURVEY_CUSTOM_THEMES_SUCCESS:
      const modifiedThemeList = state.surveyCustomThemesList.filter(
        theme => theme.id !== action.surveyThemeId,
      );
      return {
        ...state,
        surveyCustomThemesList: modifiedThemeList,
      };
    case actions.SET_LOCAL_SURVEY_THEME:
      return {
        ...state,
        surveyData: {
          ...state.surveyData,
          theme: action.payload,
        },
        setSurveyTheme: action.payload,
        setSelectedSurveyThemeId:
          action.selectedThemeId || state.setSelectedSurveyThemeId,
      };
    case actions.UPDATE_SURVEY_CUSTOM_THEMES_SUCCESS:
      let updateSelectedTheme = state.surveyCustomThemesList.map(theme => {
        if (action.payload.id === theme.id) {
          theme = action.payload;
        }
        return theme;
      });
      return {
        ...state,
        setSelectedSurveyThemeId: '',
        surveyCustomThemesList: updateSelectedTheme,
        showThemeEditor: false,
      };
    case actions.UPDATE_SURVEY_CUSTOM_THEMES_FAILURE:
      return {
        ...state,
        setSelectedSurveyThemeId: '',
      };
    case actions.SHOW_SURVEY_THEME_EDITOR_STATUS:
      return {
        ...state,
        showThemeEditor: action.payload,
        setSelectedSurveyThemeId: !action.payload
          ? ''
          : state.setSelectedSurveyThemeId,
      };
    case actions.SET_CREATED_MESSAGE_TAG:
      return {
        ...state,
        currentMessageTag: action.payload,
      };
    case actions.FETCH_SURVEY_ONGOING_REPORTS:
      return {
        ...state,
        surveyResponsesLoader: true,
      };
    case actions.FETCH_SURVEY_ONGOING_REPORTS_SUCCESS:
      return {
        ...state,
        surveyResponses: action.payload.responses,
        surveyQuestions: action.payload.question_contents,
        surveyLastResponseId: action.payload.last_response_id,
        surveyResponsesLoader: false,
      };
    case actions.FETCH_SURVEY_ONGOING_REPORTS_FAILURE:
      return {
        ...state,
        surveyResponsesLoader: false,
      };
    case actions.UPDATE_SURVEY_SAVED_DETAILS:
      return {
        ...state,
        initialSurveyData: state.surveyData,
      };
    case actions.APPEND_SURVEY_ONGOING_REPORTS_SUCCESS:
      let appendTableResponses = [];
      if (action.payload) {
        appendTableResponses =
          action.payload.responses && action.payload.responses.length
            ? action.payload.responses
            : [];
      }
      return {
        ...state,
        surveyResponses: appendTableResponses.length
          ? state.surveyResponses.concat(appendTableResponses)
          : state.surveyResponses,
        surveyLastResponseId:
          appendTableResponses.length >= 15
            ? action.payload.last_response_id
            : null,
        surveyResponsesLoader: false,
      };
    case actions.EXPORT_SURVEY_CSV_MODAL:
      return {
        ...state,
        isExportCSVModalVisible: action.payload,
      };
    case actions.EXPORT_SURVEY_RESPONSE_CSV:
      return {
        ...state,
        surveyExportCSVLoader: true,
      };
    case actions.EXPORT_SURVEY_CSV_LOADER:
      return {
        ...state,
        surveyExportCSVLoader: action.payload,
      };
    case actions.RESET_SURVEY_DETAILS:
      return {
        ...state,
        surveyData: null,
      };
    case actions.SET_SURVEY_DEFAULT_TEMPLATE_DETAILS:
      return {
        ...state,
        surveyDefaultTemplateData: action.isDefaultTemplate
          ? state.surveyDefaultTemplateData
          : action.payload,
        surveyData: action.isDefaultTemplate
          ? action.payload
          : state.surveyData,
        setSurveyTheme: action.isDefaultTemplate
          ? action.payload.theme
          : state.setSurveyTheme,
      };
    case actions.ADD_SURVEY_MAP_VARIABLE: {
      let getSurveyVariables = cloneDeep(state.surveyVariables);
      getSurveyVariables.push(action.payload);
      return {
        ...state,
        surveyVariables: getSurveyVariables,
      };
    }
    case actions.UPDATE_SURVEY_MAP_VARIABLE_DATA: {
      let getSurveyVariables = cloneDeep(state.surveyVariables);
      for (let item of getSurveyVariables) {
        if (item.id === action.variableId) {
          item[action.key] = action.value;
          break;
        }
      }
      return {
        ...state,
        surveyVariables: getSurveyVariables,
      };
    }
    case actions.REMOVE_SURVEY_MAP_VARIABLE: {
      let getSurveyVariables = cloneDeep(state.surveyVariables);
      getSurveyVariables = getSurveyVariables.filter(
        item => item.id !== action.variableId,
      );
      return {
        ...state,
        surveyVariables: getSurveyVariables,
      };
    }
    default:
      return state;
  }
}
