export const availableInboxWidgets = [
  {
    id: null,
    inbox_app_id: 'conversation_details',
    name: 'Conversation Details',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'qualification',
    name: 'Qualification',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'recent_page_views',
    name: 'Recent page views',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'past_conversations',
    name: 'Past conversations',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'contact_details',
    name: 'Contact details',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'tags',
    name: 'Tags',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'segments',
    name: 'Segments',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'campaigns',
    name: 'Campaigns',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'workflows',
    name: 'Workflows',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'notes',
    name: 'Notes',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'meetings',
    name: 'Meetings',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'email',
    name: 'Emails',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'subscription_preferences',
    name: 'Subscription preferences',
    notifiable: false,
    icon_url: null,
  },
  {
    id: null,
    inbox_app_id: 'deals',
    name: 'Deals',
    notifiable: false,
    icon_url: null,
  },
];

export const TextDom = [
  {
    type: 'text',
    text: 'This is a header',
    style: 'header',
    id: 'header-1',
  },
  {
    type: 'text',
    text:
      "This is paragraph text. Here's a [link](https://developers.getgist.com/). Here's some **bold text**. Lorem ipsum.",
    style: 'paragraph',
  },
  {
    type: 'text',
    text:
      "This is muted text. Here's a [link](https://developers.getgist.com/). Here's some *italic text*. Lorem ipsum.",
    style: 'muted',
  },
  {
    type: 'text',
    text:
      "This is error text. Here's a [link](https://developers.getgist.com/). Here's some **bold text**. Lorem ipsum.",
    style: 'error',
  },
  {
    type: 'text',
    text: 'Left aligned',
    align: 'left',
  },
  {
    type: 'text',
    text: 'Center aligned',
    align: 'center',
  },
  {
    type: 'text',
    text: 'Right aligned',
    align: 'right',
  },
];

export const DataTableDom = [
  {
    type: 'data-table',
    items: [
      {
        type: 'field-value',
        field: 'Key',
        value: 'Value 1',
      },
      {
        type: 'field-value',
        field: 'Key',
        value: 'Value 2',
      },
      {
        type: 'field-value',
        field: 'Key',
        value:
          'Value 3 which is a very long value that will exhibit different behaviours to the other values',
      },
    ],
  },
];

export const ImageDom = [
  {
    type: 'image',
    url: 'https://picsum.photos/530/240',
    height: 240,
    width: 530,
    id: 'image-1',
    bottom_margin: 'none',
    action: {
      type: 'url',
      url: 'https://getgist.com',
    },
  },
  {
    type: 'image',
    url: 'https://picsum.photos/600',
    height: 64,
    width: 64,
    align: 'left',
  },
  {
    type: 'image',
    url: 'https://picsum.photos/600',
    height: 64,
    width: 64,
    align: 'center',
  },
  {
    type: 'image',
    url: 'https://picsum.photos/600',
    height: 64,
    width: 64,
    align: 'right',
  },
  {
    type: 'image',
    url: 'https://picsum.photos/600',
    height: 64,
    width: 64,
    rounded: true,
  },
];

export const SpacerDom = [
  {
    type: 'text',
    text: 'Size as *xs* is below.',
  },
  {
    type: 'spacer',
    size: 'xs',
  },
  {
    type: 'text',
    text: 'Size as *s* is below.',
  },
  {
    type: 'spacer',
    size: 's',
  },
  {
    type: 'text',
    text: 'Size as *m* is below.',
  },
  {
    type: 'spacer',
    size: 'm',
  },
  {
    type: 'text',
    text: 'Size as *l* is below.',
  },
  {
    type: 'spacer',
    size: 'l',
  },
  {
    type: 'text',
    text: 'Size as *xl* is below.',
  },
  {
    type: 'spacer',
    size: 'xl',
  },
  {
    type: 'text',
    text: 'This is the last component.',
  },
];

export const DividerDom = [
  {
    type: 'text',
    text: 'The divider below has a bottom-margin.',
    id: 'divider-1',
  },
  {
    type: 'divider',
  },
  {
    type: 'text',
    text: 'The divider below does not have a bottom-margin.',
  },
  {
    type: 'divider',
    bottom_margin: 'none',
  },
  {
    type: 'text',
    text: 'This is the last component.',
  },
];

export const ButtonDom = [
  {
    type: 'button',
    id: 'primary-1',
    label: 'Primary',
    style: 'primary',
    action: {
      type: 'submit',
    },
  },
  {
    type: 'button',
    id: 'primary-2',
    label: 'Primary (Disabled)',
    style: 'primary',
    disabled: true,
    action: {
      type: 'submit',
    },
  },
  {
    type: 'button',
    id: 'secondary-1',
    label: 'Secondary',
    style: 'secondary',
    action: {
      type: 'submit',
    },
  },
  {
    type: 'button',
    id: 'secondary-2',
    label: 'Secondary (Disabled)',
    style: 'secondary',
    disabled: true,
    action: {
      type: 'submit',
    },
  },
  {
    type: 'button',
    id: 'link-1',
    label: 'Link',
    style: 'link',
    action: {
      type: 'submit',
    },
  },
  {
    type: 'button',
    id: 'link-2',
    label: 'Link (Disabled)',
    style: 'link',
    disabled: true,
    action: {
      type: 'submit',
    },
  },
  {
    type: 'spacer',
    size: 's',
  },
  {
    type: 'divider',
  },
  {
    type: 'spacer',
    size: 's',
  },
  {
    type: 'button',
    id: 'inbox-url-action',
    label: 'See Inbox for URL Action',
    style: 'primary',
    action: {
      type: 'url',
      url: 'https://developers.getgist.com',
    },
  },
];

export const InputDom = [
  {
    type: 'input',
    id: 'unsaved-1',
    label: 'Unsaved',
    placeholder: 'Enter input here...',
    save_state: 'unsaved',
  },
  {
    type: 'input',
    id: 'unsaved-2',
    label: 'Unsaved (Action)',
    placeholder: 'Enter input here...',
    save_state: 'unsaved',
    action: {
      type: 'submit',
    },
  },
  {
    type: 'input',
    id: 'unsaved-3',
    label: 'Unsaved (Disabled)',
    placeholder: 'Enter input here...',
    save_state: 'unsaved',
    disabled: true,
    action: {
      type: 'submit',
    },
  },
  {
    type: 'input',
    id: 'failed-1',
    label: 'Failed',
    placeholder: 'Enter input here...',
    value: 'Value is given in JSON',
    save_state: 'failed',
  },
  {
    type: 'input',
    id: 'failed-2',
    label: 'Failed (Action)',
    placeholder: 'Enter input here...',
    value: 'Value is given in JSON',
    save_state: 'failed',
    action: {
      type: 'submit',
    },
  },
  {
    type: 'input',
    id: 'failed-3',
    label: 'Failed (Disabled)',
    placeholder: 'Enter input here...',
    value: 'Value is given in JSON',
    save_state: 'failed',
    disabled: true,
    action: {
      type: 'submit',
    },
  },
  {
    type: 'input',
    id: 'saved-1',
    label: 'Saved',
    placeholder: 'Enter input here...',
    value: 'Value is given in JSON',
    save_state: 'saved',
  },
];

export const TextAreaDom = [
  {
    type: 'textarea',
    id: 'textarea-1',
    label: 'Normal',
    placeholder: 'Enter text here...',
  },
  {
    type: 'textarea',
    id: 'textarea-2',
    label: 'With Value',
    placeholder: 'Enter text here...',
    value: 'Value entered in JSON.',
  },
  {
    type: 'textarea',
    id: 'textarea-3',
    label: 'Error',
    placeholder: 'Enter text here...',
    value: 'Value entered in JSON.',
    error: true,
  },
  {
    type: 'textarea',
    id: 'textarea-4',
    label: 'Disabled',
    placeholder: 'Unable to enter text',
    disabled: true,
  },
];

export const ListDom = [
  {
    type: 'list',
    disabled: false,
    items: [
      {
        type: 'item',
        id: 'list-item-1',
        title: 'Item 1',
      },
      {
        type: 'item',
        id: 'list-item-2',
        title: 'Item 2',
        subtitle: 'With Subtitle',
      },
      {
        type: 'item',
        id: 'list-item-3',
        title: 'Item 3',
        subtitle: 'With Subtitle',
        tertiary_text: 'With Tertiary Text',
      },
      {
        type: 'item',
        id: 'list-item-4',
        title: 'Item 4',
        subtitle: 'With Action',
        action: {
          type: 'url',
          url: 'https://getgist.com',
        },
      },
      {
        type: 'item',
        id: 'list-item-5',
        title: 'Item 5',
        subtitle: 'With Action',
        tertiary_text: 'Disabled',
        disabled: true,
        action: {
          type: 'submit',
        },
      },
      {
        type: 'item',
        id: 'list-item-6',
        title: 'Item 6',
        subtitle: 'With Image',
        image: 'https://picsum.photos/600',
        image_width: 48,
        image_height: 48,
      },
      {
        type: 'item',
        id: 'list-item-7',
        title: 'Item 7',
        subtitle: 'With Image',
        tertiary_text: 'With Action',
        image: 'https://picsum.photos/600',
        image_width: 48,
        image_height: 48,
        action: {
          type: 'submit',
        },
      },
      {
        type: 'item',
        id: 'list-item-8',
        title: 'Item 8',
        subtitle: 'With Rounded Image',
        image: 'https://picsum.photos/600',
        image_width: 48,
        image_height: 48,
        rounded_image: true,
      },
      {
        type: 'item',
        id: 'list-item9',
        title: 'Item 9',
        subtitle: 'With Rounded Image',
        tertiary_text: 'With Action',
        image: 'https://picsum.photos/600',
        image_width: 48,
        image_height: 48,
        rounded_image: true,
        action: {
          type: 'submit',
        },
      },
      {
        type: 'item',
        id: 'list-item-10',
        title: 'Item 10',
        subtitle: 'With Image & Action',
        tertiary_text: 'Disabled',
        image: 'https://picsum.photos/600',
        image_width: 48,
        image_height: 48,
        rounded_image: true,
        disabled: true,
        action: {
          type: 'submit',
        },
      },
    ],
  },
];

export const SingleSelectDom = [
  {
    type: 'single-select',
    id: 'single-select-1',
    label: 'Unsaved Options',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'single-select',
    id: 'single-select-2',
    label: 'Pre-selected Option',
    value: 'option-1',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'single-select',
    id: 'single-select-3',
    label: 'Saved Options',
    save_state: 'saved',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'single-select',
    id: 'single-select-4',
    label: 'Failed Options',
    save_state: 'failed',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'single-select',
    id: 'single-select-5',
    label: 'Disabled Options',
    disabled: true,
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'single-select',
    id: 'single-select-6',
    label: 'Disabled Option',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Disabled',
        disabled: true,
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
];

export const CheckboxDom = [
  {
    type: 'checkbox',
    id: 'checkbox-1',
    label: 'Unsaved Options',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'checkbox',
    id: 'checkbox-2',
    label: 'Pre-selected Option(s)',
    value: ['pre-option-1', 'pre-option-2'],
    options: [
      {
        type: 'option',
        id: 'pre-option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'pre-option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'pre-option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'checkbox',
    id: 'checkbox-3',
    label: 'Saved Options',
    save_state: 'saved',
    options: [
      {
        type: 'option',
        id: 'saved-option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'saved-option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'saved-option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'checkbox',
    id: 'checkbox-4',
    label: 'Failed Options',
    save_state: 'failed',
    options: [
      {
        type: 'option',
        id: 'failed-option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'failed-option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'failed-option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'checkbox',
    id: 'checkbox-5',
    label: 'Disabled Options',
    disabled: true,
    options: [
      {
        type: 'option',
        id: 'all-dis-option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'all-dis-option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'all-dis-option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'checkbox',
    id: 'checkbox-6',
    label: 'Disabled Option',
    options: [
      {
        type: 'option',
        id: 'dis-option-1',
        text: 'Option 1',
        disabled: true,
      },
      {
        type: 'option',
        id: 'dis-option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'dis-option-3',
        text: 'Option 3',
      },
    ],
  },
];

export const DropDownDom = [
  {
    type: 'dropdown',
    id: 'dropdown-1',
    label: 'Unsaved Options',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'dropdown',
    id: 'dropdown-2',
    label: 'Pre-selected Option',
    value: 'option-2',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'dropdown',
    id: 'dropdown-3',
    label: 'Saved Options',
    save_state: 'saved',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'dropdown',
    id: 'dropdown-4',
    label: 'Failed Options',
    save_state: 'failed',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'dropdown',
    id: 'dropdown-5',
    label: 'Disabled Options',
    disabled: true,
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Option 1',
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
  {
    type: 'dropdown',
    id: 'dropdown-6',
    label: 'Disabled Option',
    options: [
      {
        type: 'option',
        id: 'option-1',
        text: 'Disabled',
        disabled: true,
      },
      {
        type: 'option',
        id: 'option-2',
        text: 'Option 2',
      },
      {
        type: 'option',
        id: 'option-3',
        text: 'Option 3',
      },
    ],
  },
];

export const defaultResponseObject = {
  canvas: {
    content: {
      components: [
        ...TextDom,
        ...DataTableDom,
        ...ImageDom,
        ...SpacerDom,
        ...DividerDom,
        ...ButtonDom,
        ...InputDom,
        ...TextAreaDom,
        ...ListDom,
        ...SingleSelectDom,
        ...CheckboxDom,
        ...DropDownDom,
      ],
    },
    stored_data: { key: 'value' }, // Can be more than one pair
  },
};
