import React, { Fragment } from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class SimpleMessage extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className="header">
          <h3>
            <span>
              <i className="fas fa-comment-lines" />
            </span>
            Simple message
          </h3>
        </div>
        <div className="body">
          {data.messages &&
            data.messages.map((item, key) => (
              <Fragment key={item.id}>
                {data.messages.length - 1 === key ? (
                  <div>
                    <div
                      className="message-text-component"
                      data-port-id={item.id}
                    >
                      <jtk-source port-id={item.id} endpoint={true} />
                      <InputTextArea id={item.id} value={item.text} />
                    </div>
                  </div>
                ) : (
                  <div className="message-text-component" key={item.id}>
                    <InputTextArea id={item.id} value={item.text} />
                  </div>
                )}
              </Fragment>
            ))}
        </div>
      </div>
    );
  }
}
