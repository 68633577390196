const actions = {
  FETCH_TABLE_LISTS_IN_SURVEY: 'FETCH_TABLE_LISTS_IN_SURVEY',
  APPEND_TABLE_LISTS_IN_SURVEY: 'APPEND_TABLE_LISTS_IN_SURVEY',
  CREATE_SURVEY_DRAWER: 'CREATE_SURVEY_DRAWER',
  SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES:
    'SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES',
  SET_CREATED_MESSAGE_TAG: 'SET_CREATED_MESSAGE_TAG',
  APPLY_SURVEY_TAG: 'APPLY_SURVEY_TAG',
  REMOVE_SURVEY_TAG: 'REMOVE_SURVEY_TAG',
  SET_CONFIRM_MODEL_IN_SURVEY: 'SET_CONFIRM_MODEL_IN_SURVEY',
  SET_CHAT_TAB_KEY_IN_SURVEY: 'SET_CHAT_TAB_KEY_IN_SURVEY',
  UPDATE_TABLE_ELEMENT_STATUS_IN_SURVEY:
    'UPDATE_TABLE_ELEMENT_STATUS_IN_SURVEY',
  UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_SURVEY:
    'UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_SURVEY',
  SURVEY_SAVE_BUTTON_LOADER_STATUS: 'SURVEY_SAVE_BUTTON_LOADER_STATUS',
  CREATE_SURVEY: 'CREATE_SURVEY',
  CREATE_SURVEY_SUCCESS: 'CREATE_SURVEY_SUCCESS',
  CREATE_SURVEY_FAILURE: 'CREATE_SURVEY_FAILURE',
  DUPLICATE_SURVEY: 'DUPLICATE_SURVEY',
  DUPLICATE_SURVEY_SUCCESS: 'DUPLICATE_SURVEY_SUCCESS',
  DUPLICATE_SURVEY_FAILURE: 'DUPLICATE_SURVEY_FAILURE',
  DELETE_TABLE_DATA_IN_SURVEY: 'DELETE_TABLE_DATA_IN_SURVEY',
  SET_SURVEY_TAB_KEY: 'SET_SURVEY_TAB_KEY',
  UPDATE_LOCAL_SURVEY_DATA: 'UPDATE_LOCAL_SURVEY_DATA',
  DELETE_LOCAL_SINGLE_STEP_DATA: 'DELETE_LOCAL_SINGLE_STEP_DATA',
  INSERT_SINGLE_QUESTION_DATA: 'INSERT_SINGLE_QUESTION_DATA',
  UPDATE_LOCAL_SINGLE_QUESTION_DATA: 'UPDATE_LOCAL_SINGLE_QUESTION_DATA',
  UPDATE_LOCAL_SINGLE_STEP_DATA: 'UPDATE_LOCAL_SINGLE_STEP_DATA',
  UPDATE_QUESTIONS_LIST_ORDER: 'UPDATE_QUESTIONS_LIST_ORDER',
  PUSH_SURVEY_DATA: 'PUSH_SURVEY_DATA',
  FETCH_GOOGLE_FONTS: 'FETCH_GOOGLE_FONTS',
  FETCH_GOOGLE_FONTS_SUCCESS: 'FETCH_GOOGLE_FONTS_SUCCESS',
  FETCH_SURVEY_DETAILS: 'FETCH_SURVEY_DETAILS',
  FETCH_SURVEY_DETAILS_SUCCESS: 'FETCH_SURVEY_DETAILS_SUCCESS',
  FETCH_SURVEY_DETAILS_FAILURE: 'FETCH_SURVEY_DETAILS_FAILURE',
  UPDATE_SURVEY_SAVED_DETAILS: 'UPDATE_SURVEY_SAVED_DETAILS',
  RESET_SURVEY_DETAILS: 'RESET_SURVEY_DETAILS',
  SET_SURVEY_DEFAULT_TEMPLATE_DETAILS: 'SET_SURVEY_DEFAULT_TEMPLATE_DETAILS',

  //Themes
  FETCH_SURVEY_CUSTOM_THEMES: 'FETCH_SURVEY_CUSTOM_THEMES',
  FETCH_SURVEY_CUSTOM_THEMES_SUCCESS: 'FETCH_SURVEY_CUSTOM_THEMES_SUCCESS',
  FETCH_SURVEY_CUSTOM_THEMES_FAILURE: 'FETCH_SURVEY_CUSTOM_THEMES_FAILURE',
  CREATE_SURVEY_CUSTOM_THEMES: 'CREATE_SURVEY_CUSTOM_THEMES',
  CREATE_SURVEY_CUSTOM_THEMES_SUCCESS: 'CREATE_SURVEY_CUSTOM_THEMES_SUCCESS',
  CREATE_SURVEY_CUSTOM_THEMES_FAILURE: 'CREATE_SURVEY_CUSTOM_THEMES_FAILURE',
  UPDATE_SURVEY_CUSTOM_THEMES: 'UPDATE_SURVEY_CUSTOM_THEMES',
  UPDATE_SURVEY_CUSTOM_THEMES_SUCCESS: 'UPDATE_SURVEY_CUSTOM_THEMES_SUCCESS',
  UPDATE_SURVEY_CUSTOM_THEMES_FAILURE: 'UPDATE_SURVEY_CUSTOM_THEMES_FAILURE',
  DELETE_SURVEY_CUSTOM_THEMES: 'DELETE_SURVEY_CUSTOM_THEMES',
  DELETE_SURVEY_CUSTOM_THEMES_SUCCESS: 'DELETE_SURVEY_CUSTOM_THEMES_SUCCESS',
  DELETE_SURVEY_CUSTOM_THEMES_FAILURE: 'DELETE_SURVEY_CUSTOM_THEMES_FAILURE',
  SET_LOCAL_SURVEY_THEME: 'SET_LOCAL_SURVEY_THEME',
  SHOW_SURVEY_THEME_EDITOR_STATUS: 'SHOW_SURVEY_THEME_EDITOR_STATUS',

  //Logic
  ADD_NEW_STEP_LOGICS: 'ADD_NEW_STEP_LOGICS',
  ADD_SURVEY_LOGIC_RULE: 'ADD_SURVEY_LOGIC_RULE',
  REMOVE_SURVEY_LOGIC_RULE: 'REMOVE_SURVEY_LOGIC_RULE',
  UPDATE_SURVEY_LOGIC_RULE: 'UPDATE_SURVEY_LOGIC_RULE',
  ADD_LOCAL_SINGLE_STEP_LOGIC: 'ADD_LOCAL_SINGLE_STEP_LOGIC',
  UPDATE_LOCAL_SINGLE_STEP_LOGIC: 'UPDATE_LOCAL_SINGLE_STEP_LOGIC',
  REMOVE_LOCAL_SINGLE_STEP_LOGIC: 'REMOVE_LOCAL_SINGLE_STEP_LOGIC',
  UPDATE_DEFAULT_SINGLE_STEP_LOGIC: 'UPDATE_DEFAULT_SINGLE_STEP_LOGIC',
  REMOVE_DELETED_QUESTION_LOGIC: 'REMOVE_DELETED_QUESTION_LOGIC',

  //Map Variables
  ADD_SURVEY_MAP_VARIABLE: 'ADD_SURVEY_MAP_VARIABLE',
  REMOVE_SURVEY_MAP_VARIABLE: 'REMOVE_SURVEY_MAP_VARIABLE',
  UPDATE_SURVEY_MAP_VARIABLE_DATA: 'UPDATE_SURVEY_MAP_VARIABLE_DATA',

  //Reports
  FETCH_SURVEY_ONGOING_REPORTS: 'FETCH_SURVEY_ONGOING_REPORTS',
  FETCH_SURVEY_ONGOING_REPORTS_SUCCESS: 'FETCH_SURVEY_ONGOING_REPORTS_SUCCESS',
  FETCH_SURVEY_ONGOING_REPORTS_FAILURE: 'FETCH_SURVEY_ONGOING_REPORTS_FAILURE',
  APPEND_SURVEY_ONGOING_REPORTS_SUCCESS:
    'APPEND_SURVEY_ONGOING_REPORTS_SUCCESS',
  EXPORT_SURVEY_CSV_MODAL: 'EXPORT_SURVEY_CSV_MODAL',
  EXPORT_SURVEY_RESPONSE_CSV: 'EXPORT_SURVEY_RESPONSE_CSV',
  EXPORT_SURVEY_CSV_LOADER: 'EXPORT_SURVEY_CSV_LOADER',
};

export default actions;
