import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../store';
import settings from '../../../settings';
import filterActions from '../../filters/actions';
import { filterVariables } from '../../constants';
import displayActions from '../../displayRules/actions';
import assistantActions from '../../assistants/actions';
import {
  constructBuilderData,
  loadBuilderData,
} from '../../../containers/Messages/Bots/helper';

export function* fetchChatbotTemplateList(params) {
  try {
    let status = '',
      botType = '',
      filterCategoryId = '';
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().ChatbotTemplateReducer.selectedTemplateStatus;
    }
    if (params.botType) {
      botType = params.botType;
    } else {
      botType = store.getState().Assistants.assistantType;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates?status=${status}&bot_type=${botType}`;
    if (params.fromTemplateList) {
      filterCategoryId = store.getState().ChatbotTemplateReducer
        .filterCategoryId;
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/assistants/bot_templates?status=published&category_id=${filterCategoryId}&bot_type=${botType}&use_template_page=true`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CHATBOT_TEMPLATE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_CHATBOT_TEMPLATE_LIST_FAILURE,
    });
    message.error('Fetch chatbot template list failed');
  }
}

export function* getCategoryList(params) {
  try {
    const { isTemplatePage } = params;
    let botType = store.getState().Assistants.assistantType;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates/categories?use_template_page=${isTemplatePage}&bot_type=${botType}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CHATBOT_CATEGORIES_LISTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch chatbot category list failed');
  }
}

export function* createChatbotTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_CHATBOT_TEMPLATE_ID,
      id: data.data.bot_template.id,
    });
    message.success(data.message);
    updateBrowserHistory(
      `assistants/bot-templates/${data.data.bot_template.id}`,
    );
    yield put({
      type: actions.CREATE_CHATBOT_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_CHATBOT_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* deleteChatbotTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_CHATBOT_TEMPLATE_LIST,
      status: store.getState().ChatbotTemplateReducer.selectedTemplateStatus,
    });
    yield put({
      type: actions.DELETE_CHATBOT_TEMPLATE_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_CHATBOT_TEMPLATE_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchChatbotTemplateDetails(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates/${
      params.botTemplateId
    }`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: assistantActions.FETCH_ASSISTANT_BOT_DETAILS_SUCCESS,
      payload: data.bot_template,
    });
    yield put({
      type: actions.SET_SETTINGS_CHATBOT_TEMPLATE_DETAILS,
      name: data.bot_template.name,
    });
    if (data.bot_template.bot.bot_type === 'support_bot') {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.bot_template.bot.display_conditions,
        filterName: filterVariables.supportBotKey,
      });
    } else {
      yield put({
        type: displayActions.SET_DISPLAY_RULES,
        payload: data.bot_template.bot.display_conditions,
      });
    }
    loadBuilderData(constructBuilderData(data.bot_template.bot));
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* updateChatbotTemplate(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_CHATBOT_TEMPLATE_SUCCESS,
      payload: data.data.bot_template,
      isPublishAction: params.isPublishAction,
    });
    yield put({
      type: assistantActions.UPDATE_BOT_DETAIL,
      payload: data.data.bot_template,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_CHATBOT_TEMPLATE_FAILURE,
      changeStatus: params.changeStatus,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Chatbot template update failed');
    }
  }
}

export function* duplicateChatbotTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/assistants/bot_templates/${
      params.id
    }/duplicate`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_CHATBOT_TEMPLATE_ID,
      id: data.data.bot_template.id,
    });
    message.success(data.message);
    updateBrowserHistory(
      `assistants/bot-templates/${data.data.bot_template.id}`,
    );
    yield put({
      type: actions.DUPLICATE_CHATBOT_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_CHATBOT_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CHATBOT_TEMPLATE_LIST, fetchChatbotTemplateList),
    takeEvery(actions.GET_CHATBOT_CATEGORIES_LISTS, getCategoryList),
    takeEvery(actions.CREATE_CHATBOT_TEMPLATE, createChatbotTemplate),
    takeEvery(actions.DELETE_CHATBOT_TEMPLATE, deleteChatbotTemplate),
    takeEvery(
      actions.FETCH_CHATBOT_TEMPLATE_DETAILS,
      fetchChatbotTemplateDetails,
    ),
    takeEvery(actions.UPDATE_CHATBOT_TEMPLATE, updateChatbotTemplate),
    takeEvery(actions.DUPLICATE_CHATBOT_TEMPLATE, duplicateChatbotTemplate),
  ]);
}
