import actions from './actions';

let initState = {
  appsList: {},
  pageLoader: false,
  fbPageDetail: null,
  isOpenFbPageDetailDrawer: false,
  stripeCallback: false,
  zoomCallback: false,
  zoomErrorMessage: null,
  twitterCallback: false,
  wooCommerce: {},
  wooCommerceInterval: [],
  wooCommerceModalVisible: false,
  integrationLoading: {
    ga_integrated: false,
    fb_integration: false,
    twitter: false,
    shopify_integrations: false,
    sync_woo_commerce: {},
  },
  showModal: false,
  showDeveloperAppModal: false,
  installSuccessData: null,
  loader: false,
  configureCanvasData: {},
  configurationAppLoader: [],
  configurationCanvasLoading: {},
  shopifyLists: [],
  shopifyListLoader: false,
  shopifyConnectModalVisibility: false,
  shopifyStoreId: '',
  shopifySettingsData: {},
  shopifySettingsLoader: false,
  wooCommerceLists: [],
  wooCommerceListLoader: false,
  configureAppDataLoader: false,
};

export default function appPlatformReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_PAGE_LOADER:
      return {
        ...state,
        pageLoader: action.pageLoader,
      };
    case actions.GET_APPS_LIST_SUCCESS: {
      const { project } = action.payload;
      return {
        ...state,
        appsList: {
          ...state.appsList,
          ...project,
        },
        pageLoader: false,
        syncProgressStatus: project.fb_ad_integration
          ? project.fb_ad_integration.sync_inprogress
          : state.syncProgressStatus,
        integrationLoading: {
          ...state.integrationLoading,
          sync_woo_commerce: {
            ...state.integrationLoading.sync_woo_commerce,
            ...(project.woocommerce_integrations &&
              project.woocommerce_integrations.reduce((acc, data) => {
                if (data.store_id) {
                  return { ...acc, [data.store_id]: data.sync_status };
                }
                return acc;
              }, {})),
          },
        },
      };
    }
    case actions.UPDATE_APPS_STATUS:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    case actions.UPDATE_APPS_STATUS_SUCCESS:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          ...action.payload,
        },
        showModal: false,
      };
    case actions.UPDATE_APPS_STATUS_FAILURE:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };

    // Facebook Page
    case actions.DISCONNECT_FB: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.DISCONNECT_FB_SUCCESS: {
      let fbData = { fb_integration: null };
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          ...fbData,
        },
        showModal: false,
      };
    }
    case actions.DISCONNECT_FB_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.CONNECT_FB: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.CONNECT_FB_SUCCESS: {
      return {
        ...state,
        fbPageDetail: action.payload.pages,
        isOpenFbPageDetailDrawer: true,
        showModal: false,
      };
    }
    case actions.CONNECT_FB_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.CONNECT_FB_PAGE_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          fb_integration: action.payload,
        },
        isOpenFbPageDetailDrawer: false,
        loader: false,
      };
    }
    case actions.CONNECT_FB_PAGE_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        loader: false,
      };
    }
    case actions.FB_PAGE_MODAL: {
      return {
        ...state,
        isOpenFbPageDetailDrawer: action.payload,
      };
    }
    case actions.SET_PROFILE_IMAGE_URL:
      return {
        ...state,
        usersProfile: {
          ...state.usersProfile,
          profile_photo_url: action.imageUrl
            ? action.imageUrl
            : state.usersProfile.profile_photo_url,
        },
      };
    case actions.UPDATE_FB_BUTTON_LOADER: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }

    // Facebook Custom Audience
    case actions.CONNECT_FB_AUDIENCE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.CONNECT_FB_AUDIENCE_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          fb_ad_integration: action.payload,
        },
        isOpenFbPageDetailDrawer: false,
        loader: false,
        showModal: false,
      };
    }
    case actions.CONNECT_FB_AUDIENCE_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        loader: false,
      };
    }
    case actions.DISCONNECT_FB_AUDIENCE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.DISCONNECT_FB_AUDIENCE_SUCCESS: {
      let fbData = { fb_ad_integration: null };
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          ...fbData,
        },
        syncProgressStatus: false,
        showModal: false,
      };
    }
    case actions.DISCONNECT_FB_AUDIENCE_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.SYNC_FB_CUSTOM_AUDIENCE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.SYNC_FB_CUSTOM_AUDIENCE_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.SYNC_FB_CUSTOM_AUDIENCE_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }

    // Twitter
    case actions.DISCONNECT_TWITTER: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.DISCONNECT_TWITTER_SUCCESS: {
      let twitterData = { twitter: null };
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          ...twitterData,
        },
        showModal: false,
      };
    }
    case actions.DISCONNECT_TWITTER_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.VERIFY_TWITTER_CONNECT: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.VERIFY_TWITTER_CONNECT_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          twitter: action.payload,
        },
        showModal: false,
      };
    }
    case actions.VERIFY_TWITTER_CONNECT_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.CONNECT_TWITTER_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        pageLoader: false,
        twitterCallback: true,
      };
    }
    case actions.CONNECT_TWITTER_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        pageLoader: false,
        twitterCallback: false,
      };
    }

    // Zoom
    case actions.CONNECT_ZOOM_SUCCESS: {
      return {
        ...state,
        pageLoader: false,
        zoomCallback: true,
        zoomErrorMessage: null,
      };
    }
    case actions.CONNECT_ZOOM_FAILURE: {
      return {
        ...state,
        pageLoader: false,
        zoomCallback: false,
        zoomErrorMessage: action.payload,
      };
    }
    case actions.DISCONNECT_ZOOM: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.DISCONNECT_ZOOM_SUCCESS: {
      let zoomData = { zoom_integration: null };
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          ...zoomData,
        },
        showModal: false,
      };
    }
    case actions.DISCONNECT_ZOOM_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.VERIFY_ZOOM_CONNECT: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.VERIFY_ZOOM_CONNECT_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          zoom_integration: action.payload,
        },
        showModal: false,
      };
    }
    case actions.VERIFY_ZOOM_CONNECT_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }

    // Stripe
    case actions.DISCONNECT_STRIPE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.DISCONNECT_STRIPE_SUCCESS: {
      let stripeData = { stripe_integration: null };
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          ...stripeData,
        },
        showModal: false,
      };
    }
    case actions.DISCONNECT_STRIPE_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.VERIFY_STRIPE_CONNECT: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.VERIFY_STRIPE_CONNECT_SUCCESS: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
        appsList: {
          ...state.appsList,
          stripe_integration: action.payload,
        },
        showModal: false,
      };
    }
    case actions.VERIFY_STRIPE_CONNECT_FAILURE: {
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    }
    case actions.CONNECT_STRIPE_SUCCESS: {
      return {
        ...state,
        pageLoader: false,
        stripeCallback: true,
      };
    }
    case actions.CONNECT_STRIPE_FAILURE: {
      return {
        ...state,
        pageLoader: false,
        stripeCallback: false,
      };
    }

    // WooCommerce
    case actions.DISCONNECT_WOO_COMMERCE_SUCCESS:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    case actions.SET_WOO_COMMERCE_INTERVAL:
      return {
        ...state,
        wooCommerceInterval: [...state.wooCommerceInterval, action.interval],
      };
    case actions.RESET_WOO_COMMERCE_INTERVAL:
      return {
        ...state,
        wooCommerceInterval: [],
      };
    case actions.WOO_COMMERCE_MODAL_VISIBLE:
      return {
        ...state,
        wooCommerceModalVisible: action.payload,
      };
    case actions.SYNC_WOO_COMMERCE_SUCCESS: {
      const { store_id, ...rest } = action.payload;
      return {
        ...state,
        wooCommerceLists: state.wooCommerceLists.map(
          data => (data.store_id === store_id ? { ...data, ...rest } : data),
        ),
      };
    }
    case actions.SYNC_WOO_COMMERCE:
    case actions.SYNC_WOO_COMMERCE_FAILURE:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          sync_woo_commerce: {
            ...state.integrationLoading.sync_woo_commerce,
            ...action.loading,
          },
        },
      };
    case actions.CHANGE_MODAL_VISIBILTY:
      return {
        ...state,
        showModal: action.payload,
      };
    case actions.CHANGE_DEVELOPER_APP_MODAL_VISIBILITY:
      return {
        ...state,
        showDeveloperAppModal: action.payload,
        installedStateLoading: false,
      };
    case actions.INSTALL_SUCCESS_COMPLETE:
      return {
        ...state,
        installSuccessData: action.payload,
      };
    case actions.SET_LOADER:
      return {
        ...state,
        loader: action.loader,
      };
    case actions.CONFIGURE_APP_DATA: {
      const { location } = action;
      return {
        ...state,
        configurationAppLoader: [...state.configurationAppLoader, location],
      };
    }
    case actions.CONFIGURE_APP_DATA_SUCCESS: {
      const { location, data } = action.payload;
      return {
        ...state,
        configureCanvasData: {
          ...state.configureCanvasData,
          [location]: data,
        },
        configurationCanvasLoading: {
          ...state.configureCanvasData,
          [location]: {},
        },
        configurationAppLoader: state.configurationAppLoader.filter(
          data => data !== location,
        ),
      };
    }
    case actions.CONFIGURE_APP_DATA_FAILURE: {
      const { location } = action.payload;

      return {
        ...state,
        configureCanvasData: {
          ...state.configureCanvasData,
          [location]: {},
        },
        configurationCanvasLoading: {
          ...state.configureCanvasData,
          [location]: {},
        },
        configurationAppLoader: state.configurationAppLoader.filter(
          data => data !== location,
        ),
      };
    }
    case actions.CONFIGURE_APP_SUBMIT: {
      const {
        location,
        payload: { component_id },
      } = action;
      return {
        ...state,
        configurationCanvasLoading: {
          ...state.configurationCanvasLoading,
          [location]: {
            ...state.configurationCanvasLoading[location],
            [component_id]: true,
          },
        },
      };
    }
    case actions.GET_SHOPIFY_LISTS:
      return {
        ...state,
        shopifyListLoader: true,
      };
    case actions.GET_SHOPIFY_LISTS_SUCCESS:
      return {
        ...state,
        shopifyLists: action.payload.data,
        shopifyListLoader: false,
      };
    case actions.GET_SHOPIFY_LISTS_FAILURE:
      return {
        ...state,
        shopifyListLoader: false,
      };
    case actions.SHOPIFY_SETTINGS_SELLECTED_STORE_ID:
      let getShopifyData = state.shopifyLists.find(
        data => data.store_id === action.shopifyStoreId,
      );
      return {
        ...state,
        shopifyStoreId: action.shopifyStoreId,
        shopifySettingsData: getShopifyData || {},
      };
    case actions.SHOPIFY_CONNECT_MODAL:
      return {
        ...state,
        shopifyConnectModalVisibility: action.payload,
      };
    case actions.CONNECT_SHOPIFY:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          shopify_integrations: action.loading,
        },
      };
    case actions.CONNECT_SHOPIFY_SUCCESS:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          shopify_integrations: action.loading,
        },
      };
    case actions.CONNECT_SHOPIFY_FAILURE:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          shopify_integrations: action.loading,
        },
      };
    case actions.DISCONNECT_SHOPIFY_SUCCESS:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          ...action.loading,
        },
      };
    case actions.SYNC_SHOPIFY:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          sync_shopify: {
            ...state.integrationLoading.sync_shopify,
            ...action.loading,
          },
        },
      };
    case actions.SYNC_SHOPIFY_FAILURE:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          sync_shopify: {
            ...state.integrationLoading.sync_shopify,
            ...action.loading,
          },
        },
      };
    case actions.SAVE_SHOPIFY_SETTINGS:
      return {
        ...state,
        configureAppDataLoader: true,
      };
    case actions.SAVE_SHOPIFY_SETTINGS_SUCCESS:
      return {
        ...state,
        configureAppDataLoader: false,
      };
    case actions.SAVE_SHOPIFY_SETTINGS_FAILURE:
      return {
        ...state,
        configureAppDataLoader: false,
      };
    case actions.DISCONNECT_SHOPIFY:
      return {
        ...state,
        integrationLoading: {
          ...state.integrationLoading,
          shopify_integrations: action.loading,
        },
      };
    case actions.GET_WOOCOMMERCE_LISTS:
      return {
        ...state,
        wooCommerceListLoader: true,
      };
    case actions.GET_WOOCOMMERCE_LISTS_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        wooCommerceLists: data,
        integrationLoading: {
          ...state.integrationLoading,
          sync_woo_commerce: {
            ...state.integrationLoading.sync_woo_commerce,
            ...data.reduce(
              (acc, store) => ({ ...acc, [store.store_id]: store.sync_status }),
              {},
            ),
          },
        },
        wooCommerceListLoader: false,
      };
    case actions.GET_WOOCOMMERCE_LISTS_FAILURE:
      return {
        ...state,
        wooCommerceListLoader: false,
      };
    case actions.CONFIGURE_APP_DATA_LOADER:
      return {
        ...state,
        configureAppDataLoader: action.payload,
      };
    default:
      return state;
  }
}
