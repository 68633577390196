import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  deletePromise,
  extractCurrentPersonId,
  extractCurrentUserId,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  wooCommerceVerify,
} from '../../helpers/utility';
import { message } from 'antd';
import chatActions from '../chat/actions';
import { store } from '../store';
import appPlatformActions from '../appPlatform/actions';

export function* getAppsDetail(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    let queryString = '';
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/apps`;
    if (params && params.requiredIntegration) {
      queryString = `?required_integrations=${params.requiredIntegration}`;
    }
    url = url + queryString;
    const data = yield call(() => getPromise(url));
    if (queryString.includes('woocommerce')) {
      store.dispatch({
        type: actions.WOO_COMMERCE_MODAL_VISIBLE,
        payload: false,
      });
    }
    yield put({
      type: actions.GET_APPS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Get apps list failed');
    }
  }
}

export function* disconnectFacebook(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/destroy_fb_detail`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_FB_SUCCESS,
      loading: { fb_integration: false },
    });
    message.success(payload.message);
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.DISCONNECT_FB_FAILURE,
      loading: { fb_integration: false },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Facebook disconnected failed');
    }
  }
}

export function* connectFacebook(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/fb_callback?access_token=${
      params.payload
    }`;
    let payload = yield call(() => getPromise(url, params.payload));
    yield put({
      type: actions.CONNECT_FB_SUCCESS,
      payload: payload.data,
    });
    yield put({
      type: chatActions.CHANGE_CURRENT_CHANNEL_STEP,
      payload: { currentStep: 1 },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.CONNECT_FB_FAILURE,
      loading: { fb_integration: false },
    });
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* connectFacebookPage(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/update_fb_detail`;
    const payload = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.CONNECT_FB_PAGE_SUCCESS,
      payload: { active: true, page_name: params.payload.page_name },
      loading: { fb_integration: false },
    });
    if (params.redirect) {
      updateBrowserHistory('conversations');
    }
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    message.success(payload.message);
  } catch (error) {
    yield put({
      type: actions.CONNECT_FB_PAGE_FAILURE,
      loading: { fb_integration: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Facebook page connect failed');
    }
    unAuthorisedRedirection(error);
  }
}

export function* connectFacebookAudience(params) {
  try {
    const accessToken = { access_token: params.payload };
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/connect_fb_ad_accounts`;
    const payload = yield call(() => postPromise(url, accessToken));
    yield put({
      type: actions.CONNECT_FB_AUDIENCE_SUCCESS,
      payload: { active: true, account_name: payload.data.account_name },
      loading: { fb_ad_integration: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    message.success(payload.message);
  } catch (error) {
    yield put({
      type: actions.CONNECT_FB_AUDIENCE_FAILURE,
      loading: { fb_ad_integration: false },
    });
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* disconnectFacebookAudience(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/disconnect_fb_ad_accounts`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_FB_AUDIENCE_SUCCESS,
      loading: { fb_ad_integration: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    message.success(payload.message);
  } catch (error) {
    yield put({
      type: actions.DISCONNECT_FB_AUDIENCE_FAILURE,
      loading: { fb_integration: false },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Facebook disconnected failed');
    }
  }
}

export function* syncFacebookAudience(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/sync_fb_audience_list`;
    let payload = yield call(() => patchPromise(url));
    yield put({
      type: actions.SYNC_FB_CUSTOM_AUDIENCE_SUCCESS,
      loading: { sync_fb_ad_integration: false },
    });
    message.success(payload.message);
  } catch (error) {
    yield put({
      type: actions.SYNC_FB_CUSTOM_AUDIENCE_FAILURE,
      loading: { sync_fb_ad_integration: false },
    });
    unAuthorisedRedirection(error);
    if (error.response.data.data.sync_inprogress) {
      yield put({
        type: actions.SET_SYNC_PROGRESS,
        payload: error.response.data.data.sync_inprogress,
      });
    }
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Facebook disconnected failed');
    }
  }
}

export function* disconnectTwitter(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/twitter/twitter_delete`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_TWITTER_SUCCESS,
      loading: { twitter: false },
    });
    message.success(payload.message);
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.DISCONNECT_TWITTER_FAILURE,
      loading: { twitter: false },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Twitter disconnected failed');
    }
  }
}

export function* connectTwitter(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/twitter/twitter_callback?oauth_token=${
      params.payload.token
    }&oauth_verifier=${params.payload.verifier}`;
    yield call(() => getPromise(url));
    yield put({
      type: actions.CONNECT_TWITTER_SUCCESS,
      loading: { twitter: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.CONNECT_TWITTER_FAILURE,
      loading: { twitter: false },
    });
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* verifyTwitter(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/twitter/verify_twitter`;
    const payload = yield call(() => getPromise(url));
    yield put({
      type: actions.VERIFY_TWITTER_CONNECT_SUCCESS,
      payload: payload.data,
      loading: { twitter: false },
    });
    if (payload.data && payload.data.active) {
      message.success('Twitter connected successfully');
      if (params.redirect) {
        updateBrowserHistory('conversations');
      }
    } else {
      message.error('Twitter connect failed');
    }
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.VERIFY_TWITTER_CONNECT_FAILURE,
      loading: { twitter: false },
    });
    message.error('Twitter connect failed');
    unAuthorisedRedirection(error);
  }
}

export function* disconnectWooCommerce(params) {
  try {
    let queryString = '';
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/woocommerce/revoke_woocommerce_integration`;
    if (params.storeId) {
      queryString = `?store_id=${params.storeId}`;
    }
    url = url + queryString;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_WOO_COMMERCE_SUCCESS,
      loading: { woocommerce_integration: false },
    });
    yield put({
      type: actions.GET_WOOCOMMERCE_LISTS,
    });
    message.success(data.data);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_FB_BUTTON_LOADER,
      loading: { woocommerce_integration: false },
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Disconnect failed');
    }
  }
}

export function* syncWooCommerce(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/woocommerce/update_woocommerce_details`;
    yield call(() => patchPromise(url, params.payload));
    store.dispatch({
      type: actions.SYNC_WOO_COMMERCE_SUCCESS,
      payload: params.payload,
    });
    store.dispatch({
      type: actions.CONFIGURE_APP_DATA_LOADER,
      payload: false,
    });
    if (!params.loading) {
      message.success('Woo commerce settings updated');
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    store.dispatch({
      type: actions.SYNC_WOO_COMMERCE_FAILURE,
      loading: { [params.storeId]: 'failed' },
    });
    store.dispatch({
      type: actions.CONFIGURE_APP_DATA_LOADER,
      payload: false,
    });

    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Sync woo commerce failed');
    }
  }
}

export function* verifyWooCommerceDomain(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/woocommerce/verify_domain?url_details=${
      params.object.user_id
    }`;
    yield call(() => getPromise(url));
    const { object } = params;
    let wooCommerceWindow = window.open(
      `${
        object.user_id
      }/wc-auth/v1/authorize?app_name=Gist&scope=read_write&user_id=${
        object.user_id
      }&return_url=${object.return_url}&callback_url=${object.callback_url}`,
      'popUpWindow',
      'height=600,width=1024,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes',
    );
    const interval = setInterval(() => {
      wooCommerceVerify(wooCommerceWindow);
    }, 1000);
    yield put({
      type: actions.SET_WOO_COMMERCE_INTERVAL,
      interval,
    });
    yield put({
      type: actions.WOO_COMMERCE_MODAL_VISIBLE,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_FB_BUTTON_LOADER,
      loading: { woocommerce_integration: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Verify woo commerce domain failed');
    }
  }
}

export function* connectZoom(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${settings.ROOT_URL}projects/${
      params.payload.projectID
    }/settings/integration/zoom/callback?code=${params.payload.token}`;
    yield call(() => getPromise(url));
    yield put({
      type: actions.CONNECT_ZOOM_SUCCESS,
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    if (error.response && error.response.status === 422) {
      yield put({
        type: actions.CONNECT_ZOOM_FAILURE,
        payload: error.response.data.errors,
      });
    } else {
      yield put({
        type: actions.CONNECT_ZOOM_FAILURE,
        payload: null,
      });
    }
    unAuthorisedRedirection(error);
  }
}

export function* disconnectZoom(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/zoom/revoke_access_token`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_ZOOM_SUCCESS,
      loading: { zoom_integration: false },
    });
    message.success(payload.message);
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.DISCONNECT_ZOOM_FAILURE,
      loading: { zoom_integration: false },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Zoom disconnected failed');
    }
  }
}

export function* verifyZoom(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/zoom/verify`;
    const payload = yield call(() => getPromise(url));
    yield put({
      type: actions.VERIFY_ZOOM_CONNECT_SUCCESS,
      payload: payload.data,
      loading: { zoom_integration: false },
    });
    if (payload.data && payload.data.active) {
      message.success('Zoom connected successfully');
    } else {
      message.error('Zoom connect failed');
    }
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.VERIFY_ZOOM_CONNECT_FAILURE,
      loading: { zoom_integration: false },
    });
    message.error('Zoom connect failed');
    unAuthorisedRedirection(error);
  }
}

export function* disconnectStripe(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/disconnect_stripe`;
    let payload = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_STRIPE_SUCCESS,
      loading: { stripe_integration: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    message.success(payload.message);
  } catch (error) {
    yield put({
      type: actions.DISCONNECT_STRIPE_FAILURE,
      loading: { stripe_integration: false },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Stripe disconnected failed');
    }
  }
}

export function* connectStrip(params) {
  try {
    yield put({
      type: actions.SET_PAGE_LOADER,
      pageLoader: true,
    });
    const url = `${settings.ROOT_URL}projects/${
      params.payload.projectID
    }/settings/integration/stripe_callback?code=${params.payload.token}`;
    yield call(() => getPromise(url));
    yield put({
      type: actions.CONNECT_STRIPE_SUCCESS,
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.CONNECT_STRIPE_FAILURE,
    });
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* verifyStrip(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/verify_stripe`;
    const payload = yield call(() => getPromise(url));
    yield put({
      type: actions.VERIFY_STRIPE_CONNECT_SUCCESS,
      payload: payload.data,
      loading: { stripe_integration: false },
    });
    if (payload.data && payload.data.active) {
      message.success('Stripe connected successfully');
    } else {
      message.error('Stripe connect failed');
    }
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
  } catch (error) {
    yield put({
      type: actions.VERIFY_STRIPE_CONNECT_FAILURE,
      loading: { stripe_integration: false },
    });
    message.error('Stripe connect failed');
    unAuthorisedRedirection(error);
  }
}

export function* updateAppsStatus(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/apps`;
    let payload = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_APPS_STATUS_SUCCESS,
      payload: params.payload.project,
      loading: { ga_integrated: false },
    });
    yield put({
      type: appPlatformActions.FETCH_PUBLIC_APPS,
    });
    message.success(payload.message);
  } catch (error) {
    yield put({
      type: actions.UPDATE_APPS_STATUS_SUCCESS,
      payload: params.payload.project,
      loading: { ga_integrated: false },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update failed');
    }
  }
}
export function* installSuccess(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${
      params.workspaceId
    }/developer_apps/install_complete?client_id=${params.clientId}`;
    let data = yield call(() => getPromise(url));
    store.dispatch({
      type: actions.INSTALL_SUCCESS_COMPLETE,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors);
  }
}

export function* configureAppData(params) {
  const { app_id, isInbox } = params;
  try {
    const jsonData = {
      app_id,
      admin_id: extractCurrentUserId(),
      workspace_id: fetchProjectSecretKey(),
      context: {
        locale: 'en',
        location: isInbox ? 'inbox' : 'messenger',
        referrer: settings.REACT_DOMAIN_URL,
      },
    };

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${app_id}/post_configure`;
    const data = yield call(() => postPromise(url, jsonData));

    yield put({
      type: actions.CONFIGURE_APP_DATA_SUCCESS,
      payload: {
        data,
        location: isInbox ? 'inbox' : 'messenger',
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      yield put({
        type: actions.CONFIGURE_APP_DATA_FAILURE,
        payload: {
          isInbox,
        },
      });
    }
  }
}

export function* configureAppSubmit(params) {
  const { isInbox, payload } = params,
    { inbox_app_id, component_id, input_values, current_canvas } = payload;
  try {
    let jsonData = {
      app_id: inbox_app_id,
      component_id,
      input_values,
      current_canvas,
      admin_id: extractCurrentUserId(),
      workspace_id: fetchProjectSecretKey(),
      contact_id: extractCurrentPersonId(),
      context: {
        locale: 'en',
        location: isInbox ? 'inbox' : 'messenger',
        referrer: settings.REACT_DOMAIN_URL,
      },
    };

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${inbox_app_id}/post_submit`;
    const data = yield call(() => postPromise(url, jsonData));
    yield put({
      type: actions.CONFIGURE_APP_DATA_SUCCESS,
      payload: {
        data,
        location: isInbox ? 'inbox' : 'messenger',
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      yield put({
        type: actions.CONFIGURE_APP_DATA_FAILURE,
        payload: {
          inbox_app_id,
        },
      });

      message.error('Update App data failed');
    }
  }
}

export function* getShopifyLists() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/shopify/shops`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SHOPIFY_LISTS_SUCCESS,
      payload: data,
    });
    yield put({
      type: appPlatformActions.UPDATE_INSTALLED_STATE,
      payload: data.data.length > 0,
      key: 'shopify_integration',
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.GET_SHOPIFY_LISTS_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch shopify stores failed');
    }
  }
}

export function* connectShopify(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/shopify/validate_shop_name?shop_name=${
      params.shopName
    }`;
    let data = yield call(() => getPromise(url, params.shopName));
    yield put({
      type: actions.CONNECT_SHOPIFY_SUCCESS,
      loading: { default: false },
    });
    window.open(
      `${settings.SHOPIFY_URL}${params.shopName}&access_token=${
        data.data
      }${params.slug_name ? `&slug_name=${params.slug_name}`:''}`,
      '_self',
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CONNECT_SHOPIFY_FAILURE,
      loading: { default: false },
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Connect shopify failed');
    }
  }
}

export function* disconnectShopify(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/shopify/uninstall?store_id=${
      params.storeId
    }`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DISCONNECT_SHOPIFY_SUCCESS,
      loading: { shopify_integrations: false },
      storeId: params.storeId,
    });
    if (params.billingStore) {
      window.location.reload();
    }
    yield put({
      type: actions.GET_SHOPIFY_LISTS,
    });
    message.success(data.data);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DISCONNECT_SHOPIFY_SUCCESS,
      loading: { shopify_integrations: false },
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Disconnect failed');
    }
  }
}

export function* saveShopifySettings(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/shopify/update_settings?contact_tags=${
      params.object.contact_tags
    }&abandon_interval=${params.object.abandon_cart_interval}&store_id=${
      params.storeId
    }`;
    let data = yield call(() => patchPromise(url));
    message.success(data.data);
    yield put({
      type: actions.SAVE_SHOPIFY_SETTINGS_SUCCESS,
    });
    yield put({
      type: actions.GET_SHOPIFY_LISTS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SAVE_SHOPIFY_SETTINGS_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('save failed');
    }
  }
}

export function* syncShopify(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/shopify/make_historical_sync?store_id=${
      params.storeId
    }`;
    let response = yield call(() => patchPromise(url));
    message.success(response.data);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SYNC_SHOPIFY_FAILURE,
      loading: { [params.storeId]: 'failed' },
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Sync shopify details failed');
    }
  }
}

export function* shopifyChargeWebhook(params) {
  try {
    const { storeId, chargeId } = params;
    const onBoardingState = store.getState().Auth.onBoardingState;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/shopify/verify_shopify_charge?store=${storeId}&charge_id=${chargeId}`;
    let data = yield call(() => getPromise(url));
    message.success(data.data);
    if (onBoardingState === 'company_details') {
      updateBrowserHistory('getting-started');
    } else {
      updateBrowserHistory('settings/subscription');
    }
    window.location.reload();
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Connect store failed');
    }
  }
}

export function* getWoocommerceLists() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/integration/woocommerce/stores`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_WOOCOMMERCE_LISTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.GET_WOOCOMMERCE_LISTS_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch woocommerce stores failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_APPS_LIST, getAppsDetail),
    takeEvery(actions.DISCONNECT_FB, disconnectFacebook),
    takeEvery(actions.CONNECT_FB, connectFacebook),
    takeEvery(actions.CONNECT_FB_PAGE, connectFacebookPage),
    takeEvery(actions.CONNECT_FB_AUDIENCE, connectFacebookAudience),
    takeEvery(actions.DISCONNECT_FB_AUDIENCE, disconnectFacebookAudience),
    takeEvery(actions.SYNC_FB_CUSTOM_AUDIENCE, syncFacebookAudience),
    takeEvery(actions.DISCONNECT_TWITTER, disconnectTwitter),
    takeEvery(actions.CONNECT_TWITTER, connectTwitter),
    takeEvery(actions.VERIFY_TWITTER_CONNECT, verifyTwitter),
    takeEvery(actions.DISCONNECT_WOO_COMMERCE, disconnectWooCommerce),
    takeEvery(actions.SYNC_WOO_COMMERCE, syncWooCommerce),
    takeEvery(actions.VERIFY_WOO_COMMERCE_DOMAIN, verifyWooCommerceDomain),
    takeEvery(actions.CONNECT_ZOOM, connectZoom),
    takeEvery(actions.DISCONNECT_ZOOM, disconnectZoom),
    takeEvery(actions.VERIFY_ZOOM_CONNECT, verifyZoom),
    takeEvery(actions.DISCONNECT_STRIPE, disconnectStripe),
    takeEvery(actions.CONNECT_STRIPE, connectStrip),
    takeEvery(actions.VERIFY_STRIPE_CONNECT, verifyStrip),
    takeEvery(actions.UPDATE_APPS_STATUS, updateAppsStatus),
    takeEvery(actions.INSTALL_SUCCESS, installSuccess),
    takeEvery(actions.CONFIGURE_APP_DATA, configureAppData),
    takeEvery(actions.CONFIGURE_APP_SUBMIT, configureAppSubmit),
    takeEvery(actions.GET_SHOPIFY_LISTS, getShopifyLists),
    takeEvery(actions.CONNECT_SHOPIFY, connectShopify),
    takeEvery(actions.DISCONNECT_SHOPIFY, disconnectShopify),
    takeEvery(actions.SAVE_SHOPIFY_SETTINGS, saveShopifySettings),
    takeEvery(actions.SYNC_SHOPIFY, syncShopify),
    takeEvery(actions.SHOPIFY_CHARGE_WEBHOOK, shopifyChargeWebhook),
    takeEvery(actions.GET_WOOCOMMERCE_LISTS, getWoocommerceLists),
  ]);
}
