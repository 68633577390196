import { store } from '../../../redux/store';
import actions from '../../../redux/assistants/actions';
import {
  getTeamData,
  getUserData,
  removeUnderscore,
} from '../../../helpers/utility';
import { jsPlumbUtil } from 'jsplumbtoolkit';
import { cloneDeep, isEqual, pick, map, values, max, uniqWith } from 'lodash';
import { defaultZoom, emailChoices } from './constants';
import $ from 'jquery';
import filterActions from '../../../redux/filters/actions';
import { filterVariables } from '../../../redux/constants';
import { Modal } from 'antd';
import moment from 'moment';

let containerWidth = 1400,
  containerHeight = 1400;

export function refresh() {
  window.botSurface.relayout();
  window.botSurface.refresh();
  window.botInstance.repaintEverything();
}

export function setZoom() {
  const { botSurface, botInstance } = window;
  const value = sessionStorage.getItem('botZoomLevel')
    ? sessionStorage.getItem('botZoomLevel') / 100
    : defaultZoom;
  botInstance.setZoom(value);
  botSurface.setZoom(value);
}

export function zoomIn() {
  const { botSurface, botInstance } = window;
  const currentZoom = botSurface.getZoom();
  if (currentZoom < 1.0) {
    botSurface.setZoom(currentZoom + 0.1);
    botInstance.setZoom(currentZoom + 0.1);
  }
  return parseInt(parseFloat(botSurface.getZoom()).toFixed(1) * 100);
}

export function zoomOut() {
  const { botSurface, botInstance } = window;
  const currentZoom = botSurface.getZoom();
  if (currentZoom.toFixed(1) > 0.3) {
    botSurface.setZoom(currentZoom - 0.1);
    botInstance.setZoom(currentZoom - 0.1);
  }
  return parseInt(parseFloat(botSurface.getZoom()).toFixed(1) * 100);
}

export function loadBuilderData(data) {
  window.botToolkit.load({ data: data });
  setZoom();
}

export function constructBuilderData(data) {
  let botData = { nodes: [], edges: [] };
  botData.nodes = cloneDeep(
    data && data.bot_data && data.bot_data.diagram.nodes,
  );
  botData.edges = cloneDeep(
    data && data.bot_data && data.bot_data.diagram.connections,
  );
  return botData;
}

export function handleTabOnEdge(params) {
  window.botToolkit.removeEdge(params.edge);
  // Purpose for update the store - There are no update in store when we perform the action in toolkit
  store.dispatch({
    type: actions.UPDATE_BOT_TIME_STAMP,
  });
}

export function typeExtractor(el) {
  return el.getAttribute('data-node-type');
}

export function dataGenerator(type, el) {
  const actionType = el.getAttribute('data-action-type');
  // TODO: Need to set the ID
  switch (type) {
    case 'send_message':
      return {
        data: {
          messages: [{ entity: 'text', text: '', id: getRandomID() }],
          buttons: [],
        },
      };
    case 'capture_question':
      return {
        data: {
          messages: [
            {
              entity: 'text',
              text: '',
              id: getRandomID(),
            },
          ],
          buttons: [],
        },
      };
    case 'capture_email':
      return {
        data: {
          messages: [
            {
              entity: 'text',
              text: 'What is your email?',
              id: getRandomID(),
            },
          ],
          placeholder_text: 'Your business email',
          retry_count: 2,
          block_free_email: false,
          block_disposable_email: true,
          skip_if_email_present: false,
          buttons: emailChoices,
        },
      };
    case 'send_calendar_invite':
      return {
        data: {
          id: '',
          Meeting_link: '',
          user_name: '',
        },
      };
    case 'add_conversation_note':
      return {
        data: {
          id: '',
          message_content: '',
        },
      };
    case 'question_with_open_response':
      return {
        data: {
          messages: [
            {
              id: getRandomID(),
              entity: 'text',
              text: '',
            },
          ],
          map_response_to: '',
          skip_if_property_have_value: false,
          is_map_property_enabled: true,
        },
      };
    case 'enroll_to_another_bot':
      return {
        data: { component_secret: '', linked_bot_id: '', value: '', name: '' },
      };
    case 'automation':
      if (actionType === 'apply_contact_tags') {
        return {
          action_type: 'apply_contact_tags',
          data: { tags_id: '', tags_name: '' },
        };
      } else if (actionType === 'apply_conversation_tags') {
        return {
          action_type: 'apply_conversation_tags',
          data: { tags_id: '', tags_name: '' },
        };
      } else if (actionType === 'assign_conversation') {
        return {
          action_type: 'assign_conversation',
          data: { id: undefined, name: '' },
        };
      } else if (actionType === 'close_conversation') {
        return {
          action_type: 'close_conversation',
        };
      } else if (actionType === 'map_property') {
        return {
          action_type: 'map_property',
          data: { map_response_to: '', value: '' },
        };
      } else if (actionType === 'map_conversation_property') {
        return {
          action_type: 'map_conversation_property',
          data: { map_response_to: '', value: '' },
        };
      } else if (actionType === 'goal') {
        return {
          action_type: 'goal',
          data: { name: '' },
        };
      } else if (actionType === 'disable_composer') {
        return {
          action_type: 'disable_composer',
        };
      } else {
        return false;
      }
    case 'condition':
      if (actionType === 'check_agent_availability') {
        return {
          action_type: 'check_agent_availability',
          data: {
            availability_type: 'any',
            id: 'any',
          },
        };
      } else if (actionType === 'custom_audience') {
        return {
          action_type: 'custom_audience',
          data: {
            filters: [],
          },
        };
      } else {
        return false;
      }
    case 'emma':
      if (actionType === 'hand_over_to_emma') {
        return {
          action_type: 'hand_over_to_emma',
          data: {
            messages: [
              {
                id: getRandomID(),
                entity: 'text',
                text: '',
              },
            ],
            buttons: [
              { text: 'That helped 👍', id: getRandomID(), key: 'that_helped' },
              {
                text: 'Talk to a person 👤',
                id: getRandomID(),
                key: 'talk_to_person',
              },
            ],
          },
        };
      } else {
        return false;
      }
    case 'delay': {
      return {
        action_type: 'delay',
        data: {
          delay_type: 'specific_time',
          interval_unit: 'hours',
          interval_value: 8,
        },
      };
    }
    default:
      return false;
  }
}

export function getActionData(data) {
  switch (data.action_type) {
    case 'apply_contact_tags':
      return {
        type: 'Tag contact',
        icon: 'fas fa-tag',
        content: data.data.tags_name
          ? `<span>Tag contact with <b>${data.data.tags_name}</b></span>`
          : '<span class="error-text">Contact Tag Missing</span>',
      };
    case 'apply_conversation_tags':
      return {
        type: 'Tag conversation',
        icon: 'fas fa-tag',
        content: data.data.tags_name
          ? `<span>Tag conversation with <b>${data.data.tags_name}</b></span>`
          : '<span class="error-text">Conversation Tag Missing</span>',
      };
    case 'close_conversation':
      return {
        type: 'Close conversation',
        icon: 'fas fa-check',
        content: '<span>Change conversation status to <b>Closed</b></span>',
      };
    case 'assign_conversation':
      return {
        type: 'Assign conversation',
        icon: 'fas fa-user-plus',
        content: data.data.name
          ? `<span>Assign conversation to <b>${
              data.data.contact_owner ? 'Contact Owner' : data.data.name
            }</b> ${getUsingMethod(data.data)}</span>`
          : '<span class="error-text">Teammate Missing</span>',
      };
    case 'map_property':
      return {
        type: 'Set contact property',
        icon: 'fas fa-pennant',
        content:
          data.data.map_response_to && data.data.value
            ? `<span>Set contact property <b>${
                data.data.map_response_to &&
                removeUnderscore(data.data.map_response_to)
              }</b> to <b>${data.data.value}</b></span>`
            : data.data.map_response_to
            ? '<span class="error-text">Value Missing</span>'
            : '<span class="error-text">Property Missing</span>',
      };
    case 'map_conversation_property':
      const conversationPropertiesData =
        store.getState().Chat.conversationPropertiesData;
      let dataType,
        value = data.data.value;
      conversationPropertiesData.forEach((property) => {
        if (property.name === data.data.map_response_to)
          dataType = property.data_type;
      });
      if (dataType === 'date') {
        if (!value) value = '';
        else value = moment.unix(data.data.value);
      } else if (dataType === 'boolean') value = value.toString();
      return {
        type: 'Set conversation property',
        icon: 'fas fa-pennant',
        content:
          data.data.map_response_to && value
            ? `<span>Set conversation property <b>${
                data.data.map_response_to &&
                removeUnderscore(data.data.map_response_to)
              }</b> to <b>${value}</b></span>`
            : data.data.map_response_to
            ? '<span class="error-text">Value Missing</span>'
            : '<span class="error-text">Property Missing</span>',
      };
    // case 'goal':
    //   return {
    //     type: 'Goal',
    //     icon: 'fas fa-trophy',
    //     content: `<span>Reached goal <b>${data.data.name}</b> </span>`,
    //   };
    case 'disable_composer':
      return {
        type: 'Disable customer reply',
        icon: 'fad fa-comment-alt-slash',
        content:
          '<span>Prevent replies from customers to this conversation</span>',
      };
    default:
      return false;
  }
}

export function getConditionData(data) {
  switch (data.action_type) {
    case 'check_agent_availability':
      return {
        type: 'Check if team is online',
        icon: 'fas fa-users',
        content: getContent(data.data),
      };
    case 'custom_audience':
      return {
        type: 'Matches a custom audience',
        icon: 'fas fa-split',
        content: `${
          data.data.description
            ? data.data.description
            : 'Split the path based on set segment conditions.'
        }`,
      };
    default:
      return false;
  }
}

function getContent(data) {
  if (data.availability_type === 'any') {
    return '<span>Is <b>any teammate</b> online?</span>';
  } else {
    return `<span>Is <b>${data.name}</b>  online?</span>`;
  }
}

export function setDrawerVisible(node) {
  store.dispatch({
    type: actions.SET_NODE,
    payload: node,
  });
  let data = null;
  if (node.objectType) {
    // If click the edit button we can get the node element
    data = node.data;
  } else {
    // Drag and drop element we get node data
    data = node;
  }
  let type = data.type;
  if (['automation', 'condition', 'emma'].includes(data.type)) {
    type = data.action_type;
  }
  openDrawer(type, data.data);
}

function openDrawer(type, data) {
  switch (type) {
    case 'apply_contact_tags': {
      store.dispatch({
        type: actions.SET_APPLY_TAG_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'apply_conversation_tags': {
      store.dispatch({
        type: actions.SET_APPLY_CONVERSATION_TAG_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'enroll_to_another_bot': {
      store.dispatch({
        type: actions.SET_ENROLL_BOT_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'close_conversation': {
      return false;
    }
    case 'assign_conversation': {
      store.dispatch({
        type: actions.SET_ROUTE_CONVERSATION_DRAWER_STATUS,
        payload: { visibility: true, data: getUserData(data) },
      });
      return false;
    }
    case 'capture_question': {
      store.dispatch({
        type: actions.SET_ADD_BUTTON_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'capture_email': {
      store.dispatch({
        type: actions.SET_CAPTURE_EMAIL_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'send_message': {
      store.dispatch({
        type: actions.SET_SEND_MESSAGE_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'send_calendar_invite': {
      store.dispatch({
        type: actions.SET_MEETINGS_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'add_conversation_note': {
      store.dispatch({
        type: actions.SET_CONVERSATION_NOTE_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'support_bot_question': {
      store.dispatch({
        type: actions.SET_SUPPORT_BOT_DRAWER_STATUS,
        payload: { visibility: true, data },
      });
      return false;
    }
    case 'greeting_message': {
      store.dispatch({
        type: actions.SET_WELCOME_MESSAGE_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'map_property': {
      store.dispatch({
        type: actions.SET_CONTACT_PROPERTY_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'map_conversation_property': {
      store.dispatch({
        type: actions.SET_CONVERSATION_PROPERTY_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'goal': {
      store.dispatch({
        type: actions.SET_GOAL_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'question_with_open_response': {
      store.dispatch({
        type: actions.SET_OPEN_RESPONSE_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'check_agent_availability': {
      store.dispatch({
        type: actions.SET_AGENT_AVAILABILITY_DRAWER_STATUS,
        payload: { visibility: true, data: getTeamData(data) },
      });
      return false;
    }
    case 'custom_audience': {
      store.dispatch({
        type: actions.SET_BOT_CUSTOM_AUDIENCE_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      store.dispatch({
        type: filterActions.SET_FILTERS,
        payload: data.filters,
        filterName: filterVariables.botDecisionKey,
      });
      return false;
    }
    case 'hand_over_to_emma': {
      store.dispatch({
        type: actions.SET_HANDOVER_TO_EMMA_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    case 'delay': {
      store.dispatch({
        type: actions.SET_DELAY_DRAWER_STATUS,
        payload: { visibility: true, data: data },
      });
      return false;
    }
    default:
      return false;
  }
}

export function updateNodeData(updateData) {
  const { node } = store.getState().Assistants;
  if (node && node.objectType) {
    const object = node.data;
    object.data = updateData;
    window.botToolkit.updateNode(node, object);
  } else {
    node.data = updateData;
    window.botToolkit.updateNode(node.id, node);
  }
  setTimeout(() => {
    // End point connection display issue
    window.botToolkit.updateNode(node.id);
  }, 0);
}

export function getRandomID() {
  const { bot } = store.getState().Assistants.botDetail;
  return `${bot.id}-${jsPlumbUtil.uuid()}-${new Date() / 1}`;
}

export function getNodesData() {
  if (window.botToolkit && window.botToolkit.exportData()) {
    return window.botToolkit.exportData().nodes;
  }
}

export function getEdgesData() {
  if (window.botToolkit?.exportData()) {
    //removing duplicate edges
    return uniqWith(window.botToolkit.exportData().edges, isEqual);
  }
}

export function loadCanvasData() {
  // setTimeout(() => {
  //   let botData = { nodes: getNodesData(), edges: getEdgesData() };
  //   loadBuilderData(botData);
  // }, 0);
}

export function removePorts(portIDs) {
  const { node } = store.getState().Assistants;
  const currentNode = window.botToolkit.getNode(node.id);
  for (let i = 0; i < portIDs.length; i++) {
    window.botToolkit.removePort(currentNode, portIDs[i]);
  }
  setTimeout(() => {
    window.botSurface.refresh(); // endpoint align proper
  }, 0);
}

function getPort(portID) {
  const { node } = store.getState().Assistants;
  return window.botToolkit.getPort(`${node.id}.${portID}`);
}

export function updateEdgeConnection(previousPortID, newPortID) {
  if (previousPortID) {
    const previousPort = getPort(previousPortID);
    const edges = previousPort.getEdges();
    if (edges.length) {
      const target = edges[0].target;
      const newSource = getPort(newPortID);
      window.botToolkit.addEdge({
        source: newSource,
        target: target,
        data: { id: jsPlumbUtil.uuid() },
      });
    }
  }
}
export const isEqualData = (data_1, data_2) =>
  JSON.stringify(data_1) === JSON.stringify(data_2);

export function isUnsavedCanvasData(data) {
  const existingData = {
    nodes: data.nodes,
    edges: map(data.connections, (item) => pick(item, ['source', 'target'])),
  };
  const newData = {
    nodes: getNodesData(),
    edges: map(getEdgesData(), (item) => pick(item, ['source', 'target'])),
  };
  if (!newData.nodes || (newData.nodes && newData.nodes.length === 0)) {
    return false;
  } else {
    for (let data of newData.nodes) {
      if (data.type === 'greeting_message') {
        for (let content of data.data.messages) {
          if (!content.text) {
            return true;
          }
        }
      }
    }
  }
  return !isEqualData(existingData, newData);
}

export function getBotSettingsData(data) {
  return pick(data, ['name', 'convertfox_link_text', 'enable_convertfox_link']);
}

export function getNodesMaxHeightAndMinHeight() {
  const nodesPositions = values(window.botSurface.getNodePositions()),
    nodeMaxHeight = max(map(nodesPositions, 1)), // first position is width
    nodeMaxWidth = max(map(nodesPositions, 0)); // Zero position is height
  return { nodeHeight: nodeMaxHeight, nodeWidth: nodeMaxWidth };
}

export function handleCanvasWidthAndHeight(params) {
  const [width, height] = params.pos;
  const { nodeWidth, nodeHeight } = getNodesMaxHeightAndMinHeight();
  // Check node is reached to the container width position
  if (width + 400 > containerWidth) {
    // Width increase
    let latestWidth = width + 800;
    $('.jtk-surface').width(latestWidth);
    containerWidth = latestWidth;
  }
  // Check node is dragged inside the container
  else if (containerWidth > 1400 && nodeWidth + 400 < containerWidth) {
    // Width decrease
    let latestWidth = nodeWidth + 800 < 1400 ? 1400 : nodeWidth + 800;
    $('.jtk-surface').width(latestWidth);
    containerWidth = latestWidth;
  }
  // Check node is reached to the container height
  if (height + 400 > containerHeight) {
    // Height increase
    let latestHeight = height + 600;
    $('.jtk-surface').height(latestHeight);
    containerHeight = latestHeight;
  } else if (containerHeight > 1400 && nodeHeight + 400 < containerHeight) {
    // Height decrease
    let latestHeight = nodeHeight + 600 < 1400 ? 1400 : nodeHeight + 600;
    $('.jtk-surface').height(latestHeight);
    containerHeight = latestHeight;
  }
}

function getUsingMethod(data) {
  if (data.action_type === 'team' && !data.contact_owner) {
    if (data.assign_type && data.assign_type === 'round_robin') {
      return 'using round robin';
    } else {
      return 'team inbox';
    }
  }
  return '';
}

function changeMessageIds(messages) {
  messages.forEach((message) => {
    message.id = getRandomID();
  });
}

function changeButtonIds(buttons) {
  buttons.forEach((button) => {
    button.id = getRandomID();
  });
}
function duplicateNodeData(nodeData) {
  if (nodeData.type === 'capture_question') {
    let { buttons, messages } = nodeData.data;
    changeButtonIds(buttons);
    changeMessageIds(messages);
  } else if (
    ['send_message', 'capture_email', 'question_with_open_response'].includes(
      nodeData.type,
    )
  ) {
    let { messages } = nodeData.data;
    changeMessageIds(messages);
  }
  window.botToolkit.addNode({
    ...nodeData,
    id: getRandomID(),
    top: nodeData.top + 100,
    left: nodeData.left + 100,
  });
  refresh();
}

export function handleClickOnNode(params) {
  const { node } = params,
    srcEle = params.e.srcElement,
    editIcon =
      srcEle.classList.contains('edit') ||
      srcEle.parentNode.classList.contains('edit'),
    deleteIcon =
      srcEle.classList.contains('delete') ||
      srcEle.parentNode.classList.contains('delete'),
    duplicateIcon =
      srcEle.classList.contains('duplicate') ||
      srcEle.parentNode.classList.contains('duplicate');
  if (editIcon) {
    // click Edit
    setDrawerVisible(node);
  } else if (duplicateIcon) {
    duplicateNodeData(cloneDeep(node.data));
  }
  if (deleteIcon) {
    // Click delete
    Modal.confirm({
      title: 'Delete this component?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        window.botToolkit.removeNode(node); // Remove the node
        // Purpose for update the store - There are no update in store when we perform the action in toolkit
        store.dispatch({
          type: actions.UPDATE_BOT_TIME_STAMP,
        });
      },
      onCancel() {},
    });
  }
}
