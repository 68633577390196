const actions = {
  GET_APPS_LIST: 'GET_APPS_LIST',
  GET_APPS_LIST_SUCCESS: 'GET_APPS_LIST_SUCCESS',
  SET_PAGE_LOADER: 'SET_PAGE_LOADER',
  DISCONNECT_FB: 'DISCONNECT_FB',
  DISCONNECT_FB_SUCCESS: 'DISCONNECT_FB_SUCCESS',
  DISCONNECT_FB_FAILURE: 'DISCONNECT_FB_FAILURE',
  CONNECT_FB: 'CONNECT_FB',
  CONNECT_FB_SUCCESS: 'CONNECT_FB_SUCCESS',
  CONNECT_FB_FAILURE: 'CONNECT_FB_FAILURE',
  CONNECT_FB_PAGE: 'CONNECT_FB_PAGE',
  CONNECT_FB_PAGE_SUCCESS: 'CONNECT_FB_PAGE_SUCCESS',
  CONNECT_FB_PAGE_FAILURE: 'CONNECT_FB_PAGE_FAILURE',
  FB_PAGE_MODAL: 'FB_PAGE_MODAL',
  UPDATE_FB_BUTTON_LOADER: 'UPDATE_FB_BUTTON_LOADER',

  DISCONNECT_FB_AUDIENCE: 'DISCONNECT_FB_AUDIENCE',
  DISCONNECT_FB_AUDIENCE_SUCCESS: 'DISCONNECT_FB_AUDIENCE_SUCCESS',
  DISCONNECT_FB_AUDIENCE_FAILURE: 'DISCONNECT_FB_AUDIENCE_FAILURE',
  CONNECT_FB_AUDIENCE: 'CONNECT_FB_AUDIENCE',
  CONNECT_FB_AUDIENCE_SUCCESS: 'CONNECT_FB_AUDIENCE_SUCCESS',
  CONNECT_FB_AUDIENCE_FAILURE: 'CONNECT_FB_AUDIENCE_FAILURE',
  SYNC_FB_CUSTOM_AUDIENCE: 'SYNC_FB_CUSTOM_AUDIENCE',
  SYNC_FB_CUSTOM_AUDIENCE_SUCCESS: 'SYNC_FB_CUSTOM_AUDIENCE_SUCCESS',
  SYNC_FB_CUSTOM_AUDIENCE_FAILURE: 'SYNC_FB_CUSTOM_AUDIENCE_FAILURE',

  DISCONNECT_TWITTER: 'DISCONNECT_TWITTER',
  DISCONNECT_TWITTER_SUCCESS: 'DISCONNECT_TWITTER_SUCCESS',
  DISCONNECT_TWITTER_FAILURE: 'DISCONNECT_TWITTER_FAILURE',
  CONNECT_TWITTER: 'CONNECT_TWITTER',
  CONNECT_TWITTER_SUCCESS: 'CONNECT_TWITTER_SUCCESS',
  CONNECT_TWITTER_FAILURE: 'CONNECT_TWITTER_FAILURE',
  VERIFY_TWITTER_CONNECT: 'VERIFY_TWITTER_CONNECT',
  VERIFY_TWITTER_CONNECT_SUCCESS: 'VERIFY_TWITTER_CONNECT_SUCCESS',
  VERIFY_TWITTER_CONNECT_FAILURE: 'VERIFY_TWITTER_CONNECT_FAILURE',

  DISCONNECT_WOO_COMMERCE: 'DISCONNECT_WOO_COMMERCE',
  DISCONNECT_WOO_COMMERCE_SUCCESS: 'DISCONNECT_WOO_COMMERCE_SUCCESS',
  SYNC_WOO_COMMERCE: 'SYNC_WOO_COMMERCE',
  SYNC_WOO_COMMERCE_SUCCESS: 'SYNC_WOO_COMMERCE_SUCCESS',
  SYNC_WOO_COMMERCE_FAILURE: 'SYNC_WOO_COMMERCE_FAILURE',
  VERIFY_WOO_COMMERCE_DOMAIN: 'VERIFY_WOO_COMMERCE_DOMAIN',
  SET_WOO_COMMERCE_INTERVAL: 'SET_WOO_COMMERCE_INTERVAL',
  RESET_WOO_COMMERCE_INTERVAL: 'RESET_WOO_COMMERCE_INTERVAL',
  WOO_COMMERCE_MODAL_VISIBLE: 'WOO_COMMERCE_MODAL_VISIBLE',
  GET_WOOCOMMERCE_LISTS: 'GET_WOOCOMMERCE_LISTS',
  GET_WOOCOMMERCE_LISTS_SUCCESS: 'GET_WOOCOMMERCE_LISTS_SUCCESS',
  GET_WOOCOMMERCE_LISTS_FAILURE: 'GET_WOOCOMMERCE_LISTS_FAILURE',

  //Shopify Integration
  GET_SHOPIFY_LISTS: 'GET_SHOPIFY_LISTS',
  GET_SHOPIFY_LISTS_SUCCESS: 'GET_SHOPIFY_LISTS_SUCCESS',
  GET_SHOPIFY_LISTS_FAILURE: 'GET_SHOPIFY_LISTS_FAILURE',
  SHOPIFY_SETTINGS_SELLECTED_STORE_ID: 'SHOPIFY_SETTINGS_SELLECTED_STORE_ID',
  SHOPIFY_CONNECT_MODAL: 'SHOPIFY_CONNECT_MODAL',
  CONNECT_SHOPIFY: 'CONNECT_SHOPIFY',
  CONNECT_SHOPIFY_SUCCESS: 'CONNECT_SHOPIFY_SUCCESS',
  CONNECT_SHOPIFY_FAILURE: 'CONNECT_SHOPIFY_FAILURE',
  DISCONNECT_SHOPIFY: 'DISCONNECT_SHOPIFY',
  DISCONNECT_SHOPIFY_SUCCESS: 'DISCONNECT_SHOPIFY_SUCCESS',
  DISCONNECT_SHOPIFY_FAILURE: 'DISCONNECT_SHOPIFY_FAILURE',
  SAVE_SHOPIFY_SETTINGS: 'SAVE_SHOPIFY_SETTINGS',
  SAVE_SHOPIFY_SETTINGS_SUCCESS: 'SAVE_SHOPIFY_SETTINGS_SUCCESS',
  SAVE_SHOPIFY_SETTINGS_FAILURE: 'SAVE_SHOPIFY_SETTINGS_FAILURE',
  SYNC_SHOPIFY: 'SYNC_SHOPIFY',
  SYNC_SHOPIFY_SUCCESS: 'SYNC_SHOPIFY_SUCCESS',
  SYNC_SHOPIFY_FAILURE: 'SYNC_SHOPIFY_FAILURE',
  SHOPIFY_CHARGE_WEBHOOK: 'SHOPIFY_CHARGE_WEBHOOK',

  DISCONNECT_ZOOM: 'DISCONNECT_ZOOM',
  DISCONNECT_ZOOM_SUCCESS: 'DISCONNECT_ZOOM_SUCCESS',
  DISCONNECT_ZOOM_FAILURE: 'DISCONNECT_ZOOM_FAILURE',
  CONNECT_ZOOM: 'CONNECT_ZOOM',
  CONNECT_ZOOM_SUCCESS: 'CONNECT_ZOOM_SUCCESS',
  CONNECT_ZOOM_FAILURE: 'CONNECT_ZOOM_FAILURE',
  VERIFY_ZOOM_CONNECT: 'VERIFY_ZOOM_CONNECT',
  VERIFY_ZOOM_CONNECT_SUCCESS: 'VERIFY_ZOOM_CONNECT_SUCCESS',
  VERIFY_ZOOM_CONNECT_FAILURE: 'VERIFY_ZOOM_CONNECT_FAILURE',

  DISCONNECT_STRIPE: 'DISCONNECT_STRIPE',
  DISCONNECT_STRIPE_SUCCESS: 'DISCONNECT_STRIPE_SUCCESS',
  DISCONNECT_STRIPE_FAILURE: 'DISCONNECT_STRIPE_FAILURE',
  CONNECT_STRIPE: 'CONNECT_STRIPE',
  CONNECT_STRIPE_SUCCESS: 'CONNECT_STRIPE_SUCCESS',
  CONNECT_STRIPE_FAILURE: 'CONNECT_STRIPE_FAILURE',
  VERIFY_STRIPE_CONNECT: 'VERIFY_STRIPE_CONNECT',
  VERIFY_STRIPE_CONNECT_SUCCESS: 'VERIFY_STRIPE_CONNECT_SUCCESS',
  VERIFY_STRIPE_CONNECT_FAILURE: 'VERIFY_STRIPE_CONNECT_FAILURE',

  UPDATE_APPS_STATUS: 'UPDATE_APPS_STATUS',
  UPDATE_APPS_STATUS_SUCCESS: 'UPDATE_APPS_STATUS_SUCCESS',
  UPDATE_APPS_STATUS_FAILURE: 'UPDATE_APPS_STATUS_FAILURE',
  CHANGE_MODAL_VISIBILTY: 'CHANGE_MODAL_VISIBILTY',
  CHANGE_DEVELOPER_APP_MODAL_VISIBILITY:
    'CHANGE_DEVELOPER_APP_MODAL_VISIBILITY',

  INSTALL_SUCCESS: 'INSTALL_SUCCESS',
  INSTALL_SUCCESS_COMPLETE: 'INSTALL_SUCCESS_COMPLETE',
  SET_LOADER: 'SET_LOADER',
  CONFIGURE_APP_DATA: 'CONFIGURE_APP_DATA',
  CONFIGURE_APP_DATA_SUCCESS: 'CONFIGURE_APP_DATA_SUCCESS',
  CONFIGURE_APP_DATA_FAILURE: 'CONFIGURE_APP_DATA_FAILURE',
  CONFIGURE_APP_SUBMIT: 'CONFIGURE_APP_SUBMIT',
  CONFIGURE_APP_DATA_LOADER: 'CONFIGURE_APP_DATA_LOADER',
};

export default actions;
