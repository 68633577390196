import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import {
  Tree,
  MultiBackend,
  getBackendOptions,
} from '@minoru/react-dnd-treeview';
import './style.scss';
import CollectionItem from './Collection/CollectionItem';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/knowledgeBase/actions';
import { Button, Dropdown, Skeleton } from 'antd';
import * as _ from 'lodash';
import MoveCollectionsModal from './MoveCollectionsModal';
import { updateBrowserHistory } from '../../helpers/utility';
import { cloneDeep } from 'lodash';

function Collections(props) {
  const dispatch = useDispatch();
  const { articles, categories } = useSelector(
    ({ KnowledgeBase }) => KnowledgeBase.articlesAndCollectionsList,
  );
  const isArticleSelected = useSelector(
    ({ KnowledgeBase }) => KnowledgeBase.isArticleSelected,
  );
  const supportedLanguages = useSelector(
    ({ KnowledgeBase }) => KnowledgeBase.supportedLanguages,
  );
  const loader = useSelector(
    ({ KnowledgeBase }) => KnowledgeBase.articlesAndCollectionsLoading,
  );
  const [treeData, setTreeData] = useState([]);
  const [openCollections, setOpenCollections] = useState([]);
  useEffect(() => {
    dispatch({ type: actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST });
  }, []);

  useEffect(() => formatTreeData(), [articles, categories]);

  const handleClick = () => {
    dispatch({
      type: actions.CHANGE_COLLECTIONS_MODAL_DETAIL,
      visibility: true,
      isEditCollection: false,
    });
  };
  const updateArticlesCount = (countDetails, newTree) => {
    countDetails?.map(
      (data) =>
        (newTree = newTree.map((category) => {
          if (category.id === `c-${data.id}`) {
            category.articles_count =
              data.articles_count ?? category.articles_count;
            category.hierarchy_level =
              data.hierarchy_level ?? category.hierarchy_level;
          }
          return category;
        })),
    );
    setTreeData(newTree);
  };
  const updateTreeData = (source, target, countDetails) => {
    if (!isArticleSelected) {
      source = source.map((item) => `c-${item}`);
    }
    const newTreeData = treeData.map((data) =>
      source.includes(data.id) ? { ...data, parent: `c-${target}` } : data,
    );
    updateArticlesCount(countDetails, newTreeData);
  };
  const moveItemToLast = (paramsObject) => {
    let { newTree, dropTargetId, dragSourceId, type } = paramsObject;
    if (
      (dragSourceId !== dropTargetId &&
        !openCollections.includes(dropTargetId) &&
        dropTargetId !== 0) ||
      (type === 'article' && !dropTargetId)
    ) {
      let droppedItem = newTree.find((data) => data.id === dragSourceId);
      const clonedTree = cloneDeep(newTree);
      clonedTree.map((data, index) => {
        if (data.id === dragSourceId) {
          newTree.splice(index, 1);
        } else {
          return data;
        }
      });
      newTree.push(droppedItem);
    }
    setTreeData(newTree);
  };
  const getCollectionItem = (collection) => {
    const {
      id,
      name,
      secret_key,
      category_icon,
      articles_count,
      hierarchy_level,
      category_parent_id,
      description,
      position,
      custom_icon_url,
    } = collection;
    return {
      secret_key,
      text: name,
      description,
      id: `c-${id}`,
      category_icon,
      hierarchy_level,
      droppable: true,
      type: 'collection',
      status: 'published',
      articles_count,
      parent: category_parent_id ? `c-${category_parent_id}` : 0,
      position,
      custom_icon_url,
    };
  };

  const getArticleItem = (article) => {
    const {
      id,
      name,
      all_articles_status,
      category_id,
      secret_key,
      position,
      duplicate,
    } = article;
    return {
      id,
      position,
      duplicate,
      secret_key,
      text: name,
      type: 'article',
      droppable: false,
      status: all_articles_status,
      parent: category_id ? `c-${category_id}` : 0,
    };
  };
  const formatTreeData = () => {
    let parentArticles;
    let treeDataTemp = [];
    articles.map((article) => {
      treeDataTemp.push(getArticleItem(article));
      if (article.also_appears_on) {
        article.also_appears_on.map((item) => {
          let treeItem = getArticleItem({
            ...article,
            id: `${Math.random().toString(16).substring(2, 8)}-${+new Date()}`,
            category_id: item.category_id,
            position: item.position,
            duplicate: true,
          });
          treeDataTemp.push(treeItem);
        });
      }
    });
    //to move articles without parent to last
    parentArticles = treeDataTemp.filter(
      (data) => !data.parent && !data.position,
    );
    treeDataTemp = treeDataTemp.filter((data) => data.parent);

    categories.map((collection) => {
      treeDataTemp.push(getCollectionItem(collection));
    });
    treeDataTemp.sort((a, b) => a.position - b.position);
    setTreeData([...treeDataTemp, ...parentArticles]);
  };
  const checkPosition = (dropTargetId, relativeIndex, type) => {
    if (!dropTargetId && type === 'collection') {
      return relativeIndex;
    } else if (dropTargetId !== 0 && !openCollections.includes(dropTargetId)) {
      return null;
    } else {
      return relativeIndex;
    }
  };
  const onDragFailure = (oldTreeData) => {
    setTreeData(oldTreeData);
  };
  const handleDrop = (newTree, options) => {
    const oldTreeData = cloneDeep(treeData);
    const {
      dragSource: { type, parent, secret_key },
      dropTarget,
      relativeIndex,
      dragSourceId,
      dropTargetId,
    } = options;
    const paramsObject = {
      type,
      newTree,
      dropTargetId,
      dragSourceId,
    };
    moveItemToLast(paramsObject);
    if (type === 'article') {
      const from_category = isNaN(parent)
        ? Number(parent.substring(2))
        : parent;
      const target_category = isNaN(dropTargetId)
        ? Number(dropTargetId.substring(2))
        : null;
      dispatch({
        type: actions.SET_ARTICLE_POSITION,
        payload: {
          source_article: secret_key,
          from_category,
          target_category,
          position: checkPosition(dropTargetId, relativeIndex, type),
        },
        onDragFailure: () => onDragFailure(oldTreeData),
        callback: (countDetails) => updateArticlesCount(countDetails, newTree),
      });
    } else {
      const source_category = Number(dragSourceId.substring(2));
      const target_category = dropTarget
        ? Number(dropTargetId.substring(2))
        : null;

      dispatch({
        type: actions.SET_COLLECTIONS_POSITION,
        payload: {
          source_category,
          target_category,
          position: checkPosition(dropTargetId, relativeIndex, type),
        },
        onDragFailure: () => onDragFailure(oldTreeData),
        callback: (countDetails) => updateArticlesCount(countDetails, newTree),
      });
    }
  };
  return (
    <>
      <div className="collection__header flex flex-row justify-between">
        <div>
          <h2>Collections</h2>
        </div>
        <div>
          <Button.Group>
            <Button
              disabled={loader}
              type={'default'}
              className={'btn btn-primary btn-sm'}
              onClick={handleClick}
            >
              New collection
            </Button>
            <Dropdown
              disabled={loader}
              overlay={
                <Button
                  className={'btn btn-secondary-outline btn-sm'}
                  onClick={() => {
                    updateBrowserHistory('knowledge-base/article');
                  }}
                >
                  New Article
                </Button>
              }
            >
              <Button
                className={'btn btn-primary btn-sm'}
                style={{ padding: '4px 8px' }}
              >
                <i className={'fas fa-caret-down'} />
              </Button>
            </Dropdown>
          </Button.Group>
        </div>
      </div>

      <div className="collection__tree">
        <div className="flex mt-6 pl-5 pb-3 items-center font-semibold collection__tree__header">
          <div className="w-17" />
          <div className="title-name flex flex-grow max-w-full">
            Collection name
          </div>
          <div className="w-40">
            {supportedLanguages.length > 1 ? 'Locale' : ''}
          </div>
          <div className="w-32 mr-4 text-center">Articles count</div>
          <div className="w-40 mr-2 text-center">Actions</div>
        </div>
        {loader ? (
          _.times(5, (key) => (
            <>
              <Skeleton
                className={'mt-3'}
                key={key}
                active
                title={false}
                paragraph={{ rows: 2, width: '100%' }}
              />
            </>
          ))
        ) : (
          <div className="flex flex-col">
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <div className="h-full">
                <Tree
                  tree={treeData}
                  rootId={0}
                  render={(node, { depth, isOpen, onToggle }) => (
                    <CollectionItem
                      updateArticlesCount={updateArticlesCount}
                      supportedLanguages={supportedLanguages}
                      key={node.id}
                      node={node}
                      depth={depth}
                      isOpen={isOpen}
                      onToggle={onToggle}
                    />
                  )}
                  enableAnimateExpand={true}
                  // dragPreviewRender={(monitorProps) => (
                  //   <CollectionDragPreview monitorProps={monitorProps} />
                  // )}
                  onDrop={handleDrop}
                  classes={{
                    root: 'tree-container',
                    draggingSource: 'dragging-source',
                    placeholder: 'tree-item-placeholder',
                  }}
                  onChangeOpen={(e) => setOpenCollections(e)}
                  sort={false}
                  insertDroppableFirst={false}
                  canDrop={(
                    tree,
                    { dragSource, dropTarget, dropTargetId, dragSourceId },
                  ) => {
                    if (
                      dropTarget?.type === 'article' ||
                      dropTargetId === dragSourceId ||
                      (dragSource?.type === 'article' &&
                        !dragSource?.parent &&
                        !dropTargetId)
                    ) {
                      return false;
                    }
                    return true;
                  }}
                  dropTargetOffset={5}
                  placeholderRender={(node, { depth }) => (
                    <CollectionDragPlaceholder node={node} depth={depth} />
                  )}
                />
              </div>
            </DndProvider>
          </div>
        )}
      </div>
      <MoveCollectionsModal
        treeData={treeData}
        updateTreeData={updateTreeData}
      />
    </>
  );
}

const CollectionDragPlaceholder = (props) => {
  const { depth } = props;
  return (
    <div className="placeholder-container" style={{ left: depth * 24 }}></div>
  );
};

export default Collections;
