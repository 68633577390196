import React, { Fragment } from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class SupportBotQuestion extends BaseEditableComponent {
  render() {
    let { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          editNote={this.edit.bind(this)}
          isNotAllowToDelete={true}
          isNotAllowToDuplicate={true}
        />
        <div className={'header'}>
          <h3>
            <span>
              <i className={'fas fa-question'} />
            </span>
            Training Questions
          </h3>
        </div>
        <div className={'body supportBotQuestions'}>
          {data.questions && data.questions.length ? (
            data.questions.map((text, index) => (
              <Fragment key={index}>
                <div className={'message-text-component'}>
                  <InputTextArea id={index} value={text} />
                </div>
              </Fragment>
            ))
          ) : (
            <div>No questions added yet.</div>
          )}
          {data.matching_phrases && (
            <div className={'matching-phrases'}>
              Qualfiers: <b>{data.matching_phrases}</b>
            </div>
          )}
          <jtk-source port-id={1} endpoint={true} />
        </div>
      </div>
    );
  }
}
