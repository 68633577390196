import actions from './actions';

const initState = {
  accountLoader: false,
};

export default function AccountDetailReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        accountLoader: true,
      };
    case actions.UPDATE_NEW_PASSWORD:
      return {
        ...state,
        accountLoader: true,
      };
    case actions.SET_DISABLE_PROFILE_LOADER:
      return {
        ...state,
        accountLoader: false,
      }
    default:
      return state;
  }
}
