const actions = {
  FETCH_COLLECTION_LIST: 'FETCH_COLLECTION_LIST',
  FETCH_COLLECTION_LIST_SUCCESS: 'FETCH_COLLECTION_LIST_SUCCESS',
  CREATE_COLLECTION: 'CREATE_COLLECTION',
  CREATE_COLLECTION_SUCCESS: 'CREATE_COLLECTION:SUCCESS',
  EDIT_COLLECTION: 'EDIT_COLLECTION',
  EDIT_COLLECTION_SUCCESS: 'EDIT_COLLECTION_SUCCESS',
  DELETE_COLLECTION: 'DELETE_COLLECTION',
  FETCH_AUTHOR_LIST: 'FETCH_AUTHOR_LIST',
  FETCH_AUTHOR_LIST_SUCCESS: 'FETCH_AUTHOR_LIST_SUCCESS',
  CHANGE_AUTHOR_NAME: 'CHANGE_AUTHOR_NAME',
  ON_COLLECTION_CHANGE: 'ON_COLLECTION_CHANGE',
  SHOW_SECTION_MODAL: 'SHOW_SECTION_MODAL',
  SET_SECTIONS_SORT_KEY_AND_VALUE: 'SET_SECTIONS_SORT_KEY_AND_VALUE',
  FETCH_ARTICLE_LIST: 'FETCH_ARTICLE_LIST',
  FETCH_ARTICLE_LIST_SUCCESS: 'FETCH_ARTICLE_LIST_SUCCESS',
  CHANGE_ARTICLES_STATUS: 'CHANGE_ARTICLES_STATUS',
  SET_ARTICLES_SORT_KEY_AND_VALUE: 'SET_ARTICLES_SORT_KEY_AND_VALUE',
  SET_ARTICLE_SEARCH_TEXT: 'SET_ARTICLE_SEARCH_TEXT',
  SET_ARTICLES_LOADER: 'SET_ARTICLES_LOADER',
  SET_COLLECTIONS_LOADER: 'SET_COLLECTIONS_LOADER',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  DELETE_ARTICLE_SUCCESS: 'DELETE_ARTICLE_SUCCESS',
  DELETE_FROM_ARTICLE_AND_COLLECTIONS: 'DELETE_FROM_ARTICLE_AND_COLLECTIONS',
  SET_ARTICLES_SELECTED_ROWS: 'SET_ARTICLES_SELECTED_ROWS',
  DUPLICATE_ARTICLE: 'DUPLICATE_ARTICLE',
  ARTICLES_STATUS_CHANGE: 'ARTICLES_STATUS_CHANGE',
  FETCH_ARTICLE: 'FETCH_ARTICLE',
  FETCH_ARTICLE_SUCCESS: 'FETCH_ARTICLE_SUCCESS',
  FETCH_ARTICLE_FAILURE: 'FETCH_ARTICLE_FAILURE',
  CHANGE_ARTICLES_POSITION: 'CHANGE_ARTICLES_POSITION',
  SET_ARTICLE_TEXT: 'SET_ARTICLE_TEXT',
  SET_ARTICLES_TITLE: 'SET_ARTICLES_TITLE',
  FETCH_ARTICLE_URL: 'FETCH_ARTICLE_URL',
  FETCH_ARTICLE_URL_SUCCESS: 'FETCH_ARTICLE_URL_SUCCESS',
  SET_CHANGED_ARTICLE_URL: 'SET_CHANGED_ARTICLE_URL',
  ON_SAVE_SECONDARY: 'ON_SAVE_SECONDARY',
  ON_SAVE_PRIMARY: 'ON_SAVE_PRIMARY',
  ON_SAVE_AUTHOR: 'ON_SAVE_AUTHOR',
  CREATE_ARTICLE: 'CREATE_ARTICLE',
  CREATE_ARTICLE_SUCCESS: 'CREATE_ARTICLE_SUCCESS',
  CREATE_ARTICLE_FAILURE: 'CREATE_ARTICLE_FAILURE',
  SET_ARTICLE_ID: 'SET_ARTICLE_ID',
  SET_ARTICLE_DETAILS: 'SET_ARTICLE_DETAILS',
  MOVE_ARTICLES: 'MOVE_ARTICLES',
  RESET_SELECTED_ARTICLES: 'RESET_SELECTED_ARTICLES',
  SET_COLLECTION_ICON: 'SET_COLLECTION_ICON',
  HANDLE_DOMAIN_NOTIFICATION: 'HANDLE_DOMAIN_NOTIFICATION',
  SET_DUPLICATE_DETAILS: 'SET_DUPLICATE_DETAILS',
  CHANGE_CATEGORY_POSITION: 'CHANGE_CATEGORY_POSITION',
  COLLECTION_POSITION_CHANGE_FAILURE: 'COLLECTION_POSITION_CHANGE_FAILURE',
  UPDATE_ARTICLES_COUNT: 'UPDATE_ARTICLES_COUNT',
  SET_OPEN_GRAPH_DESCRIPTION: 'SET_OPEN_GRAPH_DESCRIPTION',
  SET_OPEN_GRAPH_TITLE: 'SET_OPEN_GRAPH_TITLE',
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
  ON_SAVE_OG_IMAGE: 'ON_SAVE_OG_IMAGE',
  ON_SAVE_OG_URL: 'ON_SAVE_OG_URL',
  SET_FETCH_ARTICLE_LOADER: 'SET_FETCH_ARTICLE_LOADER',
  UPDATE_OPEN_KEY: 'UPDATE_OPEN_KEY',
  SET_SEO_INDEX: 'SET_SEO_INDEX',
  IMPORT_ARTICLE: 'IMPORT_ARTICLE',
  IMPORT_KB_UPDATE_MODAL: 'IMPORT_KB_UPDATE_MODAL',
  SET_ARTICLES_DESCRIPTION: 'SET_ARTICLES_DESCRIPTION',
  UPDATE_PUBLISH_STATUS: 'UPDATE_PUBLISH_STATUS',
  CHANGE_RESTORABLE_KEY: 'CHANGE_RESTORABLE_KEY',
  UPDATE_PUBLISH_STATUS_SUCCESS: 'UPDATE_PUBLISH_STATUS_SUCCESS',
  UPDATE_PUBLISH_STATUS_FAILURE: 'UPDATE_PUBLISH_STATUS_FAILURE',
  UPDATE_ARTICLE_LIVE_DETAILS: 'UPDATE_ARTICLE_LIVE_DETAILS',
  UPDATE_ARTICLE_LIVE_DETAILS_LOADER: 'UPDATE_ARTICLE_LIVE_DETAILS_LOADER',
  RESET_ARTICLE_DETAILS: 'RESET_ARTICLE_DETAILS',
  SET_ARTICLE_RESTORE_DETAIL: 'SET_ARTICLE_RESTORE_DETAIL',
  SET_SELECTED_LANGUAGE_ARTICLE_DATA: 'SET_SELECTED_LANGUAGE_ARTICLE_DATA',
  FETCH_SUPPORTED_LANGUAGES: 'FETCH_SUPPORTED_LANGUAGES',
  RESET_SUPPORTED_LANGUAGES: 'RESET_SUPPORTED_LANGUAGES',
  FETCH_SUPPORTED_LANGUAGES_SUCCESS: 'FETCH_SUPPORTED_LANGUAGES_SUCCESS',
  UPDATE_ARTICLE_IN_REDUCER: 'UPDATE_ARTICLE_IN_REDUCER',
  SET_ARTICLE_POSITION: 'SET_ARTICLE_POSITION',
  SET_COLLECTIONS_POSITION: 'SET_COLLECTIONS_POSITION',
  FETCH_ARTICLES_AND_COLLECTIONS_LIST: 'FETCH_ARTICLES_AND_COLLECTIONS_LIST',
  CHANGE_COLLECTIONS_MODAL_DETAIL: 'CHANGE_COLLECTIONS_MODAL_DETAIL',
  SET_PARENT_COLLECTION_SECRET: 'SET_PARENT_COLLECTION_SECRET',
  FETCH_EDIT_COLLECTION_DETAIL: 'FETCH_EDIT_COLLECTION_DETAIL',
  UPDATE_COLLECTIONS_MODAL_DETAILS: 'UPDATE_COLLECTIONS_MODAL_DETAILS',
  CHANGE_COLLECTIONS_LOADER: 'CHANGE_COLLECTIONS_LOADER',
  UPDATE_MODAL_FORM_DETAILS: 'UPDATE_MODAL_FORM_DETAILS',
  SET_ACTIVE_LANGUAGE: 'SET_ACTIVE_LANGUAGE',
  REMOVE_LANGUAGE_FROM_EDIT_COLLECTIONS:
    'REMOVE_LANGUAGE_FROM_EDIT_COLLECTIONS',
  FETCH_ARTICLES_AND_COLLECTIONS_LIST_SUCCESS:
    'FETCH_ARTICLES_AND_COLLECTIONS_LIST_SUCCESS',
  FETCH_ARTICLES_AND_COLLECTIONS_LIST_FAILURE:
    'FETCH_ARTICLES_AND_COLLECTIONS_LIST_FAILURE',
  UPLOAD_CUSTOM_ICON: 'UPLOAD_CUSTOM_ICON',
  UPLOAD_CUSTOM_ICON_SUCCESS: 'UPLOAD_CUSTOM_ICON_SUCCESS',
  UPLOAD_CUSTOM_ICON_FAILURE: 'UPLOAD_CUSTOM_ICON_FAILURE',
  REMOVE_CUSTOM_ICON_IN_REDUCER: 'REMOVE_CUSTOM_ICON_IN_REDUCER',
  APPLY_ICON_TO_ALL_LANGUAGE: 'APPLY_ICON_TO_ALL_LANGUAGE',
  DISABLE_APPLY_TO_ALL_LANGUAGES: 'DISABLE_APPLY_TO_ALL_LANGUAGES',
};

export default actions;
