import React, { Component } from 'react';
import '../../components/Settings/settings.scss';
import actions from '../../redux/apps/actions';
import { connect } from 'react-redux';
import { store } from '../../redux/store';
import * as queryString from 'query-string/index';
import { Button, Icon, Spin } from 'antd';
import { fetchProjectSecretKey } from '../../helpers/utility';

class OauthSuccess extends Component {
  componentDidMount() {
    const { location } = this.props;
    if (location) {
      const { client_id, workspace_id } = queryString.parse(location.search);
      if (client_id) {
        store.dispatch({
          type: actions.INSTALL_SUCCESS,
          clientId: client_id,
          workspaceId: workspace_id,
        });
      }
    }
  }

  render() {
    const { installSuccessData } = this.props;
    if (!installSuccessData) {
      return (
        <div className={'stripe-callback-container loader'}>
          <Spin tip="Please wait..." />
        </div>
      );
    } else {
      return (
        <div className={'stripe-callback-container oauth-success-container'}>
          <Icon type="check-circle" theme={'twoTone'} />
          <h1>
            {installSuccessData.status.charAt(0).toUpperCase() +
              installSuccessData.status.slice(1)}
          </h1>
          <h2>{installSuccessData.data}</h2>
          <Button
            className={'btn btn-sm'}
            type="primary"
            onClick={() =>
              this.props.history.push(
                `/projects/${fetchProjectSecretKey()}/contacts`,
              )
            }
          >
            Home
          </Button>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { installSuccessData } = state.Apps;
  return {
    installSuccessData,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(OauthSuccess);
