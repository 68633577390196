import { customAlphabet } from 'nanoid';
import { allTimezones } from '../Settings/constant';
import momentTimeZone from 'moment-timezone';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import defaultThemeStyleOptions from './JSON/defaultThemeStyleOptions.json';

const { fontWeights } = defaultThemeStyleOptions;

export const fontWeightFilter = (fontFamily, fontOptions) => {
  if (fontFamily) {
    let fontObject = fontOptions.find(
      (data) => data.value === fontFamily.split(',')[0],
    );
    if (fontObject && fontObject.weights && fontObject.weights.length) {
      return fontWeights.map((option) => {
        if (
          option['weights'].some((weight) =>
            fontObject.weights.includes(weight),
          )
        ) {
          return { value: option.value, text: option.text };
        } else {
          return { value: option.value, text: option.text, disabled: true };
        }
      });
    }
  }
  return fontWeights;
};

export const generateId = () => {
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const nanoid = customAlphabet(alphabet, 10);
  return nanoid();
};

export const getTimezones = () =>
  Object.entries(allTimezones)
    .reduce((selectOptions, zone) => {
      const offset = momentTimeZone.tz(zone[0]).format('Z');
      const label = `(GMT${offset}) ${zone[1]}`;

      selectOptions.push({
        value: zone[0],
        text: label,
      });

      return selectOptions;
    }, [])
    .sort((a, b) => a.offset - b.offset);

export const getFontWeights = (googleFontFamilies, fontFamily) => {
  let variants = [],
    weights = [];
  if (fontFamily) {
    variants = googleFontFamilies.find(
      (data) => data.family === fontFamily,
    ).variants;
  }
  if (variants && variants.length) {
    fontWeights.forEach((option) => {
      if (option['weights'].some((weight) => variants.includes(weight))) {
        weights.push(option['weights'][0]);
      }
    });
  }
  return weights;
};

export const fetchBuilderFonts = (googleFontFamilies) =>
  googleFontFamilies.map((data) => ({
    value: data.family,
    weights: getFontWeights(googleFontFamilies, data.family),
    text: data.family,
    group: 'Google fonts',
  }));

export default function parseHTML(html, opts = {}) {
  return parse(DOMPurify.sanitize(html), {
    ...opts,
  });
}

export const videoURLChecker = (videoURL, options) => {
  let videoURLFormatted = videoURL || '',
    videoId = '';
  if (videoURL.includes('youtu')) {
    //Reference:  https://web.archive.org/web/20160926134334/http://lasnv.net/foro/839/Javascript_parsear_URL_de_YouTube
    let regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = videoURL.match(regExp);
    if (match && match[7].length === 11) {
      videoId = match[7];
      videoURLFormatted = `https://www.youtube.com/embed/${videoId}?`;
    }
    if (Object.keys(options).length) {
      if (options['video-embed-controls'] === 'false') {
        videoURLFormatted += '&controls=0';
      } else {
        videoURLFormatted += '&controls=1';
      }

      if (options['video-embed-related'] === 'true') {
        videoURLFormatted += '&rel=1';
      } else {
        videoURLFormatted += '&rel=0';
      }

      if (options['video-embed-loop'] === 'true') {
        videoURLFormatted += `&playlist=${videoId}`;
      }
    }
  }

  if (videoURL.indexOf('wistia') > -1 && videoURL.indexOf('/medias/') > -1) {
    videoId = videoURL.split('/medias/')[1];
    videoURLFormatted = `https://fast.wistia.net/embed/iframe/${videoId}?videoFoam=true`;
  }

  if (videoURL.indexOf('vimeo') > -1 && videoURL.indexOf('vimeo.com/') > -1) {
    videoId = videoURL.split('vimeo.com/')[1];
    videoURLFormatted = `https://player.vimeo.com/video/${videoId}?`;
  }
  return videoURLFormatted;
};

export const getStep = (steps, sections, elements, section) => {
  try {
    let rowElementParentSection =
      sections[elements[section.element_id].section_id];
    if (rowElementParentSection.step_id) {
      return steps[rowElementParentSection.step_id];
    }
    return getStep(steps, sections, elements, rowElementParentSection);
  } catch (e) {}
};

export const updateWidth = (device, options, returnWidth) => {
  const suffix = device === 'mobile' ? '-mobile' : '';
  const maxWidthStyleKey = `max-width-style${suffix}`;
  const maxWidthKey = `max-width${suffix}`;

  if (options[maxWidthStyleKey] === 'custom') {
    const maxWidth = options[maxWidthKey];
    return maxWidth > 0 ? `${maxWidth}px` : returnWidth;
  }
  return undefined;
};

export const updateElementsPosition = (
  builderDataElements,
  { section_id, position },
) =>
  Object.entries(builderDataElements).reduce((acc, [name, element]) => {
    if (
      element.section_id === section_id &&
      !element.is_deleted &&
      element.position >= position
    ) {
      acc[name] = {
        ...element,
        position: element.position + 1,
      };
    } else {
      acc[name] = element;
    }
    return acc;
  }, {});

export const getFirstSection = (steps, sections, stepId) => {
  const firstStep =
    stepId ?? Object.keys(steps).find((key) => steps[key].position === 1);
  return Object.keys(sections).find(
    (key) =>
      sections[key].step_id === firstStep && sections[key].position === 1,
  );
};
