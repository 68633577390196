import { Layout, List } from 'antd';
import React, { useEffect } from 'react';
import WorkSpaceListItem from './WorkSpaceListItem';

import { history } from '../../redux/store';
import actions from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParamsValue } from '../../helpers/utility';

import logo from '../../image/logo.png';
import './style.scss';

const WorkSpaceListIntegration = ({ onProjectClick }) => {
  useEffect(() => {
    dispatch({
      type: actions.GET_ALL_PROJECTS,
    });
    document.title = 'Workspaces - Gist';
  }, []);
  const dispatch = useDispatch(),
    projects = useSelector(({ Auth }) => Auth.projects),
    workSpaceLoader = useSelector(({ Auth }) => Auth.workSpaceLoader);

  return (
    <Layout>
      <div className="workspace-list-layout">
        <div
          className={`workspace-list ${
            projects.length === 0 && !workSpaceLoader ? 'no-workspace' : ''
          }`}
          id="workSpaceList"
        >
          <div className="branding">
            <img src={logo} alt="gist" className="logo" />
            <h1>GIST</h1>
          </div>
          <p className="info">Which workspace do you want to integrate with?</p>
          <List
            itemLayout="horizontal"
            dataSource={projects}
            loading={workSpaceLoader}
            locale={{ emptyText: <span /> }}
            renderItem={project => (
              <div
                className={'workspace-list-item'}
                onClick={() => onProjectClick(project)}
              >
                <WorkSpaceListItem project={project} />
              </div>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default WorkSpaceListIntegration;
