import React, { Component, Fragment } from 'react';
import './style.scss';
import {
  fetchProjectSecretKey,
  enableEmailDomainbutton,
  showBanner, getAppDetail,
} from '../../helpers/utility';
import { Modal } from 'antd';
import SubcriptionForm from './SubcriptionForm';
import { store } from '../../redux/store';
import authActions from '../../redux/auth/actions';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Statistic } from 'antd';

const { Countdown } = Statistic;

export class TopBarNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeMaintenance: localStorage.getItem('blackFriday2022'),
    };
  }
  closeTopBarNotification = () => {
    this.setState({ closeMaintenance: true });
    localStorage.setItem('blackFriday2022', true);
  };

  getURL = value => `/projects/${fetchProjectSecretKey()}/${value}`;

  handleCancel = () => {
    store.dispatch({
      type: authActions.EMAIL_SENDING_DOMAIN_MODEL_TRIGGER,
      payload: false,
    });
  };

  showModal = e => {
    //TODO need to change this syntax to js.
    var element = window.$(e.target);
    if (element.is('#email-send')) {
      store.dispatch({
        type: authActions.EMAIL_SENDING_DOMAIN_MODEL_TRIGGER,
        payload: true,
      });
    }
  };

  render() {
    const {
      emailSendingModalVisible,
      emailSendingLoader,
      pathName,
      bannerMessages,
      // isPaid,
      plan,
    } = this.props;
    const enableDomainRequestStatus = enableEmailDomainbutton(pathName);
    let message = null;
    let alertMessage =
      !isEmpty(bannerMessages) && showBanner(bannerMessages, pathName);
    if (alertMessage) {
      message = alertMessage;
      const cardURL = `<a href=${this.getURL(
        'settings/subscription',
      )}>card</a>`;
      const billingURL = `<a href=${this.getURL(
        'settings/subscription',
      )}>billing details</a>`;
      const planURL = `<a href=${this.getURL(
        'settings/subscription',
      )}>plan</a>`;
      const trackingCodeURL = `<a class="action" href=${this.getURL(
        'settings/tracking-code',
      )}>View instructions</a>`;
      const emailSend = enableDomainRequestStatus
        ? '<button id="email-send" class="btn btn-dark btn-xs" style={{ marginLeft: 5 }}>Submit Now</button>'
        : '';

      message = message
        .replace(new RegExp('\\{{ card_url }}'), cardURL)
        .replace(new RegExp('\\{{ billing_details_url }}'), billingURL)
        .replace(new RegExp('\\{{ plan_url }}'), planURL)
        .replace(new RegExp('\\{{ tracking_code_url }}'), trackingCodeURL)
        .replace(new RegExp('\\{{ submit now }}'), emailSend);
    }
    const LDTUpgradeDeadline = moment.utc(
      '04/03/2023/06/00',
      'DD/MM/YYYY/hh/mm',
    );
    const showLDTUpgrade = false;
      // !isPaid &&
      // moment().isBefore(LDTUpgradeDeadline) &&
      // ['lifetime', 'ultimate'].includes(plan);

    return (
      <Fragment>
        {alertMessage && (
          <div
            className="notification-layout"
            // style={{
            //   display: this.state.closeNotification ? 'none' : '',
            // }}
          >
            <p
              onClick={this.showModal}
              dangerouslySetInnerHTML={{ __html: message }}
            />
            {enableDomainRequestStatus && (
              <Fragment>
                <Modal
                  visible={emailSendingModalVisible}
                  className={'email-subscription'}
                  width={600}
                  destroyOnClose={true}
                  maskClosable={false}
                  onCancel={() => this.handleCancel()}
                  footer={null}
                >
                  <button
                    aria-label="Close"
                    className="ant-modal-close"
                    onClick={() => this.handleCancel()}
                  >
                    <span className="ant-modal-close-x">
                      <i className="fas fa-circle-xmark" />
                    </span>
                  </button>
                  <h2>Getting your account approved</h2>
                  <p>
                    We need a little bit of information about your blog or
                    business before you can send emails. Just fill the form
                    below and we’ll review it today!
                  </p>
                  <p>
                    {`Until then, you can still use everything in your ${getAppDetail().name}
                    account, but emails won’t send and new email subscribers
                    will be listed as unconfirmed. You can still create forms
                    and drip camapaigns as well as import contacts from another
                    email tool.`}
                  </p>
                  {/*<p>We manually review every new account to ensure that Gist is only used for legitimate purposes. This helps us maintain the industry’s fastest delivery and highest reputation.</p>*/}
                  <SubcriptionForm emailSendingLoader={emailSendingLoader} />
                </Modal>
              </Fragment>
            )}
            {/*<p>*/}
            {/*<b>Want to switch back?</b> You can use the classic Gist layout until*/}
            {/*the beta ends.*/}
            {/*</p>*/}
            {/*<a*/}
            {/*href={settings.API_DOMAIN}*/}
            {/*className="switch-back"*/}
            {/*type="primary"*/}
            {/*target="_blank"*/}
            {/*rel="noopener noreferrer"*/}
            {/*>*/}
            {/*Switch back*/}
            {/*</a>*/}
            {/*<i*/}
            {/*className={'fas fa-xmark cancel'}*/}
            {/*onClick={() => this.closeTopBarNotification()}*/}
            {/*/>*/}
          </div>
        )}

        {/*<div*/}
        {/*className={`notification-layout ${alertMessage ? 'overlay' : ''}`}*/}
        {/*style={{*/}
        {/*display: this.state.closeMaintenance ? 'none' : '',*/}
        {/*}}*/}
        {/*>*/}
        {/*<p>*/}
        {/*We’ve begun maintenance to improve our services.You may experience*/}
        {/*slow performance during the maintenance period. See*/}
        {/*<a href={'https://status.getgist.com'} target={'blank'}>*/}
        {/*{' '}*/}
        {/*status page*/}
        {/*</a>*/}
        {/*</p>*/}
        {/*<i*/}
        {/*className={'fas fa-xmark cancel'}*/}
        {/*onClick={() => this.closeTopBarNotification()}*/}
        {/*/>*/}
        {/*</div>*/}

        {/*{!isPaid && (*/}
        {/*  <div*/}
        {/*    className={`notification-layout ${alertMessage ? 'overlay' : ''}`}*/}
        {/*  >*/}
        {/*    <p>*/}
        {/*      Our Biggest Black Friday Sale*/}
        {/*      <span role="img" aria-label="zap">*/}
        {/*        ⚡*/}
        {/*      </span>{' '}*/}
        {/*      - Over 80% OFF On Gist Premium Plan*/}
        {/*      <a href={'https://getgist.com/black-friday-2023/'} target={'blank'}>*/}
        {/*        {' '}*/}
        {/*        Learn more*/}
        {/*      </a>*/}
        {/*    </p>*/}
        {/*    <i*/}
        {/*      className={'fas fa-xmark cancel'}*/}
        {/*      onClick={() => this.closeTopBarNotification()}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {showLDTUpgrade && (
          <div
            className={`notification-layout prime ${
              alertMessage ? 'overlay' : ''
            }`}
          >
            <Countdown
              value={LDTUpgradeDeadline}
              format="D [Days] : H [Hours] : m [Minutes]"
            />
            <p>
              For a limited time, upgrade your Lifetime plan to Premium to
              unlock everything Gist has to offer.
            </p>
            <NavLink
              to={`/projects/${fetchProjectSecretKey()}/ltd-upgrade`}
              id="Checkout"
              style={{ marginLeft: 5 }}
            >
              Upgrade now!
            </NavLink>
          </div>
        )}
      </Fragment>
    );
  }
}
