import { store } from '../../../redux/store';
import actions from '../../../redux/automation/workflow/actions';
import filterActions from '../../../redux/filters/actions';
import _, { capitalize, cloneDeep } from 'lodash';
import {
  actionLists,
  particularDateFormat,
  rangeItems,
} from '../../../components/Automation/constants';
import { getQueryParamsValue, pluralise, removeUnderscore } from '../../../helpers/utility';
import { filterVariables } from '../../../redux/constants';
import sharedActions from '../../../redux/sharedReducers/actions';
import { actionList } from '../../../components/Automation/constants';
import moment from 'moment';
import { commonDateFormat } from '../../../components/constants';
import { isTemplateEnabled } from '../../../helpers/utility';
import appsActions from '../../../redux/apps/actions';
import { triggerLists } from '../../../components/Automation/constants';
import broadCastActions from '../../../redux/email/broadcast/actions';

const events = {
  click: (labelOverlay) => {
    createBlock(labelOverlay.edge);
  },
};

function createBlock(edge) {
  store.dispatch({
    type: actions.SET_WORKFLOW_COMPONENT_DRAWER_STATUS,
    payload: { status: true, edge },
  });
  insertNode({ id: 'placeholder', component_type: 'placeholder' });
  refresh();
}

function addBlockLabel(position) {
  return {
    location: position,
    label: '<span class="add-block">+</span>',
    events,
  };
}

export function extractNodes(workflow) {
  const nodes = [];
  workflow.roots.forEach((node) => {
    if (node.component_type === 'root') {
      const data = {
        id: node.id.toString(),
        type: 'singleTrigger',
        data: { workflow: workflow.roots },
      };
      nodes.push(data);
    }
  });
  workflow.non_roots.forEach((node) => {
    const data = {
      id: node.id.toString(),
      type: node.component_type,
      data: node,
    };
    nodes.push(data);
  });

  store.dispatch({
    type: actions.GET_UPDATED_NODES,
    payload: nodes,
  });

  return nodes;
}

function extractEdges(workflow) {
  const edges = [];
  let rootNodeId = '';

  workflow.non_roots.forEach((node) => {
    let edgeType = 'straight';
    if (rootNodeId === node.parent_id.toString()) {
      edges.push({
        source: rootNodeId,
        target: node.id.toString(),
        data: {
          type: 'straight',
          rootNodeId,
        },
      });
      return;
    }
    if (['fork_path', 'if', 'else'].includes(node.properties.parent_type)) {
      edgeType = 'straightWithoutEndPoint';
    }
    const data = {
      source: node.parent_id.toString(),
      target: node.id.toString(),
      data: {
        type: ['fork_path', 'if', 'else'].includes(node.component_type)
          ? 'bezier'
          : edgeType,
        ...node,
      },
    };
    edges.push(data);
  });

  return edges;
}

export function refresh() {
  window.surface.relayout();
  window.surface.refresh();
  window.instance.repaintEverything();
}

export function insertNode(block, edge = store.getState().Workflow.edge) {
  const toolkit = window.toolkit,
    nodeId = block.id.toString(),
    type = block.component_type;

  toolkit.addNode({ id: nodeId, type, data: block });
  toolkit.addEdge({
    source: edge.source.id,
    target: nodeId,
    data: { type: edge.data.type, id: edge.source.id },
  });
  toolkit.addEdge({
    source: nodeId,
    target: edge.target.id,
    data: {
      type: ['fork', 'decision'].includes(type) ? 'bezier' : 'straight',
    },
  });
  toolkit.removeEdge(edge);
}

export function insertForkNode(blocks, direction) {
  // TODO: Need to Refactor
  const toolkit = window.toolkit;
  insertNode(blocks[0]); // Insert the decision/fork node

  let nodeId = blocks[1].id.toString(),
    type = blocks[1].component_type;

  toolkit.addNode({ id: nodeId, type, data: blocks[1] }); // Insert the left path
  toolkit.addEdge({
    source: blocks[0].id.toString(),
    target: nodeId,
    data: { id: blocks[0].id.toString(), type: 'bezier' },
  });

  const edge = toolkit.getNode(blocks[0].id.toString()).getSourceEdges()[0];
  toolkit.removeEdge(edge); // Remove edge
  // insertNode(blocks[1], edge); // Insert the left path
  nodeId = blocks[2].id.toString();
  type = blocks[2].component_type;
  toolkit.addNode({ id: nodeId, type, data: blocks[2] }); // Insert the right path
  toolkit.addEdge({
    source: blocks[0].id.toString(),
    target: nodeId,
    data: { id: blocks[0].id.toString(), type: 'bezier' },
  });

  let index = 3;
  if (toolkit.getNode(blocks[3].id.toString()) !== undefined) {
    // Check node is already added or not
    index = 4;
  }
  nodeId = blocks[index].id.toString();
  type = blocks[index].component_type;

  toolkit.addNode({ id: nodeId, type, data: blocks[index] }); // Insert new node remaining

  let path = 1; // Default left side
  if (direction === 0) {
    // Check right side
    path = 2;
  }
  toolkit.addEdge({
    source: blocks[path].id.toString(),
    target: nodeId,
    data: { id: blocks[path].id.toString(), type: 'straight' },
  });

  if (index === 3) {
    index++;
  } else {
    index--;
  }

  // Existing node
  path = 1;
  if (direction === 1) {
    // Check right side
    path = 2;
  }
  nodeId = blocks[index].id.toString();
  toolkit.addEdge({
    source: blocks[path].id.toString(),
    target: nodeId,
    data: { id: blocks[path].id.toString(), type: 'straight' },
  });
}

export function extractPathConsent(node) {
  let exitCounter = 0;
  if (['decision', 'fork'].includes(node.data.type)) {
    const childPaths = node.getSourceEdges();
    childPaths.forEach((childPath) => {
      const grandChildPath = window.toolkit
        .getNode(childPath.target.data.id.toString())
        .getSourceEdges();
      if (grandChildPath[0].target.data.type === 'exit') {
        exitCounter++;
      }
    });
  }
  return exitCounter !== 2;
}

export function handleClickOnNode(params) {
  const { node } = params,
    srcEle = params.e.srcElement,
    path = srcEle.parentNode.parentNode.classList.contains('delete-icon'),
    svg = srcEle.parentNode.classList.contains('delete-icon'),
    icon = srcEle.classList.contains('delete-icon'),
    blank = srcEle.parentNode.classList.contains('blank-trigger'),
    trigger =
      srcEle.parentNode.classList.contains('configured-trigger') ||
      srcEle.parentNode.parentNode.classList.contains('configured-trigger') ||
      srcEle.parentNode.parentNode.parentNode.classList.contains(
        'configured-trigger',
      ) ||
      srcEle.parentNode.parentNode.parentNode.parentNode.classList.contains(
        'configured-trigger',
      ),
    skip = srcEle.classList.contains('skip-flow');
  if (icon || svg || path) {
    // Click the close icon
    const showPathConsent = extractPathConsent(node);
    store.dispatch({
      type: actions.SET_WORKFLOW_COMPONENT_DELETE_DRAWER_STATUS,
      payload: { status: true, data: node, showPathConsent },
    });
  }
  if (skip) {
    skipComponent(node);
  }
  if (blank) {
    // Click the blank trigger
    store.dispatch({
      type: actions.SET_WORKFLOW_TRIGGER_DRAWER_STATUS,
      payload: true,
    });
  }
  if (!(blank || icon || svg || path || skip)) {
    const { type } = node.data;
    if (trigger) {
      setTriggerDetail(node.data);
    } else if (type === 'action') {
      setActionFormValues(node);
    } else if (type === 'delay') {
      setDelayFormValues(node);
    } else if (type === 'decision') {
      setDecisionFormValues(node);
    } else if (type === 'goal') {
      setGoalFormValues(node);
    } else if (type === 'goto') {
      setGotoFormValues(node);
    }
  }
}

export function simpleBezierEdgeConfig() {
  return {
    connector: 'Bezier',
  };
}

export function straightEdgeConfig() {
  return {
    connector: 'Straight',
    overlays: [['Label', addBlockLabel(0.5)]],
  };
}

export function straightEdgeConfigWithoutEndPoint() {
  return {
    connector: 'Straight',
    overlays: [['Label', addBlockLabel(0.5)]],
    // endpoints: ['Blank', 'Blank'],
  };
}

export function zoomIn() {
  const { surface, instance } = window;
  const currentZoom = surface.getZoom();
  if (currentZoom < 1.0) {
    surface.setZoom(currentZoom + 0.1);
    instance.setZoom(currentZoom + 0.1);
  }
  return parseInt(parseFloat(surface.getZoom()).toFixed(1) * 100);
}

export function zoomOut() {
  const { surface, instance } = window;
  const currentZoom = surface.getZoom();
  if (currentZoom.toFixed(1) > 0.3) {
    surface.setZoom(currentZoom - 0.1);
    instance.setZoom(currentZoom - 0.1);
  }
  return parseInt(parseFloat(surface.getZoom()).toFixed(1) * 100);
}

export function extractGraphData(workflow) {
  const nodes = extractNodes(workflow);
  const edges = extractEdges(workflow);

  return { nodes, edges };
}

export function deletePlaceholder(reattach = false) {
  const { toolkit } = window,
    node = toolkit.getNode('placeholder');
  if (node) {
    if (reattach && node.getTargetEdges()[0]) {
      const source = node.getTargetEdges()[0].source.id,
        target = node.getSourceEdges()[0].target.id,
        type = node.getEdges()[0].data.type;
      toolkit.addEdge({ id: source, source, target, data: { type } });
    }
    toolkit.remove(node.id);
  }
  window.instance.repaintEverything();
}

export function dateFormatChange(value, property, condition, fromDate, toDate) {
  const { allPropertiesLists, propertiesMetaData } = store.getState().Filter,
    propertyList = _.find(allPropertiesLists, ['property_name', property]);
  let propertyType,
    isAbsolute = false,
    dynamicData;
  if (propertyList) {
    propertyType = propertyList.property_type;
  }
  if (propertyType === 'date') {
    isAbsolute =
      propertiesMetaData &&
      propertiesMetaData['date'].absolute.some(
        (data) => data.value === condition,
      );
    if (!isAbsolute) {
      dynamicData =
        propertiesMetaData &&
        propertiesMetaData['date'].dynamic.find((data) => data.value === value);
    }
    if (isAbsolute && rangeItems.includes(condition)) {
      return `from ${moment(fromDate).format(commonDateFormat)} to ${moment(
        toDate,
      ).format(commonDateFormat)}`;
    } else if (isAbsolute) {
      return moment(value).format(commonDateFormat);
    }
    return dynamicData ? '' : moment(value).format(commonDateFormat);
  }
  return value;
}

export function actionTypeProperties(data) {
  const actionType = data.properties.action_type;
  const actionGroup = {
    email: [
      'send_an_email',
      'send_notification_mail',
      'send_chat_message',
      'send_announcement_message',
    ],
    tag: ['apply_tag', 'remove_tag'],
    campaign: [
      'subscribe_to_campaign',
      'move_from_one_campaign_to_another',
      'restart_campaign',
    ],
    contact: [
      'set_contact_property',
      'delete_contact',
      'unsubscribe_contact',
      'perform_math',
      'assign_owner',
      'unassign_owner',
      'track_custom_event',
    ],
    workflow: ['enroll_into_workflow', 'remove_from_workflow'],
    integrations: ['add_to_fb_audience_list', 'remove_from_fb_audience_list'],
    webhook: ['trigger_webhook'],
    deal: ['create_deal'],
    hubspot: [
      'create_hubspot_lead',
      'create_hubspot_deal',
      'change_hubspot_deal_stage',
      'create_hubspot_task',
    ],
  };
  let operator = {
    add: 'to',
    subtract: 'from',
    multiple: 'with',
    divide: 'by',
  };
  let description = '';
  if (actionGroup.email.includes(actionType)) {
    let icon = 'fas fa-envelope';
    if (actionType === 'send_an_email') {
      const name = data.properties.display_values.mail_filter.name;
      description = `Send <span>${name}</span> email.`;
    } else if (actionType === 'send_notification_mail') {
      const subject = data.properties.email_subject;
      description = `Notify a teammate <span>${isValue(data, subject)}</span>.`;
    } else if (actionType === 'send_chat_message') {
      const name = data.properties.display_values.message.name;
      description = `Send <span>${name}</span> chat.`;
      icon = 'comment-black-oval-bubble-shape';
    } else if (actionType === 'send_announcement_message') {
      const name = data.properties.display_values.message.name;
      description = `Send <span>${name}</span> post.`;
      icon = 'text-documents';
    }
    return { color: '7a6ad1', icon: icon, description };
  } else if (actionGroup.tag.includes(actionType)) {
    const name = data.properties.display_values.tag.name;
    if (actionType === 'apply_tag') {
      description = `Apply tag <span>${name}</span>.`;
    } else if (actionType === 'remove_tag') {
      description = `Remove tag <span>${name}</span>.`;
    }

    return { color: 'b77ce4', icon: 'fas fa-tag', description };
  } else if (actionGroup.campaign.includes(actionType)) {
    if (actionType === 'subscribe_to_campaign') {
      const name = data.properties.display_values.campaign.name;
      description = `Subscribe to a campaign <span>${name}</span>.`;
    } else if (actionType === 'move_from_one_campaign_to_another') {
      const fromName = data.properties.display_values.from_campaign.name;
      const toName = data.properties.display_values.to_campaign.name;
      description = `Move from <span>${fromName}</span> to <span>${toName}</span>.`;
    } else if (actionType === 'restart_campaign') {
      const name = data.properties.display_values.campaign.name;
      description = `Restart campaign <span>${name}</span>.`;
    }
    return { color: 'f37b2b', icon: 'fas fa-megaphone', description };
  } else if (actionGroup.contact.includes(actionType)) {
    if (actionType === 'set_contact_property') {
      const property = data.properties.field_name;
      const value = data.properties.field_value;
      description = `Set a contact property <span>${
        isErrorClass(data) ? _.startCase(property) : ' - - -'
      }</span> to <span>${value && dateFormatChange(value, property)}</span>.`;
    } else if (actionType === 'delete_contact') {
      description = 'Delete the contact.';
    } else if (actionType === 'unsubscribe_contact') {
      const { properties } = data;
      description = `Unsubscribe contact from <span>${'All mailings'}</span>.`;
      if (properties.unsubscription_type === 'campaign') {
        const name = properties.display_values.campaign.name;
        description = `Unsubscribe contact from campaign <span>${name}</span>.`;
      }
    } else if (actionType === 'perform_math') {
      const { properties } = data;
      if (properties.operator === 'divide') {
        description = `${removeUnderscore(
          properties.operator,
        )} <span>${removeUnderscore(isValue(data, properties.key))}</span> ${
          operator[properties.operator]
        } <span>${properties.value}</span>.`;
      } else {
        description = `${removeUnderscore(properties.operator)} <span>${
          properties.value
        }</span> ${operator[properties.operator]} <span>${removeUnderscore(
          isValue(data, properties.key),
        )}</span>.`;
      }
    } else if (actionType === 'assign_owner') {
      const { userList } = store.getState().Settings,
        teammate = userList.find(
          (user) => user.id === data.properties.owner_id,
        );
      description = `Assign ${
        teammate
          ? removeUnderscore(teammate.display_name)
          : data.properties.owner_id
      } as owner`;
    } else if (actionType === 'unassign_owner') {
      description = 'Unassign contact owner';
    } else if (actionType === 'track_custom_event') {
      description = `Track <b>${data.properties.custom_event_name}</b>`;
    }
    return { color: '00a4bd', icon: 'fas fa-flag-pennant', description };
  } else if (actionGroup.workflow.includes(actionType)) {
    const name = data.properties.display_values.workflow.name;
    if (actionType === 'enroll_into_workflow') {
      description = `Enroll into another workflow  <span>${isValue(
        data,
        name,
      )}</span>.`;
    } else if (actionType === 'remove_from_workflow') {
      description = `Remove from another workflow <span>${isValue(
        data,
        name,
      )}</span>.`;
    }
    return { color: '1bcf7b', icon: 'fas fa-network-wired', description };
  } else if (actionGroup.integrations.includes(actionType)) {
    const accountName = data.properties.fb_ad_account_name,
      listName = data.properties.audience_list_name;
    if (actionType === 'add_to_fb_audience_list') {
      description = `Add contact to <span>${
        isErrorClass(data) ? listName : ' - - -'
      }</span> Custom Audience in <span>${
        isErrorClass(data) ? accountName : ' - - -'
      }</span> account.`;
    } else if (actionType === 'remove_from_fb_audience_list') {
      description = `Remove contact from <span>${
        isErrorClass(data) ? listName : ' - - -'
      }</span> Custom Audience in <span>${
        isErrorClass(data) ? accountName : ' - - -'
      }</span> account.`;
    }
    return { color: '37a1f6', icon: 'fas fa-facebook', description };
  } else if (actionGroup.webhook.includes(actionType)) {
    description = `${capitalize(data.properties.http_method || 'post')} <span>${
      isErrorClass(data) ? data.properties.target_url : ' - - -'
    }</span>.`;
    return { color: 'ffac59', icon: 'fas fa-webhook', description };
  } else if (actionGroup.deal.includes(actionType)) {
    description = `Create a deal <span>${
      isErrorClass(data) ? data.properties.deal_name : ' - - -'
    }</span>.`;
    return { color: 'ffac59', icon: 'fas fa-handshake', description };
  } else if (actionType === 'change_deal_stage') {
    description = 'Change pipeline and stage.';
    return { color: 'ffac59', icon: 'fas fa-handshake', description };
  } else if (actionGroup.hubspot.includes(actionType)) {
    let icon = 'flag';
    if (actionType === 'create_hubspot_deal') {
      description = `Create a deal <span>${
        isErrorClass(data) ? data.properties.hubspot_deal_name : ' - - -'
      }</span> in Hubspot.`;
      icon = 'deal';
    } else if (actionType === 'create_hubspot_task') {
      description = `Create a task <span>${
        isErrorClass(data) ? data.properties.hubspot_task_title : ' - - -'
      }</span> in Hubspot.`;
    } else if (actionType === 'change_hubspot_deal_stage') {
      description = 'Change pipeline and stage in Hubspot.';
    }
    return { color: '00a4bd', icon, description };
  } else {
    return { color: '00a4bd', icon: 'fas fa-flag-pennant', description };
  }
}

export function triggerTypeProperties(data) {
  const triggerType = data.properties.trigger_type;
  const triggerGroup = {
    email: [
      'email_opened',
      'clicked_link_from_email',
      'email_received',
      'email_bounced',
      'email_goal_reached',
    ],
    event: ['page_visited'],
    eventsDropdown: ['performed_event'],
    tag: ['applied_tag', 'removed_tag'],
    chat: ['chat_submitted_email'],
    form: ['submitted_form'],
    campaign: [
      'subscribed_to_campaign',
      'completed_campaign',
      'exited_campaign',
    ],
    contact: ['contact_property_updated', 'unsubscribed_contact'],
    segment: ['matches_custom_audience'],
    orders: ['made_a_purchase', 'abandoned_a_cart'],
    date: ['trigger_on_particular_day'],
    tours: [
      'tour_viewed',
      'tour_started',
      'tour_completed',
      'tour_closed',
      'tour_errored',
      'tour_goal_reached',
    ],
    meeting: ['booked_meeting', 'rescheduled_meeting', 'cancelled_meeting'],
    chatMessage: [
      'chat_message_sent',
      'chat_message_opened',
      'chat_message_replied_to',
      'chat_message_goal_reached',
    ],
    postMessage: [
      'post_message_sent',
      'post_message_opened',
      'post_message_replied_to',
      'post_message_goal_reached',
    ],
    surveys: [
      'survey_seen',
      'survey_started',
      'survey_completed',
      'survey_goal_reached',
    ],
    bots: ['bot_seen', 'bot_engaged', 'bot_completed', 'bot_goal_reached'],
    workflow: [
      'workflow_started',
      'workflow_completed',
      'workflow_goal_reached',
    ],
    forms: [
      'form_seen',
      'form_started',
      'form_completed',
      'form_dismissed',
      'form_goal_reached',
    ],
    deals: [
      'deal_created',
      'deal_stage_changed',
      'deal_pipeline_changed',
      'deal_properties_updated',
    ],
  };
  let description = '';
  let propertyDetails;
  const { allKeys } = store.getState().Filter;

  if (data) {
    allKeys.forEach((category) => {
      const property = _.find(category.properties, {
        property_name: data.properties.key,
      });
      if (property) {
        propertyDetails = property;
      }
    });
  }

  if (triggerGroup.email.includes(triggerType)) {
    let name =
      data.properties.display_values &&
      data.properties.display_values.mail_filter &&
      data.properties.display_values.mail_filter.name;
    if (data.properties.mail_type === 'any_email') {
      name = 'Any email';
    }
    if (triggerType === 'email_opened') {
      description = `<span>${name}</span> email opened`;
    } else if (triggerType === 'clicked_link_from_email') {
      let linkDetail = data.properties.link_detail
        ? data.properties.link_detail
        : '';
      if (linkDetail === 'any_link') {
        linkDetail = 'Any link';
      }
      let campaign = null;
      if (data.properties.mail_type === 'campaign') {
        campaign =
          data.properties.display_values &&
          data.properties.display_values.campaign.name;
      }
      description = `Clicked <span>${linkDetail}</span>`;
      if (name) {
        description += ` from <span>${name}</span>`;
      }
      if (campaign) {
        description += ` in <span>${campaign}</span>`;
      }
    } else if (triggerType === 'email_received') {
      description = `<span>${name}</span> email received`;
    } else if (triggerType === 'email_bounced') {
      description = `<span>${name}</span> email bounced`;
    } else if (triggerType === 'email_goal_reached') {
      description = `<span>${name}</span> email goal reached`;
    }

    return { icon: 'fas fa-envelope', description };
  } else if (triggerGroup.event.includes(triggerType)) {
    description = `<p>Visited a page</p> ${capitalize(
      isValue(data, data.properties.condition),
    )} <span>${isValue(data, data.properties.page_detail)}</span>`;
    return { icon: 'fas fa-clock', description };
  } else if (triggerGroup.tag.includes(triggerType)) {
    const name = data.properties.display_values.tag.name;

    if (triggerType === 'applied_tag') {
      description = `Applied a tag <span>${name}</span>`;
    } else if (triggerType === 'removed_tag') {
      description = `Removed a tag <span>${name}</span>`;
    }
    return { icon: 'fas fa-tag', description };
  } else if (triggerGroup.eventsDropdown.includes(triggerType)) {
    const name =
      data.properties.display_values.event &&
      data.properties.display_values.event.name;
    description = `Performed an event <span>${isValue(data, name)}</span>`;
    return { icon: 'fas fa-clock', description };
  } else if (triggerGroup.chat.includes(triggerType)) {
    description = 'Submitted email in chat';

    return { icon: 'fas fa-comment', description };
  } else if (triggerGroup.form.includes(triggerType)) {
    const name = data.properties.display_values.form.name;

    description = `Contact has filled out <span>${isValue(data, name)}</span>`;

    return { icon: 'fas fa-pencil', description };
  } else if (triggerGroup.campaign.includes(triggerType)) {
    const name = data.properties.display_values.campaign.name;

    if (triggerType === 'subscribed_to_campaign') {
      description = `Subscribed to a campaign <span>${name}</span>`;
    } else if (triggerType === 'completed_campaign') {
      description = `Completed a campaign <span>${name}</span>`;
    } else if (triggerType === 'exited_campaign') {
      description = `Exited from a campaign <span>${name}</span>`;
    }

    return { icon: 'fas fa-megaphone', description };
  } else if (triggerGroup.contact.includes(triggerType)) {
    const { properties } = data;
    if (triggerType === 'unsubscribed_contact') {
      description = `Unsubscribed contact from <span>${'All mailings'}</span>`;
      if (properties.unsubscription_type === 'campaign') {
        const name = properties.display_values.campaign.name;
        description = `Unsubscribe contact from campaign <span>${name}</span>`;
      }
    } else if (triggerType === 'contact_property_updated') {
      const {
        key: property,
        condition = '',
        value = '',
        displayData = [],
        from_date: fromDate,
        to_date: toDate,
      } = properties;
      description = `Updated contact property <span>${
        isErrorClass(data) ? _.startCase(property) : '- - -'
      }</span> ${filterLabel(
        condition,
        value,
        property,
        displayData,
        propertyDetails && propertyDetails.property_type,
        fromDate,
        toDate,
      )}`;
    }
    return { icon: 'fas fa-user', description };
  } else if (triggerGroup.orders.includes(triggerType)) {
    if (triggerType === 'made_a_purchase') {
      description = 'Contact made a purchase';
    } else {
      description = 'Contact abandoned a cart';
    }
    return { icon: 'fas fa-user', description };
  } else if (triggerGroup.segment.includes(triggerType)) {
    description = 'Matched a segment';
    return { icon: 'fas fa-user', description };
  } else if (triggerGroup.date.includes(triggerType)) {
    let values = data.properties;
    if (data.properties.key === 'at_absolute_date') {
      description = `Trigger on a specific date <span>${moment(
        data.properties.absolute_date,
        particularDateFormat,
      ).format('Do MMM YYYY')}</span>`;
    } else {
      description = `<p>Trigger on a particular day </p><span>Date</span> is <span> ${
        values.value ? values.value : ''
      }</span> ${
        values.value ? pluralise('day', parseInt(values.value)) : ''
      } <span>${
        values.condition !== 'on' ? values.condition : ''
      }</span>contact's <span>${removeUnderscore(values.key)}</span>`;
    }
    return { icon: 'fas fa-user', description };
  } else if (triggerGroup.meeting.includes(triggerType)) {
    const name = data.properties.display_values?.meeting_link?.name;
    if (triggerType === 'booked_meeting') {
      description = `Booked a meeting with <span>${name}</span>`;
    } else if (triggerType === 'rescheduled_meeting') {
      description = `Rescheduled a meeting with <span>${name}</span>`;
    } else if (triggerType === 'cancelled_meeting') {
      description = `Cancelled a meeting with <span>${name}</span>`;
    }
    return { icon: 'fas fa-user', description };
  } else if (triggerGroup.tours.includes(triggerType)) {
    const name = data.properties.display_values?.tour?.name;
    const descriptions = {
      tour_viewed: 'tour viewed',
      tour_started: 'tour started',
      tour_completed: 'tour completed',
      tour_closed: 'tour dismissed',
      tour_errored: 'Issue occurred in',
      tour_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    if (triggerType === 'tour_errored') {
      description = `${descriptions[triggerType]} <span>${name}</span> tour`;
    }
    return { icon: 'fas fa-arrow-progress', description };
  } else if (triggerGroup.chatMessage.includes(triggerType)) {
    const name = data.properties.display_values?.message?.name;
    const descriptions = {
      chat_message_sent: 'chat message sent',
      chat_message_opened: 'chat message opened',
      chat_message_replied_to: 'chat message replied',
      chat_message_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    return { icon: 'fas fa-comment-lines', description };
  } else if (triggerGroup.postMessage.includes(triggerType)) {
    const name = data.properties.display_values?.message?.name;
    const descriptions = {
      post_message_sent: 'post message sent',
      post_message_opened: 'post message opened',
      post_message_replied_to: 'post message replied',
      post_message_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    return { icon: 'fas fa-file-lines', description };
  } else if (triggerGroup.surveys.includes(triggerType)) {
    const name = data.properties.display_values?.survey?.name;
    const descriptions = {
      survey_seen: 'survey viewed',
      survey_started: 'survey started',
      survey_completed: 'survey completed',
      survey_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    return { icon: 'fas fa-ballot', description };
  } else if (triggerGroup.bots.includes(triggerType)) {
    const name = data.properties.display_values?.bot?.name;
    const descriptions = {
      bot_seen: 'bot viewed',
      bot_engaged: 'bot engaged',
      bot_completed: 'bot completed',
      bot_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    return { icon: 'fas fa-robot', description };
  } else if (triggerGroup.workflow.includes(triggerType)) {
    const name = data.properties.display_values?.workflow?.name;
    const descriptions = {
      workflow_started: 'workflow started',
      workflow_completed: 'workflow completed',
      workflow_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    return { icon: 'fas fa-list-tree', description };
  } else if (triggerGroup.forms.includes(triggerType)) {
    const name = data.properties.display_values?.form?.name;
    const descriptions = {
      form_seen: 'form viewed',
      form_started: 'form started',
      form_completed: 'form completed',
      form_dismissed: 'form closed',
      form_goal_reached: 'goal reached',
    };
    description = `<span>${name}</span> ${descriptions[triggerType]}`;
    return { icon: 'fas fa-chart-simple-horizontal', description };
  } else if (triggerGroup.deals.includes(triggerType)) {
    const descriptions = {
      deal_created: 'Deal created',
      deal_stage_changed: 'Deal stage changed',
      deal_pipeline_changed: 'Deal pipeline change',
      deal_properties_updated: 'Deal properties updated',
    };
    description = descriptions[triggerType];
    return { icon: 'fas fa-suitcase', description };
  } else {
    return { icon: 'fas fa-user', description };
  }
}

export function getContactOwners(value, displayData) {
  const contactOwnersData =
    displayData && displayData.length
      ? displayData.filter((user) => value.includes(user.id))
      : [];
  let usersStringVal = '';
  contactOwnersData.map((data, index) => {
    if (index !== 0 && index === contactOwnersData.length - 1) {
      usersStringVal += ` and ${data.display_name}`;
    } else if (index !== 0 && contactOwnersData.length > 1) {
      usersStringVal += `, ${data.display_name}`;
    } else {
      usersStringVal = data.display_name;
    }
    return data;
  });
  return usersStringVal;
}

export function filterLabel(
  condition,
  value,
  property,
  displayData,
  propertyType,
  fromDate,
  toDate,
) {
  if (propertyType === 'date') {
    const { propertiesMetaData } = store.getState().Filter,
      relativeProperty = _.find(
        propertiesMetaData.date && propertiesMetaData.date.relative,
        {
          value: condition,
        },
      ),
      dynamicProperty = _.find(
        propertiesMetaData.date && propertiesMetaData.date.dynamic,
        {
          value,
        },
      );
    if (relativeProperty) {
      return relativeProperty.label.replace(' x', ` ${value}`);
    } else if (dynamicProperty) {
      return ` ${dynamicProperty.label}`;
    }
    return `${_.startCase(condition)} ${
      (value || (fromDate && toDate)) &&
      dateFormatChange(value, property, condition, fromDate, toDate)
    }`;
  }
  return `${_.startCase(condition)} ${
    value &&
    (property === 'contact_owner'
      ? getContactOwners(value, displayData)
      : _.startCase(dateFormatChange(value, property, condition)))
  }`;
}

function isValue(data, value) {
  return isErrorClass(data) ? value : '- - -';
}
export function createExit(edge) {
  const parentId = parseInt(edge.source.data.id);

  let object = {
    workflow_component: {
      component_type: 'exit',
      parent_id: parentId,
    },
  };
  store.dispatch({
    type: actions.CREATE_COMPONENT,
    object,
  });
}

export function createGoto(edge) {
  const parentId = parseInt(edge.source.data.id);

  let object = {
    workflow_component: {
      component_type: 'goto',
      parent_id: parentId,
    },
  };
  store.dispatch({
    type: actions.CREATE_COMPONENT,
    object,
  });
}

export function setTriggerFormValues(data) {
  const type = data.properties.trigger_type;
  const triggerType = {
    key: type,
    title: triggerLists[type],
  };
  const categoryName = 'trigger';
  store.dispatch({
    type: actions.SET_WORKFLOW_TRIGGER_TYPE,
    triggerType,
    categoryName,
  });
  if (type === 'matches_custom_audience') {
    store.dispatch({
      type: actions.SET_WORKFLOW_TRIGGER_MATCHES_A_CUSTOM_AUDIENCE,
      payload: { status: true, data: data },
    });
    store.dispatch({
      type: filterActions.SET_FILTERS,
      payload: data.properties && data.properties.filters,
      filterName: filterVariables.workflowTriggerKey,
    });
  } else {
    store.dispatch({
      type: actions.SET_WORKFLOW_TRIGGER_FORM_DRAWER_STATUS,
      payload: { status: true, data: data },
    });
    store.dispatch({
      type: filterActions.SET_FILTERS,
      payload: data.people_filter && data.people_filter.filters,
      filterName: filterVariables.workflowTriggerKey,
    });
  }
}

export function updateNode(data) {
  const { toolkit } = window,
    nodeId = data.id.toString();
  toolkit.updateNode(nodeId, {
    data: data,
  });
  let updatedNodes = cloneDeep(store.getState().Workflow.updatedNodes);
  updatedNodes.forEach((node) => {
    if (node.id === data.id.toString()) {
      node.data = data;
    }
  });
  store.dispatch({
    type: actions.GET_UPDATED_NODES,
    payload: updatedNodes,
  });
}

export function setActionFormValues(node) {
  const { data } = node.data,
    type = data.properties.action_type;
  const actionType = {
    key: type,
    title: actionLists[type],
  };
  const categoryName = 'action';
  store.dispatch({
    type: actions.SET_WORKFLOW_ACTION_TYPE,
    actionType,
    categoryName,
  });
  if (actionList.integrations.includes(type)) {
    store.dispatch({
      type: sharedActions.RESET_AUDIENCE_LIST,
    });
    store.dispatch({
      type: sharedActions.GET_FB_AUDIENCE_LIST,
    });
    store.dispatch({
      type: appsActions.GET_APPS_LIST,
    });
  }
  if (type === 'send_an_email') {
    store.dispatch({
      type: actions.EDIT_BROADCAST_DETAILS,
      id: data.properties.mail_filter_id,
      data: data,
    });
  } else if (type === 'send_chat_message') {
    store.dispatch({
      type: actions.SET_WORKFLOW_CHAT_DRAWER_STATUS,
      payload: { status: true, data: data },
    });
    store.dispatch({
      type: actions.GET_WORKFLOW_CHAT_MESSAGE_DETAILS,
      id: data.properties.triggered_chat_id,
    });
  } else if (type === 'send_announcement_message') {
    store.dispatch({
      type: actions.SET_WORKFLOW_POST_DRAWER_STATUS,
      payload: { status: true, data: data },
    });
    store.dispatch({
      type: actions.GET_WORKFLOW_POST_MESSAGE_DETAILS,
      id: data.properties.triggered_chat_id,
    });
  } else {
    if (type === 'send_notification_mail') {
      store.dispatch({
        type: actions.FETCH_WORKFLOW_TEAMMATE_LIST,
      });
    }
    if (['send_notification_mail', 'create_deal'].includes(type)) {
      store.dispatch({
        type: actions.FETCH_WORKFLOW_USERS_LIST,
      });
    }
    if (actionList.hubspot.includes(type)) {
      store.dispatch({
        type: actions.GET_WORKFLOW_ACTIONS_HUBSPOT_DETAILS,
        payload: type,
      });
    }
    store.dispatch({
      type: actions.SET_WORKFLOW_ACTION_CREATE_AND_EDIT_DRAWER_STATUS,
      payload: { status: true, data: data },
    });
  }
}

export function setDelayFormValues(node) {
  const { data } = node.data;
  store.dispatch({
    type: actions.SET_WORKFLOW_DELAY_DRAWER_STATUS,
    payload: { status: true, data: data },
  });
  store.dispatch({
    type: filterActions.SET_FILTERS,
    payload: data.properties.filters,
    filterName: filterVariables.delayKey,
  });
}

export function setDecisionFormValues(node) {
  const { data } = node.data;
  store.dispatch({
    type: actions.SET_WORKFLOW_DECISION_DRAWER_STATUS,
    payload: {
      data,
      status: true,
      drawerData: { decision_type: data.properties.decision_type },
    },
  });
  store.dispatch({
    type: filterActions.SET_FILTERS,
    payload: data.properties.filters,
    filterName: filterVariables.workflowDecisionKey,
  });
}

export function setTriggerDetail(node) {
  const { data } = node;
  store.dispatch({
    type: actions.SET_WORKFLOW_TRIGGER_VIEW_DRAWER_STATUS,
    payload: true,
  });
  store.dispatch({
    type: actions.UPDATE_WORKFLOW_TRIGGERS_DETAIL,
    payload: data.workflow,
  });
}

export function updateTriggerNode(details, status) {
  const { toolkit } = window,
    { data, id } = toolkit.getNodes()[0].data;
  let workflow = data.workflow;
  if (status === 'update') {
    const index = _.findIndex(workflow, {
      id: details.id,
    });
    if (index !== -1) {
      workflow[index] = details;
    } else {
      workflow = [...workflow, ...details];
    }
  } else if (status === 'delete') {
    workflow = workflow.filter((data) => data.id !== details.id);
  } else {
    workflow = [...workflow, ...details];
  }
  toolkit.updateNode(id.toString(), { data: { workflow } });
  setTriggerDetail({ data: { workflow } });
}

export function setGoalFormValues(node) {
  const { data } = node.data;
  store.dispatch({
    type: actions.SET_WORKFLOW_GOAL_DRAWER,
    payload: { status: true, data: data },
  });
  store.dispatch({
    type: filterActions.SET_FILTERS,
    payload: data.properties.filters,
    filterName: filterVariables.goalKey,
  });
}

export function setGotoFormValues(node) {
  const { data } = node.data;
  store.dispatch({
    type: actions.SHOW_CONTACT_REPORT_DRAWER,
    payload: true,
    data,
  });
}

export function skipComponent(data) {
  const { workflowID, currentFlowId } = store.getState().Workflow;
  store.dispatch({
    type: actions.SKIP_COMPONENT,
    componentId: data.id,
    workflowId: workflowID,
    flowId: currentFlowId,
    loaderData: data,
    isPreview: getQueryParamsValue('preview') !== 'null',
  });
}

export function updateSkipNodeStatus(data) {
  const { updatedNodes } = store.getState().Workflow;
  const getNode = updatedNodes.find((node) => node.id === data.id.toString());
  if (getNode) {
    const { toolkit } = window,
      nodeId = getNode.id;
    toolkit.updateNode(nodeId, {
      ...getNode,
      data: { ...getNode.data, nodeStatus: 'Loading' },
    });
  }
}

export function setAnalytics(value) {
  for (let element of document.querySelectorAll('.jtk-node')) {
    if (isTemplateEnabled()) {
      element.classList.add('remove-analytics');
    } else {
      if (value) {
        element.classList.remove('remove-analytics');
        localStorage.setItem('isDisplayedWorkflowAnalytics', 'true');
      } else {
        element.classList.add('remove-analytics');
        localStorage.setItem('isDisplayedWorkflowAnalytics', 'false');
      }
    }
  }
  refresh();
}

export function isErrorClass(data) {
  return data.component_configuration === 'completed';
}

export const getTeamMateFullName = (teamMate) => {
  const { firstName, lastName, email } = teamMate;
  if (firstName || lastName) {
    return `${firstName} ${lastName}`.trim();
  }
  return email;
};

export const checkHasValue = (selectedMetaData) =>
  ['has_any_value', 'has_no_value'].includes(selectedMetaData);

export const getPropertyType = (value) => {
  const { custom_properties = [] } =
    store.getState().Events.customEventPropertiesDropdown;
  return custom_properties.find((data) => data.name === value)?.type;
};

export const isValueValid = (rule, value, callback) => {
  if (value && value.includes("'")) {
    callback("value cannot contain single quotes (')");
  } else {
    callback();
  }
};

export const handleHighlightConnections = (nodes, action) => {
  [
    ...window.toolkit.getAllEdges(),
    ...window.instance.getAllConnections(),
  ].forEach(function (edge) {
    if (
      nodes.includes(edge?.sourceId?.replace('goto-', '') || edge.source.id) &&
      nodes.includes(edge?.targetId?.replace('goto-', '') || edge.target.id)
    ) {
      if (edge.canvas) {
        edge.canvas.classList[`${action}`]('highlight');
      } else {
        window.surface
          ?.getRenderedConnection(edge.data.id)
          ?.canvas.classList[`${action}`]('highlight');
      }
    }
  });
};

export const handleHighlightNodes = (data) => {
  const { updatedNodes } = store.getState().Workflow;
  const getNode = updatedNodes.find(
    (node) => node.id === data.workflow_component_id.toString(),
  );
  if (getNode) {
    const { toolkit } = window,
      nodeId = getNode.id;
    toolkit.updateNode(nodeId, {
      ...getNode,
      data: { ...getNode.data, nodeStatus: data.status },
    });
  }
};

export const getLogTitle = (node) => {
  let title = '';
  switch (node.type) {
    case 'action':
      title = actionLists[node.data.properties?.action_type];
      break;
    case 'decision':
      title = node.data.properties?.display_text?.title || 'Matches a segment';
      break;
    case 'delay':
      title =
        node.data.properties?.delay_type === 'timed_delay'
          ? 'Timed delay'
          : 'Conditional delay';
      break;
    case 'fork':
      title = node.data.component_type;
      break;
    case 'goto':
      title = node.data.component_type;
      break;
    case 'exit':
      title = node.data.component_type;
      break;
    case 'goal':
      title = node.data.component_type;
      break;
    case 'fork_path':
      title = node.data.component_type;
      break;
    case 'if':
      title = 'Yes';
      break;
    case 'else':
      title = 'No';
      break;
    default:
      title = '';
  }
  return title;
};

export const getLastComponentText = (nodeData) => {
  let title = '';
  if (nodeData?.delay_type) {
    title = nodeData.delay_type;
  } else if (nodeData?.display_text?.title) {
    title = nodeData.display_text.title;
  } else if (nodeData?.action_type) {
    title = nodeData?.action_type;
  }
  return removeUnderscore(title);
};
