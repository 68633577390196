import React from 'react';
import Settings from '../settings/index';

function MicrosoftIdentify() {
  const associatedApplicationsData = {
    associatedApplications: [
      {
        applicationId: Settings.OUTLOOK_CALENDAR_CLIENT_ID,
      },
    ],
  };
  return (
    <pre id={'pre-editor-code'}>
      {JSON.stringify(associatedApplicationsData, null, 2)}
    </pre>
  );
}

export default MicrosoftIdentify;
