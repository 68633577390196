import { store } from '../../redux/store';
import actions from '../../redux/companies/company/actions';

export function addCompanyActivity(data, type) {
  const { activitiesFilter } = store.getState().Company;
  let canAdd = false;
  if (activitiesFilter.length) {
    if (activitiesFilter.includes(type)) {
      canAdd = true;
    }
  } else {
    canAdd = true;
  }
  if (canAdd) {
    store.dispatch({
      type: actions.ADD_COMPANY_ACTIVITIES,
      payload: data[0],
    });
  }
}

export function getWebsiteURL(url, displayText = false) {
  if (url) {
    const URL = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    if (displayText) {
      return URL;
    }
    return `//${URL}`;
  }
  return url;
}
