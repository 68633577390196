const actions = {
  GET_DEAL_DETAILS: 'GET_DEAL_DETAILS',
  GET_DEAL_DETAILS_SUCCESS: 'GET_DEAL_DETAILS_SUCCESS',
  GET_DEAL_DETAILS_FAILURE: 'GET_DEAL_DETAILS_FAILURE',
  GET_DEAL_ACTIVITIES: 'GET_DEAL_ACTIVITIES',
  GET_DEAL_ACTIVITIES_SUCCESS: 'GET_DEAL_ACTIVITIES_SUCCESS',
  GET_DEAL_ACTIVITIES_FAILURE: 'GET_DEAL_ACTIVITIES_FAILURE',
  APPEND_DEAL_ACTIVITIES: 'APPEND_DEAL_ACTIVITIES',
  APPEND_DEAL_ACTIVITIES_SUCCESS: 'APPEND_DEAL_ACTIVITIES_SUCCESS',
  APPEND_DEAL_ACTIVITIES_FAILURE: 'APPEND_DEAL_ACTIVITIES_FAILURE',
  DELETE_DEAL: 'DELETE_DEAL',
  DELETE_DEAL_SUCCESS: 'DELETE_DEAL_SUCCESS',
  DELETE_DEAL_FAILURE: 'DELETE_DEAL_FAILURE',
  FETCH_CONTACTS_LIST: 'FETCH_CONTACTS_LIST',
  FETCH_CONTACTS_LIST_SUCCESS: 'FETCH_CONTACTS_LIST_SUCCESS',
  FETCH_CONTACTS_LIST_FAILURE: 'FETCH_CONTACTS_LIST_FAILURE',
  DEAL_REMOVE_CONTACT: 'DEAL_REMOVE_CONTACT',
  DEAL_REMOVE_CONTACT_SUCCESS: 'DEAL_REMOVE_CONTACT_SUCCESS',
  DEAL_REMOVE_CONTACT_FAILURE: 'DEAL_REMOVE_CONTACT_FAILURE',
  DEAL_ADD_CONTACT: 'DEAL_ADD_CONTACT',
  DEAL_ADD_CONTACT_SUCCESS: 'DEAL_ADD_CONTACT_SUCCESS',
  DEAL_ADD_CONTACT_FAILURE: 'DEAL_ADD_CONTACT_FAILURE',
  DEAL_CONTACT_DRAWER_STATUS: 'DEAL_CONTACT_DRAWER_STATUS',
  GET_DEAL_PROPERTY_LIST: 'GET_DEAL_PROPERTY_LIST',
  GET_DEAL_PROPERTY_LIST_SUCCESS: 'GET_DEAL_PROPERTY_LIST_SUCCESS',
  SET_DEAL_DRAWER_STATUS: 'SET_DEAL_DRAWER_STATUS',
  SET_DEAL_COMPANY_DRAWER_STATUS: 'SET_DEAL_COMPANY_DRAWER_STATUS',
  FETCH_DEALS_NOTES: 'FETCH_DEALS_NOTES',
  FETCH_DEALS_NOTES_SUCCESS: 'FETCH_DEALS_NOTES_SUCCESS',
  FETCH_DEALS_NOTES_FAILURE: 'FETCH_DEALS_NOTES_FAILURE',
  ACTIVATE_DEALS_NOTES_LOADER: 'ACTIVATE_DEALS_NOTES_LOADER',
  CREATE_DEALS_NOTES: 'CREATE_DEALS_NOTES',
  CREATE_DEAL_NOTES_SUCCESS: 'CREATE_DEAL_NOTES_SUCCESS',
  CREATE_DEAL_NOTES_FAILURE: 'CREATE_DEAL_NOTES_FAILURE',
  EDIT_DEAL_NOTES: 'EDIT_DEAL_NOTES',
  EDIT_DEAL_NOTES_SUCCESS: 'EDIT_DEAL_NOTES_SUCCESS',
  DELETE_DEAL_NOTES: 'DELETE_DEAL_NOTES',
  DELETE_DEAL_NOTES_SUCCESS: 'DELETE_DEAL_NOTES_SUCCESS',
  GET_DEAL_MEETINGS: 'GET_DEAL_MEETINGS',
  GET_DEAL_MEETINGS_SUCCESS: 'GET_DEAL_MEETINGS_SUCCESS',
  GET_DEAL_MEETINGS_FAILURE: 'GET_DEAL_MEETINGS_FAILURE',
  DEAL_MEETING_MODAL_STATUS: 'DEAL_MEETING_MODAL_STATUS',
  ADD_DEAL_MEETING: 'ADD_DEAL_MEETING',
  ADD_DEAL_MEETING_SUCCESS: 'ADD_DEAL_MEETING_SUCCESS',
  ADD_DEAL_MEETING_FAILURE: 'ADD_DEAL_MEETING_FAILURE',
  CANCEL_DEAL_MEETING: 'CANCEL_DEAL_MEETING',
  CANCEL_DEAL_MEETING_SUCCESS: 'CANCEL_DEAL_MEETING_SUCCESS',
  CANCEL_DEAL_MEETING_FAILURE: 'CANCEL_DEAL_MEETING_FAILURE',
  GET_DEAL_TEAMMATE_LIST: 'GET_DEAL_TEAMMATE_LIST',
  GET_DEAL_TEAMMATE_LIST_SUCCESS: 'GET_DEAL_TEAMMATE_LIST_SUCCESS',
  GET_DEAL_EMAIL_LIST: 'GET_DEAL_EMAIL_LIST',
  GET_DEAL_EMAIL_LIST_SUCCESS: 'GET_DEAL_EMAIL_LIST_SUCCESS',
  GET_DEAL_EMAIL_LIST_FAILURE: 'GET_DEAL_EMAIL_LIST_FAILURE',
  CREATE_DEAL_EMAIL: 'CREATE_DEAL_EMAIL',
  CREATE_DEAL_EMAIL_SUCCESS: 'CREATE_DEAL_EMAIL_SUCCESS',
  CREATE_DEAL_EMAIL_FAILURE: 'CREATE_DEAL_EMAIL_FAILURE',
  SET_DEAL_ACTIVITIES_FILTER: 'SET_DEAL_ACTIVITIES_FILTER',
  ADD_DEAL_ACTIVITIES: 'ADD_DEAL_ACTIVITIES',
  DEAL_EMAIL_MODEL_STATUS: 'DEAL_EMAIL_MODEL_STATUS',
  UPDATE_MODAL_DEAL_ID: 'UPDATE_MODAL_DEAL_ID',
  RESET_MODAL_DEAL_ID: 'RESET_MODAL_DEAL_ID',
};

export default actions;
