import React, { useEffect, useState } from 'react';
import { Col, Layout, Menu, Row } from 'antd';
import { NavLink } from 'react-router-dom';

import logo from '../../image/logo.png';
import { fetchProjectSecretKey } from '../../helpers/utility';

const { Header } = Layout;

const DeveloperTopNavBar = () => {
  const [currentMenu, setCurrentMenu] = useState('home');
  useEffect(() => {
    const { pathname } = window.location;
    let activeMenu = 'home';
    if (pathname.includes('app')) {
      activeMenu = 'apps';
    }
    if (pathname.includes('canvas-builder')) {
      activeMenu = 'canvas-builder';
    }
    if (currentMenu !== activeMenu) {
      setCurrentMenu(activeMenu);
    }
  });
  return (
    <Header className="top-navbar">
      <Row>
        <Col span={18} className="left">
          <NavLink to={`/projects/${fetchProjectSecretKey()}/contacts`}>
            <img alt="Gist" src={logo} />
          </NavLink>
          <Menu theme="light" mode="horizontal" selectedKeys={[currentMenu]}>
            <Menu.Item className="item" key="home">
              <NavLink to={'/developer'}>Home</NavLink>
            </Menu.Item>

            <Menu.Item className="item" key="apps">
              <NavLink to={'/developer/apps'}>Your apps</NavLink>
            </Menu.Item>

            <Menu.Item className="item" key="canvas-builder">
              <NavLink to={'/developer/canvas-builder'}>Canvas builder</NavLink>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
    </Header>
  );
};

export default DeveloperTopNavBar;
