const actions = {
  PROACTIVE_OPEN_MESSAGE_MODAL: 'PROACTIVE_OPEN_MESSAGE_MODAL',
  PROACTIVE_CLOSE_MESSAGE_MODAL: 'PROACTIVE_CLOSE_MESSAGE_MODAL',
  PROACTIVE_SEND_MESSAGE: 'PROACTIVE_SEND_MESSAGE',
  PROACTIVE_SEND_MESSAGE_SUCCESS: 'PROACTIVE_SEND_MESSAGE_SUCCESS',
  PROACTIVE_SEND_MESSAGE_FAILURE: 'PROACTIVE_SEND_MESSAGE_FAILURE',
  PROACTIVE_MESSAGE_DETAILS: 'PROACTIVE_MESSAGE_DETAILS',
  UPDATE_PROACTIVE_MESSAGE_DETAILS: 'UPDATE_PROACTIVE_MESSAGE_DETAILS',
  CLEAR_PROACTIVE_MESSAGE_DETAILS: 'CLEAR_PROACTIVE_MESSAGE_DETAILS',
};

export default actions;
