import React from 'react';
import '../style.scss';

const InputComponent = ({ component, onChange, onSubmit, canvasLoading }) => {
  const hasSubmit = component.action && component.action.type === 'submit';
  const handleClick = componentId => {
    submit(componentId);
  };

  const handleKeyDown = (keyCode, componentId) => {
    if (keyCode === 13 && hasSubmit) submit(componentId);
  };

  const submit = componentId => {
    if (componentId) {
      onSubmit(componentId);
    }
  };

  const isLoading = canvasLoading && canvasLoading[component.id];
  return (
    <div
      className={`label-input-container${
        component.disabled ? ' disabled' : ''
      }${component.save_state === 'failed' ? ' invalid' : ''}`}
    >
      <label className="input-label">{component.label}</label>
      <div className="input-container">
        <input
          type="text"
          {...(component.id ? { 'data-component-id': component.id } : {})}
          {...(component.disabled ? { disabled: true } : {})}
          {...(component.placeholder
            ? { placeholder: component.placeholder }
            : {})}
          {...(component.value ? { defaultValue: component.value } : {})}
          onChange={event => {
            onChange(component.id, event.target.value);
          }}
          onKeyDown={event => handleKeyDown(event.keyCode, component.id)}
        />

        {component.save_state === 'saved' && (
          <div className="input-success-icon" />
        )}

        {hasSubmit && (
          <button
            className={`input-button${isLoading ? ' loading' : ''}`}
            onClick={() => {
              handleClick(component.id);
            }}
            {...(component.id ? { 'data-component-id': component.id } : {})}
            {...(component.disabled ? { disabled: true } : {})}
            {...(component.action && component.action.type
              ? { 'data-action-type': component.action.type }
              : {})}
            {...(component.action && component.action.url
              ? { 'data-action-url': component.action.url }
              : {})}
          >
            {isLoading && <div className="messenger-card-loader" />}
          </button>
        )}
      </div>
    </div>
  );
};

export default InputComponent;
