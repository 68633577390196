export const defaultVisibleColumns = [
  'full_name',
  'email',
  'gist_contact_action',
  'user_type',
  'user_id',
  'signed_up',
  'last_seen',
  'last_contacted',
  'web_sessions',
  'first_seen',
];
export const orderOfColumns = [
  { property_name: 'full_name' },
  { property_name: 'email' },
  { property_name: 'gist_contact_action' },
  { property_name: 'user_type' },
  { property_name: 'user_id' },
  { property_name: 'signed_up' },
  { property_name: 'last_seen' },
  { property_name: 'last_contacted' },
  { property_name: 'web_sessions' },
  { property_name: 'first_seen' },
];

export const nonEditableProperties = [
  'email',
  'web_sessions',
  'signup_source',
  'landing_page',
  'last_heard_from',
  'last_opened_email',
  'last_clicked_on_link_in_email',
  'browser',
  'browser_version',
  'platform',
  'subscribed_to',
  'unsubscribed_from_emails',
  'marked_emails_as_spam',
  'has_hard_bounced',
  'has_consent',
  'signed_up',
  'device_type',
  'screen_width',
  'screen_height',
  'user_id',
  'user_type',
  'first_seen',
  'last_seen',
  'last_contacted',
  'tags',
  'time_zone',
];

export const disableCloseIcon = ['email'];

export const disableConversationDefaultProperties = [
  'contact',
  'subject',
  'status',
];
