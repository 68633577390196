const actions = {
  SET_SIDEBAR_ACTIVE: 'SET_SIDEBAR_ACTIVE',
  SET_LOADER: 'SET_LOADER',
  SET_PAGE_LOADER: 'SET_PAGE_LOADER',
  FETCH_GENERAL_INFO: 'GET_GENERAL_INFO',
  FETCH_GENERAL_INFO_SUCCESS: 'GET_GENERAL_INFO_SUCCESS',
  PATCH_GENERAL_INFO: 'PATCH_GENERAL_INFO',
  CANCEL_GENERAL_INFO: 'CANCEL_GENERAL_INFO',
  CANCEL_BRANDING_INFO: 'CANCEL_BRANDING_INFO',
  FETCH_BRANDING_INFO: 'FETCH_BRANDING_INFO',
  FETCH_BRANDING_INFO_SUCCESS: 'FETCH_BRANDING_INFO_SUCCESS',
  PATCH_BRANDING_INFO: 'PATCH_BRANDING_INFO',
  SET_TEAM_NAME: 'SET_TEAM_NAME',
  UPDATE_TEAM_MEMBERS: 'UPDATE_TEAM_MEMBERS',
  REMOVE_FROM_TEAM: 'REMOVE_FROM_TEAM',
  CLEAR_MODAL: 'CLEAR_MODAL',
  CREATE_TEAM: 'CREATE_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  INVITE_USER: 'INVITE_USER',
  UPDATE_USER_PERMISSIONS: 'UPDATE_USER_PERMISSIONS',
  USER_NAME_CHANGE_SUCCESS: 'USER_NAME_CHANGE_SUCCESS',
  FETCH_TEAMS: 'FETCH_TEAMS',
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  CREATE_OR_UPDATE_ROLE: 'CREATE_OR_UPDATE_ROLE',
  CREATE_OR_UPDATE_ROLE_SUCCESS: 'CREATE_OR_UPDATE_ROLE_SUCCESS',
  CREATE_OR_UPDATE_ROLE_FAILURE: 'CREATE_OR_UPDATE_ROLE_FAILURE',
  DELETE_ROLE: 'DELETE_ROLE',
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLE_LIST: 'FETCH_ROLE_LIST',
  FETCH_ROLE_LIST_SUCCESS: 'FETCH_ROLE_LIST_SUCCESS',
  SET_CURRENT_ROLE: 'SET_CURRENT_ROLE',
  SET_DRAWER_STATUS: 'SET_DRAWER_STATUS',
  SET_TEAM_SEARCH: 'SET_TEAM_SEARCH',
  SET_USER_SEARCH: 'SET_USER_SEARCH',
  SET_ROLE_SEARCH: 'SET_ROLE_SEARCH',
  FETCH_USERS_LIST: 'FETCH_USERS_LIST',
  FETCH_USERS_LIST_SUCCESS: 'FETCH_USERS_LIST_SUCCESS',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  FETCH_TEAMMATES_LIST: 'FETCH_TEAMMATES_LIST',
  FETCH_TEAMMATES_LIST_SUCCESS: 'FETCH_TEAMMATES_LIST_SUCCESS',
  FETCH_CONTACT_TAGS: 'FETCH_CONTACT_TAGS',
  FETCH_CONTACT_TAGS_SUCCESS: 'FETCH_CONTACT_TAGS_SUCCESS',
  APPEND_CONTACT_TAGS: 'APPEND_CONTACT_TAGS',
  APPEND_CONTACT_TAGS_SUCCESS: 'APPEND_CONTACT_TAGS_SUCCESS',
  SEARCH_TAGS: 'SEARCH_TAGS',
  DELETE_CONTACT_TAG: 'DELETE_CONTACT_TAG',
  UPDATE_CONTACT_TAG: 'UPDATE_CONTACT_TAG',
  CREATE_CONTACT_TAG: 'CREATE_CONTACT_TAG',
  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_PROJECT_PLAN_DETAIL: 'FETCH_PROJECT_PLAN_DETAIL',
  FETCH_PROJECT_PLAN_DETAIL_SUCCESS: 'FETCH_PROJECT_PLAN_DETAIL_SUCCESS',
  CHANGE_STATUS_AND_HIDE: 'CHANGE_STATUS_AND_HIDE',
  FETCH_ACTIVE_USER: 'FETCH_ACTIVE_USER',
  FETCH_ACTIVE_USER_SUCCESS: 'FETCH_ACTIVE_USER_SUCCESS',
  TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
  DELETE_INVITE: 'DELETE_INVITE',
  DELETE_USER: 'DELETE_USER',
  RESEND_INVITE: 'RESEND_INVITE',
  FETCH_CONTACT_PROPERTIES: 'FETCH_CONTACT_PROPERTIES',
  FETCH_CONTACT_PROPERTIES_SUCCESS: 'FETCH_CONTACT_PROPERTIES_SUCCESS',
  DELETE_CONTACT_PROPERTY: 'DELETE_CONTACT_PROPERTY',
  UPDATE_CONTACT_PROPERTY: 'UPDATE_CONTACT_PROPERTY',
  CREATE_CONTACT_PROPERTY: 'CREATE_CONTACT_PROPERTY',
  FETCH_CONTACT_SEGMENTS: 'FETCH_CONTACT_SEGMENTS',
  FETCH_CONTACT_SEGMENTS_SUCCESS: 'FETCH_CONTACT_SEGMENTS_SUCCESS',
  DELETE_CONTACT_SEGMENT: 'DELETE_CONTACT_SEGMENT',
  UPDATE_CONTACT_SEGMENT: 'UPDATE_CONTACT_SEGMENT',
  APPEND_CONTACT_SEGMENT: 'APPEND_CONTACT_SEGMENT',
  APPEND_CONTACT_SEGMENT_SUCCESS: 'APPEND_CONTACT_SEGMENT_SUCCESS',
  FETCH_DOMAINS: 'FETCH_DOMAINS',
  UPDATE_DOMAIN: 'UPDATE_DOMAIN',
  UPDATE_DOMAIN_SUCCESS: 'UPDATE_DOMAIN_SUCCESS',
  DELETE_DOMAIN: 'DELETE_DOMAIN',
  DELETE_DOMAIN_SUCCESS: 'DELETE_DOMAIN_SUCCESS',
  ADD_DOMAIN: 'ADD_DOMAIN',
  FETCH_DOMAINS_SUCCESS: 'FETCH_DOMAINS_SUCCESS',
  EMAIL_TO_DEVELOPER: 'EMAIL_TO_DEVELOPER',
  FETCH_EXPORTS_LIST: 'FETCH_EXPORTS_LIST',
  FETCH_EXPORTS_LIST_SUCCESS: 'FETCH_EXPORTS_LIST_SUCCESS',
  APPEND_EXPORTS_SUCCESS: 'APPEND_EXPORTS_SUCCESS',
  APPEND_EXPORTS: 'APPEND_EXPORTS',
  FETCH_IMPORTS_LIST: 'FETCH_IMPORTS_LIST',
  FETCH_IMPORTS_LIST_SUCCESS: 'FETCH_IMPORTS_LIST_SUCCESS',
  APPEND_IMPORTS: 'APPEND_IMPORTS',
  APPEND_IMPORTS_SUCCESS: 'APPEND_IMPORTS_SUCCESS',
  FETCH_QUALIFICATION_DATA_LIST: 'FETCH_QUALIFICATION_DATA_LIST',
  FETCH_QUALIFICATION_DATA_LIST_SUCCESS:
    'FETCH_QUALIFICATION_DATA_LIST_SUCCESS',
  FETCH_QUALIFICATION_DATA_MODAL_LIST: 'FETCH_QUALIFICATION_DATA_MODAL_LIST',
  FETCH_QUALIFICATION_DATA_MODAL_LIST_SUCCESS:
    'FETCH_QUALIFICATION_DATA_MODAL_LIST_SUCCESS',
  ADD_QUALIFICATION_DATA: 'ADD_QUALIFICATION_DATA',
  DELETE_QUALIFICATION_DATA: 'DELETE_QUALIFICATION_DATA',
  SETTINGS_PROPERTIES_SEARCH: 'SETTINGS_PROPERTIES_SEARCH',
  FETCH_CHAT_TAGS: 'FETCH_CHAT_TAGS',
  FETCH_CHAT_TAGS_SUCCESS: 'FETCH_CHAT_TAGS_SUCCESS',
  UPDATE_CHAT_TAGS: 'UPDATE_CHAT_TAGS',
  DELETE_CHAT_TAGS: 'DELETE_CHAT_TAGS',
  APPEND_CHAT_TAGS: 'APPEND_CHAT_TAGS',
  APPEND_CHAT_TAGS_SUCCESS: 'APPEND_CHAT_TAGS_SUCCESS',
  CREATE_CHAT_TAGS: 'CREATE_CHAT_TAGS',
  FETCH_SNIPPET_DATA: 'FETCH_SNIPPET_DATA',
  FETCH_SNIPPET_DATA_SUCCESS: 'FETCH_SNIPPET_DATA_SUCCESS',
  FETCH_SNIPPET_DATA_FAILURE: 'FETCH_SNIPPET_DATA_FAILURE',
  FETCH_SINGLE_SNIPPET_DATA: 'FETCH_SINGLE_SNIPPET_DATA',
  FETCH_SINGLE_SNIPPET_DATA_SUCCESS: 'FETCH_SINGLE_SNIPPET_DATA_SUCCESS',
  FETCH_SINGLE_SNIPPET_DATA_FAILURE: 'FETCH_SINGLE_SNIPPET_DATA_FAILURE',
  UPDATE_SNIPPET_SORT_ORDER: 'UPDATE_SNIPPET_SORT_ORDER',
  UPDATE_SNIPPET_DATA: 'UPDATE_SNIPPET_DATA',
  UPDATE_SNIPPET_DATA_SUCCESS: 'UPDATE_SNIPPET_DATA_SUCCESS',
  DELETE_SNIPPET_DATA: 'DELETE_SNIPPET_DATA',
  APPEND_SNIPPET_DATA: 'APPEND_SNIPPET_DATA',
  APPEND_SNIPPET_DATA_SUCCESS: 'APPEND_SNIPPET_DATA_SUCCESS',
  CREATE_SNIPPET_DATA: 'CREATE_SNIPPET_DATA',
  CREATE_SNIPPET_FOLDER_DATA: 'CREATE_SNIPPET_FOLDER_DATA',
  CREATE_SNIPPET_FOLDER_DATA_SUCCESS: 'CREATE_SNIPPET_FOLDER_DATA_SUCCESS',
  CREATE_SNIPPET_FOLDER_DATA_FAILURE: 'CREATE_SNIPPET_FOLDER_DATA_FAILURE',
  UPDATE_SNIPPET_FOLDER_DATA: 'UPDATE_SNIPPET_FOLDER_DATA',
  UPDATE_SNIPPET_FOLDER_DATA_SUCCESS: 'UPDATE_SNIPPET_FOLDER_DATA_SUCCESS',
  UPDATE_SNIPPET_FOLDER_DATA_FAILURE: 'UPDATE_SNIPPET_FOLDER_DATA_FAILURE',
  DELETE_SNIPPET_FOLDER_DATA: 'DELETE_SNIPPET_FOLDER_DATA',
  SET_CHAT_TAGS_SEARCH: 'SET_CHAT_TAGS_SEARCH',
  SET_SNIPPET_DATA_SEARCH: 'SET_SNIPPET_DATA_SEARCH',
  SETTINGS_REPLY_TIME: 'SETTINGS_REPLY_TIME',
  UPDATE_APPEARANCE_VALUES: 'UPDATE_APPEARANCE_VALUES',
  FETCH_APPEARANCE_DATA: 'FETCH_APPEARANCE_DATA',
  FETCH_APPEARANCE_DATA_SUCCESS: 'FETCH_APPEARANCE_DATA_SUCCESS',
  UPDATE_APPEARANCE_VALUES_SUCCESS: 'UPDATE_APPEARANCE_VALUES_SUCCESS',
  FETCH_COLOR_VALUES: 'FETCH_COLOR_VALUES',
  FETCH_COLOR_VALUES_SUCCESS: 'FETCH_COLOR_VALUES_SUCCESS',
  FETCH_AVAILABILITY_DATA: 'FETCH_AVAILABILITY_DATA',
  FETCH_AVAILABILITY_DATA_SUCCESS: 'FETCH_AVAILABILITY_DATA_SUCCESS',
  UPDATE_AVAILABILITY_DATA: 'UPDATE_AVAILABILITY_DATA',
  UPDATE_AVAILABILITY_DATA_SUCCESS: 'UPDATE_AVAILABILITY_DATA_SUCCESS',
  UPDATE_QUALIFICATION_BOT_DATA: 'UPDATE_QUALIFICATION_BOT_DATA',
  FETCH_QUALIFICATION_BOT_DATA: 'FETCH_QUALIFICATION_BOT_DATA',
  FETCH_QUALIFICATION_BOT_DATA_SUCCESS: 'FETCH_QUALIFICATION_BOT_DATA_SUCCESS',
  FETCH_LANGUAGE_DATA: 'FETCH_LANGUAGE_DATA',
  FETCH_LANGUAGE_DATA_SUCCESS: 'FETCH_LANGUAGE_DATA_SUCCESS',
  FETCH_LANGUAGES: 'FETCH_LANGUAGES',
  FETCH_LANGUAGES_SUCCESS: 'FETCH_LANGUAGES_SUCCESS',
  UPDATE_LANGUAGE_DATA: 'UPDATE_LANGUAGE_DATA',
  SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
  FETCH_VISIBILITY_DATA: 'FETCH_VISIBILITY_DATA',
  FETCH_VISIBILITY_DATA_SUCCESS: 'FETCH_VISIBILITY_DATA_SUCCESS',
  UPDATE_VISIBILITY_DATA: 'UPDATE_VISIBILITY_DATA',
  UPDATE_VISIBILITY_ENABLED_PATHS: 'UPDATE_VISIBILITY_ENABLED_PATHS',
  FETCH_VISIBILITY_DROPDOWN: 'FETCH_VISIBILITY_DROPDOWN',
  FETCH_VISIBILITY_DROPDOWN_SUCCESS: 'FETCH_VISIBILITY_DROPDOWN_SUCCESS',
  FETCH_MEETINGS_PROFILE_DETAILS: 'FETCH_MEETINGS_PROFILE_DETAILS',
  FETCH_MEETINGS_PROFILE_DETAILS_SUCCESS:
    'FETCH_MEETINGS_PROFILE_DETAILS_SUCCESS',
  FETCH_MEETINGS_PROFILE_DETAILS_FAILURE:
    'FETCH_MEETINGS_PROFILE_DETAILS_FAILURE',
  UPDATE_MEETINGS_PROFILE_DETAILS: 'UPDATE_MEETINGS_PROFILE_DETAILS',
  AVAILABLE_CALENDAR_INTEGRATIONS: 'AVAILABLE_CALENDAR_INTEGRATIONS',
  AVAILABLE_CALENDAR_INTEGRATIONS_SUCCESS:
    'AVAILABLE_CALENDAR_INTEGRATIONS_SUCCESS',
  AVAILABLE_CALENDAR_INTEGRATIONS_FAILURE:
    'AVAILABLE_CALENDAR_INTEGRATIONS_FAILURE',
  GOOGLE_ACCOUNT_OAUTH: 'GOOGLE_ACCOUNT_OAUTH',
  DISCONNECT_CALENDAR_ACCOUNT: 'DISCONNECT_CALENDAR_ACCOUNT',
  DISCONNECT_CALENDAR_ACCOUNT_SUCCESS: 'DISCONNECT_CALENDAR_ACCOUNT_SUCCESS',
  DISCONNECT_CALENDAR_ACCOUNT_FAILURE: 'DISCONNECT_CALENDAR_ACCOUNT_FAILURE',
  OUTLOOK_ACCOUNT_OAUTH: 'OUTLOOK_ACCOUNT_OAUTH',
  UPDATE_PRIMARY_CALENDAR_ACCOUNT: 'UPDATE_PRIMARY_CALENDAR_ACCOUNT',
  UPDATE_PRIMARY_CALENDAR_ACCOUNT_SUCCESS:
    'UPDATE_PRIMARY_CALENDAR_ACCOUNT_SUCCESS',
  FETCH_CALENDAR_ACCOUNT_DETAILS: 'FETCH_CALENDAR_ACCOUNT_DETAILS',
  FETCH_CALENDAR_ACCOUNT_DETAILS_SUCCESS:
    'FETCH_CALENDAR_ACCOUNT_DETAILS_SUCCESS',
  FETCH_UNAVAILABILITIES: 'FETCH_UNAVAILABILITIES',
  FETCH_UNAVAILABILITIES_SUCCESS: 'FETCH_UNAVAILABILITIES_SUCCESS',
  FETCH_UNAVAILABILITIES_FAILURE: 'FETCH_UNAVAILABILITIES_FAILURE',
  UPDATE_UNAVAILABILITIES: 'UPDATE_UNAVAILABILITIES',
  UPDATE_UNAVAILABILITIES_SUCCESS: 'UPDATE_UNAVAILABILITIES_SUCCESS',
  UPDATE_AVAILABLE_CALENDARS: 'UPDATE_AVAILABLE_CALENDARS',
  UPDATE_AVAILABLE_CALENDARS_SUCCESS: 'UPDATE_AVAILABLE_CALENDARS_SUCCESS',
  // TODO: after
  // CLICK_TAG_LINK: 'CLICK_TAG_LINK',
  FETCH_GENERAL_KB_SETTINGS: 'FETCH_GENERAL_KB_SETTINGS',
  FETCH_KB_SETTINGS: 'FETCH_KB_SETTINGS',
  UPDATE_KB_SETTINGS: 'UPDATE_KB_SETTINGS',
  FETCH_KB_SETTINGS_SUCCESS: 'FETCH_KB_SETTINGS_SUCCESS',
  UPDATE_DEFAULT_MEETING_LINK: 'UPDATE_DEFAULT_MEETING_LINK',
  FETCH_KB_LANGUAGE_LIST: 'FETCH_KB_LANGUAGE_LIST',
  FETCH_KB_COLORS_LIST: 'FETCH_KB_COLORS_LIST',
  KB_CUSTOM_DOMAIN_UPDATE: 'KB_CUSTOM_DOMAIN_UPDATE',
  KB_CUSTOM_DOMAIN_UPDATE_SUCCESS: 'KB_CUSTOM_DOMAIN_UPDATE_SUCCESS',
  FETCH_KB_LANGUAGE_LIST_SUCCESS: 'FETCH_KB_LANGUAGE_LIST_SUCCESS',
  FETCH_KB_COLORS_LIST_SUCCESS: 'FETCH_KB_COLORS_LIST_SUCCESS',
  KB_CUSTOM_DOMAIN_UPDATE_FAILURE: 'KB_CUSTOM_DOMAIN_UPDATE_FAILURE',
  KB_CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE:
    'KB_CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE',
  SET_CONNECT_DOMAIN_TAB_KEY: 'SET_CONNECT_DOMAIN_TAB_KEY',
  FETCH_BLOCKED_IP: 'FETCH_BLOCKED_IP',
  FETCH_BLOCKED_IP_SUCCESS: 'FETCH_BLOCKED_IP_SUCCESS',
  DELETE_BLOCKED_IP: 'DELETE_BLOCKED_IP',
  APPEND_BLOCKED_USERS_OR_IP: 'APPEND_BLOCKED_USERS_OR_IP',
  APPEND_BLOCKED_IP: 'APPEND_BLOCKED_IP',
  APPEND_BLOCKED_IP_SUCCESS: 'APPEND_BLOCKED_IP_SUCCESS',
  FETCH_BLOCKED_USERS: 'FETCH_BLOCKED_USERS',
  FETCH_BLOCKED_USERS_SUCCESS: 'FETCH_BLOCKED_USERS_SUCCESS',
  DELETE_BLOCKED_USER: 'DELETE_BLOCKED_USER',
  APPEND_BLOCKED_USERS: 'APPEND_BLOCKED_USERS',
  APPEND_BLOCKED_USERS_SUCCESS: 'APPEND_BLOCKED_USERS_SUCCESS',
  FETCH_ADVANCED_TAB: 'FETCH_ADVANCED_TAB',
  FETCH_ADVANCED_TAB_SUCCESS: 'FETCH_ADVANCED_TAB_SUCCESS',
  UPDATE_ADVANCED_TAB: 'UPDATE_ADVANCED_TAB',
  FETCH_TEMPLATE_LIST: 'FETCH_TEMPLATE_LIST',
  FETCH_TEMPLATE_LIST_SUCCESS: 'FETCH_TEMPLATE_LIST_SUCCESS',
  FETCH_TEMPLATE_LIST_FAILURE: 'FETCH_TEMPLATE_LIST_FAILURE',
  FETCH_VISUAL_BUILDER_TEMPLATE_LIST_SUCCESS:
    'FETCH_VISUAL_BUILDER_TEMPLATE_LIST_SUCCESS',
  FETCH_VISUAL_BUILDER_TEMPLATE_LIST_FAILURE:
    'FETCH_VISUAL_BUILDER_TEMPLATE_LIST_FAILURE',
  SET_CURRENT_TEMPLATE_TAB_KEY: 'SET_CURRENT_TEMPLATE_TAB_KEY',
  SET_VISUAL_BUILDER_CUSTOM_TEMPLATE_DATA:
    'SET_VISUAL_BUILDER_CUSTOM_TEMPLATE_DATA',
  UPDATE_DEFAULT_TEMPLATE: 'UPDATE_DEFAULT_TEMPLATE',
  UPDATE_DEFAULT_TEMPLATE_SUCCESS: 'UPDATE_DEFAULT_TEMPLATE_SUCCESS',
  UPDATE_DEFAULT_TEMPLATE_FAILURE: 'UPDATE_DEFAULT_TEMPLATE_FAILURE',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  SET_EMAIL_TEMPLATE_NAME: 'SET_EMAIL_TEMPLATE_NAME',
  SET_VISUAL_BUILDER_TEMPLATE_NAME: 'SET_VISUAL_BUILDER_TEMPLATE_NAME',
  FETCH_TEMPLATE: 'FETCH_TEMPLATE',
  FETCH_TEMPLATE_SUCCESS: 'FETCH_TEMPLATE_SUCCESS',
  FETCH_TEMPLATE_FAILURE: 'FETCH_TEMPLATE_FAILURE',
  SET_EDITOR_CODE: 'SET_EDITOR_CODE',
  CREATE_EMAIL_TEMPLATE: 'CREATE_EMAIL_TEMPLATE',
  CREATE_EMAIL_TEMPLATE_SUCCESS: 'CREATE_EMAIL_TEMPLATE_SUCCESS',
  CREATE_EMAIL_TEMPLATE_FAILURE: 'CREATE_EMAIL_TEMPLATE_FAILURE',
  UPDATE_EMAIL_TEMPLATE: 'UPDATE_EMAIL_TEMPLATE',
  UPDATE_EMAIL_TEMPLATE_SUCCESS: 'UPDATE_EMAIL_TEMPLATE_SUCCESS',
  UPDATE_EMAIL_TEMPLATE_FAILURE: 'UPDATE_EMAIL_TEMPLATE_FAILURE',
  DELETE_EMAIL_TEMPLATE: 'DELETE_EMAIL_TEMPLATE',
  DELETE_EMAIL_TEMPLATE_SUCCESS: 'DELETE_EMAIL_TEMPLATE_SUCCESS',
  DELETE_EMAIL_TEMPLATE_FAILURE: 'DELETE_EMAIL_TEMPLATE_FAILURE',
  RESET_EMAIL_TEMPLATE_EDITOR: 'RESET_EMAIL_TEMPLATE_EDITOR',
  SET_ADD_CARD_DRAWER_STATUS: 'SET_ADD_CARD_DRAWER_STATUS',
  GET_INVOICE_DETAIL: 'GET_INVOICE_DETAIL',
  GET_INVOICE_DETAIL_SUCCESS: 'GET_INVOICE_DETAIL_SUCCESS',
  GET_INVOICE_DETAIL_FAILURE: 'GET_INVOICE_DETAIL_FAILURE',
  GET_APIKEY_DETAIL: 'GET_APIKEY_DETAIL',
  GET_APIKEY_DETAIL_SUCCESS: 'GET_APIKEY_DETAIL_SUCCESS',
  GET_PAYMENT_CARD_LIST: 'GET_PAYMENT_CARD_LIST',
  GET_PAYMENT_CARD_LIST_SUCCESS: 'GET_PAYMENT_CARD_LIST_SUCCESS',
  GET_CURRENT_PLAN_DETAIL: 'GET_CURRENT_PLAN_DETAIL',
  GET_CURRENT_PLAN_DETAIL_SUCCESS: 'GET_CURRENT_PLAN_DETAIL_SUCCESS',
  OUT_BOUND_DRAWER_VISIBLE: 'OUT_BOUND_DRAWER_VISIBLE',
  DOMAIN_VERIFY_DRAWER_VISIBLE: 'DOMAIN_VERIFY_DRAWER_VISIBLE',
  GET_SHARED_TEAM_EMAIL: 'GET_SHARED_TEAM_EMAIL',
  GET_SHARED_TEAM_EMAIL_SUCCESS: 'GET_SHARED_TEAM_EMAIL_SUCCESS',
  SET_ADD_EMAIL_DRAWER_STATUS: 'SET_ADD_EMAIL_DRAWER_STATUS',
  CREATE_SHARED_EMAIL_ADDRESS: 'CREATE_SHARED_EMAIL_ADDRESS',
  CREATE_SHARED_EMAIL_ADDRESS_SUCCESS: 'CREATE_SHARED_EMAIL_ADDRESS_SUCCESS',
  DELETE_SHARED_EMAIL_ADDRESS: 'DELETE_SHARED_EMAIL_ADDRESS',
  DELETE_SHARED_EMAIL_ADDRESS_SUCCESS: 'DELETE_SHARED_EMAIL_ADDRESS_SUCCESS',
  FETCH_EMAIL_SENDING_DOMAIN_LIST: 'FETCH_EMAIL_SENDING_DOMAIN_LIST',
  FETCH_EMAIL_SENDING_DOMAIN_LIST_SUCCESS:
    'FETCH_EMAIL_SENDING_DOMAIN_LIST_SUCCESS',
  RESEND_EMAIL_SENDING_DOMAIN: 'RESEND_EMAIL_SENDING_DOMAIN',
  CREATE_EMAIL_SEND_DOMAIN: 'CREATE_EMAIL_SEND_DOMAIN',
  SET_EMAIL_SENDING_DETAILS: 'SET_EMAIL_SENDING_DETAILS',
  UPDATE_EMAIL_SEND_DOMAIN: 'UPDATE_EMAIL_SEND_DOMAIN',
  SET_SHARED_EMAIL_DATA: 'SET_SHARED_EMAIL_DATA',
  EDIT_SHARED_EMAIL_ADDRESS: 'EDIT_SHARED_EMAIL_ADDRESS',
  EDIT_SHARED_EMAIL_ADDRESS_SUCCESS: 'EDIT_SHARED_EMAIL_ADDRESS_SUCCESS',
  DELETE_EMAIL_SENDING_DOMAIN_EMAIL: 'DELETE_EMAIL_SENDING_DOMAIN_EMAIL',
  DELETE_EMAIL_SENDING_DOMAIN_EMAIL_SUCCESS:
    'DELETE_EMAIL_SENDING_DOMAIN_EMAIL_SUCCESS',
  DISABLE_EMAIL_SEND_LOADING: 'DISABLE_EMAIL_SEND_LOADING',
  UPDATE_EMAIL_SEND_DOMAIN_SUCCESS: 'UPDATE_EMAIL_SEND_DOMAIN_SUCCESS',
  VERIFY_EMAIL_SENDING_DOMAIN_PRELIM: 'VERIFY_EMAIL_SENDING_DOMAIN_PRELIM',
  VERIFY_EMAIL_SENDING_DOMAIN_PRELIM_SUCCESS:
    'VERIFY_EMAIL_SENDING_DOMAIN_PRELIM_SUCCESS',
  VERIFY_EMAIL_SENDING_DOMAIN: 'VERIFY_EMAIL_SENDING_DOMAIN',
  VERIFY_EMAIL_SENDING_DOMAIN_SUCCESS: 'VERIFY_EMAIL_SENDING_DOMAIN_SUCCESS',
  VERIFY_EMAIL_SENDING_DOMAIN_FAILURE: 'VERIFY_EMAIL_SENDING_DOMAIN_FAILURE',
  SET_IMAGE_URL: 'SET_IMAGE_URL',
  GET_BILLING_DETAIL: 'GET_BILLING_DETAIL',
  GET_BILLING_DETAIL_SUCCESS: 'GET_BILLING_DETAIL_SUCCESS',
  UPDATE_BILLING_DETAIL: 'UPDATE_BILLING_DETAIL',
  UPDATE_BILLING_DETAIL_SUCCESS: 'UPDATE_BILLING_DETAIL_SUCCESS',
  ADD_CARD_DETAIL: 'ADD_CARD_DETAIL',
  ADD_CARD_DETAIL_SUCCESS: 'ADD_CARD_DETAIL_SUCCESS',
  REMOVE_CARD_DETAIL: 'REMOVE_CARD_DETAIL',
  REMOVE_CARD_DETAIL_SUCCESS: 'REMOVE_CARD_DETAIL_SUCCESS',
  MAKE_DEFAULT_CARD: 'MAKE_DEFAULT_CARD',
  MAKE_DEFAULT_CARD_SUCCESS: 'MAKE_DEFAULT_CARD_SUCCESS',
  PAY_DUE_MODAL_STATUS: 'PAY_DUE_MODAL_STATUS',
  PAY_DUE: 'PAY_DUE',
  PAY_DUE_SUCCESS: 'PAY_DUE_SUCCESS',
  DELETE_WORKSPACE: 'DELETE_WORKSPACE',
  SET_WORKSPACE_DEFAULT_TAB_KEY: 'SET_WORKSPACE_DEFAULT_TAB_KEY',
  SET_TEAMS_ROLES_DEFAULT_TAB_KEY: 'SET_TEAMS_ROLES_DEFAULT_TAB_KEY',
  SET_ROLES_DRAWER_DEFAULT_TAB_KEY: 'SET_ROLES_DRAWER_DEFAULT_TAB_KEY',
  SET_PROFILE_IMAGE_URL: 'SET_PROFILE_IMAGE_URL',
  SET_COVER_IMAGE_URL: 'SET_COVER_IMAGE_URL',
  GET_ELIGIBLE_PLAN_DETAIL: 'GET_ELIGIBLE_PLAN_DETAIL',
  GET_ELIGIBLE_PLAN_DETAIL_SUCCESS: 'GET_ELIGIBLE_PLAN_DETAIL_SUCCESS',
  UPGRATE_PLAN: 'UPGRATE_PLAN',
  UPGRATE_PLAN_SUCCESS: 'UPGRATE_PLAN_SUCCESS',
  GET_GDPR: 'GET_GDPR',
  GET_GDPR_SUCCESS: 'GET_GDPR_SUCCESS',
  UPDATE_GDPR: 'UPDATE_GDPR',

  PRO_TRAIL_SUBSCRIPTION: 'PRO_TRAIL_SUBSCRIPTION',
  FETCH_FORMS: 'FETCH_FORMS',
  FETCH_FORMS_SUCCESS: 'FETCH_FORMS_SUCCESS',
  UPDATE_FORMS: 'UPDATE_FORMS',
  UPDATE_FORMS_SUCCESS: 'UPDATE_FORMS_SUCCESS',
  FETCH_CUSTOM_FIELDS_LIST: 'FETCH_CUSTOM_FIELDS_LIST',
  FETCH_CUSTOM_FIELDS_LIST_SUCCESS: 'FETCH_CUSTOM_FIELDS_LIST_SUCCESS',
  CHANGE_SUPPORT_ACCESS: 'CHANGE_SUPPORT_ACCESS',
  UPDATE_KB_SUB_DOMAIN: 'UPDATE_KB_SUB_DOMAIN',
  BOT_DATA_CHANGE: 'BOT_DATA_CHANGE',
  FETCH_BOT_IDENTITY: 'FETCH_BOT_IDENTITY',
  FETCH_BOT_IDENTITY_SUCCESS: 'FETCH_BOT_IDENTITY_SUCCESS',
  UPDATE_BOT_IDENTITY: 'UPDATE_BOT_IDENTITY',
  UPDATE_MAGIC_TYPE_SETTINGS: 'UPDATE_MAGIC_TYPE_SETTINGS',
  DRAWER_VISIBILITY: 'DRAWER_VISIBILITY',
  DRAWER_VISIBILITY_TRANSFER_OWNERSHIP: 'DRAWER_VISIBILITY_TRANSFER_OWNERSHIP',
  FETCH_CURRENT_CONTACT_TAGS: 'FETCH_CURRENT_CONTACT_TAGS',
  FETCH_CURRENT_ACTION_CONTACT_TAGS: 'FETCH_CURRENT_ACTION_CONTACT_TAGS',
  FETCH_CURRENT_TRIGGER_CONTACT_TAGS: 'FETCH_CURRENT_TRIGGER_CONTACT_TAGS',
  DELETING_TEAM_PROCESS: 'DELETING_TEAM_PROCESS',
  CREATE_AND_UPDATE_PROPERTY_GROUP: 'CREATE_AND_UPDATE_PROPERTY_GROUP',
  CREATE_AND_UPDATE_PROPERTY_GROUP_SUCCESS:
    'CREATE_AND_UPDATE_PROPERTY_GROUP_SUCCESS',
  DELETE_PROPERTY_GROUP: 'DELETE_PROPERTY_GROUP',
  DRAWER_VISIBILITY_PROPERTY_GROUP: 'DRAWER_VISIBILITY_PROPERTY_GROUP',
  DRAWER_VISIBILITY_PROPERTY: 'DRAWER_VISIBILITY_PROPERTY',
  SET_SYNC_PROGRESS: 'SET_SYNC_PROGRESS',
  EMAIL_SETTINGS: 'EMAIL_SETTINGS',
  EMAIL_SUNSET_POLICY_SUCCESS: 'EMAIL_SUNSET_POLICY_SUCCESS',
  UPDATE_EMAIL_SETTINGS: 'UPDATE_EMAIL_SETTINGS',
  CONTACT_TAG_LOADER_TRIGGER: 'CONTACT_TAG_LOADER_TRIGGER',
  TRIGGER_TAG_LOADER: 'TRIGGER_TAG_LOADER',
  ACTION_TAG_LOADER: 'ACTION_TAG_LOADER',
  CONTACTS_TAG_LOADER: 'CONTACTS_TAG_LOADER',
  SET_ADVANCED_SATISFACTION_RATING_CONTENT:
    'SET_ADVANCED_SATISFACTION_RATING_CONTENT',
  SET_SATISFACTION_RATINGS_SETTINGS_LOADER:
    'SET_SATISFACTION_RATINGS_SETTINGS_LOADER',
  UPDATE_ADVANCED_TAB_SUCCESS: 'UPDATE_ADVANCED_TAB_SUCCESS',
  SET_SETTINGS_FORM_TAB_KEY: 'SET_SETTINGS_FORM_TAB_KEY',
  FETCH_REFERRER_ID: 'FETCH_REFERRER_ID',
  FETCH_REFERRER_ID_SUCCESS: 'FETCH_REFERRER_ID_SUCCESS',
  CONFIRMATION_SETTINGS_FORM_UPDATE: 'CONFIRMATION_SETTINGS_FORM_UPDATE',
  CONFIRMATION_SETTINGS_FORM_UPDATE_SUCCESS:
    'CONFIRMATION_SETTINGS_FORM_UPDATE_SUCCESS',
  CONFIRMATION_SETTINGS_FORM_UPDATE_FAILURE:
    'CONFIRMATION_SETTINGS_FORM_UPDATE_FAILURE',
  UPDATE_MEETINGS_PROFILE_DETAILS_SUCCESS:
    'UPDATE_MEETINGS_PROFILE_DETAILS_SUCCESS',
  SET_GREETING_TEXT: 'SET_GREETING_TEXT',
  SETTINGS_FETCH_TEMPLATES: 'SETTINGS_FETCH_TEMPLATES',
  SETTINGS_FETCH_TEMPLATES_SUCCESS: 'SETTINGS_FETCH_TEMPLATES_SUCCESS',
  UPDATE_SETTINGS_FORM_DATA: 'UPDATE_SETTINGS_FORM_DATA',
  UPDATE_WOO_COMMERCE_BUTTON_LOADER: 'UPDATE_WOO_COMMERCE_BUTTON_LOADER',
  UPDATE_WOO_COMMERCE_SYNC_BUTTON_LOADER: 'UPDATE_WOO_COMMERCE_SYNC_BUTTON_LOADER',
  SETTINGS_GET_PIPELINE_DETAIL: 'SETTINGS_GET_PIPELINE_DETAIL',
  SETTINGS_GET_PIPELINE_DETAIL_SUCCESS: 'SETTINGS_GET_PIPELINE_DETAIL_SUCCESS',
  SETTINGS_GET_PIPELINE_DETAIL_FAILURE: 'SETTINGS_GET_PIPELINE_DETAIL_FAILURE',
  SETTINGS_SET_PIPELINE_ID: 'SETTINGS_SET_PIPELINE_ID',
  SETTINGS_GET_STAGES: 'SETTINGS_GET_STAGES',
  SETTINGS_GET_STAGES_SUCCESS: 'SETTINGS_GET_STAGES_SUCCESS',
  SETTINGS_GET_STAGES_FAILURE: 'SETTINGS_GET_STAGES_FAILURE',
  SETTINGS_CREATE_PIPELINE: 'SETTINGS_CREATE_PIPELINE',
  SETTINGS_CREATE_PIPELINE_SUCCESS: 'SETTINGS_CREATE_PIPELINE_SUCCESS',
  SETTINGS_CREATE_PIPELINE_FAILURE: 'SETTINGS_CREATE_PIPELINE_FAILURE',
  SETTINGS_UPDATE_PIPELINE: 'SETTINGS_UPDATE_PIPELINE',
  SETTINGS_UPDATE_PIPELINE_SUCCESS: 'SETTINGS_UPDATE_PIPELINE_SUCCESS',
  SETTINGS_UPDATE_PIPELINE_FAILURE: 'SETTINGS_UPDATE_PIPELINE_FAILURE',
  SETTINGS_DELETE_PIPELINE: 'SETTINGS_DELETE_PIPELINE',
  SETTINGS_DELETE_PIPELINE_SUCCESS: 'SETTINGS_DELETE_PIPELINE_SUCCESS',
  SETTINGS_DELETE_PIPELINE_FAILURE: 'SETTINGS_DELETE_PIPELINE_FAILURE',
  SETTINGS_UPDATE_PIPELINE_DRAWER_STATUS:
    'SETTINGS_UPDATE_PIPELINE_DRAWER_STATUS',
  SETTINGS_CREATE_STAGE: 'SETTINGS_CREATE_STAGE',
  SETTINGS_CREATE_STAGE_SUCCESS: 'SETTINGS_CREATE_STAGE_SUCCESS',
  SETTINGS_CREATE_STAGE_FAILURE: 'SETTINGS_CREATE_STAGE_FAILURE',
  SETTINGS_UPDATE_STAGE: 'SETTINGS_UPDATE_STAGE',
  SETTINGS_UPDATE_STAGE_SUCCESS: 'SETTINGS_UPDATE_STAGE_SUCCESS',
  SETTINGS_UPDATE_STAGE_FAILURE: 'SETTINGS_UPDATE_STAGE_FAILURE',
  SETTINGS_UPDATE_STAGE_DRAWER_STATUS: 'SETTINGS_UPDATE_STAGE_DRAWER_STATUS',
  SETTINGS_DELETE_STAGE: 'SETTINGS_DELETE_STAGE',
  SETTINGS_DELETE_STAGE_SUCCESS: 'SETTINGS_DELETE_STAGE_SUCCESS',
  SETTINGS_DELETE_STAGE_FAILURE: 'SETTINGS_DELETE_STAGE_FAILURE',
  SETTINGS_SET_STAGES: 'SETTINGS_SET_STAGES',
  SETTINGS_UPDATE_STAGES_POSITION: 'SETTINGS_UPDATE_STAGES_POSITION',
  SETTINGS_GET_DEAL_PROPERTIES: 'SETTINGS_GET_DEAL_PROPERTIES',
  SETTINGS_GET_DEAL_PROPERTIES_SUCCESS: 'SETTINGS_GET_DEAL_PROPERTIES_SUCCESS',
  SETTINGS_GET_DEAL_PROPERTIES_FAILURE: 'SETTINGS_GET_DEAL_PROPERTIES_FAILURE',
  SETTINGS_CREATE_DEAL_PROPERTY: 'SETTINGS_CREATE_DEAL_PROPERTY',
  SETTINGS_CREATE_DEAL_PROPERTY_SUCCESS:
    'SETTINGS_CREATE_DEAL_PROPERTY_SUCCESS',
  SETTINGS_CREATE_DEAL_PROPERTY_FAILURE:
    'SETTINGS_CREATE_DEAL_PROPERTY_FAILURE',
  SETTINGS_UPDATE_DEAL_PROPERTY: 'SETTINGS_UPDATE_DEAL_PROPERTY',
  SETTINGS_UPDATE_DEAL_PROPERTY_SUCCESS:
    'SETTINGS_UPDATE_DEAL_PROPERTY_SUCCESS',
  SETTINGS_UPDATE_DEAL_PROPERTY_FAILURE:
    'SETTINGS_UPDATE_DEAL_PROPERTY_FAILURE',
  SETTINGS_DELETE_DEAL_PROPERTY: 'SETTINGS_DELETE_DEAL_PROPERTY',
  SETTINGS_DELETE_DEAL_PROPERTY_SUCCESS:
    'SETTINGS_DELETE_DEAL_PROPERTY_SUCCESS',
  SETTINGS_DELETE_DEAL_PROPERTY_FAILURE:
    'SETTINGS_DELETE_DEAL_PROPERTY_FAILURE',
  SETTINGS_SET_DEAL_PROPERTIES_SEARCH_VALUE:
    'SETTINGS_SET_DEAL_PROPERTIES_SEARCH_VALUE',
  SETTINGS_APPEND_DEAL_PROPERTIES: 'SETTINGS_APPEND_DEAL_PROPERTIES',
  SETTINGS_APPEND_DEAL_PROPERTIES_SUCCESS:
    'SETTINGS_APPEND_DEAL_PROPERTIES_SUCCESS',
  SETTINGS_APPEND_DEAL_PROPERTIES_FAILURE:
    'SETTINGS_APPEND_DEAL_PROPERTIES_FAILURE',
  SETTINGS_GET_COMPANY_TRACKING_STATUS: 'SETTINGS_GET_COMPANY_TRACKING_STATUS',
  SETTINGS_GET_COMPANY_TRACKING_STATUS_SUCCESS:
    'SETTINGS_GET_COMPANY_TRACKING_STATUS_SUCCESS',
  SETTINGS_GET_COMPANY_TRACKING_STATUS_FAILURE:
    'SETTINGS_GET_COMPANY_TRACKING_STATUS_FAILURE',
  SETTINGS_UPDATE_COMPANY_TRACKING_STATUS:
    'SETTINGS_UPDATE_COMPANY_TRACKING_STATUS',
  SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_SUCCESS:
    'SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_SUCCESS',
  SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_FAILURE:
    'SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_FAILURE',
  SETTINGS_UPDATE_THEME: 'SETTINGS_UPDATE_THEME',
  SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK: 'SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK',

  // WEBHOOKS
  GET_WEBHOOKS_LIST: 'GET_WEBHOOKS_LIST',
  GET_WEBHOOKS_LIST_SUCCESS: 'GET_WEBHOOKS_LIST_SUCCESS',
  GET_SINGLE_WEBHOOK_DATA: 'GET_SINGLE_WEBHOOK_DATA',
  GET_SINGLE_WEBHOOK_DATA_SUCCESS: 'GET_SINGLE_WEBHOOK_DATA_SUCCESS',
  GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA:
    'GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA',
  GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA_SUCCESS:
    'GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA_SUCCESS',
  UPDATE_SELECTED_WEBHOOK_REDUCER_DATA: 'UPDATE_SELECTED_WEBHOOK_REDUCER_DATA',
  UPDATE_SINGLE_WEBHOOK_DATA: 'UPDATE_SINGLE_WEBHOOK_DATA',
  UPDATE_SINGLE_WEBHOOK_DATA_SUCCESS: 'UPDATE_SINGLE_WEBHOOK_DATA_SUCCESS',
  UPDATE_SINGLE_WEBHOOK_STATUS: 'UPDATE_SINGLE_WEBHOOK_STATUS',
  CREATE_SINGLE_WEBHOOK: 'CREATE_SINGLE_WEBHOOK',
  CREATE_SINGLE_WEBHOOK_SUCCESS: 'CREATE_SINGLE_WEBHOOK_SUCCESS',
  DELETE_SINGLE_WEBHOOK: 'DELETE_SINGLE_WEBHOOK',
  DELETE_SINGLE_WEBHOOK_SUCCESS: 'DELETE_SINGLE_WEBHOOK_SUCCESS',
  SEND_SAMPLE_WEBHOOOK: 'SEND_SAMPLE_WEBHOOOK',
  GET_MAIL_SUBSCRIPTION_DATA: 'GET_MAIL_SUBSCRIPTION_DATA',
  GET_MAIL_SUBSCRIPTION_DATA_SUCCESS: 'GET_MAIL_SUBSCRIPTION_DATA_SUCCESS',
  CREATE_MAIL_SUBSCRIPTION: 'CREATE_MAIL_SUBSCRIPTION',
  DELETE_MAIL_SUBSCRIPTION: 'DELETE_MAIL_SUBSCRIPTION',
  UPDATE_MAIL_SUBSCRIPTION: 'UPDATE_MAIL_SUBSCRIPTION',
  SET_MAIL_SUBSCRIPTION_DETAILS: 'SET_MAIL_SUBSCRIPTION_DETAILS',
  MAIL_SUBSCRIPTION_DRAWER_VISIBLE: 'MAIL_SUBSCRIPTION_DRAWER_VISIBLE',
  CHANGE_SUBSCRIPTION_TYPE_POSITION: 'CHANGE_SUBSCRIPTION_TYPE_POSITION',
  DEFAULT_CONTACT_SUBSCRIPTION_TYPES: 'DEFAULT_CONTACT_SUBSCRIPTION_TYPES',
  EMAIL_SETTINGS_SWITCH_LOADER: 'EMAIL_SETTINGS_SWITCH_LOADER',
  FETCH_CONVERSATION_PROPERTIES: 'FETCH_CONVERSATION_PROPERTIES',
  FETCH_CONVERSATION_PROPERTIES_SUCCESS: 'FETCH_CONVERSATION_PROPERTIES_SUCCESS',
  FETCH_CONVERSATION_PROPERTIES_FAILURE: 'FETCH_CONVERSATION_PROPERTIES_FAILURE',
  DRAWER_VISIBILITY_CONVERSATION_PROPERTY: 'DRAWER_VISIBILITY_CONVERSATION_PROPERTY',
  CREATE_CONVERSATION_PROPERTY: 'CREATE_CONVERSATION_PROPERTY',
  UPDATE_CONVERSATION_PROPERTY: 'UPDATE_CONVERSATION_PROPERTY',
  DELETE_CONVERSATION_PROPERTY: 'DELETE_CONVERSATION_PROPERTY',
  SETTINGS_CONVERSATION_PROPERTIES_SEARCH: 'SETTINGS_CONVERSATION_PROPERTIES_SEARCH',
  GET_INDIVIDUAL_CONVERSATION_PROPERTIES: 'GET_INDIVIDUAL_CONVERSATION_PROPERTIES',
  GET_INDIVIDUAL_CONVERSATION_PROPERTIES_SUCCESS: 'GET_INDIVIDUAL_CONVERSATION_PROPERTIES_SUCCESS',
  UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY: 'UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY',
  UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY_SUCCESS:
    'UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY_SUCCESS',
  SET_CONVERSATION_PROPERTY_LISTS: 'SET_CONVERSATION_PROPERTY_LISTS',
  FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS:
    'FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS',
  FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS_SUCCESS:
    'FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS_SUCCESS',
  UPDATE_CONVERSATION_PROPERTIES_FROM_SOCKET:
    'UPDATE_CONVERSATION_PROPERTIES_FROM_SOCKET',
  SET_SNIPPET_FOLDER_DRAWER_VISIBILITY: 'SET_SNIPPET_FOLDER_Drawer_VISIBILITY',
  UPDATE_SNIPPET_DRAWER_DATA_LOADER: 'UPDATE_SNIPPET_DRAWER_DATA_LOADER',
  CONVERSATION_TAG_LOADER: 'CONVERSATION_TAG_LOADER',
  FETCH_HUBSPOT_PROPERTIES: 'FETCH_HUBSPOT_PROPERTIES',
  FETCH_HUBSPOT_PROPERTIES_SUCCESS: 'FETCH_HUBSPOT_PROPERTIES_SUCCESS',
  FETCH_HUBSPOT_PROPERTIES_FAILURE: 'FETCH_HUBSPOT_PROPERTIES_FAILURE',
  SAVE_HUBSPOT_MAPPING_PROPERITIES: 'SAVE_HUBSPOT_MAPPING_PROPERITIES',
  SAVE_HUBSPOT_MAPPING_PROPERITIES_SUCCESS:
    'SAVE_HUBSPOT_MAPPING_PROPERITIES_SUCCESS',
  SAVE_HUBSPOT_MAPPING_PROPERITIES_FAILURE:
    'SAVE_HUBSPOT_MAPPING_PROPERITIES_FAILURE',
  SYNC_HUBSPOT_HISTORY_DATA: 'SYNC_HUBSPOT_HISTORY_DATA',
  SYNC_HUBSPOT_HISTORY_DATA_SUCCESS: 'SYNC_HUBSPOT_HISTORY_DATA_SUCCESS',
  SYNC_HUBSPOT_HISTORY_DATA_FAILURE: 'SYNC_HUBSPOT_HISTORY_DATA_FAILURE',
  UPDATE_AUTO_SYNC_CONTACT: 'UPDATE_AUTO_SYNC_CONTACT',
  GET_MESSENGER_APP_PREFERENCES: 'GET_MESSENGER_APP_PREFERENCES',
  GET_MESSENGER_APP_PREFERENCES_SUCCESS:
    'GET_MESSENGER_APP_PREFERENCES_SUCCESS',
  GET_MESSENGER_APP_PREFERENCES_FAILURE:
    'GET_MESSENGER_APP_PREFERENCES_FAILURE',
  UPDATE_MESSENGER_APP_PREFERENCES: 'UPDATE_MESSENGER_APP_PREFERENCES',
  UPDATE_MESSENGER_APP_PREFERENCES_SUCCESS:
    'UPDATE_MESSENGER_APP_PREFERENCES_SUCCESS',
  UPDATE_MESSENGER_APP_PREFERENCES_FAILURE:
    'UPDATE_MESSENGER_APP_PREFERENCES_FAILURE',
  UPDATE_MESSENGER_APP_DISPLAY_CONDITION_MODAL:
    'UPDATE_MESSENGER_APP_DISPLAY_CONDITION_MODAL',
  SETTINGS_COUNTRY: 'SETTINGS_COUNTRY',
  SETTINGS_COUNTRY_SUCCESS: 'SETTINGS_COUNTRY_SUCCESS',
  GET_SPAM_FILTERS_DATA: 'GET_SPAM_FILTERS_DATA',
  GET_SPAM_FILTERS_DATA_SUCCESS: 'GET_SPAM_FILTERS_DATA_SUCCESS',
  GET_SPAM_FILTERS_DATA_FAILURE: 'GET_SPAM_FILTERS_DATA_FAILURE',
  UPDATE_SPAM_FILTERS_DATA: 'UPDATE_SPAM_FILTERS_DATA',
  UPDATE_SPAM_FILTERS_DATA_SUCCESS: 'UPDATE_SPAM_FILTERS_DATA_SUCCESS',
  UPDATE_SPAM_FILTERS_DATA_FAILURE: 'UPDATE_SPAM_FILTERS_DATA_FAILURE',
  SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_SUCCESS:
    'SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_SUCCESS',
  SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_FAILURE:
    'SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_FAILURE',
  FETCH_BOT_IDENTITY_FAILURE: 'FETCH_BOT_IDENTITY_FAILURE',
  FETCH_LIVE_BOT_LIST: 'FETCH_LIVE_BOT_LIST',
  FETCH_LIVE_BOT_LIST_SUCCESS: 'FETCH_LIVE_BOT_LIST_SUCCESS',
  FETCH_LIVE_BOT_LIST_FAILURE: 'FETCH_LIVE_BOT_LIST_FAILURE',
  UPDATE_SETTINGS_SUPPORT_BOT: 'UPDATE_SETTINGS_SUPPORT_BOT',
  UPDATE_SETTINGS_SUPPORT_BOT_SUCCESS: 'UPDATE_SETTINGS_SUPPORT_BOT_SUCCESS',
  UPDATE_SETTINGS_SUPPORT_BOT_FAILURE: 'UPDATE_SETTINGS_SUPPORT_BOT_FAILURE',

  //KB Themes
  FETCH_KB_THEMES_LIST: 'FETCH_KB_THEMES_LIST',
  FETCH_KB_THEMES_LIST_SUCCESS: 'FETCH_KB_THEMES_LIST_SUCCESS',
  FETCH_KB_THEMES_LIST_FAILURE: 'FETCH_KB_THEMES_LIST_FAILURE',
  DELETE_KB_THEME: 'DELETE_KB_THEME',
  DUPLICATE_KB_THEME_DRAWER_STATUS: 'DUPLICATE_KB_THEME_DRAWER_STATUS',
  DUPLICATE_KB_THEME: 'DUPLICATE_KB_THEME',
  DUPLICATE_KB_THEME_SUCCESS: 'DUPLICATE_KB_THEME_SUCCESS',
  DUPLICATE_KB_THEME_FAILURE: 'DUPLICATE_KB_THEME_FAILURE',
  SET_ACTIVE_KB_THEME: 'SET_ACTIVE_KB_THEME',
  SET_ACTIVE_KB_THEME_SUCCESS: 'SET_ACTIVE_KB_THEME_SUCCESS',
  FETCH_KB_THEME_DETAILS: 'FETCH_KB_THEME_DETAILS',
  FETCH_KB_THEME_DETAILS_SUCCESS: 'FETCH_KB_THEME_DETAILS_SUCCESS',
  FETCH_KB_THEME_DETAILS_FAILURE: 'FETCH_KB_THEME_DETAILS_FAILURE',
  FETCH_KB_LIQUID_FILE_DATA: 'FETCH_KB_LIQUID_FILE_DATA',
  FETCH_KB_LIQUID_FILE_DATA_SUCCESS: 'FETCH_KB_LIQUID_FILE_DATA_SUCCESS',
  FETCH_KB_LIQUID_FILE_DATA_FAILURE: 'FETCH_KB_LIQUID_FILE_DATA_FAILURE',
  GET_LIQUID_FILE_FROM_AVAILABLE_LIQUID_LIST:
    'GET_LIQUID_FILE_FROM_AVAILABLE_LIQUID_LIST',
  REMOVE_AVAILABLE_LIQUID_LIST: 'REMOVE_AVAILABLE_LIQUID_LIST',
  SAVE_DRAFT_KB_THEME_EDITOR_LIQUID: 'SAVE_DRAFT_KB_THEME_EDITOR_LIQUID',
  SAVE_DRAFT_KB_THEME_EDITOR_LIQUID_SUCCESS:
    'SAVE_DRAFT_KB_THEME_EDITOR_LIQUID_SUCCESS',
  PUBLISH_KB_THEME: 'PUBLISH_KB_THEME',
  UPDATE_EDITOR_LIQUID_CONTENT: 'UPDATE_EDITOR_LIQUID_CONTENT',
  RESET_KB_THEME_EDITOR_DETAILS: 'RESET_KB_THEME_EDITOR_DETAILS',
  REDEEM_COUPONS_SUBMIT: 'REDEEM_COUPONS_SUBMIT',
  UPDATE_PLAN_SUBSCRIPTION_USAGES: 'UPDATE_PLAN_SUBSCRIPTION_USAGES',
  FETCH_SLACK_NOTIFICATIONS: 'FETCH_SLACK_NOTIFICATIONS',
  FETCH_SLACK_NOTIFICATIONS_SUCCESS: 'FETCH_SLACK_NOTIFICATIONS_SUCCESS',
  FETCH_SLACK_NOTIFICATIONS_FAILURE: 'FETCH_SLACK_NOTIFICATIONS_FAILURE',
  FETCH_SLACK_CHANNELS_AND_EVENTS: 'FETCH_SLACK_CHANNELS_AND_EVENTS',
  FETCH_SLACK_CHANNELS_AND_EVENTS_SUCCESS:
    'FETCH_SLACK_CHANNELS_AND_EVENTS_SUCCESS',
  FETCH_SLACK_CHANNELS_AND_EVENTS_FAILURE:
    'FETCH_SLACK_CHANNELS_AND_EVENTS_FAILURE',
  SET_SLACK_NOTIFICATION_ADD_EDIT_FORM_ENABLE:
    'SET_SLACK_NOTIFICATION_ADD_EDIT_FORM_ENABLE',
  SAVE_SLACK_NOTIFICATION: 'SAVE_SLACK_NOTIFICATION',
  SAVE_SLACK_NOTIFICATION_SUCCESS: 'SAVE_SLACK_NOTIFICATION_SUCCESS',
  SAVE_SLACK_NOTIFICATION_FAILURE: 'SAVE_SLACK_NOTIFICATION_FAILURE',
  UPDATE_SLACK_NOTIFICATION: 'UPDATE_SLACK_NOTIFICATION',
  UPDATE_SLACK_NOTIFICATION_SUCCESS: 'UPDATE_SLACK_NOTIFICATION_SUCCESS',
  UPDATE_SLACK_NOTIFICATION_FAILURE: 'UPDATE_SLACK_NOTIFICATION_FAILURE',
  DELETE_SLACK_NOTIFICATION: 'DELETE_SLACK_NOTIFICATION',
  DELETE_SLACK_NOTIFICATION_SUCCESS: 'DELETE_SLACK_NOTIFICATION_SUCCESS',
  DELETE_SLACK_NOTIFICATION_FAILURE: 'DELETE_SLACK_NOTIFICATION_FAILURE',
  FETCH_SLACK_AUTH_URL: 'FETCH_SLACK_AUTH_URL',
  APPEND_SLACK_CHANNEL: 'APPEND_SLACK_CHANNEL',
  PUSH_NOTIFICATIONS_DRAWER_VISIBLE: 'PUSH_NOTIFICATIONS_DRAWER_VISIBLE',
  FETCH_PUSH_NOTIFICATIONS_LIST: 'FETCH_PUSH_NOTIFICATIONS_LIST',
  FETCH_PUSH_NOTIFICATIONS_LIST_SUCCESS: 'FETCH_PUSH_NOTIFICATIONS_LIST_SUCCESS',
  FETCH_PUSH_NOTIFICATIONS_LIST_FAILURE: 'FETCH_PUSH_NOTIFICATIONS_LIST_FAILURE',
  SET_PUSH_NOTIFICATION_EDIT_DETAILS: 'SET_PUSH_NOTIFICATION_EDIT_DETAILS',
  PUSH_NOTIFICATION_DELETE: 'PUSH_NOTIFICATION_DELETE',
  UPDATE_PUSH_NOTIFICATION_DETAILS: 'UPDATE_PUSH_NOTIFICATION_DETAILS',
  CREATE_PUSH_NOTIFICATION_DETAILS: 'CREATE_PUSH_NOTIFICATION_DETAILS',
  SET_CERTIFICATE_DETAILS: 'SET_CERTIFICATE_DETAILS',
  REMOVE_CERTIFICATE_DETAILS: 'REMOVE_CERTIFICATE_DETAILS',
  SET_AUTHENTICATION_TYPE: 'SET_AUTHENTICATION_TYPE',
  SET_APP_TYPE: 'SET_APP_TYPE',
  SET_ASK_EMAIL_BEFORE_START_LOADER: 'SET_ASK_EMAIL_BEFORE_START_LOADER',
  SET_PREVENT_CONVERSATION_CLOSE_LOADER:
    'SET_PREVENT_CONVERSATION_CLOSE_LOADER',
  FETCH_IDENTITY_VERIFICATION: 'FETCH_IDENTITY_VERIFICATION',
  FETCH_IDENTITY_VERIFICATION_SUCCESS: 'FETCH_IDENTITY_VERIFICATION_SUCCESS',
  FETCH_IDENTITY_VERIFICATION_FAILED: 'FETCH_IDENTITY_VERIFICATION_FAILED',
  UPDATE_IDENTITY_VERIFICATION: 'UPDATE_IDENTITY_VERIFICATION',
  UPDATE_IDENTITY_VERIFICATION_SUCCESS: 'UPDATE_IDENTITY_VERIFICATION_SUCCESS',
  GENERATE_USER_HASH: 'GENERATE_USER_HASH',
  GENERATE_USER_HASH_SUCCESS: 'GENERATE_USER_HASH_SUCCESS',
  DELETE_LANGUAGE_FROM_REDUCER: 'DELETE_LANGUAGE_FROM_REDUCER',
  UPDATE_SELECTED_LANGUAGES: 'UPDATE_SELECTED_LANGUAGES',
};

export default actions;
