import actions from './actions';

let initState = {
  isChatbotTemplateTableLoading: true,
  chatbotTemplateList: '',
  chatbotTemplateStatusList: [],
  chatbotCategoryList: [],
  isDrawerOpen: false,
  searchValue: '',
  loader: {
    workflowTemplateCategory: false,
    createTemplateLoading: false,
  },
  chatbotTemplateNameSet: '',
  description: '',
  duplicateId: '',
  categoryId: '',
  selectedTemplateStatus: 'all',
  isDisable: false,
  chatbotTemplateId: '',
  botDetail: [],
  isTemplateDrawerVisible: false,
  chatbotLoader: {
    publishLoader: false,
    editDetailsFormLoader: false,
  },
  defaultImage: '',
  filterCategoryId: '',
  templateSearchValue: '',
  filteredChatbotTemplateList: '',
  setChatBotTemplatename: '',
};

export default function chatbotTemplateReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CHATBOT_TEMPLATE_LIST: {
      return {
        ...state,
        isChatbotTemplateTableLoading: true,
      };
    }
    case actions.FETCH_CHATBOT_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        chatbotTemplateList: action.payload.bot_templates,
        chatbotTemplateStatusList:
          action.payload.bot_templates_status ||
          state.chatbotTemplateStatusList,
        isChatbotTemplateTableLoading: false,
        defaultImage: action.payload.build_from_scratch_overview,
      };
    }
    case actions.FETCH_CHATBOT_TEMPLATE_LIST_FAILURE: {
      return {
        ...state,
        isChatbotTemplateTableLoading: false,
      };
    }
    case actions.GET_CHATBOT_CATEGORIES_LISTS_SUCCESS:
      return {
        ...state,
        chatbotCategoryList: action.payload,
      };
    case actions.SET_CREATE_CHATBOT_TEMPLATE_DRAWER_STATUS:
      let data = action.object;
      return {
        ...state,
        isDrawerOpen: action.payload,
        chatbotTemplateNameSet: data ? `${data.name}(Copy)` : '',
        duplicateId: data ? data.id : '',
        description: data ? data.description : '',
        categoryId: data ? data.bot_template_category_id : undefined,
      };
    case actions.CREATE_CHATBOT_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.CREATE_CHATBOT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.CREATE_CHATBOT_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.SET_CHATBOT_TEMPLATE_ID:
      return {
        ...state,
        chatbotTemplateId: action.id,
      };
    case actions.DELETE_CHATBOT_TEMPLATE:
      return {
        ...state,
        isDisable: true,
      };
    case actions.DELETE_CHATBOT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.DELETE_CHATBOT_TEMPLATE_FAILURE:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.UPDATE_CHATBOT_TEMPLATE_DRAWER_VISIBLE:
      return {
        ...state,
        isTemplateDrawerVisible: action.payload,
      };
    case actions.UPDATE_CHATBOT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isTemplateDrawerVisible: false,
        chatbotLoader: {
          ...state.chatbotLoader,
          editDetailsFormLoader: false,
          publishLoader: action.isPublishAction
            ? false
            : state.chatbotLoader.publishLoader,
        },
        setChatBotTemplatename: action.payload.name,
      };
    case actions.UPDATE_CHATBOT_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateUpdate: false,
        isTemplateDrawerVisible: !action.changeStatus,
        chatbotLoader: {
          publishLoader: false,
          editDetailsFormLoader: false,
        },
      };
    case actions.UPDATE_CHATBOT_TEMPLATE:
      return {
        ...state,
        isTemplateUpdate: true,
      };
    case actions.SET_CHATBOT_TEMPLATE_STATUS_LOADER:
      return {
        ...state,
        chatbotLoader: {
          ...state.chatbotLoader,
          publishLoader: action.payload,
        },
      };
    case actions.SET_EDIT_DETAILS_FORM_LOADER:
      return {
        ...state,
        chatbotLoader: {
          ...state.chatbotLoader,
          editDetailsFormLoader: true,
        },
      };
    case actions.SET_FILTER_CHATBOT_CATEGORY_ID:
      return {
        ...state,
        filterCategoryId: action.payload,
      };
    case actions.SET_CHOOSE_CHATBOT_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        templateSearchValue: action.payload,
      };
    case actions.FILTER_CHATBOT_TEMPLATE_LIST:
      return {
        ...state,
        filteredChatbotTemplateList: state.chatbotTemplateList.filter(
          template =>
            template.name.toLowerCase().includes(action.payload.toLowerCase()),
        ),
      };
    case actions.SET_CHATBOT_TEMPLATE_STATUS:
      return {
        ...state,
        selectedTemplateStatus: action.status,
      };
    case actions.SET_CHATBOT_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.DUPLICATE_CHATBOT_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.DUPLICATE_CHATBOT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.DUPLICATE_CHATBOT_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.SET_SETTINGS_CHATBOT_TEMPLATE_DETAILS:
      return {
        ...state,
        setChatBotTemplatename: action.name,
      };
    default:
      return state;
  }
}
