import settings from '../../settings/index';
import { store } from '../../redux/store';
import { socialMediaRestriction } from '../../components/Chat/Messages/MessageComposer';
import { fetchRestrictedFeatures } from '../../helpers/utility';
import { menu } from '../../components/TopNavBar/constants';

export const socketChannels = {
  reconnectAttempt: 'reconnect_attempt',
  connect: 'connect',
  chatMessage: 'chat_message',
  conversationAssignment: 'conversation_assignment',
  changeChatRoom: 'change_chat_room',
  typingStarted: 'typing_started',
  typingStopped: 'typing_stopped',
  agentTypingStarted: 'agent_typing_started',
  agentTypingStopped: 'agent_typing_stopped',
  reloadConversation: 'reload_conversation',
  removeConversation: 'remove_conversation',
  deleteConversation: 'delete_conversation',
  blockUser: 'block_user',
  unblockUser: 'unblock_user',
  qualificationProperty: 'qualification_property',
  leadBotAttributeMapping: 'lead_bot_attribute_mapping',
  botGoalReached: 'bot_goal_reached',
  botConversationEnded: 'bot_conversation_ended',
  meetingScheduler: 'meeting_scheduler',
  online: 'online',
  offline: 'offline',
  changeConversationStatus: 'change_conversation_status',
  userPropertyUpdate: 'user_property_update',
  availabilityStatus: 'availability_status',
  priority: 'priority',
  notificationSettings: 'notification_settings',
  integrationStatus: 'integration_status',
  contactsLiveUpdate: 'contacts_live_update',
  chatSettings: 'chat_settings',
  contactsAvailableStatus: 'contacts_available_status',
  satisfactionRating: 'satisfaction_rating',
  connectError: 'connect_error',
  conversationStatusUpdate: 'conversation_status_update',
  webPermissions: 'web_permissions',
  joinCall: 'join_call',
  conversationTitle: 'conversation_title',
  conversationTagsUpdated: 'conversation_tag_updated',
  conversationPropertyUpdated: 'conversation_property_updated',
  workflowActivityUpdate: 'workflow_activity_update',
};

export const pageTitleUrl = [
  { '/settings/workspace-defaults': 'Workspace Defaults - Settings' },
  { '/settings/users': 'Users - Settings' },
  { '/settings/teams': 'Teams - Settings' },
  { '/settings/tracking-code': 'Tracking Code - Settings' },
  { '/settings/contact-properties': 'Contact Properties - Settings' },
  { '/settings/contact-tags': 'Contact Tags - Settings' },
  { '/settings/contact-segments': 'Contact Segments - Settings' },
  { '/settings/qualification-data': 'Qualification Data - Settings' },
  { '/settings/appearance': 'Appearance - Settings' },
  { '/settings/availability': 'Availability - Settings' },
  { '/settings/visibility': 'Visibility - Settings' },
  { '/settings/language': 'Language - Settings' },
  { '/settings/qualification-bot': 'Qualification Bot - Settings' },
  { '/settings/advanced': 'Advanced - Settings' },
  { '/settings/data-and-privacy': 'Data and Privacy - Settings' },
  { '/settings/imports': 'Imports - Settings' },
  { '/settings/exports': 'Exports - Settings' },
  { '/settings/snippets': 'Snippets - Settings' },
  { '/settings/conversation-tags': 'Conversation Tags - Settings' },
  { '/settings/spam': 'Spam - Settings' },
  { '/settings/email-templates': 'Custom Email Templates - Settings' },
  { '/settings/email-sending-domain': 'Email sending domain - Settings' },
  {
    '/settings/shared-team-email-address':
      'Shared Team Email Address - Settings',
  },
  { '/settings/knowledge-base': 'Knowledge Base - Settings' },
  { '/settings/api-key': 'API key - Settings' },
  { '/apps': 'Apps - Settings' },
  { '/settings/webhooks': 'Webhooks - Settings' },
  { '/settings/subscription': 'Subscription - Settings' },
  { '/settings/invoice': 'Invoice - Settings' },
  { '/account': 'Account' },
  { '/profile': 'Profile' },
  { '/notifications': 'Notifications' },
  { '/updates': 'Updates' },
  { '/contacts': 'Contacts' },
  { '/live-view': 'Live View' },
  { '/import_csv': 'Import CSV' },
  { '/conversations/[a-z0-9]': 'Inbox' },
  { '/email/broadcasts': 'Broadcasts' },
  { '/email/campaigns': 'Drip Campaigns' },
  { '/email/behavioral': 'Behavioral Emails' },
  { '/forms': 'Forms' },
  { '/events': 'Event Tracking' },
  { '/workflows': 'Workflows' },
  { '/workflow-templates': 'Workflow Templates' },
  { '/workflow/choose-template': 'Choose Template - Workflows' },
  {
    '/workflow-template/choose-template':
      'Choose Template - Workflow Templates',
  },
  { '/chat': 'Chats' },
  { '/chat-templates': 'Chat Templates' },
  { '/chat/choose-template': 'Choose Template - Chat' },
  {
    '/chat-template/choose-template': 'Choose Template - Chat Templates',
  },
  { '/post': 'Posts' },
  { '/post-templates': 'Post Templates' },
  { '/post/choose-template': 'Choose Template - Post' },
  {
    '/post-template/choose-template': 'Choose Template - Post Templates',
  },
  { '/rules': 'Automation Rules' },
  { '/assistants': 'Assistants' },
  { '/meetings': 'Meetings' },
  { '/knowledge-base/articles': 'Knowledge Base' },
  { '/change-password': 'Change Password' },
  { '/reports/conversations': 'Conversation Reports' },
  { '/conversations/rules': 'Inbox Automation' },
  { '/surveys': 'Surveys' },
  { '/survey-templates': 'Survey Templates' },
  { '/survey/choose-template': 'Choose Template - Surveys' },
  { '/tours': 'Tours' },
];

export const newMessageSound = new Audio(
  `${settings.STATIC_HOST}/audios/newMessage.mp3`,
);
export const newConversationSound = new Audio(
  `${settings.STATIC_HOST}/audios/newConversation.mp3`,
);

// Determine the OS key, either 'command' for Mac or 'ctrl' for other platforms
export const osCTRLkey = window.navigator.appVersion.includes('Mac')
  ? 'command'
  : 'ctrl';

const baseKeys = [
  'esc',
  'a',
  'c',
  'd',
  'f',
  'g',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'r',
  's',
  't',
  'u',
  'w',
  'x',
  'z',
  '[',
  ']',
  '\\',
  'shift+/',
  'shift+r',
  'shift+t',
];

const platformSpecificKeys = [
  `${osCTRLkey}+e`,
  `${osCTRLkey}+j`,
  `${osCTRLkey}+k`,
  `${osCTRLkey}+shift+a`,
  `${osCTRLkey}+shift+e`,
  `${osCTRLkey}+shift+h`,
  `${osCTRLkey}+shift+i`,
  `${osCTRLkey}+shift+m`,
  `${osCTRLkey}+shift+o`,
  `${osCTRLkey}+shift+y`,
];

export const shortcutKeys = [...baseKeys, ...platformSpecificKeys].join(',');

export const shortcutKeyCodes = {
  A: 65,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  ESC: 27,
  '[': 160,
  BACK_SLASH: 220,
  ']': 221,
};

export const shortcutRoutes = {
  65: 'conversations/latest', // a
  67: 'contacts', // c
  68: 'deals', // d
  70: 'forms', // f
  73: 'conversations/latest', // i
  74: 'conversations/latest', // j
  75: 'knowledge-base/articles', // k
  76: 'live-view', // l
  77: 'messages/email', // m
  83: 'settings', // s
  85: 'conversations/latest', // u
  87: 'workflows', // w
};

export const shortcutRoutesMappedToAction = {
  navigate_assigned_inbox: 'conversations/latest', // a
  navigate_contacts: 'contacts', // c
  navigate_deals: 'deals', // d
  navigate_forms: 'forms', // f
  navigate_all_inbox: 'conversations/latest', // i
  navigate_knowledgebase: 'knowledge-base/articles', // k
  navigate_liveview: 'live-view', // l
  navigate_messages: 'messages/email', // m
  navigate_settings: 'settings', // s
  navigate_unassigned_inbox: 'conversations/latest', // u
  navigate_workflows: 'workflows', // w
  navigate_integrations: 'apps',
  navigate_spam_inbox: 'conversations/latest', // j
};
export const shortcutInboxTableRoutesMappedToAction = {
  navigate_assigned_inbox: 'conversations/table', // a
  navigate_contacts: 'contacts', // c
  navigate_deals: 'deals', // d
  navigate_forms: 'forms', // f
  navigate_all_inbox: 'conversations/table', // i
  navigate_knowledgebase: 'knowledge-base/articles', // k
  navigate_liveview: 'live-view', // l
  navigate_messages: 'messages/email', // m
  navigate_settings: 'settings', // s
  navigate_unassigned_inbox: 'conversations/table', // u
  navigate_workflows: 'workflows', // w
  navigate_integrations: 'apps',
  navigate_spam_inbox: 'conversations/table', // j
};

export const shortcutInboxSearchRoutesMappedToAction = {
  navigate_assigned_inbox: 'conversations/search', // a
  navigate_contacts: 'contacts', // c
  navigate_deals: 'deals', // d
  navigate_forms: 'forms', // f
  navigate_all_inbox: 'conversations/search', // i
  navigate_knowledgebase: 'knowledge-base/articles', // k
  navigate_liveview: 'live-view', // l
  navigate_messages: 'messages/email', // m
  navigate_settings: 'settings', // s
  navigate_unassigned_inbox: 'conversations/search', // u
  navigate_workflows: 'workflows', // w
  navigate_integrations: 'apps',
  navigate_spam_inbox: 'conversations/search', // j
};

const os = window.navigator.appVersion.includes('Mac')
  ? { ctrl: '⌘', alt: '⌥' }
  : { ctrl: 'ctrl', alt: 'alt' };

export const shortcutsListGroups = [
  {
    id: 0,
    label: 'General',
    show_in_omni_search: false,
  },
  {
    id: 1,
    label: 'Conversation',
    show_in_omni_search: true,
  },
  {
    id: 4,
    label: 'Composer',
    show_in_omni_search: true,
  },
  {
    id: 2,
    label: 'Navigation',
    show_in_omni_search: true,
  },
  {
    id: 3,
    label: 'Creation',
    show_in_omni_search: true,
  },
  {
    id: 5,
    label: 'Help & support',
    show_in_omni_search: true,
  },
];

export const shortcutsList = [
  {
    groupId: 0,
    name: 'Open command menu',
    action: '',
    shortcut_key: [os.ctrl, 'k'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Ask Emma',
    action: 'ask_emma',
    shortcut_key: [os.ctrl, 'e'],
    icon: 'AskEmma',
    show_in_omni_search: () =>
      !fetchRestrictedFeatures().includes(menu.supportBot),
  },
  {
    groupId: 1,
    name: 'Close',
    action: 'close_conversation',
    shortcut_key: [os.ctrl, '⇧', 'y'],
    icon: 'Close',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Reopen',
    action: 'reopen_conversation',
    shortcut_key: [os.ctrl, '⇧', 'o'],
    icon: 'Reopen',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Snooze',
    action: 'snooze_conversation',
    shortcut_key: ['z'],
    icon: 'Snooze',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Change priority',
    action: 'change_priority',
    shortcut_key: ['p'],
    icon: 'Priority',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Assign',
    action: 'assign_to',
    shortcut_key: ['a'],
    icon: 'AssignTo',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Assign to me',
    action: 'assign_to_me',
    shortcut_key: ['i'],
    icon: 'AssignToMe',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Previous conversation',
    action: '',
    shortcut_key: ['j'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Next conversation',
    action: '',
    shortcut_key: ['k'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Select conversation',
    action: '',
    shortcut_key: ['x'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Tag last reply',
    action: 'tag_conversation',
    shortcut_key: ['t'],
    icon: 'TagMessage',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Tag contact',
    action: 'tag_contact',
    shortcut_key: ['⇧', 't'],
    icon: 'TagContact',
    show_in_omni_search: true,
  },
  {
    groupId: 1,
    name: 'Hide/show left menu',
    action: '',
    shortcut_key: ['['],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Hide/show right menu',
    action: '',
    shortcut_key: [']'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 1,
    name: 'Hide/show events',
    action: '',
    shortcut_key: [os.ctrl, '⇧', 'e'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 2,
    name: 'Go to assigned inbox',
    action: 'navigate_assigned_inbox',
    shortcut_key: ['g', '', 'a'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to unassigned inbox',
    action: 'navigate_unassigned_inbox',
    shortcut_key: ['g', '', 'u'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to all inbox',
    action: 'navigate_all_inbox',
    shortcut_key: ['g', '', 'i'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to spam inbox',
    action: 'navigate_spam_inbox',
    shortcut_key: ['g', '', 'j'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to contacts',
    action: 'navigate_contacts',
    shortcut_key: ['g', '', 'c'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to messages',
    action: 'navigate_messages',
    shortcut_key: ['g', '', 'm'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to workflows',
    action: 'navigate_workflows',
    shortcut_key: ['g', '', 'w'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to knowledge base',
    action: 'navigate_knowledgebase',
    shortcut_key: ['g', '', 'k'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to deals',
    action: 'navigate_deals',
    shortcut_key: ['g', '', 'd'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to live view',
    action: 'navigate_liveview',
    shortcut_key: ['g', '', 'l'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to forms',
    action: 'navigate_forms',
    shortcut_key: ['g', '', 'f'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to workspace settings',
    action: 'navigate_settings',
    shortcut_key: ['g', '', 's'],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 2,
    name: 'Go to apps & integrations',
    action: 'navigate_integrations',
    shortcut_key: [],
    icon: 'GoTo',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New contact',
    action: 'create_contact',
    shortcut_key: ['n', '', 'c'],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New message',
    action: 'create_message',
    shortcut_key: ['n', '', 'm'],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New workflow',
    action: 'create_workflow',
    shortcut_key: ['n', '', 'w'],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New article',
    action: 'create_article',
    shortcut_key: ['n', '', 'a'],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New deal',
    action: 'create_deal',
    shortcut_key: ['n', '', 'd'],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 3,
    name: 'New form',
    action: 'create_form',
    shortcut_key: ['n', '', 'f'],
    icon: 'New',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Compose reply',
    action: 'write_reply',
    shortcut_key: ['r'],
    icon: 'WriteReply',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Compose note',
    action: 'add_note',
    shortcut_key: ['⇧', 'r'],
    icon: 'AddNote',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Send',
    action: '',
    shortcut_key: [os.ctrl, 'enter'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Send & close',
    action: '',
    shortcut_key: [os.ctrl, '⇧', 'enter'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Send & snooze',
    action: '',
    shortcut_key: [os.ctrl, os.alt, 'enter'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Insert attachment',
    action: 'upload_attachment',
    shortcut_key: [os.ctrl, '⇧', 'a'],
    icon: 'Attachment',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Insert image',
    action: 'upload_image',
    shortcut_key: [os.ctrl, '⇧', 'i'],
    icon: 'Image',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Insert snippet',
    action: 'snippet',
    shortcut_key: ['\\'],
    icon: 'InsertSnippet',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Insert article',
    action: 'article',
    shortcut_key: [os.ctrl, '⇧', 'h'],
    icon: 'InsertArticle',
    show_in_omni_search: true,
  },
  {
    groupId: 4,
    name: 'Schedule meeting',
    action: 'meeting',
    shortcut_key: [os.ctrl, '⇧', 'm'],
    icon: 'Calendar',
    show_in_omni_search: () => {
      const { selectedChatRoomMessages } = store.getState().Chat;
      let chatRoomMessage = selectedChatRoomMessages;
      return socialMediaRestriction(chatRoomMessage);
    },
  },
  {
    groupId: 4,
    name: 'AI Assist',
    action: 'ai_assist',
    shortcut_key: [os.ctrl, 'j'],
    icon: 'AiAssist',
    show_in_omni_search: () =>
      !fetchRestrictedFeatures().includes(menu.aiAssist),
  },
  {
    groupId: 4,
    name: 'Bold',
    action: '',
    shortcut_key: [os.ctrl, 'b'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Italic',
    action: '',
    shortcut_key: [os.ctrl, 'i'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Underline',
    action: '',
    shortcut_key: [os.ctrl, 'u'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Hyperlink',
    action: '',
    shortcut_key: [os.ctrl, 'k'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Undo',
    action: '',
    shortcut_key: [os.ctrl, 'z'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 4,
    name: 'Redo',
    action: '',
    shortcut_key: [os.ctrl, '⇧', 'z'],
    icon: '',
    show_in_omni_search: false,
  },
  {
    groupId: 5,
    name: 'Open support center',
    action: 'help_center',
    url: 'https://docs.getgist.com',
    shortcut_key: [],
    icon: 'Help',
    show_in_omni_search: true,
  },
  {
    groupId: 5,
    name: 'Open API documentation',
    action: 'api',
    url: 'https://developers.getgist.com',
    shortcut_key: [],
    icon: 'API',
    show_in_omni_search: true,
  },
];

export const translateActions = [
  {
    name: 'English',
    action: 'translate_to',
    lang_code: 'en',
  },
  {
    name: 'Korean',
    action: 'translate_to',
    lang_code: 'ko',
  },
  {
    name: 'Chinese',
    action: 'translate_to',
    lang_code: 'zh',
  },
  {
    name: 'Japanese',
    action: 'translate_to',
    lang_code: 'ja',
  },
  {
    name: 'Spanish',
    action: 'translate_to',
    lang_code: 'es',
  },
  {
    name: 'Russian',
    action: 'translate_to',
    lang_code: 'ru',
  },
  {
    name: 'French',
    action: 'translate_to',
    lang_code: 'fr',
  },
  {
    name: 'Portuguese',
    action: 'translate_to',
    lang_code: 'pt',
  },
  {
    name: 'German',
    action: 'translate_to',
    lang_code: 'de',
  },
  {
    name: 'Italian',
    action: 'translate_to',
    lang_code: 'it',
  },
  {
    name: 'Dutch',
    action: 'translate_to',
    lang_code: 'nl',
  },
  {
    name: 'Indonesian',
    action: 'translate_to',
    lang_code: 'id',
  },
  {
    name: 'Filipino',
    action: 'translate_to',
    lang_code: 'tl',
  },
  {
    name: 'Vietnamese',
    action: 'translate_to',
    lang_code: 'vi',
  },
];

export const toneActions = [
  {
    name: 'Professional',
    action: 'change_tone_to',
    tone: 'professional',
  },
  {
    name: 'Helpful',
    action: 'change_tone_to',
    tone: 'helpful',
  },
  {
    name: 'Straightforward',
    action: 'change_tone_to',
    tone: 'straightforward',
  },
  {
    name: 'Empathetic',
    action: 'change_tone_to',
    tone: 'empathetic',
  },
  {
    name: 'Friendly',
    action: 'change_tone_to',
    tone: 'friendly',
  },
];

export const MOST_USED_TAGS_LIMIT = 5;

export const inboxShortcutGroupId = [1, 4];
