import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import DeveloperAppRouter from './DeveloperAppRouter';
import { initiatePreferColorScheduleListeners } from '../../helpers/utility';
import DeveloperTopNavBar from '../../components/AppPlatform/DeveloperTopNavBar';

const DeveloperApp = props => {
  const projectBlocked = useSelector(({ Auth }) => Auth.projectBlocked);
  const userPreferences = useSelector(({ Auth }) => Auth.userPreferences);

  const { url } = props.match;

  useEffect(() => {
    initiatePreferColorScheduleListeners(userPreferences.theme || 'auto');
  }, []);

  return (
    <Layout className="layout main-layout">
      <DeveloperTopNavBar />
      <DeveloperAppRouter url={url} projectBlocked={projectBlocked} />
    </Layout>
  );
};

export default DeveloperApp;
