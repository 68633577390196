import React from 'react';
import '../style.scss';

const DataTableComponent = props => {
  const { component } = props;

  return (
    <div className="data-table">
      {component.items &&
        component.items.map((item, index) => (
          <div className="list-item" key={index}>
            <span className="list-item-field">{item.field}</span>
            <span className="list-item-field">{item.value}</span>
          </div>
        ))}
    </div>
  );
};

export default DataTableComponent;
