export function getTeammateInboxCount(data, userId) {
  return data &&
    data.assigned &&
    data.assigned.teammates &&
    data.assigned.teammates[userId] >= 0
    ? data.assigned.teammates[userId]
    : 0;
}

export function getTeamInboxCount(data, userId) {
  return data &&
    data.assigned &&
    data.assigned.teams &&
    data.assigned.teams[userId] >= 0
    ? data.assigned.teams[userId]
    : 0;
}

export function getMentionedInboxCount(data, userId) {
  return data && data.mentioned && data.mentioned[userId] >= 0
    ? data.mentioned[userId]
    : 0;
}
