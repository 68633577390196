const actions = {
  SET_AUTO_EMAIL_SELECTED_ROW_KEYS: 'SET_AUTO_EMAIL_SELECTED_ROW_KEYS',
  FETCH_AUTO_EMAIL: 'FETCH_AUTO_EMAIL',
  FETCH_AUTO_EMAIL_SUCCESS: 'FETCH_AUTO_EMAIL_SUCCESS',
  SET_AUTO_EMAIL_STATUS: 'SET_AUTO_EMAIL_STATUS',
  SET_AUTO_EMAIL_SEARCH_VALUE: 'SET_AUTO_EMAIL_SEARCH_VALUE',
  FETCH_AUTO_TEMPLATES: 'FETCH_AUTO_TEMPLATES',
  FETCH_AUTO_TEMPLATES_SUCCESS: 'FETCH_AUTO_TEMPLATES_SUCCESS',
  SET_AUTO_EMAIL_NAME: 'SET_AUTO_EMAIL_NAME',
  SET_SELECTED_TEMPLATE_ID: 'SET_SELECTED_TEMPLATE_ID',
  CREATE_AUTO_EMAIL: 'CREATE_AUTO_EMAIL',
  CREATE_AUTO_EMAIL_SUCCESS: 'CREATE_AUTO_EMAIL_SUCCESS',
  CREATE_AUTO_EMAIL_FAILURE: 'CREATE_AUTO_EMAIL_FAILURE',
  FETCH_AUTO_EMAIL_DATA: 'FETCH_AUTO_EMAIL_DATA',
  FETCH_AUTO_EMAIL_DATA_SUCCESS: 'FETCH_AUTO_EMAIL_DATA_SUCCESS',
  SET_AUTO_EMAIL_TEXT: 'SET_AUTO_EMAIL_TEXT',
  SEND_AUTO_TEST_EMAIL: 'SEND_AUTO_TEST_EMAIL',
  SEND_AUTO_TEST_EMAIL_SUCCESS: 'SEND_AUTO_TEST_EMAIL_SUCCESS',
  TEST_EMAIL_MODAL_VISIBLE: 'TEST_EMAIL_MODAL_VISIBLE',
  SEND_AUTO_EMAIL_TEST_EMAIL_FAILURE: 'SEND_AUTO_EMAIL_TEST_EMAIL_FAILURE',
  CREATE_AUTO_EMAIL_COMPOSER: 'CREATE_AUTO_EMAIL_COMPOSER',
  CREATE_AUTO_EMAIL_COMPOSER_SUCCESS: 'CREATE_AUTO_EMAIL_COMPOSER_SUCCESS',
  SET_AUTO_EMAIL_SORT_KEY_AND_VALUE: 'SET_AUTO_EMAIL_SORT_KEY_AND_VALUE',
  SCHEDULE_AUTO_EMAIL: 'SCHEDULE_AUTO_EMAIL',
  SET_AUTO_EMAIL_ID: 'SET_AUTO_EMAIL_ID',
  DELETE_AUTO_EMAIL: 'DELETE_AUTO_EMAIL',
  SET_FROM_EMAIL_USER_ID: 'SET_FROM_EMAIL_USER_ID',
  SET_TO_EMAIL_USER_ID: 'SET_TO_EMAIL_USER_ID',
  CREATE_AUTO_EMAIL_SETTINGS: 'CREATE_AUTO_EMAIL_SETTINGS',
  OPEN_CREATE_AUTO_EMAIL_DRAWER: 'OPEN_CREATE_AUTO_EMAIL_DRAWER',
  CLOSE_CREATE_AUTO_EMAIL_DRAWER: 'CLOSE_CREATE_AUTO_EMAIL_DRAWER',
  SET_DUPLICATE_AUTO_EMAIL_DATA: 'SET_DUPLICATE_AUTO_EMAIL_DATA',
  CREATE_DUPLICATE_AUTO_EMAIL: 'CREATE_DUPLICATE_AUTO_EMAIL',
  SET_AUTO_EMAIL_SUBJECT: 'SET_AUTO_EMAIL_SUBJECT',
  SET_AUTO_EMAIL_PRE_HEADER_TEXT: 'SET_AUTO_EMAIL_PRE_HEADER_TEXT',
  OPEN_AUTO_EMAIL_TEMPLATE_DRAWER: 'OPEN_AUTO_EMAIL_TEMPLATE_DRAWER',
  SET_AUTO_EMAIL_TAB_KEY: 'SET_AUTO_EMAIL_TAB_KEY',
  SET_DUPLICATE_AUTO_EMAIL_NAME: 'SET_DUPLICATE_AUTO_EMAIL_NAME',
  SET_PRE_HEADER_TEXT: 'SET_PRE_HEADER_TEXT',
  RESET_EMAIL_COMPOSER_FORM: 'RESET_EMAIL_COMPOSER_FORM',
  APPEND_AUTO_EMAIL: 'APPEND_AUTO_EMAIL',
  APPEND_AUTO_EMAIL_SUCCESS: 'APPEND_AUTO_EMAIL_SUCCESS',
  AUTO_EMAIL_CREATE_RECIPIENTS_SUCCESS: 'AUTO_EMAIL_CREATE_RECIPIENTS_SUCCESS',
  AUTO_EMAIL_CREATE_RECIPIENTS: 'AUTO_EMAIL_CREATE_RECIPIENTS',
  AUTO_EMAIL_FETCH_FILTER_RECIPIENTS_COUNT:
    'AUTO_EMAIL_FETCH_FILTER_RECIPIENTS_COUNT',
  AUTO_EMAIL_FETCH_RECIPIENTS_SUCCESS: 'AUTO_EMAIL_FETCH_RECIPIENTS_SUCCESS',
  AUTO_EMAIL_FETCH_RECIPIENTS_FAILURE: 'AUTO_EMAIL_FETCH_RECIPIENTS_FAILURE',
  SET_AUTO_EMAIL_LAST_SAVE_TIME: 'SET_AUTO_EMAIL_LAST_SAVE_TIME',
  AUTO_MAIL_SET_RECIPIENT_LOADER: 'AUTO_MAIL_SET_RECIPIENT_LOADER',
  AUTO_MAIL_FILTER_VALIDATION: 'AUTO_MAIL_FILTER_VALIDATION',
  AUTO_EMAIL_SATUS_CHANGE_SUCCESS: 'AUTO_EMAIL_SATUS_CHANGE_SUCCESS',
  AUTO_EMAIL_RECIPIENTS_CREATE_SUCCESS: 'AUTO_EMAIL_RECIPIENTS_CREATE_SUCCESS',
  AUTO_EMAIL_GET_AUDIENCE_COUNT: 'AUTO_EMAIL_GET_AUDIENCE_COUNT',
  SET_AUTO_EMAIL_TEMPLATE_TAB_KEY: 'SET_AUTO_EMAIL_TEMPLATE_TAB_KEY',
  SAVE_AUTO_EMAIL_SETTINGS: 'SAVE_AUTO_EMAIL_SETTINGS',
  RESET_AUTO_EMAIL_COMPOSER_DATA: 'RESET_AUTO_EMAIL_COMPOSER_DATA',
  RESET_AUTO_EMAIL_SETTINGS: 'RESET_AUTO_EMAIL_SETTINGS',
  SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS:
    'SET_AUTO_EMAIL_LOADING_INDICATOR_SUCCESS',
  SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE:
    'SET_AUTO_EMAIL_LOADING_INDICATOR_FAILURE',
  UPDATE_COMPOSER_DATA: 'UPDATE_COMPOSER_DATA',
  UPDATE_RECIPIENTS_DATA: 'UPDATE_RECIPIENTS_DATA',
  UPDATE_SETTINGS_DATA: 'UPDATE_SETTINGS_DATA',
  UPDATE_SETTING_DEADLINE: 'UPDATE_SETTING_DEADLINE',
  SET_LIVE_MODAL: 'SET_LIVE_MODAL',
  DELETE_AUTO_EMAIL_SUCCESS: 'DELETE_AUTO_EMAIL_SUCCESS',
  DELETE_AUTO_EMAIL_FOLDER_LOADING: 'DELETE_AUTO_EMAIL_FOLDER_LOADING',
  SET_AUTO_EMAIL_SUBJECT_VALIDATION: 'SET_AUTO_EMAIL_SUBJECT_VALIDATION',
  AUTO_EMAIL_CANCEL_SCHEDULE_SUCCESS: 'AUTO_EMAIL_CANCEL_SCHEDULE_SUCCESS',
  SET_AUTO_MAIL_TAG_ID: 'SET_AUTO_MAIL_TAG_ID',
  CREATE_AUTO_EMAIL_SCHEDULE: 'CREATE_AUTO_EMAIL_SCHEDULE',
  SET_AUTO_EMAIL_SUBSCRIPTION_ID: 'SET_AUTO_EMAIL_SUBSCRIPTION_ID',
  SET_COMPOSER_VALUE_CHANGE: 'SET_COMPOSER_VALUE_CHANGE',
  UPDATE_AUTO_EMAIL_VISUAL_BUILDER_JSON:
    'UPDATE_AUTO_EMAIL_VISUAL_BUILDER_JSON',
  FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON: 'FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON',
  FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON_SUCCESS:
    'FETCH_AUTO_EMAIL_VISUAL_BUILDER_JSON_SUCCESS',
  FETCH_AUTO_EMAIL_BEE_TOKEN: 'FETCH_AUTO_EMAIL_BEE_TOKEN',
  FETCH_AUTO_EMAIL_BEE_TOKEN_SUCCESS: 'FETCH_AUTO_EMAIL_BEE_TOKEN_SUCCESS',
};

export default actions;
