import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import './index.css';
import maintenanceImage from '../../image/maintenance.png';
import { useSelector } from 'react-redux';
import { updateBrowserHistory } from '../../helpers/utility';

const { Title, Paragraph } = Typography;

const MaintenancePage = () => {
  const currentProjectSecretKey = useSelector(({Auth})=>Auth.currentProjectSecretKey)
  useEffect(()=>{
    if(currentProjectSecretKey)updateBrowserHistory('contacts')
  },[currentProjectSecretKey])
 return (
    <div className="maintenance-container">
      <Row className="maintenance-content">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-section">
          <Title>We are under maintenance!</Title>
          <Paragraph>
            Sorry for the inconvenience but we’re improving our website. We’ll be
            back online shortly!
          </Paragraph>
          <Paragraph>- Team Gist </Paragraph>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="image-section">
          <img src={maintenanceImage} alt="Maintenance" />
        </Col>
      </Row>
    </div>
  );
}

export default MaintenancePage;
