const actions = {
  FETCH_CHAT_ONEOFF_LIST_IN_POST: 'FETCH_CHAT_ONEOFF_LIST_IN_POST',
  FETCH_CHAT_ONEOFF_LIST_SUCCESS_IN_POST: 'FETCH_CHAT_ONEOFF_LIST_SUCCESS_IN_POST',
  FETCH_CHAT_ONEOFF_LIST_FAILURE_IN_POST: 'FETCH_CHAT_ONEOFF_LIST_FAILURE_IN_POST',
  APPEND_CHAT_ONEOFF_LIST_IN_POST: 'APPEND_CHAT_ONEOFF_LIST_IN_POST',
  APPEND_CHAT_ONEOFF_LIST_SUCCESS_IN_POST: 'APPEND_CHAT_ONEOFF_LIST_SUCCESS_IN_POST',
  APPEND_CHAT_ONEOFF_LIST_FAILURE_IN_POST: 'APPEND_CHAT_ONEOFF_LIST_FAILURE_IN_POST',
  SET_CHAT_ONEOFF_SORT_VALUES_IN_POST: 'SET_CHAT_ONEOFF_SORT_VALUES_IN_POST',
  SET_CHAT_ONEOFF_SEARCH_VALUE_IN_POST: 'SET_CHAT_ONEOFF_SEARCH_VALUE_IN_POST',
  SET_CHAT_ONEOFF_STATUS_IN_POST: 'SET_CHAT_ONEOFF_STATUS_IN_POST',
  SET_CREATE_CHAT_ONEOFF_IN_POST: 'SET_CREATE_CHAT_ONEOFF_IN_POST',
  GET_CHAT_ONEOFF_PROMPT_DETAILS_IN_POST: 'GET_CHAT_ONEOFF_PROMPT_DETAILS_IN_POST',
  GET_CHAT_ONEOFF_PROMPT_DETAILS_SUCCESS_IN_POST: 'GET_CHAT_ONEOFF_PROMPT_DETAILS_SUCCESS_IN_POST',
  GET_CHAT_ONEOFF_PROMPT_DETAILS_FAILURE_IN_POST: 'GET_CHAT_ONEOFF_PROMPT_DETAILS_FAILURE_IN_POST',
  SET_CHAT_ONEOFF_TAB_KEY_IN_POST: 'SET_CHAT_ONEOFF_TAB_KEY_IN_POST',
  SET_CHAT_ONEOFF_TAG_ID_IN_POST: 'SET_CHAT_ONEOFF_TAG_ID_IN_POST',
  SET_CHAT_ONEOFF_SCHEDULE_TIME_IN_POST: 'SET_CHAT_ONEOFF_SCHEDULE_TIME_IN_POST',
  SET_CHAT_ONEOFF_PROMOT_DETAILS_IN_POST: 'SET_CHAT_ONEOFF_PROMOTDETAILS_IN_POST',
  CHAT_ONEOFF_SCHEDULE_DETAILS_IN_POST: 'CHAT_ONEOFF_SCHEDULE_DETAILS_IN_POST',
  CHAT_ONEOFF_SCHEDULE_DETAILS_SUCCESS_IN_POST: 'CHAT_ONEOFF_SCHEDULE_DETAILS_SUCCESS_IN_POST',
  UPDATE_MESSAGE_CHAT_ONEOFF_IN_POST: 'UPDATE_MESSAGE_CHAT_ONEOFF_IN_POST',
  UPDATE_MESSAGE_CHAT_ONEOFF_SCHEDULE_DETAILS_IN_POST: 'UPDATE_MESSAGE_CHAT_ONEOFF_SCHEDULE_DETAILS_IN_POST',
  DELETE_CHAT_ONEOFF_DATA_IN_POST: 'DELETE_CHAT_ONEOFF_DATA_IN_POST',
  SET_CHAT_ONEOFF_SELECTED_ROWS_IN_POST: 'SET_CHAT_ONEOFF_SELECTED_ROWS_IN_POST',
  REMOVE_DELETED_ONE_OFF_CHAT_ID_IN_POST: 'REMOVE_DELETED_ONE_OFF_CHAT_ID_IN_POST',
};

export default actions;