import actions from './actions';

let initState = {
  isFromTemplate: true,
  isMessageTemplateTableLoading: true,
  messageTemplateList: [],
  messageTemplateStatusList: [],
  messageCategoryList: [],
  isDrawerOpen: false,
  searchValue: '',
  loader: {
    workflowTemplateCategory: false,
    createTemplateLoading: false,
  },
  messageTemplateNameSet: '',
  description: '',
  duplicateId: '',
  categoryId: '',
  overview: '',
  selectedTemplateStatus: 'all',
  isDisable: false,
  messageTemplateId: '',
  botDetail: [],
  isTemplateDrawerVisible: false,
  messageTemplateLoader: {
    publishLoader: false,
    editDetailsFormLoader: false,
  },
  defaultImage: '',
  filterCategoryId: '',
  templateSearchValue: '',
  filteredMessageTemplateList: '',
  setMessageTemplate: '',
  editDrawerMessageTemplate: {},
  messageDefaultTemplateData: {},
};

export default function MessageTemplateReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_MESSAGE_TEMPLATE_LIST: {
      return {
        ...state,
        isMessageTemplateTableLoading: true,
      };
    }
    case actions.FETCH_MESSAGE_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        messageTemplateList: action.payload.message_templates,
        messageTemplateStatusList:
          action.payload.message_template_status ||
          state.messageTemplateStatusList,
        isMessageTemplateTableLoading: false,
        defaultImage: action.payload.build_from_scratch_overview,
      };
    }
    case actions.FETCH_MESSAGE_TEMPLATE_LIST_FAILURE: {
      return {
        ...state,
        isMessageTemplateTableLoading: false,
      };
    }
    case actions.GET_MESSAGE_CATEGORIES_LISTS_SUCCESS:
      return {
        ...state,
        messageCategoryList: action.payload.template_categories,
      };
    case actions.SET_CREATE_MESSAGE_TEMPLATE_DRAWER_STATUS:
      let data = action.object;
      return {
        ...state,
        isDrawerOpen: action.payload,
        messageTemplateNameSet: data ? `${data.name}(Copy)` : '',
        duplicateId: data ? data.id : '',
        description: data ? data.description : '',
        overview: data ? data.overview_url : '',
        categoryId: data ? data.template_category_id : undefined,
      };
    case actions.CREATE_MESSAGE_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.CREATE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.CREATE_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.SET_MESSAGE_TEMPLATE_ID:
      return {
        ...state,
        messageTemplateId: action.id,
      };
    case actions.DELETE_MESSAGE_TEMPLATE:
      return {
        ...state,
        isDisable: true,
      };
    case actions.DELETE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.DELETE_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.UPDATE_MESSAGE_TEMPLATE_DRAWER_VISIBLE:
      return {
        ...state,
        isTemplateDrawerVisible: action.payload,
      };
    case actions.UPDATE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isTemplateDrawerVisible: false,
        messageTemplateLoader: {
          ...state.messageTemplateLoader,
          editDetailsFormLoader: false,
          publishLoader: action.isPublishAction
            ? false
            : state.messageTemplateLoader.publishLoader,
        },
        editDrawerMessageTemplate: action.payload,
      };
    case actions.UPDATE_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateUpdate: false,
        isTemplateDrawerVisible: !action.changeStatus,
        messageTemplateLoader: {
          publishLoader: false,
          editDetailsFormLoader: false,
        },
      };
    case actions.UPDATE_MESSAGE_TEMPLATE:
      return {
        ...state,
        isTemplateUpdate: true,
      };
    case actions.SET_MESSAGE_TEMPLATE_STATUS_LOADER:
      return {
        ...state,
        messageTemplateLoader: {
          ...state.messageTemplateLoader,
          publishLoader: action.payload,
        },
      };
    case actions.SET_EDIT_DETAILS_FORM_LOADER:
      return {
        ...state,
        messageTemplateLoader: {
          ...state.messageTemplateLoader,
          editDetailsFormLoader: true,
        },
      };
    case actions.SET_FILTER_MESSAGE_CATEGORY_ID:
      return {
        ...state,
        filterCategoryId: action.payload,
      };
    case actions.SET_CHOOSE_MESSAGE_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        templateSearchValue: action.payload,
      };
    case actions.FILTER_MESSAGE_TEMPLATE_LIST:
      return {
        ...state,
        filteredMessageTemplateList: state.messageTemplateList.filter(
          template =>
            template.name.toLowerCase().includes(action.payload.toLowerCase()),
        ),
      };
    case actions.SET_MESSAGE_TEMPLATE_STATUS:
      return {
        ...state,
        selectedTemplateStatus: action.status,
      };
    case actions.SET_MESSAGE_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.DUPLICATE_MESSAGE_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.DUPLICATE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.DUPLICATE_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.SET_MESSAGE_TEMPLATE_DETAILS:
      return {
        ...state,
        setMessageTemplate: action.payload,
      };
    case actions.UPDATE_DRAWER_MESSAGE_TEMPLATE:
      return {
        ...state,
        editDrawerMessageTemplate: action.payload,
      };
    case actions.SET_MESSAGE_DEFAULT_TEMPLATE_DETAILS:
      return {
        ...state,
        messageDefaultTemplateData: action.payload,
      }
    default:
      return state;
  }
}
