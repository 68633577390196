import actions from './actions';
import CRMactions from '../actions';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fetchProjectSecretKey,
  getPromise,
  deletePromise,
  unAuthorisedRedirection,
  patchPromise,
  postPromise,
} from '../../../helpers/utility';
import settings from '../../../settings';
import { push } from 'connected-react-router';
import { store } from '../../store';
import { addActivity } from '../../../components/CRM/helper';

export function* getDealDetails(params) {
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/deals/${
      params.dealSecret
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_DETAILS_SUCCESS,
      payload: data.data.deal,
    });
    if (params.isPageLoad) {
      // Get activities
      yield put({
        type: actions.GET_DEAL_ACTIVITIES,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch pipelines failed');
    }
    yield put({ type: actions.GET_DEAL_DETAILS_FAILURE });
  }
}

export function* fetchDealActivities(params) {
  try {
    const { id } = store.getState().CRMDeal.dealDetails;
    let filterData = '';
    if (params.activityTypes && params.activityTypes.length) {
      filterData = params.activityTypes.map(activityType =>
        activityType.toUpperCase().replace(' ', '_'),
      );
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm/activities?deal_id=${id}&activity_types=${filterData}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_ACTIVITIES_SUCCESS,
      payload: data.data.activities,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch activities failed');
    }
    yield put({ type: actions.GET_DEAL_ACTIVITIES_FAILURE });
  }
}

export function* appendDealActivities(params) {
  try {
    const { dealDetails, activitiesFilter } = store.getState().CRMDeal;
    const { id } = dealDetails;
    let activityTypes = '';
    if (activitiesFilter.length) {
      activityTypes = activitiesFilter.map(activityType =>
        activityType.toUpperCase().replace(' ', '_'),
      );
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm/activities?deal_id=${id}&last_activity_id=${
      params.lastActivityId
    }&activity_types=${activityTypes}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_DEAL_ACTIVITIES_SUCCESS,
      payload: data.data.activities,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch activities failed');
    }
    yield put({ type: actions.APPEND_DEAL_ACTIVITIES_FAILURE });
  }
}

export function* deleteDeal(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.pipelineId
    }/stages/${params.stageId}/deals/${params.dealId}`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({ type: CRMactions.DELETE_DEAL_SUCCESS,stageId:params.stageId,dealId:params.dealId });
    if (params.fetchContactOrCompanyDeals) {
      yield put({
        type: actions.RESET_MODAL_DEAL_ID,
      });
      params.fetchContactOrCompanyDeals();
    } else {
      yield put(push(`/projects/${fetchProjectSecretKey()}/deals`));
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Delete deal failed');
    }
    yield put({ type: actions.DELETE_DEAL_FAILURE });
  }
}

export function* fetchContactsList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/get_filtered_people_count?filter_data=[{"criteria":[{"key":"email","condition":"has_any_value"}]}]&with_data=true`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACTS_LIST_SUCCESS,
      payload: data.data.filtered_people,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch pipelines failed');
    }
    yield put({ type: actions.FETCH_CONTACTS_LIST_FAILURE });
  }
}

export function* removeContact(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.pipelineId
    }/stages/${params.stageId}/deals/${params.dealId}/remove_contact`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.DEAL_REMOVE_CONTACT_SUCCESS,
      contactID: params.payload.deal.associated_contact_ids[0],
    });
    if (params.fetchContactOrCompanyDeals) {
      params.fetchContactOrCompanyDeals();
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Remove contact failed');
    }
    yield put({ type: actions.DEAL_REMOVE_CONTACT_FAILURE });
  }
}

export function* addContact(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.pipelineId
    }/stages/${params.stageId}/deals/${params.dealId}/add_contact`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.DEAL_ADD_CONTACT_SUCCESS,
      payload: data.data.deal.associated_contacts,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add contact failed');
    }
    yield put({ type: actions.DEAL_ADD_CONTACT_FAILURE });
  }
}

export function* getDealProperties(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/deal_properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_PROPERTY_LIST_SUCCESS,
      payload: data.data.properties,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Deal properties fetch failed');
    }
  }
}

export function* fetchDealNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/notes?deal_id=${params.dealID}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_DEALS_NOTES_SUCCESS,
      payload: data.data.deal_notes,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DEALS_NOTES_FAILURE });
    unAuthorisedRedirection(error);
    message.error('Fetch notes failed');
  }
}

export function* createDealNotes(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/notes`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_DEAL_NOTES_SUCCESS,
      payload: data.data.deal_note,
      params,
    });
    addActivity(data.data.deal_note.activities, 'Notes');
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.CREATE_DEAL_NOTES_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Create note failed');
    }
  }
}

export function* editDealNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/notes/${params.noteID}?deal_id=${
      params.dealID
    }`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EDIT_DEAL_NOTES_SUCCESS,
      payload: data.data.deal_note,
    });
    // addActivity(data.data.deal_note.activities, 'Notes');
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update note failed');
    }
  }
}

export function* deleteDealNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/notes/${params.noteID}?deal_id=${
      params.dealID
    }`;
    const data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_DEAL_NOTES_SUCCESS,
      noteID: params.noteID,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Delete note failed');
  }
}

export function* getMeetingList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meetings_list?deal_id=${
      params.dealID
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_MEETINGS_SUCCESS,
      payload: data.data.scheduled_meetings,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch meetings failed');
    }
    yield put({ type: actions.GET_DEAL_MEETINGS_FAILURE });
  }
}

export function* addDealMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/scheduled_meetings`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.ADD_DEAL_MEETING_SUCCESS,
      payload: data.data.scheduled_meeting,
    });
    addActivity(data.data.scheduled_meeting.activities, 'Meetings');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add meeting failed');
    }
    yield put({ type: actions.ADD_DEAL_MEETING_FAILURE });
  }
}

export function* removeDealMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/scheduled_meetings/${
      params.meetingID
    }/cancel_scheduled_meeting`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.CANCEL_DEAL_MEETING_SUCCESS,
      meetingID: params.meetingID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Cancel meeting failed');
    }
    yield put({ type: actions.CANCEL_DEAL_MEETING_FAILURE });
  }
}
export function* getDealTeammateList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teammates_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_TEAMMATE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Teammate details fetch failed');
  }
}

function* fetchEmailList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm_emails?deal_id=${params.dealID}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_EMAIL_LIST_SUCCESS,
      payload: data.data.crm_emails,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Email list fetch failed');
  }
}

function* createDealEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm_emails`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_DEAL_EMAIL_SUCCESS,
      payload: data.data.crm_email,
    });
    if (params.setDealEmailModalStatus) {
      params.setDealEmailModalStatus(false);
    }
    addActivity(data.data.crm_email.activities, 'Emails');
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.CREATE_DEAL_EMAIL_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Email send failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DEAL_DETAILS, getDealDetails),
    takeEvery(actions.GET_DEAL_ACTIVITIES, fetchDealActivities),
    takeEvery(actions.APPEND_DEAL_ACTIVITIES, appendDealActivities),
    takeEvery(actions.DELETE_DEAL, deleteDeal),
    takeEvery(actions.FETCH_CONTACTS_LIST, fetchContactsList),
    takeEvery(actions.DEAL_REMOVE_CONTACT, removeContact),
    takeEvery(actions.DEAL_ADD_CONTACT, addContact),
    takeEvery(actions.GET_DEAL_PROPERTY_LIST, getDealProperties),
    takeEvery(actions.FETCH_DEALS_NOTES, fetchDealNotes),
    takeEvery(actions.CREATE_DEALS_NOTES, createDealNotes),
    takeEvery(actions.EDIT_DEAL_NOTES, editDealNotes),
    takeEvery(actions.DELETE_DEAL_NOTES, deleteDealNotes),
    takeEvery(actions.GET_DEAL_MEETINGS, getMeetingList),
    takeEvery(actions.ADD_DEAL_MEETING, addDealMeeting),
    takeEvery(actions.CANCEL_DEAL_MEETING, removeDealMeeting),
    takeEvery(actions.GET_DEAL_TEAMMATE_LIST, getDealTeammateList),
    takeEvery(actions.GET_DEAL_EMAIL_LIST, fetchEmailList),
    takeEvery(actions.CREATE_DEAL_EMAIL, createDealEmail),
  ]);
}
