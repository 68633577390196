import React from 'react';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';
import { meetingButtonList } from '../../../../../containers/Messages/Bots/constants';

export class Meetings extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className="header">
          <h3>
            <span>
              <i className="fas fa-calendar-alt" />
            </span>
            Book a meeting
          </h3>
        </div>
        <div className="body">
          <div className="message-text-component">
            {data.user_name ? (
              <div>
                Scheduling a meeting with <b>{data.user_name}</b>
              </div>
            ) : (
              <div className={'error-text'}>Teammate Missing</div>
            )}
          </div>
          <div className="buttons-list">
            {meetingButtonList.map((item, key) => (
              <div
                key={key}
                className="button-component"
                data-port-id={item.id}
              >
                <span
                  className={`outline-btn ${
                    item.response_type === 'successful_booking'
                      ? 'primary-btn'
                      : 'danger-btn'
                  }`}
                >
                  {item.message}
                </span>
                <jtk-source port-id={item.id} endpoint={true} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
