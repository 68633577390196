import React from 'react';
import { Button, Popover, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../redux/chat/actions';

const CallHolder = props => {
  const dispatch = useDispatch();

  const callBtnLoading = useSelector(state => state.Chat.callBtnLoading);
  const popoverVisible = useSelector(state => state.Chat.popoverVisible);
  const selectedPreviousConversationIdentifier = useSelector(
    state => state.Chat.selectedPreviousConversationIdentifier,
  );

  const scheduleCall = () => {
    dispatch({ type: actions.SCHEDULE_CALL, callType: 'audio' });
  };
  const onVisibleChange = popoverVisible => {
    dispatch({
      type: actions.CHANGE_POPOVER_VISIBLE,
      payload: popoverVisible,
    });
  };

  return (
    <Tooltip title="Call" placement={'top'}>
      <Popover
        content={
          <div className="insert-app-content">
            <p>Start a video call</p>
            <Button
              type={'primary'}
              loading={callBtnLoading}
              onClick={scheduleCall}
              className="center"
            >
              Initiate call
            </Button>
          </div>
        }
        trigger={'click'}
        overlayClassName={'daily-co-popover'}
        visible={
          popoverVisible &&
          (!selectedPreviousConversationIdentifier ||
            (selectedPreviousConversationIdentifier &&
              props.isFromPreviousConversation))
        }
        onVisibleChange={onVisibleChange}
      >
        <i className={'fal fa-phone'} />
      </Popover>
    </Tooltip>
  );
};

export default CallHolder;
