import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import pageBuilderActions from '../pageBuilder/actions';
import displayActions from '../displayRules/actions';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  patchPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
} from '../../helpers/utility';
import { message } from 'antd';
import settings from '../../settings';
import {
  appendNewImages,
  prePopulateAlreadyAddedOptInFields,
  updateImageUrlInEditor,
} from '../../components/Forms/helpers';
import { store } from '../store';
import sharedActions from '../sharedReducers/actions';
import filterActions from '../filters/actions';
import { filterVariables } from '../constants';

export function* fetchFormList(params) {
  try {
    let searchValue = '';
    let status = '';
    let formType = '',
      { formSortKey, formSortOrder } = store.getState().Forms;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Forms.searchedValue;
    }
    if (params.selectedFormType) {
      formType = params.selectedFormType;
    } else {
      formType = store.getState().Forms.setFormType;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().Forms.selectedFormStatus;
    }
    if (![null, undefined].includes(params.sortKey)) {
      formSortKey = params.sortKey;
    }
    if (![null, undefined].includes(params.sortOrder)) {
      formSortOrder = params.sortOrder;
    }
    let queryParams = `status=${status}&search_value=${encodeURIComponent(
      searchValue,
    )}&form_type=${formType}&sort_key=${formSortKey}&sort_order=${formSortOrder}`;
    if (params.status === 'reorder') {
      queryParams = 'reorder=true&status=all&form_type=all';
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORM_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_FORM_LIST_FAILURE,
    });
    message.error('Fetch form list failed');
  }
}

export function* changeFormPosition(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms/update_forms_priority`;
    const data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Forms priority update failed');
  }
}

export function* createForm(params) {
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/forms`;
    const data = yield call(() => postPromise(url, params.payload));
    trackEvent('Created form');
    yield put({
      type: actions.CREATE_FORM_SUCCESS,
      payload: data.data,
    });
    updateBrowserHistory(`forms/${data.data.form.form_secret}`);
    if (data.data.form.version !== 3) {
      window.location.reload();
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_FORM_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}
export function* fetchOptInList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_OPT_IN_FIELDS_LIST_SUCCESS,
      payload: data.data,
    });
    prePopulateAlreadyAddedOptInFields(window.editor);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Properties fetch failed');
  }
}

export function* uploadFormImage(params) {
  try {
    const { formId } = store.getState().Forms;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/update_image?form_secret=${formId}`;
    let data = yield call(() => postPromise(url, params.payload));
    updateImageUrlInEditor(data.data.image_url);
    yield put({
      type: actions.UPLOAD_FORM_IMAGE_SUCCESS,
      payload: data.data.image_url,
    });
    // message.success('Image is uploaded successfully');
  } catch (error) {
    message.error('Image can not be uploaded');
    yield put({ type: actions.UPLOAD_FORM_IMAGE_FAILURE });
  }
}

export function* deleteFormImage(params) {
  try {
    const { formId } = store.getState().Forms;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/update_image?form_secret=${formId}`;
    yield call(() => patchPromise(url, params.payload));
    yield put({ type: actions.DELETE_FORM_IMAGE_SUCCESS });
    message.success('Image is deleted successfully');
  } catch (error) {
    message.error('Image can not be deleted');
    yield put({ type: actions.DELETE_FORM_IMAGE_FAILURE });
  }
}

export function* getFormEditorPageDetails(params) {
  let preview = false;
  if (params.isFromPreviewTemplate) {
    preview = params.isFromPreviewTemplate;
  }
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/forms/${
      params.id
    }${params.isFromPreviewTemplate ? `?preview=${preview}`: '' }`;
    let { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DETAILS_OF_FORM_EDITOR_PAGE_SUCCESS,
      payload: data.form,
    });
    yield put({
      type: pageBuilderActions.FETCH_BUILDER_DATA_SUCCESS,
      payload: data.form.builder_data,
    });
    // need to page builder for V3
    yield put({
      type: displayActions.SET_DISPLAY_RULES,
      payload: data.form.display_conditions,
    });
    yield put({
      type: sharedActions.SET_FORM_TEMPLATE_DETAILS,
      payload: data.form,
    });
    if (data.form.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: data.form.message_goal.goal_filter,
        filterName: filterVariables.formKeyGoal,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.GET_DETAILS_OF_FORM_EDITOR_PAGE_FAILURE,
    });
    message.error('Fetch form editor page details failed');
  }
}

export function* deleteForm(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_FORM_LIST,
      status: store.getState().Forms.selectedFormStatus,
    });
    yield put({
      type: actions.DELETE_FORM_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_FORM_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* updateForm(params) {
  let id = params.id ? params.id : store.getState().Forms.formId;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms/${id}`;
    let data = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.SET_LOADER_FOR_SAVING_DETAILS,
      formLoading: false,
    });
    if (params.form_version !== 3) {
      yield put({
        type: actions.UPDATE_HTML_FORM_DATA,
      });
    }
    yield put({
      type: pageBuilderActions.UPDATE_BUILDER_DATA_REFERENCE,
      payload: data.data.form,
    });
    if (params.statusChange || params.titleChange) {
      yield put({
        type: actions.UPDATE_FORM_SUCCESS,
        id: id,
        payload: data.data.form,
      });
    }
    if(params.isFromFormTemplate){
      yield put({
        type: actions.UPDATE_FORM_SUCCESS,
        id: id,
        payload: data.data.form,
      });
    }
    if (params.object.status) {
      yield put({
        type: actions.SET_EDITOR_STATUS_LOADING,
        payload: false,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (params.object.status) {
      yield put({
        type: actions.SET_EDITOR_STATUS_LOADING,
        payload: false,
      });
    }
    yield put({
      type: actions.SET_LOADER_FOR_SAVING_DETAILS,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* appendFormList(params) {
  try {
    const { isReOrderForm } = store.getState().Forms;
    let searchValue = '';
    let status = '';
    let formType = '';
    const { payload } = params,
      { formSortKey, formSortOrder } = store.getState().Forms;
    let lastFormId = '';
    if (payload && payload.lastFormId) {
      lastFormId = payload.lastFormId;
    }
    let queryParams = `reorder=true&last_form_secret=${lastFormId}&status=all&form_type=all`;

    if (!isReOrderForm) {
      if (params.searchValue) {
        searchValue = params.searchValue;
      } else {
        searchValue = store.getState().Forms.searchedValue;
      }
      if (params.status) {
        status = params.status;
      } else {
        status = store.getState().Forms.selectedFormStatus;
      }
      if (params.selectedFormType) {
        formType = params.selectedFormType;
      } else {
        formType = store.getState().Forms.setFormType;
      }
      queryParams = `status=${status}&search_value=${encodeURIComponent(
        searchValue,
      )}&last_form_secret=${lastFormId}&form_type=${formType}&sort_key=${formSortKey}&sort_order=${formSortOrder}`;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_FORM_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.APPEND_FORM_LIST_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* getFormTypesList() {
  try {
    let url = `${settings.ROOT_URL}form_types`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_FORM_TYPES_LIST_SUCCESS,
      payload: data.data.form_types,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.GET_FORM_TYPES_LIST_FAILURE,
      payload: false,
    });
    message.error('Fetch form types failed');
  }
}

export function* getFormTemplatesList(params) {
  let searchValue = '',
    formTypes = store.getState().Forms.selectedFormType;
  if (params.searchValue || params.searchValue === '') {
    searchValue = params.searchValue;
  }
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_templates?form_type=${formTypes}&search_value=${encodeURIComponent(
      searchValue,
    )}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORM_TEMPLATES_LIST_SUCCESS,
      payload: data.data.form_templates,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_FORM_TEMPLATES_LIST_FAILURE,
    });
    message.error('Fetch form types failed');
  }
}
export function* getImageGallery() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_images`;
    let data = yield call(() => getPromise(url));
    updateImageUrlInEditor(data.data.form_images);
    yield put({
      type: actions.UPDATE_IMAGE_CONTINUATION_TOKEN,
      payload: data.data.continuation_token,
    });
    yield put({
      type: actions.GET_IMAGE_GALLERY_SUCCESS,
      payload: data.data.form_images,
    });
  } catch (e) {
    message.error('Image gallery is not loaded');
  }
}
export function* appendImageGallery(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_images/?continuation_token=${encodeURIComponent(
      params.payload,
    )}`;
    let data = yield call(() => getPromise(url));
    appendNewImages(data.data.form_images);
    yield put({
      type: actions.UPDATE_IMAGE_CONTINUATION_TOKEN,
      payload: data.data.continuation_token,
    });
  } catch (e) {
    message.error('Image gallery is not loaded');
  }
}

export function* duplicateForm(params) {
  try {
    const { id, title } = params;
    const encodedTitle = encodeURIComponent(title);
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms/${id}/duplicate?title=${encodedTitle}`;
    let data = yield call(() => postPromise(url));
    message.success(data.message);
    yield put({
      type: actions.DUPLICATE_FORM_SUCCESS,
    });
    yield put({
      type: actions.SET_FORM_DRAWER_STATUS,
      payload: false,
      isDuplicate: true,
    });
    updateBrowserHistory(`forms/${data.data.form.form_secret}`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_FORM_FAILURE,
    });
    message.error(error.response.data.error.message);
  }
}

export function* fetchOnGoingReports(params) {
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/forms/${
      params.formSecret
    }/report`;
    if (params.isResponsePagination) {
      url += `?last_response_id=${params.payload.surveyLastResponseId}`;
    }
    const data = yield call(() => getPromise(url));
    if (!params.isResponsePagination) {
      yield put({
        type: actions.FETCH_FORMS_ONGOING_REPORTS_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_FORMS_ONGOING_REPORTS_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch Form responses failed');
    }
  }
}

export function* exportFormsCSV(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms/${
      params.formSecret
    }/export_report`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.EXPORT_FORMS_CSV_LOADER,
      payload: false,
    });
    yield put({
      type: actions.EXPORT_FORMS_CSV_MODAL,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.EXPORT_FORMS_CSV_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Export survey response failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_FORM_LIST, fetchFormList),
    takeEvery(actions.DELETE_FORM, deleteForm),
    takeEvery(actions.UPDATE_FORM, updateForm),
    takeEvery(actions.DUPLICATE_FORM, duplicateForm),
    takeEvery(actions.APPEND_FORM_LIST, appendFormList),
    takeEvery(actions.CHANGE_FORM_POSITION, changeFormPosition),
    takeEvery(actions.CREATE_FORM, createForm),
    takeEvery(actions.GET_OPT_IN_FIELDS_LIST, fetchOptInList),
    takeEvery(
      actions.GET_DETAILS_OF_FORM_EDITOR_PAGE,
      getFormEditorPageDetails,
    ),
    takeEvery(actions.UPLOAD_FORM_IMAGE, uploadFormImage),
    takeEvery(actions.DELETE_FORM_IMAGE, deleteFormImage),
    takeEvery(actions.GET_FORM_TYPES_LIST, getFormTypesList),
    takeEvery(actions.FETCH_FORM_TEMPLATES_LIST, getFormTemplatesList),
    takeEvery(actions.GET_IMAGE_GALLERY, getImageGallery),
    takeEvery(actions.APPEND_IMAGE_GALLERY, appendImageGallery),
    takeEvery(actions.FETCH_FORMS_ONGOING_REPORTS, fetchOnGoingReports),
    takeEvery(actions.EXPORT_FORMS_RESPONSE_CSV, exportFormsCSV),
  ]);
}
