const actions = {
  UPDATE_NOTIFICATION_CHANGES: 'UPDATE_NOTIFICATION_CHANGES',
  UPDATE_NOTIFICATION_CHANGES_SUCCESS: 'UPDATE_NOTIFICATION_CHANGES_SUCCESS',
  UPDATE_NOTIFICATION_CHANGES_FAILURE: 'UPDATE_NOTIFICATION_CHANGES_FAILURE',
  GET_NOTIFICATION_DETAILS: 'GET_NOTIFICATION_DETAILS',
  GET_NOTIFICATION_DETAILS_SUCCESS: 'GET_NOTIFICATION_DETAILS_SUCCESS',
  GET_NOTIFICATION_DETAILS_FAILURE: 'GET_NOTIFICATION_DETAILS_FAILURE',
};

export default actions;
