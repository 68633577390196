import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../store';
import formAction from '../../forms/actions';

export function* fetchPageBuilderTemplateList(params) {
  try {
    let status = '',
      formType = '',
      search = '';
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().FormTemplateReducer.selectedTemplateStatus;
    }
    if (params.formType) {
      formType = params.formType;
    } else {
      formType = store.getState().FormTemplateReducer.selectedFormTypeStatus;
    }
    if (status === 'all' || status === undefined) {
      status = '';
    }
    if (params.searchValue || params.searchValue === '') {
      search = params.searchValue;
    }
    let url =
      formType === 'all' || formType === undefined
        ? `${
            settings.ROOT_URL
        }projects/${fetchProjectSecretKey()}/form_templates?status=${status}&search_value=${search}`
        : `${
          settings.ROOT_URL
          }projects/${fetchProjectSecretKey()}/form_templates?status=${status}&form_type=${formType}`;
    if (params.fromTemplateList) {
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/form_templates?status=published&form_type=${
        params.formType
      }&search_value=${search}`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORMS_TEMPLATE_LIST_SUCCESS,
      payload: data.data,
    });
    if (params.searchValue) {
      yield put({
        type: actions.FILTER_FORMS_TEMPLATE_LIST,
        payload: search,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_FORMS_TEMPLATE_LIST_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* createPageBuilderFormTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }/projects/${fetchProjectSecretKey()}/form_templates`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_FORMS_TEMPLATE_ID,
      form_secret: data.data.form_template.form_secret,
      id: data.data.form_template.id,
    });
    if (params.messageType === 'form') {
      updateBrowserHistory(`form-template/${data.data.form_template.id}`);
    }
    yield put({
      type: actions.CREATE_FORMS_TEMPLATE_SUCCESS,
      payload: data.data.form_template,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_FORMS_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}
export function* deletePageBuilderFormTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_templates/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_FORMS_TEMPLATE_LIST,
      status: store.getState().FormTemplateReducer.selectedTemplateStatus,
      messageType: params.messageType,
    });
    yield put({
      type: actions.DELETE_FORMS_TEMPLATE_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_FORMS_TEMPLATE_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}
export function* duplicatePageBuilderTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_templates?template_id=${
      params.id
    }`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_FORMS_TEMPLATE_ID,
      id: data.data.form_template.id,
    });
    if (params.messageType === 'form') {
      updateBrowserHistory(`form-template/${data.data.form_template.id}`);
    }
    yield put({
      type: actions.DUPLICATE_FORMS_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_FORMS_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}
export function* getEditorDetails(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }/projects/${fetchProjectSecretKey()}/form_templates/${params.id}`;
    const data = yield call(() => getPromise(url, params.payload));
    yield put({
      type: formAction.GET_DETAILS_OF_FORM_EDITOR_PAGE,
      id: data.data.form_template.form_secret,
      isFromFormTemplate: true,
    });
    yield put({
      type: actions.GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE_SUCCESS,
      chooseType: data.data.form_template.form_type,
      payload: data.data.form_template,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}
export function* updateDetailsPageBuilderFormTemplate(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }/projects/${fetchProjectSecretKey()}/form_templates/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_EDIT_DETAILS_SUCCESS,
      payload: data.data.form_template,
      isPublishAction: params.isPublishAction,
    });
    if (params.isFromTemplateTable) {
      yield put({
        type: actions.FETCH_FORMS_TEMPLATE_LIST,
        status: store.getState().MessageTemplateReducer.selectedTemplateStatus,
        messageType: params.messageType,
      });
    }
    message.success('Updated Form template details successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_EDIT_DETAILS_FAILURE,
      changeStatus: params.changeStatus,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Chat template update failed');
    }
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_FORMS_TEMPLATE_LIST, fetchPageBuilderTemplateList),
    takeEvery(actions.CREATE_FORMS_TEMPLATE, createPageBuilderFormTemplate),
    takeEvery(actions.DUPLICATE_FORMS_TEMPLATE, duplicatePageBuilderTemplate),
    takeEvery(actions.DELETE_FORMS_TEMPLATE, deletePageBuilderFormTemplate),
    takeEvery(
      actions.UPDATE_TEMPLATE_EDIT_DETAILS,
      updateDetailsPageBuilderFormTemplate,
    ),
    takeEvery(
      actions.UPDATE_EDIT_DETAILS,
      updateDetailsPageBuilderFormTemplate,
    ),
    takeEvery(
      actions.GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE,
      getEditorDetails,
    ),
  ]);
}
