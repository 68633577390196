import {Modal} from 'antd';
import moment from 'moment';
import { setSnoozeCalculation } from '../../helpers/utility';
import React, { useState } from 'react';
import { store } from '../../redux/store';
import actions from '../../redux/chat/actions';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const confirm = Modal.confirm;

const Snooze = props => {
  const [showCustomSnoozeModal, setShowCustomSnoozeModal] = useState(false);
  const [customSnoozeTime, setCustomSnoozeTime] = useState(undefined);
  const [snoozeButtonStatus, setSnoozeButtonStatus] = useState(true);
  const {
    bulkSnooze,
    sendAndSnooze,
    selectedConversations,
    indeterminate,
    sendReply,
    currentConversationId,
    status,
    isOpen,
    anchorRef,
    handleSnoozeMenu,
  } = props;

  // Verified
  const showLaterTodayOption = () => {
    let today = moment();
    let tomorrow = moment().add(4, 'hour');
    return moment(today).isSame(tomorrow, 'day');
  };

  const snooze = e => {
    const key = e.value;
    if(bulkSnooze) {
        showBulkSnoozeMenu(key)
    } else {
      if(sendAndSnooze) {
        onSendAndSnooze(key)
      } else {
        snoozeConversation(key)
      }
    }
  };

  // Bulk snooze conversations
  const showBulkSnoozeMenu = value => {
    hideCustomSnoozeModal();
    confirm({
      title: 'Snooze the conversation(s)?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        let selectedTime = (value !== "custom" ? setSnoozeCalculation(value) : {format: 'custom', time: customSnoozeTime});
        store.dispatch({
          type: actions.SNOOZE_CONVERSATION,
          conversation_identifiers: indeterminate === true ? selectedConversations : [],
          select_all: indeterminate !== true,
          time_key: selectedTime.format,
          time_value: selectedTime.time,
        });
      },
      onCancel() {},
    });
  };

  // Send and snooze conversation
  const onSendAndSnooze = value => {
    hideCustomSnoozeModal();
    let selectedTime = (value !== "custom" ? setSnoozeCalculation(value) : {format: 'custom', time: customSnoozeTime});
    store.dispatch({
      type: actions.SEND_AND_SNOOZE_CONVERSATION,
      time_key: selectedTime.format,
      time_value: selectedTime.time,
    });
    store.dispatch({
      type: actions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
      payload: {
        conversation_identifier: currentConversationId,
        status: 'pending',
      },
    });
    sendReply('pending');
  };

  // Snooze conversation
  const snoozeConversation = value => {
    hideCustomSnoozeModal();
    let selectedTime = (value !== "custom" ? setSnoozeCalculation(value) : {format: 'custom', time: customSnoozeTime});
    store.dispatch({
      type: actions.SNOOZE_CONVERSATION,
      conversation_identifiers: [currentConversationId],
      select_all: false,
      time_key: selectedTime.format,
      time_value: selectedTime.time,
      currentStatus: status,
    });

    store.dispatch({
      type: actions.UPDATE_CHAT_ROOMS_CONVERSATION_STATUS,
      payload: {
        conversation_identifier: currentConversationId,
        status: 'pending',
      },
    });

    handleSnoozeMenu(false);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const setSnoozeTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    if (selectedDate >= currentDate) {
      setSnoozeButtonStatus( false)
    } else {
      setSnoozeButtonStatus( true)
    }
    setCustomSnoozeTime( time);
  }

  const onShowCustomSnoozeModal = () => {
    setShowCustomSnoozeModal( true);
  }

  const hideCustomSnoozeModal = () => {
    setShowCustomSnoozeModal( false);
  }

    return (
      <>
        <div className={`snooze-overlay ${isOpen ? "" : "hidden"}`} onClick={() => handleSnoozeMenu(false)}></div>
      <ControlledMenu
        anchorRef={anchorRef}
        isOpen={isOpen}
        // onClose={() => handleSnoozeMenu(false)}
        className={'snooze-list'}
      >
        {showLaterTodayOption() && (
          <MenuItem key="later" value="later" onClick={e => snooze(e)}>
              Later today <span> in 4 hours</span>
            </MenuItem>
          )}
          <MenuItem key="tomorrow" value="tomorrow" onClick={e => snooze(e)}>
            Tomorrow{' '}
            <span>
              {moment()
                .add(1, 'day')
                .format('ddd')}{' '}
              7 am{' '}
            </span>
          </MenuItem>
          <MenuItem key="monday" value="monday" onClick={e => snooze(e)}>
          Next monday <span>Mon 7 am</span>
        </MenuItem>
        <MenuItem key="week" value="week" onClick={e => snooze(e)}>
          One week{' '}
          <span>
            {moment()
              .add(1, 'week')
              .format('ddd DD')}{' '}
          </span>
        </MenuItem>
        <MenuItem key="month" value="month" onClick={e => snooze(e)}>
            One month{' '}
            <span>
              {moment()
                .add(1, 'month')
                .format('MMM D')}{' '}
            </span>
          </MenuItem>
          <MenuItem key="custom" value="custom" onClick={onShowCustomSnoozeModal}>
            Custom
          </MenuItem>
        </ControlledMenu>

      <Modal
        visible={showCustomSnoozeModal}
        wrapClassName={'custom-snooze-modal'}
        destroyOnClose={true}
        onCancel={hideCustomSnoozeModal}
        okText="Snooze"
        onOk={() => snooze({value: "custom"})}
        okButtonProps={{disabled: snoozeButtonStatus}}
      >
        <DatePicker
          selected={customSnoozeTime}
          onChange={(date) => setSnoozeTime(date)}
          filterTime={filterPassedTime}
          dateFormat="DD/MM/YYYY HH:mm Z"
          showTimeSelect
          inline
        />
      </Modal>
    </>
  );
};
export default Snooze;
