import { Avatar, List, Tooltip } from 'antd';
import React, { Fragment } from 'react';

let WorkSpaceListItem = ({ project }) => {
  const getCompanyProfile = (projectName, logo) => {
    if (logo.endsWith('/default-office@3x.png')) {
      let name = projectName.slice(0, 1).toUpperCase();
      const getRandomColor = () =>
        `${Math.floor(Math.random() * 255)},${Math.floor(
          Math.random() * 255,
        )},${Math.floor(Math.random() * 255)}`;
      let color = getRandomColor();
      // extractProjectFirstLetterWithRandomColor
      return (
        <div
          className="project-image"
          style={{ color: `rgb(${color})`, background: `rgb(${color},0.2)` }}
        >
          {name}
        </div>
      );
    } else {
      return <Avatar src={logo} />;
    }
  };

  return (
    <List.Item>
      <List.Item.Meta
        avatar={getCompanyProfile(project.project_name, project.logo)}
        title={
          <Fragment>
            <Tooltip title={project.project_name} placement="top">
              {project.project_name}
            </Tooltip>
            <Tooltip title={project.domain} placement="top" className="domain">
              ({project.domain})
            </Tooltip>
          </Fragment>
        }
      />
      <div className="key">{project.secret_key}</div>
      <i className="fal fa-arrow-right" />
    </List.Item>
  );
};

export default WorkSpaceListItem;
