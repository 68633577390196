import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/knowledgeBase/actions';
import { pluralise } from '../../helpers/utility';
const MoveCollectionsModal = (props) => {
  const dispatch = useDispatch(),
    { treeData, updateTreeData } = props,
    collectionModalVisibility = useSelector(
      ({ KnowledgeBase }) => KnowledgeBase.collectionModalVisibility,
    ),
    isArticleSelected = useSelector(
      ({ KnowledgeBase }) => KnowledgeBase.isArticleSelected,
    ),
    parentCollectionId = useSelector(
      ({ KnowledgeBase }) => KnowledgeBase.parentCollectionId,
    ),
    collectionLoading = useSelector(
      ({ KnowledgeBase }) => KnowledgeBase.collectionLoading,
    ),
    [selectedItems, setSelectedItems] = useState([]),
    [newTreeData, setNewTreeData] = useState([]),
    inputRef = useRef(null),
    [query, setQuery] = useState(''),
    filteredTreeData = newTreeData.filter((data) =>
      data.text?.toLowerCase().includes(query.toLowerCase()),
    );

  useEffect(() => {
    setQuery('');
    inputRef?.current?.focus();
    setSelectedItems([]);
  }, [collectionModalVisibility]);

  useEffect(() => {
    let tempData;
    let parentID = treeData.find(
      (data) => data.secret_key === parentCollectionId,
    )?.id;
    let parentIDs = [parentID];
    if (isArticleSelected) {
      tempData = treeData.filter((data) => {
        const { parent, duplicate, type } = data;
        return parent !== parentID && !duplicate && type === 'article';
      });
      setNewTreeData(tempData);
    } else {
      const collectionsTree = treeData.filter(
        (data) => data.type === 'collection',
      );
      collectionsTree.map((data) => {
        if (data.id === parentID) {
          parentIDs.push(data.parent);
        } else if (data.parent === parentID) {
          parentIDs.push(data.id);
        }
      });
      tempData = collectionsTree.filter((data) => !parentIDs.includes(data.id));
      setNewTreeData(tempData);
    }
  }, [isArticleSelected]);
  const handleCancel = () => {
    collectionLoading &&
      dispatch({
        type: actions.CHANGE_COLLECTIONS_LOADER,
        payload: false,
      });
    dispatch({
      type: actions.UPDATE_COLLECTIONS_MODAL_DETAILS,
      payload: {
        isVisible: false,
      },
    });
  };
  const handleAdd = () => {
    dispatch({
      type: actions.CHANGE_COLLECTIONS_LOADER,
      payload: true,
    });
    const target = treeData.find(
      (val) => val.secret_key === parentCollectionId,
    );
    if (isArticleSelected) {
      dispatch({
        type: actions.SET_ARTICLE_POSITION,
        payload: {
          article_ids: selectedItems,
          target_category: Number(target.id.substring(2)),
        },
        updateTreeData: (source, target, countDetails) =>
          updateTreeData(source, target, countDetails),
      });
    } else {
      dispatch({
        type: actions.SET_COLLECTIONS_POSITION,
        payload: {
          source_categories: selectedItems.map((item) =>
            Number(item.substring(2)),
          ),
          target_category: Number(target.id.substring(2)),
        },
        updateTreeData: (source, target, countDetails) =>
          updateTreeData(source, target, countDetails),
      });
    }
  };
  const handleSelect = (itemId) => {
    query && inputRef?.current?.focus();
    if (selectedItems.includes(itemId)) {
      return setSelectedItems(selectedItems.filter((item) => item !== itemId));
    }
    return setSelectedItems((prev) => [...prev, itemId]);
  };

  const count = selectedItems.length || '';
  return (
    <Modal
      destroyOnClose={true}
      title={`Add ${isArticleSelected ? 'Articles' : 'Collections'}`}
      visible={collectionModalVisibility}
      className={'move-collections-modal'}
      width={600}
      onCancel={handleCancel}
      onOk={handleAdd}
      footer={[
        <Button key={'cancel'} onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key={'add'}
          type={'primary'}
          disabled={count === '' || collectionLoading}
          loading={collectionLoading}
          onClick={handleAdd}
        >
          Add {count}{' '}
          {pluralise(isArticleSelected ? 'article' : 'collection', count)}
        </Button>,
      ]}
    >
      <Input
        ref={inputRef}
        suffix={<i className={'fal fa-magnifying-glass'} />}
        placeholder={`Search ${
          isArticleSelected ? 'articles' : 'collections'
        }...`}
        className={'search-box'}
        onChange={(event) => setQuery(event.target.value)}
      />
      <div className={'list-container'}>
        {filteredTreeData.map((item) => (
          <div
            key={item.id}
            className={'list-item'}
            onClick={() => handleSelect(item.id)}
          >
            <Checkbox
              checked={selectedItems.includes(item.id)}
              key={item.id}
              value={item.id}
              className={'mr-2'}
            >
              <ItemIcon item={item} />
              <span onClick={(e) => e.stopPropagation()}>{item.text}</span>
            </Checkbox>
          </div>
        ))}
      </div>
    </Modal>
  );
};

const ItemIcon = ({ item }) => {
  if (item.custom_icon_url) {
    return (
      <img
        onClick={(e) => e.stopPropagation()}
        src={item.custom_icon_url}
        alt={'Icon'}
      />
    );
  }
  return (
    <i
      onClick={(e) => e.stopPropagation()}
      className={`${item.category_icon ?? 'fal fa-file-alt'}`}
    />
  );
};
export default MoveCollectionsModal;
