import React, { Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { store } from '../../../redux/store';
import actions from '../../../redux/chat/actions';

import { Input } from 'antd';
import {
  extractConversationIdentifier,
  generateRandomId,
} from '../../../helpers/utility';
import _ from 'lodash';

const Gif = props => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');
  const {
    isEmptyGif,
    gifOffset,
    hideGif,
    gifResource,
    postNote,
    postReply,
    visible,
    composerState,
  } = props;

  useEffect(
    () => {
      if (loading) {
        setLoading(false);
      }
    },
    [loading],
  );

  useEffect(
    () => {
      if (!visible && !!queryString) {
        setQueryString('');
        store.dispatch({
          type: actions.FETCH_GIF,
          queryString: '',
          clearGif: true,
        });
      }
    },
    [visible, queryString],
  );

  const debounce = _.debounce(value => {
    store.dispatch({
      type: actions.FETCH_GIF,
      queryString: value,
      clearGif: true,
    });
  }, 500);

  const handleSearch = event => {
    const value = event.target.value;
    debounce(value);
    setLoading(true);
    setQueryString(value);
  };

  const handleInfiniteOnLoad = () => {
    if (!isEmptyGif) {
      store.dispatch({
        type: actions.FETCH_GIF,
        offset: gifOffset,
        queryString: queryString,
      });
      setLoading(true);
    }
  };

  const handleClick = gif => {
    hideGif();
    let message = {
      message_content: gif.media[0].tinygif.url,
      original_gif: gif.media[0].gif.url,
      message_temp_id: generateRandomId(extractConversationIdentifier()),
      is_gif: true,
    };
    if (composerState === 'reply') {
      postReply(message);
    } else {
      postNote(message);
    }
  };

  if (gifResource.length <= 0) {
    return (
      <Fragment>
        <Input
          suffix={<i className={'fal fa-magnifying-glass'} />}
          placeholder="Search GIFs..."
          onChange={event => handleSearch(event)}
          value={queryString}
          ref={ref => ref && ref.focus()}
        />
        <div className="gif-container">
          <div className="no-gif">No GIFs found</div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Input
        suffix={<i className={'fal fa-magnifying-glass'} />}
        placeholder="Search GIFs..."
        onChange={event => handleSearch(event)}
        value={queryString}
        ref={ref => ref && ref.focus()}
      />
      <div className="gif-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading}
          useWindow={false}
          threshold={1}
        >
          {gifResource.map((gif, index) => (
            <div
              className="gif-image-container"
              key={index}
              onClick={() => handleClick(gif)}
            >
              <div className={'gif-image-body'}>
                <img
                  className="gif-image"
                  src={gif.media[0].nanogif.url}
                  alt="GIF"
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      '<svg xmlns="http://www.w3.org/2000/svg" id="send-button_1_" width="22" height="18.857" data-name="send-button (1)" viewBox="0 0 22 18.857">&gt;<defs></defs><g id="send"><path id="Path_1663" d="M0 57.107l22-9.429L0 38.25v7.333l15.714 2.1L0 49.774z" fill="#fff" data-name="Path 1663" transform="translate(0 -38.25)"></path></g></svg>',
                  }}
                />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </Fragment>
  );
};

export default Gif;
