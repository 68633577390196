const actions = {
  SET_MEETING_TAB_KEY: 'SET_MEETING_TAB_KEY',
  FETCH_MEETINGS: 'FETCH_MEETINGS',
  FETCH_MEETINGS_SUCCESS: 'FETCH_MEETINGS_SUCCESS',
  FETCH_MEETINGS_FAILURE: 'FETCH_MEETINGS_FAILURE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  DELETE_MEETING: 'DELETE_MEETING',
  SET_MEETING_ID: 'SET_MEETING_ID',
  FETCH_MEETING_DATA: 'FETCH_MEETING_DATA',
  FETCH_MEETING_DATA_SUCCESS: 'FETCH_MEETING_DATA_SUCCESS',
  UPDATE_MEETING_DETAILS: 'UPDATE_MEETING_DETAILS',
  UPDATE_MEETING_DETAILS_SUCCESS: 'UPDATE_MEETING_DETAILS_SUCCESS',
  CREATE_MEETING_LINK: 'CREATE_MEETING_LINK',
  CREATE_MEETING_LINK_SUCCESS: 'CREATE_MEETING_LINK_SUCCESS',
  CREATE_MEETING_LINK_FAILURE: 'CREATE_MEETING_LINK_FAILURE',
  SET_MEETING_DRAWER_STATUS: 'SET_MEETING_DRAWER_STATUS',
  UPDATE_CONFIGURATION_DETAILS: 'UPDATE_CONFIGURATION_DETAILS',
  UPDATE_CONFIGURATION_DETAILS_SUCCESS: 'UPDATE_CONFIGURATION_DETAILS_SUCCESS',
  UPDATE_AVAILABILITY_DETAILS: 'UPDATE_AVAILABILITY_DETAILS',
  UPDATE_AVAILABILITY_DETAILS_SUCCESS: 'UPDATE_AVAILABILITY_DETAILS_SUCCESS',
  UPDATE_FORM_QUESTIONS: 'UPDATE_FORM_QUESTIONS',
  UPDATE_FORM_QUESTIONS_SUCCESS: 'UPDATE_FORM_QUESTIONS_SUCCESS',
  MEETING_LINK_AUTO_SAVE: 'MEETING_LINK_AUTO_SAVE',
  RESET_MEETING_DATA: 'RESET_MEETING_DATA',
  DELETE_MEETING_PROCESS: 'DELETE_MEETING_PROCESS',
  UPDATE_REDIRECT_URL: 'UPDATE_REDIRECT_URL',
  UPDATE_REDIRECT_URL_SUCCESS: 'UPDATE_REDIRECT_URL_SUCCESS',
  DUPLICATE_MEETING: 'DUPLICATE_MEETING',
};

export default actions;
