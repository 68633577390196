import React from 'react';
import { Input } from 'antd';

import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

const { TextArea } = Input;

export class NoteBlock extends BaseEditableComponent {
  render() {
    const { value } = this.state;
    return (
      <div className="bot-component note-component">
        <ActionButtons isNotAllowToEdit={true} />
        <div className={'body'}>
          <TextArea value={value} autoSize />
        </div>
      </div>
    );
  }
}
