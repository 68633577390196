import React, { useEffect } from 'react';
import { history } from '../redux/store';
import * as queryString from 'query-string';
import actions from '../redux/settings/actions';
import { useSelector, useDispatch } from 'react-redux';
import SpinLoader from './Shared/SpinLoader';

function Oauth2Callback(props) {
  const pageLoader = useSelector(({ Settings }) => Settings.pageLoader);
  const dispatch = useDispatch();
  useEffect(() => {
    const { location } = props;
    if (location) {
      const { code, scope } = queryString.parse(location.search);
      if (code && scope) {
        localStorage.setItem('code', code);
        localStorage.setItem('scope', scope);
        dispatch({
          type: actions.GOOGLE_ACCOUNT_OAUTH,
          payload: {
            code,
            scope,
          },
        });
      } else {
        localStorage.removeItem('code');
        history.push('/projects/oauth2callback');
      }
    }
  }, []);

  if (pageLoader) {
    return (
      <div className={'calendar-integration-loader'}>
        <SpinLoader />
      </div>
    );
  }
  return '';
}

export default Oauth2Callback;
