import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { store } from '../store';
import settings from '../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  updateBrowserHistory,
} from '../../helpers/utility';
import { message } from 'antd';
import { updateImageUrlInEditor } from '../../components/Forms/helpers';

export function* getTemplateList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_templates?form_type=${
      params.payload
    }&search_value=${encodeURIComponent('')}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_TEMPLATES_SUCCESS,
      payload: data.data.form_templates,
    });
  } catch (e) {
    yield put({ type: actions.GET_TEMPLATES_FAILURE });
  }
}
export function* updateFormTemplate(params) {
  try {
    const templateData = { form_template: params.payload };
    let url = `${settings.ROOT_URL}form_templates/${params.payload.id}`;
    let data = yield call(() => patchPromise(url, templateData));
    yield put({
      type: actions.UPDATE_FORM_TEMPLATE_SUCCESS,
      payload: data.data.form_template,
    });
    message.success('Template has been updated successfully');
  } catch (e) {
    yield put({ type: actions.UPDATE_FORM_TEMPLATE_FAILURE });
    message.error('Template can not be updated');
  }
}
export function* createFormTemplate(params) {
  try {
    const templateData = { form_template: params.payload };
    let url = `${settings.ROOT_URL}form_templates`;
    let data = yield call(() => postPromise(url, templateData));
    yield put({
      type: actions.CREATE_FORM_TEMPLATE_SUCCESS,
      payload: data.data.form_template,
    });
    message.success('Template has been created successfully');
    yield put({ type: actions.FORM_TEMPLATE_DRAWER_VISIBLE, payload: false });
    updateBrowserHistory(`template/${data.data.form_template.id}`);
    localStorage.setItem(
      'templateDetails',
      JSON.stringify(data.data.form_template),
    );
    window.location.reload();
  } catch (e) {
    yield put({ type: actions.CREATE_FORM_TEMPLATE_FAILURE });
    message.error('Template can not be created');
  }
}
export function* getFormTypes() {
  try {
    let url = `${settings.ROOT_URL}form_types`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_FORM_TYPES_SUCCESS,
      payload: data.data.form_types,
    });
  } catch (e) {
    yield put({
      type: actions.GET_FORM_TYPES_FAILURE,
    });
  }
}
export function* deleteFormTemplate(params) {
  try {
    let url = `${settings.ROOT_URL}form_templates/${params.payload}`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.GET_TEMPLATES,
      payload: store.getState().Templates.selectedFormType,
    });
  } catch (e) {
    message.error('Form Template can not be deleted');
  }
}
export function* uploadTemplateImage(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/update_image?image_type=template_image`;
    let data = yield call(() => postPromise(url, params.payload));
    updateImageUrlInEditor(data.data.image_url);
  } catch (e) {
    message.error('Image can not be uploaded');
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TEMPLATES, getTemplateList),
    takeEvery(actions.UPDATE_FORM_TEMPLATE, updateFormTemplate),
    takeEvery(actions.CREATE_FORM_TEMPLATE, createFormTemplate),
    takeEvery(actions.GET_FORM_TYPES, getFormTypes),
    takeEvery(actions.DELETE_FORM_TEMPLATE, deleteFormTemplate),
    takeEvery(actions.UPLOAD_TEMPLATE_IMAGE, uploadTemplateImage),
  ]);
}
