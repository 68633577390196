import { all, takeEvery, put } from 'redux-saga/effects';
import actions from '../pageBuilder/actions';
import { unAuthorisedRedirection } from '../../helpers/utility';
import { message } from 'antd';

export function* fetchBuilderData(params) {
  try {
    // const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/builder/${params.id}`;
    // const data = yield call(() => getPromise(url));
    const data = {};
    yield put({
      type: actions.FETCH_BUILDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch builder data failed');
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_BUILDER_DATA, fetchBuilderData)]);
}
