import React, { Component } from 'react';
import './style.scss';
import { Card, Button, Input, Spin, Icon } from 'antd';
import { satisfactionRatings } from '../../components/Chat/Messages/constants';
import actions from '../../redux/auth/actions';
import { store } from '../../redux/store';
import { getQueryParamsValue, trimFunction } from '../../helpers/utility';
import connect from 'react-redux/es/connect/connect';
import settings from '../../settings';

const { TextArea } = Input;

class SatisfactionRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: '',
      comment: '',
    };
  }

  submitFeedback = () => {
    const { comment, rating } = this.state;
    store.dispatch({
      type: actions.SET_SATISFACTION_RATING_COMMENT_LOADER,
      payload: true,
    });
    let object = {
      rating: rating,
      comment: trimFunction(comment),
      token: getQueryParamsValue('token'),
      satisfaction_status: 'completed',
    };
    this.updateSatisfactionRating(object);
  };

  updateSatisfactionRating = object => {
    store.dispatch({
      type: actions.UPDATE_SATISFACTION_RATING,
      payload: object,
    });
  };

  componentDidMount() {
    let rating = getQueryParamsValue('rating');
    this.setState({ rating: parseInt(rating) }, () => {
      let object = {
        rating: rating,
        token: getQueryParamsValue('token'),
      };
      this.updateSatisfactionRating(object);
    });
    document.addEventListener('gistReady', function() {
      window.gist.chat('hide');
    });
  }

  updateComment = event => {
    this.setState({ comment: event.target.value });
  };

  updateRating = rating => {
    this.setState({ rating }, () => {
      let object = {
        rating: rating,
        token: getQueryParamsValue('token'),
      };
      this.updateSatisfactionRating(object);
    });
  };

  render() {
    const { comment, rating } = this.state,
      {
        satisfactionRatingCommentLoader,
        isSatisfactionRatingSubmitted,
        satisfactionStatus,
        satisfactionTranslateKeys,
      } = this.props,
      antIcon = <Icon type="loading" spin />;
    if (isSatisfactionRatingSubmitted) {
      if (satisfactionStatus === 'completed') {
        return (
          <div className="satisfaction-rating success">
            <div className="rating-card">
              <Card bordered={false}>
                <div className="success-text">
                  {satisfactionTranslateKeys &&
                    satisfactionTranslateKeys.thank_you}
                </div>
                <div className={'thumb'}>
                  <span role="img" aria-label={'thumb'}>
                    👍
                  </span>
                </div>
              </Card>
            </div>
          </div>
        );
      }

      return (
        <div className="satisfaction-rating">
          <div className="rating-card">
            <h1>{satisfactionTranslateKeys &&
              satisfactionTranslateKeys.thanks}</h1>
            <p className="greeting-text">
              {satisfactionTranslateKeys &&
                satisfactionTranslateKeys.change_rate_submit}
            </p>
            <Card bordered={false}>
              <span>
                {satisfactionTranslateKeys &&
                  satisfactionTranslateKeys.add_comment}
              </span>
              <div className={'rating-emoji'}>
                {satisfactionRatings.map((satisfactionRating, index) => (
                  <span
                    key={index}
                    className={
                      rating === satisfactionRating.rating
                        ? 'selected'
                        : 'unselected'
                    }
                    onClick={() => this.updateRating(satisfactionRating.rating)}
                  >
                    <img
                      src={`${settings.EMOJI_URL}${satisfactionRating.val}`}
                      alt={'emoji.svg'}
                    />
                  </span>
                ))}
              </div>
              <div className="form">
                <label>
                  {satisfactionTranslateKeys &&
                    satisfactionTranslateKeys.rate_support}
                </label>
                <TextArea
                  className="form-item input-textarea"
                  rows={3}
                  onChange={this.updateComment}
                  cols={50}
                />
                <Button
                  className="btn btn-primary btn-sm"
                  onClick={this.submitFeedback}
                  loading={!!(satisfactionRatingCommentLoader && comment)}
                >
                  {satisfactionTranslateKeys &&
                    satisfactionTranslateKeys.submit_feedback}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="satisfaction-rating">
        <Spin indicator={antIcon} className={'initial-loading'} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    satisfactionRating,
    satisfactionComment,
    satisfactionRatingLoader,
    isSatisfactionRatingSubmitted,
    satisfactionRatingCommentLoader,
    satisfactionStatus,
    satisfactionTranslateKeys,
  } = state.Auth;
  return {
    satisfactionRating,
    satisfactionComment,
    satisfactionRatingLoader,
    isSatisfactionRatingSubmitted,
    satisfactionRatingCommentLoader,
    satisfactionStatus,
    satisfactionTranslateKeys,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(SatisfactionRating);
