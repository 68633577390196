import { store } from '../../../../redux/store';

export function getTableValues() {
  const { tableState } = store.getState().SharedReducers;
  let detailObj = {
    tableState,
    lastId: 'last_triggered_chat_id',
    api: 'triggered_chats',
  };
  switch (tableState) {
    case 'chatOnGoing':
      return {
        ...detailObj,
        sortKey: store.getState().chatOngoingPost.sortKey,
        sortOrder: store.getState().chatOngoingPost.sortOrder,
        status: store.getState().chatOngoingPost.status,
        searchValue: store.getState().chatOngoingPost.searchValue,
        messageType: 'on_going_announcement',
      };
    case 'chatOneOff':
      return {
        ...detailObj,
        sortKey: store.getState().ChatOneOffPost.sortKey,
        sortOrder: store.getState().ChatOneOffPost.sortOrder,
        status: store.getState().ChatOneOffPost.status,
        searchValue: store.getState().ChatOneOffPost.searchValue,
        messageType: 'one_off_announcement',
      };
    default:
      return detailObj;
  }
}
