import actions from './actions';
import { cloneDeep } from 'lodash';

let initState = {
  meetingTabKey: '1',
  meetingsTableLoader: false,
  meetingsTableData: [],
  searchTextValue: '',
  currentMeetingID: null,
  meetingData: {},
  showMeetingCreateDrawer: false,
  createMeetingLoader: false,
  profileLinkName: '',
  lastSavedAt: null,
  isAutoSaving: false,
  googleCalendarSynced: true,
  zoomIntegrated: false,
  isDeletingMeetingProcess: false,
  googleCalendarIntegrated: false,
};

export default function meetingReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_MEETING_TAB_KEY:
      return {
        ...state,
        meetingTabKey: action.payload,
      };
    case actions.FETCH_MEETINGS:
      return {
        ...state,
        meetingsTableLoader: true,
      };
    case actions.FETCH_MEETINGS_SUCCESS:
      let meetingsList = action.payload.data.meeting_links;
      if (action.page) {
        meetingsList = cloneDeep(state.meetingsTableData).concat(
          action.payload.data.meeting_links,
        );
      }
      return {
        ...state,
        meetingsTableLoader: false,
        meetingsTableData: meetingsList,
        profileLinkName: action.payload.data.profile_link,
        zoomIntegrated: action.payload.data.zoom_integration,
        googleCalendarSynced: action.payload.data.google_calendar_synced,
      };
    case actions.FETCH_MEETINGS_FAILURE:
      return {
        ...state,
        meetingsTableLoader: false,
        meetingsTableData: [],
      };
    case actions.SET_SEARCH_TEXT:
      return {
        ...state,
        searchTextValue: action.payload,
      };
    case actions.SET_MEETING_ID:
      return {
        ...state,
        currentMeetingID: action.payload,
      };
    case actions.FETCH_MEETING_DATA_SUCCESS:
      return {
        ...state,
        meetingData: action.payload.data.meeting_links,
        currentMeetingID: action.payload.data.meeting_links.id,
        profileLinkName: action.payload.data.profile_link,
        lastSavedAt: action.payload.data.meeting_links.updated_at,
        zoomIntegrated: action.payload.data.zoom_integration,
        googleCalendarIntegrated:
          action.payload.data.google_calendar_integration,
      };
    case actions.SET_MEETING_DRAWER_STATUS: {
      return {
        ...state,
        showMeetingCreateDrawer: action.payload,
      };
    }
    case actions.CREATE_MEETING_LINK: {
      return {
        ...state,
        createMeetingLoader: true,
      };
    }
    case actions.CREATE_MEETING_LINK_SUCCESS: {
      return {
        ...state,
        createMeetingLoader: false,
        currentMeetingID: action.payload.data.id,
        meetingData: action.payload.data,
        lastSavedAt: action.payload.data.updated_at,
        meetingTabKey: '1',
      };
    }
    case actions.CREATE_MEETING_LINK_FAILURE: {
      return {
        ...state,
        createMeetingLoader: false,
      };
    }
    case actions.UPDATE_MEETING_DETAILS_SUCCESS: {
      return {
        ...state,
        lastSavedAt: action.payload.data.updated_at,
      };
    }
    case actions.UPDATE_AVAILABILITY_DETAILS_SUCCESS: {
      return {
        ...state,
        lastSavedAt: action.payload.data.updated_at,
      };
    }
    case actions.UPDATE_CONFIGURATION_DETAILS_SUCCESS: {
      return {
        ...state,
        lastSavedAt: action.payload.data.updated_at,
      };
    }
    case actions.UPDATE_FORM_QUESTIONS_SUCCESS: {
      return {
        ...state,
        lastSavedAt: action.payload.data.updated_at,
      };
    }
    case actions.MEETING_LINK_AUTO_SAVE: {
      return {
        ...state,
        isAutoSaving: action.payload,
      };
    }
    case actions.RESET_MEETING_DATA: {
      return {
        ...state,
        meetingData: {},
        lastSavedAt: null,
        meetingTabKey: '1',
      };
    }
    case actions.DELETE_MEETING_PROCESS: {
      return {
        ...state,
        isDeletingMeetingProcess: action.payload,
      };
    }
    case actions.UPDATE_REDIRECT_URL_SUCCESS:{
      return {
        ...state,
        meetingData: {
          ...state.meetingData,
          custom_redirect_url: action.payload.customRedirectUrl ? action.payload.customRedirectUrl : state.meetingData.custom_redirect_url,
          custom_redirect_params: action.payload.customRedirectParams ? action.payload.customRedirectParams : state.meetingData.custom_redirect_params,
        },
        lastSavedAt: action.payload.updatedAt ? action.payload.updatedAt : state.lastSavedAt,
      }
    }
    default:
      return state;
  }
}
