const actions = {
  SET_PREVIEW_EMAIL_DATA: 'SET_PREVIEW_EMAIL_DATA',
  SET_PREVIEW_USER: 'SET_PREVIEW_USER',
  GET_PREVIEW_USER_LIST: 'GET_PREVIEW_USER_LIST',
  GET_PREVIEW_USER_LIST_SUCCESS: 'GET_PREVIEW_USER_LIST_SUCCESS',
  GET_PREVIEW_USER_LIST_FAILURE: 'GET_PREVIEW_USER_LIST_FAILURE',
  CLEAR_PREVIEW_EMAIL_DATA: 'CLEAR_PREVIEW_EMAIL_DATA',
  UPDATE_PREVIEW_USER_LIST: 'UPDATE_PREVIEW_USER_LIST',
  SET_PREVIEW_EMAIL_LOADER: 'SET_PREVIEW_EMAIL_LOADER',
  SET_MAIL_SUBJECT_DATA: 'SET_MAIL_SUBJECT_DATA',
  CANCEL_SCHEDULE: 'CANCEL_SCHEDULE',
  CANCEL_SCHEDULE_SUCCESS: 'CANCEL_SCHEDULE_SUCCESS',
  CANCEL_SCHEDULE_FAILURE: 'CANCEL_SCHEDULE_FAILURE',
  FETCH_EMAIL_TAG_LISTS_SUCCESS: 'FETCH_EMAIL_TAG_LISTS_SUCCESS',
  FETCH_EMAIL_TAG_LISTS: 'FETCH_EMAIL_TAG_LISTS',
  CREATE_EMAIL_TAG: 'CREATE_EMAIL_TAG',
  UPDATE_EMAIL_TAG: 'UPDATE_EMAIL_TAG',
  DELETE_EMAIL_TAG: 'DELETE_EMAIL_TAG',
  SET_EMAIL_LOADER: 'SET_EMAIL_LOADER',
  CLEAR_MODAL: 'CLEAR_MODAL',
  SET_PAGE_LOADER: 'SET_PAGE_LOADER',
  APPEND_EMAIL_TAGS: 'APPEND_EMAIL_TAGS',
  APPEND_EMAIL_TAGS_SUCCESS: 'APPEND_EMAIL_TAGS_SUCCESS',
  SET_EMAIL_TAGS_SEARCH: 'SET_EMAIL_TAGS_SEARCH',
  APPLY_EMAIL_TAG: 'APPLY_EMAIL_TAG',
  REMOVE_EMAIL_TAG: 'REMOVE_EMAIL_TAG',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  SET_BROADCAST: 'SET_BROADCAST',
  SET_CONFIRM_MODEL: 'SET_CONFIRM_MODEL',
  FETCH_TEAMS_USERS_LIST: 'FETCH_TEAMS_USERS_LIST',
  FETCH_TEAMS_USERS_LIST_SUCCESS: 'FETCH_TEAMS_USERS_LIST_SUCCESS',
  SET_CUSTOM_TEMPLATE_NAME_MODAL_VISIBILITY:
    'SET_CUSTOM_TEMPLATE_NAME_MODAL_VISIBILITY',
};

export default actions;
