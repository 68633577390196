const actions = {
  FETCH_CONVERSATIONS: 'FETCH_CONVERSATIONS',
  FETCH_CONVERSATIONS_SUCCESS: 'FETCH_CONVERSATIONS_SUCCESS',
  CLEAR_CONVERSATION: 'CLEAR_CONVERSATION',
  FETCH_TIME_BASED_CONVERSATIONS: 'FETCH_TIME_BASED_CONVERSATIONS',
  FETCH_TIME_BASED_CONVERSATIONS_SUCCESS:
    'FETCH_TIME_BASED_CONVERSATIONS_SUCCESS',
  FETCH_TIME_BASED_CONVERSATIONS_FAILURE:
    'FETCH_TIME_BASED_CONVERSATIONS_FAILURE',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  FETCH_MESSAGES_SUCCESS: 'FETCH_MESSAGES_SUCCESS',
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE',
  SET_SELECTED_CHAT_ROOM: 'SET_SELECTED_CHAT_ROOM',
  EMPTY_CHAT_ROOM: 'EMPTY_CHAT_ROOM',
  EMPTY_CHAT_HISTORY: 'EMPTY_CHAT_HISTORY',
  POST_MESSAGE: 'POST_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  PREPEND_MESSAGES: 'PREPEND_MESSAGES',
  DELETE_CONVERSATION: 'DELETE_CONVERSATION',
  UPDATE_CONVERSATION_STATUS: 'UPDATE_CONVERSATION_STATUS',
  INIT_CONVERSATION_STATUS_CHANGE: 'INIT_CONVERSATION_STATUS_CHANGE',
  INIT_CONVERSATION_DELETE: 'INIT_CONVERSATION_DELETE',
  INIT_BLOCK: 'INIT_BLOCK',
  POST_REPLY: 'POST_REPLY',
  REPLY_LOADER: 'REPLY_LOADER',
  SET_REPLY_MESSAGE: 'SET_REPLY_MESSAGE',
  POST_NOTE: 'POST_NOTE',
  NOTE_LOADER: 'NOTE_LOADER',
  POST_ATTACHMENTS: 'POST_ATTACHMENTS',
  SET_REPLY_COMPOSERS_VALUE: 'SET_REPLY_COMPOSERS_VALUE',
  SET_NOTE_COMPOSERS_VALUE: 'SET_NOTE_COMPOSERS_VALUE',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
  SET_EDIT_MESSAGE: 'SET_EDIT_MESSAGE',
  EDIT_MESSAGE_SUCCESS: 'EDIT_MESSAGE_SUCCESS',
  SET_CURRENT_COMPOSERS_VALUE: 'SET_CURRENT_COMPOSERS_VALUE',
  SET_SELECT_ALL_CONVERSATION: 'SET_SELECT_ALL_CONVERSATION',
  ON_SELECT_CONVERSATION: 'ON_SELECT_CONVERSATION',
  ASSIGN_MULTIPLE_CONVERSATION: 'ASSIGN_MULTIPLE_CONVERSATION',
  FETCH_ASSIGNEE_LIST: 'FETCH_ASSIGNEE_LIST',
  FETCH_ASSIGNEE_SUCCESS: 'FETCH_ASSIGNEE_SUCCESS',
  SET_CHAT_ROOM_LOADING: 'SET_CHAT_ROOM_LOADING',
  SET_FILTER: 'SET_FILTER',
  EMMA_CONVERSATION_MESSAGES: 'EMMA_CONVERSATION_MESSAGES',
  EMMA_CONVERSATION_MESSAGES_SUCCESS: 'EMMA_CONVERSATION_MESSAGES_SUCCESS',
  EMMA_CONVERSATION_MESSAGES_FAILURE: 'EMMA_CONVERSATION_MESSAGES_FAILURE',
  FETCH_GIF: 'FETCH_GIF',
  FETCH_GIF_SUCCESS: 'FETCH_GIF_SUCCESS',
  DELETE_GIF: 'DELETE_GIF',
  FETCH_SNIPPETS: 'FETCH_SNIPPETS',
  FETCH_SNIPPETS_SUCCESS: 'FETCH_SNIPPETS_SUCCESS',
  FETCH_SNIPPETS_FAILURE: 'FETCH_SNIPPETS_FAILURE',
  UPDATE_SNIPPET_DATA_SORT_ORDER: 'UPDATE_SNIPPET_DATA_SORT_ORDER',
  FETCH_SINGLE_SNIPPET_DATA_INBOX: 'FETCH_SINGLE_SNIPPET_DATA_INBOX',
  FETCH_SINGLE_SNIPPET_DATA_INBOX_SUCCESS:
    'FETCH_SINGLE_SNIPPET_DATA_INBOX_SUCCESS',
  FETCH_SINGLE_SNIPPET_DATA_INBOX_FAILURE:
    'FETCH_SINGLE_SNIPPET_DATA_INBOX_FAILURE',
  DELETE_SNIPPETS: 'DELETE_SNIPPETS',
  FETCH_ARTICLES: 'FETCH_ARTICLES',
  FETCH_ARTICLES_SUCCESS: 'FETCH_ARTICLES_SUCCESS',
  UPDATE_QUALIFICATION_PROPERTIES: 'UPDATE_QUALIFICATION_PROPERTIES',
  UPDATE_QUALIFICATION_PROPERTIES_SUCCESS:
    'UPDATE_QUALIFICATION_PROPERTIES_SUCCESS',
  SCHEDULE_MEETING: 'SCHEDULE_MEETING',
  ADD_USER_TAGS: 'ADD_USER_TAGS',
  ADD_USER_TAGS_SUCCESS: 'ADD_USER_TAGS_SUCCESS',
  REMOVE_USER_TAGS: 'REMOVE_USER_TAGS',
  REMOVE_USER_TAGS_SUCCESS: 'REMOVE_USER_TAGS_SUCCESS',
  GET_USER_TAGS: 'GET_USER_TAGS',
  GET_USER_TAGS_SUCCESS: 'GET_USER_TAGS_SUCCESS',
  UPDATE_MESSAGE_STATUS: 'UPDATE_MESSAGE_STATUS',
  UPDATE_MESSAGE_STATUS_SUCCESS: 'UPDATE_MESSAGE_STATUS_SUCCESS',
  GET_CHAT_TAG: 'GET_CHAT_TAG',
  GET_CHAT_TAG_SUCCESS: 'GET_CHAT_TAG_SUCCESS',
  ADD_MESSAGE_TAG: 'ADD_MESSAGE_TAG',
  SET_CLEAR_ALL: 'SET_CLEAR_ALL',
  SET_SNIPPET_MESSAGE: 'SET_SNIPPET_MESSAGE',
  FETCH_SNIPPET_PARSED_CONTENT: 'FETCH_SNIPPET_PARSED_CONTENT',
  PREPEND_CONVERSATION: 'PREPEND_CONVERSATION',
  APPEND_CONVERSATION: 'APPEND_CONVERSATION',
  APPEND_UNREPLIED_CONVERSATION: 'APPEND_UNREPLIED_CONVERSATION',
  PREPEND_NON_PRIORITY_CONVERSATION: 'PREPEND_NON_PRIORITY_CONVERSATION',
  UPDATE_CONVERSATIONS: 'UPDATE_CONVERSATIONS',
  END_USER_TYPING_START: 'END_USER_TYPING_START',
  END_USER_TYPING_STOP: 'END_USER_TYPING_STOP',
  AGENT_TYPING_START: 'AGENT_TYPING_START',
  AGENT_TYPING_STOP: 'AGENT_TYPING_STOP',
  CLEAR_TYPING_DETAIL: 'CLEAR_TYPING_DETAIL',
  BLOCK_CONVERSATION: 'BLOCK_CONVERSATION',
  DETECT_NETWORK_STATUS: 'DETECT_NETWORK_STATUS',
  SET_EMPTY_EDIT_MESSAGE: 'SET_EMPTY_EDIT_MESSAGE',
  UPDATE_PERSON_IN_CHAT_ROOMS: 'UPDATE_PERSON_IN_CHAT_ROOMS',
  UPDATE_PERSON_IN_SELECTED_CHAT_ROOM_MESSAGES:
    'UPDATE_PERSON_IN_SELECTED_CHAT_ROOM_MESSAGES',
  UPDATE_CONVERSATION_STATUS_COUNT: 'UPDATE_CONVERSATION_STATUS_COUNT',
  ASSIGN_CONVERSATION_SUCCESS: 'ASSIGN_CONVERSATION_SUCCESS',
  LOADER_FOR_CHAT_ROOMS: 'LOADER_FOR_CHAT_ROOMS',
  LOADER_FOR_CHAT_HISTORY: 'LOADER_FOR_CHAT_HISTORY',
  LOADER_FOR_USER_PROFILE: 'LOADER_FOR_USER_PROFILE',
  ASSIGN_MULTIPLE_CONVERSATION_SUCCESS: 'ASSIGN_MULTIPLE_CONVERSATION_SUCCESS',
  POST_SUCCESS: 'POST_SUCCESS',
  USER_PROPERTIES_UPDATE: 'USER_PROPERTIES_UPDATE',
  CHANGE_FACEBOOK_CONNECTION_STATUS: 'CHANGE_FACEBOOK_CONNECTION_STATUS',
  ADD_MESSAGE_TAG_SUCCESS: ' ADD_MESSAGE_TAG_SUCCESS',
  CLEAR_CHAT: 'CLEAR_CHAT',
  REMOVE_CONVERSATION_TAGS: 'REMOVE_CONVERSATION_TAGS',
  REMOVE_CONVERSATION_TAGS_SUCCESS: 'REMOVE_CONVERSATION_TAGS_SUCCESS',
  REMOVE_MESSAGE_TAGS: 'REMOVE_MESSAGE_TAGS',
  REMOVE_MESSAGE_TAGS_SUCCESS: 'REMOVE_MESSAGE_TAGS_SUCCESS',
  SNOOZE_CONVERSATION: 'SNOOZE_CONVERSATION',
  CHANGE_CONVERSATION_STATUS: 'CHANGE_CONVERSATION_STATUS',
  UPDATE_CHAT_ROOMS_CONVERSATION_STATUS:
    'UPDATE_CHAT_ROOMS_CONVERSATION_STATUS',
  UPDATE_REMOVED_CONVERSATION_IDENTIFIER:
    'UPDATE_REMOVED_CONVERSATION_IDENTIFIER',
  ASSOCIATE_VISITOR: 'ASSOCIATE_VISITOR',
  ASSOCIATE_VISITOR_SUCCESS: 'ASSOCIATE_VISITOR_SUCCESS',
  ASSOCIATE_VISITOR_FAILURE: 'ASSOCIATE_VISITOR_FAILURE',
  ASSOCIATE_VISITOR_CANCEL: 'ASSOCIATE_VISITOR_CANCEL',
  SET_CONVERSATION_SEARCH_TEXT: 'SET_CONVERSATION_SEARCH_TEXT',
  SET_CURRENT_CONVERSATION_ID: 'SET_CURRENT_CONVERSATION_ID',
  FETCH_AGENT_TIMINGS: 'FETCH_AGENT_TIMINGS',
  FETCH_AGENT_TIMINGS_SUCCESS: 'FETCH_AGENT_TIMINGS_SUCCESS',
  SEND_FIRE_BASE_TOKEN: 'SEND_FIRE_BASE_TOKEN',
  DELETE_CONVERSATION_MESSAGE: 'DELETE_CONVERSATION_MESSAGE',
  DELETE_CONVERSATION_MESSAGE_SUCCESS: 'DELETE_CONVERSATION_MESSAGE_SUCCESS',
  UPDATE_AVAILABILITY_STATUS: 'UPDATE_AVAILABILITY_STATUS',
  GET_NOTIFICATION_SETTINGS_DETAILS: 'GET_NOTIFICATION_SETTINGS_DETAILS',
  GET_NOTIFICATION_SETTINGS_DETAILS_SUCCESS:
    'GET_NOTIFICATION_SETTINGS_DETAILS_SUCCESS',
  PRIORITIZE_CONVERSATION: 'PRIORITIZE_CONVERSATION',
  PRIORITIZE_CONVERSATION_SUCCESS: 'PRIORITIZE_CONVERSATION_SUCCESS',
  PRIORITIZE_CONVERSATION_FAILURE: 'PRIORITIZE_CONVERSATION_FAILURE',
  PRIORITIZE_CURRENT_CONVERSATION_SOCKET:
    'PRIORITIZE_CURRENT_CONVERSATION_SOCKET',
  CHANGE_TWITTER_CONNECTION_STATUS: 'CHANGE_TWITTER_CONNECTION_STATUS',
  CHANGE_ASSOCIATE_STATUS: 'CHANGE_ASSOCIATE_STATUS',
  UPDATE_CHAT_MAGIC_TYPE_SETTINGS: 'UPDATE_CHAT_MAGIC_TYPE_SETTINGS',
  ENQUEUE_REPLY_ATTACHMENT: 'ENQUEUE_REPLY_ATTACHMENT',
  DEQUEUE_REPLY_ATTACHMENT: 'DEQUEUE_REPLY_ATTACHMENT',
  RESTORE_REPLY_ATTACHMENT_QUEUE: 'RESTORE_REPLY_ATTACHMENT_QUEUE',
  REMOVE_REPLY_ATTACHMENT: 'REMOVE_REPLY_ATTACHMENT',
  ENQUEUE_NOTE_ATTACHMENT: 'ENQUEUE_NOTE_ATTACHMENT',
  DEQUEUE_NOTE_ATTACHMENT: 'DEQUEUE_NOTE_ATTACHMENT',
  RESTORE_NOTE_ATTACHMENT_QUEUE: 'RESTORE_NOTE_ATTACHMENT_QUEUE',
  REMOVE_NOTE_ATTACHMENT: 'REMOVE_NOTE_ATTACHMENT',
  CHANGE_CURRENT_CHANNEL_STEP: 'CHANGE_CURRENT_CHANNEL_STEP',
  UPDATE_SATISFACTION_RATING_IN_LIST: 'UPDATE_SATISFACTION_RATING_IN_LIST',
  FETCH_CONVERSATIONS_FAILURE: 'FETCH_CONVERSATIONS_FAILURE',
  UPDATE_CURRENT_ASSIGNEE: 'UPDATE_CURRENT_ASSIGNEE',
  CONVERSATION_STATUS_UPDATE_LOADER: 'CONVERSATION_STATUS_UPDATE_LOADER',
  CONVERSATION_STATUS_UPDATE_LOADER_SUCCESS:
    'CONVERSATION_STATUS_UPDATE_LOADER_SUCCESS',
  APPEND_STATUS_MESSAGE: 'APPEND_STATUS_MESSAGE',
  ACTIVE_COMPOSER_TAB: 'ACTIVE_COMPOSER_TAB',
  TRANSCRIPT_MODAL_VISIBILITY: 'TRANSCRIPT_MODAL_VISIBILITY',
  SEND_TRANSCRIPT: 'SEND_TRANSCRIPT',
  SEND_TRANSCRIPT_SUCCESS: 'SEND_TRANSCRIPT_SUCCESS',
  SEND_TRANSCRIPT_FAILURE: 'SEND_TRANSCRIPT_FAILURE',
  SEND_AND_SNOOZE_CONVERSATION: 'SEND_AND_SNOOZE_CONVERSATION',
  SET_CONVERSATION_INBOX_COUNT: 'SET_CONVERSATION_INBOX_COUNT',
  FETCH_PREVIOUS_CONVERSATIONS: 'FETCH_PREVIOUS_CONVERSATIONS',
  FETCH_PREVIOUS_CONVERSATIONS_SUCCESS: 'FETCH_PREVIOUS_CONVERSATIONS_SUCCESS',
  FETCH_PREVIOUS_CONVERSATIONS_FAILURE: 'FETCH_PREVIOUS_CONVERSATIONS_FAILURE',
  GET_USER_PROPERTIES: 'GET_USER_PROPERTIES',
  SET_USER_PROPERTIES_LOADER: 'SET_USER_PROPERTIES_LOADER',
  REMOVE_USER_PROPERTIES_LOADER: 'REMOVE_USER_PROPERTIES_LOADER',
  GET_USER_PROPERTIES_SUCCESS: 'GET_USER_PROPERTIES_SUCCESS',
  UNSUBSCRIBE_CURRENT_PERSON_SUCCESS: 'UNSUBSCRIBE_CURRENT_PERSON_SUCCESS',
  UPDATE_SOCKET_ASSIGNMENT: 'UPDATE_SOCKET_ASSIGNMENT',
  UPDATE_ALL_CONVERSATION_DATA: 'UPDATE_ALL_CONVERSATION_DATA',
  UPDATE_LIVE_PERSON_DATA: 'UPDATE_LIVE_PERSON_DATA',
  SHOW_ASSIGNMENT_DROPDOWN: 'SHOW_ASSIGNMENT_DROPDOWN',
  SHOW_HIDE_MESSAGE_TAGS_MODAL: 'SHOW_HIDE_MESSAGE_TAGS_MODAL',
  CHANGE_REPLY_EDITOR_FOCUS: 'CHANGE_REPLY_EDITOR_FOCUS',
  CHANGE_NOTE_EDITOR_FOCUS: 'CHANGE_NOTE_EDITOR_FOCUS',
  DELETE_FROM_ALL_CONVERSATION_DATA: 'DELETE_FROM_ALL_CONVERSATION_DATA',
  UPDATE_SATISFACTION_RATING_IN_ALL_CONVERSATION_DATA:
    'UPDATE_SATISFACTION_RATING_IN_ALL_CONVERSATION_DATA',
  UPDATE_PARTICIPANTS_IN_CURRENT_CONVERSATION_DATA:
    'UPDATE_PARTICIPANTS_IN_CURRENT_CONVERSATION_DATA',
  UPDATE_PARTICIPANTS_IN_ALL_CONVERSATION_DATA:
    'UPDATE_PARTICIPANTS_IN_ALL_CONVERSATION_DATA',
  EMPTY_CONVERSATION_REDUCER_DATA: 'EMPTY_CONVERSATION_REDUCER_DATA',
  SCHEDULE_CALL: 'SCHEDULE_CALL',
  SCHEDULE_CALL_SUCCESS: 'SCHEDULE_CALL_SUCCESS',
  SCHEDULE_CALL_FAILURE: 'SCHEDULE_CALL_FAILURE',
  CHANGE_POPOVER_VISIBLE : 'CHANGE_POPOVER_VISIBLE',
  UPDATE_CALL_ENDED_STATUS: 'UPDATE_CALL_ENDED_STATUS',

  // Custom Inbox Views
  FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST:
      'FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST',
  FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST_SUCCESS:
      'FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST_SUCCESS',
  FETCH_INBOX_VIEW_CONTACT_TAGS_LIST: 'FETCH_INBOX_VIEW_CONTACT_TAGS_LIST',
  FETCH_INBOX_VIEW_CONTACT_TAGS_LIST_SUCCESS:
      'FETCH_INBOX_VIEW_CONTACT_TAGS_LIST_SUCCESS',
  FETCH_INBOX_VIEW_CONTACT_PROPERTIES_LIST:
      'FETCH_INBOX_VIEW_CONTACT_PROPERTIES_LIST',
  FETCH_INBOX_VIEW_CONTACT_PROPERTIES_LIST_SUCCESS:
      'FETCH_INBOX_VIEW_CONTACT_PROPERTIES_LIST_SUCCESS',
  FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA:
      'FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA',
  FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA_SUCCESS:
      'FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA_SUCCESS',
  FETCH_INBOX_VIEW_DATA: 'FETCH_INBOX_VIEW_DATA',
  FETCH_INBOX_VIEW_DATA_SUCCESS: 'FETCH_INBOX_VIEW_DATA_SUCCESS',
  FETCH_INBOX_VIEW_CONDITION_PROPERTIES_META_DATA:
      'FETCH_INBOX_VIEW_CONDITION_PROPERTIES_META_DATA',
  FETCH_INBOX_VIEW_CONDITION_PROPERTIES_META_DATA_SUCCESS:
      'FETCH_INBOX_VIEW_CONDITION_PROPERTIES_META_DATA_SUCCESS',
  APPEND_INBOX_CHAT_TAGS: 'APPEND_INBOX_CHAT_TAGS',

  SET_INBOX_VIEW_CONDITION_INITIAL_DATA:
      'SET_INBOX_VIEW_CONDITION_INITIAL_DATA',
  ADD_INBOX_VIEW_CONDITION_BLOCK: 'ADD_INBOX_VIEW_CONDITION_BLOCK',
  ADD_INBOX_VIEW_CONDITION_ITEM: 'ADD_INBOX_VIEW_CONDITION_ITEM',
  DELETE_INBOX_VIEW_CONDITION_ITEM: 'DELETE_INBOX_VIEW_CONDITION_ITEM',
  UPDATE_INBOX_VIEW_META: 'UPDATE_INBOX_VIEW_META',
  UPDATE_INBOX_VIEW_CONDITION_DATA: 'UPDATE_INBOX_VIEW_CONDITION_DATA',
  UPDATE_JOIN_CALL_MESSAGES: 'UPDATE_JOIN_CALL_MESSAGES',
  GET_CUSTOM_INBOX_VIEWS_LIST: 'GET_CUSTOM_INBOX_VIEWS_LIST',
  GET_CUSTOM_INBOX_VIEWS_LIST_SUCCESS: 'GET_CUSTOM_INBOX_VIEWS_LIST_SUCCESS',
  GET_CUSTOM_INBOX_VIEWS_LIST_FAILURE: 'GET_CUSTOM_INBOX_VIEWS_LIST_FAILURE',
  CREATE_CUSTOM_INBOX_VIEW: 'CREATE_CUSTOM_INBOX_VIEW',
  CREATE_CUSTOM_INBOX_VIEW_SUCCESS: 'CREATE_CUSTOM_INBOX_VIEW_SUCCESS',
  CREATE_CUSTOM_INBOX_VIEW_FAILURE: 'CREATE_CUSTOM_INBOX_VIEW_FAILURE',
  CHANGE_SHOW_CREATE_VIEW_MODAL_STATUS: 'CHANGE_SHOW_CREATE_VIEW_MODAL_STATUS',
  DELETE_CUSTOM_INBOX_VIEW: 'DELETE_CUSTOM_INBOX_VIEW',
  SET_CURRENT_INBOX_VIEW_DATA: 'SET_CURRENT_INBOX_VIEW_DATA',
  UPDATE_INBOX_VIEW_NAME: 'UPDATE_INBOX_VIEW_NAME',
  UPDATE_INBOX_VIEW_EMOJI: 'UPDATE_INBOX_VIEW_EMOJI',
  UPDATE_CUSTOM_INBOX_VIEW: 'UPDATE_CUSTOM_INBOX_VIEW',
  UPDATE_CUSTOM_INBOX_VIEW_SUCCESS: 'UPDATE_CUSTOM_INBOX_VIEW_SUCCESS',
  UPDATE_CUSTOM_INBOX_VIEW_FAILURE: 'UPDATE_CUSTOM_INBOX_VIEW_FAILURE',
  INCREMENT_PAGE_NO: 'INCREMENT_PAGE_NO',
  RESET_PAGE_NO: 'RESET_PAGE_NO',
  UPDATE_CONVERSATION_TITLE:'UPDATE_CONVERSATION_TITLE',
  UPDATE_CONVERSATION_TITLE_SUCCESS:'UPDATE_CONVERSATION_TITLE_SUCCESS',
  UPDATE_CONVERSATION_TITLE_FAILURE:'UPDATE_CONVERSATION_TITLE_FAILURE',
  SET_SIDEBAR_COLLAPSED_STATUS: 'SET_SIDEBAR_COLLAPSED_STATUS',
  CUSTOM_INBOX_VIEW_PING: 'CUSTOM_INBOX_VIEW_PING',
  UPDATE_CUSTOM_INBOX_CONVERSATION_COUNT:
    'UPDATE_CUSTOM_INBOX_CONVERSATION_COUNT',
  FETCH_CONTACT_CONVERSATION_PROPERTIES:
    'FETCH_CONTACT_CONVERSATION_PROPERTIES',
  FETCH_CONTACT_CONVERSATION_PROPERTIES_SUCCESS:
    'FETCH_CONTACT_CONVERSATION_PROPERTIES_SUCCESS',
  FETCH_CONTACT_CONVERSATION_PROPERTIES_FAILURE:
    'FETCH_CONTACT_CONVERSATION_PROPERTIES_FAILURE',
  UPDATE_SNIPPET_DETAILS: 'UPDATE_SNIPPET_DETAILS',
  UPDATE_SNIPPET_DETAILS_SUCCESS: 'UPDATE_SNIPPET_DETAILS_SUCCESS',
  SHOW_HIDE_CONTACT_TAG_MODAL: 'SHOW_HIDE_CONTACT_TAG_MODAL',

  // INBOX APP FRAMEWORK
  INITIALIZE_INBOX_APP_DATA: 'INITIALIZE_INBOX_APP_DATA',
  INITIALIZE_INBOX_APP_DATA_SUCCESS: 'INITIALIZE_INBOX_APP_DATA_SUCCESS',
  INITIALIZE_INBOX_APP_DATA_FAILURE: 'INITIALIZE_INBOX_APP_DATA_FAILURE',
  SUBMIT_INBOX_APP_DATA: 'SUBMIT_INBOX_APP_DATA',
  SUBMIT_INBOX_APP_DATA_SUCCESS: 'SUBMIT_INBOX_APP_DATA_SUCCESS',
  UPDATE_ENABLED_INBOX_WIDGETS: 'UPDATE_ENABLED_INBOX_WIDGETS',
  UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS: 'UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS',
  SET_INBOX_VISIBLE_AND_HIDDEN_WIDGETS: 'SET_INBOX_VISIBLE_AND_HIDDEN_WIDGETS',
  FETCH_INSTALLED_APPS_SUCCESS: 'FETCH_INSTALLED_APPS_SUCCESS',
  TOGGLE_INBOX_LEFT_MENU: 'TOGGLE_INBOX_LEFT_MENU',
  TOGGLE_INBOX_RIGHT_MENU: 'TOGGLE_INBOX_RIGHT_MENU',
  CHANGE_PREVIOUS_CONVERSATION_DRAWER_STATUS:
    'CHANGE_PREVIOUS_CONVERSATION_DRAWER_STATUS',
  UPDATE_SELECTED_CHAT_ROOM_CONTACT_OWNER:
    'UPDATE_SELECTED_CHAT_ROOM_CONTACT_OWNER',
  UPDATE_MARK_AS_NOT_SPAM_CONVERSATION: 'UPDATE_MARK_AS_NOT_SPAM_CONVERSATION',
  UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_SUCCESS:
    'UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_SUCCESS',
  UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_FAILURE:
    'UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_FAILURE',
  SET_VIEW_ORIGINAL_MESSAGE_MODAL_VISIBILITY:
    'SET_VIEW_ORIGINAL_MESSAGE_MODAL_VISIBILITY',
  GET_VIEW_ORIGINAL_MESSAGE_DETAILS: 'GET_VIEW_ORIGINAL_MESSAGE_DETAILS',
  GET_VIEW_ORIGINAL_MESSAGE_DETAILS_SUCCESS:
    'GET_VIEW_ORIGINAL_MESSAGE_DETAILS_SUCCESS',
  GET_VIEW_ORIGINAL_MESSAGE_DETAILS_FAILURE:
    'GET_VIEW_ORIGINAL_MESSAGE_DETAILS_FAILURE',

  TOGGLE_NEW_CONVERSATION_SCREEN: 'TOGGLE_NEW_CONVERSATION_SCREEN',
  SHOW_TO_USER: 'SHOW_TO_USER',
  LOADER_FOR_CHAT_INFINITE_SCROLL: 'LOADER_FOR_CHAT_INFINITE_SCROLL',
  FETCH_AI_ASSIST: 'FETCH_AI_ASSIST',
  FETCH_AI_ASSIST_SUCCESS: 'FETCH_AI_ASSIST_SUCCESS',
  SET_AI_ASSIST: 'SET_AI_ASSIST',
  FETCH_CONVERSATION_COMPLETION: 'FETCH_CONVERSATION_COMPLETION',
  UPDATE_AI_ASSIST_MODE: 'UPDATE_AI_ASSIST_MODE',
  AI_ASSIST_REPLY_LOADER: 'AI_ASSIST_REPLY_LOADER',
  AI_ASSIST_NOTE_LOADER: 'AI_ASSIST_NOTE_LOADER',
  SET_ACTIVE_CONVERSATION_IDENTIFIER: 'SET_ACTIVE_CONVERSATION_IDENTIFIER',
  SET_ACTIVE_PERSON_ID: 'SET_ACTIVE_PERSON_ID',
  UPDATE_CONVERSATION_VERIFICATION_STATUS:
    'UPDATE_CONVERSATION_VERIFICATION_STATUS',
  FETCH_AI_ANSWER: 'FETCH_AI_ANSWER',
  FETCH_AI_ANSWER_FOUND: 'FETCH_AI_ANSWER_FOUND',
  FETCH_AI_ANSWER_FOUND_SUCCESS: 'FETCH_AI_ANSWER_FOUND_SUCCESS',
  RESET_AI_ANSWER_FOUND: 'RESET_AI_ANSWER_FOUND',
  FETCH_AI_CUSTOM_ANSWER: 'FETCH_AI_CUSTOM_ANSWER',
  FETCH_AI_CUSTOM_ANSWER_SUCCESS: 'FETCH_AI_CUSTOM_ANSWER_SUCCESS',
  AI_ASSIST_CUSTOM_ANSWER_LOADER: 'AI_ASSIST_CUSTOM_ANSWER_LOADER',
  UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY:
    'UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY',
  SHOW_ADVANCED_SEARCH: 'SHOW_ADVANCED_SEARCH',
  SET_INBOX_MODE: 'SET_INBOX_MODE',
  PREVIOUS_CONVERSATION_ENABLED: 'PREVIOUS_CONVERSATION_ENABLED',
  SELECTED_PREVIOUS_CONVERSATION: 'SELECTED_PREVIOUS_CONVERSATION',
  CONVERSATION_DRAWER_STATUS: 'CONVERSATION_DRAWER_STATUS',
  LAST_CONVERSATION_ID: 'LAST_CONVERSATION_ID',
  SET_SELECTED_TABLE_CONVERSATIONS: 'SET_SELECTED_TABLE_CONVERSATIONS',

  fetchConversations: params => ({
    type: actions.FETCH_CONVERSATIONS,
    ...params,
  }),
  fetchTimeBasedConversations: params => ({
    type: actions.FETCH_TIME_BASED_CONVERSATIONS,
    ...params,
  }),
  fetchCurrentConversationMessages: params => ({
    type: actions.FETCH_MESSAGES,
    ...params,
  }),
  fetchCurrentPersonProfile: params => ({
    type: actions.FETCH_PROFILE,
    ...params,
  }),
  setSelectedChatRoom: chatRoom => ({
    type: actions.SET_SELECTED_CHAT_ROOM,
    chatRoom,
  }),
  assignConversation: assignData => ({
    type: actions.POST_MESSAGE,
    assignData,
  }),
  changeConversationStatus: statusDetails => ({
    type: actions.INIT_CONVERSATION_STATUS_CHANGE,
    statusDetails,
  }),
  deleteConversation: deleteParams => ({
    type: actions.INIT_CONVERSATION_DELETE,
    deleteParams,
  }),
  block: blockParams => ({
    type: actions.INIT_BLOCK,
    blockParams,
  }),
  setSelectConversation: checkAll => ({
    type: actions.SET_SELECT_ALL_CONVERSATION,
    checkAll,
  }),
  onSelectConversation: (selectedConversation, checked) => ({
    type: actions.ON_SELECT_CONVERSATION,
    selectedConversation,
    checked,
  }),
  fetchAssigneeList: () => ({
    type: actions.FETCH_ASSIGNEE_LIST,
  }),
  scheduleMeeting: scheduleData => ({
    type: actions.SCHEDULE_MEETING,
    scheduleData,
  }),
};

export default actions;
