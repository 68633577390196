import React from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Icon, Menu, Modal, Tooltip } from 'antd';
import {
  fetchProjectSecretKey,
  updateBrowserHistory,
} from '../../../helpers/utility';
import { Link } from 'react-router-dom';
import actions from '../../../redux/knowledgeBase/actions';
import { getStatusTags } from '../helpers/utility';
import { folderIcon } from '../constants';

const CollectionItem = (props) => {
  const dispatch = useDispatch();
  const { node: item, supportedLanguages, depth, isOpen, onToggle } = props;

  const handleToggle = (e) => {
    e.stopPropagation();
    onToggle(item.id);
  };

  const addArticleOrCollection = (isArticle) => {
    dispatch({
      type: actions.SET_PARENT_COLLECTION_SECRET,
      payload: item.secret_key,
    });
    dispatch({
      type: actions.UPDATE_COLLECTIONS_MODAL_DETAILS,
      payload: {
        isArticle,
        isVisible: true,
      },
    });
  };
  const editItem = (secretKey) => {
    if (item.type === 'article') {
      return updateBrowserHistory(`knowledge-base/article/${secretKey}`);
    }
    return dispatch({
      type: actions.FETCH_EDIT_COLLECTION_DETAIL,
      payload: secretKey,
    });
  };

  const handleDelete = (secretKey) => {
    const isArticle = item.type === 'article';
    return Modal.confirm({
      title: `Delete the ${
        isArticle ? 'article' : 'collection'
      }? This is permanent.`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        if (isArticle) {
          dispatch({
            type: actions.DELETE_ARTICLE,
            deleteArticles: { delete_articles: [secretKey] },
            isFromCollections: true,
          });
        } else {
          dispatch({
            type: actions.DELETE_COLLECTION,
            key: secretKey,
          });
        }
      },
      onCancel() {},
    });
  };
  return (
    <div className="flex flex-col collection-item">
      <div className="flex flex-row flex-grow items-center w-full py-4 collection__tree__item pl-4">
        <div>
          <div className="flex">
            {[...Array(depth)].map((e, i) => (
              <div className="w-13" key={i}></div>
            ))}
            <div className="opacity-0 w-4 h-4 -mt-2 cursor-grab">
              <i className="fas fa-grid" />
            </div>
          </div>
        </div>

        <div
          onClick={handleToggle}
          className={`flex w-10 mr-3 cursor-pointer justify-center items-center expand_icon__wrapper ${
            isOpen ? 'is-open' : ''
          }`}
        >
          {item.droppable && (
            <div>
              <Icon type="right" />
            </div>
          )}
        </div>
        <div className="flex flex-grow max-w-full">
          <div className="flex items-centered">
            <div className="mr-3">
              <div className="collection-icon-container">
                {item.custom_icon_url ? (
                  <img src={item.custom_icon_url} alt="icon" />
                ) : (
                  <i className={item.category_icon || folderIcon} />
                )}
              </div>
            </div>
            <div className="flex items-center">
              {item.type === 'collection' && (
                <div className="flex flex-col min-w-96 collections-detail">
                  {item.text && (
                    <span className="font-medium">{item.text}</span>
                  )}
                  {item.description && (
                    <span className="text-muted">{item.description}</span>
                  )}
                </div>
              )}
              {item.type === 'article' && (
                <div className="article-title">
                  <Link
                    to={`/projects/${fetchProjectSecretKey()}/knowledge-base/article/${
                      item.secret_key
                    }`}
                    className="font-medium"
                  >
                    {item.text}
                  </Link>
                  {item.duplicate && (
                    <Tooltip title={'Duplicate article'}>
                      <i className="far fa-copy" />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {item.type === 'article' && supportedLanguages.length > 1 && (
          <div className="article-tags">{getStatusTags(item.status)}</div>
        )}
        <div className="collections-count">
          {item.type === 'collection' && item.articles_count}
        </div>
        <div className="item-actions">
          {item.type === 'collection' && (
            <Dropdown
              trigger={'click'}
              overlay={
                <Menu className={'more-action-dropdown'}>
                  <Menu.Item
                    key="add_article"
                    onClick={() => addArticleOrCollection(true)}
                  >
                    <i className="fas fa-book-open"></i> Add article
                  </Menu.Item>
                  {item.hierarchy_level !== 3 && (
                    <Menu.Item
                      key="add_collection"
                      onClick={() => addArticleOrCollection(false)}
                    >
                      <i className="fas fa-folder"></i> Add collection
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <span className="text-primary cursor-pointer add-item">
                + Add
              </span>
            </Dropdown>
          )}
          <Dropdown.Button
            onClick={() => editItem(item.secret_key)}
            overlay={
              <Menu className={'more-action-dropdown'}>
                <Menu.Item onClick={() => handleDelete(item.secret_key)}>
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            Edit
          </Dropdown.Button>
        </div>
      </div>
    </div>
  );
};
export default CollectionItem;
