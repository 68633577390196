import '../AutoEmails/style.scss';
import '../Broadcast/style.scss';
import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { store } from '../../../redux/store';
import { BeeTemplates } from '../constants';
import autoEmailActions from '../../../redux/email/autoEmails/actions';
import broadcastActions from '../../../redux/email/broadcast/actions';
import campaignEmailActions from '../../../redux/email/campaign/actions';
import settings from '../../../settings';

class BeeTemplate extends Component {
  constructor(props) {
    super(props);
    if (isNaN(props.selectedTemplateId)) {
      this.setTemplateId(props.selectedTemplateId);
    } else {
      this.setTemplateId(BeeTemplates[0].id);
    }
  }

  setTemplateId = id => {
    const { isFromAutoEmails, isFromCampaignEmails } = this.props;
    const actions = isFromAutoEmails
      ? autoEmailActions : isFromCampaignEmails ? campaignEmailActions
      : broadcastActions;
    store.dispatch({
      type: actions.SET_SELECTED_TEMPLATE_ID,
      templateId: id,
    });
    store.dispatch({
      type:
        actions[
          `FETCH_${
            this.props.isFromAutoEmails ? 'AUTO_EMAIL_' : isFromCampaignEmails ? 'CAMPAIGN_EMAIL_' : ''
          }VISUAL_BUILDER_JSON`
        ],
      templateId: id,
    });
  };
  render() {
    const { selectedTemplateId,isfromSelectTemplateContainer } = this.props;
    return (
      <div className="bee-templates drawer-content">
        <Row type="flex" gutter={10}>
          {BeeTemplates.length ? (
            BeeTemplates.map((template, index) => (
              <Col span={isfromSelectTemplateContainer ? 6 : 8} key={template.id}>
                <div className={'template'}>
                  <img
                    className={
                      (isNaN(selectedTemplateId)
                        ? template.id === selectedTemplateId && 'active-image'
                        : index === 0 && 'active-image') || ''
                    }
                    src={`${settings.BEE_EMAIL_TEMPLATE}${template.thumbnail}`}
                    alt={'template'}
                  />
                  <div
                    className="overlay"
                    onClick={() => this.setTemplateId(template.id)}
                  />
                </div>
                <p>{template.name}</p>
              </Col>
            ))
          ) : (
            <Col span={24}>
              <div className="no-data">No template found</div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default BeeTemplate;
