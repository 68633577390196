const actions = {
  FETCH_MESSAGE_TEMPLATE_LIST: 'FETCH_MESSAGE_TEMPLATE_LIST',
  FETCH_MESSAGE_TEMPLATE_LIST_SUCCESS: 'FETCH_MESSAGE_TEMPLATE_LIST_SUCCESS',
  FETCH_MESSAGE_TEMPLATE_LIST_FAILURE: 'FETCH_MESSAGE_TEMPLATE_LIST_FAILURE',
  GET_MESSAGE_CATEGORIES_LISTS: 'GET_MESSAGE_CATEGORIES_LISTS',
  GET_MESSAGE_CATEGORIES_LISTS_SUCCESS: 'GET_MESSAGE_CATEGORIES_LISTS_SUCCESS',
  SET_CREATE_MESSAGE_TEMPLATE_DRAWER_STATUS:
    'SET_CREATE_MESSAGE_TEMPLATE_DRAWER_STATUS',
  CREATE_MESSAGE_TEMPLATE: 'CREATE_MESSAGE_TEMPLATE',
  CREATE_MESSAGE_TEMPLATE_SUCCESS: 'CREATE_MESSAGE_TEMPLATE_SUCCESS',
  CREATE_MESSAGE_TEMPLATE_FAILURE: 'CREATE_MESSAGE_TEMPLATE_FAILURE',
  SET_MESSAGE_TEMPLATE_ID: 'SET_MESSAGE_TEMPLATE_ID',
  DELETE_MESSAGE_TEMPLATE: 'DELETE_MESSAGE_TEMPLATE',
  DELETE_MESSAGE_TEMPLATE_SUCCESS: 'DELETE_MESSAGE_TEMPLATE_SUCCESS',
  DELETE_MESSAGE_TEMPLATE_FAILURE: 'DELETE_MESSAGE_TEMPLATE_FAILURE',
  UPDATE_MESSAGE_TEMPLATE_DRAWER_VISIBLE:
    'UPDATE_MESSAGE_TEMPLATE_DRAWER_VISIBLE',
  UPDATE_MESSAGE_TEMPLATE: 'UPDATE_MESSAGE_TEMPLATE',
  UPDATE_MESSAGE_TEMPLATE_SUCCESS: 'UPDATE_MESSAGE_TEMPLATE_SUCCESS',
  UPDATE_MESSAGE_TEMPLATE_FAILURE: 'UPDATE_MESSAGE_TEMPLATE_FAILURE',
  SET_MESSAGE_TEMPLATE_STATUS: 'SET_MESSAGE_TEMPLATE_STATUS',
  SET_MESSAGE_TEMPLATE_STATUS_LOADER: 'SET_MESSAGE_TEMPLATE_STATUS_LOADER',
  SET_EDIT_DETAILS_FORM_LOADER: 'SET_EDIT_DETAILS_FORM_LOADER',
  SET_FILTER_MESSAGE_CATEGORY_ID: 'SET_FILTER_MESSAGE_CATEGORY_ID',
  SET_CHOOSE_MESSAGE_TEMPLATE_SEARCH_VALUE:
    'SET_CHOOSE_MESSAGE_TEMPLATE_SEARCH_VALUE',
  FILTER_MESSAGE_TEMPLATE_LIST: 'FILTER_MESSAGE_TEMPLATE_LIST',
  SET_MESSAGE_TEMPLATE_SEARCH_VALUE: 'SET_MESSAGE_TEMPLATE_SEARCH_VALUE',
  DUPLICATE_MESSAGE_TEMPLATE: 'DUPLICATE_MESSAGE_TEMPLATE',
  DUPLICATE_MESSAGE_TEMPLATE_SUCCESS: 'DUPLICATE_MESSAGE_TEMPLATE_SUCCESS',
  DUPLICATE_MESSAGE_TEMPLATE_FAILURE: 'DUPLICATE_MESSAGE_TEMPLATE_FAILURE',
  SET_MESSAGE_TEMPLATE_DETAILS: 'SET_MESSAGE_TEMPLATE_DETAILS',
  UPDATE_DRAWER_MESSAGE_TEMPLATE: 'UPDATE_DRAWER_MESSAGE_TEMPLATE',
  SET_MESSAGE_DEFAULT_TEMPLATE_DETAILS: 'SET_MESSAGE_DEFAULT_TEMPLATE_DETAILS',
};

export default actions;
