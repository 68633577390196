import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../../redux/store';

export function* fetchWorkflowTemplateList(params) {
  try {
    let searchValue = '';
    let status = '',
      filterCategoryId = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().WorkflowTemplateReducer.searchValue;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().WorkflowTemplateReducer.selectedTemplateStatus;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates?status=${status}&search_text=${encodeURIComponent(
      searchValue,
    )}`;
    if (params.fromTemplateList) {
      filterCategoryId = store.getState().WorkflowTemplateReducer
        .filterCategoryId;
      searchValue = store.getState().WorkflowTemplateReducer
        .templateSearchValue;
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/workflow_templates?status=published&search_text=${encodeURIComponent(
        searchValue,
      )}&category_id=${filterCategoryId}&use_template_page=true`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_TEMPLATE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_WORKFLOW_TEMPLATE_LIST_FAILURE,
    });
    message.error('Fetch workflow template list failed');
  }
}

export function* getCategoryList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates/categories?use_template_page=${
      params.isParams ? params.isParams : false
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CATEGORIES_LISTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch workflow template list failed');
  }
}

export function* createWorkflowTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_WORKFLOW_TEMPLATE_ID,
      id: data.data.workflow_template.id,
    });
    message.success(data.message);
    updateBrowserHistory(
      `workflow-templates/${data.data.workflow_template.id}`,
    );
    yield put({
      type: actions.CREATE_WORKFLOW_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_WORKFLOW_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}
export function* duplicateWorkflowTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates/${
      params.id
    }/duplicate`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_WORKFLOW_TEMPLATE_ID,
      id: data.data.workflow_template.id,
    });
    message.success(data.message);
    updateBrowserHistory(
      `workflow-templates/${data.data.workflow_template.id}`,
    );
    yield put({
      type: actions.DUPLICATE_WORKFLOW_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_WORKFLOW_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* deleteWorkflowTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_WORKFLOW_TEMPLATE_LIST,
      status: store.getState().WorkflowTemplateReducer.selectedTemplateStatus,
    });
    yield put({
      type: actions.DELETE_WORKFLOW_TEMPLATE_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_WORKFLOW_TEMPLATE_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* appendWorkflowTemplateList(params) {
  try {
    let searchValue = '';
    let status = '';
    const { payload } = params;
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().WorkflowTemplateReducer.searchValue;
    }
    let lastWorkflowTemplateID = '';
    if (payload && payload.data.lastWorkflowTemplateID) {
      lastWorkflowTemplateID = payload.data.lastWorkflowTemplateID;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().WorkflowTemplateReducer.selectedTemplateStatus;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflow_templates?status=${status}&search_text=${encodeURIComponent(
      searchValue,
    )}&workflow_template_id=${lastWorkflowTemplateID}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_WORKFLOW_TEMPLATE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.APPEND_WORKFLOW_TEMPLATE_LIST_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_WORKFLOW_TEMPLATE_LIST, fetchWorkflowTemplateList),
    takeEvery(actions.CREATE_WORKFLOW_TEMPLATE, createWorkflowTemplate),
    takeEvery(actions.DUPLICATE_WORKFLOW_TEMPLATE, duplicateWorkflowTemplate),
    takeEvery(actions.GET_CATEGORIES_LISTS, getCategoryList),
    takeEvery(actions.DELETE_WORKFLOW_TEMPLATE, deleteWorkflowTemplate),
    takeEvery(
      actions.APPEND_WORKFLOW_TEMPLATE_LIST,
      appendWorkflowTemplateList,
    ),
  ]);
}
