import React, { useMemo } from 'react';
import WorkSpaceListIntegration from '../WorkSpaceList/WorkSpaceListIntegration';
import OauthAuthorizeDetails from '../../components/DeveloperApp/OauthAuthorizeDetails';

import { useSelector, useDispatch } from 'react-redux';
import { getQueryParamsValue } from '../../helpers/utility';
import actions from '../../redux/auth/actions';

const OauthAuthorize = () => {
  const dispatch = useDispatch();
  const selectedProjectSecretKey = useSelector(
      ({ Auth }) => Auth.authorizeAppProjectSecretKey,
    ),
    workspaceId = useMemo(
      () =>
        getQueryParamsValue('workspace_id') === 'null'
          ? null
          : getQueryParamsValue('workspace_id'),
      [],
    );

  const onProjectClick = project => {
    dispatch({
      type: actions.SET_AUTHORIZE_APP_PROJECT_SECRET_KEY,
      payload: project.secret_key,
    });
  };

  if (workspaceId || selectedProjectSecretKey) {
    return (
      <OauthAuthorizeDetails
        workspaceId={workspaceId || selectedProjectSecretKey}
      />
    );
  }

  return <WorkSpaceListIntegration onProjectClick={onProjectClick} />;
};

export default OauthAuthorize;
