import actions from './actions';

export const initState = {
  sortKey: 'name',
  sortOrder: 'asc',
  searchValue: '',
  surveyOneOffTagId: '',
  surveyOneOffStatus: 'all',
  surveyOneOffList: [],
  surveyOneOffTableLoader: false,
  surveyOneOffScheduleTime: false,
  surveyOneOffPromptDetails: {},
  surveyOneOffScheduleDetails: {},
  list: {},
  surveyOneOffSelectedRowKeys: [],
};

export default function surveyOneOffReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_SURVEY_ONEOFF_SORT_VALUES:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    case actions.SET_SURVEY_ONEOFF_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.SET_SURVEY_ONEOFF_STATUS:
      return {
        ...state,
        surveyOneOffStatus: action.payload,
      };
    case actions.SET_SURVEY_ONEOFF_TAG_ID:
      return {
        ...state,
        surveyOneOffTagId: action.payload,
      };
    case actions.FETCH_SURVEY_ONEOFF_LIST_SUCCESS:
      return {
        ...state,
        surveyOneOffList: action.payload.surveys,
        list: action.payload.surveys_count,
        surveyOneOffTableLoader: false,
      };
    case actions.SET_SURVEY_ONEOFF_TABLE_LOADER:
      return {
        ...state,
        surveyOneOffTableLoader: action.payload,
      };
    case actions.APPEND_SURVEY_ONEOFF_LIST_SUCCESS:
      let appendTableLists = [],
          appendCounts = [];
      if (action.payload) {
        appendTableLists = action.payload.surveys;
        appendCounts = action.payload.surveys_count;
      }
      return {
        ...state,
        surveyOneOffList: appendTableLists.length ? state.surveyOneOffList.concat(appendTableLists) : state.surveyOneOffList,
        list: appendCounts.length ? appendCounts : state.list,
        surveyOneOffTableLoader: false,
      };
    case actions.SET_SURVEY_ONEOFF_SELECTED_ROWS:
      return {
        ...state,
        surveyOneOffSelectedRowKeys: action.selectedRowKeys,
      };
    case actions.REMOVE_DELETED_ONE_OFF_SURVEY_ID:
      let selectedRowKeys = [];
      if (
          state.surveyOneOffSelectedRowKeys &&
          state.surveyOneOffSelectedRowKeys.length
      ) {
        selectedRowKeys = state.surveyOneOffSelectedRowKeys.filter(
            id => id !== action.deletedSurveyId,
        );
      }
      return {
        ...state,
        surveyOneOffSelectedRowKeys: selectedRowKeys
            ? selectedRowKeys
            : state.surveyOneOffSelectedRowKeys,
      };
    default:
      return state;
  }
}
