const actions = {
  FETCH_WORKFLOW_TEMPLATE_LIST: 'FETCH_WORKFLOW_TEMPLATE_LIST',
  FETCH_WORKFLOW_TEMPLATE_LIST_SUCCESS: 'FETCH_WORKFLOW_TEMPLATE_LIST_SUCCESS',
  FETCH_WORKFLOW_TEMPLATE_LIST_FAILURE: 'FETCH_WORKFLOW_TEMPLATE_LIST_FAILURE',
  SET_CREATE_WORKFLOW_TEMPLATE_DRAWER_STATUS:
    'SET_CREATE_WORKFLOW_TEMPLATE_DRAWER_STATUS',
  CREATE_WORKFLOW_TEMPLATE: 'CREATE_WORKFLOW_TEMPLATE',
  CREATE_WORKFLOW_TEMPLATE_SUCCESS: 'CREATE_WORKFLOW_TEMPLATE_SUCCESS',
  CREATE_WORKFLOW_TEMPLATE_FAILURE: 'CREATE_WORKFLOW_TEMPLATE_FAILURE',
  DUPLICATE_WORKFLOW_TEMPLATE: 'DUPLICATE_WORKFLOW_TEMPLATE',
  DUPLICATE_WORKFLOW_TEMPLATE_SUCCESS: 'DUPLICATE_WORKFLOW_TEMPLATE_SUCCESS',
  DUPLICATE_WORKFLOW_TEMPLATE_FAILURE: 'DUPLICATE_WORKFLOW_TEMPLATE_FAILURE',
  GET_CATEGORIES_LISTS: 'GET_CATEGORIES_LISTS',
  GET_CATEGORIES_LISTS_SUCCESS: 'GET_CATEGORIES_LISTS_SUCCESS',
  SET_WORKFLOW_TEMPLATE_STATUS: 'SET_WORKFLOW_TEMPLATE_STATUS',
  SET_WORKFLOW_TEMPLATE_SEARCH_VALUE: 'SET_WORKFLOW_TEMPLATE_SEARCH_VALUE',
  DELETE_WORKFLOW_TEMPLATE: 'DELETE_WORKFLOW_TEMPLATE',
  DELETE_WORKFLOW_TEMPLATE_SUCCESS: 'DELETE_WORKFLOW_TEMPLATE_SUCCESS',
  DELETE_WORKFLOW_TEMPLATE_FAILURE: 'DELETE_WORKFLOW_TEMPLATE_FAILURE',
  SET_WORKFLOW_TEMPLATE_ID: 'SET_WORKFLOW_TEMPLATE_ID',
  APPEND_WORKFLOW_TEMPLATE_LIST: 'APPEND_WORKFLOW_TEMPLATE_LIST',
  APPEND_WORKFLOW_TEMPLATE_LIST_SUCCESS:
    'APPEND_WORKFLOW_TEMPLATE_LIST_SUCCESS',
  APPEND_WORKFLOW_TEMPLATE_LIST_FAILURE:
    'APPEND_WORKFLOW_TEMPLATE_LIST_FAILURE',
  SET_WORKFLOW_OR_WORKFLOW_TEMPLATE: 'SET_WORKFLOW_OR_WORKFLOW_TEMPLATE',
  CREATE_WORKFLOW_TEMPLATE_DETAILS: 'SET_WORKFLOW_DETAILS',
  SET_LOADER: 'SET_LOADER',
  SET_FILTER_CATEGORY_ID: 'SET_FILTER_CATEGORY_ID',
  SET_CHOOSE_WORKFLOW_TEMPLATE_SEARCH_VALUE:
    'SET_CHOOSE_WORKFLOW_TEMPLATE_SEARCH_VALUE',
};

export default actions;
