import actions from './actions';
import * as _ from 'lodash';
import {
  actionList,
  triggerList,
} from '../../../components/Automation/constants';
import { removeUnderscore } from '../../../helpers/utility';

const { integrations, webhook } = actionList;
const { orders, date } = triggerList;

let initState = {
  rulesData: [],
  selectedRulesCount: [],
  rulesTableLoader: true,
  loader: false,
  rulesStatus: null,
  searchValue: '',
  sortKey: '',
  sortOrder: '',
  triggerName: '',
  filterStatus: 'all',
  buttonLoader: false,
  switchLoader: false,
  triggerModalIndex: 0,
  initFilterData: [],
  initActionModalData: [],
  triggerSelectOptions: [],
  campaignsList: [],
  formsList: [],
  emailsList: [],
  campaignEmailList: [],
  actionModalVisible: false,
  filters: {},
  tagsList: [],
  emailLinkList: [],
  allLinkList: {
    allEmailLinkList: [],
    allBroadcastEmailLinkList: [],
    allAutoEmailLinkList: [],
    allCampaignLinkList: [],
    allCampaignEmailLinkList: [],
  },
  contactList: [],
  actionSelectOptions: [],
  existingData: [],
  triggerData: [
    {
      trigger_type: '',
      trigger_details: {},
      people_filter: [],
      service: 'gist',
    },
  ],
  deletedTriggerData: [],
  actionData: [
    {
      action_type: '',
      action_details: {},
      service: 'gist',
      perform_multiple_times: false,
    },
  ],
  deletedActionData: [],
  actionModalIndex: '',
  segments: [],
  propertiesMetaData: {},
  allKeys: [],
  events: [],
  eventsMetaData: [],
  emailComposerData: [],
  recipientsLoader: false,
  fromUserID: '',
  toUserID: '',
  mailText: '',
  customFields: [],
  mailSubject: '',
  triggerCheck: true,
  actionCheck: true,
  teamatesList: [],
  rulesWorkflowList: [],
  showEmptyPageLoader: true,
  productLists: [],
  productCategoriesList: [],
  triggerLoader: false,
  triggerIndex: null,
  actionLoader: false,
  actionIndex: null,
  allProperties: [],
  categorySearch: '',
  productSearch: '',
};

function constructProperties(value) {
  return {
    key: removeUnderscore(value.property_name),
    value: value.property_name,
  };
}

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_RULES_LIST:
      return {
        ...state,
        rulesTableLoader: true,
      };
    case actions.FETCH_RULES_LIST_SUCCESS:
      const initData = action.payload.data.automation_rules;
      const automationCount = action.payload.data.automation_rules_count;
      return {
        ...state,
        rulesData: initData,
        selectedRulesCount: automationCount,
        rulesTableLoader: false,
        searchValue: action.searchValue,
        filterStatus: action.filterStatus,
      };
    case actions.APPEND_RULES_LIST:
      return {
        ...state,
        rulesTableLoader: true,
      };
    case actions.APPEND_RULES_LIST_SUCCESS:
      return {
        ...state,
        rulesData: state.rulesData.concat(action.data.data.automation_rules),
        rulesTableLoader: false,
      };
    case actions.LOADER_STATUS:
      return {
        ...state,
        rulesTableLoader: action.rulesTableLoader,
        buttonLoader: action.buttonLoader,
        switchLoader: action.switchLoader,
        loader: action.loader,
      };
    case actions.VALIDATION_CHECK:
      return {
        ...state,
        triggerCheck: action.triggerCheck,
        actionCheck: action.actionCheck,
      };
    case actions.CHANGE_STATUS:
      return {
        ...state,
        rulesTableLoader: true,
        switchLoader: true,
      };
    case actions.CHANGE_STATUS_SUCCESS:
      let statusChange = [...state.rulesData];
      let active;
      active = action.payload.status;
      if (action.payload.index >= 0) {
        statusChange[action.payload.index].active = active;
      }
      return {
        ...state,
        rulesStatus: action.payload.status,
        rulesData: statusChange,
        rulesTableLoader: false,
        switchLoader: false,
      };
    case actions.ADD_RULE_TABLE_CONTENT:
      return {
        ...state,
        loader: true,
      };
    case actions.PATCH_RULES_EDITOR:
      if (action.from === 'trigger') {
        return {
          ...state,
          buttonLoader: true,
          triggerLoader: true,
          triggerIndex: action.index ? action.index : null,
        };
      } else if (action.from === 'action') {
        return {
          ...state,
          buttonLoader: true,
          actionLoader: true,
          actionIndex: action.index ? action.index : null,
        };
      } else {
        return {
          ...state,
          buttonLoader: true,
        };
      }
    case actions.PATCH_RULES_EDITOR_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
        actionLoader: false,
        triggerLoader: false,
      };
    case actions.PATCH_RULES_EDITOR_FAILURE:
      return {
        ...state,
        buttonLoader: false,
        actionLoader: false,
        triggerLoader: false,
      };
    case actions.SET_TRIGGER_NAME:
      let name1 = action.payload;
      return {
        ...state,
        triggerName: name1,
        loader: false,
      };
    case actions.DELETE_TABLE_CONTENT_SUCCESS:
      let remainingData = state.rulesData;
      remainingData = remainingData.filter(data => data.id !== action.id);
      return {
        ...state,
        rulesData: remainingData,
      };
    case actions.RULES_FILTER_CHANGE:
      return {
        ...state,
        filterStatus: action.status,
      };
    case actions.SET_RULES_SORT_KEY_AND_VALUE:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
        rulesTableLoader: true,
      };
    case actions.SET_RULES_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
        rulesTableLoader: true,
      };
    case actions.FETCH_RULES_EDITOR:
      return {
        ...state,
        loader: true,
      };
    case actions.FETCH_RULES_EDITOR_SUCCESS:
      let existingData = _.cloneDeep(action.existingData);
      let initTrigger = [
          {
            trigger_type: '',
            trigger_details: {},
            people_filter: [],
            service: 'gist',
          },
        ],
        initAction = [
          {
            action_type: '',
            action_details: {},
            service: 'gist',
            perform_multiple_times: false,
          },
        ];
      if (action.triggerData.length) {
        initTrigger = action.triggerData;
        initTrigger.forEach((data, index) => {
          if (data.trigger_type === 'matches_custom_audience') {
            initTrigger[index].people_filter = data.trigger_details.filters;
          }
        });
      } else {
        existingData.automation_rule_triggers = [
          {
            trigger_type: '',
            trigger_details: {},
            people_filter: [],
            service: 'gist',
          },
        ];
      }
      if (action.actionData.length) {
        initAction = action.actionData;
        initAction.forEach((data, index) => {
          if (data.action_type === 'trigger_webhook') {
            initAction[index].action_details.target_url = initAction[
              index
            ].action_details.target_url.replace('https://', '');
          }
        });
      } else {
        existingData.automation_rule_actions = [
          {
            action_type: '',
            action_details: {},
            service: 'gist',
            perform_multiple_times: false,
          },
        ];
      }
      return {
        ...state,
        triggerData: initTrigger,
        actionData: initAction,
        rulesStatus: action.rulesStatus,
        existingData: existingData,
      };
    case actions.FETCH_TRIGGER_AND_ACTION_SUCCESS:
      return {
        ...state,
        triggerSelectOptions: action.triggerOptions,
        actionSelectOptions: action.actionOptions,
      };
    case actions.FETCH_CAMPAIGNS_LIST_SUCCESS:
      return {
        ...state,
        campaignsList: action.data,
      };
    case actions.FETCH_FORMS_LIST_SUCCESS:
      return {
        ...state,
        formsList: action.data,
      };
    case actions.FETCH_RULES_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        rulesWorkflowList: action.data,
      };
    case actions.CAMPAIGN_EMAIL_LIST:
      let campaignEmailList = [];
      campaignEmailList[action.id] = action.campaignEmailList;
      return {
        ...state,
        campaignEmailList: {
          ...state.campaignEmailList,
          ...campaignEmailList,
        },
      };
    case actions.FETCH_EMAIL_LINKS_SUCCESS:
      let emailLinks = [];
      emailLinks[action.id] = action.data;
      return {
        ...state,
        emailLinkList: {
          ...state.emailLinkList,
          ...emailLinks,
        },
      };
    case actions.FETCH_RULES_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tagsList: action.data,
      };
    case actions.FETCH_CONTACT_LIST_SUCCESS:
      let customProperties = _.map(action.data.custom_properties, value =>
        constructProperties(value),
      );
      let allProperties = [];
      for (let category of action.data.user_properties) {
        allProperties = _.concat(allProperties, category.properties);
      }
      allProperties = _.concat(allProperties, customProperties);
      return {
        ...state,
        contactList: action.data,
        allProperties: allProperties,
      };
    case actions.FETCH_EMAILS_LIST_SUCCESS:
      return {
        ...state,
        emailsList: action.emailsList,
      };
    case actions.FETCH_CUSTOM_LIST_SUCCESS:
      return {
        ...state,
        customFields: action.data,
      };
    case actions.FETCH_FROM_USER_SUCCESS:
      return {
        ...state,
        emailComposerData: action.data,
      };
    case actions.ADD_TRIGGER_SECTION:
      let triggerData = state.triggerData;
      let trigger_type = '';
      let trigger_details = {};
      let people_filter = [];
      let service = 'gist';
      let perform_multiple_times = false;
      return {
        ...state,
        triggerData: triggerData.concat({
          trigger_type,
          trigger_details,
          people_filter,
          service,
          perform_multiple_times,
        }),
      };
    case actions.UPDATE_TRIGGER_SECTION:
      let updatedData = [...state.triggerData];
      updatedData[action.index].trigger_details = {};
      updatedData[action.index].trigger_type = action.value
        ? action.value
        : updatedData[action.index].trigger_type;
      if (
        ['applied_tag', 'removed_tag'].includes(
          updatedData[action.index].trigger_type,
        )
      ) {
        updatedData[action.index].trigger_details.tag_id =
          action.conditionalSelectValue;
      } else if (
        [
          'subscribed_to_campaign',
          'completed_campaign',
          'exited_campaign',
        ].includes(updatedData[action.index].trigger_type)
      ) {
        updatedData[action.index].trigger_details.campaign_id =
          action.conditionalSelectValue;
      } else if (
        ['submitted_form'].includes(updatedData[action.index].trigger_type)
      ) {
        updatedData[action.index].trigger_details.form_id =
          action.conditionalSelectValue;
      } else if (
        ['performed_event'].includes(updatedData[action.index].trigger_type)
      ) {
        updatedData[action.index].trigger_details.event_id =
          action.conditionalSelectValue;
      } else if (
        ['page_visited'].includes(updatedData[action.index].trigger_type)
      ) {
        updatedData[action.index].trigger_details.page_detail =
          action.conditionalSelectValue;
      } else if (
        ['email_opened', 'clicked_link_from_email'].includes(
          updatedData[action.index].trigger_type,
        )
      ) {
        updatedData[action.index].trigger_details.mail_type =
          action.conditionalSelectValue;
        if (
          updatedData[action.index].trigger_type === 'clicked_link_from_email'
        ) {
          updatedData[action.index].trigger_details.link_detail = action.link;
        }
        if (
          action.conditionalSelectValue === 'campaign' ||
          updatedData[action.index].trigger_details.mail_type === 'campaign'
        ) {
          updatedData[action.index].trigger_details.campaign_id =
            action.secondConditionalValue;
          updatedData[action.index].trigger_details.mail_filter_id =
            action.campaignMailId;
        } else {
          updatedData[action.index].trigger_details.mail_filter_id =
            action.secondConditionalValue;
        }
      } else if (
        ['unsubscribed_contact'].includes(
          updatedData[action.index].trigger_type,
        )
      ) {
        updatedData[action.index].trigger_details.unsubscription_type =
          'all_mailings';
      } else if (
        ['contact_property_updated'].includes(
          updatedData[action.index].trigger_type,
        )
      ) {
        updatedData[action.index].trigger_details.property = {
          key: '',
          condition: '',
          value: '',
        };
      } else if (
        ['added_mail_subscription', 'removed_mail_subscription'].includes(
          updatedData[action.index].trigger_type,
        )
      ) {
        updatedData[action.index].trigger_details.mail_subscription_id =
          action.conditionalSelectValue;
      } else if (orders.includes(updatedData[action.index].trigger_type)) {
        updatedData[action.index].trigger_details.store_id =
          action.conditionalSelectValue;
        updatedData[action.index].trigger_details.category_id =
          action.secondConditionalValue;
        updatedData[action.index].trigger_details.product_id = action.productId;
        updatedData[action.index].trigger_details.order_value_condition =
          action.orderValueCondition;
        if (action.orderValueCondition !== 'any_value') {
          updatedData[action.index].trigger_details.order_value =
            action.orderValue;
        }
      } else if (date.includes(updatedData[action.index].trigger_type)) {
        updatedData[action.index].trigger_details.trigger_config =
          action.triggerConfig;
        updatedData[action.index].trigger_details.value = action.intervalUnit;
        updatedData[action.index].trigger_details.condition = action.condition;
        updatedData[action.index].trigger_details.key = action.key;
        updatedData[action.index].trigger_details.time = action.time;
        updatedData[action.index].trigger_details.time_zone = action.timeZone;
      }
      return {
        ...state,
        triggerData: updatedData,
      };
    case actions.UPDATE_PERFORM_MULTIPLE:
      let performMultiple = [...state.triggerData];
      performMultiple[action.index].perform_multiple_times = action.checked;
      return {
        ...state,
        triggerData: performMultiple,
      };
    case actions.UPDATE_ACTION_PERFORM_MULTIPLE:
      let actionPerformMultiple = [...state.actionData];
      actionPerformMultiple[action.index].perform_multiple_times =
        action.checked;
      return {
        ...state,
        actionData: actionPerformMultiple,
      };
    case actions.UPDATE_MODAL_SECTION:
      let updatedFilterData = [...state.triggerData];
      updatedFilterData = action.filterValues;
      return {
        ...state,
        triggerData: updatedFilterData,
        triggerModalIndex: action.index,
      };
    case actions.UPDATE_MODAL_INDEX:
      let updatedModalIndex = action.triggerModalIndex;
      let initFilterData = _.cloneDeep(state.triggerData);
      return {
        ...state,
        triggerModalIndex: updatedModalIndex,
        initFilterData: initFilterData,
      };
    case actions.DELETE_TRIGGER_SECTION:
      let deletedTriggerData = [...state.triggerData],
        deletedApiData = [...state.deletedTriggerData];
      deletedTriggerData = deletedTriggerData.filter(
        index => action.triggerDeleteId !== deletedTriggerData.indexOf(index),
      );
      if (
        action.id &&
        !state.triggerData[action.triggerDeleteId].active &&
        state.rulesStatus
      ) {
        deletedApiData = deletedApiData.concat({
          id: action.id,
          _destroy: true,
        });
      }
      return {
        ...state,
        triggerData: deletedTriggerData,
        deletedTriggerData: deletedApiData,
      };
    case actions.ADD_ACTION_SECTION:
      let actionData = state.actionData;
      let action_type = '';
      let subject = '';
      let action_details = {};
      let actionService = 'gist';
      return {
        ...state,
        actionData: actionData.concat({
          action_type,
          action_details,
          service: actionService,
          perform_multiple_times: false,
        }),
        mailSubject: subject,
        mailText: '',
        toUserID: subject,
        fromUserID: subject,
      };
    case actions.UPDATE_ACTION_DATA:
      let updateActionData = [...state.actionData];
      let updateMailSubject = state.mailSubject;
      let updateMailText = state.mailText;
      let toUserId = state.toUserID;
      let fromUserId = state.fromUserID;
      updateActionData[action.index].action_type = action.value
        ? action.value
        : state.actionData[action.index].action_type;
      updateActionData[action.index].action_details = {};
      if (
        ['send_notification_mail'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        if (action.mailDetails) {
          updateActionData[action.index].action_details = action.mailDetails;
          updateMailSubject = action.mailDetails.email_subject;
          updateMailText = action.mailDetails.email_content;
          toUserId = action.mailDetails.email_to_user_ids;
          fromUserId = action.mailDetails.email_from_user_id;
        }
      } else if (
        ['apply_tag', 'remove_tag'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.tag_id =
          action.actionFirstConditionalValue;
      } else if (
        ['subscribe_to_campaign', 'restart_campaign', 'stop_campaign'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.campaign_id =
          action.actionFirstConditionalValue;
        if (
          updateActionData[action.index].action_type === 'subscribe_to_campaign'
        ) {
          updateActionData[action.index].action_details.mail_filter_id =
            action.actionSecondConditionalValue;
        }
      } else if (
        ['move_from_one_campaign_to_another'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.from_campaign_id =
          action.actionFirstConditionalValue;
        updateActionData[action.index].action_details.to_campaign_id =
          action.actionSecondConditionalValue;
      } else if (
        ['send_broadcast_mail'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.mail_filter_id =
          action.actionFirstConditionalValue;
      } else if (
        ['delete_contact'].includes(updateActionData[action.index].action_type)
      ) {
      } else if (
        ['set_contact_property'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.key =
          action.actionFirstConditionalValue;
        updateActionData[action.index].action_details.value =
          action.actionSecondConditionalValue;
      } else if (
        ['add_mail_subscription', 'remove_mail_subscription'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.mail_subscription_id =
          action.actionFirstConditionalValue;
      } else if (
        ['unsubscribe_contact'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.unsubscription_type =
          'all_mailings';
      } else if (
        ['enroll_into_workflow', 'remove_from_workflow'].includes(
          updateActionData[action.index].action_type,
        )
      ) {
        updateActionData[action.index].action_details.workflow_id =
          action.actionFirstConditionalValue;
        if (
          updateActionData[action.index].action_type === 'enroll_into_workflow'
        ) {
          updateActionData[
            action.index
          ].perform_multiple_times = action.actionSecondConditionalValue
            ? action.actionSecondConditionalValue
            : false;
        }
      } else if (
        ['perform_math'].includes(updateActionData[action.index].action_type)
      ) {
        updateActionData[action.index].action_details.operator =
          action.actionFirstConditionalValue;
        updateActionData[action.index].action_details.key =
          action.actionSecondConditionalValue;
        updateActionData[action.index].action_details.value = parseInt(
          action.actionThirdConditionalValue,
        );
      } else if (
        integrations.includes(updateActionData[action.index].action_type)
      ) {
        updateActionData[action.index].action_details.fb_ad_account_id =
          action.actionFirstConditionalValue;
        updateActionData[action.index].action_details.audience_list_id =
          action.actionSecondConditionalValue;
        let accountName = _.find(
          action.fbAccountList,
          list => list.id === action.actionFirstConditionalValue,
        );
        let audienceList = _.find(
          action.fbCustomAudienceList,
          list => list.id === action.actionSecondConditionalValue,
        );
        updateActionData[
          action.index
        ].action_details.fb_ad_account_name = accountName
          ? accountName.display_name
          : '';
        updateActionData[action.index].action_details.audience_list_name =
          action.actionSecondConditionalValue === 0
            ? 'All Custom Audiences'
            : audienceList
              ? audienceList.display_name
              : '';
      } else if (webhook.includes(updateActionData[action.index].action_type)) {
        updateActionData[action.index].action_details.target_url =
          action.actionFirstConditionalValue;
        updateActionData[action.index].action_details.secret_token =
          action.actionSecondConditionalValue;
      } else if (
        ['assign_owner'].includes(updateActionData[action.index].action_type)
      ) {
        updateActionData[action.index].action_details.owner_id =
          action.actionFirstConditionalValue;
      }
      return {
        ...state,
        actionData: updateActionData,
        mailSubject: updateMailSubject,
        mailText: updateMailText,
        toUserID: toUserId,
        fromUserID: fromUserId,
      };
    case actions.DELETE_ACTION_SECTION:
      let deleteData = [...state.actionData],
        deletedActionData = [...state.deletedActionData];
      deleteData = deleteData.filter(
        index => action.actionDeleteId !== deleteData.indexOf(index),
      );
      if (
        action.id &&
        !state.actionData[action.actionDeleteId].active &&
        state.rulesStatus
      ) {
        deletedActionData = deletedActionData.concat({
          id: action.id,
          _destroy: true,
        });
      }
      return {
        ...state,
        actionData: deleteData,
        deletedActionData: deletedActionData,
      };
    case actions.UPDATE_ACTION_MODAL:
      let initActionModalData = _.cloneDeep(state.actionData);
      return {
        ...state,
        actionModalIndex: action.index,
        initActionModalData: initActionModalData,
        actionModalVisible: action.actionModalVisible,
      };
    case actions.SEND_EMAIL_VALIDATION:
      return {
        ...state,
        actionModalVisible: action.actionModalVisible,
      };
    case actions.CLEAR_ACTION_MODAL:
      return {
        ...state,
        actionData: state.initActionModalData,
        actionModalVisible: action.actionModalVisible,
      };
    case actions.RULES_SET_EMAIL_TEXT:
      let emailText = state.actionData;
      emailText[action.index].action_details.email_content = action.message;
      return {
        ...state,
        mailText: action.message,
        actionData: emailText,
      };
    case actions.RULES_SET_EMAIL_SUBJECT:
      let emailSubject = state.actionData;
      emailSubject[action.index].action_details.email_subject = action.subject;
      return {
        ...state,
        mailSubject: action.subject,
        actionData: emailSubject,
      };
    case actions.RULES_SET_FROM_EMAIL_USER_ID:
      let emailFrom = state.actionData;
      emailFrom[action.index].action_details.email_from_user_id =
        action.fromUserID;
      return {
        ...state,
        fromUserID: action.fromUserID,
        actionData: emailFrom,
      };
    case actions.RULES_SET_TO_EMAIL_USER_ID:
      let emailToUser = state.actionData;
      emailToUser[action.index].action_details.email_to_user_ids =
        action.toUserID;
      return {
        ...state,
        toUserID: action.toUserID,
        actionData: emailToUser,
      };
    case actions.FETCH_LINK_SUCCESS:
      if (action.params.value === 'any_email') {
        return {
          ...state,
          allLinkList: {
            ...state.allLinkList,
            allEmailLinkList: action.payload,
          },
        };
      } else if (action.params.campaignId) {
        return {
          ...state,
          allLinkList: {
            ...state.allLinkList,
            allCampaignEmailLinkList: action.payload,
          },
        };
      } else if (
        action.params.value === 'auto' ||
        action.params.value === 'manual' ||
        action.params.value === 'campaign'
      ) {
        if (action.params.value === 'auto') {
          return {
            ...state,
            allLinkList: {
              ...state.allLinkList,
              allAutoEmailLinkList: action.payload,
            },
          };
        } else if (action.params.value === 'manual') {
          return {
            ...state,
            allLinkList: {
              ...state.allLinkList,
              allBroadcastEmailLinkList: action.payload,
            },
          };
        } else if (action.params.value === 'campaign') {
          return {
            ...state,
            allLinkList: {
              ...state.allLinkList,
              allCampaignLinkList: action.payload,
            },
          };
        }
      }
      return {
        ...state,
      };
    case actions.RESET_ACTION_DATA:
      return {
        ...state,
        actionData: [
          {
            action_type: '',
            action_details: { perform_multiple_times: false },
            service: 'gist',
          },
        ],
      };
    case actions.FETCH_TO_USERS_SUCCESS:
      return {
        ...state,
        teamatesList: action.payload.team_mates,
      };
    case actions.FETCH_ACTIONS_SUCCESS:
      return {
        ...state,
        actionSelectOptions: action.actionOptions,
      };
    case actions.SHOW_EMPTY_PAGE_LOADER:
      return {
        ...state,
        showEmptyPageLoader: true,
      };
    case actions.CLEAR_DELETED_ARRAY:
      return {
        ...state,
        deletedActionData: [],
        deletedTriggerData: [],
      };
    case actions.CLEAR_TRIGGER_MODAL:
      return {
        ...state,
        triggerData: state.initFilterData,
      };
    case actions.UPDATE_PROPERTY_DATA_RULES:
      let { propertyKey, propertyValue, propertyCondition } = action.payload,
        addedProperty = _.cloneDeep(
          state.triggerData[action.triggerIndex].trigger_details,
        );
      if (propertyKey) {
        addedProperty = {
          key: propertyKey,
          value: propertyCondition,
        };
      }
      if (propertyCondition) {
        let property;
        state.contactList.user_properties.map(category => {
          category.properties.map(newProperty => {
            if (newProperty.property_name === addedProperty.key) {
              property = _.cloneDeep(newProperty);
            }
            return null;
          });
          return null;
        });
        addedProperty.value = '';
        if (addedProperty.key === 'user_type') {
          addedProperty = {
            key: addedProperty.key,
            value: propertyCondition,
          };
        } else if (
          ['has_any_value', 'has_no_value'].includes(propertyCondition)
        ) {
          addedProperty = {
            key: addedProperty.key,
            condition: propertyCondition,
          };
        } else if (
          property &&
          !_.isEmpty(property) &&
          property.property_type === 'boolean'
        ) {
          addedProperty = {
            key: addedProperty.key,
            value: propertyCondition,
            condition: 'equals',
          };
        } else {
          addedProperty.condition = propertyCondition;
        }
      }
      if (![undefined, null].includes(propertyValue)) {
        addedProperty.value = propertyValue;
      }
      let triggerValue = [...state.triggerData];
      triggerValue[action.triggerIndex].trigger_details = addedProperty;
      return {
        ...state,
        triggerData: triggerValue,
      };
    case actions.APPEND_RULES_TAG_LIST:
      const newTag = [
        {
          id: action.payload.id,
          display_name: action.payload.tags,
        },
      ];
      return {
        ...state,
        tagsList: [...newTag, ...state.tagsList],
      };
    case actions.RULES_GET_PRODUCT_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        productCategoriesList: action.payload,
      };
    case actions.RULES_UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        productCategoryList: [...state.productCategoryList, ...action.payload],
      };
    case actions.RULES_GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productLists: {
          ...state.productLists,
          [action.payload.categoryId ? action.payload.categoryId : 0]: action
            .payload.productListData,
        },
      };
    case actions.RULES_UPDATE_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productLists: {
          ...state.productLists,
          [action.payload.categoryId ? action.payload.categoryId : 0]: [
            ...state.productLists[action.payload.categoryId ? action.payload.categoryId : 0],
            ...action.payload.productListData,
          ],
        },
      };
    case actions.RULES_PRODUCTS_CATEGORIES_SEARCH:
      return {
        ...state,
        categorySearch: action.value,
      };
    case actions.RULES_PRODUCTS_LIST_SEARCH:
      return {
        ...state,
        productSearch: action.value,
      };
    default:
      return state;
  }
}
