import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import sharedActions from '../sharedReducers/actions';
import settings from '../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  patchPromise,
  deletePromise,
  updateBrowserHistory,
  unAuthorisedRedirection,
  trackEvent,
  unProcessableEntity,
} from '../../helpers/utility';
import { message } from 'antd';
import { store } from '../store';

export function* fetchCollections() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/categories`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_COLLECTION_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch collections failed');
    }
  }
}

export function* createCollection(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/categories`;
    let data = yield call(() => postPromise(url, params.payload));
    trackEvent('Created KB collection');
    yield put({ type: actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST });
    yield put({
      type: actions.CHANGE_COLLECTIONS_MODAL_DETAIL,
      visibility: false,
    });
    yield put({
      type: actions.SET_COLLECTIONS_LOADER,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Create collection failed');
    yield put({
      type: actions.SET_COLLECTIONS_LOADER,
      payload: false,
    });
  }
}

export function* editCollection(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/categories/${params.key}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EDIT_COLLECTION_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.CHANGE_COLLECTIONS_MODAL_DETAIL,
      visibility: false,
      id: null,
      editCollection: {},
      isEditCollection: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Collection update failed');
    yield put({
      type: actions.SET_COLLECTIONS_LOADER,
      payload: false,
    });
  }
}

export function* deleteCollection(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/categories/${params.key}`;
    let data = yield call(() => deletePromise(url));
    yield put({ type: actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Collection delete failed');
    }
  }
}

export function* fetchAuthors() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/users_list`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AUTHOR_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch Authors failed');
    }
  }
}

export function* fetchArticleList(params) {
  let {
    articleStatus,
    author,
    articlesSortKey,
    articlesSortValue,
    articleSearchText,
  } = store.getState().KnowledgeBase;
  author = author === 'all' ? '' : author;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles?status=${articleStatus}&author_id=${author}&sort_key=${articlesSortKey}&sort_type=${articlesSortValue}&search_text=${articleSearchText}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ARTICLE_LIST_SUCCESS,
      payload: data.data,
    });
    yield put({ type: actions.SET_ARTICLES_LOADER, payload: false });
  } catch (error) {
    yield put({ type: actions.SET_ARTICLES_LOADER, payload: false });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch articles failed');
    }
  }
}

export function* deleteArticles(params) {
  let { selectedCollection, selectedSection } = store.getState().KnowledgeBase,
    deleteArticles = {
      ...params.deleteArticles,
      category_secret: selectedCollection === 'all' ? '' : selectedCollection,
      section_secret: selectedSection,
    };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles`;
    let data = yield call(() => deletePromise(url, deleteArticles));
    message.success(data.message);
    const action = params.isFromCollections
      ? 'DELETE_FROM_ARTICLE_AND_COLLECTIONS'
      : 'DELETE_ARTICLE_SUCCESS';
    yield put({
      type: action,
      payload: params.deleteArticles.delete_articles,
    });
    yield put({ type: actions.UPDATE_ARTICLES_COUNT, payload: data.data });
    yield put({ type: actions.RESET_SELECTED_ARTICLES });
    if (params.isFromCollections) {
      yield put({ type: actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST });
    }
  } catch (error) {
    yield put({ type: actions.RESET_SELECTED_ARTICLES });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Delete section failed');
    }
  }
}

export function* duplicateArticle(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/${
      params.articleId
    }/duplicate`;
    let data = yield call(() => postPromise(url));
    yield put({
      type: actions.FETCH_ARTICLE_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.SET_ARTICLE_ID,
      payload: data.data.article_secret,
    });
    updateBrowserHistory(`knowledge-base/article/${data.data.article_secret}`);
    yield put({ type: actions.SET_FETCH_ARTICLE_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Duplicate article failed');
    yield put({ type: actions.SET_FETCH_ARTICLE_LOADER, payload: false });
  }
}

export function* changeArticlesStatus(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/change_status`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({ type: actions.FETCH_ARTICLE_LIST });
    yield put({ type: actions.RESET_SELECTED_ARTICLES });
  } catch (error) {
    yield put({ type: actions.RESET_SELECTED_ARTICLES });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Article status change failed');
    }
  }
}

export function* fetchArticle(params) {
  const { id, code, isFromRestore, isDefaultLanguage } = params;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/${id}/edit`;
    if (isFromRestore) {
      url += `?restore=true&language=${code}`;
    }
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ARTICLE_SUCCESS,
      payload: data.data,
      code,
      isFromRestore,
      isDefaultLanguage,
    });
    let articleUrl = data.data.url;
    if (isFromRestore && !isDefaultLanguage) {
      articleUrl = data.data.translations[code].url;
    }
    yield put({
      type: actions.FETCH_ARTICLE_URL_SUCCESS,
      payload: { articleUrl },
    });
    if (code && !isFromRestore) {
      store.dispatch({
        type: actions.SET_SELECTED_LANGUAGE_ARTICLE_DATA,
        payload: {
          code,
          default: code === data.data.article.language,
        },
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error, true);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch article details failed');
    }
    yield put({
      type: actions.FETCH_ARTICLE_FAILURE,
      payload: false,
    });
  }
}
export function* changeArticlesPosition(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/update_priority`;
    let data = yield call(() => patchPromise(url, params.orderedArticles));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Articles position change failed');
    }
  }
}

export function* fetchArticleUrl() {
  let { authorList } = store.getState().KnowledgeBase;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/new`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ARTICLE_URL_SUCCESS,
      payload: { articleUrl: data.data.url },
    });
    yield put({
      type: actions.ON_SAVE_AUTHOR,
      payload: authorList[0].id,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch article url failed');
    }
    yield put({ type: actions.SET_FETCH_ARTICLE_LOADER, payload: false });
  }
}

export function* createArticle(params) {
  let { articleId } = store.getState().KnowledgeBase,
    data;
  try {
    if (articleId === '' || params.isForCreateArticle) {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/articles`;
      data = yield call(() => postPromise(url, params.formData));
      yield put({
        type: actions.CREATE_ARTICLE_SUCCESS,
        payload: data.data.article,
        isForCreateArticle: params.isForCreateArticle,
      });
      yield put({
        type: actions.FETCH_ARTICLE_URL_SUCCESS,
        payload: { articleUrl: data.data.article.url },
      });
      trackEvent('Created KB article');
    } else {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/articles/${articleId}/save_draft`;
      data = yield call(() => patchPromise(url, params.formData));
      yield put({
        type: actions.CREATE_ARTICLE_SUCCESS,
        payload: params.object,
        isUpdate: params.isUpdate,
      });
    }
    message.success(data.message);
    if (data.data && data.data.article_secret) {
      // Set article ID and duplicate is false
      yield put({
        type: actions.SET_ARTICLE_ID,
        payload: data.data.article_secret,
      });
      yield put({ type: actions.SET_DUPLICATE_DETAILS, payload: false });
      updateBrowserHistory(
        `knowledge-base/article/${data.data.article_secret}`,
      );
    }
    if (params.isUpdate) {
      const { formData, isFromPublish, language } = params;
      store.dispatch({
        type: actions.UPDATE_ARTICLE_LIVE_DETAILS,
        isFromSaveDraft: true,
        formData,
        isFromPublish,
        language,
      });
    }
  } catch (error) {
    yield put({ type: actions.CREATE_ARTICLE_FAILURE });
    unAuthorisedRedirection(error, true);
    if (error.response && error.response.status === 422) {
      message.error(
        error.response.data.errors || error.response.data.error.message.join(),
      );
    } else if (error.response && error.response.status !== 403) {
      message.error('Something went wrong');
    }
  }
}

export function* moveArticles(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/move_articles`;
    let data = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.DELETE_ARTICLE_SUCCESS,
      payload: params.object.move_articles,
    });
    message.success(data.message);
    yield put({ type: actions.RESET_SELECTED_ARTICLES });
    yield put({ type: actions.UPDATE_ARTICLES_COUNT, payload: data.data });
  } catch (error) {
    yield put({ type: actions.RESET_SELECTED_ARTICLES });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Move articles failed');
    }
  }
}

export function* changeCategoryPosition(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/category/update_category_priority`;
    let data = yield call(() => patchPromise(url, params.orderedCollection));
    message.success(data.message);
  } catch (error) {
    yield put({ type: actions.COLLECTION_POSITION_CHANGE_FAILURE });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Collection position change failed');
    }
  }
}
export function* importArticle(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/migrate_articles`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.IMPORT_KB_UPDATE_MODAL,
      payload: {
        loading: false,
        modalVisibility: false,
      },
    });
  } catch (error) {
    yield put({
      type: actions.IMPORT_KB_UPDATE_MODAL,
      payload: {
        loading: false,
        modalVisibility: true,
      },
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Migration articles failed');
    }
  }
}
export function* publishArticle(params) {
  const { payload } = params;
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/change_status`;
    let data = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.UPDATE_PUBLISH_STATUS_SUCCESS,
      payload,
    });
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.UPDATE_PUBLISH_STATUS_FAILURE,
      payload: !params.status,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Article status update failed');
  }
}
export function* updateArticleLiveDetails(params) {
  let { articleId } = store.getState().KnowledgeBase;
  const {
    formData,
    isFromPublish,
    language,
    isDefaultLanguage,
    isFromSaveDraft,
  } = params;
  try {
    if (!isFromSaveDraft) {
      yield put({
        type: actions.UPDATE_ARTICLE_LIVE_DETAILS_LOADER,
        payload: true,
      });
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/${articleId}/update_live_article`;
    let data = yield call(() => patchPromise(url, formData));
    if (!isFromSaveDraft) {
      yield put({
        type: actions.CREATE_ARTICLE_SUCCESS,
        payload: data.data.article,
        isUpdate: true,
      });
    }
    yield put({
      type: actions.SET_ARTICLE_RESTORE_DETAIL,
    });
    yield put({
      type: actions.UPDATE_ARTICLE_LIVE_DETAILS_LOADER,
      payload: false,
    });
    if (isFromPublish && window.location.pathname.includes(articleId)) {
      yield put({
        type: actions.UPDATE_PUBLISH_STATUS,
        payload: {
          change_status: [articleId],
          language,
          status: isFromPublish,
          isDefaultLanguage,
        },
      });
      yield put({
        type: actions.CHANGE_RESTORABLE_KEY,
        payload: {
          language,
          isDefaultLanguage,
        },
      });
    }
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.UPDATE_ARTICLE_LIVE_DETAILS_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Article live details update failed');
  }
}

export function* fetchSupportedLanguages(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/knowledge_base/supported_languages`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SUPPORTED_LANGUAGES_SUCCESS,
      payload: data.data,
    });
    if (params.isForComposer) {
      const defaultLanguage = data.data.supported_languages.find(
        (lang) => lang.default,
      );
      if (defaultLanguage) {
        yield put({
          type: sharedActions.SET_SELECTED_ARTICLE_LANGUAGE,
          payload: defaultLanguage.code,
        });
      }
      params.callback();
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Fetch supported languages failed');
  }
}

export function* fetchArticlesAndCollections() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/articles_and_collections`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error, true);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch article details failed');
    }
  }
}
export function* setArticlePosition(params) {
  const { payload, updateTreeData, callback, onDragFailure } = params;
  try {
    const { article_ids, target_category } = payload;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/update_article_hierarchy`;
    const data = yield call(() => patchPromise(url, payload));
    //for moving via modal
    updateTreeData && updateTreeData(article_ids, target_category, data.data);
    yield put({
      type: actions.UPDATE_COLLECTIONS_MODAL_DETAILS,
      payload: {
        isVisible: false,
        collectionLoading: false,
      },
    });
    //update articles count & hierarchy_level
    callback && callback(data.data);
    message.success('Article updated Successfully');
  } catch (error) {
    onDragFailure && onDragFailure();
    yield put({
      type: actions.UPDATE_COLLECTIONS_MODAL_DETAILS,
      payload: {
        collectionLoading: false,
      },
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Article update failed');
  }
}
export function* setCollectionsPosition(params) {
  const { payload, updateTreeData, callback, onDragFailure } = params;
  try {
    const { source_categories, target_category } = payload;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/category/update_category_hierarchy`;
    const data = yield call(() => patchPromise(url, payload));
    message.success('Collections updated Successfully');
    updateTreeData &&
      updateTreeData(source_categories, target_category, data.data);
    yield put({
      type: actions.UPDATE_COLLECTIONS_MODAL_DETAILS,
      payload: {
        isVisible: false,
        collectionLoading: false,
      },
    });
    callback && callback(data.data);
  } catch (error) {
    onDragFailure && onDragFailure();
    yield put({
      type: actions.UPDATE_COLLECTIONS_MODAL_DETAILS,
      payload: {
        collectionLoading: false,
      },
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Collections update Failed');
  }
}
export function* fetchEditCollectionDetail({ payload }) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/categories/${payload}/edit`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.CHANGE_COLLECTIONS_MODAL_DETAIL,
      editCollection: data.data,
      visibility: true,
      id: payload,
      isEditCollection: true,
    });
  } catch (error) {
    message.error('Fetch collections details failed');
  }
}
export function* uploadCustomIcon(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/categories/upload_svg`;
    let { data } = yield call(() => postPromise(url, params.payload));
    if (params.isApplyToAllEnabled) {
      yield put({
        type: actions.APPLY_ICON_TO_ALL_LANGUAGE,
        payload: {
          ...data,
          apply_to_all: true,
        },
      });
    } else {
      yield put({
        type: actions.UPLOAD_CUSTOM_ICON_SUCCESS,
        payload: data,
      });
    }

    message.success('Custom icon uploaded successfully');
  } catch (error) {
    yield put({
      type: actions.UPLOAD_CUSTOM_ICON_FAILURE,
    });
    message.error('Upload custom icon failed');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_COLLECTION_LIST, fetchCollections),
    takeEvery(actions.CREATE_COLLECTION, createCollection),
    takeEvery(actions.EDIT_COLLECTION, editCollection),
    takeEvery(actions.DELETE_COLLECTION, deleteCollection),
    takeEvery(actions.FETCH_AUTHOR_LIST, fetchAuthors),
    takeEvery(actions.FETCH_ARTICLE_LIST, fetchArticleList),
    takeEvery(actions.DELETE_ARTICLE, deleteArticles),
    takeEvery(actions.DUPLICATE_ARTICLE, duplicateArticle),
    takeEvery(actions.ARTICLES_STATUS_CHANGE, changeArticlesStatus),
    takeEvery(actions.FETCH_ARTICLE, fetchArticle),
    takeEvery(actions.CHANGE_ARTICLES_POSITION, changeArticlesPosition),
    takeEvery(actions.FETCH_ARTICLE_URL, fetchArticleUrl),
    takeEvery(actions.CREATE_ARTICLE, createArticle),
    takeEvery(actions.MOVE_ARTICLES, moveArticles),
    takeEvery(actions.CHANGE_CATEGORY_POSITION, changeCategoryPosition),
    takeEvery(actions.IMPORT_ARTICLE, importArticle),
    takeEvery(actions.UPDATE_PUBLISH_STATUS, publishArticle),
    takeEvery(actions.UPDATE_ARTICLE_LIVE_DETAILS, updateArticleLiveDetails),
    takeEvery(actions.FETCH_SUPPORTED_LANGUAGES, fetchSupportedLanguages),
    takeEvery(actions.SET_ARTICLE_POSITION, setArticlePosition),
    takeEvery(actions.SET_COLLECTIONS_POSITION, setCollectionsPosition),
    takeEvery(actions.FETCH_EDIT_COLLECTION_DETAIL, fetchEditCollectionDetail),
    takeEvery(actions.UPLOAD_CUSTOM_ICON, uploadCustomIcon),
    takeEvery(
      actions.FETCH_ARTICLES_AND_COLLECTIONS_LIST,
      fetchArticlesAndCollections,
    ),
  ]);
}
