import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Skeleton, Divider } from 'antd';
import NumberFormat from 'react-number-format';
import {
  getPeriod,
  roundOffByTwoDecimal,
  removeUnderscore,
} from '../../helpers/utility';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/auth/actions';
import { supportBotAddonDetails } from './constants';

function AddonModal(props) {
  const dispatch = useDispatch(),
    [isAddonLoader, setLoader] = useState(true),
    { addonContent, isModalOpen, setModalOpen } = props,
    addonDetails = useSelector(({ Auth }) => Auth.addonDetails),
    addonLoader = useSelector(({ Auth }) => Auth.addonLoader);

  const updateAddonDetails = () => {
    dispatch({
      type: actions.ADDON_LOADER,
      payload: true,
    });
    dispatch({
      type: actions.UPDATE_ADDON_DETAILS,
      payload: { add_on_name: addonContent.restriction, action: 'activate' },
      callback: () => setModalOpen(false),
    });
  };

  useEffect(() => {
    dispatch({
      type: actions.FETCH_ADDON_DETAILS,
      key: addonContent.restriction,
    });
  }, []);

  useEffect(
    () => {
      if (addonDetails) {
        setLoader(false);
      }
    },
    [addonDetails],
  );

  return (
    <Modal
      width={1000}
      visible={isModalOpen}
      onCancel={() => setModalOpen(false)}
      footer={null}
      wrapClassName={'addon-modal'}
      centered={true}
    >
      <Row className="body" type={'flex'}>
        <Col span={16} className="content">
          <h1>
            {addonContent.restriction === 'support_bot'
              ? 'Emma'
              : removeUnderscore(addonContent.restriction)}
          </h1>
          <p className={'description'}>
            Instantly resolve 33% of your customers’ most common questions with
            with Emma
          </p>
          <Divider className="divider" />
          <h3>All the features you need and more</h3>
          {isAddonLoader ? (
            <Skeleton
              title={false}
              paragraph={{ rows: 5, width: '100%' }}
              active
            />
          ) : (
            <div className={'flex-coloumn'}>
              <div>
                {supportBotAddonDetails.map(
                  (value, index) =>
                    index < 3 && (
                      <div key={value.title}>
                        <h4>{value.title}</h4>
                        {value.features.map((feature, index) => (
                          <li key={index}>
                            <i className="far fa-check" />
                            {feature}
                          </li>
                        ))}
                      </div>
                    ),
                )}
              </div>
              <div>
                {supportBotAddonDetails.map(
                  (value, index) =>
                    index > 2 && (
                      <div key={value.title}>
                        <h4>{value.title}</h4>
                        {value.features.map((feature, index) => (
                          <li key={index}>
                            <i className="far fa-check" />
                            {feature}
                          </li>
                        ))}
                      </div>
                    ),
                )}
              </div>
            </div>
          )}
        </Col>
        <Col span={8} className="details">
          {isAddonLoader ? (
            <Skeleton
              title={false}
              paragraph={{ rows: 5, width: '100%' }}
              active
            />
          ) : (
            <>
              <i className={'far fa-arrow-alt-circle-up'} />
              <p className={'heading'}>Subscribe to unlock this feature</p>
              <div className={'cost'}>
                <span className={'price'}>
                  <span>
                    {addonContent.restriction === 'support_bot'
                      ? 'Emma'
                      : removeUnderscore(addonContent.restriction)}
                  </span>
                  <span>
                    <NumberFormat
                      value={roundOffByTwoDecimal(addonDetails.default_price)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      suffix={'/'}
                    />
                    <span className={'per'}>{getPeriod('monthly')}</span>
                  </span>
                </span>
              </div>
              <p className={'light'}>Billed on your usual invoice date</p>
              <Button
                className={'btn btn-md btn-secondary-outline'}
                onClick={updateAddonDetails}
                loading={addonLoader}
              >
                Subscribe
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
}

export default AddonModal;
