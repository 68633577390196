import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch, generatePath } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import NotFound from '../Page/404';
import { AllowRoutesOnBlockedProjects } from '../../redux/constants';
import {
  fetchTeamRestrictedFeatures,
  isSettingsRestrictedTeam,
} from '../../helpers/utility';
import NotAuthorised from '../Page/NotAuthorised';
import Collections from '../../components/KnowledgeBase/Collections';

const routes = [
  {
    path: ':project_secret_key/contacts',
    component: asyncComponent(() => import('../Contacts/Contacts.js')),
  },
  {
    path: ':project_secret_key/companies',
    component: asyncComponent(() => import('../Companies/Companies.js')),
  },
  {
    path: ':project_secret_key/company/:company_id',
    component: asyncComponent(() => import('../Companies/CompanyContainer.js')),
  },
  {
    path: ':project_secret_key/live-view',
    component: asyncComponent(() => import('../LiveView/LiveView.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/people',
    redirect: 'contacts',
  },
  {
    path: ':project_secret_key/conversations/rules',
    component: asyncComponent(() => import('../InboxAutomation/Rules.js')),
  },
  {
    path: ':project_secret_key/conversations/rule/:rule_id',
    component: asyncComponent(() => import('../InboxAutomation/RuleTabs.js')),
  },
  {
    path: ':project_secret_key/conversations/rule',
    component: asyncComponent(() => import('../InboxAutomation/RuleTabs.js')),
  },
  {
    path: ':project_secret_key/conversations/:conversation_identifier',
    component: asyncComponent(() => import('../Chat/Chat.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/(conversations|messages)',
    redirect: 'conversations/latest',
  },
  {
    path: ':project_secret_key/search',
    redirect: 'conversations/search',
  },
  {
    path: ':project_secret_key/table',
    redirect: 'conversations/table',
  },
  {
    path: ':project_secret_key/import_csv',
    component: asyncComponent(() => import('../Contacts/ImportCsv.js')),
  },
  {
    path: ':project_secret_key/progress',
    component: asyncComponent(() => import('../Contacts/ImportProgress.js')),
  },
  {
    path: ':project_secret_key/people/:contact_info',
    redirect: 'contacts/:contact_info',
  },
  {
    path: ':project_secret_key/contacts/:contact_info',
    component: asyncComponent(() => import('../Contacts/ProfileView.js')),
  },
  {
    path: ':project_secret_key/import/mailChimp',
    component: asyncComponent(() =>
      import('../Contacts/ImportCsvFromMailChimp.js'),
    ),
  },
  // {
  //   path: ':project_secret_key/email/behavioral',
  //   component: asyncComponent(() => import('../Messages/Email/AutoEmails/Home.js')),
  // },
  {
    path: ':project_secret_key/email/behavioral/:email_id',
    component: asyncComponent(() =>
      import('../Messages/Email/AutoEmails/EmailTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/email/behavioral/:email_id/reports',
    component: asyncComponent(() =>
      import('../Messages/Email/AutoEmails/Reports.js'),
    ),
  },
  // {
  //   path: ':project_secret_key/email/broadcasts',
  //   component: asyncComponent(() => import('../Messages/Email/Broadcast/Home.js')),
  // },
  {
    path: ':project_secret_key/messages/email',
    component: asyncComponent(() => import('../Messages/Email/Home.js')),
  },
  {
    path: ':project_secret_key/messages/post',
    component: asyncComponent(() => import('../Messages/Posts/Home.js')),
  },
  {
    path: ':project_secret_key/messages/post/:chat_id',
    component: asyncComponent(() =>
      import('../Messages/Posts/OnGoing/ChatTabs'),
    ),
  },
  {
    path: ':project_secret_key/messages/chat',
    component: asyncComponent(() => import('../Messages/Chats/Home.js')),
  },
  {
    path: ':project_secret_key/chats/:chat_id',
    component: asyncComponent(() =>
      import('../Messages/Chats/OnGoing/ChatTabs'),
    ),
  },
  {
    path: ':project_secret_key/bots',
    redirect: 'messages/bots',
  },
  {
    path: ':project_secret_key/messages/bots',
    component: asyncComponent(() => import('../Messages/Bots/Home')),
  },
  {
    path: ':project_secret_key/bots/:assistant_id',
    component: asyncComponent(() =>
      import('../Messages/Bots/Instant/AssistantTabs'),
    ),
  },
  {
    path: ':project_secret_key/messages/surveys',
    component: asyncComponent(() => import('../Messages/Surveys/Home.js')),
  },
  {
    path: ':project_secret_key/surveys/:survey_id',
    component: asyncComponent(() =>
      import('../Messages/Surveys/EditorTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/surveys/:survey_id/results',
    component: asyncComponent(() =>
      import('../Messages/Surveys/ReportHome.js'),
    ),
  },
  {
    path: ':project_secret_key/messages/tours',
    component: asyncComponent(() => import('../Messages/Tours/Home.js')),
  },
  {
    path: ':project_secret_key/tours/:tour_id',
    component: asyncComponent(() => import('../Messages/Tours/EditorTabs.js')),
  },
  {
    path: ':project_secret_key/support/bots/answers',
    component: asyncComponent(() => import('../SupportBot/index')),
  },
  {
    path: ':project_secret_key/support/bots/setup',
    component: asyncComponent(() => import('../Settings/SupportBot')),
  },
  {
    path: ':project_secret_key/support/bots/contents',
    component: asyncComponent(() => import('../SupportBot/EmmaSources')),
  },
  {
    path: ':project_secret_key/support/bots/contents/:source_id',
    component: asyncComponent(() => import('../SupportBot/EmmaSources')),
  },
  {
    path: ':project_secret_key/support/bot/:assistant_id',
    component: asyncComponent(() => import('../SupportBot/SupportBotTabs')),
  },
  {
    path: ':project_secret_key/support/bots/unanswered',
    component: asyncComponent(() =>
      import('../SupportBot/UnansweredQuestions'),
    ),
  },
  {
    path: ':project_secret_key/support/bots/unhelpful',
    component: asyncComponent(() => import('../SupportBot/UnhelpfulAnswers')),
  },
  {
    path: ':project_secret_key/mail_filters',
    redirect: 'email/broadcasts',
  },
  {
    path: ':project_secret_key/email/broadcasts/:email_id',
    component: asyncComponent(() =>
      import('../Messages/Email/Broadcast/EmailTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/email/broadcasts/:email_id/reports',
    component: asyncComponent(() =>
      import('../Messages/Email/Broadcast/Reports.js'),
    ),
  },
  {
    path: ':project_secret_key/messages/campaigns',
    component: asyncComponent(() => import('../Messages/Email/Home.js')),
  },
  {
    path: ':project_secret_key/email/campaigns/:campaign_id',
    component: asyncComponent(() =>
      import('../Messages/Email/Campaigns/EmailTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/email/campaigns/:campaign_id/email/:email_id',
    component: asyncComponent(() =>
      import('../Messages/Email/Campaigns/Composer.js'),
    ),
  },
  {
    path: ':project_secret_key/email/campaigns/:campaign_id/email/:email_id/reports',
    component: asyncComponent(() =>
      import('../Messages/Email/Campaigns/Reports.js'),
    ),
  },
  {
    path: ':project_secret_key/messages/rules',
    component: asyncComponent(() => import('../Automation/Rules/Rules.js')),
  },
  {
    path: ':project_secret_key/rules',
    redirect: 'messages/rules',
  },
  {
    path: ':project_secret_key/triggers',
    redirect: 'messages/rules',
  },
  {
    path: ':project_secret_key/rules/:rule_id',
    component: asyncComponent(() => import('../Automation/Rules/RulesTabs.js')),
  },
  {
    path: ':project_secret_key/messages/workflows',
    component: asyncComponent(() =>
      import('../Automation/Workflow/Workflow.js'),
    ),
  },
  {
    path: ':project_secret_key/workflows',
    redirect: 'messages/workflows',
  },
  {
    path: ':project_secret_key/workflows/:workflow_id',
    component: asyncComponent(() =>
      import('../Automation/Workflow/WorkflowTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/workflows/:workflow_id/report',
    component: asyncComponent(() =>
      import('../../components/Automation/Workflow/WorkflowReport.js'),
    ),
  },
  {
    path: ':project_secret_key/knowledge-base/articles',
    component: asyncComponent(() => import('../KnowledgeBase/Home.js')),
  },
  {
    path: ':project_secret_key/knowledge-base/collections',
    component: asyncComponent(() =>
      import('../KnowledgeBase/CollectionsHome.js'),
    ),
  },
  {
    path: ':project_secret_key/categories/:category_secret/articles',
    redirect: 'knowledge-base/articles',
  },
  {
    path: ':project_secret_key/articles',
    redirect: 'knowledge-base/articles',
  },
  {
    path: ':project_secret_key/knowledge-base/all-articles',
    redirect: 'knowledge-base/articles',
  },
  {
    path: ':project_secret_key/knowledge-base/article/:article_id',
    component: asyncComponent(() => import('../KnowledgeBase/Article.js')),
  },
  {
    path: ':project_secret_key/knowledge-base/article',
    component: asyncComponent(() => import('../KnowledgeBase/Article.js')),
  },
  {
    path: ':project_secret_key/settings/',
    redirect: 'settings/workspace-defaults',
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/workspace-defaults',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Settings.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/users',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Users.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/team',
    redirect: 'settings/users',
  },
  {
    path: ':project_secret_key/settings/teams',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/TeamsAndRoles.js')),
  },
  {
    path: ':project_secret_key/settings/roles',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/TeamsAndRoles.js')),
  },
  {
    path: ':project_secret_key/settings/tracking-code',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/TrackingCode.js')),
  },
  {
    path: ':project_secret_key/settings/tracking_code',
    redirect: 'settings/tracking-code',
  },
  {
    path: ':project_secret_key/settings/mobile-app',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/MobileApp.js')),
  },
  {
    path: ':project_secret_key/settings/mobile-app',
    redirect: 'settings/mobile-app',
  },
  {
    path: ':project_secret_key/settings/security',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Security.js')),
  },
  {
    path: ':project_secret_key/settings/contact-tags',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ContactTags.js')),
  },
  {
    path: ':project_secret_key/settings/people_tags',
    redirect: 'settings/contact-tags',
  },
  {
    path: ':project_secret_key/settings/contact-segments',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ContactSegments.js')),
  },
  {
    path: ':project_secret_key/settings/people_segments',
    redirect: 'settings/contact-segments',
  },
  {
    path: ':project_secret_key/settings/contact-properties',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ContactProperties.js')),
  },
  {
    path: ':project_secret_key/settings/conversation-properties',
    settingsRout: true,
    component: asyncComponent(() =>
      import('../Settings/ConversationProperties.js'),
    ),
  },
  {
    path: ':project_secret_key/settings/custom_attributes',
    redirect: 'settings/contact-properties',
  },
  {
    path: ':project_secret_key/settings/qualification-data',
    settingsRout: true,
    component: asyncComponent(() =>
      import('../Settings/QualificationDataContainer.js'),
    ),
  },
  {
    path: ':project_secret_key/settings/qualification_data',
    redirect: 'settings/qualification-data',
  },
  {
    path: ':project_secret_key/settings/exports',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ExportsContainer.js')),
  },
  {
    path: ':project_secret_key/settings/export_status',
    redirect: 'settings/exports',
  },
  {
    path: ':project_secret_key/settings/imports',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ImportsContainer.js')),
  },
  {
    path: ':project_secret_key/settings/conversation-tags',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ChatTags.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/chat_tags',
    redirect: 'settings/conversation-tags',
  },
  {
    path: ':project_secret_key/settings/snippets',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Snippets.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/appearance',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Appearance.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/chat_appearance',
    redirect: 'settings/appearance',
  },
  {
    path: ':project_secret_key/settings/availability',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Availability.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/messenger',
    redirect: 'settings/availability',
  },
  {
    path: ':project_secret_key/settings/visibility',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Visibility.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/language',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Language.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/qualification-bot',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/QualificationBot.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/spam',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Spam.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/(blocked_users|blocked_ips)',
    redirect: 'settings/spam',
  },
  {
    path: ':project_secret_key/settings/advanced',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Advanced.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/messenger-apps',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/MessengerApps.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/data-and-privacy',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/DataAndPrivacy.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/project-blocked',
    component: asyncComponent(() => import('../Page/ProjectBlocked.js')),
  },
  {
    path: ':project_secret_key/meetings',
    component: asyncComponent(() => import('../Meetings/Home.js')),
  },
  {
    path: ':project_secret_key/meetings/:meeting_id',
    component: asyncComponent(() => import('../Meetings/MeetingTabs.js')),
  },
  {
    path: ':project_secret_key/meetings/meeting-tabs',
    component: asyncComponent(() => import('../Meetings/MeetingTabs.js')),
  },
  {
    path: ':project_secret_key/settings/knowledge-base',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/KnowledgeBase.js')),
  },
  {
    path: ':project_secret_key/settings/kb-themes',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/KbThemes.js')),
  },
  {
    path: ':project_secret_key/settings/kb-themes/:theme_id',
    settingsRout: true,
    component: asyncComponent(() =>
      import(
        '../../components/Settings/KnowledgeBase/Themes/Editor/EditorHome'
      ),
    ),
  },
  {
    path: ':project_secret_key/settings/knowledgebase',
    redirect: 'settings/knowledge-base',
  },
  {
    path: ':project_secret_key/settings/email-templates',
    settingsRout: true,
    component: asyncComponent(() =>
      import('../Settings/CustomEmailTemplatesContainer.js'),
    ),
  },
  {
    path: ':project_secret_key/settings/email-template/edit/:template_id',
    settingsRout: true,
    component: asyncComponent(() =>
      import('../Settings/EmailTemplateEditorContainer.js'),
    ),
  },
  {
    path: ':project_secret_key/settings/email-template/create/:templateType(text-editor|visual-builder)',
    settingsRout: true,
    component: asyncComponent(() =>
      import('../Settings/EmailTemplateEditorContainer.js'),
    ),
  },
  {
    path: ':project_secret_key/settings/forms',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Forms.js')),
  },
  {
    path: ':project_secret_key/settings/api-key',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ApiKey.js')),
  },
  {
    path: ':project_secret_key/settings/access_token',
    redirect: 'settings/api-key',
  },
  {
    path: ':project_secret_key/apps',
    settingsRout: true,
    component: asyncComponent(() => import('../Apps/Apps.js')),
  },
  {
    path: ':project_secret_key/settings/apps',
    redirect: 'apps',
  },
  {
    path: ':project_secret_key/settings/subscription',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Subscription.js')),
  },
  {
    path: ':project_secret_key/settings/redeem-coupon',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/RedeemCoupon')),
  },
  {
    path: ':project_secret_key/settings/subscription_plans',
    redirect: 'settings/subscription',
  },
  {
    path: ':project_secret_key/settings/invoice',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Invoice.js')),
  },
  {
    path: ':project_secret_key/settings/email-sending-domain',
    settingsRout: true,
    component: asyncComponent(() =>
      import('../Settings/EmailSendingDomainContainer.js'),
    ),
  },
  {
    path: ':project_secret_key/settings/custom_senders',
    redirect: 'settings/email-sending-domain',
  },
  {
    path: ':project_secret_key/settings/shared-team-email-address',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/SharedTeamEmail.js')),
  },
  {
    path: ':project_secret_key/settings/inbound_emails',
    redirect: 'settings/shared-team-email-address',
  },
  {
    path: ':project_secret_key/old-forms',
    component: asyncComponent(() => import('../Forms/Home.js')),
  },
  {
    path: ':project_secret_key/messages/forms',
    component: asyncComponent(() => import('../Forms/NewForm.js')),
  },
  {
    path: ':project_secret_key/forms',
    redirect: 'messages/forms',
  },
  {
    path: ':project_secret_key/forms/choose-form-template',
    component: asyncComponent(() => import('../Forms/FormTemplateBeta.js')),
  },
  {
    path: ':project_secret_key/forms/:form_id',
    component: asyncComponent(() => import('../Forms/Editor.js')),
  },
  {
    path: ':project_secret_key/forms/:form_id/results',
    component: asyncComponent(() => import('../Forms/ReportHome')),
  },
  {
    path: ':project_secret_key/settings/events',
    component: asyncComponent(() => import('../Events/Home.js')),
  },
  {
    path: ':project_secret_key/events',
    redirect: 'settings/events',
  },
  {
    path: ':project_secret_key/settings/events/:event_id',
    component: asyncComponent(() => import('../Events/Home.js')),
  },
  {
    path: ':project_secret_key/events/:event_id',
    redirect: 'settings/events/:event_id',
  },
  {
    path: ':project_secret_key/deals',
    component: asyncComponent(() => import('../CRM/Home.js')),
  },
  {
    path: ':project_secret_key/deals/:pipeline_id',
    component: asyncComponent(() => import('../CRM/Home.js')),
  },
  {
    path: ':project_secret_key/deal/:deal_secret',
    component: asyncComponent(() => import('../CRM/DealView')),
  },
  {
    path: ':project_secret_key/settings/webhooks',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/WebHooks.js')),
  },
  {
    path: ':project_secret_key/settings/webhooks/:webhook_id',
    component: asyncComponent(() => import('../Settings/WebHooksEditor.js')),
  },
  {
    path: ':project_secret_key/account',
    component: asyncComponent(() => import('../AccountDetail/Account.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/profile',
    component: asyncComponent(() => import('../ProfileAndPreferences/Home.js')),
    isRouteRestricted: false,
  },
  {
    path: 'oauth2callback',
    component: asyncComponent(() => import('../ProfileAndPreferences/Home.js')),
  },
  {
    path: 'microsoft-callback',
    component: asyncComponent(() => import('../ProfileAndPreferences/Home.js')),
  },
  {
    path: ':project_secret_key/settings/notifications',
    component: asyncComponent(() =>
      import('../ProfileAndPreferences/NotificationContainer.js'),
    ),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/change-password',
    component: asyncComponent(() =>
      import('../AccountDetail/ChangePassword.js'),
    ),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/change-plan',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/ChangePlan.js')),
  },
  {
    path: ':project_secret_key/updates',
    component: asyncComponent(() =>
      import('../ProductUpdates/ProductUpdates.js'),
    ),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key',
    redirect: 'contacts',
  },
  {
    path: ':project_secret_key/getting-started',
    component: asyncComponent(() =>
      import('../GettingStarted/GettingStarted.js'),
    ),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/settings/bot',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/BotIdentity.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/reports/support/conversations',
    component: asyncComponent(() => import('../Reports/InboxReports.js')),
  },
  {
    path: ':project_secret_key/reports/support/responsiveness',
    component: asyncComponent(() =>
      import('../Reports/ProductivitySummary.js'),
    ),
  },
  {
    path: ':project_secret_key/reports/conversations',
    redirect: 'reports/support/conversations',
  },
  {
    path: ':project_secret_key/reports/conversations/responsiveness',
    redirect: 'reports/support/responsiveness',
  },
  {
    path: ':project_secret_key/reports/conversations/teams-and-teammates',
    redirect: 'reports/support/team-performance',
  },
  {
    path: ':project_secret_key/reports/articles/feedback',
    redirect: 'reports/support/article-feedback',
  },
  {
    path: ':project_secret_key/reports/support/emma',
    component: asyncComponent(() => import('../Reports/SupportBotOverview.js')),
  },
  {
    path: ':project_secret_key/reports/support/team-performance',
    component: asyncComponent(() => import('../Reports/TeamsAndTeammates.js')),
  },
  {
    path: ':project_secret_key/reports/support/teams/:teams_id',
    component: asyncComponent(() => import('../Reports/IndividualSummary.js')),
  },
  {
    path: ':project_secret_key/reports/support/teammates/:teammates_id',
    component: asyncComponent(() => import('../Reports/IndividualSummary.js')),
  },
  {
    path: ':project_secret_key/reports/support/article-feedback',
    component: asyncComponent(() => import('../Reports/ArticlesFeedback.js')),
  },
  {
    path: ':project_secret_key/reports/support/customer-satisfaction',
    component: asyncComponent(() =>
      import('../Reports/CustomerSatisfactionRating.js'),
    ),
  },
  {
    path: ':project_secret_key/reports/marketing/email-health',
    component: asyncComponent(() => import('../Reports/EmailsOverview.js')),
  },
  {
    path: ':project_secret_key/connect-channel',
    component: asyncComponent(() =>
      import('../../components/Chat/ProductOnboarding/ProductOnboarding.js'),
    ),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/connect-channel/:channel_type',
    component: asyncComponent(() =>
      import('../Chat/ProductOnboarding/SelectedChannel.js'),
    ),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/reports/conversations',
    component: asyncComponent(() => import('../Reports/InboxReports.js')),
  },
  {
    path: ':project_secret_key/settings/email',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/Email.js')),
  },
  {
    path: ':project_secret_key/settings/message-tags',
    settingsRout: true,
    component: asyncComponent(() => import('../Settings/EmailTags.js')),
  },
  {
    path: ':project_secret_key/workflow-templates',
    component: asyncComponent(() =>
      import('../Automation/Workflow/WorkflowTemplate'),
    ),
  },
  {
    path: ':project_secret_key/chatbot-templates',
    component: asyncComponent(() =>
      import('../Automation/Chatbot/ChatbotTemplate'),
    ),
  },
  {
    path: ':project_secret_key/survey-templates',
    component: asyncComponent(() =>
      import('../Automation/Survey/SurveyTemplatesHome'),
    ),
  },
  {
    path: ':project_secret_key/chat-templates',
    component: asyncComponent(() =>
      import('../Automation/Chat/ChatTemplatesHome'),
    ),
  },
  {
    path: ':project_secret_key/post-templates',
    component: asyncComponent(() =>
      import('../Automation/Post/PostTemplatesHome'),
    ),
  },
  {
    path: ':project_secret_key/workflow-templates/:workflow_id',
    component: asyncComponent(() =>
      import('../Automation/Workflow/WorkflowTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/assistants/bot-templates/:bot_id',
    component: asyncComponent(() =>
      import('../Automation/Chatbot/ChatbotTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/survey-templates/:survey_id',
    component: asyncComponent(() =>
      import('../Automation/Survey/SurveyTabs.js'),
    ),
  },
  {
    path: ':project_secret_key/form-template/:form_id',
    component: asyncComponent(() => import('../Automation/Form/FormTabs')),
  },
  {
    path: ':project_secret_key/chat-templates/:chat_id',
    component: asyncComponent(() => import('../Automation/Chat/ChatTabs')),
  },
  {
    path: ':project_secret_key/post-templates/:post_id',
    component: asyncComponent(() => import('../Automation/Post/PostTabs')),
  },
  {
    path: ':project_secret_key/workflow-template/choose-template',
    component: asyncComponent(() =>
      import('../Automation/Workflow/WorkflowTemplatesList.js'),
    ),
  },
  {
    path: ':project_secret_key/workflow/choose-template',
    component: asyncComponent(() =>
      import('../Automation/Workflow/WorkflowTemplatesList.js'),
    ),
  },
  {
    path: ':project_secret_key/chatbot/choose-template',
    component: asyncComponent(() =>
      import('../Messages/Bots/ChatBotTemplateList'),
    ),
  },
  {
    path: ':project_secret_key/survey/choose-template',
    component: asyncComponent(() =>
      import('../Messages/Surveys/SurveyTemplatesList'),
    ),
  },
  {
    path: ':project_secret_key/emails/:automation_email/reports',
    component: asyncComponent(() =>
      import('../Messages/Email/Broadcast/Reports.js'),
    ),
  },
  {
    path: ':project_secret_key/chat/choose-template',
    component: asyncComponent(() =>
      import('../Messages/Chats/ChatTemplatesList'),
    ),
  },
  {
    path: ':project_secret_key/post/choose-template',
    component: asyncComponent(() =>
      import('../Messages/Posts/PostTemplatesList'),
    ),
  },
  {
    path: ':project_secret_key/referral',
    component: asyncComponent(() => import('../Referral/home.js')),
    isRouteRestricted: false,
  },
  {
    path: ':project_secret_key/templates',
    component: asyncComponent(() => import('../Templates/Templates')),
  },
  {
    path: ':project_secret_key/template/:template_id',
    component: asyncComponent(() => import('../Templates/TemplateEditor')),
  },
  {
    path: ':project_secret_key/settings/pipelines-and-stages',
    component: asyncComponent(() => import('../Settings/PipelinesAndStages')),
  },
  {
    path: ':project_secret_key/settings/deal-properties',
    component: asyncComponent(() => import('../Settings/DealsProperties')),
  },
  {
    path: ':project_secret_key/settings/company-settings',
    component: asyncComponent(() => import('../Settings/CompanyProperties')),
  },
  {
    path: ':project_secret_key/settings/company-properties',
    redirect: 'settings/company-settings',
  },
  {
    path: ':project_secret_key/settings/general',
    component: asyncComponent(() => import('../Settings/General')),
  },
  {
    path: ':project_secret_key/settings/subscription-types',
    component: asyncComponent(() => import('../Settings/MailSubscription')),
  },
  {
    path: ':project_secret_key/slack_auth',
    component: asyncComponent(() => import('../../components/Apps/SlackAuth')),
  },
  {
    path: ':project_secret_key/form-template',
    component: asyncComponent(() => import('../Forms/FormTemplateHome')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, projectBlocked } = this.props;
    const referrerPaths = document.location.pathname.split('/');
    if (
      projectBlocked &&
      !AllowRoutesOnBlockedProjects.includes(referrerPaths[3]) &&
      !AllowRoutesOnBlockedProjects.includes(referrerPaths[4])
    ) {
      return (
        <Route
          path={`${url}/:project_secret_key`}
          component={asyncComponent(() => import('../Page/ProjectBlocked.js'))}
        />
      );
    } else {
      return (
        <Fragment>
          <Switch>
            {routes.map((singleRoute) => {
              let {
                path,
                exact,
                redirect,
                isRouteRestricted = true,
                settingsRout = false,
                ...otherProps
              } = singleRoute;
              if (isSettingsRestrictedTeam() && settingsRout) {
                return (
                  <Route
                    exact={exact !== false}
                    key={path}
                    path={`${url}/${path}`}
                    component={NotAuthorised}
                  />
                );
              } else if (redirect !== undefined) {
                return (
                  <Route
                    exact={exact !== false}
                    key={path}
                    path={`${url}/${path}`}
                    render={(props) => (
                      <Redirect
                        from={`${url}/${path}`}
                        to={`${url}/${
                          props.match.params.project_secret_key
                        }/${generatePath(redirect, props.match.params)}${
                          props.location.search
                        }`}
                      />
                    )}
                  />
                );
              } else if (fetchTeamRestrictedFeatures() && isRouteRestricted) {
                return (
                  <Route
                    exact={exact !== false}
                    key={path}
                    path={`${url}/${path}`}
                    component={NotAuthorised}
                  />
                );
              } else {
                return (
                  <Route
                    exact={exact !== false}
                    key={path}
                    path={`${url}/${path}`}
                    {...otherProps}
                  />
                );
              }
            })}
            <Route path="*" component={NotFound} />
          </Switch>
        </Fragment>
      );
    }
  }
}

export default AppRouter;
