import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import chatActions from '../../../redux/chat/actions';
import sharedActions from '../../../redux/sharedReducers/actions';
import { cloneDeep } from 'lodash';
import JungleSelect from 'react-jungle-select';
import SnippetPreview from '../../Chat/Messages/SnippetPreview';

const SnippetWidget = props => {
  let previousItem = null;
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(undefined),
    [firstItemSelected, setFirstItemSelected] = useState(false);

  const snippets = useSelector(({ Chat }) => Chat.snippets),
    snippetCategories = useSelector(({ Chat }) => Chat.snippetCategories),
    singleSnippetData = useSelector(({ Chat }) => Chat.singleSnippetData),
    mostRecentSnippets = useSelector(({ Chat }) => Chat.mostRecentSnippets),
    showNewConversation = useSelector(({ Chat }) => Chat.showNewConversation);

  const snippetPresentIn = 'teamInbox';

  const {
    activeConversationId,
    personId,
    tabActiveKey,
    pushContentIntoComposer,
    handleSnippetVisibleChange,
  } = props;

  useEffect(() => {
    props.omniSearchEventListener();

    return () => {
      props.removeOmniSearchListener();
    };
  }, []);

  const getCommandItem = item => {
    if (item.groupId === 'nogroup') {
      return (
        <div className="command-item">
          <div className="command-item-inner">
            <span className="command-content">
              <i className="fal fa-angle-left" />
              <span className="command-title">{item.title}</span>
            </span>
          </div>
        </div>
      );
    } else if (Object.keys(item).includes('snippet_count')) {
      return (
        <div className="command-item">
          <div className="command-item-inner">
            <span className="command-content category-item">
              <i className="fal fa-folder" />
              <span className="title">{item.name}</span>
              <span className="snippet-count">
                {`${item.snippet_count || 0} snippet${
                  (item.snippet_count || 0) > 1 ? 's' : ''
                }`}
              </span>
              <span className={'right-icon'}>
                <i className="fal fa-chevron-right" />
              </span>
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="command-item">
          <div className="command-item-inner">
            <span className="command-content">
              <i className="fal fa-bookmark" />
              <span className="command-title">{item.title}</span>
            </span>
          </div>
        </div>
      );
    }
  };

  const handleClick = snippet => {
    if (showNewConversation) {
      pushContentIntoComposer(snippet.message);
    } else {
      const payload = {
        data: snippet,
        pushContentIntoComposer,
        personId,
        snippetPresentIn,
        handleSnippetVisibleChange,
        cid: activeConversationId,
      };
      dispatch({
        type: chatActions.FETCH_SNIPPET_PARSED_CONTENT,
        payload,
        isNote: tabActiveKey === 'note',
      });
    }
    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  const handleSnippetSelect = item => {
    if (item.groupId === 'nogroup') {
      setSelectedCategory(undefined);
    } else if (Object.keys(item).includes('snippet_count')) {
      setSelectedCategory(item);
    } else {
      if (previousItem === item) {
        handleClick(item);
      } else {
        previousItem = item;
        dispatch({
          type: chatActions.FETCH_SINGLE_SNIPPET_DATA_INBOX,
          id: item.id,
        });
      }
    }
  };

  const hasEmptyList = list => {
    // This function is find filtered list length of react-jungle-select. Once react-jungle-select is updated, need to change this logic
    let listLength = 0;
    if (list && list.length) {
      for (let item of list) {
        if (
          item.props &&
          item.props.children &&
          item.props.children[1] &&
          item.props.children[1].props &&
          item.props.children[1].props.children.length
        ) {
          listLength += item.props.children[1].props.children.length;
        }
      }
    }
    return !listLength;
  };

  let snippetsGroups = [
    {
      id: 'nogroup',
      label: '',
      show_in_omni_search: true,
    },
    {
      id: 'recent',
      label: 'Recent snippets',
      show_in_omni_search: true,
    },
    {
      id: 'all',
      label: 'All',
      show_in_omni_search: true,
    },
  ];

  let snippetData = [...snippetCategories, ...snippets];
  snippetData.forEach(item => (item.groupId = 'all'));

  let recentSnippetData = cloneDeep(snippets).filter(snippet =>
    mostRecentSnippets.includes(snippet.id),
  );
  recentSnippetData.forEach(item => (item.groupId = 'recent'));

  let snippetsList = [];
  if (selectedCategory) {
    snippetsList = [
      ...[
        {
          groupId: 'nogroup',
          title: 'Back',
        },
      ],
      ...snippetData.filter(
        snippet => snippet.snippet_category_id === selectedCategory.id,
      ),
    ];
  } else {
    snippetsList = [...recentSnippetData, ...snippetData];
  }
  if (!singleSnippetData && !firstItemSelected && snippetsList.length !== 0) {
    handleSnippetSelect(snippetsList[0]);
    setFirstItemSelected(true);
  }
  return (
    <div className={'snippet-container'}>
      <JungleSelect
        items={snippetsList}
        groups={snippetsList.length ? snippetsGroups : []}
        renderGroup={(group, items) => props.getGroupItem(group, items)}
        renderItem={item => getCommandItem(item)}
        placeholder="Search snippets..."
        searchable={true}
        searchableAttributes={['title']}
        listWrapper={list => (
          <div>
            <div className="command-list snippet-list">
              {hasEmptyList(list) ? (
                <div className={'no-data'}>No snippet found</div>
              ) : (
                list
              )}
            </div>
          </div>
        )}
        onChange={value => handleSnippetSelect(value)}
        mode="list"
        selectFirstItem={true}
        autofocus={true}
        className="snippets-component"
      />
      {!!snippetsList.length && <SnippetPreview handleClick={handleClick} />}
    </div>
  );
};
export default SnippetWidget;
