import React, { Fragment } from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class WelcomeMesage extends BaseEditableComponent {
  isAddPort(lastElementIndex) {
    const { messages, buttons } = this.state.data;
    if (
      (!buttons || buttons.length === 0) &&
      messages &&
      messages.length - 1 === lastElementIndex
    ) {
      return true;
    }
    return false;
  }
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          editNote={this.edit.bind(this)}
          isNotAllowToDelete={true}
          isNotAllowToDuplicate={true}
        />
        <jtk-target />
        <div className={'header'}>
          <h3>
            <span role={'img'} aria-label={'welcome'}>
              👋
            </span>
            Welcome Message
          </h3>
        </div>
        <div className={'body'}>
          {data.messages &&
            data.messages.map((item, key) => (
              <Fragment key={item.id}>
                {this.isAddPort(key) ? (
                    <div
                      className={'message-text-component'}
                      data-port-id={item.id}
                    >
                      <jtk-source port-id={item.id} endpoint={true} />
                      <InputTextArea id={item.id} value={item.text} />
                    </div>
                ) : (
                  <div className={'message-text-component'} key={item.id}>
                    <InputTextArea id={item.id} value={item.text} />
                  </div>
                )}
              </Fragment>
            ))}
          <div className="buttons-list">
            {data.buttons &&
              data.buttons.map((item, key) => (
                <div
                  key={item.id}
                  className={'button-component'}
                  data-port-id={item.id}
                >
                  <span className={`primary-btn ${!item.text ? 'error' : ''}`}>
                    {item.text}
                  </span>
                  <jtk-source port-id={item.id} endpoint={true} />
                </div>
              ))}
            {data.enable_open_response && (
              <div
                className={'button-component'}
                data-port-id={data.open_response_id}
              >
                {' '}
                <i className={`primary-btn`}>Customer sends a message</i>
                <jtk-source port-id={data.open_response_id} endpoint={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
