import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import chatSaga from './chat/saga';
import contactSaga from './contacts/saga';
import liveViewSaga from './liveView/saga';
import broadcastSaga from './email/broadcast/saga';
import autoEmailSaga from './email/autoEmails/saga';
import emailReportSaga from './email/report/saga';
import workflowSaga from './automation/workflow/saga';
import campaignSaga from './email/campaign/saga';
import ruleSaga from './automation/rules/saga';
import knowledgeBaseSaga from './knowledgeBase/saga';
import settingsSaga from './settings/saga';
import meetingSaga from './meetings/sagas';
import notificationSaga from './notification/saga';
import eventsSaga from './events/saga';
import accountDetailSaga from './accountDetail/saga';
import filterSaga from './filters/saga';
import emailSaga from './email/saga';
import inboxReportSaga from './reports/inboxReports/saga';
import sharedSaga from './sharedReducers/saga';
import workflowTemplateSaga from './automation/workflowTemplate/saga';
import chatbotTemplateSaga from './automation/chatbotTemplate/saga';
import formsSaga from './forms/saga';
import displayRules from './displayRules/saga';
import templates from './templates/saga';
import ProactiveMessageSaga from './proactiveMessage/saga';
import MessageSaga from './messages/saga';
import ChatOngoingSaga from './messages/chats/onGoing/saga';
import AssistantSaga from './assistants/saga';
import ChatOneOffSaga from './messages/chats/oneOff/saga';
import PostSaga from './post/saga';
import chatOngoingPost from './post/onGoing/saga';
import chatOneOffPost from './post/oneOff/saga';
import SurveySaga from './survey/saga';
import CRMSaga from './CRM/saga';
import CRMDealSaga from './CRM/Deal/saga';
import Companies from './companies/saga';
import Company from './companies/company/saga';
import InboxAutomation from './inboxAutomation/saga';
import Apps from './apps/saga';
import AppPlatform from './appPlatform/saga';
import SurveyOngoingSaga from './survey/onGoing/saga';
import PageBuilderTemplate from './automation/formTemplate/saga';
// import SurveyOneOffSaga from './survey/oneOff/saga';
import SurveyTemplateSaga from './automation/surveyTemplate/saga';
import ToursSaga from './tours/saga';
import MessageTemplateSaga from './automation/messageTemplate/saga';
// import PostTemplateSaga from './automation/postTemplate/saga';
import PageBuilderSaga from './pageBuilder/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    contactSaga(),
    liveViewSaga(),
    broadcastSaga(),
    autoEmailSaga(),
    emailReportSaga(),
    campaignSaga(),
    ruleSaga(),
    knowledgeBaseSaga(),
    settingsSaga(),
    workflowSaga(),
    meetingSaga(),
    accountDetailSaga(),
    notificationSaga(),
    eventsSaga(),
    filterSaga(),
    emailSaga(),
    inboxReportSaga(),
    sharedSaga(),
    workflowTemplateSaga(),
    chatbotTemplateSaga(),
    formsSaga(),
    displayRules(),
    templates(),
    ProactiveMessageSaga(),
    MessageSaga(),
    ChatOngoingSaga(),
    AssistantSaga(),
    ChatOneOffSaga(),
    PostSaga(),
    chatOngoingPost(),
    chatOneOffPost(),
    SurveySaga(),
    CRMSaga(),
    CRMDealSaga(),
    Companies(),
    Company(),
    InboxAutomation(),
    Apps(),
    AppPlatform(),
    SurveyOngoingSaga(),
    // SurveyOneOffSaga(),
    SurveyTemplateSaga(),
    ToursSaga(),
    PageBuilderSaga(),
    MessageTemplateSaga(),
    PageBuilderTemplate(),
  ]);
}
