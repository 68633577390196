import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  unAuthorisedRedirection,
  postPromise,
  patchPromise,
  canWeAccess,
  updateURLWithoutRerender,
  trackEvent,
} from '../../helpers/utility';
import { message } from 'antd';
import crmDealActions from './Deal/actions';
import { store } from '../store';
import { uniq, find } from 'lodash';
import { userRestriction } from '../../components/TopNavBar/constants';
import { filterCurrentUserDeals } from '../../components/CRM/helper';

export function* getPipeLines(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines`;
    const data = yield call(() => getPromise(url));
    const { pipelines } = data.data;
    let currentPipeline;
    if (params.pipelineId) {
      currentPipeline = find(pipelines, { id: params.pipelineId });
    }
    if (!currentPipeline) {
      currentPipeline = find(pipelines, { is_default: true });
    }

    yield put({
      type: actions.GET_PIPELINES_SUCCESS,
      payload: pipelines,
      currentPipeline,
      isRestrictRedirect: params.isRestrictRedirect,
    });
    if (!params.isRestrictRedirect) {
      updateURLWithoutRerender(
        null,
        null,
        `/projects/${fetchProjectSecretKey()}/deals/${currentPipeline.id}`,
      );
      yield put({
        type: actions.GET_STAGES,
        pipelineId: currentPipeline.id,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch pipelines failed');
    }
    yield put({ type: actions.GET_PIPELINES_FAILURE });
  }
}

export function* getStages(params) {
  let { currentPipeline, userID } = store.getState().CRM,
    queryParams = '?with_deals=true',
    pipelineID = currentPipeline && currentPipeline.id;
  if (params.pipelineId) {
    pipelineID = params.pipelineId;
  }
  if (params.userID) {
    userID = params.userID;
  }
  if (userID) {
    queryParams += `&user_id=${userID}`;
  }
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${pipelineID + queryParams}`;
    let data = yield call(() => getPromise(url));
    let pipeline = data.data.pipeline;
    // check the assinged_only permission
    if (canWeAccess(userRestriction.deals).assigned_only) {
      pipeline = filterCurrentUserDeals(pipeline);
    }
    yield put({
      type: actions.GET_STAGES_SUCCESS,
      payload: pipeline,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch pipelines failed');
    }
    yield put({ type: actions.GET_STAGES_FAILURE });
  }
}

export function* createDeal(params) {
  try {
    const { currentPipeline } = store.getState().CRM;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.pipeline_id
    }/stages/${params.stage_id}/deals`;
    let data = yield call(() => postPromise(url, params.payload));
    trackEvent('Created deal');
    message.success(data.message);
    yield put({ type: actions.CREATE_DEAL_SUCCESS });
    if (currentPipeline) {
      yield put({ type: actions.GET_STAGES, pipelineId: currentPipeline.id });
    } else {
      yield put({ type: actions.GET_PIPELINES });
    }
    yield put({ type: actions.OPEN_CREATE_DEAL_DRAWER, payload: false });

    if (params.fetchContactOrCompanyDeals) {
      params.fetchContactOrCompanyDeals();
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Create deal failed');
    }
    yield put({ type: actions.CREATE_DEAL_FAILURE });
  }
}

export function* updateDeal(params) {
  try {
    const { currentPipeline } = store.getState().CRM;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.pipeline_id
    }/stages/${params.stage_id}/deals/${params.id}`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    if (params.isFromDealEdit) {
      yield put({
        type: crmDealActions.GET_DEAL_DETAILS_SUCCESS,
        payload: data.data.deal,
      });
      yield put({
        type: actions.UPDATE_DEAL_DETAILS,
        payload: {data: data.data},
      });
      yield put({
        type: crmDealActions.SET_DEAL_DRAWER_STATUS,
        payload: false,
      });
      yield put({
        type: crmDealActions.SET_DEAL_COMPANY_DRAWER_STATUS,
        payload: false,
      });
    } else {
      yield put({ type: actions.UPDATE_DEAL_SUCCESS });
      if (currentPipeline) {
        yield put({ type: actions.GET_STAGES, pipelineId: currentPipeline.id });
      } else {
        yield put({ type: actions.GET_PIPELINES });
      }
      yield put({ type: actions.OPEN_CREATE_DEAL_DRAWER, payload: false });
    }
    yield put({ type: actions.DEAL_BUTTON_LOADER_STATUS, payload: false });
    if (params.fetchContactOrCompanyDeals) {
      params.fetchContactOrCompanyDeals();
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update deal failed');
    }
    yield put({ type: actions.UPDATE_DEAL_FAILURE });
  }
}

export function* updateDealOneStageToAnother(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.payload.pipeline_id
    }/stages/${params.payload.stage_id}/deals/${params.payload.id}`;
    let data = yield call(() => patchPromise(url, params.payload.data));
    yield put({ type: actions.UPDATE_DEAL_DETAILS, payload: {data: data.data,isFromDragEnd:true} });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Move deal failed ');
    }
  }
}

export function* getStagesList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${params.pipelineID}/stages`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_STAGES_LIST_SUCCESS,
      payload: data.data.stages,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch stages failed');
    }
    yield put({
      type: actions.GET_STAGES_LIST_FAILURE,
    });
  }
}

export function* getPipelineList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_PIPELINE_LIST_SUCCESS,
      payload: data.data.pipelines,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch pipelines failed');
    }
    yield put({
      type: actions.GET_PIPELINE_LIST_FAILURE,
    });
  }
}

export function* getDealContactsList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/contacts?search_text=${encodeURIComponent(
      params.payload.searchText,
    )}&contact_ids=${params.payload.Id}&recent_contacts=${
      params.payload.activeContactsFetch
    }`;

    let data = yield call(() => getPromise(url));
    let contact = [...data.data.contacts[0], ...data.data.contacts[1]];
    let uniqueArray = uniq(contact, 'id');

    yield put({
      type: actions.GET_CRM_CONTACTS_SUCCESS,
      payload: uniqueArray,
    });
  } catch (error) {
    yield put({
      type: actions.GET_CRM_CONTACTS_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Deal contacts fetch failed');
    }
  }
}

export function* getCompanyList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies?search_text=${
      params.searchText
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DEAL_COMPANY_LIST_SUCCESS,
      payload: data.data.companies,
    });
  } catch (error) {
    yield put({
      type: actions.GET_DEAL_COMPANY_LIST_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Companies fetch failed');
    }
  }
}

export function* updateDefaultPipeline(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/pipelines/${
      params.payload.pipeline_id
    }/default_pipeline`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.UPDATE_DEFAULT_PIPELINE_SUCCESS,
      payload: params.payload.pipeline_id,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Update default pipeline failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PIPELINES, getPipeLines),
    takeEvery(actions.GET_STAGES, getStages),
    takeEvery(actions.CREATE_DEAL, createDeal),
    takeEvery(actions.UPDATE_DEAL, updateDeal),
    takeEvery(
      actions.UPDATE_CHANGE_DEAL_STAGE_TO_ANOTHER,
      updateDealOneStageToAnother,
    ),
    takeEvery(actions.GET_STAGES_LIST, getStagesList),
    takeEvery(actions.GET_PIPELINE_LIST, getPipelineList),
    takeEvery(actions.GET_CRM_CONTACT, getDealContactsList),
    takeEvery(actions.GET_DEAL_COMPANY_LIST, getCompanyList),
    takeEvery(actions.UPDATE_DEFAULT_PIPELINE, updateDefaultPipeline),
  ]);
}
