import actions from './actions';
import _, { countBy, find, map, cloneDeep } from 'lodash';
import { templateContent, visualBuilderTemplateContent } from '../constants';
import { defaultTimeZone } from '../../components/constants';
import { getSortedSnippet } from '../../helpers/utility';

let initState = {
  projectName: '',
  sideBarActiveKey: [],
  loader: false,
  pageLoader: false,
  brandingInfo: [],
  userData: [],
  userList: [],
  currentUser: null,
  teamMatesList: [],
  teamData: [],
  roleData: [],
  roleList: [],
  currentRolePermissionData: [],
  drawerStatus: false,
  roleButtonLoader: false,
  teamEmoji: '',
  activeUserList: [],
  contactTagsData: [],
  contactSegmentsData: [],
  contactPropertiesData: [],
  domains: [],
  exportsData: [],
  importsData: [],
  qualificationData: [],
  qualificationList: [],
  qualificationBotData: [],
  tagSearch: '',
  settingsUserTableColumn: [],
  contactPropertySearch: '',
  countryList: [],
  settingsTeamSearch: '',
  settingsUserSearch: '',
  settingsRolesSearch: '',
  chatTagSearch: '',
  snippetSearch: '',
  chatTagsData: [],
  snippetData: [],
  singleSnippetData: {},
  singleSnippetDataLoader: false,
  snippetCategories: [],
  snippetDataOrder: 'default',
  replyTime: '',
  appearanceData: {
    iconColor: { hex: '' },
    backgroundColor: { hex: '' },
    fontColor: { hex: '' },
  },
  colorValues: {},
  availabilityData: {},
  languageData: {},
  languages: {},
  visibilityData: {},
  messengerPath: [],
  gdprData: {},
  visibilityDropdown: [],
  blockedIPData: [],
  blockedUserData: [],
  advancedData: [],
  connectGoogleCalendarLoader: false,
  calendarList: {},
  calendarAccounts: [],
  calendarPageLoader: false,
  meetingLinks: [],
  usersProfile: {},
  qualificationText: '',
  disconnectGoogleAccountLoader: [],
  unavailabilitiesLoader: false,
  unavailabilities: [],
  templateList: [],
  templateLoader: false,
  defaultTemplate: null,
  visualBuilderTemplateList: [],
  visualBuilderTemplateLoader: false,
  templateName: '',
  templateContent,
  visualBuilderTemplateContent,
  visualBuilderTemplateHtmlContent: '',
  visualBuilderTemplateName: '',
  currentTemplateTabKey: '1',
  deleteTemplateLoader: false,
  templateColor: '#37a1f6',
  templateLanguage: '',
  kbSettings: {},
  sslStatus: '',
  kbLanguages: {},
  kbColorsList: {},
  updateKBLoader: false,
  customDomainErrorMessage: '',
  isOpenCardDrawer: false,
  isTableLoader: false,
  invoiceList: [],
  apiKeyDetail: null,
  paymentCardList: null,
  currentPlanDetail: null,
  connectDomainCurrentTabKey: 0,
  customDomainVerifyLoader: false,
  domainVerified: false,
  outBoundDrawerVisible: false,
  domainVerifyDrawerVisible: false,
  sharedTeamEmailDetail: null,
  isAddEmailDrawer: false,
  emailSendingDomainList: {},
  emailSendingDetails: {},
  sharedEmailData: null,
  isEmailCreateLoading: false,
  isFetchingDomainList: false,
  isVerifyingDomain: false,
  billingDetail: null,
  payDueModalVisible: false,
  workSpaceTabKey: '1',
  teamsRolesTabKey: '1',
  rolesDrawerTabKey: '1',
  deleteWarningMessage: '',
  planDetail: null,
  customFields: [],
  conversationFields: [],
  formsData: {},
  botIdentityData: {
    backgroundColor: '#888',
    foregroundColor: '#fff',
    iconId: 'chat_bot_icon_1',
    botName: 'Bot',
    botIconUrl: '',
    autoClose: false,
    closeInterval: '',
    closeIntervalUnit: '',
  },
  isDrawerOpen: false,
  isOwnerDrawerOpen: false,
  currentContactTagData: { id: undefined },
  currentActionContactTagData: {},
  currentTriggerContactTagData: { id: '', tags: '' },
  isDeletingTeamProcess: false,
  groups: [],
  isShowGroupDrawer: false,
  isShowPropertyDrawer: false,
  syncProgressStatus: false,
  emailSunsetPolicy: false,
  contactTagLoader: false,
  triggerContactTagLoader: false,
  actionContactTagLoader: false,
  satisfactionRatingSettingsLoader: false,
  settingsFormTabKey: '1',
  referralLoader: true,
  referralMail: '',
  settingsFormLoading: false,
  projectTimeZone: undefined,
  projectBrandingEnabled: false,
  greetingText: '',
  pipelineList: [],
  stagesList: [],
  currentPipeline: null,
  stagesLoader: false,
  pipelineDrawerStatus: false,
  stageDrawerStatus: false,
  dealProperties: [],
  dealPropertiesSearchValue: '',
  companyTrackingStatus: false,
  projectSeatDetail: null,
  supportBotFeedback: {
    has_answered: {
      message_content:
        'Did that answer help, or are you looking for something else?',
      buttons: {
        helped: {
          button_text: 'That Helped',
        },
        show_me_more: {
          button_text: 'Show me more',
        },
        ask_another_question: {
          button_text: 'Ask another question',
        },
        talk_to_person: {
          button_text: 'Talk to a person',
        },
      },
    },
    has_not_answered: {
      message_content:
        'Did that answer help, or are you looking for something else?',
      buttons: {
        show_me_more: {
          button_text: 'Show me more',
        },
        ask_another_question: {
          button_text: 'Ask another question',
        },
        talk_to_person: {
          button_text: 'Talk to a person',
        },
      },
    },
  },
  aiBotDetails: {},
  webhooksTableData: [],
  webhooksTemplateData: null,
  initialWebhookData: {},
  selectedWebhookData: {},
  isSupportBotEnabled: true,
  isAIBotEnabled: true,
  isEmmaAllowedInEmail: false,
  mailSubscriptionData: [],
  setMailSubscription: {},
  mailSubscriptionDrawerVisible: false,
  defaultContactSubscriptionTypes: false,
  sunsetPolicyLoader: false,
  isShowConversationPropertiesDrawer: false,
  conversationPropertiesData: [],
  conversationPropertyDrawerData: '',
  conversationPropertySearch: '',
  conversationPropertiesArray: '',
  isConversationPropertyEdit: false,
  conversationPropertyLists: [],
  conversationPropertyListCount: 0,
  isConversationPropertyCreate: false,
  sortedConversationProperties: [],
  conversationPropertiesLoader: false,
  snippetFolderDrawerVisibility: false,
  snippetFolderDrawerLoader: false,
  snippetDrawerDataLoader: false,
  conversationTagLoader: false,
  currentConversationTagData: {},
  searchSnippetListLoader: false,
  primaryCalendarAccount: null,
  selectedCalendarAccount: undefined,
  fetchCalendarAccountCount: 0,
  hubspotPropertiesLoader: false,
  hubspotProperties: [],
  mappingSaveLoader: false,
  hubspotMappedProperties: {},
  syncHubspotHistoryDataLoader: false,
  autoSyncContact: false,
  messengerAppPreferencesLoader: false,
  messengerAppPreferences: {
    site_visitor_apps: [],
    contact_apps: [],
  },
  oldMessengerAppPreferences: {
    site_visitor_apps: [],
    contact_apps: [],
  },
  messengerAppDisplayConditionModalData: {},
  messengerAppDisplayConditionModalVisibility: false,
  spamFiltersData: null,
  spamFiltersDataLoader: false,
  updateSpamFiltersLoader: false,
  teammateFields: [],
  updateSupportBotFeedbackLoader: false,
  botIdentityDataLoader: false,
  liveBots: [],
  supportBotIntroMessages: {},
  isSupportBotIntroMessagesEnabled: false,
  supportBotIntroMessagesLoader: false,
  kbThemesList: [],
  kbThemeActiveId: null,
  kbThemePreviewDomain: '',
  kbThemesListLoader: false,
  kbThemeDuplicateDrawer: false,
  setKbThemeDuplicateId: null,
  setKbDuplicateName: '',
  kbThemeEditorLoader: false,
  kbThemeEditorSpinner: false,
  kbThemeEditorDetails: {},
  kbThemeEditorContent: '',
  kbThemeEditorInitialContent: '',
  kbThemeEditorFileName: '',
  kbThemeAvailableLiquids: [],
  kbThemeInitialAvailableLiquids: [],
  invalidatePrimeCoupons: [],
  invalidTypePrimeCoupons: [],
  slackNotificationsLoader: false,
  slackNotifications: [],
  slackChannels: [],
  slackEvents: [],
  isAddEditSlackNotificationEnable: false,
  notificationDetails: null,
  slackChannelsAndEventsLoader: false,
  mobileAppDrawerVisible: false,
  isFetchingPushNotificationsList: false,
  pushNotificationsList: {},
  pushNotificationEditDetails: {},
  pushNotificationImageUrl: '',
  certificateDetails: {},
  authenticationType: '',
  appType: undefined,
  askForEmailBeforeStartLoader: false,
  preventConversationCloseLoader: false,
  identity_verification_value: {},
  enforceIdentityToken: false,
  identityToken: '',
  userHashValue: '',
  securityLoader: false,
  enableButtonLoader: false,
  emmaTriggerOption: 'once',
  selectedLanguages: [],
};

export default function settingsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_SIDEBAR_ACTIVE:
      return {
        ...state,
        sideBarActiveKey: action.key,
      };
    case actions.SET_LOADER:
      return {
        ...state,
        loader: action.loader,
      };
    case actions.SET_PAGE_LOADER:
      return {
        ...state,
        pageLoader: action.pageLoader,
      };
    case actions.FETCH_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        projectName: action.payload.project.project_name,
        deleteWarningMessage: action.payload.delete_alert_message,
        partnerCode: action.payload.project.partner_code,
        projectTimeZone: action.payload.project.project_timezone
          ? action.payload.project.project_timezone
          : defaultTimeZone,
        projectBrandingEnabled: action.payload.project.branding_enabled,
      };
    case actions.FETCH_BRANDING_INFO_SUCCESS:
      return {
        ...state,
        brandingInfo: action.brandingInfo,
      };
    case actions.SET_TEAM_NAME:
      return {
        ...state,
        teamEmoji: action.teamEmoji ? action.teamEmoji : state.teamEmoji,
      };
    case actions.SET_TEAM_SEARCH:
      return {
        ...state,
        settingsTeamSearch: action.searchText,
      };
    case actions.SET_ROLE_SEARCH:
      return {
        ...state,
        settingsRolesSearch: action.searchText,
      };
    case actions.UPDATE_TEAM_MEMBERS:
      return {
        ...state,
        teamMatesList: action.data,
      };
    // TODO: after API integration
    case actions.CLEAR_MODAL:
      return {
        ...state,
      };
    case actions.USER_NAME_CHANGE_SUCCESS:
      let userNameChange = [...state.userData];
      userNameChange[action.index].userName = action.name;
      return {
        ...state,
        userData: userNameChange,
      };
    case actions.FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teamData: action.teamData,
      };
    case actions.FETCH_USERS_LIST_SUCCESS:
      return {
        ...state,
        userList: action.userList,
      };
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case actions.FETCH_TEAMMATES_LIST_SUCCESS:
      return {
        ...state,
        teamMatesList: action.teamMatesList.map((data) => data.id),
        teamEmoji: action.teamEmoji,
      };
    case actions.SET_CURRENT_ROLE:
      return {
        ...state,
        currentRolePermissionData: action.payload,
      };
    case actions.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roleData: action.payload,
      };
    case actions.FETCH_ROLE_LIST_SUCCESS:
      return {
        ...state,
        roleList: action.payload,
      };
    case actions.SET_DRAWER_STATUS:
      return {
        ...state,
        drawerStatus: action.payload,
      };
    case actions.CREATE_OR_UPDATE_ROLE:
      return {
        ...state,
        roleButtonLoader: true,
      };
    case actions.CREATE_OR_UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roleButtonLoader: false,
        drawerStatus: false,
      };
    case actions.CREATE_OR_UPDATE_ROLE_FAILURE:
      return {
        ...state,
        roleButtonLoader: false,
        drawerStatus: true,
      };
    case actions.FETCH_CONTACT_TAGS_SUCCESS:
      return {
        ...state,
        contactTagsData: action.contactTagsData,
      };
    case actions.FETCH_CURRENT_ACTION_CONTACT_TAGS:
      return {
        ...state,
        currentActionContactTagData: action.payload,
        currentTriggerContactTagData: state.currentTriggerContactTagData,
        actionContactTagLoader: false,
      };
    case actions.FETCH_CURRENT_TRIGGER_CONTACT_TAGS:
      return {
        ...state,
        currentTriggerContactTagData: action.payload,
        currentActionContactTagData: state.currentActionContactTagData,
        triggerContactTagLoader: false,
      };
    case actions.FETCH_CURRENT_CONTACT_TAGS:
      return {
        ...state,
        currentContactTagData: action.payload,
        contactTagLoader: false,
      };
    case actions.APPEND_CONTACT_TAGS_SUCCESS:
      return {
        ...state,
        contactTagsData: state.contactTagsData.concat(action.contactTagsData),
      };
    case actions.SEARCH_TAGS:
      return {
        ...state,
        tagSearch: action.tagSearch,
      };
    case actions.FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.userData,
      };
    case actions.FETCH_PROJECT_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        projectSeatDetail: action.payload,
      };
    case actions.FETCH_ACTIVE_USER_SUCCESS:
      return {
        ...state,
        activeUserList: action.data,
      };
    case actions.FETCH_CONTACT_PROPERTIES_SUCCESS:
      const groups = [];
      for (let group of action.contactPropertiesData) {
        groups.push(group.category_name);
      }
      return {
        ...state,
        contactPropertiesData: action.contactPropertiesData,
        groups,
      };
    case actions.FETCH_CONTACT_SEGMENTS_SUCCESS:
      return {
        ...state,
        contactSegmentsData: action.contactSegmentsData,
      };
    case actions.APPEND_CONTACT_SEGMENT_SUCCESS:
      return {
        ...state,
        contactSegmentsData: state.contactSegmentsData.concat(
          action.contactSegmentsData,
        ),
      };
    case actions.FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        domains: action.domains,
      };
    case actions.UPDATE_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: state.domains.concat(action.domains),
      };
    case actions.DELETE_DOMAIN_SUCCESS:
      let domains;
      if (action.id) {
        domains = state.domains.filter((data) => data.id !== action.id);
      } else {
        domains = state.domains.filter((data, index) => index !== action.index);
      }
      return {
        ...state,
        domains,
      };
    case actions.FETCH_EXPORTS_LIST_SUCCESS:
      return {
        ...state,
        exportsData: action.payload.exports,
      };
    case actions.APPEND_EXPORTS_SUCCESS:
      return {
        ...state,
        exportsData: state.exportsData.concat(action.exportsData.exports),
      };
    case actions.FETCH_IMPORTS_LIST_SUCCESS:
      return {
        ...state,
        importsData: action.payload.imports,
      };
    case actions.APPEND_IMPORTS_SUCCESS:
      return {
        ...state,
        importsData: state.importsData.concat(action.importsData.imports),
      };
    case actions.FETCH_QUALIFICATION_DATA_LIST_SUCCESS:
      return {
        ...state,
        qualificationData: action.payload.setting.qualification_settings,
        qualificationText: action.payload.setting.qualification_text,
      };
    case actions.FETCH_QUALIFICATION_DATA_MODAL_LIST_SUCCESS:
      return {
        ...state,
        qualificationList: action.qualificationList,
      };
    case actions.SETTINGS_PROPERTIES_SEARCH:
      return {
        ...state,
        contactPropertySearch: action.searchValue,
      };
    case actions.FETCH_CHAT_TAGS_SUCCESS:
      return {
        ...state,
        chatTagsData: action.chatTagsData,
        conversationTagLoader: action.fromChatBots
          ? false
          : state.conversationTagLoader,
        currentConversationTagData: action.currentConversationTagData
          ? action.currentConversationTagData
          : {},
      };
    case actions.FETCH_SINGLE_SNIPPET_DATA:
      return {
        ...state,
        singleSnippetDataLoader: true,
      };
    case actions.FETCH_SINGLE_SNIPPET_DATA_SUCCESS:
      return {
        ...state,
        singleSnippetData: action.snippetData,
        singleSnippetDataLoader: false,
      };
    case actions.FETCH_SINGLE_SNIPPET_DATA_FAILURE:
      return {
        ...state,
        singleSnippetData: {},
        singleSnippetDataLoader: false,
      };
    case actions.UPDATE_SNIPPET_SORT_ORDER: {
      const snippetData = getSortedSnippet(action.order_by, state.snippetData);
      return {
        ...state,
        snippetDataOrder: action.order_by,
        snippetData: snippetData,
      };
    }
    case actions.FETCH_SNIPPET_DATA_SUCCESS: {
      const snippetData = getSortedSnippet(
          state.snippetDataOrder,
          action.snippetData,
        ),
        categoriesSnippetCounts = countBy(
          snippetData,
          (snippet) => snippet.snippet_category_id,
        ),
        snippetCategories = action.snippetCategories.map((category) => ({
          ...category,
          snippet_count: categoriesSnippetCounts[category.id] || 0,
        }));

      return {
        ...state,
        snippetData,
        snippetCategories,
        searchSnippetListLoader: false,
      };
    }
    case actions.FETCH_SNIPPET_DATA_FAILURE:
      return {
        ...state,
        searchSnippetListLoader: false,
      };
    case actions.APPEND_CHAT_TAGS_SUCCESS:
      return {
        ...state,
        chatTagsData: state.chatTagsData.concat(action.chatTagsData),
      };
    case actions.APPEND_SNIPPET_DATA_SUCCESS:
      return {
        ...state,
        snippetData: state.snippetData.concat(action.snippetData),
      };
    case actions.SET_CHAT_TAGS_SEARCH:
      return {
        ...state,
        chatTagSearch: action.chatTagSearch,
      };
    case actions.SET_USER_SEARCH:
      return {
        ...state,
        settingsUserSearch: action.settingsUserSearch,
      };
    case actions.SET_SNIPPET_DATA_SEARCH:
      return {
        ...state,
        snippetSearch: action.snippetSearch,
      };
    case actions.SETTINGS_REPLY_TIME:
      return {
        ...state,
        replyTime: action.replyTime,
      };
    case actions.FETCH_APPEARANCE_DATA_SUCCESS:
      return {
        ...state,
        appearanceData: action.appearanceData,
      };
    case actions.UPDATE_APPEARANCE_VALUES_SUCCESS:
      if (action.payload.buttonType && action.payload.buttonType === 'icon') {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            icon_background_value: action.payload.colorId,
            iconColor: action.payload.color,
            icon_custom_color: null,
          },
        };
      } else if (
        action.payload.buttonType &&
        action.payload.buttonType === 'background'
      ) {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            background_value: action.payload.colorId,
            backgroundColor: action.payload.color,
            custom_value: null,
          },
        };
      } else if (
        action.payload.buttonType &&
        action.payload.buttonType === 'font'
      ) {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            font_color_value: action.payload.colorId,
            fontColor: action.payload.color,
            font_custom_color: null,
          },
        };
      } else if (action.payload.iconColor) {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            icon_custom_color: action.payload.iconColor.hex,
          },
        };
      } else if (action.payload.fontColor) {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            font_custom_color: action.payload.fontColor.hex,
          },
        };
      } else if (action.payload.backgroundColor) {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            custom_value: action.payload.backgroundColor.hex,
          },
        };
      } else {
        return {
          ...state,
          appearanceData: {
            ...state.appearanceData,
            ...action.payload,
          },
        };
      }
    case actions.FETCH_COLOR_VALUES_SUCCESS:
      return {
        ...state,
        colorValues: action.colorValues,
      };
    case actions.FETCH_LANGUAGE_DATA_SUCCESS:
      return {
        ...state,
        languageData: action.languageData,
      };
    case actions.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.languages,
      };
    case actions.SET_SELECTED_LANGUAGE:
      return {
        ...state,
        ...state.languageData.added_languages.push(action.payload),
      };
    case actions.FETCH_VISIBILITY_DATA_SUCCESS:
      return {
        ...state,
        visibilityData: action.visibilityData,
        messengerPath: action.messengerPath,
      };
    case actions.FETCH_VISIBILITY_DROPDOWN_SUCCESS:
      return {
        ...state,
        visibilityDropdown: action.visibilityDropdown,
      };
    case actions.FETCH_ADVANCED_TAB_SUCCESS:
      return {
        ...state,
        advancedData: {
          ...action.payload,
        },
        satisfactionRatingContent: action.payload.satisfaction_rating_content,
      };
    case actions.AVAILABLE_CALENDAR_INTEGRATIONS:
      return {
        ...state,
        calendarPageLoader: true,
      };
    case actions.AVAILABLE_CALENDAR_INTEGRATIONS_SUCCESS:
      const { email_accounts, primary_email_account_id } = action.payload;
      return {
        ...state,
        calendarAccounts: email_accounts,
        primaryCalendarAccount: primary_email_account_id,
        calendarPageLoader: !!primary_email_account_id,
      };
    case actions.AVAILABLE_CALENDAR_INTEGRATIONS_FAILURE:
      return {
        ...state,
        calendarPageLoader: false,
      };
    case actions.FETCH_CALENDAR_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        selectedCalendarAccount: action.accountId,
        calendarList: action.payload.email_account,
        calendarPageLoader: false,
        fetchCalendarAccountCount: ++state.fetchCalendarAccountCount,
      };
    case actions.UPDATE_AVAILABLE_CALENDARS_SUCCESS:
      return {
        ...state,
        calendarList: {
          ...state.calendarList,
          selected_calendars: action.payload.selected_calendars,
        },
      };
    case actions.UPDATE_PRIMARY_CALENDAR_ACCOUNT_SUCCESS:
      return {
        ...state,
        primaryCalendarAccount: action.payload,
      };
    case actions.FETCH_MEETINGS_PROFILE_DETAILS_SUCCESS:
      const { meeting_links, users_profile } = action.payload;

      return {
        ...state,
        meetingLinks: meeting_links,
        usersProfile: users_profile,
      };
    case actions.FETCH_AVAILABILITY_DATA_SUCCESS:
      return {
        ...state,
        availabilityData: action.availabilityData,
      };
    case actions.UPDATE_AVAILABILITY_DATA_SUCCESS:
      return {
        ...state,
        availabilityData: {
          ...state.availabilityData,
          ...action.availabilityData.project,
        },
      };
    case actions.FETCH_QUALIFICATION_BOT_DATA_SUCCESS:
      return {
        ...state,
        qualificationBotData: action.payload.data.setting,
      };
    case actions.FETCH_BLOCKED_IP_SUCCESS:
      return {
        ...state,
        blockedIPData: action.payload,
      };
    case actions.FETCH_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        blockedUserData: action.payload,
      };
    case actions.DISCONNECT_CALENDAR_ACCOUNT:
      return {
        ...state,
        disconnectGoogleAccountLoader: [
          ...state.disconnectGoogleAccountLoader,
          action.payload,
        ],
      };
    case actions.DISCONNECT_CALENDAR_ACCOUNT_SUCCESS:
      return {
        ...state,
        disconnectGoogleAccountLoader:
          state.disconnectGoogleAccountLoader.filter(
            (accountId) => accountId !== action.payload,
          ),
      };
    case actions.DISCONNECT_CALENDAR_ACCOUNT_FAILURE:
      return {
        ...state,
        disconnectGoogleAccountLoader:
          state.disconnectGoogleAccountLoader.filter(
            (accountId) => accountId !== action.payload,
          ),
      };
    case actions.FETCH_UNAVAILABILITIES:
      return {
        ...state,
        unavailabilitiesLoader: true,
      };
    case actions.FETCH_UNAVAILABILITIES_SUCCESS:
      const unavailabilitiesList = action.payload.data;
      return {
        ...state,
        unavailabilitiesLoader: false,
        unavailabilities: _.isEmpty(unavailabilitiesList)
          ? []
          : unavailabilitiesList,
      };
    case actions.FETCH_UNAVAILABILITIES_FAILURE:
      return {
        ...state,
        unavailabilitiesLoader: false,
      };
    case actions.UPDATE_UNAVAILABILITIES_SUCCESS:
      return {
        ...state,
        unavailabilities: action.payload,
      };
    case actions.FETCH_TEMPLATE_LIST:
      if (action.templateType === 'visual_builder') {
        return {
          ...state,
          visualBuilderTemplateLoader: true,
        };
      } else {
        return {
          ...state,
          templateLoader: true,
        };
      }
    case actions.FETCH_TEMPLATE_LIST_SUCCESS:
      let topBarColor = _.find(action.payload.data.custom_email_templates, {
        name: 'personal',
      }).top_bar_color;
      let language = _.find(action.payload.data.custom_email_templates, {
        default: true,
      }).email_language;
      return {
        ...state,
        templateList: action.payload.data.custom_email_templates,
        templateLoader: false,
        defaultTemplate: action.payload.data.custom_email_templates.filter(
          (value) => value.default === true,
        ),
        templateColor: topBarColor,
        templateLanguage: language ? language : 'en',
      };
    case actions.FETCH_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        templateList: [],
        templateLoader: false,
        defaultTemplate: null,
      };
    case actions.FETCH_VISUAL_BUILDER_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        visualBuilderTemplateList: action.payload.data.custom_email_templates,
        visualBuilderTemplateLoader: false,
      };
    case actions.FETCH_VISUAL_BUILDER_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        visualBuilderTemplateList: [],
        visualBuilderTemplateLoader: false,
      };
    case actions.SET_CURRENT_TEMPLATE_TAB_KEY:
      return {
        ...state,
        currentTemplateTabKey: action.payload,
      };
    case actions.SET_VISUAL_BUILDER_CUSTOM_TEMPLATE_DATA:
      return {
        ...state,
        visualBuilderTemplateContent: action.jsonFile,
        visualBuilderTemplateHtmlContent: action.htmlFile,
      };
    case actions.SET_EMAIL_TEMPLATE_NAME:
      return {
        ...state,
        templateName: action.payload,
      };
    case actions.SET_VISUAL_BUILDER_TEMPLATE_NAME:
      return {
        ...state,
        visualBuilderTemplateName: action.payload,
      };
    case actions.FETCH_TEMPLATE:
      return {
        ...state,
        templateLoader: true,
      };
    case actions.FETCH_TEMPLATE_SUCCESS:
      if (
        action.payload.data.custom_email_template.template_type ===
        'visual_builder'
      ) {
        return {
          ...state,
          templateLoader: false,
          visualBuilderTemplateName:
            action.payload.data.custom_email_template.name,
          visualBuilderTemplateContent:
            action.payload.data.custom_email_template.visual_builder_data,
          visualBuilderTemplateHtmlContent:
            action.payload.data.custom_email_template.email_template,
          currentTemplateTabKey: '2',
        };
      } else {
        return {
          ...state,
          templateLoader: false,
          templateName: action.payload.data.custom_email_template.name,
          templateContent:
            action.payload.data.custom_email_template.email_template,
          currentTemplateTabKey: '1',
        };
      }
    case actions.FETCH_TEMPLATE_FAILURE:
      return {
        ...state,
        templateLoader: false,
        templateContent: '',
        templateName: '',
      };
    case actions.SET_EDITOR_CODE:
      return {
        ...state,
        templateContent: action.payload,
      };
    case actions.CREATE_EMAIL_TEMPLATE:
      return {
        ...state,
        createTemplateLoader: true,
      };
    case actions.CREATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        createTemplateLoader: false,
      };
    case actions.CREATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        createTemplateLoader: false,
      };
    case actions.UPDATE_EMAIL_TEMPLATE:
      return {
        ...state,
        createTemplateLoader: true,
      };
    case actions.UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        createTemplateLoader: false,
      };
    case actions.UPDATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        createTemplateLoader: false,
      };
    case actions.DELETE_EMAIL_TEMPLATE:
      return {
        ...state,
        deleteTemplateLoader: true,
      };
    case actions.DELETE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteTemplateLoader: false,
      };
    case actions.DELETE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteTemplateLoader: false,
      };
    case actions.UPDATE_DEFAULT_TEMPLATE_SUCCESS:
      let clearDefault = state.templateList.map((value) => {
        value.default = false;
        return value;
      });
      let templateList = clearDefault.map((value) => {
        if (value.id === action.defaultTemplateID) {
          value.default = true;
        }
        return value;
      });
      return {
        ...state,
        deleteTemplateLoader: false,
        templateList: templateList,
      };
    case actions.UPDATE_TEMPLATE_SUCCESS:
      if (action.payload.changeType === 'color') {
        return {
          ...state,
          templateColor: action.payload.color,
        };
      } else if (action.payload.changeType === 'template') {
        return {
          ...state,
          defaultTemplate: state.templateList.filter(
            (data) => data.id === action.payload.templateId,
          ),
        };
      } else {
        return {
          ...state,
        };
      }
    case actions.RESET_EMAIL_TEMPLATE_EDITOR:
      return {
        ...state,
        templateName: '',
        templateContent,
        visualBuilderTemplateName: '',
        visualBuilderTemplateContent,
      };
    case actions.DELETE_LANGUAGE_FROM_REDUCER:
      return {
        ...state,
        ...(action.fromKnowledgeBase
          ? {
              kbSettings: {
                ...state.kbSettings,
                additional_languages:
                  state.kbSettings.additional_languages.filter(
                    (_, index) => index !== action.index,
                  ),
              },
            }
          : {
              languageData: {
                ...state.languageData,
                added_languages: state.languageData.added_languages.filter(
                  (_, index) => index !== action.index,
                ),
              },
            }),
      };

    case actions.FETCH_KB_SETTINGS_SUCCESS:
      return {
        ...state,
        kbSettings: {
          ...state.kbSettings,
          ...(action.key
            ? {
                translations: {
                  ...state.kbSettings.translations,
                  [action.key]: action.payload,
                },
              }
            : {
                ...action.payload,
                additional_languages: action.payload.additional_languages || [],
              }),
        },
        updateKBLoader: false,
        customDomainErrorMessage: '',
      };
    case actions.KB_CUSTOM_DOMAIN_UPDATE_SUCCESS:
      return {
        ...state,
        sslStatus: action.payload.message,
        customDomainVerifyLoader: false,
        customDomainErrorMessage: '',
        domainVerified: true,
      };
    case actions.FETCH_KB_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        kbLanguages: action.payload,
      };
    case actions.FETCH_KB_COLORS_LIST_SUCCESS:
      return {
        ...state,
        kbColorsList: action.payload,
      };
    case actions.UPDATE_KB_SETTINGS:
      return {
        ...state,
        updateKBLoader: true,
      };
    case actions.KB_CUSTOM_DOMAIN_UPDATE_FAILURE:
      return {
        ...state,
        updateKBLoader: false,
        kbSettings: {
          ...state.kbSettings,
          custom_domain:
            action.payload &&
            action.payload.knowledgebase_setting &&
            action.payload.knowledgebase_setting.custom_domain,
        },
        customDomainErrorMessage: action.error,
      };
    case actions.KB_CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        customDomainErrorMessage: action.payload,
        customDomainVerifyLoader: false,
      };
    case actions.SET_CONNECT_DOMAIN_TAB_KEY:
      return {
        ...state,
        connectDomainCurrentTabKey: action.payload,
      };
    case actions.KB_CUSTOM_DOMAIN_UPDATE:
      return {
        ...state,
        customDomainVerifyLoader: true,
      };
    case actions.SET_ADD_CARD_DRAWER_STATUS:
      return {
        ...state,
        isOpenCardDrawer: action.payload,
      };
    case actions.GET_INVOICE_DETAIL:
      return {
        ...state,
        isTableLoader: true,
      };
    case actions.GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        isTableLoader: false,
        invoiceList: action.payload.invoices,
      };
    case actions.GET_INVOICE_DETAIL_FAILURE:
      return {
        ...state,
        isTableLoader: false,
      };
    case actions.DOWNLOAD_INVOICE_DETAIL_FAILURE:
      return {
        ...state,
        isDownloadLoader: state.isDownloadLoader.filter(
          (data) => data !== action.invoiceID,
        ),
      };
    case actions.GET_APIKEY_DETAIL_SUCCESS:
      return {
        ...state,
        apiKeyDetail: action.payload.project,
        pageLoader: false,
      };
    case actions.GET_PAYMENT_CARD_LIST_SUCCESS:
      return {
        ...state,
        paymentCardList: action.payload.cards,
        pageLoader: false,
      };
    case actions.GET_CURRENT_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        currentPlanDetail: action.payload.project_subscription_plan,
        pageLoader: false,
      };
    case actions.OUT_BOUND_DRAWER_VISIBLE:
      return {
        ...state,
        outBoundDrawerVisible: action.payload,
      };
    case actions.DOMAIN_VERIFY_DRAWER_VISIBLE:
      return {
        ...state,
        domainVerifyDrawerVisible: action.payload,
      };
    case actions.GET_SHARED_TEAM_EMAIL_SUCCESS:
      return {
        ...state,
        sharedTeamEmailDetail: action.payload,
        pageLoader: false,
      };
    case actions.SET_ADD_EMAIL_DRAWER_STATUS:
      return {
        ...state,
        isAddEmailDrawer: action.payload,
      };
    case actions.CREATE_SHARED_EMAIL_ADDRESS_SUCCESS:
      let teamDetail = state.sharedTeamEmailDetail.inbound_email_addresses;
      teamDetail.push(action.payload.inbound_email_address);
      return {
        ...state,
        sharedTeamEmailDetail: {
          ...state.sharedTeamEmailDetail,
          has_reached_limit: action.payload.has_reached_limit,
          inbound_email_addresses: teamDetail,
        },
        loading: false,
        isAddEmailDrawer: false,
        sharedEmailData: null,
      };

    case actions.DELETE_SHARED_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        sharedTeamEmailDetail: {
          ...state.sharedTeamEmailDetail,
          // has_reached_limit: action.payload.has_reached_limit,
          inbound_email_addresses:
            state.sharedTeamEmailDetail.inbound_email_addresses.filter(
              (data) => data.id !== action.id,
            ),
        },
      };
    case actions.FETCH_EMAIL_SENDING_DOMAIN_LIST_SUCCESS:
      return {
        ...state,
        emailSendingDomainList: action.payload,
        isFetchingDomainList: false,
      };
    case actions.SET_EMAIL_SENDING_DETAILS:
      return {
        ...state,
        emailSendingDetails: action.payload,
        imageUrl: action.payload.profile_picture,
      };
    case actions.SET_SHARED_EMAIL_DATA:
      let updatedEmailData = {};
      if (action.payload.id) {
        updatedEmailData = action.payload;
      } else {
        updatedEmailData = {
          inbound_ack_text:
            "<p>Hi {{contact.name | default: ''}}!</p>\n" +
            "<p>Thank you for your message. Our team will reply to you very shortly and we'll update you by email.</p>\n" +
            '<p>Thank you!</p>',
          inbound_ack: true,
        };
      }
      return {
        ...state,
        sharedEmailData: updatedEmailData,
      };
    case actions.EDIT_SHARED_EMAIL_ADDRESS_SUCCESS:
      let editedEmailAddress =
        state.sharedTeamEmailDetail.inbound_email_addresses.map((data) => {
          if (data.id === action.id) {
            data = {
              ...data,
              ...action.payload,
            };
          }
          return data;
        });
      return {
        ...state,
        sharedTeamEmailDetail: {
          ...state.sharedTeamEmailDetail,
          inbound_email_addresses: editedEmailAddress,
        },
        isAddEmailDrawer: false,
      };
    case actions.DELETE_EMAIL_SENDING_DOMAIN_EMAIL_SUCCESS:
      let updatedList = state.emailSendingDomainList.custom_team_mates.filter(
        (teamMate) => teamMate.id !== action.id,
      );
      return {
        ...state,
        emailSendingDomainList: {
          ...state.emailSendingDomainList,
          custom_team_mates: updatedList,
        },
      };
    case actions.CREATE_EMAIL_SEND_DOMAIN:
      return {
        ...state,
        isEmailCreateLoading: true,
      };
    case actions.DISABLE_EMAIL_SEND_LOADING:
      return {
        ...state,
        isEmailCreateLoading: false,
      };
    case actions.UPDATE_EMAIL_SEND_DOMAIN:
      return {
        ...state,
        isEmailCreateLoading: true,
      };
    case actions.UPDATE_EMAIL_SEND_DOMAIN_SUCCESS:
      let updatedEmails = state.emailSendingDomainList.custom_team_mates.map(
        (teamMate) => {
          if (teamMate.id === action.payload.id) {
            teamMate = action.payload;
          }
          return teamMate;
        },
      );
      return {
        ...state,
        emailSendingDomainList: {
          ...state.emailSendingDomainList,
          custom_team_mates: updatedEmails,
        },
      };
    case actions.FETCH_EMAIL_SENDING_DOMAIN_LIST:
      return {
        ...state,
        isFetchingDomainList: true,
      };
    case actions.VERIFY_EMAIL_SENDING_DOMAIN_SUCCESS:
      let updatedDNSList =
        state.emailSendingDomainList.send_grid_sub_domains.map((domain) => {
          if (domain.id === action.id) {
            domain = {
              ...domain,
              is_dns_configured: true,
            };
          }
          return domain;
        });
      return {
        ...state,
        emailSendingDomainList: {
          ...state.emailSendingDomainList,
          send_grid_sub_domains: updatedDNSList,
        },
        isVerifyingDomain: false,
        domainVerifyDrawerVisible: false,
      };
    case actions.VERIFY_EMAIL_SENDING_DOMAIN_PRELIM_SUCCESS:
      let dnsList = state.emailSendingDomainList.send_grid_sub_domains.map(
        (domain) => {
          if (domain.id === action.id) {
            domain = {
              ...domain,
              dns_details: action.payload,
            };
          }
          return domain;
        },
      );
      return {
        ...state,
        emailSendingDomainList: {
          ...state.emailSendingDomainList,
          send_grid_sub_domains: dnsList,
        },
      };
    case actions.VERIFY_EMAIL_SENDING_DOMAIN_PRELIM:
      return {
        ...state,
        isVerifyingDomain: true,
      };
    case actions.VERIFY_EMAIL_SENDING_DOMAIN:
      return {
        ...state,
        isVerifyingDomain: true,
      };
    case actions.VERIFY_EMAIL_SENDING_DOMAIN_FAILURE:
      return {
        ...state,
        isVerifyingDomain: false,
      };
    case actions.SET_IMAGE_URL:
      return {
        ...state,
        imageUrl: action.imageUrl,
      };
    case actions.GET_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        pageLoader: false,
        billingDetail: action.payload.billing_details,
      };
    case actions.ADD_CARD_DETAIL_SUCCESS:
      let cardDetail = state.paymentCardList.map((card) => {
        if (action.payload.card.default && card.default) {
          card.default = false;
        }
        return card;
      });
      cardDetail.push(action.payload.card);
      return {
        ...state,
        loader: false,
        paymentCardList: cardDetail,
      };
    case actions.REMOVE_CARD_DETAIL_SUCCESS:
      return {
        ...state,
        paymentCardList: state.paymentCardList.filter(
          (card) => card.id !== action.payload,
        ),
      };
    case actions.MAKE_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        paymentCardList: state.paymentCardList.map((card) => {
          if (card.default) {
            card.default = false;
          }
          if (card.id === action.payload) {
            card.default = true;
          }
          return card;
        }),
      };
    case actions.PAY_DUE_MODAL_STATUS: {
      return {
        ...state,
        payDueModalVisible: action.payload,
      };
    }
    case actions.PAY_DUE_SUCCESS: {
      return {
        ...state,
        currentPlanDetail: action.payload.project_subscription_plan,
        payDueModalVisible: false,
        loader: false,
      };
    }
    case actions.SET_WORKSPACE_DEFAULT_TAB_KEY:
      return {
        ...state,
        workSpaceTabKey: action.key,
      };
    case actions.SET_TEAMS_ROLES_DEFAULT_TAB_KEY:
      return {
        ...state,
        teamsRolesTabKey: action.key,
      };
    case actions.SET_ROLES_DRAWER_DEFAULT_TAB_KEY:
      return {
        ...state,
        rolesDrawerTabKey: action.key,
      };
    case actions.SET_COVER_IMAGE_URL:
      return {
        ...state,
        usersProfile: {
          ...state.usersProfile,
          cover_photo_url: action.coverImageUrl
            ? action.coverImageUrl
            : state.usersProfile.cover_photo_url,
        },
      };

    case actions.GET_ELIGIBLE_PLAN_DETAIL_SUCCESS: {
      return {
        ...state,
        planDetail: action.payload.subscription_plans,
      };
    }
    case actions.UPGRATE_PLAN_SUCCESS: {
      return {
        ...state,
        loader: false,
        payDueModalVisible: false,
      };
    }
    case actions.GET_GDPR_SUCCESS:
      return {
        ...state,
        gdprData: action.gdprData,
      };
    case actions.FETCH_CUSTOM_FIELDS_LIST_SUCCESS: {
      return {
        ...state,
        customFields: action.payload.contact,
        conversationFields: action.payload.conversation,
        teammateFields: action.payload.teammate,
      };
    }
    case actions.FETCH_FORMS_SUCCESS:
      const data = action.formsData;
      return {
        ...state,
        formsData: data,
      };
    case actions.UPDATE_FORMS_SUCCESS:
      return {
        ...state,
        formsData: {
          ...state.formsData,
          capture_non_gist_forms: action.payload,
        },
      };
    case actions.UPDATE_KB_SUB_DOMAIN:
      return {
        ...state,
        kbSettings: {
          ...state.kbSettings,
          sub_domain: action.payload,
        },
      };
    case actions.BOT_DATA_CHANGE:
      let backgroundColor = state.botIdentityData.backgroundColor,
        foregroundColor = state.botIdentityData.foregroundColor,
        iconId = state.botIdentityData.iconId,
        botName = state.botIdentityData.botName,
        autoClose = state.botIdentityData.autoClose,
        closeInterval = state.botIdentityData.closeInterval,
        closeIntervalUnit = state.botIdentityData.closeIntervalUnit,
        type = action.payload.changeType;
      if (type === 'color') {
        if (action.payload.background) {
          backgroundColor = action.payload.color;
        } else {
          foregroundColor = action.payload.color;
        }
        return {
          ...state,
          botIdentityData: {
            ...state.botIdentityData,
            backgroundColor,
            foregroundColor,
          },
        };
      } else if (type === 'icon') {
        iconId = action.payload.iconId;
        return {
          ...state,
          botIdentityData: {
            ...state.botIdentityData,
            iconId,
          },
        };
      } else if (type === 'name-auto-close') {
        if (action.changedValues.hasOwnProperty('auto-close')) {
          autoClose = action.changedValues['auto-close'];
          if (autoClose) {
            return {
              ...state,
              botIdentityData: {
                ...state.botIdentityData,
                autoClose,
                closeInterval: 1,
                closeIntervalUnit: 'minute',
              },
            };
          }
          return {
            ...state,
            botIdentityData: {
              ...state.botIdentityData,
              autoClose,
            },
          };
        } else if (action.changedValues.hasOwnProperty('close_interval')) {
          closeInterval = action.changedValues['close_interval'];
          return {
            ...state,
            botIdentityData: {
              ...state.botIdentityData,
              closeInterval,
            },
          };
        } else if (action.changedValues.hasOwnProperty('close_interval_unit')) {
          closeIntervalUnit = action.changedValues['close_interval_unit'];
          return {
            ...state,
            botIdentityData: {
              ...state.botIdentityData,
              closeIntervalUnit,
            },
          };
        } else if (action.changedValues.botName) {
          botName = action.changedValues.botName;
          return {
            ...state,
            botIdentityData: {
              ...state.botIdentityData,
              botName,
            },
          };
        } else {
          return {
            ...state,
            botIdentityData: {
              ...state.botIdentityData,
            },
          };
        }
      } else {
        return {
          ...state,
          botIdentityData: {
            ...state.botIdentityData,
          },
        };
      }
    case actions.FETCH_BOT_IDENTITY: {
      return {
        ...state,
        botIdentityDataLoader: true,
      };
    }
    case actions.FETCH_BOT_IDENTITY_SUCCESS: {
      const {
        setting: botData,
        enable_support_bot: isSupportBotEnabled,
        enable_support_bot_intro_message: isSupportBotIntroMessagesEnabled,
        support_bot_intro_messages: supportBotIntroMessages,
        support_bot_feedback: supportBotFeedback,
        enable_ai_bot: isAIBotEnabled,
        emma_enabled_for_email: isEmmaAllowedInEmail,
        ai_bot_details: aiBotDetails,
        emma_details: emmaDetails,
      } = action.payload;
      return {
        ...state,
        botIdentityData: {
          backgroundColor: botData.bot_icon_details.background_colour,
          foregroundColor: botData.bot_icon_details.foreground_colour,
          iconId: botData.bot_icon_details.icon_type,
          botName: botData.bot_name,
          botIconUrl: botData.bot_icon_url,
          autoClose: botData.bot_auto_close_enabled,
          closeInterval: botData.bot_auto_close_interval_quantity,
          closeIntervalUnit: botData.bot_auto_close_interval_unit,
        },
        customBotData: {
          background_colour: botData.bot_icon_details.background_colour,
          foreground_colour: botData.bot_icon_details.foreground_colour,
          icon_type: botData.bot_icon_details.icon_type,
          bot_name: botData.bot_name,
          bot_icon_url: botData.bot_icon_url,
          bot_auto_close_enabled: botData.bot_auto_close_enabled,
          bot_auto_close_interval_quantity:
            botData.bot_auto_close_interval_quantity,
          bot_auto_close_interval_unit: botData.bot_auto_close_interval_unit,
        },
        supportBotFeedback,
        isSupportBotEnabled,
        imageUrl: '',
        botIdentityDataLoader: false,
        supportBotIntroMessages,
        isSupportBotIntroMessagesEnabled,
        aiBotDetails,
        isAIBotEnabled,
        isEmmaAllowedInEmail,
        emmaTriggerOption: emmaDetails.trigger_option,
      };
    }
    case actions.FETCH_BOT_IDENTITY_FAILURE: {
      return {
        ...state,
        botIdentityDataLoader: false,
      };
    }
    case actions.UPDATE_MAGIC_TYPE_SETTINGS: {
      return {
        ...state,
        advancedData: {
          ...state.advancedData,
          allow_magic_type: action.payload,
        },
      };
    }
    case actions.DRAWER_VISIBILITY: {
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    }
    case actions.DRAWER_VISIBILITY_TRANSFER_OWNERSHIP: {
      return {
        ...state,
        isOwnerDrawerOpen: action.payload,
      };
    }
    case actions.DELETING_TEAM_PROCESS: {
      return {
        ...state,
        isDeletingTeamProcess: action.payload,
      };
    }
    case actions.DRAWER_VISIBILITY_PROPERTY_GROUP: {
      return {
        ...state,
        isShowGroupDrawer: action.payload,
      };
    }
    case actions.DRAWER_VISIBILITY_PROPERTY: {
      return {
        ...state,
        isShowPropertyDrawer: action.payload,
      };
    }

    case actions.SET_SYNC_PROGRESS: {
      return {
        ...state,
        syncProgressStatus: action.payload,
      };
    }
    case actions.TRIGGER_TAG_LOADER: {
      return {
        ...state,
        triggerContactTagLoader: action.payload,
      };
    }
    case actions.ACTION_TAG_LOADER: {
      return {
        ...state,
        actionContactTagLoader: action.payload,
        conversationTagLoader: action.payload,
      };
    }
    case actions.CONTACTS_TAG_LOADER: {
      return {
        ...state,
        contactTagLoader: action.payload,
      };
    }
    case actions.EMAIL_SUNSET_POLICY_SUCCESS: {
      return {
        ...state,
        emailSunsetPolicy: action.payload,
      };
    }
    case actions.SET_ADVANCED_SATISFACTION_RATING_CONTENT:
      return {
        ...state,
        satisfactionRatingContent: action.payload,
      };
    case actions.UPDATE_ADVANCED_TAB_SUCCESS:
      return {
        ...state,
        advancedData: {
          ...state.advancedData,
          ...action.payload.setting,
        },
      };
    case actions.SET_SATISFACTION_RATINGS_SETTINGS_LOADER:
      return {
        ...state,
        satisfactionRatingSettingsLoader: action.payload,
      };
    case actions.SET_ASK_EMAIL_BEFORE_START_LOADER:
      return {
        ...state,
        askForEmailBeforeStartLoader: action.payload,
      };
    case actions.SET_PREVENT_CONVERSATION_CLOSE_LOADER:
      return {
        ...state,
        preventConversationCloseLoader: action.payload,
      };
    case actions.SET_SETTINGS_FORM_TAB_KEY:
      return {
        ...state,
        settingsFormTabKey: action.key,
      };
    case actions.CONFIRMATION_SETTINGS_FORM_UPDATE:
      return {
        ...state,
        settingsFormLoading: true,
      };
    case actions.CONFIRMATION_SETTINGS_FORM_UPDATE_SUCCESS:
      return {
        ...state,
        settingsFormLoading: false,
      };
    case actions.FETCH_REFERRER_ID_SUCCESS: {
      return {
        ...state,
        referralLoader: false,
        referralMail: action.payload.data.email,
      };
    }
    case actions.CONFIRMATION_SETTINGS_FORM_UPDATE_FAILURE:
      return {
        ...state,
        settingsFormLoading: false,
      };
    case actions.UPDATE_MEETINGS_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        usersProfile: {
          ...state.usersProfile,
          ...action.payload,
        },
      };
    }
    case actions.SET_GREETING_TEXT:
      return {
        ...state,
        greetingText: action.payload,
      };
    case actions.APPEND_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        blockedUserData: [...state.blockedUserData, ...action.payload],
      };
    case actions.APPEND_BLOCKED_IP_SUCCESS:
      return {
        ...state,
        blockedIPData: [...state.blockedIPData, ...action.payload],
      };
    case actions.SETTINGS_GET_PIPELINE_DETAIL:
      return {
        ...state,
        stagesLoader: true,
      };
    case actions.SETTINGS_GET_PIPELINE_DETAIL_SUCCESS:
      const currentPipeline = find(action.payload, { is_default: true });
      const pipelineList = map(action.payload, (data) => ({
        id: data.id,
        name: data.name,
        is_default: data.is_default,
      }));
      return {
        ...state,
        currentPipeline: {
          id: currentPipeline.id,
          name: currentPipeline.name,
          is_default: currentPipeline.is_default,
        },
        stagesList: currentPipeline.stages,
        pipelineList: pipelineList,
        stagesLoader: false,
      };
    case actions.SETTINGS_GET_PIPELINE_DETAIL_FAILURE:
      return {
        ...state,
        stagesLoader: false,
      };
    case actions.SETTINGS_GET_STAGES:
      return {
        ...state,
        stagesLoader: true,
      };
    case actions.SETTINGS_GET_STAGES_SUCCESS:
      return {
        ...state,
        stagesList: action.payload,
        stagesLoader: false,
      };
    case actions.SETTINGS_GET_STAGES_FAILURE:
      return {
        ...state,
        stagesLoader: false,
      };
    case actions.SETTINGS_SET_PIPELINE_ID:
      return {
        ...state,
        currentPipeline: action.payload,
      };
    case actions.SETTINGS_CREATE_PIPELINE_SUCCESS:
      const newPipeline = {
        id: action.payload.id,
        name: action.payload.name,
        is_default: action.payload.is_default,
      };
      return {
        ...state,
        currentPipeline: newPipeline,
        stagesList: action.payload.stages,
        pipelineList: [...state.pipelineList, ...[newPipeline]],
        pipelineDrawerStatus: false,
      };
    case actions.SETTINGS_CREATE_PIPELINE_FAILURE:
      return {
        ...state,
        pipelineDrawerStatus: true,
      };
    case actions.SETTINGS_UPDATE_PIPELINE_SUCCESS:
      return {
        ...state,
        currentPipeline: {
          ...state.currentPipeline,
          name: action.payload.name,
        },
        pipelineList: state.pipelineList.map((data) => {
          if (data.id === action.payload.id) {
            data.name = action.payload.name;
          }
          return data;
        }),
        pipelineDrawerStatus: false,
      };
    case actions.SETTINGS_UPDATE_PIPELINE_FAILURE:
      return {
        ...state,
        pipelineDrawerStatus: true,
      };
    case actions.SETTINGS_DELETE_PIPELINE_SUCCESS:
      const defaultPipeline = find(state.pipelineList, { is_default: true });
      return {
        ...state,
        currentPipeline: defaultPipeline,
        pipelineList: state.pipelineList.filter(
          (data) => data.id !== action.payload.id,
        ),
      };
    case actions.SETTINGS_UPDATE_PIPELINE_DRAWER_STATUS:
      return {
        ...state,
        pipelineDrawerStatus: action.payload,
      };
    case actions.SETTINGS_UPDATE_STAGE_DRAWER_STATUS:
      return {
        ...state,
        stageDrawerStatus: action.payload,
      };
    case actions.SETTINGS_CREATE_STAGE_SUCCESS:
      const newList = state.stagesList;
      newList.splice(action.payload.display_order - 1, 0, action.payload);
      return {
        ...state,
        stagesList: newList,
        stageDrawerStatus: false,
      };
    case actions.SETTINGS_CREATE_STAGE_FAILURE:
      return {
        ...state,
        stageDrawerStatus: false,
      };
    case actions.SETTINGS_UPDATE_STAGE_SUCCESS:
      return {
        ...state,
        stagesList: state.stagesList.map((data) => {
          if (data.id === action.id) {
            data.name = action.name;
            data.probability = action.probability;
          }
          return data;
        }),
        stageDrawerStatus: false,
      };
    case actions.SETTINGS_UPDATE_STAGE_FAILURE:
      return {
        ...state,
        stageDrawerStatus: false,
      };
    case actions.SETTINGS_DELETE_STAGE_SUCCESS:
      return {
        ...state,
        stagesList: state.stagesList.filter((item) => item.id !== action.id),
      };
    case actions.SETTINGS_SET_STAGES:
      return {
        ...state,
        stagesList: action.payload,
      };
    case actions.SETTINGS_GET_DEAL_PROPERTIES:
      return {
        ...state,
        pageLoader: true,
      };
    case actions.SETTINGS_GET_DEAL_PROPERTIES_SUCCESS:
      return {
        ...state,
        dealProperties: action.payload ? action.payload : state.dealProperties,
        pageLoader: false,
      };
    case actions.SETTINGS_GET_DEAL_PROPERTIES_FAILURE:
      return {
        ...state,
        pageLoader: false,
      };
    case actions.SETTINGS_CREATE_DEAL_PROPERTY:
      return {
        ...state,
        loader: true,
      };
    case actions.SETTINGS_CREATE_DEAL_PROPERTY_SUCCESS:
      return {
        ...state,
        loader: false,
        isShowPropertyDrawer: false,
      };
    case actions.SETTINGS_CREATE_DEAL_PROPERTY_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case actions.SETTINGS_UPDATE_DEAL_PROPERTY:
      return {
        ...state,
        loader: true,
      };
    case actions.SETTINGS_UPDATE_DEAL_PROPERTY_SUCCESS:
      return {
        ...state,
        loader: false,
        isShowPropertyDrawer: false,
      };
    case actions.SETTINGS_UPDATE_DEAL_PROPERTY_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case actions.SETTINGS_DELETE_DEAL_PROPERTY_SUCCESS:
      return {
        ...state,
        dealProperties: action.id
          ? state.dealProperties.filter((item) => item.id !== action.id)
          : state.dealProperties,
      };
    case actions.SETTINGS_SET_DEAL_PROPERTIES_SEARCH_VALUE:
      return {
        ...state,
        dealPropertiesSearchValue: action.payload,
      };
    case actions.SETTINGS_APPEND_DEAL_PROPERTIES:
      return {
        ...state,
        pageLoader: true,
      };
    case actions.SETTINGS_APPEND_DEAL_PROPERTIES_SUCCESS:
      return {
        ...state,
        dealProperties: action.payload
          ? [...state.dealProperties, ...action.payload]
          : state.dealProperties,
        pageLoader: false,
      };
    case actions.SETTINGS_APPEND_DEAL_PROPERTIES_FAILURE:
      return {
        ...state,
        pageLoader: false,
      };
    case actions.SETTINGS_GET_COMPANY_TRACKING_STATUS:
      return {
        ...state,
        pageLoader: true,
      };
    case actions.SETTINGS_GET_COMPANY_TRACKING_STATUS_SUCCESS:
      return {
        ...state,
        companyTrackingStatus: action.payload,
        pageLoader: false,
      };
    case actions.SETTINGS_GET_COMPANY_TRACKING_STATUS_FAILURE:
      return {
        ...state,
        pageLoader: false,
      };
    case actions.SETTINGS_UPDATE_COMPANY_TRACKING_STATUS:
      return {
        ...state,
        loader: true,
      };
    case actions.SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_SUCCESS:
      return {
        ...state,
        companyTrackingStatus: action.payload,
        loader: false,
      };
    case actions.SETTINGS_UPDATE_COMPANY_TRACKING_STATUS_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case actions.GET_WEBHOOKS_LIST_SUCCESS:
      return {
        ...state,
        webhooksTableData: action.payload,
      };
    case actions.GET_SINGLE_WEBHOOK_DATA_SUCCESS:
      return {
        ...state,
        initialWebhookData: action.payload,
        selectedWebhookData: action.payload,
      };
    case actions.GET_WEBHOOKS_SUBSCRIPTIONS_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        webhooksTemplateData: action.payload,
      };

    case actions.UPDATE_SELECTED_WEBHOOK_REDUCER_DATA:
      return {
        ...state,
        selectedWebhookData: action.payload,
      };

    case actions.UPDATE_SINGLE_WEBHOOK_DATA_SUCCESS:
      return {
        ...state,
        initialWebhookData: action.payload,
        selectedWebhookData: action.payload,
      };

    case actions.CREATE_SINGLE_WEBHOOK_SUCCESS:
      return {
        ...state,
        initialWebhookData: action.payload,
        selectedWebhookData: action.payload,
      };
    case actions.GET_MAIL_SUBSCRIPTION_DATA:
      return {
        ...state,
        pageLoader: true,
      };
    case actions.GET_MAIL_SUBSCRIPTION_DATA_SUCCESS:
      return {
        ...state,
        mailSubscriptionData: action.payload,
        pageLoader: false,
      };
    case actions.SET_MAIL_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        setMailSubscription: action.payload,
      };
    case actions.MAIL_SUBSCRIPTION_DRAWER_VISIBLE:
      return {
        ...state,
        mailSubscriptionDrawerVisible: action.payload,
      };
    case actions.DEFAULT_CONTACT_SUBSCRIPTION_TYPES:
      return {
        ...state,
        defaultContactSubscriptionTypes: action.payload,
      };
    case actions.EMAIL_SETTINGS_SWITCH_LOADER:
      return {
        ...state,
        sunsetPolicyLoader: action.payload,
      };
    case actions.DRAWER_VISIBILITY_CONVERSATION_PROPERTY: {
      return {
        ...state,
        isConversationPropertyCreate: action.isCreate,
        isShowConversationProperty: action.payload,
        isConversationPropertyEdit: !!action.isEditMode,
        conversationPropertyDrawerData: !action.payload
          ? null
          : action.isEditMode
          ? action.selectedPropertyData
          : state.conversationPropertyDrawerData,
        conversationPropertyLists: !action.payload
          ? []
          : state.conversationPropertyLists,
        conversationPropertyListCount: !action.payload
          ? 0
          : state.conversationPropertyListCount,
      };
    }
    case actions.FETCH_CONVERSATION_PROPERTIES_SUCCESS: {
      return {
        ...state,
        conversationPropertiesData: action.conversationPropertiesData,
      };
    }
    case actions.SETTINGS_CONVERSATION_PROPERTIES_SEARCH:
      return {
        ...state,
        conversationPropertySearch: action.searchValue,
      };
    case actions.GET_INDIVIDUAL_CONVERSATION_PROPERTIES_SUCCESS: {
      const propertyArray = [];
      const individualConversationProperties = action.payload;

      individualConversationProperties &&
        Object.entries({
          ...individualConversationProperties.properties,
          ...individualConversationProperties.highlighted_properties,
        }).forEach(([key, value]) => {
          const defaultKeys = ['organization', 'priority'];
          if (defaultKeys.includes(key)) {
            propertyArray.push({
              name: key,
              value: value,
            });
          }
          state.conversationPropertiesData.forEach((property) => {
            if (property.name === key) {
              propertyArray.push({
                id: property.id,
                name: key,
                data_type: property.data_type,
                value: value,
                options: property.options ? property.options : null,
                select_type: property.select_type,
              });
            }
          });
        });

      const tempSortedConversationPropertiesArray = [];
      if (individualConversationProperties) {
        const highlightedProperties = Object.keys(
          individualConversationProperties.highlighted_properties,
        );
        const remainingProperties = Object.keys(
          individualConversationProperties.properties,
        );

        highlightedProperties.forEach((property) => {
          propertyArray.forEach((item) => {
            if (item.name === property) {
              tempSortedConversationPropertiesArray.push(item);
            }
          });
        });
        remainingProperties.forEach((property) => {
          propertyArray.forEach((item) => {
            if (item.name === property) {
              tempSortedConversationPropertiesArray.push(item);
            }
          });
        });
      }

      return {
        ...state,
        individualConversationProperties: action.payload,
        conversationPropertiesArray: propertyArray,
        sortedConversationProperties: tempSortedConversationPropertiesArray,
        conversationPropertiesLoader: false,
      };
    }
    case actions.UPDATE_INDIVIDUAL_CONVERSATION_PROPERTY: {
      let updatedConversationPropertiesArray =
        state.conversationPropertiesArray.map((property) => {
          if (property.id === action.object.property_id) {
            property.value = action.object.property_value;
          }
          return property;
        });
      return {
        ...state,
        conversationPropertiesArray: updatedConversationPropertiesArray,
      };
    }
    case actions.SET_CONVERSATION_PROPERTY_LISTS:
      const setCount = action.isEditProperty
        ? action.payload.length
        : action.isCountUpdate
        ? state.conversationPropertyListCount + 1
        : state.conversationPropertyListCount;
      return {
        ...state,
        conversationPropertyLists: action.payload,
        conversationPropertyListCount: setCount,
      };
    case actions.FETCH_CONTACT_CONVERSATION_PROPERTY_WITH_OPTIONS_SUCCESS:
      return {
        ...state,
        conversationPropertyDrawerData: action.payload,
      };
    case actions.UPDATE_CONVERSATION_PROPERTIES_FROM_SOCKET: {
      const updatedProperties = action.payload;
      let sortedConversationProperties = state.sortedConversationProperties;

      Object.keys(updatedProperties).map(property => {
        let propertyValue = updatedProperties[property];
        sortedConversationProperties.forEach((conversationProperty) => {
          if (conversationProperty.name === property) {
            conversationProperty.value = propertyValue;
          }
        });
      });
      return {
        ...state,
        sortedConversationProperties,
      };
    }
    case actions.GET_INDIVIDUAL_CONVERSATION_PROPERTIES: {
      return {
        ...state,
        conversationPropertiesLoader: true,
      };
    }
    case actions.SET_SNIPPET_FOLDER_DRAWER_VISIBILITY: {
      return {
        ...state,
        snippetFolderDrawerVisibility: action.payload,
      };
    }
    case actions.UPDATE_SNIPPET_FOLDER_DATA: {
      return {
        ...state,
        snippetFolderDrawerLoader: true,
      };
    }
    case actions.UPDATE_SNIPPET_FOLDER_DATA_SUCCESS: {
      return {
        ...state,
        snippetFolderDrawerLoader: false,
      };
    }
    case actions.UPDATE_SNIPPET_FOLDER_DATA_FAILURE: {
      return {
        ...state,
        snippetFolderDrawerLoader: false,
      };
    }
    case actions.CREATE_SNIPPET_FOLDER_DATA: {
      return {
        ...state,
        snippetFolderDrawerLoader: true,
      };
    }
    case actions.CREATE_SNIPPET_FOLDER_DATA_SUCCESS: {
      return {
        ...state,
        snippetFolderDrawerLoader: false,
      };
    }
    case actions.CREATE_SNIPPET_FOLDER_DATA_FAILURE: {
      return {
        ...state,
        snippetFolderDrawerLoader: false,
      };
    }
    case actions.UPDATE_SNIPPET_DRAWER_DATA_LOADER: {
      return {
        ...state,
        snippetDrawerDataLoader: action.payload,
      };
    }
    case actions.CONVERSATION_TAG_LOADER: {
      return {
        ...state,
        conversationTagLoader: action.payload,
      };
    }
    case actions.UPDATE_SNIPPET_DATA_SUCCESS: {
      if (action.payload.id === state.singleSnippetData.id) {
        return {
          ...state,
          singleSnippetData: action.payload,
        };
      }
      return {
        ...state,
      };
    }
    case actions.FETCH_SNIPPET_DATA:
      return {
        ...state,
        searchSnippetListLoader: true,
      };
    case actions.FETCH_HUBSPOT_PROPERTIES: {
      return {
        ...state,
        hubspotPropertiesLoader: true,
      };
    }
    case actions.FETCH_HUBSPOT_PROPERTIES_SUCCESS: {
      return {
        ...state,
        hubspotProperties: action.payload.hubspot_contact_properties.results,
        hubspotMappedProperties: action.payload.hubspot_properties_mapping,
        autoSyncContact: action.payload.auto_sync_contact || false,
        hubspotPropertiesLoader: false,
      };
    }
    case actions.FETCH_HUBSPOT_PROPERTIES_FAILURE: {
      return {
        ...state,
        hubspotPropertiesLoader: false,
      };
    }
    case actions.SAVE_HUBSPOT_MAPPING_PROPERITIES: {
      return {
        ...state,
        mappingSaveLoader: true,
      };
    }
    case actions.SAVE_HUBSPOT_MAPPING_PROPERITIES_SUCCESS: {
      return {
        ...state,
        mappingSaveLoader: false,
        hubspotMappedProperties: action.payload,
      };
    }
    case actions.SAVE_HUBSPOT_MAPPING_PROPERITIES_FAILURE: {
      return {
        ...state,
        mappingSaveLoader: false,
      };
    }
    case actions.SYNC_HUBSPOT_HISTORY_DATA: {
      return {
        ...state,
        syncHubspotHistoryDataLoader: true,
      };
    }
    case actions.SYNC_HUBSPOT_HISTORY_DATA_SUCCESS: {
      return {
        ...state,
        syncHubspotHistoryDataLoader: false,
      };
    }
    case actions.SYNC_HUBSPOT_HISTORY_DATA_FAILURE: {
      return {
        ...state,
        syncHubspotHistoryDataLoader: false,
      };
    }
    case actions.UPDATE_AUTO_SYNC_CONTACT: {
      return {
        ...state,
        autoSyncContact: action.payload,
      };
    }
    case actions.GET_MESSENGER_APP_PREFERENCES: {
      return {
        ...state,
        messengerAppPreferencesLoader: true,
      };
    }
    case actions.GET_MESSENGER_APP_PREFERENCES_SUCCESS: {
      return {
        ...state,
        messengerAppPreferencesLoader: false,
        messengerAppPreferences: {
          site_visitor_apps: action.payload.site_visitor_apps || [],
          contact_apps: action.payload.contact_apps || [],
        },
      };
    }
    case actions.GET_MESSENGER_APP_PREFERENCES_FAILURE: {
      return {
        ...state,
        messengerAppPreferencesLoader: false,
        messengerAppPreferences: {
          site_visitor_apps: [],
          contact_apps: [],
        },
      };
    }
    case actions.UPDATE_MESSENGER_APP_PREFERENCES: {
      return {
        ...state,
        oldMessengerAppPreferences: state.messengerAppPreferences,
        messengerAppPreferences: {
          ...state.messengerAppPreferences,
          ...action.payload.setting.app_preference,
        },
      };
    }
    case actions.UPDATE_MESSENGER_APP_PREFERENCES_SUCCESS: {
      return {
        ...state,
        oldMessengerAppPreferences: state.messengerAppPreferences,
      };
    }
    case actions.UPDATE_MESSENGER_APP_PREFERENCES_FAILURE: {
      return {
        ...state,
        messengerAppPreferences: state.oldMessengerAppPreferences,
      };
    }
    case actions.UPDATE_MESSENGER_APP_DISPLAY_CONDITION_MODAL: {
      return {
        ...state,
        messengerAppDisplayConditionModalData: action.payload,
        messengerAppDisplayConditionModalVisibility: action.visibility,
      };
    }
    case actions.SETTINGS_COUNTRY_SUCCESS:
      return {
        ...state,
        countryList: action.countryList,
      };
    case actions.GET_SPAM_FILTERS_DATA: {
      return {
        ...state,
        spamFiltersDataLoader: true,
      };
    }
    case actions.GET_SPAM_FILTERS_DATA_SUCCESS: {
      return {
        ...state,
        spamFiltersDataLoader: false,
        spamFiltersData: action.payload,
      };
    }
    case actions.GET_SPAM_FILTERS_DATA_FAILURE: {
      return {
        ...state,
        spamFiltersDataLoader: false,
      };
    }
    case actions.UPDATE_SPAM_FILTERS_DATA: {
      return {
        ...state,
        updateSpamFiltersLoader: true,
      };
    }
    case actions.UPDATE_SPAM_FILTERS_DATA_SUCCESS: {
      return {
        ...state,
        spamFiltersData: action.payload,
        updateSpamFiltersLoader: false,
      };
    }
    case actions.UPDATE_SPAM_FILTERS_DATA_FAILURE: {
      return {
        ...state,
        updateSpamFiltersLoader: false,
      };
    }
    case actions.SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK: {
      return {
        ...state,
        updateSupportBotFeedbackLoader: true,
      };
    }
    case actions.SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_SUCCESS: {
      return {
        ...state,
        supportBotFeedback: action.payload.support_bot_feedback.en,
        isSupportBotEnabled: action.payload.enable_support_bot,
        updateSupportBotFeedbackLoader: false,
      };
    }
    case actions.SETTINGS_UPDATE_SUPPORT_BOT_FEEDBACK_FAILURE: {
      return {
        ...state,
        updateSupportBotFeedbackLoader: false,
      };
    }
    case actions.FETCH_LIVE_BOT_LIST_SUCCESS: {
      return {
        ...state,
        liveBots: action.payload,
      };
    }
    case actions.UPDATE_SETTINGS_SUPPORT_BOT: {
      return {
        ...state,
        updateSupportBotLoader: true,
      };
    }
    case actions.UPDATE_SETTINGS_SUPPORT_BOT_SUCCESS: {
      return {
        ...state,
        updateSupportBotLoader: false,
        isSupportBotEnabled: action.payload.enable_support_bot,
        isSupportBotIntroMessagesEnabled:
          action.payload.enable_support_bot_intro_message,
        supportBotIntroMessages: action.payload.support_bot_intro_messages,
        supportBotFeedback: action.payload.support_bot_feedback,
        isAIBotEnabled: action.payload.enable_ai_bot,
        isEmmaAllowedInEmail: action.payload.emma_enabled_for_email,
        aiBotDetails: action.payload.ai_bot_details,
      };
    }
    case actions.UPDATE_SETTINGS_SUPPORT_BOT_FAILURE: {
      return {
        ...state,
        updateSupportBotLoader: false,
      };
    }
    case actions.FETCH_KB_THEMES_LIST:
      return {
        ...state,
        kbThemesListLoader: true,
      };
    case actions.FETCH_KB_THEMES_LIST_SUCCESS:
      return {
        ...state,
        kbThemesList: action.payload.kb_themes,
        kbThemeActiveId: action.payload.active_theme_id,
        kbThemePreviewDomain: action.payload.kb_domain,
        kbThemesListLoader: false,
      };
    case actions.FETCH_KB_THEMES_LIST_FAILURE:
      return {
        ...state,
        kbThemesListLoader: false,
      };
    case actions.DUPLICATE_KB_THEME_DRAWER_STATUS:
      return {
        ...state,
        kbThemeDuplicateDrawer: action.payload,
        setKbThemeDuplicateId: action.themeId,
        setKbDuplicateName: action.themeName ? `${action.themeName}(Copy)` : '',
      };
    case actions.DUPLICATE_KB_THEME:
      return {
        ...state,
        kbDuplicateLoader: true,
      };
    case actions.DUPLICATE_KB_THEME_SUCCESS:
      return {
        ...state,
        kbThemeEditorDetails: action.payload,
        kbDuplicateLoader: false,
        kbThemeDuplicateDrawer: false,
      };
    case actions.DUPLICATE_KB_THEME_FAILURE:
      return {
        ...state,
        kbDuplicateLoader: false,
      };
    case actions.FETCH_KB_THEME_DETAILS:
      return {
        ...state,
        kbThemeEditorLoader: true,
      };
    case actions.FETCH_KB_THEME_DETAILS_SUCCESS:
      return {
        ...state,
        kbThemeEditorDetails: action.payload,
        kbThemePreviewDomain: !action.isFromCustomize
          ? action.payload.kb_domain
          : state.kbThemePreviewDomain,
      };
    case actions.FETCH_KB_THEME_DETAILS_FAILURE:
      return {
        ...state,
        kbThemeEditorLoader: false,
      };
    case actions.FETCH_KB_LIQUID_FILE_DATA:
      return {
        ...state,
        kbThemeEditorSpinner: !action.isInitialFetch,
        kbThemeEditorLoader: action.isInitialFetch,
      };
    case actions.FETCH_KB_LIQUID_FILE_DATA_SUCCESS:
      return {
        ...state,
        kbThemeEditorSpinner: false,
        kbThemeEditorLoader: false,
        kbThemeEditorFileName: action.fileName,
        kbThemeEditorContent: action.payload,
        kbThemeEditorInitialContent: action.payload,
        kbThemeAvailableLiquids: [
          ...state.kbThemeAvailableLiquids,
          { file_name: action.fileName, content: action.payload },
        ],
        kbThemeInitialAvailableLiquids: [
          ...state.kbThemeInitialAvailableLiquids,
          { file_name: action.fileName, content: action.payload },
        ],
      };
    case actions.FETCH_KB_LIQUID_FILE_DATA_FAILURE:
      return {
        ...state,
        kbThemeEditorSpinner: false,
        kbThemeEditorLoader: false,
      };
    case actions.GET_LIQUID_FILE_FROM_AVAILABLE_LIQUID_LIST:
      return {
        ...state,
        kbThemeEditorFileName: action.payload.file_name,
      };
    case actions.REMOVE_AVAILABLE_LIQUID_LIST: {
      let getInitialAvailableLiquids = cloneDeep(
          state.kbThemeInitialAvailableLiquids,
        ),
        getAvailableLiquids = cloneDeep(state.kbThemeAvailableLiquids);
      getInitialAvailableLiquids = getInitialAvailableLiquids.filter(
        (liquid) => liquid.file_name !== action.fileName,
      );
      getAvailableLiquids = getAvailableLiquids.filter(
        (liquid) => liquid.file_name !== action.fileName,
      );
      return {
        ...state,
        kbThemeAvailableLiquids: getAvailableLiquids,
        kbThemeInitialAvailableLiquids: getInitialAvailableLiquids,
      };
    }
    case actions.UPDATE_EDITOR_LIQUID_CONTENT:
      let getAvailableLiquids = cloneDeep(state.kbThemeAvailableLiquids).map(
        (liquid) => {
          if (liquid.file_name === state.kbThemeEditorFileName) {
            liquid.content = action.payload;
          }
          return liquid;
        },
      );
      return {
        ...state,
        kbThemeAvailableLiquids: getAvailableLiquids,
      };
    case actions.SAVE_DRAFT_KB_THEME_EDITOR_LIQUID_SUCCESS: {
      let getInitialAvailableLiquids = cloneDeep(
          state.kbThemeInitialAvailableLiquids,
        ),
        getLiquidContent = state.kbThemeAvailableLiquids.find(
          (liquid) => liquid.file_name === state.kbThemeEditorFileName,
        );
      getInitialAvailableLiquids = getInitialAvailableLiquids.map((liquid) => {
        if (liquid.file_name === state.kbThemeEditorFileName) {
          liquid.content = getLiquidContent.content;
        }
        return liquid;
      });
      return {
        ...state,
        kbThemeEditorInitialContent: !action.isForNameUpdate
          ? state.kbThemeEditorContent
          : state.kbThemeEditorInitialContent,
        kbThemeEditorDetails: {
          ...state.kbThemeEditorDetails,
          name: action.payload.name,
          preview_url: action.payload.preview_url,
        },
        kbThemeInitialAvailableLiquids: !action.isForNameUpdate
          ? getInitialAvailableLiquids
          : state.kbThemeInitialAvailableLiquids,
      };
    }
    case actions.RESET_KB_THEME_EDITOR_DETAILS:
      return {
        ...state,
        kbThemeEditorDetails: {},
        kbThemeAvailableLiquids: [],
        kbThemeInitialAvailableLiquids: [],
        kbThemeEditorFileName: '',
        kbThemeEditorContent: '',
        kbThemeEditorInitialContent: '',
      };
    case actions.SET_ACTIVE_KB_THEME_SUCCESS:
      return {
        ...state,
        kbThemeActiveId: action.payload,
      };
    case actions.UPDATE_PLAN_SUBSCRIPTION_USAGES:
      return {
        ...state,
        currentPlanDetail: {
          ...state.currentPlanDetail,
          usage: action.payload.usage,
        },
        invalidatePrimeCoupons: action.payload.invalid_coupons,
        invalidTypePrimeCoupons: action.payload.invalid_type_coupons,
      };
    case actions.FETCH_SLACK_NOTIFICATIONS: {
      return {
        ...state,
        slackNotificationsLoader: true,
      };
    }
    case actions.FETCH_SLACK_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        slackNotificationsLoader: false,
        slackNotifications: action.payload.slack_notifications,
      };
    }
    case actions.FETCH_SLACK_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        slackNotificationsLoader: false,
      };
    }
    case actions.FETCH_SLACK_CHANNELS_AND_EVENTS: {
      return {
        ...state,
        slackChannelsAndEventsLoader: true,
      };
    }
    case actions.FETCH_SLACK_CHANNELS_AND_EVENTS_SUCCESS: {
      return {
        ...state,
        slackChannels: action.payload.channels,
        slackEvents: action.payload.events,
        slackChannelsAndEventsLoader: false,
      };
    }
    case actions.APPEND_SLACK_CHANNEL: {
      return {
        ...state,
        slackChannels: [...state.slackChannels, action.payload],
      };
    }
    case actions.FETCH_SLACK_CHANNELS_AND_EVENTS_FAILURE: {
      return {
        ...state,
        slackChannelsAndEventsLoader: false,
      };
    }
    case actions.SET_SLACK_NOTIFICATION_ADD_EDIT_FORM_ENABLE: {
      return {
        ...state,
        isAddEditSlackNotificationEnable: action.payload.visible,
        notificationDetails: action.payload.notification,
      };
    }
    case actions.SAVE_SLACK_NOTIFICATION: {
      return {
        ...state,
        notificationLoader: true,
      };
    }
    case actions.SAVE_SLACK_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notificationLoader: false,
        slackNotifications: [...state.slackNotifications, action.payload],
      };
    }
    case actions.SAVE_SLACK_NOTIFICATION_FAILURE: {
      return {
        ...state,
        notificationLoader: false,
      };
    }
    case actions.UPDATE_SLACK_NOTIFICATION: {
      return {
        ...state,
        notificationLoader: true,
      };
    }
    case actions.UPDATE_SLACK_NOTIFICATION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        notificationLoader: false,
        slackNotifications: state.slackNotifications.map((notification) => {
          if (notification.id === payload.id) {
            return payload;
          }
          return notification;
        }),
      };
    }
    case actions.UPDATE_SLACK_NOTIFICATION_FAILURE: {
      return {
        ...state,
        notificationLoader: false,
      };
    }
    case actions.DELETE_SLACK_NOTIFICATION: {
      return {
        ...state,
        slackNotificationsLoader: true,
      };
    }
    case actions.DELETE_SLACK_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        slackNotificationsLoader: false,
        slackNotifications: state.slackNotifications.filter(
          (notification) => notification.id !== action.notificationId,
        ),
      };
    }
    case actions.DELETE_SLACK_NOTIFICATION_FAILURE: {
      return {
        ...state,
        slackNotificationsLoader: false,
      };
    }
    case actions.SET_AUTHENTICATION_TYPE: {
      return {
        ...state,
        authenticationType: action.payload,
      };
    }
    case actions.PUSH_NOTIFICATIONS_DRAWER_VISIBLE: {
      if (!action.payload) {
        return {
          ...state,
          pushNotificationEditDetails: {},
          pushNotificationImageUrl: '',
          mobileAppDrawerVisible: action.payload,
        };
      }
      return {
        ...state,
        mobileAppDrawerVisible: action.payload,
      };
    }
    case actions.SET_CERTIFICATE_DETAILS: {
      return {
        ...state,
        certificateDetails: {
          ...state.certificateDetails,
          [action.authenticationType]: action.file,
        },
      };
    }
    case actions.REMOVE_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificateDetails: {},
      };
    case actions.FETCH_PUSH_NOTIFICATIONS_LIST: {
      return {
        ...state,
        isFetchingPushNotificationsList: true,
      };
    }
    case actions.FETCH_PUSH_NOTIFICATIONS_LIST_SUCCESS: {
      return {
        ...state,
        pushNotificationsList: action.payload,
        isFetchingPushNotificationsList: false,
      };
    }
    case actions.FETCH_PUSH_NOTIFICATIONS_LIST_FAILURE: {
      return {
        ...state,
        isFetchingPushNotificationsList: false,
      };
    }
    case actions.SET_PUSH_NOTIFICATION_EDIT_DETAILS: {
      return {
        ...state,
        pushNotificationEditDetails: action.payload,
        pushNotificationImageUrl: action.payload.certificate,
        authenticationType:
          (action.payload.key_data && action.payload.key_data.auth_type) || '',
        appType: action.payload.app_type,
      };
    }
    case actions.SET_APP_TYPE:
      return {
        ...state,
        appType: action.payload,
      }
    case actions.FETCH_IDENTITY_VERIFICATION: {
      return {
        ...state,
        securityLoader: true,
      };
    }
    case actions.FETCH_IDENTITY_VERIFICATION_SUCCESS:
      return {
        ...state,
        enforceIdentityToken: action.payload.enforce_identity_token || false,
        identityToken: action.payload.identity_token,
        securityLoader: false,
      };
    case actions.FETCH_IDENTITY_VERIFICATION_FAILED:
      return {
        ...state,
        securityLoader: false,
      };
    case actions.UPDATE_IDENTITY_VERIFICATION:
      return {
        ...state,
        enableButtonLoader: true,
      };
    case actions.UPDATE_IDENTITY_VERIFICATION_SUCCESS:
      return {
        ...state,
        enforceIdentityToken: action.payload.enforce_identity_token,
        enableButtonLoader: false,
      };
    case actions.GENERATE_USER_HASH_SUCCESS:
      return {
        ...state,
        userHashValue: action.payload.user_hash,
      };
    case actions.UPDATE_SELECTED_LANGUAGES:
      return {
        ...state,
        selectedLanguages: action.payload,
      };
    default:
      return state;
  }
}
