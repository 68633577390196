import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../store';
import settings from '../../../settings';
import displayActions from '../../displayRules/actions';
import surveyActions from '../../survey/actions';
import surveyOnGoingActions from '../../survey/onGoing/actions';

export function* fetchSurveyTemplateList(params) {
  try {
    let status = '',
      filterCategoryId = '';
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().SurveyTemplateReducer.selectedTemplateStatus;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates?status=${status}`;
    if (params.fromTemplateList) {
      filterCategoryId = store.getState().SurveyTemplateReducer
        .filterCategoryId;
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/survey_templates?status=published&template_category_id=${filterCategoryId}`;
    }
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SURVEY_TEMPLATE_LIST_SUCCESS,
      payload: data.data,
    });
    if (params.fromTemplateList) {
      const templateSearchValue = store.getState().SurveyTemplateReducer
        .templateSearchValue;
      if (templateSearchValue) {
        yield put({
          type: actions.FILTER_SURVEY_TEMPLATE_LIST,
          payload: templateSearchValue,
        });
      }
      yield put({
        type: surveyActions.SET_SURVEY_DEFAULT_TEMPLATE_DETAILS,
        payload: {
          ...data.data.default_content,
        },
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_SURVEY_TEMPLATE_LIST_FAILURE,
    });
    message.error('Fetch survey template list failed');
  }
}

export function* getCategoryList(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates/categories`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SURVEY_CATEGORIES_LISTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch survey category list failed');
  }
}

export function* createSurveyTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_SURVEY_TEMPLATE_ID,
      id: data.data.survey_template.id,
    });
    message.success(data.message);
    updateBrowserHistory(`survey-templates/${data.data.survey_template.id}`);
    yield put({
      type: actions.CREATE_SURVEY_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_SURVEY_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export function* deleteSurveyTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.FETCH_SURVEY_TEMPLATE_LIST,
      status: store.getState().SurveyTemplateReducer.selectedTemplateStatus,
    });
    yield put({
      type: actions.DELETE_SURVEY_TEMPLATE_SUCCESS,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_SURVEY_TEMPLATE_FAILURE,
      payload: false,
    });
    message.error(error.response.data.errors);
  }
}

export function* fetchSurveyTemplateDetails(params) {
  try {
    yield put({ type: surveyOnGoingActions.SET_SURVEY_LOADER, payload: true });
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates/${
      params.surveyTemplateId
    }`;
    const { data } = yield call(() => getPromise(url));
    const { survey } = data.survey_template;
    yield put({
      type: surveyActions.UPDATE_LOCAL_SURVEY_DATA,
      payload: {
        survey: { ...survey.content, id: survey.id, name: survey.name },
      },
      isInitialSurveyLogics: true,
    });
    yield put({
      type: surveyOnGoingActions.GET_SURVEY_DETAILS_SUCCESS,
      payload: { ...survey, content: survey.content },
      surveyTabKey: '1',
    });
    yield put({
      type: actions.SET_SURVEY_TEMPLATE_DETAILS,
      payload: data.survey_template,
    });
    yield put({
      type: displayActions.SET_DISPLAY_RULES,
      payload: data.survey_template.survey.display_conditions,
    });
    yield put({ type: surveyOnGoingActions.SET_SURVEY_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: surveyOnGoingActions.SET_SURVEY_LOADER, payload: false });
    message.error(error.response.data.errors);
  }
}

export function* updateSurveyTemplate(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_SURVEY_TEMPLATE_SUCCESS,
      payload: data.data.survey_template,
      isPublishAction: params.isPublishAction,
    });
    if (params.isFromTemplateTable) {
      yield put({
        type: actions.FETCH_SURVEY_TEMPLATE_LIST,
        status: store.getState().SurveyTemplateReducer.selectedTemplateStatus,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_SURVEY_TEMPLATE_FAILURE,
      changeStatus: params.changeStatus,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Survey template update failed');
    }
  }
}

export function* duplicateSurveyTemplate(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_templates/${
      params.id
    }/duplicate`;
    const data = yield call(() => postPromise(url, params.object));
    yield put({
      type: actions.SET_SURVEY_TEMPLATE_ID,
      id: data.data.survey_template.id,
    });
    message.success(data.message);
    updateBrowserHistory(`survey-templates/${data.data.survey_template.id}`);
    yield put({
      type: actions.DUPLICATE_SURVEY_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DUPLICATE_SURVEY_TEMPLATE_FAILURE,
    });
    message.error(error.response.data.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_SURVEY_TEMPLATE_LIST, fetchSurveyTemplateList),
    takeEvery(actions.GET_SURVEY_CATEGORIES_LISTS, getCategoryList),
    takeEvery(actions.CREATE_SURVEY_TEMPLATE, createSurveyTemplate),
    takeEvery(actions.DELETE_SURVEY_TEMPLATE, deleteSurveyTemplate),
    takeEvery(
      actions.FETCH_SURVEY_TEMPLATE_DETAILS,
      fetchSurveyTemplateDetails,
    ),
    takeEvery(actions.UPDATE_SURVEY_TEMPLATE, updateSurveyTemplate),
    takeEvery(actions.DUPLICATE_SURVEY_TEMPLATE, duplicateSurveyTemplate),
  ]);
}
