const actions = {
  GET_FB_AUDIENCE_LIST: 'GET_FB_AUDIENCE_LIST',
  GET_FB_AUDIENCE_LIST_SUCCESS: 'GET_FB_AUDIENCE_LIST_SUCCESS',
  GET_FB_AUDIENCE_LIST_FAILURE: 'GET_FB_AUDIENCE_LIST_FAILURE',
  CREATE_CUSTOM_AUDIENCE: 'CREATE_CUSTOM_AUDIENCE',
  CREATE_CUSTOM_AUDIENCE_SUCCESS: 'CREATE_CUSTOM_AUDIENCE_SUCCESS',
  CREATE_CUSTOM_AUDIENCE_FAILURE: 'CREATE_CUSTOM_AUDIENCE_FAILURE',
  CREATE_CUSTOM_AUDIENCE_VISIBLE: 'CREATE_CUSTOM_AUDIENCE_VISIBLE',
  GET_FB_CUSTOM_AUDIENCE_LIST: 'GET_FB_CUSTOM_AUDIENCE_LIST',
  RESET_AUDIENCE_LIST: 'RESET_AUDIENCE_LIST',
  FETCH_FORM_CUSTOM_FIELD_LIST: 'FETCH_FORM_CUSTOM_FIELD_LIST',
  FETCH_FORM_CUSTOM_FIELD_LIST_SUCCESS: 'FETCH_FORM_CUSTOM_FIELD_LIST_SUCCESS',
  // CLEAR_FORM_CUSTOM_FIELDS: 'CLEAR_FORM_CUSTOM_FIELDS',  Todo: Need to reset the follow up text editor after disable this, will work on later
  SET_SETTINGS_MAIL_TEXT: 'SET_SETTINGS_MAIL_TEXT',
  FETCH_FORM_TEAMMATE_LIST: 'FETCH_FORM_TEAMMATE_LIST',
  FETCH_FORM_TEAMMATE_LIST_SUCCESS: 'FETCH_FORM_TEAMMATE_LIST_SUCCESS',
  CONFIRMATION_SETTINGS_FORM_UPDATE: 'CONFIRMATION_SETTINGS_FORM_UPDATE',
  CONFIRMATION_SETTINGS_FORM_UPDATE_SUCCESS:
    'CONFIRMATION_SETTINGS_FORM_UPDATE_SUCCESS',
  CONFIRMATION_SETTINGS_FORM_UPDATE_FAILURE:
    'CONFIRMATION_SETTINGS_FORM_UPDATE_FAILURE',
  FETCH_EMAIL_TEMPLATES: 'FETCH_EMAIL_TEMPLATES',
  FETCH_EMAIL_TEMPLATES_SUCCESS: 'FETCH_EMAIL_TEMPLATES_SUCCESS',
  UPDATE_TEMPLATES_FORM_DATA: 'UPDATE_TEMPLATES_FORM_DATA',
  SET_FORM_MAIL_TEXT: 'SET_FORM_MAIL_TEXT',
  SET_FORM_TEMPLATE_DETAILS_SETTINGS: 'SET_FORM_TEMPLATE_DETAILS_SETTINGS',
  SET_FORM_TEMPLATE_DETAILS: 'SET_FORM_TEMPLATE_DETAILS',
  BEE_EDITOR_REFERENCE: 'BEE_EDITOR_REFERENCE',
  SET_MESSAGE_SIDEBAR_ACTIVE: 'SET_MESSAGE_SIDEBAR_ACTIVE',
  SET_SUPPORT_BOT_SIDEBAR_ACTIVE: 'SET_SUPPORT_BOT_SIDEBAR_ACTIVE',
  SET_TABLE_STATE: 'SET_TABLE_STATE',
  SHOW_HIDE_OMNISEARCH: 'SHOW_HIDE_OMNISEARCH',
  UPDATE_OMNISEARCH_STATE: 'UPDATE_OMNISEARCH_STATE',
  SHOW_HIDE_KEYBOARD_SHORTCUTS: 'SHOW_HIDE_KEYBOARD_SHORTCUTS',
  SET_REPLY_EDITOR: 'SET_REPLY_EDITOR',
  SET_NOTE_EDITOR: 'SET_NOTE_EDITOR',
  CHANGE_COMPOSER_UNDO_STATUS: 'CHANGE_COMPOSER_UNDO_STATUS',
  FETCH_GOOGLE_FONTS_SHARED_REDUCER: 'FETCH_GOOGLE_FONTS_SHARED_REDUCER',
  FETCH_GOOGLE_FONTS_SHARED_REDUCER_SUCCESS:
    'FETCH_GOOGLE_FONTS_SHARED_REDUCER_SUCCESS',
  VISUAL_BUILDER_PREVIEW_VISIBLE: 'VISUAL_BUILDER_PREVIEW_VISIBLE',
  SHOW_HIDE_CUSTOM_SNOOZE_MODAL: 'SHOW_HIDE_CUSTOM_SNOOZE_MODAL',
  SET_SELECTED_ARTICLE_LANGUAGE: 'SET_SELECTED_ARTICLE_LANGUAGE',
};

export default actions;
