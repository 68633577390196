import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  unAuthorisedRedirection,
} from '../../helpers/utility';
import settings from '../../settings/index';
import actions from './actions';
import { message } from 'antd';
import authActions from '../../redux/auth/actions';

export function* updateNotification(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/notifications`;
    yield call(() => patchPromise(url, params.payload));
    yield put({
      type: authActions.SET_INBOX_AUDIO_NOTIFICATION,
      payload:
        params.payload.notification_setting.is_audio_notification_enabled,
    });
    yield put({ type: actions.UPDATE_NOTIFICATION_CHANGES_SUCCESS });
    message.success('Updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.UPDATE_NOTIFICATION_CHANGES_FAILURE });
    if (error.response && error.response.status !== 403) {
      message.error('Notification update failed');
    }
  }
}

export function* getNotification() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/notifications`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_NOTIFICATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.GET_NOTIFICATION_DETAILS_FAILURE });
    if (error.response && error.response.status !== 403) {
      message.error('Notification fetch failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_NOTIFICATION_CHANGES, updateNotification),
    takeEvery(actions.GET_NOTIFICATION_DETAILS, getNotification),
  ]);
}
