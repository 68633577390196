import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import chatActions from '../../../redux/chat/actions';
import { message as Message } from 'antd';
import sharedActions from '../../../redux/sharedReducers/actions';
import {
  toneActions,
  translateActions,
} from '../../../containers/App/constants';
import { removeUnderscore } from '../../../helpers/utility';
import JunglePicker from './JunglePicker';

const AIAssistWidget = props => {
  const dispatch = useDispatch();
  const {
    replyEditor,
    noteEditor,
    showNewConversation,
    agentTypedReplyMessages,
    agentTypedNoteMessages,
    aiAssistMode,
    activeConversationId,
    askEmmaMatchFound,
    omniSearchEventListener,
    removeOmniSearchListener,
    tabActiveKey,
  } = props;
  // let message = (tabActiveKey === 'reply'
  //   ? agentTypedReplyMessages[activeConversationId] || ''
  //   : agentTypedNoteMessages[activeConversationId] || ''
  // )
  //   .replace(/&nbsp;/g, ' ')
  //   .trim();

  useEffect(() => {
    props.omniSearchEventListener();

    return () => {
      props.removeOmniSearchListener();
      dispatch({
        type: chatActions.UPDATE_AI_ASSIST_MODE,
        payload: 'default',
      });
    };
  }, []);

  const handleSelect = item => {
    const { action } = item;
    let message = '';
    const currentEditor = tabActiveKey === 'reply' ? replyEditor : noteEditor;
    currentEditor && currentEditor.selection.restore();
    if (showNewConversation && !activeConversationId) {
      replyEditor && replyEditor.selection.restore();
      if (replyEditor && replyEditor.selection.text() !== '') {
        message = replyEditor.selection.text().trim();
      } else {
        message = replyEditor.html
          .get()
          .replace(/(<([^>]+)>)/gi, ' ')
          .replace(/&nbsp;/g, ' ')
          .trim();
      }
    } else if (
      ![
        'quick_summary',
        'detailed_summary',
        'find_action_items',
        'ai_answer',
      ].includes(action) &&
      currentEditor &&
      currentEditor.selection.text() !== ''
    ) {
      message = currentEditor.selection.text().trim();
    } else {
      message = (tabActiveKey === 'reply'
        ? agentTypedReplyMessages[activeConversationId] || ''
        : agentTypedNoteMessages[activeConversationId] || ''
      )
        .replace(/(<([^>]+)>)/gi, ' ')
        .replace(/&nbsp;/g, ' ')
        .trim();
    }

    if (
      !message &&
      ![
        'quick_summary',
        'detailed_summary',
        'find_action_items',
        'ai_answer',
      ].includes(action)
    ) {
      Message.error(`Please enter text to ${removeUnderscore(action)}`);
      dispatch({
        type: sharedActions.SHOW_HIDE_OMNISEARCH,
        payload: false,
      });
      return;
    }

    const aiLoaderActionType =
      tabActiveKey === 'reply'
        ? chatActions.AI_ASSIST_REPLY_LOADER
        : chatActions.AI_ASSIST_NOTE_LOADER;

    if (showNewConversation && !activeConversationId) {
      const element = document.getElementsByClassName(
        `${action === 'change_tone_to' ? item.tone : action}NewMessage`,
      );
      if (element && element[0]) {
        element[0].classList.add('ai-assist-loader');
      }
      let isTranslateOrChangeToneAction = '';

      if (['change_tone', 'translate'].includes(action)) {
        onTranslateAndChangeToneAction(action);
        return;
      }
      if (item.tone || item.lang_code) {
        isTranslateOrChangeToneAction = item.lang_code
          ? 'translate'
          : 'change_tone';
      }
      dispatch({
        type: chatActions.FETCH_AI_ASSIST,
        actionType:
          isTranslateOrChangeToneAction === 'change_tone' ? item.tone : action,
        activeElement: element[0],
        message: message,
        ...(isTranslateOrChangeToneAction === 'translate'
          ? { lang_code: item.lang_code }
          : {}),
        callback: data => {
          replyEditor.selection.restore();
          if (replyEditor.selection.text() !== '') {
            replyEditor.html.insert(data);
          } else {
            replyEditor.html.set(data);
          }
          replyEditor.undo.saveStep();
        },
      });
      dispatch({
        type: sharedActions.SHOW_HIDE_OMNISEARCH,
        payload: false,
      });
      return;
    }

    switch (action) {
      case 'quick_summary':
      case 'detailed_summary':
      case 'find_action_items':
      case 'continue_writing':
        dispatch({
          type: chatActions.FETCH_CONVERSATION_COMPLETION,
          tabActiveKey,
          message,
          completionType: action,
          conversationId: activeConversationId,
          isOmniSearchSelect:
            currentEditor.selection.text() !== '' &&
            action === 'continue_writing',
        });
        break;
      case 'ai_answer':
        dispatch({
          type: chatActions.UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY,
          payload: { visible: true, mode: 'ai_answer' },
        });
        dispatch({
          type: chatActions.AI_ASSIST_CUSTOM_ANSWER_LOADER,
          payload: true,
          conversationId: activeConversationId,
        });
        dispatch({
          type: chatActions.FETCH_AI_ANSWER,
          conversationId: activeConversationId,
        });
        break;
      case 'change_tone':
      case 'translate':
        onTranslateAndChangeToneAction(action);
        break;
      case 'change_tone_to':
        dispatch({
          type: chatActions.FETCH_AI_ASSIST,
          actionType: item.tone,
          tabActiveKey,
          message,
          isFromOmniSearch: true,
          isOmniSearchSelect: currentEditor.selection.text() !== '',
          conversationId: activeConversationId,
        });
        break;
      case 'translate_to':
        dispatch({
          type: chatActions.FETCH_AI_ASSIST,
          actionType: action,
          tabActiveKey,
          message,
          isFromOmniSearch: true,
          isOmniSearchSelect: currentEditor.selection.text() !== '',
          conversationId: activeConversationId,
          lang_code: item.lang_code,
        });
        break;
      case 'continue':
      case 'improve':
      case 'fix_grammar':
      case 'make_shorter':
      case 'expand':
      case 'rephrase':
      default:
        dispatch({
          type: aiLoaderActionType,
          payload: true,
          conversationId: activeConversationId,
        });
        dispatch({
          type: chatActions.FETCH_AI_ASSIST,
          actionType: action,
          tabActiveKey,
          message,
          isFromOmniSearch: true,
          isOmniSearchSelect: currentEditor.selection.text() !== '',
          conversationId: activeConversationId,
        });
        break;
    }
    if (
      !['change_tone', 'translate'].includes(action) ||
      item.tone ||
      item.lang_code
    ) {
      if (action !== 'ai_answer') {
        dispatch({
          type: aiLoaderActionType,
          payload: true,
          conversationId: activeConversationId,
        });
      }
      dispatch({
        type: sharedActions.SHOW_HIDE_OMNISEARCH,
        payload: false,
      });
    }
  };
  const getCommandItem = item => (
    <div className="command-item">
      <div className="command-item-inner">
        <span className="command-content">
          <span className="command-title">
            {item.icon && <i className={item.icon} />} {item.name}
          </span>
        </span>
      </div>
    </div>
  );

  const onTranslateAndChangeToneAction = action => {
    dispatch({
      type: chatActions.UPDATE_AI_ASSIST_MODE,
      payload: action,
    });
  };
  const getGroupItem = (group, items) =>
    items.length > 0 &&
    group.id !== 'nogroup' && (
      <div>
        <div className="list-heading-container">
          <span className="list-heading">{group.label}</span>
        </div>
      </div>
    );

  const AiAssistGroups = [
    {
      id: 0,
      label: 'Write with AI',
    },
    {
      id: 1,
      label: 'Generate from conversation',
    },
    {
      id: 2,
      label: 'Edit or review message',
    },
  ];

  const AiAssistActions = [
    ...(!(showNewConversation && !activeConversationId) &&
    askEmmaMatchFound[activeConversationId] &&
    askEmmaMatchFound[activeConversationId].matchFound
      ? [
          {
            groupId: 0,
            name: 'Generate AI Answer',
            action: 'ai_answer',
            icon: 'fal fa-magic',
          },
        ]
      : []),
    {
      groupId: 0,
      name: 'Expand',
      action: 'expand',
      icon: 'fal fa-paint-brush',
    },
    {
      groupId: 0,
      name: 'Rephrase',
      action: 'rephrase',
      icon: 'fal fa-pencil',
    },
    ...(!(showNewConversation && !activeConversationId)
      ? [
          {
            groupId: 1,
            name: 'Quick summary',
            action: 'quick_summary',
            icon: 'fal fa-file-contract',
          },
          {
            groupId: 1,
            name: 'Detailed summary',
            action: 'detailed_summary',
            icon: 'fal fa-file-alt',
          },
          {
            groupId: 1,
            name: 'Find action items',
            action: 'find_action_items',
            icon: 'fal fa-list',
          },
          {
            groupId: 1,
            name: 'Continue writing',
            action: 'continue_writing',
            icon: 'fal fa-pen-alt',
          },
        ]
      : []),
    {
      groupId: 2,
      name: 'Improve writing',
      action: 'improve',
      icon: 'fal fa-magic',
    },
    {
      groupId: 2,
      name: 'Simplify language',
      action: 'simplify_language',
      icon: 'fal fa-sitemap',
    },
    {
      groupId: 2,
      name: 'Fix spelling & grammar',
      action: 'fix_grammar',
      icon: 'fal fa-check',
    },
    {
      groupId: 2,
      name: 'Make shorter',
      action: 'make_shorter',
      icon: 'fal fa-grip-lines',
    },
    {
      groupId: 2,
      name: 'Change tone',
      action: 'change_tone',
      icon: 'fal fa-microphone-stand',
    },
    {
      groupId: 2,
      name: 'Translate',
      action: 'translate',
      icon: 'fal fa-language',
    },
  ];

  const baseJungleSelectProps = {
    searchable: true,
    searchableAttributes: ['name'],
    listWrapper: list => <div className="command-list">{list}</div>,
    onChange: value => handleSelect(value),
    mode: 'list',
    selectFirstItem: true,
    autofocus: true,
  };
  const defaultJungleSelectProps = {
    items: AiAssistActions,
    renderItem: item => getCommandItem(item),
    groups: AiAssistGroups,
    renderGroup: (group, items) => getGroupItem(group, items),
    placeholder: 'Search commands...',
  };
  const changetoneJungleSelectProps = {
    items: toneActions,
    renderItem: item => getCommandItem(item),
    placeholder: 'Search tones...',
  };
  const translateJungleSelectProps = {
    items: translateActions,
    renderItem: item => getCommandItem(item),
    placeholder: 'Search languages...',
  };
  return (
    <>
      {aiAssistMode === 'default' && (
        <JunglePicker
          jungleSelectProps={defaultJungleSelectProps}
          baseJungleSelectProps={baseJungleSelectProps}
          omniSearchEventListener={omniSearchEventListener}
          removeOmniSearchListener={removeOmniSearchListener}
        />
      )}
      {aiAssistMode === 'change_tone' && (
        <JunglePicker
          jungleSelectProps={changetoneJungleSelectProps}
          baseJungleSelectProps={baseJungleSelectProps}
          omniSearchEventListener={omniSearchEventListener}
          removeOmniSearchListener={removeOmniSearchListener}
        />
      )}
      {aiAssistMode === 'translate' && (
        <JunglePicker
          jungleSelectProps={translateJungleSelectProps}
          baseJungleSelectProps={baseJungleSelectProps}
          omniSearchEventListener={omniSearchEventListener}
          removeOmniSearchListener={removeOmniSearchListener}
        />
      )}
    </>
  );
};

export default AIAssistWidget;
