import { store } from '../../redux/store';
import authActions from '../../redux/auth/actions';
import settings from '../../settings';

export const analyticsScripts = () => {
  const { isAnalyticsScriptInstalled } = store.getState().Auth;
  if (!isAnalyticsScriptInstalled) {
    // Start Beamer Code
    window.beamer_config = {
      product_id: settings.BEAMER_ID, //DO NOT CHANGE: This is your product code on Beamer
      selector: '.gist-updates-tigger',
      mobile: false,
    };
    let beamerScript = document.createElement('script');
    beamerScript.type = 'text/javascript';
    beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    beamerScript.defer = 'defer';
    document.body.appendChild(beamerScript);
    // End Beamer Code

    // Userback script start
    window.Userback = window.Userback || {};
    window.Userback.access_token = settings.USER_BACK_TOKEN;
    // Userback script end

    // Scripts updated on reducer
    store.dispatch({
      type: authActions.UPDATE_ANALYTICS_TRACK_SCRIPTS,
      payload: true,
    });
  }
};
