import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import PublicRoutes from './router';
import Boot from './redux/boot';
import { message } from 'antd';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import Settings from './settings';

window.bugsnagClient = bugsnag({
  apiKey: Settings.BUG_SNAG_KEY,
  notifyReleaseStages: ['production'],
});
window.bugsnagClient.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = window.bugsnagClient.getPlugin('react');

message.config({
  duration: 2,
  maxCount: 1,
});

const DashApp = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PublicRoutes history={history} />
    </Provider>
  </ErrorBoundary>
);
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
