import React from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class ConversationNote extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className="header">
          <h3>
            <span>
              <i className="fas fa-pencil" />
            </span>
            Conversation note
          </h3>
        </div>
        <div className="body">
          <div>
            <div className="message-text-component note" data-port-id={data.id}>
              <jtk-source port-id={data.id} endpoint={true} />
              <InputTextArea id={data.id} value={data.message_content} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
