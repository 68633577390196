import React from 'react';
import { Popover, Upload } from 'antd';
import { fileType, imageFileType } from './constants';

const FileUpload = props => (
  <>
    <Upload
      customRequest={file => props.customRequest(file.file)}
      showUploadList={false}
      accept={fileType}
      multiple={true}
      className="upload-attachment"
    >
      <Popover
        content={
          <>
            <span>Insert attachment</span>
            <span className="shortcut-indicator">
              <span className="shortcut-key">⌘</span>
              <span className="shortcut-key">⇧</span>
              <span className="shortcut-key">a</span>
            </span>
          </>
        }
        overlayClassName="minimal-popover"
        trigger="hover"
      >
        <i className={'fal fa-paperclip'} />
      </Popover>
    </Upload>

    <Upload
      customRequest={file => props.customRequest(file.file)}
      showUploadList={false}
      accept={imageFileType}
      multiple={true}
      className="upload-image"
    >
      <Popover
        content={
          <>
            <span>Insert image</span>
            <span className="shortcut-indicator">
              <span className="shortcut-key">⌘</span>
              <span className="shortcut-key">⇧</span>
              <span className="shortcut-key">i</span>
            </span>
          </>
        }
        overlayClassName="minimal-popover"
        trigger="hover"
      >
        <i className={'fal fa-image'} />
      </Popover>
    </Upload>
  </>
);

export default FileUpload;
