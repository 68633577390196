import actions from './actions';
import { cloneDeep } from 'lodash';

export const initState = {
  selectedTourData: {},
  initialTourData: {},
  settingsPanelData: {},
  isTourSettingsPanelVisible: false,
  tourEditorTabKey: '1',
  tourOnGoingList: [],
  selectedTableData: [],
  list: {},
  tourOnGoingTableLoader: false,
  sortKey: 'priority',
  sortOrder: 'asc',
  searchValue: '',
  tourOnGoingTagId: '',
  tourOnGoingStatus: 'all',
  tourOnGoingScheduleTime: false,
  tourDetails: {},
  initialTourDetails: {},
  scheduleDetails: {},
  isReOrderTour: false,
  tourLoader: false,
  statusLoader: false,
  createTourDrawer: false,
  createTourLoader: false,
  openTagApplyModal: false,
  openSuggestion: false,
  setTourName: '',
  setTourDuplicateId: '',
  openConfirmPopup: false,
  showDrawerFrom: 'on_going_tour',
  activeTourName: '',
  isSelectChannelModalVisible: false,
  messageTagsListLastId: '',
  messageTagsListPage: 1,
  currentMessageTag: { id: '' },
  highlightedActionData: {},
  linkModeData: {
    enabled: false,
    actionType: null,
    actionListKey: null,
    originStepKey: null,
    targetStepKey: null,
  },
  isTourExtensionEnabled: false,
  videoUploadLoader: false,
  recentUrls: [],
};

export default function ToursReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TOUR_TABLE_LISTS:
      return {
        ...state,
        tourOnGoingTableLoader: true,
      };
    case actions.FETCH_TOUR_TABLE_LISTS_SUCCESS:
      return {
        ...state,
        tourOnGoingTableLoader: false,
        tourOnGoingList: action.payload.tours,
        list: action.payload.tours_count,
      };
    case actions.FETCH_TOUR_TABLE_LISTS_FAILURE:
      return {
        ...state,
        tourOnGoingTableLoader: false,
      };
    case actions.APPEND_TABLE_LISTS_IN_TOUR:
      return {
        ...state,
        tourOnGoingTableLoader: true,
      };
    case actions.APPEND_TABLE_LISTS_IN_TOUR_SUCCESS:
      let appendTableLists = [],
        appendCounts = [];
      if (action.payload) {
        appendTableLists =
          action.payload.tours && action.payload.tours.length
            ? action.payload.tours
            : [];
        appendCounts =
          action.payload.tours_count && action.payload.tours_count.length
            ? action.payload.tours_count
            : state.list;
      }
      return {
        ...state,
        tourOnGoingList: appendTableLists.length
          ? state.tourOnGoingList.concat(appendTableLists)
          : state.tourOnGoingList,
        list: appendCounts.length ? appendCounts : state.list,
        tourOnGoingTableLoader: false,
      };
    case actions.APPEND_TABLE_LISTS_IN_TOUR_FAILURE:
      return {
        ...state,
        tourOnGoingTableLoader: false,
      };
    case actions.CREATE_TOUR_DRAWER:
      return {
        ...state,
        createTourDrawer: action.payload,
        setTourName: action.name ? `${action.name}(Copy)` : '',
        setTourDuplicateId: action.id ? action.id : '',
        showDrawerFrom: action.requestFrom,
        selectedTemplateId: action.templateId,
      };
    case actions.CREATE_TOUR:
      return {
        ...state,
        createTourLoader: true,
      };
    case actions.CREATE_TOUR_SUCCESS:
      return {
        ...state,
        createTourLoader: false,
        createTourDrawer: false,
      };
    case actions.CREATE_TOUR_FAILURE:
      return {
        ...state,
        createTourLoader: false,
      };
    case actions.DUPLICATE_TOUR:
      return {
        ...state,
        createTourLoader: true,
      };
    case actions.DUPLICATE_TOUR_SUCCESS:
      return {
        ...state,
        createTourLoader: false,
        createTourDrawer: false,
      };
    case actions.DUPLICATE_TOUR_FAILURE:
      return {
        ...state,
        createTourLoader: false,
      };
    case actions.FETCH_TOUR_DETAILS_SUCCESS:
      return {
        ...state,
        tourDetails: action.payload,
        initialTourDetails: action.payload,
        tourOnGoingScheduleTime:
          action.payload &&
          action.payload.schedule_details &&
          Object.keys(action.payload.schedule_details).length
            ? true
            : false,
        scheduleDetails:
          action.payload && action.payload.schedule_details
            ? action.payload.schedule_details
            : {},
        tourEditorTabKey: action.tourTabKey
          ? action.tourTabKey
          : state.tourEditorTabKey,
        recentUrls: action.recentUrls,
      };
    case actions.UPDATE_TABLE_ELEMENT_STATUS_IN_TOUR:
      return {
        ...state,
        tableLoading: true,
      };
    case actions.UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_TOUR:
      return {
        ...state,
        tableLoading: false,
      };
    case actions.TOUR_SAVE_BUTTON_LOADER_STATUS:
      return {
        ...state,
        tourSaveButtonLoader: action.payload,
      };
    case actions.SET_CONFIRM_MODEL_IN_TOUR:
      return {
        ...state,
        openConfirmPopup: action.payload,
      };
    case actions.UPDATE_SELECTED_TOUR_DATA:
      return {
        ...state,
        selectedTourData: action.payload,
        initialTourData: action.isFromInitialTourData
          ? action.payload
          : state.initialTourData,
      };
    case actions.SET_SETTINGS_PANEL_VISIBILITY:
      return {
        ...state,
        isTourSettingsPanelVisible: action.payload,
      };
    case actions.SET_SETTINGS_PANEL_DATA:
      return {
        ...state,
        settingsPanelData: action.payload,
      };
    case actions.SET_TOUR_EDITOR_TAB_KEY:
      return {
        ...state,
        tourEditorTabKey: action.payload,
      };
    case actions.SET_TOUR_LOADER:
      return {
        ...state,
        tourLoader: action.payload,
      };
    case actions.SET_TOUR_TABLE_DATA:
      return {
        ...state,
        tourOnGoingList: action.payload,
      };
    case actions.SET_TOUR_ONGOING_STATUS:
      return {
        ...state,
        tourOnGoingStatus: action.payload,
      };
    case actions.SET_TOUR_ONGOING_SELECTED_ROWS:
      return {
        ...state,
        selectedTableData: action.selectedRowKeys,
      };
    case actions.SET_TOUR_ONGOING_SORT_VALUES:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    case actions.SET_TOUR_ONGOING_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.REORDER_TOURS_LIST:
      return {
        ...state,
        isReOrderTour: action.payload,
      };
    case actions.SET_TOUR_NAME: {
      return {
        ...state,
        tourDetails: {
          ...state.tourDetails,
          name: action.payload,
        },
      };
    }
    case actions.UPDATE_TOUR_PORT_HIGHLIGHT_DATA: {
      return {
        ...state,
        highlightedActionData: action.payload,
      };
    }
    case actions.UPDATE_TOUR_LINK_MODE_DATA: {
      return {
        ...state,
        linkModeData: action.payload,
      };
    }
    case actions.UPDATE_TOUR_SAVED_DETAILS:
      return {
        ...state,
        initialTourData: state.selectedTourData,
        initialTourDetails: action.payload,
        tourDetails: action.payload,
      };
    case actions.UPDATE_TOUR_STATUS: {
      return {
        ...state,
        statusLoader: true,
        tourDetails: {
          ...state.tourDetails,
          status:
            state.tourDetails.has_schedule &&
            action.payload.tour.status === 'live'
              ? 'scheduled'
              : action.payload.tour.status,
        },
      };
    }
    case actions.UPDATE_TOUR_STATUS_SUCCESS: {
      return {
        ...state,
        statusLoader: false,
      };
    }
    case actions.UPDATE_TOUR_STATUS_FAILURE: {
      return {
        ...state,
        statusLoader: false,
        tourDetails: {
          ...state.tourDetails,
          status: action.payload === 'live' ? 'paused' : 'live',
        },
      };
    }
    case actions.UPDATE_TOUR_SCHEDULE_DETAILS: {
      return {
        ...state,
        statusLoader: false,
        tourDetails: {
          ...state.tourDetails,
          ...action.payload,
        },
      };
    }
    case actions.TOUR_EXTENSION_ENABLED_STATUS:
      return {
        ...state,
        isTourExtensionEnabled: action.payload,
      };
    case actions.TOUR_VIDEO_FILE_ATTACHMENT:
      return {
        ...state,
        videoUploadLoader: true,
      };
    case actions.TOUR_VIDEO_FILE_ATTACHMENT_SUCCESS:
      let getTourData = cloneDeep(state.selectedTourData);
      for (let step of getTourData.steps) {
        if (step.id === action.stepId) {
          step[action.isForSnapShot ? 'thumbnail' : 'video_url'] = action.payload;
        }
      }
      return {
        ...state,
        selectedTourData: getTourData,
        videoUploadLoader: false,
      };
    case actions.TOUR_VIDEO_FILE_ATTACHMENT_FAILURE:
      return {
        ...state,
        videoUploadLoader: false,
      };
    case actions.REMOVE_DELETED_ONGOING_TOUR_ID:
      let selectedRowKeys = state.selectedTableData;
      if (selectedRowKeys && selectedRowKeys.length) {
        selectedRowKeys = selectedRowKeys.filter(
          id => id !== action.deletedTourId,
        );
      }
      return {
        ...state,
        selectedTableData: selectedRowKeys
          ? selectedRowKeys
          : state.selectedTableData,
      };
    case actions.ADD_TOUR_LAUNCH_URL_SUCCESS:
      let getUrlList = state.recentUrls;
      if (!getUrlList.includes(action.payload)) {
        getUrlList.unshift(action.payload);
      } else if (getUrlList[0] !== action.payload) {
        let getUrlIndex = getUrlList.findIndex(url => url === action.payload);
        getUrlList.splice(getUrlIndex, 1);
        getUrlList.unshift(action.payload);
      }
      return {
        ...state,
        recentUrls: getUrlList,
      };
    default:
      return state;
  }
}
