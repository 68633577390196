import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import contactActions from '../contacts/actions';
import { message } from 'antd';
import settings from '../../settings/index';
import {
  deletePromise,
  extractConversationIdentifier,
  extractCurrentPersonId,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  scrollToBottom,
  getlastMessage,
  activateChatRoom,
  assignFilters,
  assignSorter,
  scrollToPosition,
  updateBrowserHistory,
  unAuthorisedRedirection,
  fetchAttachmentQueues,
  conversationStatusChangeHandler,
  updateMessage,
  userList,
  fetchActiveUserPropertyTabs,
  isLiveViewRestricted,
  canWeAccess,
  removeConversation,
  getConversationStatusMessage,
  extractCurrentUserId,
  updateTitle,
  extractConversationId,
  trackEvent,
  unProcessableEntity, extractConversationIdentifierFromUrl,
} from '../../helpers/utility';
import { gifOffset } from '../../components/Chat/Messages/constants';
import { store } from '../store';
import settingsActions from '../settings/actions';
import { userRestriction } from '../../components/TopNavBar/constants';
import appsActions from '../apps/actions';
import sharedActions from '../../redux/sharedReducers/actions';
import chatActions from './actions';
import { serverErrorCodes } from '../constants';

export function* fetchConversations(params) {
  try {
    let status = '',
      searchQuery = '',
      lastConversationIdentifier = '',
      filters = '',
      sort = '',
      conversationIdentifier = '',
      pageNo = '',
      assigned_to = '',
      tagFilter = '',
      participantId = '',
      isFromSearchView = false,
      startedDate = '',
      endDate = '',
      contacts = '',
      channel = '';
    if (params.startedDate) {
      startedDate = params.startedDate;
    }
    if (params.channel) {
      channel = params.channel;
    }
    if (params.contacts) {
      contacts = params.contacts;
    }
    if (params.endDate) {
      endDate = params.endDate;
    }
    if (params.isFromSearchView) {
      isFromSearchView = params.isFromSearchView;
    }
    if (params.participant_ids) {
      participantId = params.participant_ids;
    }
    if (params.assignedTo) {
      assigned_to = params.assignedTo;
    }
    if (params.tagFilter) {
      tagFilter = params.tagFilter;
    }
    if (params.status) {
      status =
        (params.filter || store.getState().Chat.inboxFilter) === 'IB5'
          ? 'open'
          : params.status;
    }
    if (params.searchTag && params.searchTag !== 'view=table') {
      store.dispatch({
        type: actions.SET_CONVERSATION_SEARCH_TEXT,
        payload: params.searchTag.split('=')[1],
      });
    }
    if (params.searchQuery) {
      searchQuery = params.searchQuery;
    } else {
      searchQuery = store.getState().Chat.conversationSearchText;
    }
    if (params.lastConversationIdentifier) {
      lastConversationIdentifier = params.lastConversationIdentifier;
    } else {
      yield put({
        type: actions.ASSIGN_CONVERSATION_SUCCESS,
        currentUserId: store.getState().Auth.currentUserId,
      });
    }
    const { selectedConversations, inboxFilter } = store.getState().Chat;
    if (selectedConversations.length && params.filter !== inboxFilter) {
      yield put({ type: actions.SET_SELECT_ALL_CONVERSATION, checkAll: false });
    }
    if (params.filter || store.getState().Chat.inboxFilter) {
      let key = params.filter || store.getState().Chat.inboxFilter;
      filters = assignFilters(key, filters);
    }
    if (params.sort) {
      sort = assignSorter(params, sort);
    }
    if (params.conversationIdentifier) {
      conversationIdentifier = params.conversationIdentifier;
      status = '';
    }
    if (params.pageNo && params.filter.includes('custom-inbox-view')) {
      pageNo = params.pageNo;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations.json?conversation_status=${status}&search_text=${encodeURIComponent(
      searchQuery,
    )}&last_conversation_identifier=${lastConversationIdentifier}${filters}${sort}&conversation_identifier=${conversationIdentifier}&page=${pageNo}&assigned_to=${assigned_to}&tag_filter=${tagFilter}&participant_ids=${participantId}&started_date=${startedDate}&end_date=${endDate}&channel=${channel}&contacts=${encodeURIComponent(
      contacts,
    )}&inbox_es=${isFromSearchView}`;
    if (lastConversationIdentifier === '' && !params.avoidLoading) {
      yield put({ type: actions.LOADER_FOR_CHAT_ROOMS, payload: true });
    }
    const data = yield call(() => getPromise(url));
    if (params.emptyChatRoom) yield put({ type: actions.EMPTY_CHAT_ROOM });
    if (params.filter || params.status || params.sort) {
      yield put({
        type: actions.SET_FILTER,
        payload: {
          inbox: params.filter,
          status: params.status,
          sort: params.sort,
        },
      });
    }
    if (params.newConversation) {
      yield put({
        type: actions.TOGGLE_NEW_CONVERSATION_SCREEN,
        payload: true,
      });
      yield put({
        type: actions.LOADER_FOR_CHAT_HISTORY,
        payload: { cid: conversationIdentifier, status: false },
      });
      yield put({ type: actions.LOADER_FOR_USER_PROFILE, payload: false });
    }
    if (
      data.data.conversations.length !== 0 &&
      !['search', 'table'].includes(conversationIdentifier) &&
      params.searchTag !== 'view=table'
    ) {
      // TODO Jitta - check this
      if (params.chatRoomActivation) activateChatRoom(params, data);
    } else {
      yield put({ type: actions.SET_CLEAR_ALL });
      yield put({
        type: actions.LOADER_FOR_CHAT_HISTORY,
        payload: { cid: conversationIdentifier, status: false },
      });
      yield put({ type: actions.LOADER_FOR_USER_PROFILE, payload: false });
      if (
        store.getState().Chat.chatRooms.length === 0 &&
        conversationIdentifier === 'search'
      ) {
        updateBrowserHistory('conversations/search');
      } else if (
        store.getState().Chat.chatRooms.length === 0 &&
        conversationIdentifier === 'table'
      ) {
        updateBrowserHistory('conversations/table');
      } else if (
        store.getState().Chat.chatRooms.length === 0 &&
        params.searchTag === 'view=table'
      ) {
        updateBrowserHistory(
          `conversations/${conversationIdentifier}?view=table`,
        );
      } else if (store.getState().Chat.chatRooms.length === 0) {
        updateBrowserHistory('conversations/latest');
      }
    }
    const { teams, teamMates } = store.getState().Chat;
    if (!teamMates.length && !teams.length) {
      yield put({
        type: actions.FETCH_ASSIGNEE_LIST,
        payload: data.data.conversation_count,
        isFromCreateUpdateCustomView: params.isFromCreateUpdateCustomView,
      });
    } else {
      const inboxRoles = canWeAccess(userRestriction.inbox);
      const canManageUnassignedInbox = canWeAccess(
        userRestriction.UnassignedInbox,
      );
      const currentUserId = extractCurrentUserId();
      yield put({
        type: actions.SET_CONVERSATION_INBOX_COUNT,
        payload: userList(
          data.data.conversation_count,
          currentUserId,
          inboxRoles,
          canManageUnassignedInbox,
          teams,
        ),
        currentUserId,
        inboxRoles,
        canManageUnassignedInbox,
        assignedTeams: teams,
      });
    }
    const isAllowedLiveView = !isLiveViewRestricted(); // live view restriction status
    yield put({
      type: actions.FETCH_CONVERSATIONS_SUCCESS,
      payload: data,
      project_secret: fetchProjectSecretKey(),
      isAllowedLiveView,
      inbox_search_view_filters: {
        started_date: startedDate,
        end_date: endDate,
        participant_id: participantId,
        assigned_to_filter: assigned_to,
        tag_filter: tagFilter,
        contacts_filter: contacts,
        channel_filter: channel,
      },
    });
    yield put({ type: actions.LOADER_FOR_CHAT_ROOMS, payload: false });
    if (store.getState().Chat.selectAllConversations) {
      yield put({ type: actions.SET_SELECT_ALL_CONVERSATION, checkAll: true });
    }
    if (!data.data.event_tracked) {
      store.dispatch({ type: settingsActions.GET_SHARED_TEAM_EMAIL });
      store.dispatch({
        type: appsActions.GET_APPS_LIST,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Conversations fetch failed');
    }
    yield put({ type: actions.LOADER_FOR_CHAT_ROOMS, payload: false });
    yield put({ type: actions.FETCH_CONVERSATIONS_FAILURE });
    if (error.response && error.response.message === 'Elastic Search Timeout') {
      yield put({
        type: actions.LAST_CONVERSATION_ID,
        payload: null,
      });
      window.bugsnagClient.notify(error);
    }
  }
}

export function* fetchCurrentConversationMessages(params) {
  try {
    const conversationsMessageData =
      store.getState().Chat.conversationsMessageData;
    let data,
      oldMessageId = params.oldMessageId ? params.oldMessageId : '',
      oldScrollHeight = 0;
    const currentChatBody = 'messageBody';

    // Check if Conversation Messages already exist in reducer
    if (oldMessageId === '' && conversationsMessageData[params.cid]) {
      data = { data: conversationsMessageData[params.cid] };
    } else {
      const messageBodyElement = document.getElementById(currentChatBody);
      if (messageBodyElement) {
        oldScrollHeight = messageBodyElement.scrollHeight;
      }

      if (oldMessageId === '' && !params.avoidLoading) {
        yield put({
          type: actions.LOADER_FOR_CHAT_HISTORY,
          payload: { cid: params.cid, status: true },
        });
      }

      let url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/conversations/${
        params.cid
      }.json?id=${oldMessageId}`;
      data = yield call(() => getPromise(url));
    }

    if (data.data && data.data.messages.length) {
      // Mark the conversation in Inbox as seen. Doesn't relate to Messenger end seen status.
      let lastMessage = getlastMessage(data.data.messages);
      if (lastMessage.index >= 0 && params.unread_count > 0) {
        lastMessage.statusData.status = 'seen';
        yield put({
          type: actions.UPDATE_MESSAGE_STATUS,
          payload: lastMessage.statusData,
        });
      }

      // Update conversationMessageData in reducer by formating the "internal_display_content" key as needed
      data.data.messages.map((message) => {
        const internalDisplayContent = getConversationStatusMessage(message);
        if (internalDisplayContent) {
          message.internal_display_content = internalDisplayContent;
        }

        return message;
      });

      // If messages are being fetched the first time
      if (oldMessageId === '') {
        yield put({
          type: actions.FETCH_MESSAGES_SUCCESS,
          payload: data,
          currentUserId: store.getState().Auth.currentUserId,
        });
        setTimeout(() => {
          scrollToBottom(document.getElementById(currentChatBody));
        }, 0);
        // const personId = store.getState().Chat.currentPersonId;
        // if (fetchActiveUserPropertyTabs()) {
        //   yield put({
        //     type: actions.GET_USER_PROPERTIES,
        //     userId: data.data.messages[0].person_id
        //       ? data.data.messages[0].person_id
        //       : personId,
        //   });
        // }
        // // Need to get the count for notes
        // store.dispatch({
        //   type: contactActions.FETCH_CONTACT_NOTES,
        //   personID: data.data.messages[0].person_id
        //     ? data.data.messages[0].person_id
        //     : personId,
        // });
        // // TODO Handle the active tab
        // store.dispatch({
        //   type: contactActions.GET_CONTACT_MEETINGS,
        //   personID: data.data.messages[0].person_id
        //     ? data.data.messages[0].person_id
        //     : personId,
        // });
        // store.dispatch({
        //   type: contactActions.GET_CONTACT_EMAIL_LIST,
        //   personID: data.data.messages[0].person_id
        //     ? data.data.messages[0].person_id
        //     : personId,
        // });
      } else {
        yield put({
          type: actions.PREPEND_MESSAGES,
          payload: data,
        });
        scrollToPosition(
          document.getElementById(currentChatBody),
          oldScrollHeight,
        );
      }
    }

    yield put({
      type: actions.LOADER_FOR_CHAT_HISTORY,
      payload: { cid: params.cid, status: false },
    });
    yield put({
      type: actions.LOADER_FOR_CHAT_INFINITE_SCROLL,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Messages fetch failed');
    }
    yield put({
      type: actions.LOADER_FOR_CHAT_HISTORY,
      payload: { cid: params.cid, status: false },
    });
  }
}

export function* fetchCurrentPersonProfile(params) {
  try {
    const userProfileData = store.getState().Chat.userProfileData;
    let data;
    if (userProfileData[params.pid]) {
      data = { data: userProfileData[params.pid] };
      if (data.data && data.data.profile) {
        yield put({
          type: actions.FETCH_PROFILE_SUCCESS,
          payload: data,
        });
      }
    }

    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/people/${
      params.pid
    }/person_details`;
    if (!params.avoidLoading && !userProfileData[params.pid]) {
      yield put({ type: actions.LOADER_FOR_USER_PROFILE, payload: true });
    }
    data = yield call(() => getPromise(url));
    if (data.data && data.data.profile) {
      yield put({
        type: actions.FETCH_PROFILE_SUCCESS,
        payload: data,
      });
    }
    if (params.showId) {
      if (fetchActiveUserPropertyTabs()) {
        yield put({
          type: actions.GET_USER_PROPERTIES,
          userId: params.pid,
        });
      }
      // Need to get the count for notes
      store.dispatch({
        type: contactActions.FETCH_CONTACT_NOTES,
        personID: params.pid,
      });
      // TODO Handle the active tab
      store.dispatch({
        type: contactActions.GET_CONTACT_MEETINGS,
        personID: params.pid,
      });
      store.dispatch({
        type: contactActions.GET_CONTACT_EMAIL_LIST,
        personID: params.pid,
      });
    }
    yield put({ type: actions.LOADER_FOR_USER_PROFILE, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Profile fetch failed');
    }
    yield put({ type: actions.FETCH_PROFILE_FAILURE });
    yield put({ type: actions.LOADER_FOR_USER_PROFILE, payload: false });
  }
}

export function* postReply(params) {
  const { replyMessageData, attachmentQueue, messageStatus } = params,
    psk = fetchProjectSecretKey(),
    cid = params.cid;
  if (!attachmentQueue.length && messageStatus === 'closed') {
    replyMessageData['change_status_to'] = 'closed';
  } else if (!attachmentQueue.length && messageStatus === 'pending') {
    replyMessageData['change_status_to'] = 'pending';
    replyMessageData['time_key'] = store.getState().Chat.timeKey;
    replyMessageData['time_value'] = store.getState().Chat.timeValue;
  }
  try {
    const url = `${settings.ROOT_URL}projects/${psk}/conversations/${cid}/message/user.json`;
    const data = yield call(() => postPromise(url, replyMessageData));
    updateMessage(data.data);
    yield put({
      type: actions.POST_SUCCESS,
      payload: cid,
      isReply: true,
    });
    const currentChatBody = 'messageBody';
    scrollToBottom(document.getElementById(currentChatBody));
    // Sending reply attachments or images
    if (attachmentQueue.length) {
      store.dispatch({
        type: actions.POST_ATTACHMENTS,
        payload: { attachmentData: attachmentQueue[0], cid },
        messageStatus,
      });
      store.dispatch({
        type: actions.DEQUEUE_REPLY_ATTACHMENT,
        payload: { cid },
      });
    } else {
      store.dispatch({
        type: actions.REPLY_LOADER,
        payload: { status: false, cid },
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Message post failed');
    }
    store.dispatch({
      type: actions.REPLY_LOADER,
      payload: { status: false, cid },
    });
  }
}

export function* postNote(params) {
  const { noteData, attachmentQueue } = params,
    psk = fetchProjectSecretKey(),
    cid = params.cid;
  try {
    const url = `${settings.ROOT_URL}projects/${psk}/conversations/${cid}/message/agent.json`;
    const data = yield call(() => postPromise(url, noteData));
    updateMessage(data.data);
    yield put({
      type: actions.POST_SUCCESS,
      payload: cid,
    });
    const currentChatBody = 'messageBody';
    scrollToBottom(document.getElementById(currentChatBody));
    // Sending note attachments or images
    if (!!attachmentQueue && !!attachmentQueue.length) {
      store.dispatch({
        type: actions.POST_ATTACHMENTS,
        payload: { attachmentData: attachmentQueue[0], cid },
      });
      store.dispatch({
        type: actions.DEQUEUE_NOTE_ATTACHMENT,
        payload: { cid },
      });
    } else {
      store.dispatch({
        type: actions.NOTE_LOADER,
        payload: { status: false, cid },
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Note post failed');
    }
    store.dispatch({
      type: actions.NOTE_LOADER,
      payload: { status: false, cid },
    });
  }
}

export function* assignConversation(params) {
  try {
    let filters = '',
      searchQuery = '';
    const {
      inboxFilter,
      conversationSearchText,
      selectedChatRoom,
      removedConversationIdentifier,
      redirectingToSearch,
      chatRooms,
      statusFilter,
      isFromPreviousConversation,
      selectedPreviousConversation,
    } = store.getState().Chat;
    filters = assignFilters(inboxFilter, filters);
    if (conversationSearchText) {
      searchQuery = conversationSearchText;
    }
    let currentStatus = '';
    if (isFromPreviousConversation) {
      currentStatus = selectedPreviousConversation.status || statusFilter;
    } else {
      currentStatus =
        params.assignData.conversation_identifiers.length === 1 &&
        params.assignData.conversation_identifiers[0] ===
          removedConversationIdentifier
          ? selectedChatRoom.status
          : statusFilter;
    }
    if (redirectingToSearch) {
      if (isFromPreviousConversation) {
        currentStatus = selectedPreviousConversation.status;
      } else if (params.assignData.conversation_identifiers.length === 1) {
        let filterChatRoom = chatRooms.filter(
          (conversation) =>
            conversation.conversation_identifier ===
            params.assignData.conversation_identifiers[0],
        );
        currentStatus = filterChatRoom[0].status;
      }
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/assign?conversation_status=${currentStatus}${filters}&search_text=${searchQuery}`;
    const data = yield call(() => patchPromise(url, params.assignData));
    if (params.assignData.conversation_identifiers.length === 1) {
      //TODO:  add the check current conversation id is match and update the conversationMessageData
      yield put({
        type: actions.ASSIGN_CONVERSATION_SUCCESS,
        payload: params.assignData.assign_to,
        assignData: params.assignData,
        currentUserId: store.getState().Auth.currentUserId,
      });

      message.success(data.message);
    } else if (params.assignData.conversation_identifiers.length > 1) {
      let isActiveConversation =
        params.assignData.conversation_identifiers.some(
          (cid) => cid === extractConversationIdentifier(),
        );
      if (isActiveConversation) {
        yield put({
          type: actions.ASSIGN_CONVERSATION_SUCCESS,
          payload: params.assignData,
        });
      }
      message.success(data.message, 2);
    } else if (params.assignData.select_all) {
      message.success(data.message, 2);
    }
    yield put({
      type: actions.ASSIGN_MULTIPLE_CONVERSATION_SUCCESS,
    });
    if (params.fetchConversation) {
      params.fetchConversation();
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Conversation assignment failed');
    }
  }
}

export function* changeConversationStatus(params) {
  let statusDetails = {
    change_status_to: params.statusDetails.change_status_to,
    conversation_identifiers: params.statusDetails.conversation_identifiers,
    select_all: params.statusDetails.select_all,
  };

  yield put({
    type: actions.CONVERSATION_STATUS_UPDATE_LOADER,
    payload: params.statusDetails.conversation_identifiers,
  });

  try {
    let filters = '',
      searchQuery = '';
    const { inboxFilter, conversationSearchText } = store.getState().Chat;
    filters = assignFilters(inboxFilter, filters);
    if (conversationSearchText) {
      searchQuery = conversationSearchText;
    }

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/change_status?conversation_status=${
      params.statusDetails.currentStatus || store.getState().Chat.statusFilter
    }${filters}&search_text=${searchQuery}`;
    yield call(() => patchPromise(url, statusDetails));

    if (params.fetchConversation) {
      params.fetchConversation();
    } else {
      params.statusDetails.conversation_identifiers.forEach((value) => {
        removeConversation(
          value,
          params.statusDetails.change_status_to,
          params.statusDetails.isFromPreviousConversation,
        );
      });
    }
    yield put({
      type: actions.CONVERSATION_STATUS_UPDATE_LOADER_SUCCESS,
      payload: params.statusDetails.conversation_identifiers,
    });

    yield put({
      type: actions.ASSIGN_MULTIPLE_CONVERSATION_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Conversation status change failed');
      conversationStatusChangeHandler();
    }
  }
}

export function* deleteConversation(params) {
  try {
    let filters = '',
      searchQuery = '';
    const {
      inboxFilter,
      conversationSearchText,
      selectedChatRoom,
      removedConversationIdentifier,
      currentPersonId,
      redirectingToSearch,
      isFromPreviousConversation,
      chatRooms,
      statusFilter,
      selectedPreviousConversation,
    } = store.getState().Chat;
    filters = assignFilters(inboxFilter, filters);
    if (conversationSearchText) {
      searchQuery = conversationSearchText;
    }
    let currentStatus = '';
    if (isFromPreviousConversation) {
      currentStatus = selectedPreviousConversation.status || statusFilter;
    } else {
      currentStatus =
        params.deleteParams.conversation_identifiers.length === 1 &&
        params.deleteParams.conversation_identifiers[0] ===
          removedConversationIdentifier
          ? selectedChatRoom.status
          : statusFilter;
    }
    if (redirectingToSearch) {
      if (isFromPreviousConversation) {
        currentStatus = selectedPreviousConversation.status;
      } else if (params.deleteParams.conversation_identifiers.length === 1) {
        let filterChatRoom = chatRooms.filter(
          (conversation) =>
            conversation.conversation_identifier ===
            params.deleteParams.conversation_identifiers[0],
        );
        currentStatus = filterChatRoom[0].status;
      }
    }

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations?conversation_status=${currentStatus}${filters}&search_text=${searchQuery}`;
    const data = yield call(() => deletePromise(url, params.deleteParams));
    if (params.isFromTableView || params.isFromElasticSearch) {
      yield put({
        type: actions.CONVERSATION_DRAWER_STATUS,
        payload: false,
      });
    }
    if (isFromPreviousConversation) {
      yield put({
        type: actions.PREVIOUS_CONVERSATION_ENABLED,
        payload: false,
      });
      yield put({
        type: actions.FETCH_PREVIOUS_CONVERSATIONS,
        conversationID: extractConversationIdentifierFromUrl(),
        personId: currentPersonId,
      });
    }
    params.deleteParams.conversation_identifiers.forEach((value) => {
      removeConversation(value, 'delete');
    });
    yield put({
      type: actions.ASSIGN_MULTIPLE_CONVERSATION_SUCCESS,
    });
    message.success(data.message, 2);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Conversation deletion failed');
    }
  }
}

export function* block(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${
      params.blockParams.conversation_identifier
    }/block.json`;
    const data = yield call(() => postPromise(url, params.blockParams));
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Block failed');
    }
  }
}

export function* postAttachment(params) {
  window.onbeforeunload = () => true;
  let { attachmentData, cid } = params.payload,
    psk = fetchProjectSecretKey(),
    formData = new FormData(),
    { message_type } = attachmentData;
  let messageStatus = params.messageStatus;
  let dequeueType = actions.DEQUEUE_REPLY_ATTACHMENT,
    sendingLoaderType = actions.REPLY_LOADER,
    restoreQueue = actions.RESTORE_REPLY_ATTACHMENT_QUEUE;
  if (message_type === 'note') {
    dequeueType = actions.DEQUEUE_NOTE_ATTACHMENT;
    sendingLoaderType = actions.NOTE_LOADER;
    restoreQueue = actions.RESTORE_NOTE_ATTACHMENT_QUEUE;
  }
  const attachmentQueue = fetchAttachmentQueues(message_type, cid);
  if (attachmentQueue.length === 0 && messageStatus === 'closed') {
    attachmentData = { ...attachmentData, change_status_to: 'closed' };
  } else if (attachmentQueue.length === 0 && messageStatus === 'pending') {
    attachmentData = {
      ...attachmentData,
      change_status_to: 'pending',
      time_key: store.getState().Chat.timeKey,
      time_value: store.getState().Chat.timeValue,
    };
  }
  for (let key in attachmentData) {
    formData.append(key, attachmentData[key]);
  }

  try {
    const url = `${settings.ROOT_URL}projects/${psk}/conversations/${cid}/attachments.json`;
    const data = yield call(() => postPromise(url, formData));
    updateMessage(data.data);
    if (extractConversationIdentifier() === cid) {
      yield put({ type: actions.UPDATE_MESSAGE, payload: data });
    }
    yield put({
      type: actions.POST_SUCCESS,
      payload: cid,
    });
    const currentChatBody = 'messageBody';
    scrollToBottom(document.getElementById(currentChatBody));
    // Sending reply attachments or images
    if (attachmentQueue.length) {
      store.dispatch({
        type: actions.POST_ATTACHMENTS,
        payload: { attachmentData: attachmentQueue[0], cid },
        messageStatus,
      });
      store.dispatch({ type: dequeueType, payload: { cid } });
    } else {
      store.dispatch({
        type: sendingLoaderType,
        payload: { status: false, cid },
      });
      window.onbeforeunload = () => undefined;
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Attachment post failed. Some of the files may not sent');
    }
    store.dispatch({ type: restoreQueue, payload: { attachmentData, cid } });
    store.dispatch({
      type: sendingLoaderType,
      payload: { status: false, cid },
    });
    window.onbeforeunload = () => undefined;
  }
}

export function* editMessage(params) {
  const editedMessage = params.messageData,
    sendEditedMessage = {
      project_secret_key: editedMessage.projectSecretKey,
      message_content: editedMessage.message_content,
      user_ids: editedMessage.user_ids,
    },
    cid = extractConversationIdentifier();
  try {
    const url = `${settings.ROOT_URL}projects/${editedMessage.projectSecretKey}/message/${editedMessage.id}.json`;
    const data = yield call(() => patchPromise(url, sendEditedMessage));
    yield put({
      type: actions.EDIT_MESSAGE_SUCCESS,
      payload: data,
    });
    store.dispatch({
      type: actions.REPLY_LOADER,
      payload: { status: false, cid },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Message Post failed');
    }
    store.dispatch({
      type: actions.REPLY_LOADER,
      payload: { status: false, cid },
    });
  }
}

export function* fetchPreviousConversationMessages(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${params.cid}/messages`;
    const response = yield call(() => getPromise(url));
    const { messages } = response.data,
      updatedMessage = messages.map((message) => {
        const internalDisplayContent = getConversationStatusMessage(message);
        if (internalDisplayContent) {
          return {
            ...message,
            internal_display_content: internalDisplayContent,
          };
        }
        return message;
      });
    yield put({
      type: actions.EMMA_CONVERSATION_MESSAGES_SUCCESS,
      payload: {
        ...response.data,
        messages: updatedMessage,
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Messages fetch failed');
    }
  }
}

export function* fetchGif(params) {
  let inValidQueryString =
    params.queryString === '' || params.queryString === undefined;
  const endPoint = inValidQueryString ? 'trending' : 'search';
  try {
    let url = `${settings.GIF_URL}${endPoint}?key=${settings.TENOR_API_KEY}&limit=${gifOffset}`;
    url = inValidQueryString ? url : `${url}&q=${params.queryString}`;
    url = params.offset ? `${url}&pos=${params.offset}` : url;
    url = `${url}&contentfilter=high&media_filter=basic`;
    const data = yield call(() => getPromise(url, false));
    if (params.clearGif) {
      yield put({ type: actions.DELETE_GIF, payload: data });
    }
    yield put({ type: actions.FETCH_GIF_SUCCESS, payload: data });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('GIF fetch failed');
    }
  }
}

export function* fetchSingleSnippetData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${
      params.id
    }?with_message=true`;
    let data = yield call(() => getPromise(url));

    yield put({
      type: actions.FETCH_SINGLE_SNIPPET_DATA_INBOX_SUCCESS,
      snippetData: data.data.saved_reply,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('fetch snippet failed');
    }
    yield put({
      type: actions.FETCH_SINGLE_SNIPPET_DATA_INBOX_FAILURE,
    });
  }
}

export function* fetchSnippets(params) {
  const { withMessage = false, queryString, setSelectedFirstItem } = params,
    inValidQueryString = !queryString;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets?pagination=false&with_snippet_category=true&with_message=${withMessage}`;
    url = inValidQueryString
      ? url
      : `${url}&search_text=${encodeURIComponent(params.queryString)}`;
    const data = yield call(() => getPromise(url));
    if (params.clearSnippets) {
      yield put({ type: actions.DELETE_SNIPPETS, payload: data });
    }
    yield put({
      type: actions.FETCH_SNIPPETS_SUCCESS,
      snippets: data.data.saved_replies,
      snippetCategories: data.snippet_categories,
      offset: params.offset,
      queryString: queryString,
      mostRecentSnippets: data.most_recent_snippets || [],
    });
    if (setSelectedFirstItem) {
      yield setSelectedFirstItem(false);
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Snippets fetch failed');
    }
    yield put({
      type: actions.FETCH_SNIPPETS_FAILURE,
    });
  }
}

export function* fetchArticles(params) {
  let inValidQueryString =
    params.queryString === '' || params.queryString === undefined;
  try {
    const { selectedArticleLanguage } = store.getState().SharedReducers;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/articles/search?language=${selectedArticleLanguage}`;

    // ?last_article_id=${params.offset}`; TODO: This pagination feature will implement later
    url = inValidQueryString ? url : `${url}&search_text=${params.queryString}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ARTICLES_SUCCESS,
      payload: data.data.articles,
      queryString: params.queryString,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Articles fetch failed');
    }
  }
}

export function* updateQualificationData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/${extractCurrentPersonId()}/update_person.json`;
    const data = yield call(() => patchPromise(url, params.object));
    yield put({
      type: actions.UPDATE_QUALIFICATION_PROPERTIES_SUCCESS,
      payload: data,
      personId: extractCurrentPersonId(),
    });
    yield put({
      type: actions.CHANGE_ASSOCIATE_STATUS,
      payload: {
        associateContact: true,
      },
    });
    message.success(data.data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CHANGE_ASSOCIATE_STATUS,
      payload: {
        associateContact: false,
      },
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Qualification update failed');
    }
  }
}

export function* getUserTagList() {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/tags`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_USER_TAGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch user tag failed');
    }
  }
}
export function* getUserProperties(params) {
  try {
    const requiredData = params.property
        ? params.property
        : fetchActiveUserPropertyTabs(),
      url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/people/${
        params.userId
      }/marketing_details?required_data=${requiredData}`;
    yield put({
      type: actions.SET_USER_PROPERTIES_LOADER,
      payload: requiredData.split(','),
    });
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_USER_PROPERTIES_SUCCESS,
      payload: data.data,
    });
    yield put({
      type: actions.REMOVE_USER_PROPERTIES_LOADER,
      payload: requiredData.split(','),
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('property failed');
      yield put({
        type: actions.REMOVE_USER_PROPERTIES_LOADER,
      });
    }
  }
}

export function* addUserTags(params) {
  let personId = extractCurrentPersonId();
  if (params.personID) {
    personId = params.personID;
  }
  const object = {
    tags: params.tags,
    person_id: personId,
  };
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/tags`;
    const data = yield call(() => postPromise(url, object));
    message.success(data.data.message);
    yield put({
      type: actions.ADD_USER_TAGS_SUCCESS,
      payload: data,
    });
    yield put({
      type: contactActions.UPDATE_CONTACT_PROFILE_TAG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Tag addition failed');
    }
  }
}

export function* removeUserTags(params) {
  try {
    let personId = extractCurrentPersonId();
    if (params.personID) {
      personId = params.personID;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags?person_id=${personId}&tag_id=${
      params.id
    }`;
    const removeTagResponse = yield call(() => deletePromise(url, params.id));
    message.success(removeTagResponse.data.message);
    yield put({
      type: actions.REMOVE_USER_TAGS_SUCCESS,
      tagId: params.id,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Tag removal failed');
    }
  }
}

export function* removeConversationTags(params) {
  try {
    let conversationID = store.getState().Chat.currentConversationId;
    if (params.cid) {
      conversationID = params.cid;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/chat_tags/${
      params.id
    }?conversation_identifier=${conversationID}`;
    yield call(() => deletePromise(url, params.id));
    yield put({
      type: actions.REMOVE_CONVERSATION_TAGS_SUCCESS,
      tagId: params.id,
      conversationID: conversationID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Tag removal failed');
    }
  }
}

export function* removeMessageTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_tags/${
      params.messageID
    }?chat_tag_id=${params.tagID}`;
    const data = yield call(() => deletePromise(url));
    yield put({
      type: actions.REMOVE_MESSAGE_TAGS_SUCCESS,
      tagID: params.tagID,
      messageID: params.messageID,
      removeConversationTag: data.data.remove_conversation_tag,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Tag removal failed');
    }
  }
}

export function* updateMessageStatus(params) {
  const conversationIdentifier =
    extractConversationIdentifier() || params.conversationId;
  try {
    if (conversationIdentifier) {
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/conversations/${conversationIdentifier}/message/update-status.json`;
      yield call(() => patchPromise(url, params.payload));
      yield put({
        type: actions.UPDATE_MESSAGE_STATUS_SUCCESS,
        payload: params.payload,
        cid: conversationIdentifier,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error, false);
    if (error.response && error.response.status !== 403) {
      message.error('Message status update failed');
    }
  }
}

export function* fetchAssignee(params) {
  let queryParams = '';
  const { all, assigned_only, assigned_teams_only } = canWeAccess(
    userRestriction.inbox,
  );
  if (!all && assigned_only && assigned_teams_only) {
    // Check assigned team only
    queryParams = 'assigned_teams_only=true';
  }
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?${queryParams}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ASSIGNEE_SUCCESS,
      payload: data,
    });
    if (!params.isFromCreateUpdateCustomView && params.payload) {
      const inboxRoles = canWeAccess(userRestriction.inbox);
      const canManageUnassignedInbox = canWeAccess(
        userRestriction.UnassignedInbox,
      );
      const assignedTeams = store.getState().Chat.teams;
      const currentUserId = extractCurrentUserId();
      yield put({
        type: actions.SET_CONVERSATION_INBOX_COUNT,
        payload: userList(
          params.payload,
          currentUserId,
          inboxRoles,
          canManageUnassignedInbox,
          assignedTeams,
        ),
        currentUserId,
        inboxRoles,
        canManageUnassignedInbox,
        assignedTeams,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch assignee failed');
    }
  }
}

export function* scheduleMeeting(params) {
  let { scheduleData } = params;
  const currentConversationId = extractConversationIdentifier();
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${currentConversationId}/initiate-meeting.json`;
    const data = yield call(() => postPromise(url, scheduleData));
    yield put({
      type: actions.UPDATE_MESSAGE,
      payload: data,
      currentUserId: extractCurrentUserId(),
    });
    yield put({
      type: actions.POST_SUCCESS,
      payload: extractConversationIdentifier(),
    });
    const currentChatBody = 'messageBody';
    scrollToBottom(document.getElementById(currentChatBody));
    yield put({
      type: actions.UPDATE_ALL_CONVERSATION_DATA,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Meeting schedule failed');
    }
  }
}

export function* getChatTags() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/chat_tags`;
    const data = yield call(() => getPromise(url));
    const result = {
      chatTags: data.chat_tags,
      mostUsedChatTags: data.most_used_chat_tags,
    };
    yield put({
      type: actions.GET_CHAT_TAG_SUCCESS,
      payload: result,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch chat tags failed');
    }
  }
}

export function* addMessageTags(params) {
  try {
    let messageId = params.messageId;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message_tags?message_id=${messageId}`;
    let data = yield call(() => postPromise(url, { tags: params.tags }));
    yield put({
      type: actions.ADD_MESSAGE_TAG_SUCCESS,
      payload: {
        ...data.message_tags,
        cid: params.cid,
        messageId: messageId,
      },
    });

    yield put({
      type: actions.SHOW_HIDE_MESSAGE_TAGS_MODAL,
      payload: {
        status: false,
        messageId: null,
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Message tags addition failed');
    }
  }
}

export function* snoozeConversation(params) {
  try {
    let statusDetails = {
      change_status_to: 'pending',
      time_key: params.time_key,
      time_value: params.time_value,
      conversation_identifiers: params.conversation_identifiers,
      select_all: params.select_all,
      isFromPreviousConversation: params.isFromPreviousConversation,
    };

    yield put({
      type: actions.CONVERSATION_STATUS_UPDATE_LOADER,
      payload: params.conversation_identifiers,
    });

    let filters = '',
      searchQuery = '';
    const { inboxFilter, conversationSearchText } = store.getState().Chat;
    filters = assignFilters(inboxFilter, filters);
    if (conversationSearchText) {
      searchQuery = conversationSearchText;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/change_status?conversation_status=${
      params.currentStatus || store.getState().Chat.statusFilter
    }${filters}&search_text=${searchQuery}`;
    const data = yield call(() => patchPromise(url, statusDetails));

    yield put({
      type: actions.CONVERSATION_STATUS_UPDATE_LOADER_SUCCESS,
      payload: params.conversation_identifiers,
    });

    params.conversation_identifiers.forEach((value) => {
      removeConversation(
        value,
        'pending',
        statusDetails.isFromPreviousConversation,
      );
    });

    yield put({
      type: actions.ASSIGN_MULTIPLE_CONVERSATION_SUCCESS,
    });
    message.success(data.message, 2);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error('Invalid Snooze time');
    } else if (error.response && error.response.status !== 403) {
      message.error('Snooze failed');
    }
  }
}

export function* associateVisitor(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/get_person_name?email=${encodeURIComponent(
      params.payload.data.email,
    )}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.ASSOCIATE_VISITOR_SUCCESS,
      payload: { data: data.data },
    });
  } catch (error) {
    unAuthorisedRedirection(error, false);
    yield put({ type: actions.ASSOCIATE_VISITOR_FAILURE });
  }
}

export function* fetchAgentTimings(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${extractConversationIdentifier()}/list_meeting_links/${
      params.id
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_AGENT_TIMINGS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch timings failed');
    }
  }
}

export function* sendFireBaseToken(params) {
  try {
    let url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/firebase?device_token=${params.token}`;
    yield call(() => postPromise(url));
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Send token failed');
    }
  }
}

export function* deleteConversationMessage(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/message/${params.messageID}`;
    let data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.DELETE_CONVERSATION_MESSAGE_SUCCESS,
      id: params.messageID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Delete message failed');
    }
  }
}

export function* getNotificationDetails(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/send_notification?message_id=${
      params.id
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_NOTIFICATION_SETTINGS_DETAILS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch notification details failed');
    }
  }
}

export function* prioritizeConversation(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${
      params.payload.cid
    }/priority`;
    yield call(() => patchPromise(url, { priority: params.payload.priority }));
    yield put({
      type: actions.PRIORITIZE_CONVERSATION_SUCCESS,
      data: { ...params.payload },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Prioritize conversation failed');
    }
    yield put({
      type: actions.PRIORITIZE_CONVERSATION_FAILURE,
      data: { ...params.payload },
    });
  }
}

export function* fetchSnippetParsedContent(params) {
  try {
    const {
      personId,
      snippetPresentIn,
      pushContentIntoComposer,
      handleSnippetVisibleChange,
      cid,
    } = params.payload;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${
      params.payload.data.id
    }/parse_message_content?person_id=${
      personId ? personId : extractCurrentPersonId()
    }&conversation_identifier=${cid}`;
    const data = yield call(() => getPromise(url));
    const payload = {
      data: {
        ...params.payload.data,
        message: data.data.parsed_message_content,
      },
    };
    if (snippetPresentIn === 'teamInbox') {
      store.dispatch({
        type: actions.SET_SNIPPET_MESSAGE,
        payload,
        isNote: params.isNote,
      });
    }
    pushContentIntoComposer(
      `${data.data.parsed_message_content}`.replace(/^(<br>|<br>+)*/, ''),
    );
    yield put({
      type: actions.UPDATE_SNIPPET_DETAILS,
      payload: params.payload.data.id,
    });
    yield put({
      type: sharedActions.CHANGE_COMPOSER_UNDO_STATUS,
      payload: false,
    });
    handleSnippetVisibleChange(false);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch snippet parsed content failed');
    }
  }
}

export function* sendChatTranscript(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/send_transcript_mail`;
    let data = yield call(() => postPromise(url, { ...params.payload }));
    yield put({
      type: actions.SEND_TRANSCRIPT_SUCCESS,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      if (error.response.data && error.response.data.errors) {
        message.error(error.response.data.errors);
      } else {
        message.error('Send chat transcript failed');
      }
    }
    yield put({
      type: actions.SEND_TRANSCRIPT_FAILURE,
    });
  }
}
export function* fetchPreviousConversations(params) {
  let currentConversationID = params.conversationID;
  if (params.cid) {
    currentConversationID = params.cid;
  }
  const searchValue = params.searchText || '';
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations.json?person_id=${
      params.personId
    }&conversation_identifier=${currentConversationID}&previous_conversations=true&conversation_status=all&search_text=${searchValue.toLowerCase()}`;
    if (params.lastConversationID) {
      url += `&last_conversation_identifier=${params.lastConversationID}`;
    }
    const data = yield call(() => getPromise(url));
    let previous_conversations = data.data.conversations;
    let count = data.data.previous_conversations_count;
    yield put({
      type: actions.FETCH_PREVIOUS_CONVERSATIONS_SUCCESS,
      payload: {
        previous_conversations,
        count,
      },
      personId: params.personId,
      isAppend: !!params.lastConversationID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch previous conversation failed');
    }
    yield put({
      type: actions.FETCH_PREVIOUS_CONVERSATIONS_FAILURE,
      personId: params.personId,
    });
  }
}

export function* scheduleCall(params) {
  let currentConversationID = store.getState().Chat.currentConversationId;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${currentConversationID}/initiate-call`;
    const data = yield call(() =>
      postPromise(url, { audio_only: params.callType === 'audio' }),
    );
    yield put({
      type: actions.SCHEDULE_CALL_SUCCESS,
      payload: data,
    });
    const currentChatBody = 'messageBody';
    scrollToBottom(document.getElementById(currentChatBody));
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SCHEDULE_CALL_FAILURE,
    });
  }
}

export function* updateCallEndedStatus(params) {
  try {
    const { projectSecretKey, messageContent, messageId } = params,
      updatedMessage = {
        project_secret_key: projectSecretKey,
        message_content: messageContent,
      };
    const url = `${settings.ROOT_URL}projects/${projectSecretKey}/message/${messageId}.json`;
    const response = yield call(() => patchPromise(url, updatedMessage));
    yield put({
      type: actions.UPDATE_JOIN_CALL_MESSAGES,
      payload: response.data.message,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Call update failed');
    }
  }
}

export function* updateConversationTitle(params) {
  try {
    let currentConversationID = store.getState().Chat.currentConversationId;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${currentConversationID}/update_conversation_title`;
    const data = yield call(() => patchPromise(url, { title: params.payload }));
    message.success(data.message);
    yield put({
      type: chatActions.UPDATE_CONVERSATION_TITLE_SUCCESS,
      payload: params.payload,
      conversationId: currentConversationID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.error.message);
    store.dispatch({
      type: actions.UPDATE_CONVERSATION_TITLE_FAILURE,
    });
  }
}


export function* fetchContactSegments() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/segments.json?project_secret_key=${fetchProjectSecretKey()}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Segments fetch failed');
    }
  }
}

export function* fetchContactTagsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_INBOX_VIEW_CONTACT_TAGS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch tags list failed');
    }
  }
}

export function* fetchContactPropertiesMetaData() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/property-meta-data`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Properties meta data fetch failed');
    }
  }
}

export function* createCustomInboxView() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_views`;
    const currentInboxViewData = store.getState().Chat.currentInboxViewData;
    let sortFilter = store.getState().Chat.sortFilter;
    let data = yield call(() =>
      postPromise(url, {
        name: currentInboxViewData.name,
        icon: currentInboxViewData.emoji,
        filters: currentInboxViewData.conditions,
      }),
    );
    trackEvent('Created custom inbox view');
    message.success(data.message);
    yield put({
      type: actions.CREATE_CUSTOM_INBOX_VIEW_SUCCESS,
    });
    yield put({
      type: actions.GET_CUSTOM_INBOX_VIEWS_LIST,
    });
    yield put({
      type: actions.UPDATE_CUSTOM_INBOX_CONVERSATION_COUNT,
      payload: data.data,
    });
    yield put({
      type: actions.SET_FILTER,
      payload: {
        inbox: `custom-inbox-view-${data.data.inbox_view.id}`,
      },
    });
    yield put({
      type: actions.FETCH_CONVERSATIONS,
      filter: `custom-inbox-view-${data.data.inbox_view.id}`,
      emptyChatRoom: true,
      isFromCreateUpdateCustomView: true,
      sort: sortFilter,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Could not create custom inbox view');
    }
    yield put({
      type: actions.CREATE_CUSTOM_INBOX_VIEW_FAILURE,
    });
  }
}

export function* getCustomInboxViewsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_views`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CUSTOM_INBOX_VIEWS_LIST_SUCCESS,
      payload: data.data.inbox_views,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch custom inbox views failed');
    }
    yield put({
      type: actions.GET_CUSTOM_INBOX_VIEWS_LIST_FAILURE,
    });
  }
}

export function* deleteCustomInboxView(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_views/${params.id}`;
    let data = yield call(() => deletePromise(url));
    let sortFilter = store.getState().Chat.sortFilter;
    message.success(data.message);
    yield put({
      type: actions.GET_CUSTOM_INBOX_VIEWS_LIST,
    });
    yield put({
      type: actions.SET_FILTER,
      payload: {
        inbox: 'IB4',
      },
    });
    yield put({
      type: actions.FETCH_CONVERSATIONS,
      filter: 'IB4',
      emptyChatRoom: true,
      sort: sortFilter,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Delete custom inbox failed');
    }
  }
}

export function* updateCustomInboxView() {
  try {
    const currentInboxViewData = store.getState().Chat.currentInboxViewData;
    let sortFilter = store.getState().Chat.sortFilter;
    const updatedData = {
      name: currentInboxViewData.name,
      icon: currentInboxViewData.emoji,
      filters: currentInboxViewData.conditions,
    };
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox_views/${
      currentInboxViewData.id
    }`;
    const data = yield call(() => patchPromise(url, updatedData));
    message.success(data.message);
    yield put({
      type: actions.UPDATE_CUSTOM_INBOX_VIEW_SUCCESS,
    });
    yield put({
      type: actions.GET_CUSTOM_INBOX_VIEWS_LIST,
    });
    yield put({
      type: actions.UPDATE_CUSTOM_INBOX_CONVERSATION_COUNT,
      payload: data.data,
    });
    yield put({
      type: actions.SET_FILTER,
      payload: {
        inbox: `custom-inbox-view-${currentInboxViewData.id}`,
      },
    });
    yield put({
      type: actions.FETCH_CONVERSATIONS,
      filter: `custom-inbox-view-${currentInboxViewData.id}`,
      emptyChatRoom: true,
      isFromCreateUpdateCustomView: true,
      sort: sortFilter,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Update custom inbox view failed');
    }
    yield put({
      type: actions.UPDATE_CUSTOM_INBOX_VIEW_FAILURE,
    });
  }
}

export function* customInboxViewPing(params) {
  try {
    const inboxFilter = params.payload;
    let id = inboxFilter.split('custom-inbox-view-')[1];

    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/inbox-view-status/ping.json`;
    yield call(() => postPromise(url, { inbox_view_id: id }));
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}

export function* fetchTimeBasedConversations(params) {
  try {
    let status = '',
      searchQuery = '',
      filters = '',
      sort = '',
      pageNo = store.getState().Chat.pageNo;
    if (params.status) {
      status = params.status;
    }
    if (params.searchTag) {
      store.dispatch({
        type: actions.SET_CONVERSATION_SEARCH_TEXT,
        payload: params.searchTag.split('=')[1],
      });
    }
    if (params.searchQuery) {
      searchQuery = params.searchQuery;
    } else {
      searchQuery = store.getState().Chat.conversationSearchText;
    }
    const { selectedConversations, inboxFilter } = store.getState().Chat;
    if (selectedConversations.length && params.filter !== inboxFilter) {
      yield put({ type: actions.SET_SELECT_ALL_CONVERSATION, checkAll: false });
    }
    if (params.filter) {
      let key = params.filter;
      filters = assignFilters(key, filters);
    }
    if (params.sort) {
      sort = assignSorter(params, sort);
    }

    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations.json?conversation_status=${status}&search_text=${encodeURIComponent(
      searchQuery,
    )}${filters}${sort}&pages=${pageNo + 1}`;
    if (params.filter === inboxFilter) {
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_TIME_BASED_CONVERSATIONS_SUCCESS,
        payload: data,
        filter: params.filter,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_TIME_BASED_CONVERSATIONS_FAILURE,
    });
  }
}

export function* fetchConversationProperties(params) {
  try {
    const { with_options, with_hidden } = params.payload;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/conversation_properties?with_options=${with_options}&with_hidden=${with_hidden}`;
    let payload = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_CONVERSATION_PROPERTIES_SUCCESS,
      conversationPropertiesData: payload.data.conversation_properties,
      inboxPropertyPreference: store.getState().Auth.inboxPropertyPreference,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
    yield put({
      type: actions.FETCH_CONTACT_CONVERSATION_PROPERTIES_FAILURE,
    });
  }
}

export function* updateSnippetDetails(params) {
  try {
    const { payload } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/snippets/${payload}/update_snippet_details`;
    const { data } = yield call(() => getPromise(url));
    yield put({
      type: actions.UPDATE_SNIPPET_DETAILS_SUCCESS,
      payload: data.saved_reply,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
  }
}

export function* initializeInboxAppData(params) {
  const { inbox_app_id, conversation_id } = params.payload;
  try {
    const jsonData = {
      app_id: inbox_app_id,
      admin_id: extractCurrentUserId(),
      workspace_id: fetchProjectSecretKey(),
      conversation_id,
      contact_id: extractCurrentPersonId(),
      context: {
        locale: 'en',
        location: 'inbox',
        referrer: settings.REACT_DOMAIN_URL,
      },
    };

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${inbox_app_id}/post_initialize`;
    const data = yield call(() => postPromise(url, jsonData));

    yield put({
      type: actions.INITIALIZE_INBOX_APP_DATA_SUCCESS,
      payload: {
        data: data,
        inbox_app_id,
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      yield put({
        type: actions.INITIALIZE_INBOX_APP_DATA_FAILURE,
        payload: {
          inbox_app_id: inbox_app_id,
        },
      });
    }
  }
}

export function* submitInboxAppData(params) {
  const { inbox_app_id, component_id, input_values, current_canvas } =
    params.payload;
  try {
    let jsonData = {
      app_id: inbox_app_id,
      component_id,
      input_values,
      current_canvas,
      admin_id: extractCurrentUserId(),
      workspace_id: fetchProjectSecretKey(),
      conversation_id: extractConversationId(),
      contact_id: extractCurrentPersonId(),
      context: {
        locale: 'en',
        location: 'inbox',
        referrer: settings.REACT_DOMAIN_URL,
      },
    };

    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/developer_apps/${inbox_app_id}/post_submit`;
    const data = yield call(() => postPromise(url, jsonData));
    yield put({
      type: actions.SUBMIT_INBOX_APP_DATA_SUCCESS,
      payload: {
        data: data,
        inbox_app_id,
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      yield put({
        type: actions.INITIALIZE_INBOX_APP_DATA_FAILURE,
        payload: {
          inbox_app_id,
        },
      });

      message.error('Update App data failed');
    }
  }
}

export function* updateInboxWidgets(params) {
  try {
    const { payload } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/users_profiles/update_inbox_widget_preference`;
    const { data } = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.UPDATE_ENABLED_INBOX_WIDGETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Update inbox widgets failed');
  }
}

export function* updateMarkAsNotSpam(params) {
  try {
    const { payload, isFromTableView, isFromElasticSearch } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations/${
      payload.conversationId
    }/update_conversation_spam_status_manually`;
    const response = yield call(() =>
      patchPromise(url, { mark_not_spam: true }),
    );
    message.success(response.message);
    if (isFromTableView || isFromElasticSearch) {
      yield put({
        type: actions.CONVERSATION_DRAWER_STATUS,
        payload: false,
      });
    }
    yield put({
      type: actions.UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_SUCCESS,
      payload,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch conversation properties failed');
    yield put({
      type: actions.UPDATE_MARK_AS_NOT_SPAM_CONVERSATION_FAILURE,
    });
  }
}

export function* getViewOriginalMessageData() {
  try {
    const { viewOriginalMessageId } = store.getState().Chat,
      url = `${
        settings.S3_BUCKET_URL
      }inbound/project_${fetchProjectSecretKey()}/${viewOriginalMessageId}.json`;
    let response = yield call(() => getPromise(url, false));
    yield put({
      type: actions.GET_VIEW_ORIGINAL_MESSAGE_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    message.error('Fetch view original message details is failed');
    yield put({
      type: actions.GET_VIEW_ORIGINAL_MESSAGE_DETAILS_FAILURE,
    });
  }
}

export function* fetchAIAssist(params) {
  try {
    const {
        actionType,
        tabActiveKey,
        isFromOmniSearch,
        conversationId,
        isOmniSearchSelect,
      } = params,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/openai/completion`;
    const payload = {
      completion_type: actionType,
      input_text: params.message,
      ...(params.lang_code ? { lang_code: params.lang_code } : {}),
    };
    const data = yield call(() => postPromise(url, payload));
    const generatedText = data.completion_text.replace(/\n/g, '<br>');

    if (!isFromOmniSearch) {
      if (conversationId) {
        yield put({
          type: actions.FETCH_AI_ASSIST_SUCCESS,
          ...(tabActiveKey === 'reply'
            ? { replyResponse: generatedText }
            : { noteResponse: generatedText }),
          conversationId,
        });
      } else {
        params.callback(generatedText);
      }
      if (params.activeElement) {
        params.activeElement.classList.remove('ai-assist-loader');
      }
    } else if (isOmniSearchSelect) {
      yield put({
        type: actions.FETCH_AI_ASSIST_SUCCESS,
        ...(tabActiveKey === 'reply'
          ? { replyResponse: generatedText }
          : { noteResponse: generatedText }),
        conversationId,
      });
    } else {
      yield put({
        type:
          tabActiveKey === 'reply'
            ? actions.SET_REPLY_COMPOSERS_VALUE
            : actions.SET_NOTE_COMPOSERS_VALUE,
        payload: generatedText,
        conversationId,
      });
      yield put({
        type: actions.SET_AI_ASSIST,
      });
    }
    yield put({
      type:
        tabActiveKey === 'reply'
          ? actions.AI_ASSIST_REPLY_LOADER
          : actions.AI_ASSIST_NOTE_LOADER,
      payload: false,
      conversationId,
    });
  } catch (error) {
    if (!params.isFromOmniSearch) {
      if (params.activeElement) {
        params.activeElement.classList.remove('ai-assist-loader');
      }
    }
    yield put({
      type:
        params.tabActiveKey === 'reply'
          ? actions.AI_ASSIST_REPLY_LOADER
          : actions.AI_ASSIST_NOTE_LOADER,
      payload: false,
      conversationId: params.conversationId,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, `AI assist text ${params.actionType} failed`);
  }
}

export function* fetchConversationCompletion(params) {
  try {
    const {
        tabActiveKey,
        message,
        conversationId,
        completionType,
        isOmniSearchSelect,
      } = params,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/openai/conversation_completion`,
      payload = {
        conversation_identifier: conversationId,
        completion_type: completionType,
        ...(['continue_writing', 'expand'].includes(completionType)
          ? { input_text: message }
          : {}),
      };
    const data = yield call(() => postPromise(url, payload));
    let completionResponse = data.completion_text;
    if (completionType !== 'continue_writing') {
      const newLine = message.substr(-4) === '<br>' ? '' : '<br>';
      if (message) {
        completionResponse = message + newLine + data.completion_text;
      }
    }
    if (isOmniSearchSelect) {
      yield put({
        type: actions.FETCH_AI_ASSIST_SUCCESS,
        ...(tabActiveKey === 'reply'
          ? { replyResponse: completionResponse }
          : { noteResponse: completionResponse }),
        conversationId,
      });
    } else {
      yield put({
        type:
          tabActiveKey === 'reply'
            ? actions.SET_REPLY_COMPOSERS_VALUE
            : actions.SET_NOTE_COMPOSERS_VALUE,
        payload: completionResponse.replace(/\n/g, '<br>'),
        conversationId,
      });
      yield put({
        type: actions.SET_AI_ASSIST,
      });
    }
    yield put({
      type:
        tabActiveKey === 'reply'
          ? actions.AI_ASSIST_REPLY_LOADER
          : actions.AI_ASSIST_NOTE_LOADER,
      payload: false,
      conversationId,
    });
  } catch (error) {
    yield put({
      type:
        params.tabActiveKey === 'reply'
          ? actions.AI_ASSIST_REPLY_LOADER
          : actions.AI_ASSIST_NOTE_LOADER,
      payload: false,
      conversationId: params.conversationId,
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'AI summarize failed');
  }
}

export function* fetchAIAnswer(params) {
  try {
    const { conversationId } = params,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/openai/complete_by_ai_sources/?${
        conversationId ? `conversation_identifier=${conversationId}` : ''
      }`;

    const response = yield call(() => postPromise(url, {}));

    yield put({
      type: actions.FETCH_AI_CUSTOM_ANSWER_SUCCESS,
      payload: response,
      conversationId,
    });

    yield put({
      type: actions.AI_ASSIST_CUSTOM_ANSWER_LOADER,
      payload: false,
      conversationId,
    });
  } catch (error) {
    yield put({
      type: actions.AI_ASSIST_CUSTOM_ANSWER_LOADER,
      payload: false,
      conversationId: params.conversationId,
    });
    yield put({
      type: actions.UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY,
      payload: {
        visible: false,
        mode: null,
      },
    });
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'AI Answer generation failed');
  }
}

export function* fetchAIAnswerFound(params) {
  try {
    const { conversationId, messageId } = params,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/openai/complete_by_ai_sources/?conversation_identifier=${conversationId}&only_semantic_check=true`;
    const data = yield call(() => postPromise(url, {}));
    const matchFound = data.match_found;

    yield put({
      type: actions.FETCH_AI_ANSWER_FOUND_SUCCESS,
      conversationId,
      payload: {
        messageId,
        matchFound,
      },
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'No match');
  }
}

export function* fetchAICustomAnswer(params) {
  try {
    const { customMessage, conversationId, includeConversationId } = params,
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/openai/complete_by_ai_sources/?custom_message=${
        customMessage ? customMessage : ''
      }${
        includeConversationId
          ? `&conversation_identifier=${conversationId}`
          : ''
      }`;
    const response = yield call(() => postPromise(url, {}));

    yield put({
      type: actions.FETCH_AI_CUSTOM_ANSWER_SUCCESS,
      payload: response,
      conversationId,
    });

    yield put({
      type: actions.AI_ASSIST_CUSTOM_ANSWER_LOADER,
      payload: false,
      conversationId,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (
      error.response &&
      error.response.data.errors === 'No matching article found'
    ) {
      yield put({
        type: actions.FETCH_AI_CUSTOM_ANSWER_SUCCESS,
        payload: { completion_text: 'not_found' },
        conversationId: params.conversationId,
      });
    } else {
      unProcessableEntity(error, 'Emma Answer generation failed');
      yield put({
        type: actions.UPDATE_AI_CUSTOM_ANSWER_MODAL_VISIBILITY,
        payload: {
          visible: false,
          mode: null,
        },
      });
    }
    yield put({
      type: actions.AI_ASSIST_CUSTOM_ANSWER_LOADER,
      payload: false,
      conversationId: params.conversationId,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CONVERSATIONS, fetchConversations),
    takeEvery(actions.FETCH_MESSAGES, fetchCurrentConversationMessages),
    takeLatest(actions.FETCH_PROFILE, fetchCurrentPersonProfile),
    takeEvery(actions.POST_REPLY, postReply),
    takeEvery(actions.POST_NOTE, postNote),
    takeEvery(actions.POST_MESSAGE, assignConversation),
    takeEvery(
      actions.INIT_CONVERSATION_STATUS_CHANGE,
      changeConversationStatus,
    ),
    takeEvery(actions.INIT_CONVERSATION_DELETE, deleteConversation),
    takeEvery(actions.INIT_BLOCK, block),
    takeEvery(actions.POST_ATTACHMENTS, postAttachment),
    takeEvery(actions.EDIT_MESSAGE, editMessage),
    takeEvery(
      actions.EMMA_CONVERSATION_MESSAGES,
      fetchPreviousConversationMessages,
    ),
    takeEvery(actions.FETCH_GIF, fetchGif),
    takeEvery(actions.FETCH_SINGLE_SNIPPET_DATA_INBOX, fetchSingleSnippetData),
    takeEvery(actions.FETCH_SNIPPETS, fetchSnippets),
    takeEvery(actions.FETCH_ARTICLES, fetchArticles),
    takeEvery(actions.UPDATE_QUALIFICATION_PROPERTIES, updateQualificationData),
    takeEvery(actions.GET_USER_TAGS, getUserTagList),
    takeEvery(actions.ADD_USER_TAGS, addUserTags),
    takeEvery(actions.REMOVE_USER_TAGS, removeUserTags),
    takeEvery(actions.UPDATE_MESSAGE_STATUS, updateMessageStatus),
    takeEvery(actions.FETCH_ASSIGNEE_LIST, fetchAssignee),
    takeEvery(actions.SCHEDULE_MEETING, scheduleMeeting),
    takeEvery(actions.GET_CHAT_TAG, getChatTags),
    takeEvery(actions.REMOVE_MESSAGE_TAGS, removeMessageTags),
    takeEvery(actions.ADD_MESSAGE_TAG, addMessageTags),
    takeEvery(actions.REMOVE_CONVERSATION_TAGS, removeConversationTags),
    takeEvery(actions.SNOOZE_CONVERSATION, snoozeConversation),
    takeEvery(actions.ASSOCIATE_VISITOR, associateVisitor),
    takeEvery(actions.FETCH_AGENT_TIMINGS, fetchAgentTimings),
    takeEvery(actions.SEND_FIRE_BASE_TOKEN, sendFireBaseToken),
    takeEvery(actions.DELETE_CONVERSATION_MESSAGE, deleteConversationMessage),
    takeEvery(
      actions.GET_NOTIFICATION_SETTINGS_DETAILS,
      getNotificationDetails,
    ),
    takeEvery(actions.PRIORITIZE_CONVERSATION, prioritizeConversation),
    takeEvery(actions.FETCH_SNIPPET_PARSED_CONTENT, fetchSnippetParsedContent),
    takeEvery(actions.SEND_TRANSCRIPT, sendChatTranscript),
    takeLatest(
      actions.FETCH_PREVIOUS_CONVERSATIONS,
      fetchPreviousConversations,
    ),
    takeEvery(actions.GET_USER_PROPERTIES, getUserProperties),
    takeEvery(
      actions.FETCH_INBOX_VIEW_CONTACT_SEGMENTS_LIST,
      fetchContactSegments,
    ),
    takeEvery(actions.FETCH_INBOX_VIEW_CONTACT_TAGS_LIST, fetchContactTagsList),
    takeEvery(
      actions.FETCH_INBOX_VIEW_CONTACT_PROPERTIES_META_DATA,
      fetchContactPropertiesMetaData,
    ),
    takeEvery(actions.SCHEDULE_CALL, scheduleCall),
    takeEvery(actions.UPDATE_CALL_ENDED_STATUS, updateCallEndedStatus),
    takeEvery(actions.INITIALIZE_INBOX_APP_DATA, initializeInboxAppData),
    takeEvery(actions.SUBMIT_INBOX_APP_DATA, submitInboxAppData),
    takeEvery(actions.CREATE_CUSTOM_INBOX_VIEW, createCustomInboxView),
    takeEvery(actions.GET_CUSTOM_INBOX_VIEWS_LIST, getCustomInboxViewsList),
    takeEvery(actions.DELETE_CUSTOM_INBOX_VIEW, deleteCustomInboxView),
    takeEvery(actions.UPDATE_CUSTOM_INBOX_VIEW, updateCustomInboxView),
    takeEvery(actions.UPDATE_CONVERSATION_TITLE, updateConversationTitle),
    takeEvery(actions.CUSTOM_INBOX_VIEW_PING, customInboxViewPing),
    takeEvery(
      actions.FETCH_TIME_BASED_CONVERSATIONS,
      fetchTimeBasedConversations,
    ),
    takeEvery(
      actions.FETCH_CONTACT_CONVERSATION_PROPERTIES,
      fetchConversationProperties,
    ),
    takeEvery(actions.UPDATE_SNIPPET_DETAILS, updateSnippetDetails),
    takeEvery(actions.UPDATE_ENABLED_INBOX_WIDGETS, updateInboxWidgets),
    takeEvery(
      actions.UPDATE_MARK_AS_NOT_SPAM_CONVERSATION,
      updateMarkAsNotSpam,
    ),
    takeEvery(
      actions.GET_VIEW_ORIGINAL_MESSAGE_DETAILS,
      getViewOriginalMessageData,
    ),
    takeEvery(actions.FETCH_AI_ASSIST, fetchAIAssist),
    takeEvery(
      actions.FETCH_CONVERSATION_COMPLETION,
      fetchConversationCompletion,
    ),
    takeEvery(actions.FETCH_AI_ANSWER, fetchAIAnswer),
    takeEvery(actions.FETCH_AI_ANSWER_FOUND, fetchAIAnswerFound),
    takeEvery(actions.FETCH_AI_CUSTOM_ANSWER, fetchAICustomAnswer),
  ]);
}
