export const TourStepTypes = {
  POINTER: 'pointer',
  POST: 'post',
  VIDEO_POINTER: 'video',
};

export const TourActionTypes = {
  GO_TO_STEP: {
    key: 'GO_TO_STEP',
    name: 'Go to step',
    icon_class: 'fal fa-external-link-square-alt',
  },
  CLOSE_TOUR: {
    key: 'CLOSE_TOUR',
    name: 'Dismiss tour',
    icon_class: 'fal fa-times-circle',
  },
  NAVIGATE: {
    key: 'NAVIGATE',
    name: 'Navigate to page',
    icon_class: 'fal fa-link',
  },
  // START_TOUR: {
  //   key: 'START_TOUR',
  //   name: 'Start another tour',
  //   icon_class: 'fal fa-arrow-right',
  // },
  EVAL_JS: {
    key: 'EVAL_JS',
    name: 'Evaluate JavaScript',
    icon_class: 'fab fa-js-square',
  },
};

export function generateId(tourId, type) {
  return `${tourId}-${type}-${Math.random()
    .toString(16)
    .substr(2, 8)}-${+new Date()}`;
}

export const DefaultStepPost = (tourId, stepsLength) => ({
  id: generateId(tourId, 'step'),
  title: `Step ${++stepsLength}`,
  order: 3,
  content: '',
  style: 'post',
  width: 600,
  is_goal: false,
  show_overlay: true,
  buttons: [],
  triggers: [],
  beacons: [],
});

export const DefaultStepPointer = (tourId, stepsLength) => ({
  id: generateId(tourId, 'step'),
  pointer_id: generateId(tourId, 'pointer'),
  title: `Step ${++stepsLength}`,
  order: 3,
  content: '',
  selector: {
    type: 'AUTO',
    css: '',
  },
  placement: 'BELOW',
  pointer_size: 0,
  style: 'pointer',
  width: 268,
  is_goal: false,
  show_overlay: true,
  padding: 0,
  actions: [],
  beacons: [],
  triggers: [],
  buttons: [],
});

export const DefaultStepVideoPointer = (tourId, stepsLength) => ({
  id: generateId(tourId, 'step'),
  pointer_id: generateId(tourId, 'pointer'),
  title: `Step ${++stepsLength}`,
  order: 3,
  content: '',
  selector: {
    type: 'AUTO',
    css: '',
  },
  placement: 'BELOW',
  pointer_size: 0,
  style: 'video',
  width: 268,
  is_goal: false,
  show_overlay: true,
  padding: 0,
  actions: [],
  beacons: [],
  triggers: [],
  buttons: [],
});

export const DefaultTourButtonData = tourId => ({
  id: generateId(tourId, 'button'),
  text: 'Next',
  style: 'primary',
  actions: [],
  disabled_conditions: null,
  hidden_conditions: null,
});

export const DefaultTourBeaconData = tourId => ({
  id: generateId(tourId, 'beacon'),
  offsetX: 0,
  offsetY: 0,
  placement: 'right',
  selector: {
    type: 'AUTO',
    css: '',
    text: null,
    offset: 0,
    precision: '1',
  },
  hidden_conditions: null,
});

export const DefaultTourTriggerData = tourId => ({
  id: generateId(tourId, 'trigger'),
  wait: 0,
  conditions: [],
  actions: [],
});

export const DefaultTourGoToStepAction = tourId => ({
  id: generateId(tourId, 'action'),
  type: 'GO_TO_STEP',
  step_id: '',
});

export const DefaultGoToStepActionData = {
  type: 'GO_TO_STEP',
  step_id: '',
};

export const DefaultStartTourActionData = {
  type: 'START_TOUR',
  tour: {
    tour_id: '',
    step_id: '',
    name: 'Test',
  },
};

export const DefaultCloseTourActionData = {
  type: 'CLOSE_TOUR',
};

export const DefaultNavigateActionData = {
  type: 'NAVIGATE',
  navigate_target_new_tab: true,
  url: 'https://google.com',
};

export const DefaultEvalJSActionData = {
  type: 'EVAL_JS',
  code: 'console.log("Hello world!");',
};
