import actions from './actions';
import { addedOptInFields } from '../constants';
import { getFilteredFields } from '../../components/Forms/helpers';
import _ from 'lodash';

const initState = {
  optInFields: [],
  filteredOptInFields: [],
  addedOptInFields: addedOptInFields,
  isFormTableLoading: false,
  selectedTemplateData: [],
  searchedValue: '',
  tabKey: '1',
  formTemplateSearchValue: '',
  previewModalVisible: false,
  formDrawerStatus: false,
  formDrawerLoading: false,
  selectedFormStatus: 'live',
  formStatusList: [],
  formTableLists: [],
  isDisableFormAction: false,
  formId: '',
  editorPageDetails: {},
  formTypesList: [],
  selectedFormType: 'popup',
  formTemplatesList: [],
  formSubmitActions: [],
  selectedAction: null,
  formImageUrl: null,
  formSettingsDetails: {
    title: '',
    sendConfirmation: 'all',
    customConfirmationLink: '',
    sendUserData: false,
    displayPoweredByLink: false,
    mail_subscription_id: null,
  },
  formLoading: false,
  formV2TemplatesLoading: true,
  editorFormStatus: false,
  editorPageLoading: false,
  editorPageStatusLoading: false,
  isRequireGdpr: false,
  formAlignment: 'right',
  publishTabKey: '1',
  selectedFormSecret: '',
  continuationToken: null,
  setFormType: 'all',
  followUpStatus: false,
  liquidVariables: [],
  htmlFormData: null,
  isReOrderForm: false,
  formSortKey: 'priority',
  formSortOrder: 'asc',
  duplicateFormDrawerDetails: {
    title: '',
    id: '',
    isDuplicate: false,
  },
  formVersion: null,
  formDetails: {},
  formTableDetails: [],
  formExportCSVLoader: false,
  imageGalleryData: [],
  imagePickerElementData: {}, // Holds current element/section data
  imagePickerVisibility: false,
  uploadFormImageLoader: false,
  editDrawerFormTemplate: {},
  formResponsesLoader: false,
};

export default function formsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_FORM_LIST:
      return {
        ...state,
        isFormTableLoading: true,
      };
    case actions.FETCH_FORM_LIST_SUCCESS:
      return {
        ...state,
        isFormTableLoading: false,
        formStatusList: action.payload.forms_count,
        formTableLists: action.payload.forms,
      };
    case actions.FETCH_FORM_LIST_FAILURE:
      return {
        ...state,
        isFormTableLoading: false,
      };
    case actions.SET_FORM_STATUS:
      return {
        ...state,
        selectedFormStatus: action.payload,
      };
    case actions.DELETE_FORM:
      return {
        ...state,
        isDisableFormAction: true,
      };
    case actions.DELETE_FORM_SUCCESS:
      return {
        ...state,
        isDisableFormAction: action.payload,
      };
    case actions.DELETE_FORM_FAILURE:
      return {
        ...state,
        isDisableFormAction: action.payload,
      };
    case actions.APPEND_FORM_LIST:
      return {
        ...state,
        isFormTableLoading: true,
      };
    case actions.APPEND_FORM_LIST_SUCCESS:
      return {
        ...state,
        formTableLists: state.formTableLists.concat(
          action.payload.forms,
        ),
        isFormTableLoading: false,
      };
    case actions.APPEND_FORM_LIST_FAILURE:
      return {
        ...state,
        isFormTableLoading: false,
      };
    case actions.UPDATE_ADDED_OPTIN_FIELD:
      return {
        ...state,
        addedOptInFields: action.payload.updatedAddedOptInField
          ? action.payload.updatedAddedOptInField
          : state.addedOptInFields,
        filteredOptInFields: action.payload.filteredOptInFields
          ? action.payload.filteredOptInFields
          : state.filteredOptInFields,
      };
    case actions.SET_FORM_TEMPLATE_DATA:
      return {
        ...state,
        selectedTemplateData: action.payload,
      };
    case actions.SET_FORM_SEARCH_VALUE:
      return {
        ...state,
        searchedValue: action.payload,
      };
    case actions.SET_FORM_TAB_KEY:
      return {
        ...state,
        tabKey: action.payload,
      };
    case actions.SET_FORM_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        formTemplateSearchValue: action.payload,
      };
    case actions.SET_PREVIEW_MODAL_VISIBLE:
      return {
        ...state,
        previewModalVisible: action.payload,
      };
    case actions.SET_FORM_DRAWER_STATUS:
      return {
        ...state,
        formDrawerStatus: action.payload,
        duplicateFormDrawerDetails: {
          ...state.duplicateFormDrawerDetails,
          isDuplicate: action.isDuplicate,
        },
      };
    case actions.CREATE_FORM:
      return {
        ...state,
        formDrawerLoading: true,
      };
    case actions.CREATE_FORM_SUCCESS:
      return {
        ...state,
        formDrawerStatus: false,
        formDrawerLoading: false,
      };
    case actions.CREATE_FORM_FAILURE:
      return {
        ...state,
        formDrawerStatus: true,
        formDrawerLoading: false,
      };
    case actions.GET_OPT_IN_FIELDS_LIST_SUCCESS:
      let data = action.payload.user_properties;
      const nonEditableProperties = action.payload.non_editable_properties.map(
        property => ({
          property_name: property,
        }),
      );
      data = data.map(optInField => {
        let filteredProperty = optInField;
        filteredProperty.properties = _.differenceBy(
          optInField.properties,
          nonEditableProperties,
          'property_name',
        );
        return filteredProperty;
      });
      return {
        ...state,
        optInFields: _.cloneDeep(data),
        filteredOptInFields: getFilteredFields(_.cloneDeep(data)),
      };
    case actions.SET_LOADER_FOR_SAVING_DETAILS:
      return {
        ...state,
        formLoading: action.formLoading,
      };
    case actions.GET_DETAILS_OF_FORM_EDITOR_PAGE:
      return {
        ...state,
        formId: action.id,
        editorPageLoading: true,
      };
    case actions.GET_DETAILS_OF_FORM_EDITOR_PAGE_SUCCESS:
      return {
        ...state,
        editorPageDetails: action.payload,
        formSubmitActions: action.payload.submit_actions,
        formSettingsDetails: {
          title: action.payload.title,
          sendConfirmation: action.payload.send_confirmation,
          customConfirmationLink: action.payload.custom_confirmation_link,
          displayPoweredByLink: action.payload.display_powered_by_link,
        },
        editorFormStatus: !['draft', 'paused'].includes(action.payload.status),
        editorPageLoading: false,
        formAlignment: action.payload.form_alignment,
        followUpStatus: action.payload.is_follow_up_enabled,
        formVersion: action.payload.version,
      };
    case actions.GET_DETAILS_OF_FORM_EDITOR_PAGE_FAILURE:
      return {
        ...state,
        editorPageLoading: false,
      };
    case actions.EMPTY_EDITOR_DETAILS:
      return {
        ...state,
        editorPageDetails: action.payload,
      };
    case actions.GET_FORM_TYPES_LIST_SUCCESS:
      return {
        ...state,
        formTypesList: action.payload,
      };
    case actions.SET_SELECTED_FORM_TYPES:
      return {
        ...state,
        selectedFormType: action.payload,
      };
    case actions.FETCH_FORM_TEMPLATES_LIST:
      return {
        ...state,
        formV2TemplatesLoading: true,
      };
    case actions.FETCH_FORM_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        formTemplatesList: action.payload,
        formV2TemplatesLoading: false,
      };
    case actions.FETCH_FORM_TEMPLATES_LIST_FAILURE:
      return {
        ...state,
        formV2TemplatesLoading: false,
      };
    case actions.CHANGE_FORM_POSITION:
      return {
        ...state,
        formTableLists: action.data,
      };
    case actions.UPLOAD_FORM_IMAGE:
      return {
        ...state,
        formImageUrl: action.payload,
        uploadFormImageLoader: true,
      };
    case actions.RESET_TEMPLATE_VALUES:
      return {
        ...state,
        searchedValue: '',
        selectedFormType: 'popup',
      };
    case actions.STORE_SUBMIT_ACTION_TYPES:
      const submitActionIndex = state.formSubmitActions.findIndex(
        data => data.button_id === action.payload.button_id,
      );
      let submitArray = state.formSubmitActions;
      if (submitActionIndex > -1) {
        submitArray[submitActionIndex] = action.payload;
      } else {
        submitArray = submitArray.concat(action.payload);
      }
      return {
        ...state,
        formSubmitActions: submitArray,
      };
    case actions.UPDATE_SELECTED_ACTION:
      return {
        ...state,
        selectedAction: action.payload,
      };
    case actions.SET_FORMS_SETTINGS_DETAILS:
      return {
        ...state,
        formSettingsDetails: {
          title:
            action.title || action.title === ''
              ? action.title
              : state.formSettingsDetails.title,
          sendConfirmation: action.sendConfirmation
            ? action.sendConfirmation
            : state.formSettingsDetails.sendConfirmation,
          customConfirmationLink:
            action.customConfirmationLink ||
            action.customConfirmationLink === ''
              ? action.customConfirmationLink
              : state.formSettingsDetails.customConfirmationLink,
          sendUserData: [false, true].includes(action.sendUserData)
            ? action.sendUserData
            : state.formSettingsDetails.sendUserData,
          displayPoweredByLink: [false, true].includes(
            action.displayPoweredByLink,
          )
            ? action.displayPoweredByLink
            : state.formSettingsDetails.displayPoweredByLink,
          mail_subscription_id: action.subscriptionID
            ? action.subscriptionID
            : state.formSettingsDetails.mail_subscription_id,
        },
      };
    case actions.SET_EDITOR_STATUS_VALUE:
      return {
        ...state,
        editorFormStatus: action.payload,
      };
    case actions.SET_EDITOR_STATUS_LOADING:
      return {
        ...state,
        editorPageStatusLoading: action.payload,
      };
    case actions.UPDATE_FORM_SUCCESS:
      let lists = _.cloneDeep(state.formTableLists);
      const selectedFormStatus = state.selectedFormStatus;
      if (selectedFormStatus === 'all') {
        lists = lists.map(list => {
          if (list.form_secret === action.id) {
            list.status = action.payload.status;
            list.updated_at = action.payload.updated_at;
          }
          return list;
        });
      } else {
        lists = lists.filter(list => list.form_secret !== action.id);
      }
      let formDetailsTemp = { ...state.formDetails };
      formDetailsTemp['title'] = action.payload.title;

      return {
        ...state,
        formTableLists: lists,
        editorPageDetails: action.payload,
        formDetails: formDetailsTemp,
        isPublish: action.payload.isPublish,
        editDrawerFormTemplate: action.payload,
      };
    case actions.SET_FORM_EDITOR_PAGE_LOADER:
      return {
        ...state,
        editorPageLoading: action.payload,
      };
    case actions.UPDATE_GDPR_ENABLED:
      return {
        ...state,
        isGdprEnabled: action.payload.gdprEnabled,
        isRequireGdpr: action.payload.isRequireGdpr,
      };
    case actions.REMOVE_SUBMIT_ACTION_TYPES:
      const filteredActions = state.formSubmitActions.filter(
        submitAction => submitAction.button_id !== action.buttonId,
      );
      return {
        ...state,
        formSubmitActions: filteredActions,
      };
    case actions.SET_FORM_ALIGNMENT:
      return {
        ...state,
        formAlignment: action.payload,
      };
    case actions.SET_PUBLISH_TAB_KEY:
      return {
        ...state,
        publishTabKey: action.payload,
      };
    case actions.SET_SELECTED_FORM_SECRET:
      return {
        ...state,
        selectedFormSecret: action.payload,
      };
    case actions.GET_IMAGE_GALLERY_SUCCESS:
      return {
        ...state,
        imageGalleryData: action.payload,
      };
    case actions.UPLOAD_FORM_IMAGE_SUCCESS:
      return {
        ...state,
        imageGalleryData: [action.payload, ...state.imageGalleryData],
        uploadFormImageLoader: false,
      };
    case actions.UPLOAD_FORM_IMAGE_FAILURE:
      return {
        ...state,
        uploadFormImageLoader: false,
      };
    case actions.SET_ASSET_MANAGER_DATA:
      if (action.payload) {
        return {
          ...state,
          imagePickerElementData: action.payload,
          imagePickerVisibility: action.visibility,
        };
      }
      return {
        ...state,
        imagePickerVisibility: action.visibility,
      };
    case actions.UPDATE_IMAGE_CONTINUATION_TOKEN:
      return {
        ...state,
        continuationToken: action.payload,
      };
    case actions.SET_SELECTED_FORM_TYPE:
      return {
        ...state,
        setFormType: action.payload,
      };
    case actions.SET_FOLLOW_UP_STATUS:
      return {
        ...state,
        followUpStatus: action.payload,
      };
    case actions.FETCH_LIQUID_VARIABLES_SUCCESS:
      const liquidTags = action.payload.map(tag => [tag.value, tag.key]);
      return {
        ...state,
        liquidVariables: liquidTags,
      };
    case actions.UPDATE_HTML_FORM_DATA:
      return {
        ...state,
        htmlFormData: window.editor.getHtml(),
      };
    case actions.RE_ORDER_FORM_STATUS:
      return {
        ...state,
        isReOrderForm: action.payload,
      };
    case actions.SET_FORM_SORT_VALUES:
      return {
        ...state,
        formSortKey: action.sortKey,
        formSortOrder: action.sortOrder,
      };
    case actions.SET_DUPLICATE_DRAWER_FORM_DETAILS:
      return {
        ...state,
        duplicateFormDrawerDetails: {
          ...state.duplicateFormDrawerDetails,
          title: action.title,
          id: action.id,
          isDuplicate: action.isDuplicate,
        },
      };
    case actions.DUPLICATE_FORM:
      return {
        ...state,
        formDrawerLoading: true,
      };
    case actions.DUPLICATE_FORM_SUCCESS:
      return {
        ...state,
        formDrawerStatus: false,
        formDrawerLoading: false,
      };
    case actions.DUPLICATE_FORM_FAILURE:
      return {
        ...state,
        formDrawerStatus: true,
        formDrawerLoading: false,
      };
    case actions.FETCH_FORMS_ONGOING_REPORTS: {
      return {
        ...state,
        formResponsesLoader: true,
      };
    }
    case actions.FETCH_FORMS_ONGOING_REPORTS_SUCCESS:
      return {
        ...state,
        formDetails: action.payload.form,
        formTableDetails: action.payload.form_data,
        formResponsesLoader: false,
      };
    case actions.FETCH_FORMS_ONGOING_REPORTS_FAILURE:
      return {
        ...state,
        formResponsesLoader: false,
      };
    case actions.RESET_REPORT_PAGE_DETAILS:
      return {
        ...state,
        formDetails: {},
        formTableDetails: [],
      };
    case actions.EXPORT_FORMS_CSV_MODAL:
      return {
        ...state,
        isExportCSVModalVisible: action.payload,
      };
    case actions.EXPORT_FORMS_RESPONSE_CSV:
      return {
        ...state,
        formExportCSVLoader: true,
      };
    case actions.EXPORT_FORMS_CSV_LOADER:
      return {
        ...state,
        formExportCSVLoader: action.payload,
      };
    default:
      return state;
  }
}
