import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import sharedActions from '../../../redux/sharedReducers/actions';
import JungleSelect from 'react-jungle-select';
import { Select } from 'antd';
import chatActions from '../../../redux/chat/actions';

const ArticleWidget = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const articles = useSelector(({ Chat }) => Chat.articles);
  const {
    omniSearchEventListener,
    removeOmniSearchListener,
    pushContentIntoComposer,
    supportedLanguages,
    selectedArticleLanguage,
  } = props;

  useEffect(() => {
    omniSearchEventListener();
    return () => {
      removeOmniSearchListener();
    };
  }, []);

  const handleArticleSelect = (article) => {
    pushContentIntoComposer(
      `<a href="${article.article_url}" target="_blank" rel="noreferrer noopener nofollow">${article.title}</a>`,
      true,
    );
    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  const handleLanguage = (code) => {
    const setSelectedLanguage = new Promise((resolve) => {
      resolve(
        dispatch({
          type: sharedActions.SET_SELECTED_ARTICLE_LANGUAGE,
          payload: code,
        }),
      );
    });
    setSelectedLanguage.then(() =>
      dispatch({ type: chatActions.FETCH_ARTICLES, offset: '' }),
    );
  };

  const getCommandItem = (item) => (
    <div className="command-item">
      <div className="command-item-inner">
        <span className="command-content article-meta">
          <span className="article-title">
            <i className="fal fa-file-alt" /> {item.title}
          </span>
          <span className="article-body">{item.text_body}</span>
        </span>
      </div>
    </div>
  );

  return (
    <JungleSelect
      items={articles}
      renderItem={(item) => getCommandItem(item)}
      className={'articles-list'}
      placeholder="Search articles..."
      searchable={true}
      searchableAttributes={['title', 'text_body']}
      listWrapper={(list) => (
        <>
          {supportedLanguages.length > 1 && (
            <Select
              className={'form-item select-md supported-languages'}
              placeholder="Select language..."
              showSearch
              optionFilterProp="name"
              dropdownMatchSelectWidth={false}
              value={selectedArticleLanguage}
              onChange={handleLanguage}
              dropdownClassName={'supported-language-dropdown'}
            >
              {supportedLanguages.map((language) => (
                <Option key={language.code} value={language.code}>
                  {language.name}
                </Option>
              ))}
            </Select>
          )}
          <div className="command-list">{list}</div>
        </>
      )}
      onChange={(value) => handleArticleSelect(value)}
      mode="list"
      selectFirstItem={true}
      autofocus={true}
    />
  );
};
export default ArticleWidget;
