import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect, ReactReduxContext } from 'react-redux';
import App from './containers/App/App';
import DeveloperApp from './containers/App/DeveloperApp';
import Prime from './containers/Settings/Prime';
import NotFound from './containers/Page/404';
import asyncComponent from './helpers/AsyncFunc';
import { Auth } from './components/Auth';
import { fetchProjectSecretKey, trackPageview } from './helpers/utility';
import WorkSpaceList from './containers/WorkSpaceList/WorkSpaceList';
import Oauth2Callback from './components/Oauth2Callback';
import StripeCallback from './containers/Settings/StripeIntegrationCallback';
import ZoomCallback from './containers/Settings/ZoomIntegrationCallback';
import TwitterCallback from './containers/Settings/TwitterIntegrationCallback';
import SatisfactionRating from './containers/Page/SatisfactionRating';
import WooCommerceCallback from './containers/Settings/WooCommerceCallback';
import Unsubscription from './containers/Page/Unsubscription';
import OutlookCallback from './components/OutlookCallback';
import OauthSuccess from './containers/App/OauthSuccess';
import MicrosoftIdentify from './components/MicrosoftIdentify';
import CanvasBuilder from './containers/AppPlatform/CanvasBuilder';
import OauthAuthorize from './containers/App/OauthAuthorize';
import Maintenance from './containers/Maintenance/index.js';
import FeedbackContainer from './containers/Page/FeedbackContainer';

const RestrictedRoute = ({
  component: Component,
  isFoundProjectKey,
  setReferrer,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      !isFoundProjectKey ? (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
            ...(setReferrer
              ? {
                search: `?referrer=${encodeURIComponent(
                  `${document.location.pathname}${document.location.search}`,
                )}`,
              }
              : {}),
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

function usePageViews() {
  const [count, setCount] = useState(0);
  let location = useLocation();

  useEffect(
    () => {
      if (count > 0) {
        trackPageview();
        window.gist && window.gist.trackPageView();
      } else setCount(count + 1);
    },
    [location.pathname],
  );
}

const Routes = props => {
  const { isFoundProjectKey } = props;
  usePageViews();

  return (
    <div>
      <Switch>
        <Route
          exact
          path={'/'}
          render={() => <Redirect to={{ pathname: '/login' }} />}
        />
        <Route
          exact
          path={'/users/sign_in'}
          render={() => <Redirect to={{ pathname: '/login' }} />}
        />
        <Route
          exact
          path={'/users/sign_up'}
          render={() => <Redirect to={{ pathname: '/signup' }} />}
        />
        <Route
          exact
          path={'/sign_up'}
          render={() => <Redirect to={{ pathname: '/signup' }} />}
        />
        <Route
          exact
          path={'/sign-up'}
          render={() => <Redirect to={{ pathname: '/signup' }} />}
        />
        <Route
          path="/login"
          sensitive={true}
          component={asyncComponent(() => import('./containers/Login/Login'))}
        />
        <Route
          sensitive={true}
          path="/signup"
          component={asyncComponent(() =>
            import('./containers/OnBoarding/CreateAccount'),
          )}
        />
        <Route
          sensitive={true}
          path="/onboarding"
          component={asyncComponent(() =>
            import('./containers/OnBoarding/OnBoarding'),
          )}
        />
        <Route
          sensitive={true}
          path="/choose-plan"
          component={asyncComponent(() =>
            import('./containers/OnBoarding/ChoosePlan'),
          )}
        />
        <Route
          sensitive={true}
          path="/start-trial"
          component={asyncComponent(() =>
            import('./containers/OnBoarding/StartTrial'),
          )}
        />
        <Route
          path="/users/password/edit"
          component={asyncComponent(() =>
            import('./containers/Page/ChangePassword'),
          )}
        />

        <Route
          sensitive={true}
          path="/forgot-password"
          component={asyncComponent(() =>
            import('./containers/Page/ForgotPassword'),
          )}
        />
        <Route
          path={'/invites/:token'}
          component={asyncComponent(() =>
            import('./containers/Page/EmailAcceptDecline'),
          )}
        />
        <Route path={'/conversations/rating'} component={SatisfactionRating} />
        <Route path={'/subscriptions/unsubscribe'} component={Unsubscription} />
        <Route
          path={'/forms/email-confirmation'}
          component={asyncComponent(() =>
            import('./containers/Page/FormsRedirectPage'),
          )}
        />
        <Route
          sensitive={true}
          path="/room/:roomId"
          component={asyncComponent(() =>
            import('./containers/Page/CallContainer'),
          )}
        />
        <Route sensitive={true} path="/workspaces" component={WorkSpaceList} />
        <Route
          sensitive={true}
          path={'/shopify/choose-workspace'}
          component={WorkSpaceList}
        />
        <Route
          sensitive={true}
          path="/stripe-callback"
          component={StripeCallback}
        />
        <Route
          sensitive={true}
          path="/zoom-callback"
          component={ZoomCallback}
        />
        <Route
          sensitive={true}
          path="/oauth2callback"
          component={Oauth2Callback}
        />
        <Route
          sensitive={true}
          path="/microsoft-callback"
          component={OutlookCallback}
        />
        <Route
          sensitive={true}
          path="/maintenance"
          component={Maintenance}
        />
        <Route
          sensitive={true}
          path="/.well-known/microsoft-identity-association.json"
          component={MicrosoftIdentify}
        />
        <Route
          sensitive={true}
          path="/twitter/callback"
          component={TwitterCallback}
        />
        <Route
          sensitive={true}
          path="/blackfriday/checkout"
          render={() => <Redirect to={{ pathname: '/limited-time-offer' }} />}
          // component={asyncComponent(() =>
          //   import('./containers/DiscountedAllInOne/DiscountedAllInOne'),
          // )}
        />
        <Route
          sensitive={true}
          path="/woocommerce-offer/checkout"
          component={asyncComponent(
            () => (window.location.href = 'https://getgist.com/expired/'),
          )}
        />
        <Route
          sensitive={true}
          path="/exclusive-offer/checkout"
          render={() => <Redirect to={{ pathname: '/limited-time-offer' }} />}
          // component={asyncComponent(() =>
          //   import('./containers/DiscountedAllInOne/DiscountedAllInOne'),
          // )}
        />
        <Route
          sensitive={true}
          path="/prime/checkout"
          render={() => <Redirect to={{ pathname: '/limited-time-offer' }} />}
          // component={asyncComponent(() =>
          //   import('./containers/DiscountedAllInOne/DiscountedAllInOne'),
          // )}
        />
        <Route
          sensitive={true}
          path="/limited-time-offer"
          component={asyncComponent(
            () => (window.location.href = 'https://getgist.com/expired/'),
          )}
        />
        <Route
          sensitive={true}
          path="/wpcrafter-exclusive"
          component={asyncComponent(
            () => (window.location.href = 'https://getgist.com/expired/'),
          )}
        />
        <Route
          sensitive={true}
          path="/woocommerce/callback"
          component={WooCommerceCallback}
        />

        <Route
          sensitive={true}
          path="/emma/:type"
          component={FeedbackContainer}
        />

        <Route exact path="/404" component={NotFound} />
        <Route
          exact
          path={'/projects'}
          render={() => (
            <Redirect
              to={{
                pathname: '/workspaces',
              }}
            />
          )}
        />
        <RestrictedRoute
          path="/projects/:project_secret_key/ltd-upgrade"
          component={Prime}
          isFoundProjectKey={isFoundProjectKey}
        />
        <RestrictedRoute
          path="/projects"
          component={App}
          isFoundProjectKey={isFoundProjectKey}
        />
        <RestrictedRoute
          path="/developer"
          component={DeveloperApp}
          isFoundProjectKey={isFoundProjectKey}
        />
        <RestrictedRoute
          path="/oauth/authorize"
          component={OauthAuthorize}
          isFoundProjectKey={isFoundProjectKey}
          setReferrer={true}
        />
        <Route
          exact
          path={'/accounts'}
          render={() => (
            <Redirect
              to={{
                pathname: `/projects/${fetchProjectSecretKey()}/account`,
              }}
            />
          )}
        />
        <Route
          sensitive={true}
          path="/install_success"
          component={OauthSuccess}
        />
        <Route
          sensitive={true}
          path="/canvas-builder"
          component={CanvasBuilder}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

const PublicRoutes = ({ history, isAuthenticating, isFoundProjectKey }) => {
  if (isAuthenticating) {
    return <Auth />;
  }

  return (
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <Routes isFoundProjectKey={isFoundProjectKey} />
    </ConnectedRouter>
  );
};
export default connect(state => ({
  isFoundProjectKey: state.Auth.currentProjectSecretKey,
  isAuthenticating: state.Auth.isAuthenticating,
}))(PublicRoutes);
