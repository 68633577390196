import actions from './actions';
import { tableTypes } from '../../components/constants';

export const initState = {
  tableLists: [],
  tableLoading: false,
  createChatDrawer: false,
  selectedTableData: [],
  list: [],
  createChatLoader: false,
  openTagApplyModal: false,
  openSuggestion: false,
  setMessageChatName: '',
  setDuplicateId: '',
  isReOrderMessageChats: false,
  openConfirmPopup: false,
  showDrawerFrom: 'on_going_chat',
  chatTabKey: 'chatOnGoing',
  activeChatName: '',
  isSelectChannelModalVisible: false,
  messageTagsListLastId: '',
  messageTagsListPage: 1,
  currentMessageTag: { id: '' },
  isChatChange: false,
  selectedTemplateId: null,
};

export default function messagesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TABLE_LISTS:
      return {
        ...state,
        tableLoading: true,
      };
    case actions.FETCH_TABLE_LISTS_SUCCESS:
      let tableLists = [],
        counts = [];
      if (action.tableState === tableTypes.chatOnGoing) {
        tableLists = action.payload.triggered_chats;
        counts = action.payload.triggered_chats_count;
      }
      return {
        ...state,
        tableLoading: false,
        tableLists: tableLists,
        list: counts,
      };
    case actions.FETCH_TABLE_LISTS_FAILURE:
      return {
        ...state,
        tableLoading: false,
      };
    case actions.APPEND_TABLE_LISTS:
      return {
        ...state,
        tableLoading: true,
      };
    case actions.APPEND_TABLE_LISTS_SUCCESS:
      let appendTableLists = [],
        appendCounts = [];
      if (action.payload) {
        appendTableLists =
          action.payload.triggered_chats &&
          action.payload.triggered_chats.length
            ? action.payload.triggered_chats
            : [];
        appendCounts =
          action.payload.triggered_chats_count &&
          action.payload.triggered_chats_count.length
            ? action.payload.triggered_chats_count
            : state.list;
      }
      return {
        ...state,
        tableLists: appendTableLists.length ? state.tableLists.concat(appendTableLists) : state.tableLists,
        list: appendCounts.length ? appendCounts : state.list,
        tableLoading: false,
      };
    case actions.APPEND_TABLE_LISTS_FAILURE:
      return {
        ...state,
        tableLoading: false,
      };
    case actions.CREATE_CHAT_DRAWER:
      return {
        ...state,
        createChatDrawer: action.payload,
        setMessageChatName: action.name ? `${action.name}(Copy)` : '',
        setDuplicateId: action.id ? action.id : '',
        showDrawerFrom: action.requestFrom,
        selectedTemplateId: action.templateId,
      };
    case actions.SET_SELECTED_ROWS:
      return {
        ...state,
        selectedTableData: action.selectedRowKeys,
      };
    // case actions.APPLY_CHAT_TAG:
    case actions.CREATE_MESSAGES_CHAT:
      return {
        ...state,
        createChatLoader: true,
      };
    case actions.CREATE_MESSAGES_CHAT_SUCCESS:
      return {
        ...state,
        createChatLoader: false,
        createChatDrawer: false,
      };
    case actions.CREATE_MESSAGES_CHAT_FAILURE:
      return {
        ...state,
        createChatLoader: false,
      };
    case actions.DUPLICATE_MESSAGE_CHAT:
      return {
        ...state,
        createChatLoader: true,
      };
    case actions.DUPLICATE_MESSAGE_CHAT_SUCCESS:
      return {
        ...state,
        createChatLoader: false,
        createChatDrawer: false,
      };
    case actions.DUPLICATE_MESSAGE_CHAT_FAILURE:
      return {
        ...state,
        createChatLoader: false,
      };
    case actions.UPDATE_MESSAGE_CHAT:
      return {
        ...state,
        tableLoading: true,
      };
    case actions.UPDATE_MESSAGE_CHAT_FAILURE:
      return {
        ...state,
        tableLoading: false,
      };
    case actions.APPLY_CHAT_TAG_SUCCESS:
      return {
        ...state,
        selectedTableData: [],
      };
    case actions.CHANGE_TABLE_ELEMENT_POSITION:
      return {
        ...state,
        tableLists: action.data,
      };
    case actions.RE_ORDER_MESSAGE_CHAT_STATUS:
      return {
        ...state,
        isReOrderMessageChats: action.payload,
      };
    case actions.SET_CONFIRM_MODEL:
      return {
        ...state,
        openConfirmPopup: action.payload,
      };
    case actions.SET_CHAT_TAB_KEY:
      return {
        ...state,
        chatTabKey: action.payload,
      };
    case actions.SET_CHAT_NAME:
      return {
        ...state,
        activeChatName: action.payload,
      };
    case actions.REMOVE_DELETED_ON_GOING_CHAT_ID:
      let selectedRowKeys = state.selectedTableData;
      if (selectedRowKeys && selectedRowKeys.length) {
        selectedRowKeys = selectedRowKeys.filter(
          id => id !== action.deletedChatId,
        );
      }
      return {
        ...state,
        selectedTableData: selectedRowKeys
          ? selectedRowKeys
          : state.selectedTableData,
      };
    case actions.SET_SELECT_CHANNEL_MODAL_STATUS:
      return {
        ...state,
        isSelectChannelModalVisible: action.payload,
      };
    case actions.SET_TABLE_LISTS:
      return {
        ...state,
        tableLists: action.payload,
      };
    case actions.SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES:
      return {
        ...state,
        messageTagsListLastId: action.lastId,
        messageTagsListPage: action.page,
      };
    case actions.SET_CREATED_MESSAGE_TAG:
      return {
        ...state,
        currentMessageTag: action.payload,
      };
    case actions.SET_CHAT_CHANGE:
      return {
        ...state,
        isChatChange: action.payload,
      };
    default:
      return state;
  }
}
