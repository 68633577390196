import '../AutoEmails/style.scss';
import '../Broadcast/style.scss';
import React, { useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import _ from 'lodash';
import { store } from '../../../redux/store';
import autoEmailActions from '../../../redux/email/autoEmails/actions';
import broadcastActions from '../../../redux/email/broadcast/actions';
import campaignEmailActions from '../../../redux/email/campaign/actions';
import { brandingName } from '../constants';
import { menu } from '../../TopNavBar/constants';
import workflowActions from '../../../redux/automation/workflow/actions';

function AllTemplateTab(props) {
  const {
      isFromAutoEmails,
      emailTemplates,
      emailTemplatesLoader,
      selectedTemplateId,
      dynamicData,
      restrictedFeatures,
      emailType,
      isFromWorkflow,
      isFromCampaignEmails,
      isfromSelectTemplateContainer,
    } = props,
    actions = isFromAutoEmails
      ? autoEmailActions
      : isFromCampaignEmails
        ? campaignEmailActions
        : broadcastActions;
  useEffect(() => {
    if (isFromWorkflow && emailTemplates && emailTemplates.length) {
      setTemplateId(emailTemplates[0]);
    }
  },[]);
  const setTemplateId = template => {
    store.dispatch({
      type: actions.SET_SELECTED_TEMPLATE_ID,
      templateId: template.id,
    });
    if (isFromWorkflow) {
      store.dispatch({
        type: workflowActions.SET_SELECTED_TEMPLATE_CONTENT,
        payload: template.email_content,
      });
    }
    if (emailType === 'visual_builder') {
      store.dispatch({
        type:
          actions[
            `UPDATE_${
              isFromAutoEmails
                ? 'AUTO_EMAIL_'
                : isFromCampaignEmails
                  ? 'CAMPAIGN_EMAIL_'
                  : ''
            }VISUAL_BUILDER_JSON`
          ],
        payload: template.visual_builder_json,
      });
      store.dispatch({
        type:
          actions[
            `FETCH_${
              isFromAutoEmails
                ? 'AUTO_EMAIL_'
                : isFromCampaignEmails
                  ? 'CAMPAIGN_EMAIL_'
                  : ''
            }VISUAL_BUILDER_JSON_SUCCESS`
          ],
        payload: template.visual_builder_json,
      });
    }
  };
  if (emailTemplatesLoader) {
    return (
      <div className="all-template-tab">
        <Row type="flex">
          {_.times(4, key => (
            <Col span={12} key={key}>
              <div className={'template'}>
                <Skeleton
                  active
                  title={false}
                  paragraph={{ rows: 8, width: '100%' }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
  return (
    <div
      className={`all-template-tab ${
        emailType === 'visual_builder' ? 'visual-builder-template-tab' : ''
      }`}
    >
      <Row type="flex">
        {emailTemplates.length ? (
          emailTemplates.map(template => (
            <Col
              span={
                isfromSelectTemplateContainer
                  ? 6
                  : emailType === 'visual_builder'
                    ? 8
                    : 12
              }
              key={template.id}
            >
              <div className="template">
                <iframe
                  title={template.id}
                  className={
                    template.id === selectedTemplateId ? 'active-image' : ''
                  }
                  srcDoc={template.email_content
                    .replace(
                      new RegExp('\\{{ avatar_url }}'),
                      dynamicData.avatar_url && dynamicData.avatar_url,
                    )
                    .replace(
                      new RegExp('\\{{ from_name }}'),
                      dynamicData.full_name ? dynamicData.full_name : '',
                    )
                    .replace(
                      new RegExp('\\{{ company_name }}'),
                      dynamicData.company_name ? dynamicData.company_name : '',
                    )
                    .replace(
                      new RegExp('\\{{ project_logo }}'),
                      dynamicData.logo_url ? dynamicData.logo_url : '',
                    )
                    .replace(
                      new RegExp('\\{{ company_address }}'),
                      dynamicData.postal_address
                        ? dynamicData.postal_address
                        : '',
                    )
                    .replace(
                      new RegExp(/{\s{0,}{\s{0,}top_bar_color\s{0,}}\s{0,}}/),
                      dynamicData.settings &&
                        dynamicData.settings.top_bar_color &&
                        dynamicData.settings.top_bar_color,
                    )
                    .replace(
                      new RegExp(
                        /{\s{0,}{\s{0,}unsubscribe_link_text\s{0,}}\s{0,}}/,
                      ),
                      dynamicData.settings &&
                        dynamicData.settings.unsubscribe_link_text &&
                        dynamicData.settings.unsubscribe_link_text,
                    )
                    .replace(
                      new RegExp(
                        /{\s{0,}{\s{0,}unsubscribe_message_text\s{0,}}\s{0,}}/,
                      ),
                      dynamicData.settings &&
                        dynamicData.settings.unsubscribe_message_text &&
                        dynamicData.settings.unsubscribe_message_text,
                    )
                    .replace(
                      new RegExp('\\{{ branding }}'),
                      restrictedFeatures.indexOf(menu.brandingRemoved) !== -1
                        ? brandingName
                        : '',
                    )}
                  sandbox=""
                />
                <div
                  className="overlay"
                  onClick={() => setTemplateId(template)}
                />
              </div>
              <p>{template.name}</p>
            </Col>
          ))
        ) : (
          <Col span={24}>
            <div className="no-data">No custom template found</div>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default AllTemplateTab;
