import React, { Fragment, useState } from 'react';
import '../style.scss';

const SingleSelectComponent = props => {
  const { component, onSubmit, onChange } = props;
  const [hasSubmit] = useState(
    component.action && component.action.type === 'submit',
  );

  return (
    <div
      className={`single-select-container${
        component.save_state === 'failed' ? ' single-select-failed' : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
    >
      <label className="label-single-select-container">{component.label}</label>

      <div className="single-select-options">
        {component.options &&
          component.options.map((option, index) => (
            <Fragment key={index}>
              <input
                className="single-select-option-radio"
                type="radio"
                {...(option.id ? { 'data-component-id': option.id } : {})}
                {...(option.id && component.id
                  ? { id: `${component.id}-${option.id}` }
                  : {})}
                {...(component.id ? { name: component.id } : {})}
                {...(option.disabled || component.disabled
                  ? { disabled: true }
                  : {})}
                {...(component.value && component.value === option.id
                  ? { defaultChecked: true }
                  : {})}
                onChange={() => {
                  onChange(component.id, option.id);
                }}
              />

              <label
                className={`single-select-option-label${
                  option.disabled || component.disabled ? ' disabled' : ''
                }`}
                htmlFor={`${component.id}-${option.id}`}
                {...(hasSubmit
                  ? {
                    onClick: () => {
                      onSubmit(option.id);
                    },
                    }
                  : {})}
              >
                {option.text}
              </label>
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default SingleSelectComponent;
