export const panels = ['#general-settings', '#blocks'];

export const componentsWithoutTraits = [
  'box',
  'divider',
  'text',
  'input',
  'gdpr',
  'formInput',
  'downArrow',
];
// grapesjs config

export const gjsStylePrefix = 'gist-';

export const config = {
  blocks: [
    'column1',
    'column11',
    'column12',
    'column21',
    'column111',
    'column121',
    'column1111',
    'text',
    'link',
    'image',
    'video',
    'map',
    'divider',
    'form',
    'button',
  ],
  flexGrid: 1,
  stylePrefix: gjsStylePrefix,
  addBasicStyle: true,
  category: 'Basic',
  labelColumn1: '1 Column',
  labelColumn11: '2 Columns 1:1',
  labelColumn111: '3 Columns 1:1:1',
  labelColumn12: '2 Columns 1:2',
  labelColumn21: '2 Columns 2:1',
  labelColumn121: '3 Columns 1:2:1',
  labelColumn1111: '4 Columns 1:1:1:1',
  labelText: 'Text',
  labelLink: 'Link',
  labelImage: 'Image',
  labelVideo: 'Video',
  labelDivider: 'Divider',
  labelForm: 'Form',
  labelButton: 'Button',
};

//the footer is in top because the last-child property is not applied since the footer is in absolute position.
export const buttonConfirmationModal = {
  title: 'Edit Form Actions',
  content: `<div class="modal-content"><div class="form-field">
                           <label>When someone submits this form</label>
                           <select>
                           <option id="thank-you-option" value="thank-you-message" style="display: none">Show a thank you message</option>
                           <option id="submit-option" value="submit" style="display: none">Close the form</option>
                           <option value="redirect">Redirect to a URL</option>
                           </select>
                           </div>
                           <div class="form-field" style="display:none;" id="thank-you-message-content">
                           <label>Message</label>
                           <textarea class="form-item input-sm gist-form-input" id="thank-you-text" placeholder="Thank you for subscribing :)"></textarea>
                           </div>
                           <div class="form-field" id="url" style="display:none;">
                           <label>Which URL</label>
                           <input type="text" class="form-item input-sm gist-form-input" id="redirect-url" placeholder="https://domain.com"/>
                           </div>
                           <div class="form-field form-switch" id="new-tab" style="display:none;">
                           <label class="label">Open in a new tab?</label>
                           <label class="switch"> 
  <input type="checkbox" id="redirect-option">
  <span class="knob"></span>
</label>
                           </div>
                           <div class="form-field form-switch" id="send-data" style="display:none;">
                           <label class="label">Send form submission data to redirect page</label>
                           <label class="switch"> 
  <input type="checkbox" id="send-data-option">
  <span class="knob"></span>
</label>
                           </div>
                           </div>
                           <div class="mdl-footer">
                          <button class="ant-btn btn btn-primary btn-sm">Done</button>
</div>
                           `,
};

export const gdprText = 'I agree with terms and privacy policy.';

export const customFontFamilies = [
  {
    key: '"Roboto Slab',
    value: 'Roboto+Slab:300,400,500,600,700',
  },
  {
    key: 'Adamina',
    value: 'Adamina',
  },
  {
    key: '"Crete Round"',
    value: 'Crete+Round:400,400i',
  },
  {
    key: 'Domine',
    value: 'Domine:400,700',
  },
  {
    key: '"Libre Baskerville"',
    value: 'Libre+Baskerville:400,400i,700',
  },
  {
    key: 'Lora',
    value: 'Lora:400,400i,700,700i',
  },
  {
    key: 'Merriweather',
    value: 'Merriweather:300,300i,400,400i,700,700i',
  },
  {
    key: '"Noto Serif"',
    value: 'Noto+Serif:400,400i,700,700i',
  },
  {
    key: '"Playfair Display"',
    value: 'Playfair+Display:400,400i,700,700i',
  },
  {
    key: '"Old Standard TT"',
    value: 'Old+Standard+TT:400,400i,700',
  },
  {
    key: 'Cinzel',
    value: 'Cinzel:400,700',
  },
  {
    key: '"Open Sans"',
    value: 'Open+Sans:300,300i,400,400i,600,600i,700,700i',
  },
  {
    key: 'Roboto',
    value: 'Roboto+Slab:300,400,500,600,700',
  },
  {
    key: 'Lato',
    value: 'Lato:300,300i,400,400i,700,700i',
  },
  {
    key: 'Montserrat',
    value: 'Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i',
  },
  {
    key: '"Fira Sans"',
    value: 'Fira+Sans:300,300i,400,400i,500,500i,600,600i,700,700i',
  },
  {
    key: '"Source Sans Pro"',
    value: 'Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i',
  },
  {
    key: 'Ubuntu',
    value: 'Ubuntu:300,300i,400,400i,500,500i,700,700i',
  },
  {
    key: '"Noto Sans"',
    value: 'Noto+Sans:400,400i,700,700i',
  },
  {
    key: 'Oxygen',
    value: 'Oxygen:300,400,700',
  },
  {
    key: 'Oswald',
    value: 'Oswald:300,400,500,600,700',
  },
  {
    key: '"Fjalla One"',
    value: 'Fjalla+One|Josefin+Slab:300,300i,400,400i,600,600i,700,700i',
  },
  {
    key: 'Poppins',
    value: 'Poppins:300,300i,400,400i,500,500i,600,600i,700,700i',
  },
  {
    key: 'Alegreya',
    value: 'Alegreya:400,400i,500,500i,700,700i',
  },
  {
    key: '"Josefin Slab"',
    value: 'Josefin+Slab:300,300i,400,400i,600,600i,700,700i',
  },
  {
    key: 'Raleway',
    value: 'Raleway:300,300i,400,400i,500,500i,600,600i,700,700i',
  },
];

export const defaultFontFamilies = [
  'Arial',
  '"Comic Sans MS"',
  '"Courier New"',
  'Georgia',
  '"Lucida Sans Unicode"',
  'Tahoma',
  '"Times New Roman"',
  '"Trebuchet MS"',
  'Verdana',
  'Times',
  'system-ui',
];

export const devices = [
  { label: 'Desktop', value: 'desktop' },
  { label: 'Mobile', value: 'mobile' },
  { label: 'Tablet', value: 'tablet' },
  { label: 'All', value: 'all' },
];

export const days = [
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
];

export const months = [
  { label: 'January', value: 'january' },
  { label: 'February', value: 'february' },
  { label: 'March', value: 'march' },
  { label: 'April', value: 'april' },
  { label: 'May', value: 'may' },
  { label: 'June', value: 'june' },
  { label: 'July', value: 'july' },
  { label: 'August', value: 'august' },
  { label: 'September', value: 'september' },
  { label: 'October', value: 'october' },
  { label: 'November', value: 'november' },
  { label: 'December', value: 'december' },
];

export const urlConditions = ['is_any_page', 'is_homepage', 'is_not_homepage'];

export const overrideCss = 'body { height: 100vh; }';

export const popUp =
  'body { display: flex; justify-content: center; align-items: center; height: 100vh }';

export const slidIn =
  'body { display: flex; align-items: flex-end; flex-direction: column-reverse; height: 100vh; padding: 0px 20px 0px 0px; }';

export const fontWeightList = [
  { value: '400', name: 'Regular' },
  { value: '600', name: 'Bold' },
];
export const gdprCss = `
  .${gjsStylePrefix}gdpr {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    text-align: left;
  }
  .${gjsStylePrefix}gdpr-text {
  display: inline;
  }
    .${gjsStylePrefix}form-checkbox {
    border-radius: 0;
    opacity: 0;
    position: absolute;
    z-index: 10;
    cursor:pointer;
    }
    .${gjsStylePrefix}form-checkbox + div {
    position: relative;
    padding-left: 25px;
    }
    .${gjsStylePrefix}form-checkbox:checked + div:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #37a1f6;
    border-radius: 2px;
    border: 1px solid #dcdee5;
    }
        .${gjsStylePrefix}form-checkbox + div:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #dcdee5;
    }
    .${gjsStylePrefix}form-checkbox:checked + div:after {
   content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
    }
`;

export const canvasCss =
  '.gist-hovered {background-color: rgba(59,151,227,0.5) !important} .gist-gdpr-text.gist-comp-selected {outline-offset: unset;}';

export const embeddedType = 'embedded';

export const defaultThankYouMessage = 'Thank you for subscribing!';
