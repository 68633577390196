import React from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class Buttons extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className="header">
          <h3>
            <span role={'img'} aria-label={'button'}>
              <i className="fas fa-grip-lines" />
            </span>
            Buttons
          </h3>
        </div>
        <div className="body">
          {data.messages &&
            data.messages.map((item, key) => (
              <div className="message-text-component" key={key}>
                <InputTextArea value={item.text} />
              </div>
            ))}

          <div className="buttons-list">
            {data.buttons &&
              data.buttons.map((item, key) => (
                <div
                  key={item.id}
                  className="button-component"
                  data-port-id={item.id}
                >
                  <span className={`primary-btn ${!item.text ? 'error' : ''}`}>
                    {item.text}
                  </span>
                  {item.button_type !== 'start_over' && (
                    <jtk-source port-id={item.id} endpoint={true} />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
