const actions = {
  SET_CHAT_ONGOING_SORT_VALUES: 'SET_CHAT_ONGOING_SORT_VALUES',
  SET_CHAT_ONGOING_SEARCH_VALUE: 'SET_CHAT_ONGOING_SEARCH_VALUE',
  SET_CHAT_ONGOING_STATUS: 'SET_CHAT_ONGOING_STATUS',
  SET_CREATE_CHAT_ONGOING: 'SET_CREATE_CHAT_ONGOING',
  GET_CHAT_PROMPT_DETAILS: 'GET_CHAT_PROMPT_DETAILS',
  GET_CHAT_PROMPT_DETAILS_SUCCESS: 'GET_CHAT_PROMPT_DETAILS_SUCCESS',
  UPDATE_CHAT_PROMPT_DETAILS: 'UPDATE_CHAT_PROMPT_DETAILS',
  SET_CHAT_ONGOING_TAB_KEY: 'SET_CHAT_ONGOING_TAB_KEY',
  SET_CHAT_ONGOING_TAG_ID: 'SET_CHAT_ONGOING_TAG_ID',
  SET_CHAT_ONGOING_SCHEDULE_TIME: 'SET_CHAT_ONGOING_SCHEDULE_TIME',
  SET_CHAT_ONGOING_PROMOT_DETAILS: 'SET_CHAT_ONGOING_PROMOTDETAILS',
  CHAT_ONGOING_SCHEDULE_DETAILS: 'CHAT_ONGOING_SCHEDULE_DETAILS',
  CHAT_ONGOING_SCHEDULE_DETAILS_SUCCESS: 'CHAT_ONGOING_SCHEDULE_DETAILS_SUCCESS',
  UPDATE_MESSAGE_CHAT_ONGOING: 'UPDATE_MESSAGE_CHAT_ONGOING',
  UPDATE_MESSAGE_CHAT_ONGOING_SCHEDULE_DETAILS: 'UPDATE_MESSAGE_CHAT_ONGOING_SCHEDULE_DETAILS',
  REORDER_MESSAGE_CHATS: 'REORDER_MESSAGE_CHATS',
  CHANGE_MESSAGE_CHAT_POSITION: 'CHANGE_MESSAGE_CHAT_POSITION',
  SET_MESSAGE_CHAT_LOADER: 'SET_MESSAGE_CHAT_LOADER',
};

export default actions;