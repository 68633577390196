const actions = {
  FETCH_TABLE_LISTS_IN_POST: 'FETCH_TABLE_LISTS_IN_POST',
  FETCH_TABLE_LISTS_SUCCESS_IN_POST: 'FETCH_TABLE_LISTS_SUCCESS_IN_POST',
  FETCH_TABLE_LISTS_FAILURE_IN_POST: 'FETCH_TABLE_LISTS_FAILURE_IN_POST',
  APPEND_TABLE_LISTS_IN_POST: 'APPEND_TABLE_LISTS_IN_POST',
  APPEND_TABLE_LISTS_SUCCESS_IN_POST: 'APPEND_TABLE_LISTS_SUCCESS_IN_POST',
  APPEND_TABLE_LISTS_FAILURE_IN_POST: 'APPEND_TABLE_LISTS_FAILURE_IN_POST',
  CREATE_CHAT_DRAWER_IN_POST: 'CREATE_CHAT_DRAWER_IN_POST',
  DELETE_TABLE_DATA_IN_POST: 'DELETE_TABLE_DATA_IN_POST',
  DELETE_TABLE_DATA_SUCCESS_IN_POST: 'DELETE_TABLE_DATA_SUCCESS_IN_POST',
  DELETE_TABLE_DATA_FAILURE_IN_POST: 'DELETE_TABLE_DATA_FAILURE_IN_POST',
  SET_SELECTED_ROWS_IN_POST: 'SET_SELECTED_ROWS_IN_POST',
  APPLY_CHAT_TAG_IN_POST: 'APPLY_CHAT_TAG_IN_POST',
  APPLY_CHAT_TAG_SUCCESS_IN_POST: 'APPLY_CHAT_TAG_SUCCESS_IN_POST',
  APPLY_CHAT_TAG_FAILURE_IN_POST: 'APPLY_CHAT_TAG_FAILURE_IN_POST',
  CREATE_MESSAGES_CHAT_IN_POST: 'CREATE_MESSAGES_CHAT_IN_POST',
  CREATE_MESSAGES_CHAT_SUCCESS_IN_POST: 'CREATE_MESSAGES_CHAT_SUCCESS_IN_POST',
  CREATE_MESSAGES_CHAT_FAILURE_IN_POST: 'CREATE_MESSAGES_CHAT_FAILURE_IN_POST',
  DUPLICATE_MESSAGE_CHAT_IN_POST: 'DUPLICATE_MESSAGE_CHAT_IN_POST',
  DUPLICATE_MESSAGE_CHAT_SUCCESS_IN_POST:
    'DUPLICATE_MESSAGE_CHAT_SUCCESS_IN_POST',
  DUPLICATE_MESSAGE_CHAT_FAILURE_IN_POST:
    'DUPLICATE_MESSAGE_CHAT_FAILURE_IN_POST',
  UPDATE_TABLE_ELEMENT_STATUS_IN_POST: 'UPDATE_TABLE_ELEMENT_STATUS_IN_POST',
  UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_POST:
    'UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_POST',
  REMOVE_CHAT_TAG_IN_POST: 'REMOVE_CHAT_TAG_IN_POST',
  SET_CONFIRM_MODEL_IN_POST: 'SET_CONFIRM_MODEL_IN_POST',
  UPDATE_CHAT_MESSAGE_IN_POST: 'UPDATE_CHAT_MESSAGE_IN_POST',
  CANCEL_CHAT_SCHEDULE_IN_POST: 'CANCEL_CHAT_SCHEDULE_IN_POST',
  SET_CHAT_TAB_KEY_IN_POST: 'SET_CHAT_TAB_KEY_IN_POST',
  SET_CHAT_NAME_IN_POST: 'SET_CHAT_NAME_IN_POST',
  REMOVE_DELETED_ON_GOING_CHAT_ID_IN_POST:
    'REMOVE_DELETED_ON_GOING_CHAT_ID_IN_POST',
  SET_TABLE_LISTS_IN_POST: 'SET_TABLE_LISTS_IN_POST',
  SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES: 'SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES',
  SET_CREATED_MESSAGE_TAG: 'SET_CREATED_MESSAGE_TAG',
  SET_POST_CHANGE: 'SET_POST_CHANGE',
};

export default actions;