import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  handleMeetingLinkAutoSave,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  trackEvent, unProcessableEntity,
} from '../../helpers/utility';
import settings from '../../settings/index';
import actions from './actions';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { store } from '../store';

export function* fetchMeetings(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links`;
    if (params.page) {
      url += `?page=${params.page}`;
    }
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_MEETINGS_SUCCESS,
      payload: data,
      page: params.page,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_MEETINGS_FAILURE });
    if (error.response && error.response.status !== 403) {
      message.error('Meetings fetch failed');
    }
  }
}

export function* deleteMeeting(params) {
  try {
    yield put({
      type: actions.DELETE_MEETING_PROCESS,
      payload: true,
    });
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/${params.meetingID}`;
    yield call(() => deletePromise(url));
    yield put({ type: actions.FETCH_MEETINGS });
    yield put({
      type: actions.DELETE_MEETING_PROCESS,
      payload: false,
    });
    message.success('Meeting link has been deleted.');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Delete meeting failed');
    }
    yield put({
      type: actions.DELETE_MEETING_PROCESS,
      payload: false,
    });
  }
}

export function* fetchMeetingData(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/${params.payload}`;
    let data = yield call(() => getPromise(url));
    store.dispatch({
      type: actions.RESET_MEETING_DATA,
    });
    yield put({
      type: actions.FETCH_MEETING_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Fetch meeting failed');
    }
  }
}

export function* createMeetingLink(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links`;
    let data = yield call(() => postPromise(url, params.payload));
    trackEvent('Created meeting link');
    yield put({
      type: actions.CREATE_MEETING_LINK_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SET_MEETING_DRAWER_STATUS,
      payload: false,
    });
    yield put(
      push(`/projects/${fetchProjectSecretKey()}/meetings/${data.data.id}`),
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_MEETING_LINK_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Create meeting link');
    }
  }
}
export function* duplicateMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/duplicate`;
    let data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_MEETING_LINK_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SET_MEETING_DRAWER_STATUS,
      payload: false,
    });
    yield put(
      push(`/projects/${fetchProjectSecretKey()}/meetings/${data.data.id}`),
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    unProcessableEntity(error, 'Create meeting link failed');
    yield put({
      type: actions.CREATE_MEETING_LINK_FAILURE,
    });
  }
}

export function* updateMeetingDetails(params) {
  const meetingID = store.getState().Meetings.currentMeetingID;
  let object = params.payload;
  object.id = meetingID;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/update_meeting_details`;
    let data = yield call(() => patchPromise(url, object));
    yield put({
      type: actions.UPDATE_MEETING_DETAILS_SUCCESS,
      payload: data,
    });
    handleMeetingLinkAutoSave(false);
  } catch (error) {
    handleMeetingLinkAutoSave(false);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Update meeting link failed');
    }
  }
}

export function* updateConfiguration(params) {
  const meetingID = store.getState().Meetings.currentMeetingID;
  let object = params.payload;
  object.id = meetingID;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/update_configurations`;
    let data = yield call(() => patchPromise(url, object));
    handleMeetingLinkAutoSave(false);
    yield put({
      type: actions.UPDATE_CONFIGURATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    handleMeetingLinkAutoSave(false);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Update configuration failed');
    }
  }
}

export function* updateAvailableDetails(params) {
  const meetingID = store.getState().Meetings.currentMeetingID;
  let object = params.payload;
  object.id = meetingID;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/update_availabilities`;
    let data = yield call(() => patchPromise(url, object));
    handleMeetingLinkAutoSave(false);
    yield put({
      type: actions.UPDATE_AVAILABILITY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    handleMeetingLinkAutoSave(false);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Update availability failed');
    }
  }
}

export function* updateFormQuestions(params) {
  const meetingID = store.getState().Meetings.currentMeetingID;
  let object = params.payload;
  object.id = meetingID;
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/update_form_questions`;
    let data = yield call(() => patchPromise(url, object));
    handleMeetingLinkAutoSave(false);
    yield put({
      type: actions.UPDATE_FORM_QUESTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    handleMeetingLinkAutoSave(false);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else if (error.response && error.response.status !== 403) {
      message.error('Update form question failed');
    }
  }
}

export function* updateRedirectUrl(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meeting_links/update_redirection`;
    let data = yield call(() => patchPromise(url, params.payload));
    handleMeetingLinkAutoSave(false);
    yield put({
      type: actions.UPDATE_REDIRECT_URL_SUCCESS,
      payload: {
        customRedirectUrl: params.payload.meeting_link.custom_redirect_url,
        customRedirectParams:
          params.payload.meeting_link.custom_redirect_params,
        updatedAt: data.data.updated_at,
      },
    });
  } catch (error) {
    handleMeetingLinkAutoSave(false);
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update form question failed');
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_MEETINGS, fetchMeetings)]);
  yield all([takeEvery(actions.DELETE_MEETING, deleteMeeting)]);
  yield all([takeEvery(actions.DUPLICATE_MEETING, duplicateMeeting)]);
  yield all([takeEvery(actions.FETCH_MEETING_DATA, fetchMeetingData)]);
  yield all([takeEvery(actions.UPDATE_MEETING_DETAILS, updateMeetingDetails)]);
  yield all([takeEvery(actions.CREATE_MEETING_LINK, createMeetingLink)]);
  yield all([
    takeEvery(actions.UPDATE_CONFIGURATION_DETAILS, updateConfiguration),
  ]);
  yield all([
    takeEvery(actions.UPDATE_AVAILABILITY_DETAILS, updateAvailableDetails),
  ]);
  yield all([
    takeEvery(actions.UPDATE_FORM_QUESTIONS, updateFormQuestions),
    takeEvery(actions.UPDATE_REDIRECT_URL, updateRedirectUrl),
  ]);
}
