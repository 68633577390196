export const CUSTOM_INBOX_VIEW_CONDITION_LIMIT = 5;
export const CUSTOM_INBOX_VIEW_CONDITION_BLOCK_LIMIT = 5;

export const conversationStatusMasterList = [
  { name: 'Open', id: 1, key: 'open' },
  { name: 'Snoozed', id: 2, key: 'snoozed' },
  { name: 'Closed', id: 3, key: 'close' },
];

export const conversationChannelMasterList = [
  { name: 'Email', id: 1, key: 'email' },
  { name: 'Chat', id: 2, key: 'chat' },
  { name: 'Facebook', id: 3, key: 'facebook' },
  { name: 'Gist-API', id: 4, key: 'API' },
];

export const conversationRatingMasterList = [
  { name: 'Terrible', id: 1, key: 'terrible' },
  { name: 'Bad', id: 2, key: 'bad' },
  { name: 'Okay', id: 3, key: 'okay' },
  { name: 'Good', id: 4, key: 'good' },
  { name: 'Amazing', id: 5, key: 'amazing' },
];

export const conditionsMasterList = [
  {
    category_name: 'conversation',
    properties: [
      {
        property_name: 'Channel',
        value: 'conversation_channel',
        property_type: 'multi_select',
      },
      {
        property_name: 'Assignee',
        value: 'conversation_assignee',
        property_type: 'multi_select',
      },
      {
        property_name: 'Conversation tag',
        value: 'conversation_tag',
        property_type: 'multi_select',
      },
      {
        property_name: 'Conversation rating',
        value: 'conversation_rating',
        property_type: 'multi_select',
      },
    ],
  },
  {
    category_name: 'time',
    properties: [
      {
        property_name: 'Waiting for teammate reply',
        value: 'unreplied_after',
        property_type: 'date',
      },
      {
        property_name: 'Conversation started',
        value: 'conversation_started',
        property_type: 'date',
      },
    ],
  },
];

export const conditionsDataTypes = {
  time_range: [
    {
      label: 'is between',
      value: 'is_between',
    },
    {
      label: 'is outside',
      value: 'is_outside',
    },
  ],
  url: [
    {
      label: 'is any page',
      value: 'is_any_page',
    },
    {
      label: 'is the homepage',
      value: 'is_homepage',
    },
    {
      label: 'is not the homepage',
      value: 'is_not_homepage',
    },
    {
      label: 'is exactly',
      value: 'equals',
    },
    {
      label: 'is not exactly',
      value: 'not_equals',
    },
    {
      label: 'contains',
      value: 'contains',
    },
    {
      label: 'does not contain',
      value: 'not_contains',
    },
    {
      label: 'starts with',
      value: 'starts_with',
    },
    {
      label: 'does not starts with',
      value: 'does_not_starts_with',
    },
    {
      label: 'ends with',
      value: 'ends_with',
    },
    {
      label: 'does not ends with',
      value: 'does_not_ends_with',
    },
    {
      label: 'matches the pattern',
      value: 'matches_the_pattern',
    },
  ],
  number: [
    {
      label: 'is greater than',
      value: 'greater_than_number',
    },
    {
      label: 'is less than',
      value: 'less_than_number',
    },
    {
      label: 'is equal to',
      value: 'equals',
    },
    {
      label: 'not equal to',
      value: 'not_equals',
    },
    {
      label: 'is less than or equal to',
      value: 'less_than_or_equal_to_number',
    },
    {
      label: 'is greater than or equal to',
      value: 'greater_than_or_equal_to_number',
    },
    {
      label: 'has any value',
      value: 'has_any_value',
    },
    {
      label: 'is unknown',
      value: 'has_no_value',
    },
  ],
  date_time: [
    {
      label: 'is on',
      value: 'on',
    },
    {
      label: 'is before',
      value: 'before',
    },
    {
      label: 'is after',
      value: 'after',
    },
    {
      label: 'is on or before',
      value: 'on_or_before',
    },
    {
      label: 'is on or after',
      value: 'on_or_after',
    },
  ],
  string: [
    {
      label: 'is exactly',
      value: 'equals',
    },
    {
      label: 'is not exactly',
      value: 'not_equals',
    },
    {
      label: 'contains',
      value: 'contains',
    },
    {
      label: 'does not contain',
      value: 'not_contains',
    },
    {
      label: 'starts with',
      value: 'starts_with',
    },
    {
      label: 'ends with',
      value: 'ends_with',
    },
    {
      label: 'has any value',
      value: 'has_any_value',
    },
    {
      label: 'is unknown',
      value: 'has_no_value',
    },
  ],
  multi_select: [
    {
      label: 'is any of',
      value: 'any',
    },
    {
      label: 'is none of',
      value: 'none',
    },
  ],
  is_is_not: [
    {
      label: 'is',
      value: 'equals',
    },
    {
      label: 'is not',
      value: 'not_equals',
    },
  ],
  boolean: [
    {
      label: 'is true',
      value: 'true',
    },
    {
      label: 'is false',
      value: 'false',
    },
    {
      label: 'has any value',
      value: 'has_any_value',
    },
    {
      label: 'is unknown',
      value: 'has_no_value',
    },
  ],
  date: [
    {
      label: 'is greater than',
      value: 'greater_than',
    },
    {
      label: 'is less than',
      value: 'less_than',
    },
    {
      label: 'is equal to',
      value: 'equals',
    },
    {
      label: 'is less than or equal to',
      value: 'less_or_equal',
    },
    {
      label: 'is greater than or equal to',
      value: 'greater_or_equal',
    },
  ],
};

export const InboxButtonProps = [
  {
    channel: {
      prependText: 'Channel',
      prependIconClass: 'fas fa-paper-plane',
    },
    contacts: {
      prependText: 'Contacts',
      prependIconClass: 'fas fa-user',
    },
    participants: {
      prependText: 'Participants',
      prependIconClass: 'fas fa-user-group',
    },
    tags: {
      prependText: 'Tags',
      prependIconClass: 'fas fa-tag',
    },
    assigned_to: {
      prependText: 'Assigned To',
      prependIconClass: 'fas fa-circle-user',
    },

    status: {
      prependText: 'Status',
      prependIconClass: 'fas fa-check',
    },
    sortable: {
      prependText: '',
      prependIconClass: '',
    },
    picker: {
      prependText: 'Started',
      prependIconClass: 'fas fa-calendar',
    },
  },
];
export const filterStatus = [
  {
    key: 'open',
    name: 'Open',
  },
  {
    key: 'closed',
    name: 'Closed',
  },
  {
    key: 'pending',
    name: 'Snoozed',
  },
  {
    key: 'all',
    name: 'All',
  },
];

export const customDatePickerFilters = [
  {
    name: 'Earlier today',
    key: 'earlier_today',
  },
  {
    name: 'Yesterday',
    key: 'yesterday',
  },
  {
    name: 'Last one week',
    key: 'last_week',
  },
  {
    name: 'Last one month',
    key: 'last_month',
  },
];
