import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import chatActions from '../../../redux/chat/actions';
import { extractCurrentPersonId } from '../../../helpers/utility';
import sharedActions from '../../../redux/sharedReducers/actions';
import JungleSelect from 'react-jungle-select';

const TagContactWidget = props => {
  const dispatch = useDispatch();
  const tags = useSelector(({ Chat }) => Chat.contactTagsData),
    currentContactTags = useSelector(({ Chat }) => Chat.tags);
  let tag_ids = currentContactTags.map(tag => tag.id) || [];
  const { omniSearchEventListener, removeOmniSearchListener } = props;

  useEffect(() => {
    omniSearchEventListener();

    return () => {
      removeOmniSearchListener();
    };
  }, []);

  const handleSelect = tag => {
    dispatch({
      type: chatActions.ADD_USER_TAGS,
      tags: [tag.display_name],
      personID: extractCurrentPersonId(),
    });

    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  const getCommandItem = item => (
    <div className="command-item">
      <div className="command-item-inner">
        <span className="command-content">
          <span className="command-title">
            <i className="fal fa-tag" /> {item.display_name}
          </span>
        </span>
      </div>
    </div>
  );

  return (
    <JungleSelect
      items={tags.filter(tag => !tag_ids.includes(tag.id))}
      renderItem={item => getCommandItem(item)}
      placeholder="Tag contact..."
      searchable={true}
      searchableAttributes={['display_name']}
      listWrapper={list => <div className="command-list">{list}</div>}
      onChange={value => handleSelect(value)}
      mode="list"
      selectFirstItem={true}
      autofocus={true}
    />
  );
};
export default TagContactWidget;
