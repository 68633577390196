const actions = {
  FETCH_FORM_LIST: 'FETCH_FORM_LIST',
  FETCH_FORM_LIST_SUCCESS: 'FETCH_FORM_LIST_SUCCESS',
  FETCH_FORM_LIST_FAILURE: 'FETCH_FORM_LIST_FAILURE',
  SET_FORM_STATUS: 'SET_FORM_STATUS',
  DELETE_FORM: 'DELETE_FORM',
  DELETE_FORM_SUCCESS: 'DELETE_FORM_SUCCESS',
  UPDATE_FORM: 'UPDATE_FORM',
  UPDATE_FORM_SUCCESS: 'UPDATE_FORM_SUCCESS',
  APPEND_FORM_LIST: 'APPEND_FORM_LIST',
  APPEND_FORM_LIST_SUCCESS: 'APPEND_FORM_LIST_SUCCESS',
  APPEND_FORM_LIST_FAILURE: 'APPEND_FORM_LIST_FAILURE',
  DELETE_FORM_FAILURE: 'DELETE_FORM_FAILURE',
  FETCH_OPT_IN_FIELDS_SUCCESS: 'FETCH_OPT_IN_FIELDS_SUCCESS',
  SET_FORM_TEMPLATE_DATA: 'SET_FORM_TEMPLATE_DATA',
  UPDATE_ADDED_OPTIN_FIELD: 'UPDATE_ADDED_OPTIN_FIELD',
  SET_FORM_SEARCH_VALUE: 'SET_FORM_SEARCH_VALUE',
  SET_FORM_TAB_KEY: 'SET_FORM_TAB_KEY',
  CHANGE_FORM_POSITION: 'CHANGE_FORM_POSITION',
  SET_FORM_TEMPLATE_SEARCH_VALUE: 'SET_FORM_TEMPLATE_SEARCH_VALUE',
  SET_PREVIEW_MODAL_VISIBLE: 'SET_PREVIEW_MODAL_VISIBLE',
  SET_FORM_DRAWER_STATUS: 'SET_FORM_DRAWER_STATUS',
  CREATE_FORM: 'CREATE_FORM',
  CREATE_FORM_SUCCESS: 'CREATE_FORM_SUCCESS',
  CREATE_FORM_FAILURE: 'CREATE_FORM_FAILURE',
  SET_LOADER_FOR_SAVING_DETAILS: 'SET_LOADER_FOR_SAVING_DETAILS',
  GET_OPT_IN_FIELDS_LIST: 'GET_OPT_IN_FIELDS_LIST',
  GET_OPT_IN_FIELDS_LIST_SUCCESS: 'GET_OPT_IN_FIELDS_LIST_SUCCESS',
  UPLOAD_FORM_IMAGE: 'UPLOAD_FORM_IMAGE',
  UPLOAD_FORM_IMAGE_SUCCESS: 'UPLOAD_FORM_IMAGE_SUCCESS',
  UPLOAD_FORM_IMAGE_FAILURE: 'UPLOAD_FORM_IMAGE_FAILURE',
  SET_ASSET_MANAGER_DATA: 'SET_ASSET_MANAGER_DATA',
  DELETE_FORM_IMAGE: 'DELETE_FORM_IMAGE',
  DELETE_FORM_IMAGE_SUCCESS: 'DELETE_FORM_IMAGE_SUCCESS',
  DELETE_FORM_IMAGE_FAILURE: 'DELETE_FORM_IMAGE_FAILURE',
  GET_DETAILS_OF_FORM_EDITOR_PAGE: 'GET_DETAILS_OF_FORM_EDITOR_PAGE',
  GET_DETAILS_OF_FORM_EDITOR_PAGE_SUCCESS:
    'GET_DETAILS_OF_FORM_EDITOR_PAGE_SUCCESS',
  GET_DETAILS_OF_FORM_EDITOR_PAGE_FAILURE:
    'GET_DETAILS_OF_FORM_EDITOR_PAGE_FAILURE',
  GET_FORM_TYPES_LIST: 'GET_FORM_TYPES_LIST',
  GET_FORM_TYPES_LIST_SUCCESS: 'GET_FORM_TYPES_LIST_SUCCESS',
  GET_FORM_TYPES_LIST_FAILURE: 'GET_FORM_TYPES_LIST_FAILURE',
  SET_SELECTED_FORM_TYPES: 'SET_SELECTED_FORM_TYPES',
  FETCH_FORM_TEMPLATES_LIST: 'FETCH_FORM_TEMPLATES_LIST',
  FETCH_FORM_TEMPLATES_LIST_SUCCESS: 'FETCH_FORM_TEMPLATES_LIST_SUCCESS',
  FETCH_FORM_TEMPLATES_LIST_FAILURE: 'FETCH_FORM_TEMPLATES_LIST_FAILURE',
  RESET_TEMPLATE_VALUES: 'RESET_TEMPLATE_VALUES',
  EMPTY_EDITOR_DETAILS: 'EMPTY_EDITOR_DETAILS',
  STORE_SUBMIT_ACTION_TYPES: 'STORE_SUBMIT_ACTION_TYPES',
  UPDATE_SELECTED_ACTION: 'UPDATE_SELECTED_ACTION',
  SET_FORMS_SETTINGS_DETAILS: 'SET_FORMS_SETTINGS_DETAILS',
  UPDATE_GDPR_ENABLED: 'UPDATE_GDPR_ENABLED',
  SET_EDITOR_STATUS_VALUE: 'SET_EDITOR_STATUS_VALUE',
  SET_EDITOR_STATUS_LOADING: 'SET_EDITOR_STATUS_LOADING',
  SET_FORM_EDITOR_PAGE_LOADER: 'SET_FORM_EDITOR_PAGE_LOADER',
  REMOVE_SUBMIT_ACTION_TYPES: 'REMOVE_SUBMIT_ACTION_TYPES',
  SET_FORM_ALIGNMENT: 'SET_FORM_ALIGNMENT',
  SET_PUBLISH_TAB_KEY: 'SET_PUBLISH_TAB_KEY',
  SET_SELECTED_FORM_SECRET: 'SET_SELECTED_FORM_SECRET',
  GET_IMAGE_GALLERY: 'GET_IMAGE_GALLERY',
  GET_IMAGE_GALLERY_SUCCESS: 'GET_IMAGE_GALLERY_SUCCESS',
  APPEND_IMAGE_GALLERY: 'APPEND_IMAGE_GALLERY',
  UPDATE_IMAGE_CONTINUATION_TOKEN: 'UPDATE_IMAGE_CONTINUATION_TOKEN',
  SET_SELECTED_FORM_TYPE: 'SET_SELECTED_FORM_TYPE',
  SET_FOLLOW_UP_STATUS: 'SET_FOLLOW_UP_STATUS',
  FETCH_LIQUID_VARIABLES_SUCCESS: 'FETCH_LIQUID_VARIABLES_SUCCESS',
  UPDATE_HTML_FORM_DATA: 'UPDATE_HTML_FORM_DATA',
  RE_ORDER_FORM_STATUS: 'RE_ORDER_FORM_STATUS',
  SET_FORM_SORT_VALUES: 'SET_FORM_SORT_VALUES',
  DUPLICATE_FORM: 'DUPLICATE_FORM',
  DUPLICATE_FORM_SUCCESS: 'DUPLICATE_FORM_SUCCESS',
  DUPLICATE_FORM_FAILURE: 'DUPLICATE_FORM_FAILURE',
  SET_DUPLICATE_DRAWER_FORM_DETAILS: 'SET_DUPLICATE_DRAWER_FORM_DETAILS',

  //Reports
  FETCH_FORMS_ONGOING_REPORTS: 'FETCH_FORMS_ONGOING_REPORTS',
  FETCH_FORMS_ONGOING_REPORTS_SUCCESS: 'FETCH_FORMS_ONGOING_REPORTS_SUCCESS',
  FETCH_FORMS_ONGOING_REPORTS_FAILURE: 'FETCH_FORMS_ONGOING_REPORTS_FAILURE',
  EXPORT_FORMS_CSV_MODAL: 'EXPORT_FORMS_CSV_MODAL',
  EXPORT_FORMS_RESPONSE_CSV: 'EXPORT_FORMS_RESPONSE_CSV',
  EXPORT_FORMS_CSV_LOADER: 'EXPORT_FORMS_CSV_LOADER',
  RESET_REPORT_PAGE_DETAILS: 'RESET_REPORT_PAGE_DETAILS',
};

export default actions;
