import { store } from '../../../redux/store';
import actions from '../../../redux/contacts/actions';

export function addContactActivity(data, type) {
  const { activitiesFilterList } = store.getState().Contacts;
  let canAdd = false;
  if (activitiesFilterList.length) {
    if (activitiesFilterList.includes(type)) {
      canAdd = true;
    }
  } else {
    canAdd = true;
  }
  if (canAdd) {
    store.dispatch({
      type: actions.ADD_CONTACT_ACTIVITIES,
      payload: data[0],
    });
  }
}
