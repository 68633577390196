import actions from './actions';
import { addHyphenToTeamsTeammates } from '../../helpers/utility';

let initState = {
  previewEmail: '',
  selectedPreviewUser: '',
  previewUserList: [],
  previewEmailLoader: false,
  previewEmailSubject: '',
  isCancelSchedule: false,
  emailTagList: [],
  emailBtnLoader: false,
  emailTagSearch: '',
  campaign: false,
  emailTypeKey: '1',
  openConfirmPopup: false,
  teamMates: [],
  teams: [],
  customTemplateNameModalVisible: false,
};

export default function emailReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_PREVIEW_EMAIL_DATA:
      return {
        ...state,
        previewEmail: action.payload,
      };
    case actions.FETCH_EMAIL_TAG_LISTS_SUCCESS:
      return {
        ...state,
        emailTagList: action.data.feature_tags,
      };
    case actions.SET_PREVIEW_USER:
      return {
        ...state,
        selectedPreviewUser: action.payload,
      };
    case actions.GET_PREVIEW_USER_LIST_SUCCESS:
      return {
        ...state,
        previewUserList: action.payload,
      };
    case actions.GET_PREVIEW_USER_LIST_FAILURE:
      return {
        ...state,
        previewUserList: action.payload,
      };
    case actions.CLEAR_PREVIEW_EMAIL_DATA:
      return {
        ...state,
        selectedPreviewUser: '',
        previewEmail: '',
        previewEmailSubject: '',
      };
    case actions.SET_PREVIEW_EMAIL_LOADER:
      return {
        ...state,
        previewEmailLoader: action.payload,
      };
    case actions.SET_MAIL_SUBJECT_DATA:
      return {
        ...state,
        previewEmailSubject: action.payload,
      };
    case actions.CANCEL_SCHEDULE:
      return {
        ...state,
        isCancelSchedule: true,
      };
    case actions.CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        isCancelSchedule: false,
      };
    case actions.CANCEL_SCHEDULE_FAILURE:
      return {
        ...state,
        isCancelSchedule: false,
      };
    case actions.SET_EMAIL_LOADER:
      return {
        ...state,
        emailBtnLoader: action.emailBtnLoader,
      };
    case actions.CLEAR_MODAL:
      return {
        ...state,
      };
    case actions.SET_EMAIL_TAGS_SEARCH:
      return {
        ...state,
        emailTagSearch: action.emailTagSearch,
      };
    case actions.APPEND_EMAIL_TAGS_SUCCESS:
      return {
        ...state,
        emailTagList: [...state.emailTagList, ...action.data.feature_tags],
      };
    case actions.SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
      };
    case actions.SET_BROADCAST:
      return {
        ...state,
        emailTypeKey: action.emailTypeKey,
      };
    case actions.SET_CONFIRM_MODEL:
      return {
        ...state,
        openConfirmPopup: action.payload,
      };
    case actions.FETCH_TEAMS_USERS_LIST_SUCCESS:
      let { teams, teamMates } = addHyphenToTeamsTeammates(action.payload);
      return {
        ...state,
        teamMates: teamMates,
        teams: teams,
      };
    case actions.SET_CUSTOM_TEMPLATE_NAME_MODAL_VISIBILITY:
      return {
        ...state,
        customTemplateNameModalVisible: action.payload,
      };
    default:
      return state;
  }
}
