import React, {useEffect} from 'react';
import {Picker} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

const Emojis = props => {
  useEffect(() => {
    const emojis = document.getElementsByClassName('emojis')[0];
    if (emojis) {
      setTimeout(() => {
        const search = emojis.getElementsByClassName('emoji-mart-search')[0];
        if (search) {
          search
            .getElementsByTagName('input')[0]
            .addEventListener('keydown', e => e.stopPropagation());
        }
      }, 500);
    }
  }, []);
    return (
      <Picker
        set="emojione"
        onSelect={props.onSelectEmojis}
      darkMode={false}
      showPreview={true}
      showSkinTones={true}
      emojiTooltip={true}
      title="Emoji"
      autoFocus={true}
    />
  );
};

export default Emojis;
