import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  unAuthorisedRedirection,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../../redux/store';

export function* fetchEmailPreviewReport(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/mail-details`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_PREVIEW_REPORT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch email preview report failed');
  }
}

export function* fetchEmailOpenCountReport(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/opened-details`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_OPEN_COUNT_REPORT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch open count failed');
  }
}

export function* fetchEmailClickCountReport(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/clicked-details`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CLICK_COUNT_REPORT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch clicked count failed');
  }
}

export function* fetchEmailTotalReport(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/total-report`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TOTAL_REPORT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch total report failed');
  }
}

export function* fetchEmailLinkActivityReport(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${params.id}/link-clicks`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_LINK_ACTIVITY_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch link activity failed');
  }
}

export function* fetchEmailReportRecipientCount(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/recipient-count`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_REPORT_RECIPIENT_COUNT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch recipient count failed');
  }
}

export function* fetchEmailRecipientUser(params) {
  try {
    let pagination = 0;
    let sortKey = '';
    let sortValue = '';
    let searchText = '';
    if (params.menu_type) {
      if (params.pagination) {
        pagination = params.pagination;
      }
      if (params.sortKey && params.sortOrder) {
        sortKey = params.sortKey;
        sortValue = params.sortOrder;
      } else {
        sortKey = store.getState().EmailReport.reportData.sortKey;
        sortValue = store.getState().EmailReport.reportData.sortOrder;
      }
      if (params.searchText) {
        searchText = params.searchText;
      } else {
        searchText = store.getState().EmailReport.reportData.searchText;
      }
      const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/mail_filters/${
        params.id
      }/load-people?menu_type=${
        params.menu_type
      }&start=${pagination}&sort_key=${sortKey}&sort_type=${sortValue}&search_text=${searchText}`;
      const data = yield call(() => getPromise(url));
      yield put({
        type: actions.FETCH_REPORT_RECIPIENT_COUNT_SUCCESS,
        payload: data.data.count_details,
      });
      yield put({
        type: actions.FETCH_RECIPIENT_USER_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch user list failed');
  }
}

export function* updateEmailName(params) {
  const object = {
    mail_filter: {
      title: params.title,
    },
  };
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/settings/${
      params.emailID
    }`;
    yield call(() => patchPromise(url, object));
    message.success('Email name updated successfully');
    yield put({
      type: actions.UPDATE_EMAIL_NAME_SUCCESS,
      payload: params.title,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Email name updated failed');
    }
  }
}

export function* updateEmailLinkUrl(params) {
  try {
    const url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/mail_filters/${
        params.emailID
      }/link_update`,
      { payload } = params;
    yield call(() => patchPromise(url, payload));
    message.success('Email link url updated successfully');
    yield put({
      type: actions.UPDATE_EMAIL_LINK_SUCCESS,
      payload,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Email link url updated failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_EMAIL_PREVIEW_REPORT, fetchEmailPreviewReport),
  ]);
  yield all([
    takeEvery(actions.FETCH_OPEN_COUNT_REPORT, fetchEmailOpenCountReport),
  ]);
  yield all([
    takeEvery(actions.FETCH_CLICK_COUNT_REPORT, fetchEmailClickCountReport),
  ]);
  yield all([takeEvery(actions.FETCH_TOTAL_REPORT, fetchEmailTotalReport)]);
  yield all([
    takeEvery(actions.FETCH_LINK_ACTIVITY_REPORT, fetchEmailLinkActivityReport),
  ]);
  yield all([
    takeEvery(
      actions.FETCH_REPORT_RECIPIENT_COUNT,
      fetchEmailReportRecipientCount,
    ),
  ]);
  yield all([takeEvery(actions.FETCH_RECIPIENT_USER, fetchEmailRecipientUser)]);
  yield all([
    takeEvery(actions.UPDATE_EMAIL_NAME, updateEmailName),
    takeEvery(actions.UPDATE_EMAIL_LINK, updateEmailLinkUrl),
  ]);
}
