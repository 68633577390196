import React from 'react';
import '../style.scss';

const DividerComponent = props => {
  const { component } = props;

  return (
    <div
      className="divider"
      {...(component.id ? { 'data-component-id': component.id } : {})}
    />
  );
};

export default DividerComponent;
