import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  unAuthorisedRedirection,
} from '../../helpers/utility';
import settings from '../../settings/index';
import actions from './actions';
import { store } from '../../redux/store';
import { message } from 'antd';

export function* getCompaniesList(params) {
  let { searchText } = store.getState().Companies;
  let pageView = 1,
    sortOrder = 'desc';
  let updateTable = false;
  if (params.searchText) {
    searchText = params.searchText;
  }
  if (params.updatePageView) {
    pageView = params.updatePageView;
    updateTable = true;
  }
  if (params.sortOrder) {
    sortOrder = params.sortOrder;
  }
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies?search_text=${searchText}&page=${pageView}&sort_order=${sortOrder}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANIES_LIST_SUCCESS,
      payload: data.data.companies,
      isAppend: updateTable,
      pageView,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Companies fetch failed');
    }
    yield put({ type: actions.GET_COMPANIES_LIST_FAILURE });
  }
}

export function* createCompany(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies`;
    const data = yield call(() => postPromise(url, params.payload));
    let company = data.data.company;
    company.associated_contacts_count = company.data.associated_contacts_count;
    company.associated_deals_count = company.data.associated_deals_count;
    yield put({
      type: actions.CREATE_COMPANY_SUCCESS,
      payload: company,
    });
    message.success(data.data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Company creation failed');
    }
  }
}

export function* GetCompany(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies/company_info?domain=${
      params.payload.domainName
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANY_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Get company info failed');
    }
    yield put({ type: actions.GET_COMPANY_INFO_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_COMPANIES_LIST, getCompaniesList),
    takeEvery(actions.CREATE_COMPANY, createCompany),
    takeEvery(actions.GET_COMPANY_INFO, GetCompany),
  ]);
}
