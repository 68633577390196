import React, { Fragment } from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';
import { removeUnderscore } from '../../../../../helpers/utility';

export class OpenResponse extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className="header">
          <h3>
            <span>
              <i className="fas fa-comment-dots" />
            </span>
            Collect customer data
          </h3>
        </div>
        <div className="body">
          {data.messages &&
            data.messages.map((item, key) => (
              <Fragment key={item.id}>
                <div className="message-text-component" key={item.id}>
                  <InputTextArea id={item.id} value={item.text} />
                </div>
              </Fragment>
            ))}
          <div className="buttons-list mb-2">
            <div
              key={data.messages[data.messages.length - 1].id}
              className="button-component"
              data-port-id={data.messages[data.messages.length - 1].id} // Handled without changing the structure of previous JSON format
            >
              <span className={`primary-btn`}>Customer sends a message</span>
              <jtk-source
                port-id={data.messages[data.messages.length - 1].id}
                endpoint={true}
              />
            </div>
          </div>
          {data.is_map_property_enabled && (
            <div className="footer">
              {removeUnderscore(data.map_response_to) ? (
                <span>
                  Save response to{' '}
                  <b>{removeUnderscore(data.map_response_to)}</b> property
                </span>
              ) : (
                <span className="error-text">Property Missing</span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
