import actions from './actions';
import { cloneDeep } from 'lodash';

const initState = {
  companyDetails: null,
  companyLoading: false,
  activitiesLoading: false,
  activitiesAppendLoading: false,
  companyActivities: [],
  contactLoader: false,
  contactDrawerStatus: false,
  companyProperties: null,
  companyNonEditableKeys: [],
  companyDrawerStatus: false,
  companyNotesLoader: false,
  companyNotes: [],
  noteAddLoading: false,
  companyMeetingList: [],
  companyMeetingLoader: false,
  companyMeetingModalStatus: false,
  companyMeetingModalLoader: false,
  mailList: [],
  emailLoader: false,
  emailListLoader: false,
  activitiesFilter: [],
  companyEmailModalStatus: false,
  companyModalLoader: false,
  modalCompanyId: null,
};

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_COMPANY_DETAILS:
      return {
        ...state,
        companyLoading: true,
      };
    case actions.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: action.payload,
        companyLoading: false,
      };
    case actions.GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        companyLoading: false,
      };
    case actions.GET_COMPANY_ACTIVITIES:
      return {
        ...state,
        activitiesLoading: true,
      };
    case actions.GET_COMPANY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        companyActivities: action.payload,
        activitiesLoading: false,
      };
    case actions.GET_COMPANY_ACTIVITIES_FAILURE:
      return {
        ...state,
        activitiesLoading: false,
      };
    case actions.APPEND_COMPANY_ACTIVITIES:
      return {
        ...state,
        activitiesAppendLoading: true,
      };
    case actions.APPEND_COMPANY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        companyActivities: [...state.companyActivities, ...action.payload],
        activitiesAppendLoading: false,
      };
    case actions.APPEND_COMPANY_ACTIVITIES_FAILURE:
      return {
        ...state,
        activitiesAppendLoading: false,
      };
    case actions.COMPANY_REMOVE_CONTACT:
      return {
        ...state,
        contactLoader: true,
      };
    case actions.COMPANY_REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          associated_contacts: state.companyDetails.associated_contacts.filter(
            data => data.id !== action.contactID,
          ),
        },
        contactLoader: false,
      };
    case actions.COMPANY_REMOVE_CONTACT_FAILURE:
      return {
        ...state,
        contactLoader: false,
      };
    case actions.COMPANY_ADD_CONTACT:
      return {
        ...state,
        contactLoader: true,
      };
    case actions.COMPANY_CONTACT_DRAWER_STATUS:
      return {
        ...state,
        contactDrawerStatus: action.payload,
      };
    case actions.COMPANY_ADD_CONTACT_SUCCESS:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          associated_contacts: action.payload,
        },
        contactLoader: false,
        contactDrawerStatus: false,
      };
    case actions.COMPANY_ADD_CONTACT_FAILURE:
      return {
        ...state,
        contactLoader: false,
        contactDrawerStatus: true,
      };
    case actions.GET_COMPANY_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        companyProperties: action.payload,
        companyNonEditableKeys: action.payload.non_editable_properties.map(
          data => data.name,
        ),
      };
    case actions.SET_COMPANY_DRAWER_STATUS:
      return {
        ...state,
        companyDrawerStatus: action.payload,
      };
    case actions.FETCH_COMPANY_NOTES_SUCCESS:
      return {
        ...state,
        companyNotes: action.payload,
        companyNotesLoader: false,
      };
    case actions.ACTIVATE_COMPANY_NOTES_LOADER:
      return {
        ...state,
        companyNotesLoader: true,
      };
    case actions.CREATE_COMPANY_NOTES:
      return {
        ...state,
        noteAddLoading: true,
      };
    case actions.CREATE_COMPANY_NOTES_SUCCESS:
      let companyNotes = cloneDeep(state.companyNotes);
      companyNotes.unshift(action.payload);
      return {
        ...state,
        companyNotes: companyNotes,
        noteAddLoading: false,
      };
    case actions.FETCH_COMPANY_NOTES_FAILURE:
      return {
        ...state,
        companyNotesLoader: false,
      };
    case actions.CREATE_COMPANY_NOTES_FAILURE:
      return {
        ...state,
        noteAddLoading: false,
      };
    case actions.EDIT_COMPANY_NOTES_SUCCESS:
      let updateNotes = state.companyNotes.map(note => {
        if (note.id === action.payload.id) {
          note = action.payload;
        }
        return note;
      });
      return {
        ...state,
        companyNotes: updateNotes,
      };
    case actions.DELETE_COMPANY_NOTES_SUCCESS:
      return {
        ...state,
        companyNotes: state.companyNotes.filter(
          note => note.id !== action.noteID,
        ),
      };
    case actions.GET_COMPANY_MEETINGS:
      return {
        ...state,
        companyMeetingLoader: true,
      };
    case actions.GET_COMPANY_MEETINGS_SUCCESS:
      return {
        ...state,
        companyMeetingList: action.payload,
        companyMeetingLoader: false,
      };
    case actions.GET_COMPANY_MEETINGS_FAILURE:
      return {
        ...state,
        companyMeetingLoader: false,
      };
    case actions.COMPANY_MEETING_MODAL_STATUS: {
      return {
        ...state,
        companyMeetingModalStatus: action.payload,
      };
    }
    case actions.ADD_COMPANY_MEETING:
      return {
        ...state,
        companyMeetingModalLoader: true,
      };
    case actions.ADD_COMPANY_MEETING_SUCCESS:
      const meetingList = cloneDeep(state.companyMeetingList);
      meetingList.unshift(action.payload);
      return {
        ...state,
        companyMeetingList: meetingList,
        companyMeetingModalLoader: false,
        companyMeetingModalStatus: false,
      };
    case actions.ADD_COMPANY_MEETING_FAILURE:
      return {
        ...state,
        companyMeetingModalLoader: false,
      };
    case actions.CANCEL_COMPANY_MEETING:
      return {
        ...state,
        companyMeetingModalLoader: true,
      };
    case actions.CANCEL_COMPANY_MEETING_SUCCESS:
      return {
        ...state,
        companyMeetingList: state.companyMeetingList.filter(
          data => data.id !== action.meetingID,
        ),
        companyMeetingModalLoader: false,
      };
    case actions.CANCEL_COMPANY_MEETING_FAILURE:
      return {
        ...state,
        companyMeetingModalLoader: false,
      };
    case actions.GET_COMPANY_EMAIL_LIST:
      return {
        ...state,
        emailListLoader: true,
      };
    case actions.GET_COMPANY_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        mailList: action.payload,
        emailListLoader: false,
      };
    case actions.GET_COMPANY_EMAIL_LIST_FAILURE:
      return {
        ...state,
        emailListLoader: false,
      };
    case actions.CREATE_COMPANY_EMAIL:
      return {
        ...state,
        emailLoader: true,
      };
    case actions.COMPANY_EMAIL_MODEL_STATUS:
      return {
        ...state,
        companyEmailModalStatus: action.payload,
      };
    case actions.CREATE_COMPANY_EMAIL_SUCCESS:
      let email = cloneDeep(state.mailList);
      email.unshift(action.payload);
      return {
        ...state,
        mailList: email,
        emailLoader: false,
        companyEmailModalStatus: false,
      };
    case actions.CREATE_COMPANY_EMAIL_FAILURE:
      return {
        ...state,
        emailLoader: false,
      };
    case actions.SET_COMPANY_ACTIVITIES_FILTER:
      return {
        ...state,
        activitiesFilter: action.payload,
      };
    case actions.ADD_COMPANY_ACTIVITIES:
      const activities = cloneDeep(state.companyActivities);
      activities.unshift(action.payload);
      return {
        ...state,
        companyActivities: activities,
      };
    case actions.UPDATE_COMPANY:
      return {
        ...state,
        companyModalLoader: true,
      };
    case actions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companyDetails: action.payload,
        companyDrawerStatus: false,
        companyModalLoader: false,
      };
    case actions.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        companyModalLoader: false,
      };
    case actions.UPDATE_MODAL_COMPANY_ID:
      return {
        ...state,
        modalCompanyId: action.payload.company_id,
      };
    default: {
      return state;
    }
  }
}
