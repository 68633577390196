const actions = {
  FETCH_CHAT_ONEOFF_LIST: 'FETCH_CHAT_ONEOFF_LIST',
  FETCH_CHAT_ONEOFF_LIST_SUCCESS: 'FETCH_CHAT_ONEOFF_LIST_SUCCESS',
  FETCH_CHAT_ONEOFF_LIST_FAILURE: 'FETCH_CHAT_ONEOFF_LIST_FAILURE',
  APPEND_CHAT_ONEOFF_LIST: 'APPEND_CHAT_ONEOFF_LIST',
  APPEND_CHAT_ONEOFF_LIST_SUCCESS: 'APPEND_CHAT_ONEOFF_LIST_SUCCESS',
  APPEND_CHAT_ONEOFF_LIST_FAILURE: 'APPEND_CHAT_ONEOFF_LIST_FAILURE',
  SET_CHAT_ONEOFF_SORT_VALUES: 'SET_CHAT_ONEOFF_SORT_VALUES',
  SET_CHAT_ONEOFF_SEARCH_VALUE: 'SET_CHAT_ONEOFF_SEARCH_VALUE',
  SET_CHAT_ONEOFF_STATUS: 'SET_CHAT_ONEOFF_STATUS',
  SET_CREATE_CHAT_ONEOFF: 'SET_CREATE_CHAT_ONEOFF',
  GET_CHAT_ONEOFF_PROMPT_DETAILS: 'GET_CHAT_ONEOFF_PROMPT_DETAILS',
  GET_CHAT_ONEOFF_PROMPT_DETAILS_SUCCESS: 'GET_CHAT_ONEOFF_PROMPT_DETAILS_SUCCESS',
  GET_CHAT_ONEOFF_PROMPT_DETAILS_FAILURE: 'GET_CHAT_ONEOFF_PROMPT_DETAILS_FAILURE',
  SET_CHAT_ONEOFF_TAB_KEY: 'SET_CHAT_ONEOFF_TAB_KEY',
  SET_CHAT_ONEOFF_TAG_ID: 'SET_CHAT_ONEOFF_TAG_ID',
  SET_CHAT_ONEOFF_SCHEDULE_TIME: 'SET_CHAT_ONEOFF_SCHEDULE_TIME',
  SET_CHAT_ONEOFF_PROMOT_DETAILS: 'SET_CHAT_ONEOFF_PROMOTDETAILS',
  CHAT_ONEOFF_SCHEDULE_DETAILS: 'CHAT_ONEOFF_SCHEDULE_DETAILS',
  CHAT_ONEOFF_SCHEDULE_DETAILS_SUCCESS: 'CHAT_ONEOFF_SCHEDULE_DETAILS_SUCCESS',
  UPDATE_MESSAGE_CHAT_ONEOFF: 'UPDATE_MESSAGE_CHAT_ONEOFF',
  UPDATE_MESSAGE_CHAT_ONEOFF_SCHEDULE_DETAILS: 'UPDATE_MESSAGE_CHAT_ONEOFF_SCHEDULE_DETAILS',
  DELETE_CHAT_ONEOFF_DATA: 'DELETE_CHAT_ONEOFF_DATA',
  SET_CHAT_ONEOFF_SELECTED_ROWS: 'SET_CHAT_ONEOFF_SELECTED_ROWS',
  REMOVE_DELETED_ONE_OFF_CHAT_ID: 'REMOVE_DELETED_ONE_OFF_CHAT_ID',
};

export default actions;