export const tableColumnSortOrder = [];
tableColumnSortOrder['descend'] = 'desc';
tableColumnSortOrder['ascend'] = 'asc';

export const recipientMenuType = [
  null,
  'all_data',
  'sent_data',
  'opened_data',
  'clicked_data',
  'unsubscribed_data',
  'failed_data',
];

export const recipientMenuValue = [
  null,
  'sent',
  'delivered',
  'opened',
  'clicked',
  'unsubscribed',
  'failed',
];

export const editorColorOptions = [
  'rgb(97,189,109)',
  'rgb(26,188,156)',
  'rgb(84,172,210)',
  'rgb(44,130,201)',
  'rgb(147,101,184)',
  'rgb(71,85,119)',
  'rgb(204,204,204)',
  'rgb(65,168,95)',
  'rgb(0,168,133)',
  'rgb(61,142,185)',
  'rgb(41,105,176)',
  'rgb(85,57,130)',
  'rgb(40,50,78)',
  'rgb(0,0,0)',
  'rgb(247,218,100)',
  'rgb(251,160,38)',
  'rgb(235,107,86)',
  'rgb(226,80,65)',
  'rgb(163,143,132)',
  'rgb(239,239,239)',
  'rgb(255,255,255)',
  'rgb(250,197,28)',
  'rgb(243,121,52)',
  'rgb(209,72,65)',
  'rgb(184,49,47)',
  'rgb(124,112,107)',
  'rgb(209,213,216)',
];

export const dateFormat = 'll';

export const sentEmailEditWarning =
  "Can't able to edit the sent broadcast email";

export const dayOptions = [
  { label: 'Sun', value: 0, disabled: false },
  { label: 'Mon', value: 1, disabled: false },
  { label: 'Tue', value: 2, disabled: false },
  { label: 'Wed', value: 3, disabled: false },
  { label: 'Thr', value: 4, disabled: false },
  { label: 'Fri', value: 5, disabled: false },
  { label: 'Sat', value: 6, disabled: false },
];

export const brandingName = 'Powered by Gist';

export const fetchRecipientsTimeInterval = 500;
export const generalTimeInterval = 500;
export const emailComposerSavingInterval = 1000;
export const emailEditorSavingInterval = 2000;

export const toolbarButtons = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'subscript',
  'superscript',
  'fontFamily',
  'fontSize',
  'textColor',
  'backgroundColor',
  'paragraphFormat',
  'lineHeight',
  'align',
  'formatOLSimple',
  'formatUL',
  'outdent',
  'indent',
  'leftToRight',
  'rightToLeft',
  'insertLink',
  'insertImage',
  'insertTable',
  'emoticons',
  'personalize',
  'insertButton',
  'clearFormatting',
  'selectAll',
  'insertHR',
  'undo',
  'redo',
  'fullscreen',
  'html',
];

export const pluginsEnabled = [
  'image',
  'link',
  'codeView',
  'codeBeautifier',
  'emoticons',
  'align',
  'colors',
  'fontFamily',
  'fontSize',
  'fullscreen',
  'lineBreaker',
  'lists',
  'save',
  'quote',
  'paragraphFormat',
  'video',
  'table',
  'lineHeight',
];
export const imageEditButtons = [
  'imageSize',
  'imageAlign',
  'imageAlt',
  '|',
  'imageLink',
  'linkOpen',
  'linkEdit',
  'linkRemove',
  '|',
  'imageReplace',
  'imageRemove'
];

export const fontFamily = {
  "Arial,sans-serif": 'Arial',
  "Comic Sans MS,sans-serif": 'Comic Sans',
  "Courier New,sans-serif": 'Courier New',
  "Georgia,sans-serif": 'Georgia',
  "Helvetica,sans-serif": 'Helvetica',
  "Impact,sans-serif": 'Impact',
  "Tahoma,sans-serif": 'Tahoma',
  "Times New Roman,sans-serif": 'Times New Roman',
  "Trebuchet MS,sans-serif": 'Trebuchet',
  "Verdana,sans-serif": 'Verdana'
}

export const extraSpaceRegex = /(\r\n|\n|\r)/gm;

export const emailSendingHours = [
  { label: '12:00 AM', value: '00:00' },
  { label: '12:30 AM', value: '00:30' },
  { label: '01:00 AM', value: '01:00' },
  { label: '01:30 AM', value: '01:30' },
  { label: '02:00 AM', value: '02:00' },
  { label: '02:30 AM', value: '02:30' },
  { label: '03:00 AM', value: '03:00' },
  { label: '03:30 AM', value: '03:30' },
  { label: '04:00 AM', value: '04:00' },
  { label: '04:30 AM', value: '04:30' },
  { label: '05:00 AM', value: '05:00' },
  { label: '05:30 AM', value: '05:30' },
  { label: '06:00 AM', value: '06:00' },
  { label: '06:30 AM', value: '06:30' },
  { label: '07:00 AM', value: '07:00' },
  { label: '07:30 AM', value: '07:30' },
  { label: '08:00 AM', value: '08:00' },
  { label: '08:30 AM', value: '08:30' },
  { label: '09:00 AM', value: '09:00' },
  { label: '09:30 AM', value: '09:30' },
  { label: '10:00 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11:00 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12:00 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '01:00 PM', value: '13:00' },
  { label: '01:30 PM', value: '13:30' },
  { label: '02:00 PM', value: '14:00' },
  { label: '02:30 PM', value: '14:30' },
  { label: '03:00 PM', value: '15:00' },
  { label: '03:30 PM', value: '15:30' },
  { label: '04:00 PM', value: '16:00' },
  { label: '04:30 PM', value: '16:30' },
  { label: '05:00 PM', value: '17:00' },
  { label: '05:30 PM', value: '17:30' },
  { label: '06:00 PM', value: '18:00' },
  { label: '06:30 PM', value: '18:30' },
  { label: '07:00 PM', value: '19:00' },
  { label: '07:30 PM', value: '19:30' },
  { label: '08:00 PM', value: '20:00' },
  { label: '08:30 PM', value: '20:30' },
  { label: '09:00 PM', value: '21:00' },
  { label: '09:30 PM', value: '21:30' },
  { label: '10:00 PM', value: '22:00' },
  { label: '10:30 PM', value: '22:30' },
  { label: '11:00 PM', value: '23:00' },
  { label: '11:30 PM', value: '23:30' },
  { label: 'Midnight', value: '23:59' },
];

export const BeeTemplates = [
  {
    id: 'announcement',
    name: 'Announcement',
    json: 'announcement.json',
    thumbnail: 'announcement.png',
  },
  {
    id: 'article',
    name: 'Article',
    json: 'article.json',
    thumbnail: 'article.png',
  },
  {
    id: 'BF-basic-e-commerce',
    name: 'Basic E-Commerce',
    json: 'BF-basic-e-commerce.json',
    thumbnail: 'BF-basic-e-commerce.png',
  },
  {
    id: 'BF-basic-newsletter',
    name: 'Basic Newsletter',
    json: 'BF-basic-newsletter.json',
    thumbnail: 'BF-basic-newsletter.png',
  },
  {
    id: 'BF-basic-onecolumn',
    name: 'Basic One Column',
    json: 'BF-basic-onecolumn.json',
    thumbnail: 'BF-basic-onecolumn.png',
  },
  {
    id: 'BF-basic-standard',
    name: 'Basic Standard',
    json: 'BF-basic-standard.json',
    thumbnail: 'BF-basic-standard.png',
  },
  {
    id: 'BF-ecommerce-template',
    name: 'E-commerce Template',
    json: 'BF-ecommerce-template.json',
    thumbnail: 'BF-ecommerce-template.png',
  },
  {
    id: 'newsletter',
    name: 'Newsletter',
    json: 'newsletter.json',
    thumbnail: 'newsletter.png',
  },
  {
    id: 'BF-newsletter-template',
    name: 'Newsletter Template',
    json: 'BF-newsletter-template.json',
    thumbnail: 'BF-newsletter-template.png',
  },
  {
    id: 'personal-touch',
    name: 'Personal Touch',
    json: 'personal-touch.json',
    thumbnail: 'personal-touch.png',
  },
  {
    id: 'BF-promo-template',
    name: 'Promo Template',
    json: 'BF-promo-template.json',
    thumbnail: 'BF-promo-template.png',
  },
  {
    id: 'BF-simple-template',
    name: 'Simple Template',
    json: 'BF-simple-template.json',
    thumbnail: 'BF-simple-template.png',
  },
  {
    id: 'video',
    name: 'Video',
    json: 'video.json',
    thumbnail: 'video.png',
  },
];

export const beeCustomFonts = {
  showDefaultFonts: false,
  customFonts: [
    { name: "Arial", fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif" },
    { name: "Courier", fontFamily: "'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace" },
    { name: "Georgia", fontFamily: "Georgia, Times, 'Times New Roman', serif" },
    { name: "Helvetica", fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif" },
    { name: "Lucida Sans", fontFamily: "'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif" },
    { name: "Tahoma", fontFamily: "Tahoma, Verdana, Segoe, sans-serif" },
    { name: "Times New Roman", fontFamily: "TimesNewRoman, 'Times New Roman', Times, Beskerville, Georgia, serif" },
    { name: "Trebuchet MS", fontFamily: "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif" },
    { name: "Verdana", fontFamily: "Verdana, Geneva, sans-serif" },
    { name: "ヒラギノ角ゴ Pro W3", fontFamily: "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif" },
    { name: "メイリオ", fontFamily: "メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, sans-serif" },
    { name: "Abril Fatface", fontFamily: "'Abril Fatface', Arial, 'Helvetica Neue', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Abril+Fatface" },
    { name: "Bitter", fontFamily: "'Bitter', Georgia, Times, 'Times New Roman', serif", url: "https://fonts.googleapis.com/css?family=Bitter" },
    { name: "Cabin", fontFamily: "'Cabin', Arial, 'Helvetica Neue', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Cabin" },
    { name: "Droid Serif", fontFamily: "'Droid Serif', Georgia, Times, 'Times New Roman', serif", url: "https://fonts.googleapis.com/css?family=Droid+Serif" },
    { name: "Lato", fontFamily: "'Lato', Tahoma, Verdana, Segoe, sans-serif", url: "https://fonts.googleapis.com/css?family=Lato" },
    { name: "Open Sans", fontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif", url: "https://fonts.googleapis.com/css?family=Open+Sans" },
    { name: "Roboto", fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif", url: "https://fonts.googleapis.com/css?family=Roboto" },
    { name: "Source Sans Pro", fontFamily: "'Source Sans Pro', Tahoma, Verdana, Segoe, sans-serif", url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro" },
    { name: "Merriweather", fontFamily: "'Merriweather', 'Georgia', serif", url: "https://fonts.googleapis.com/css?family=Merriweather" },
    { name: "Montserrat", fontFamily: "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif", url: "https://fonts.googleapis.com/css?family=Montserrat" },
    { name: "Nunito", fontFamily: "'Nunito', Arial, 'Helvetica Neue', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Nunito" },
    { name: "Oswald", fontFamily: "'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Oswald" },
    { name: "Oxygen", fontFamily: "'Oxygen', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif", url: "https://fonts.googleapis.com/css?family=Oxygen" },
    { name: "Permanent Marker", fontFamily: "'Permanent Marker', Impact, Charcoal, sans-serif", url: "https://fonts.googleapis.com/css?family=Permanent+Marker" },
    { name: "Roboto Slab", fontFamily: "'Roboto Slab', Arial, 'Helvetica Neue', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Roboto+Slab" },
    { name: "Ubuntu", fontFamily: "'Ubuntu', Tahoma, Verdana, Segoe, sans-serif", url: "https://fonts.googleapis.com/css?family=Ubuntu" },
    { name: "Alegreya", fontFamily: "'Alegreya', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Alegreya" },
    { name: "Arvo", fontFamily: "'Arvo', 'Courier New', Courier, monospace", url: "https://fonts.googleapis.com/css?family=Arvo" },
    { name: "Catamaran", fontFamily: "'Catamaran', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif", url: "https://fonts.googleapis.com/css?family=Catamaran" },
    { name: "Chivo", fontFamily: "'Chivo', Arial, Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Chivo" },
    { name: "Cormorant Garamond", fontFamily: "'Cormorant Garamond', 'Times New Roman', Times, serif", url: "https://fonts.googleapis.com/css?family=Cormorant+Garamond" },
    { name: "Dosis", fontFamily: "'Dosis', Arial, Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Dosis" },
    { name: "Fira Sans", fontFamily: "'Fira Sans', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif", url: "https://fonts.googleapis.com/css?family=Fira+Sans" },
    { name: "Lora", fontFamily: "'Lora', Georgia, serif", url: "https://fonts.googleapis.com/css?family=Lora" },
    { name: "Monda", fontFamily: "'Monda', 'Courier New', Courier, monospace", url: "https://fonts.googleapis.com/css?family=Monda" },
    { name: "Noto Sans", fontFamily: "'Noto Sans', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Noto+Sans" },
    { name: "Noto Serif", fontFamily: "'Noto Serif', Georgia, serif", url: "https://fonts.googleapis.com/css?family=Noto+Serif" },
    { name: "Onest", fontFamily: "'Onest', Arial, sans-serif", url: "https://fonts.googleapis.com/css?family=Onest" },
    { name: "Playfair Display", fontFamily: "'Playfair Display', Georgia, serif", url: "https://fonts.googleapis.com/css?family=Playfair+Display" },
    { name: "Poppins", fontFamily: "'Poppins', Arial, Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Poppins" },
    { name: "Quattrocento", fontFamily: "'Quattrocento', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Quattrocento" },
    { name: "Quattrocento Sans", fontFamily: "'Quattrocento Sans', Georgia, serif", url: "https://fonts.googleapis.com/css?family=Quattrocento+Sans" },
    { name: "Questrial", fontFamily: "'Questrial', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Questrial" },
    { name: "Raleway", fontFamily: "'Raleway', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Raleway" },
    { name: "Rubik", fontFamily: "'Rubik', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Rubik" },
    { name: "Ruda", fontFamily: "'Ruda', 'Courier New', Courier, monospace", url: "https://fonts.googleapis.com/css?family=Ruda" },
    { name: "Shrikhand", fontFamily: "'Shrikhand', Impact, Charcoal, sans-serif", url: "https://fonts.googleapis.com/css?family=Shrikhand" },
    { name: "Sintony", fontFamily: "'Sintony', 'Courier New', Courier, monospace", url: "https://fonts.googleapis.com/css?family=Sintony" },
    { name: "Varela Round", fontFamily: "'Varela Round', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Varela+Round" },
    { name: "Work Sans", fontFamily: "'Work Sans', 'Trebuchet MS', Helvetica, sans-serif", url: "https://fonts.googleapis.com/css?family=Work+Sans" }
  ]
};

export const broadcastCompleted = ['sent', 'sending', 'stopped', 'queued'];