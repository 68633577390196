import { Button, Card, Icon, Spin } from 'antd';
import React, { useEffect, useMemo } from 'react';
import actions from '../../redux/auth/actions';
import {
  fetchProjectSecretKey,
  getQueryParamsValue,
  updateBrowserHistory,
} from '../../helpers/utility';
import { useDispatch, useSelector } from 'react-redux';
import appPlatformActions from '../../redux/appPlatform/actions';

const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />;

const OauthAuthorizeDetails = ({ workspaceId }) => {
  const dispatch = useDispatch(),
    projects = useSelector(({ Auth }) => Auth.projects),
    clientId = useMemo(
      () =>
        getQueryParamsValue('client_id') === 'null'
          ? null
          : getQueryParamsValue('client_id'),
      [],
    ),
    redirectURI = useMemo(
      () =>
        getQueryParamsValue('redirect_uri') === 'null'
          ? null
          : getQueryParamsValue('redirect_uri'),
      [],
    ),
    responseType = useMemo(
      () =>
        getQueryParamsValue('response_type') === 'null'
          ? null
          : getQueryParamsValue('response_type'),
      [],
    ),
    state = useMemo(
      () =>
        getQueryParamsValue('state') === 'null'
          ? null
          : getQueryParamsValue('state'),
      [],
    );

  const authorize = () => {
    dispatch({
      type: actions.AUTHORIZE_APP,
      payload: {
        client_id: clientId,
        redirect_uri: redirectURI,
        response_type: responseType,
        scope: appByClientId.credentials.scopes.join(' '),
        workspace_id: workspaceId,
        ...(state ? { state } : {}),
      },
    });
  };

  const oauthDenied = () => {
    dispatch({ type: actions.DENY_APP, payload: clientId });
    updateBrowserHistory('apps');
  };

  const getScopes = appByClientIdScopes => {
    let scopes = [];
    oAuthScopes.forEach(scope => {
      if (appByClientIdScopes.includes(scope.key)) scopes.push(scope.name);
    });
    return scopes.map(scope => (
      <li>
        <div>{scope}</div>
      </li>
    ));
  };

  const findAppByClientIdLoader = useSelector(
      ({ Auth }) => Auth.findAppByClientIdLoader,
    ),
    appByClientId = useSelector(({ Auth }) => Auth.appByClientId),
    oAuthScopes = useSelector(({ AppPlatform }) => AppPlatform.oAuthScopes),
    currentProjectName = useSelector(({ Auth }) => Auth.currentProjectName);
  let projectName = currentProjectName;
  if (fetchProjectSecretKey() !== workspaceId) {
    const projectDetail = projects.find(
      ({ secret_key }) => secret_key === workspaceId,
    );
    if (projectDetail) {
      projectName = projectDetail.project_name;
    }
  }

  useEffect(() => {
    if (clientId) {
      dispatch({
        type: actions.FIND_APP_BY_CLIENT_ID,
        clientId,
      });
      dispatch({
        type: appPlatformActions.FETCH_O_AUTH_SCOPES_AND_CATEGORIES,
      });
    }
  }, []);

  return (
    <div className="oauth-authorize-container">
      {findAppByClientIdLoader ? (
        <Spin indicator={antIcon} className="spinner-align" />
      ) : (
        <div className={'oauth-authorize-container-inner'}>
          {Object.keys(appByClientId).length > 0 && (
            <>
              {appByClientId.logo && (
                <img src={appByClientId.logo} alt="logo" className="app-logo" />
              )}
              <div className={'oauth-authorize-header'}>
                <div>
                  {appByClientId.name.replace('<beta>', '')} wants to access
                  your workspace ({projectName})
                </div>
                <span>
                  This will allow the {appByClientId.name.replace('<beta>', '')}{' '}
                  to perform following actions:
                </span>
              </div>
              <Card bordered={false} className={'oauth-authorize-card'}>
                <ul>
                  {appByClientId.credentials &&
                  appByClientId.credentials.scopes &&
                  appByClientId.credentials.scopes.length ? (
                    getScopes(appByClientId.credentials.scopes)
                  ) : (
                    <li>{appByClientId.name} will access your project</li>
                  )}
                </ul>
                <div className={'footer-btn'}>
                  <Button
                    type={'default'}
                    onClick={oauthDenied}
                    className={'ant-btn btn btn-secondary-outline btn-sm'}
                  >
                    Deny
                  </Button>
                  <Button
                    type={'primary'}
                    disabled={
                      !clientId || !redirectURI || findAppByClientIdLoader
                    }
                    onClick={authorize}
                    className={'ant-btn btn btn-primary btn-sm'}
                  >
                    Authorize
                  </Button>
                </div>
              </Card>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OauthAuthorizeDetails;
