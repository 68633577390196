import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
} from '../../helpers/utility';
import actions from './actions';
import { message } from 'antd';
import { getTableValues } from '../../components/Messages/Surveys/Shared/Helper';
import settings from '../../settings';
import surveyOneOffActions from '../survey/oneOff/actions';
import surveyOnGoingActions from '../survey/onGoing/actions';
import displayRuleActions from '../displayRules/actions';
import filterActions from '../filters/actions';
import { filterVariables } from '../constants';

export function* fetchSurveyTableLists(params) {
  const surveyType = getTableValues().surveyType;
  try {
    if (surveyType === 'on_going') {
      yield put({
        type: surveyOnGoingActions.SET_SURVEY_ONGOING_TABLE_LOADER,
        payload: true,
      });
    } else {
      yield put({
        type: surveyOneOffActions.SET_SURVEY_ONEOFF_TABLE_LOADER,
        payload: true,
      });
    }
    let searchValue = '';
    let sortKey = '';
    let sortOrder = '';
    let status = '';
    let tagID = '';
    let lastSurveyId = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = getTableValues().searchValue;
    }
    if (params && params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = getTableValues().sortKey;
      sortOrder = getTableValues().sortOrder;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = getTableValues().status;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = getTableValues().tagIds;
    }
    if (params.lastSurveyId) {
      lastSurveyId = params.lastSurveyId;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys?status=${status}&survey_type=${surveyType}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&last_survey_id=${lastSurveyId}`;
    const data = yield call(() => getPromise(url));
    if (surveyType === 'on_going') {
      yield put({
        type: surveyOnGoingActions.FETCH_SURVEY_ONGOING_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: surveyOneOffActions.FETCH_SURVEY_ONEOFF_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (surveyType === 'on_going') {
      yield put({
        type: surveyOnGoingActions.SET_SURVEY_ONGOING_TABLE_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: surveyOneOffActions.SET_SURVEY_ONEOFF_TABLE_LOADER,
        payload: false,
      });
    }
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch survey list failed');
    }
  }
}

export function* appendSurveyTableLists(params) {
  const surveyType = getTableValues().surveyType;
  try {
    if (surveyType === 'on_going') {
      yield put({
        type: surveyOnGoingActions.SET_SURVEY_ONGOING_TABLE_LOADER,
        payload: true,
      });
    } else {
      yield put({
        type: surveyOneOffActions.SET_SURVEY_ONEOFF_TABLE_LOADER,
        payload: true,
      });
    }
    let searchValue = '';
    let sortKey = '';
    let sortOrder = '';
    let status = '';
    let tagID = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = getTableValues().searchValue;
    }
    if (params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = getTableValues().sortKey;
      sortOrder = getTableValues().sortOrder;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = getTableValues().tagIds;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = getTableValues().status;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys?status=${status}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&survey_type=${surveyType}&last_survey_id=${
      params.payload.getLastId
    }`;
    const data = yield call(() => getPromise(url));
    if (surveyType === 'on_going') {
      yield put({
        type: surveyOnGoingActions.APPEND_SURVEY_ONGOING_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: surveyOneOffActions.APPEND_SURVEY_ONEOFF_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (surveyType === 'on_going') {
      yield put({
        type: surveyOnGoingActions.SET_SURVEY_ONGOING_TABLE_LOADER,
        payload: false,
      });
    } else {
      yield put({
        type: surveyOneOffActions.SET_SURVEY_ONEOFF_TABLE_LOADER,
        payload: false,
      });
    }
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch ongoing messages failed');
    }
  }
}

export function* createSurvey(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys`;
    const data = yield call(() => postPromise(url, params.data));
    trackEvent('Created survey');
    yield put({ type: actions.CREATE_SURVEY_SUCCESS });
    updateBrowserHistory(`surveys/${data.data.survey.id}`, {
      survey_id: data.data.survey.id,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.CREATE_SURVEY_FAILURE });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Create ${getTableValues().tableState} failed`);
    }
  }
}

export function* updateSurveyTableElement(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.id}`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    const { survey } = data.data;
    if (params.table) {
      yield put({
        type: actions.FETCH_TABLE_LISTS_IN_SURVEY,
      });
    }
    yield put({
      type: actions.UPDATE_SURVEY_SAVED_DETAILS,
    });
    yield put({
      type: surveyOnGoingActions.UPDATE_SURVEY_DETAILS,
      payload: survey,
    });
    yield put({
      type: actions.SURVEY_SAVE_BUTTON_LOADER_STATUS,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.SURVEY_SAVE_BUTTON_LOADER_STATUS,
      payload: false,
    });
    yield put({
      type: actions.UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_SURVEY,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Update ${getTableValues().tableState} failed`);
    }
  }
}

export function* deleteSurveyTableData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.id}`;
    let data = yield call(() => deletePromise(url));
    if (params.isRedirect) {
      updateBrowserHistory('messages/surveys');
    } else {
      yield put({
        type: actions.FETCH_TABLE_LISTS_IN_SURVEY,
      });
    }
    if (
      params.requestFrom === 'on_going' ||
      getTableValues().surveyType === 'on_going'
    ) {
      yield put({
        type: surveyOnGoingActions.REMOVE_DELETED_ON_GOING_SURVEY_ID,
        deletedSurveyId: params.id,
      });
    } else if (
      params.requestFrom === 'one_off' ||
      getTableValues().surveyType === 'one_off'
    ) {
      yield put({
        type: surveyOneOffActions.REMOVE_DELETED_ONE_OFF_SURVEY_ID,
        deletedSurveyId: params.id,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Delete ${getTableValues().tableState} failed`);
    }
  }
}

export function* applySurveyTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/apply_tag`;
    const responseData = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.FETCH_TABLE_LISTS_IN_SURVEY,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Survey tags apply failed');
    }
  }
}

export function* removeSurveyTags(param) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/remove_tag?feature_type=Survey&feature_id=${
      param.payload.id
    }&tag_id=${param.payload.tagId}`;
    const responseData = yield call(() => postPromise(url));
    yield put({
      type: actions.FETCH_TABLE_LISTS_IN_SURVEY,
    });
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Remove survey tags failed');
    }
  }
}

export function* duplicateSurvey(params) {
  try {
    yield put({ type: surveyOnGoingActions.SET_SURVEY_LOADER, payload: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${
      params.duplicateId
    }/duplicate`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({ type: actions.DUPLICATE_SURVEY_SUCCESS });
    updateBrowserHistory(`surveys/${data.data.survey.id}`);
    const { survey } = data.data;
    yield put({
      type: actions.UPDATE_LOCAL_SURVEY_DATA,
      payload: {
        survey: { ...survey.content, id: survey.id, name: survey.name },
      },
      isInitialSurveyLogics: true,
    });
    yield put({
      type: surveyOnGoingActions.GET_SURVEY_DETAILS_SUCCESS,
      payload: survey,
      surveyTabKey: '1',
    });
    if (survey.survey_type === 'on_going') {
      yield put({
        type: displayRuleActions.SET_DISPLAY_RULES,
        payload: survey.display_conditions,
      });
    } else {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: survey.display_conditions,
        filterName: filterVariables.oneOffSurveyKey,
      });
    }
    yield put({ type: surveyOnGoingActions.SET_SURVEY_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.DUPLICATE_SURVEY_FAILURE });
    yield put({ type: surveyOnGoingActions.SET_SURVEY_LOADER, payload: false });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error(`Duplicate ${getTableValues().tableState} failed`);
    }
  }
}

export function* fetchGoogleFonts() {
  try {
    const url = settings.GOOGLE_WEB_FONTS_URL;
    let response = yield call(() => getPromise(url, false));
    yield put({
      type: actions.FETCH_GOOGLE_FONTS_SUCCESS,
      payload: response.items,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch google fonts failed');
  }
}
export function* fetchSurveyDetails(params) {
  try {
    // yield put({type: actions.SET_MESSAGE_CHAT_LOADER_IN_POST, payload: true});
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.id}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SURVEY_DETAILS_SUCCESS,
      payload: response.data.survey,
    });

    // if (response.data.triggered_chat.message_type === 'one_off_announcement') {
    //   yield put({
    //     type: filterActions.SET_FILTERS,
    //     payload: response.data.triggered_chat.display_conditions,
    //     filterName: filterVariables.oneOffChatKey,
    //   });
    // }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.FETCH_SURVEY_DETAILS_FAILURE });
    message.error('Fetch survey failed');
  }
}

export function* fetchCustomThemes() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_themes`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_SURVEY_CUSTOM_THEMES_SUCCESS,
      payload: data.data.survey_themes,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_SURVEY_CUSTOM_THEMES_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch survey themes failed');
    }
  }
}

export function* createCustomTheme(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_themes`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.CREATE_SURVEY_CUSTOM_THEMES_SUCCESS,
      payload: data.data.survey_theme,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_SURVEY_CUSTOM_THEMES_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Create survey theme failed');
    }
  }
}

export function* updateCustomTheme(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_themes/${params.surveyThemeId}`;
    const data = yield call(() => patchPromise(url, params.data));
    yield put({
      type: actions.UPDATE_SURVEY_CUSTOM_THEMES_SUCCESS,
      payload: data.data.survey_theme,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_SURVEY_CUSTOM_THEMES_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update survey theme failed');
    }
  }
}

export function* deleteCustomTheme(params) {
  try {
    const { surveyThemeId } = params;
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/survey_themes/${surveyThemeId}`;
    const data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_SURVEY_CUSTOM_THEMES_SUCCESS,
      surveyThemeId,
    });
    if (params.getTheme) {
      yield put({
        type: actions.SET_LOCAL_SURVEY_THEME,
        payload: params.getTheme.id ? params.getTheme.details : params.getTheme,
        selectedThemeId: params.getTheme.id,
      });
    }
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_SURVEY_CUSTOM_THEMES_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Delete survey theme failed');
    }
  }
}

export function* fetchSurveyReports(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.surveyId}/responses`;
    if (params.isResponsePagination) {
      url += `?last_response_id=${params.payload.surveyLastResponseId}`;
    }
    const data = yield call(() => getPromise(url));
    if (!params.isResponsePagination) {
      yield put({
        type: actions.FETCH_SURVEY_ONGOING_REPORTS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actions.APPEND_SURVEY_ONGOING_REPORTS_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_SURVEY_ONGOING_REPORTS_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Fetch survey responses failed');
    }
  }
}

export function* exportSurveyResponseCSV(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${
      params.surveyId
    }/export_response`;
    const data = yield call(() => postPromise(url, params.data));
    yield put({
      type: actions.EXPORT_SURVEY_CSV_LOADER,
      payload: false,
    });
    yield put({
      type: actions.EXPORT_SURVEY_CSV_MODAL,
      payload: false,
    });
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.EXPORT_SURVEY_CSV_LOADER,
      payload: false,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Export survey response failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_GOOGLE_FONTS, fetchGoogleFonts),
    takeEvery(actions.FETCH_TABLE_LISTS_IN_SURVEY, fetchSurveyTableLists),
    takeEvery(actions.APPEND_TABLE_LISTS_IN_SURVEY, appendSurveyTableLists),
    takeEvery(actions.CREATE_SURVEY, createSurvey),
    takeEvery(
      actions.UPDATE_TABLE_ELEMENT_STATUS_IN_SURVEY,
      updateSurveyTableElement,
    ),
    takeEvery(actions.DELETE_TABLE_DATA_IN_SURVEY, deleteSurveyTableData),
    takeEvery(actions.APPLY_SURVEY_TAG, applySurveyTags),
    takeEvery(actions.REMOVE_SURVEY_TAG, removeSurveyTags),
    takeEvery(actions.DUPLICATE_SURVEY, duplicateSurvey),
    takeEvery(actions.FETCH_SURVEY_CUSTOM_THEMES, fetchCustomThemes),
    takeEvery(actions.CREATE_SURVEY_CUSTOM_THEMES, createCustomTheme),
    takeEvery(actions.UPDATE_SURVEY_CUSTOM_THEMES, updateCustomTheme),
    takeEvery(actions.DELETE_SURVEY_CUSTOM_THEMES, deleteCustomTheme),
    takeEvery(actions.FETCH_SURVEY_DETAILS, fetchSurveyDetails),
    takeEvery(actions.FETCH_SURVEY_ONGOING_REPORTS, fetchSurveyReports),
    takeEvery(actions.EXPORT_SURVEY_RESPONSE_CSV, exportSurveyResponseCSV),
  ]);
}
