import {
  inboxShortcutGroupId,
  shortcutsList,
  shortcutsListGroups,
} from '../../../containers/App/constants';
import JungleSelect from 'react-jungle-select';
import React from 'react';
import { extractConversationIdentifier } from '../../../helpers/utility';

const DefaultWidget = props => {
  const {
    selectedCommand,
    activeConversation,
    handleCommandSelect,
    getCommandItem,
    getGroupItem,
    showNewConversation,
  } = props;
  let isInbox = false;
  const pathName = window.location.pathname.split('/');

  if (
    pathName[3] === 'conversations' &&
    !['rules', 'rule', 'table', 'search', 'latest'].includes(pathName[4]) &&
    (showNewConversation || extractConversationIdentifier())
  ) {
    isInbox = true;
  }

  let shortcutsListLocal = shortcutsList.filter(
    ({ show_in_omni_search: showInOmniSearch }) => {
      if (typeof showInOmniSearch === 'function') {
        return showInOmniSearch(props);
      }
      return showInOmniSearch;
    },
  );
  let shortcutsListGroupsLocal = shortcutsListGroups.filter(
    ({ show_in_omni_search: showInOmniSearch }) => {
      if (typeof showInOmniSearch === 'function') {
        return showInOmniSearch(props);
      }
      return showInOmniSearch;
    },
  );

  if (!isInbox) {
    shortcutsListLocal = shortcutsListLocal.filter(
      item => !inboxShortcutGroupId.includes(item.groupId),
    );

    shortcutsListGroupsLocal = shortcutsListGroupsLocal.filter(
      item => !inboxShortcutGroupId.includes(item.id),
    );
  } else {
    shortcutsListLocal = shortcutsListLocal.filter(item => {
      if (activeConversation && activeConversation.status === 'closed') {
        return item.action !== 'close_conversation';
      } else if (activeConversation && activeConversation.status === 'open') {
        return item.action !== 'reopen_conversation';
      } else if (
        activeConversation &&
        activeConversation.status === 'pending'
      ) {
        return item.action !== 'snooze_conversation';
      }
      return true;
    });
  }

  return (
    <JungleSelect
      items={shortcutsListLocal}
      groups={shortcutsListGroupsLocal}
      renderGroup={(group, items) => getGroupItem(group, items)}
      renderItem={item => getCommandItem(item)}
      placeholder="Type a command or search..."
      searchable={true}
      searchableAttributes={['name']}
      listWrapper={list => <div className="command-list">{list}</div>}
      onChange={value => handleCommandSelect(value)}
      mode="list"
      selectFirstItem={true}
      selected={selectedCommand}
      autofocus={true}
    />
  );
};

export default DefaultWidget;
