import { call, put, all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import surveyActions from '../actions';
import settings from '../../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  unAuthorisedRedirection,
} from '../../../helpers/utility';
import filterActions from '../../filters/actions';
import { filterVariables } from '../../constants';
import { message } from 'antd';
import { getTableValues } from '../../../components/Messages/Surveys/Shared/Helper';
import displayRuleActions from '../../displayRules/actions';

let defaultOnGoingDisplayConditions = [
    {
      criteria: [
        {
          key: 'current_url_path',
          unit: null,
          condition: 'is_any_page',
          conditions: [
            {
              label: 'is any page',
              value: 'is_any_page',
            },
            {
              label: 'is the homepage',
              value: 'is_homepage',
            },
            {
              label: 'is not the homepage',
              value: 'is_not_homepage',
            },
            {
              label: 'is exactly',
              value: 'equals',
            },
            {
              label: 'is not exactly',
              value: 'not_equals',
            },
            {
              label: 'contains',
              value: 'contains',
            },
            {
              label: 'does not contain',
              value: 'not_contains',
            },
            {
              label: 'starts with',
              value: 'starts_with',
            },
            {
              label: 'does not starts with',
              value: 'does_not_starts_with',
            },
            {
              label: 'ends with',
              value: 'ends_with',
            },
            {
              label: 'does not ends with',
              value: 'does_not_ends_with',
            },
            {
              label: 'matches the pattern',
              value: 'matches_the_pattern',
            },
          ],
          selectedType: 'url',
        },
      ],
      criteria_operator: 'AND',
    },
    {
      criteria: [
        {
          key: 'time_on_page',
          unit: 'secs',
          value: 5,
          condition: 'greater_than',
          conditions: [
            {
              label: 'is greater than',
              value: 'greater_than',
            },
            {
              label: 'is less than',
              value: 'less_than',
            },
            {
              label: 'is equal to',
              value: 'equals',
            },
            {
              label: 'is less than or equal to',
              value: 'less_or_equal',
            },
            {
              label: 'is greater than or equal to',
              value: 'greater_or_equal',
            },
          ],
          selectedType: 'number',
        },
      ],
    },
  ],
  defaultOneOffDisplayConditions = [
    {
      criteria: [
        {
          key: 'email',
          condition: 'has_any_value',
        },
      ],
    },
  ];

export function* getSurveyDetails(params) {
  try {
    yield put({ type: actions.SET_SURVEY_LOADER, payload: true });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.id}`;
    const data = yield call(() => getPromise(url));
    const { survey } = data.data;

    yield put({
      type: surveyActions.UPDATE_LOCAL_SURVEY_DATA,
      payload: {
        survey: { ...survey.content, id: survey.id, name: survey.name },
      },
      isInitialSurveyLogics: true,
    });
    yield put({
      type: actions.GET_SURVEY_DETAILS_SUCCESS,
      payload: { ...survey, content: survey.content },
      surveyTabKey: '1',
    });
    if (survey.message_goal) {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: survey.message_goal.goal_filter,
        filterName: filterVariables.surveyKeyGoal,
      });
    }
    if (survey.survey_type === 'on_going') {
      yield put({
        type: displayRuleActions.SET_DISPLAY_RULES,
        payload: survey.display_conditions || defaultOnGoingDisplayConditions,
      });
    } else {
      yield put({
        type: filterActions.SET_FILTERS,
        payload: survey.display_conditions || defaultOneOffDisplayConditions,
        filterName: filterVariables.oneOffSurveyKey,
      });
    }
    yield put({ type: actions.SET_SURVEY_LOADER, payload: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({ type: actions.SET_SURVEY_LOADER, payload: false });
    message.error(`Fetch ${getTableValues().tableState} survey failed`);
  }
}

export function* changeSurveyPosition(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.id}/update_priority`;
    let data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.SET_SURVEY_TABLE_DATA,
      payload: params.data,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    // TODO: need to check
    // yield put({
    //   type: actions.UPDATE_TABLE_ELEMENT_STATUS_FAILURE_IN_SURVEY,
    // });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Survey update priority failed');
    }
  }
}

export function* updateSurveyStatus(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/surveys/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload.survey));
    yield put({
      type: actions.UPDATE_SURVEY_STATUS_SUCCESS,
    });
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.UPDATE_SURVEY_STATUS_FAILURE,
      payload: params.payload.survey.status,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Survey update failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SURVEY_DETAILS, getSurveyDetails),
    takeEvery(actions.CHANGE_SURVEY_POSITION, changeSurveyPosition),
    takeEvery(actions.UPDATE_SURVEY_STATUS, updateSurveyStatus),
  ]);
}
