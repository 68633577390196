import React from 'react';
import { parseMarkdown } from '../../../../../helpers/utility';
import '../style.scss';

const TextComponent = props => {
  const { component } = props;

  return (
    <p
      className={`${component.style}${
        component.align ? ` align-${component.align}` : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
    >
      {parseMarkdown(component.text)}
    </p>
  );
};

export default TextComponent;
