import { useDispatch, useSelector } from 'react-redux';
import chatActions from '../../../redux/chat/actions';
import { canWeAccess, extractCurrentUserId } from '../../../helpers/utility';
import sharedActions from '../../../redux/sharedReducers/actions';
import InboxStatusIndicator from '../../Chat/InboxStatusIndicator';
import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import DefaultUserAvatar from '../../../image/avatar-placeholder.png';
import JungleSelect from 'react-jungle-select';
import { userRestriction } from '../../TopNavBar/constants';

const AssignWidget = props => {
  const dispatch = useDispatch();

  const teams = useSelector(({ Chat }) => Chat.teams),
    teamMates = useSelector(({ Chat }) => Chat.teamMates),
    profile = useSelector(({ Auth }) => Auth.profile);
  const {
    omniSearchEventListener,
    getGroupItem,
    removeOmniSearchListener,
    activeConversation,
    participants,
  } = props;
  const handleAssignSelect = value => {
    let assignedData;
    if (value.name === 'Unassigned' || value.name === 'Myself') {
      assignedData = value.name.toLowerCase();
    } else {
      if (!canWeAccess(userRestriction.reassignConversations)) {
        return;
      }
      assignedData = value.id;
    }
    let type = 1;
    let member_id = 1;
    dispatch({
      type: chatActions.UPDATE_CURRENT_ASSIGNEE,
      payload: assignedData,
      currentConversationIdentifier: activeConversation.conversation_identifier,
    });
    if (assignedData !== 'unassigned' && assignedData !== 'myself') {
      teamMates.map(teamMate => {
        if (assignedData === teamMate.id) {
          type = 'member';
          member_id = teamMate.id;
        }
        return teamMate;
      });
      teams.map(team => {
        if (assignedData === team.id) {
          member_id = team.id;
          type = 'team';
        }
        return team;
      });
    } else if (assignedData === 'unassigned') {
      type = 'unassign';
    } else if (assignedData === 'myself') {
      type = 'member';
      member_id = extractCurrentUserId();
    }

    let assignTo =
      assignedData !== 'unassigned' ? { assign_to: member_id } : '';

    let assignData = {
      ...assignTo,
      conversation_identifiers: [activeConversation.conversation_identifier],
      type: type,
    };
    dispatch({
      type: chatActions.POST_MESSAGE,
      assignData,
    });
    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  const getCommandItem = item => (
    <div
      className={`command-item ${
        !canWeAccess(userRestriction.reassignConversations) &&
        !['nogroup'].includes(item.groupId)
          ? 'disabled'
          : ''
      }`}
    >
      <div className={'command-item-inner'}>
        <span className="command-content">
          {['participants', 'teammates', 'nogroup'].includes(item.groupId) && (
            <>
              <InboxStatusIndicator teamMate={item} />
              <img src={item.avatar_url} className="avatar" alt={'user'} />
            </>
          )}

          {item.groupId === 'teams' && (
            <span className={'team-emoji'}>{item.team_emoji}</span>
          )}

          <span className="command-title">{item.name}</span>
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    omniSearchEventListener();

    return () => {
      removeOmniSearchListener();
    };
  }, []);

  let participantsData = cloneDeep(teamMates).filter(teammate =>
    participants.includes(teammate.id),
  );

  participantsData.forEach(item => {
    item.groupId = 'participants';
  });

  teams.forEach(item => {
    item.groupId = 'teams';
    item.name = item.team_name;
  });

  teamMates.forEach(item => {
    item.groupId = 'teammates';
  });

  let defaultOptions = [
    {
      name: 'Unassigned',
      avatar_url: DefaultUserAvatar,
    },
  ];
  profile.name = 'Myself';
  defaultOptions.push(profile);
  defaultOptions.forEach(item => {
    item.groupId = 'nogroup';
  });

  let assigneeData = [
    ...defaultOptions,
    ...participantsData,
    ...teams,
    ...teamMates,
  ];

  const assigneeGroupData = [
    {
      id: 'nogroup',
      label: '',
      show_in_omni_search: true,
    },
    {
      id: 'participants',
      label: 'Participants',
      show_in_omni_search: true,
    },
    {
      id: 'teams',
      label: 'Teams',
      show_in_omni_search: true,
    },
    {
      id: 'teammates',
      label: 'Teammates',
      show_in_omni_search: true,
    },
  ];

  return (
    <JungleSelect
      items={assigneeData}
      groups={assigneeGroupData}
      renderGroup={(group, items) => getGroupItem(group, items)}
      renderItem={item => getCommandItem(item)}
      placeholder="Search..."
      searchable={true}
      searchableAttributes={['name']}
      listWrapper={list => <div className="command-list">{list}</div>}
      onChange={value => handleAssignSelect(value)}
      mode="list"
      selectFirstItem={true}
      autofocus={true}
    />
  );
};

export default AssignWidget;
