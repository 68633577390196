const actions = {
  FETCH_EVENT_CATEGORIES: 'FETCH_EVENT_CATEGORIES',
  FETCH_EVENT_CATEGORIES_SUCCESS: 'FETCH_EVENT_CATEGORIES_SUCCESS',
  FETCH_EVENT_CATEGORIES_FAILURE: 'FETCH_EVENT_CATEGORIES_FAILURE',
  FETCH_EVENT_DATA: 'FETCH_EVENT_DATA',
  FETCH_EVENT_DATA_SUCCESS: 'FETCH_EVENT_DATA_SUCCESS',
  FETCH_EVENT_DATA_FAILURE: 'FETCH_EVENT_DATA_FAILURE',
  FETCH_EVENT_EDIT_DATA: 'FETCH_EVENT_EDIT_DATA',
  FETCH_EVENT_EDIT_DATA_SUCCESS: 'FETCH_EVENT_EDIT_DATA_SUCCESS',
  FETCH_EVENT_EDIT_DATA_FAILURE: 'FETCH_EVENT_EDIT_DATA_FAILURE',
  FETCH_CATEGORY_DROPDOWN: 'FETCH_CATEGORY_DROPDOWN',
  FETCH_CATEGORY_DROPDOWN_SUCCESS: 'FETCH_CATEGORY_DROPDOWN_SUCCESS',
  FETCH_CATEGORY_DROPDOWN_FAILURE: 'FETCH_CATEGORY_DROPDOWN_FAILURE',
  EVENT_ID_CHANGE: 'EVENT_ID_CHANGE',
  ADD_EVENT: 'ADD_EVENT',
  ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
  ADD_EVENT_FAILURE: 'ADD_EVENT_FAILURE',
  EDIT_EVENT: 'EDIT_EVENT',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
  EDIT_EVENT_FAILURE: 'EDIT_EVENT_FAILURE',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',
  EVENT_DRAWER_CLOSE: 'EVENT_DRAWER_CLOSE',
  EVENT_DRAWER_OPEN: 'EVENT_DRAWER_OPEN',
  CLEAR_DRAWER_DATA: 'CLEAR_DRAWER_DATA',
  EVENT_CATEGORY_DRAWER: 'EVENT_CATEGORY_DRAWER',
  EDIT_CATEGORY_NAME: 'EDIT_CATEGORY_NAME',
  EDIT_CATEGORY_NAME_SUCCESS: 'EDIT_CATEGORY_NAME_SUCCESS',
  EDIT_CATEGORY_NAME_FAILURE: 'EDIT_CATEGORY_NAME_FAILURE',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',
  EVENT_VISUALISER_DRAWER: 'EVENT_VISUALISER_DRAWER',
  FETCH_EVENT_VISUALISER: 'FETCH_EVENT_VISUALISER',
  FETCH_EVENT_VISUALISER_SUCCESS: 'FETCH_EVENT_VISUALISER_SUCCESS',
  FETCH_EVENT_VISUALISER_FAILURE: 'FETCH_EVENT_VISUALISER_FAILURE',
  SET_RECENT_URL: 'SET_RECENT_URL',
  ADD_LAUNCH_URL: 'ADD_LAUNCH_URL',
  ADD_LAUNCH_URL_SUCCESS: 'ADD_LAUNCH_URL_SUCCESS',
  ADD_LAUNCH_URL_FAILURE: 'ADD_LAUNCH_URL_FAILURE',
  SET_EVENTS_SEARCH: 'SET_EVENTS_SEARCH',
  FETCH_SEARCHED_EVENT: 'FETCH_SEARCHED_EVENT',
  FETCH_SEARCHED_EVENT_SUCCESS: 'FETCH_SEARCHED_EVENT_SUCCESS',
  FETCH_SEARCHED_EVENT_FAILURE: 'FETCH_SEARCHED_EVENT_FAILURE',
  CHANGE_CURRENT_STEP: 'CHANGE_CURRENT_STEP',
  CHANGE_MENU_ID: 'CHANGE_MENU_ID',
  FETCH_CUSTOM_EVENTS_DROPDOWN: 'FETCH_CUSTOM_EVENTS_DROPDOWN',
  FETCH_CUSTOM_EVENTS_DROPDOWN_SUCCESS: 'FETCH_CUSTOM_EVENTS_DROPDOWN_SUCCESS',
  FETCH_CUSTOM_EVENTS_DROPDOWN_FAILURE: 'FETCH_CUSTOM_EVENTS_DROPDOWN_FAILURE',
  FETCH_CUSTOM_FILTER_LIST: 'FETCH_CUSTOM_FILTER_LIST',
  FETCH_CUSTOM_FILTER_LIST_SUCCESS: 'FETCH_CUSTOM_FILTER_LIST_SUCCESS',
  FETCH_CUSTOM_FILTER_LIST_FAILURE: 'FETCH_CUSTOM_FILTER_LIST_FAILURE',
};

export default actions;
