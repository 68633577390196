import actions from './actions';
import _ from 'lodash';
import {
  conditionsMasterList,
  conditionsDataTypes,
  conversationStatusMasterList,
  conversationChannelMasterList,
  conversationRatingMasterList,
  conversationBusinessHoursMasterList,
  conversationDayOfWeekMasterList,
  defaultRuleData,
} from '../../components/InboxAutomation/constants';
import {
  addHyphenToTeamsTeammates,
  removeUnderscore,
} from '../../helpers/utility';

let initState = {
  currentRuleId: null,
  ruleData: defaultRuleData.assignment_rule,
  inboxRulesList: [],
  contactSegmentsData: [],
  contactTagsData: [],
  chatTagsData: [],
  teamMates: [],
  teams: [],
  displayRulesdisplayRulesPropertiesMetaData: {},
  allKeysOfDisplayRules: [],
  allPropertiesListOfInboxAutomationConditions: [],
  propertiesMetaData: {},
  contactPropertyData: {
    data: [],
    metaData: null,
    userProperties: [],
    nonEditableKeys: [],
    allProperties: [],
  },
  ruleFilters: {
    status: 'all',
    trigger_name: 'all',
    action_name: 'all',
  },
  ruleLoader: false,
  rulesListLoader: false,
  buttonLoader: false,
  switchLoader: false,
  deletedTriggerIds: [],
  deletedActionIds: [],
  initialRuleData: defaultRuleData.assignment_rule,
};

function constructProperties(value) {
  return {
    key: removeUnderscore(value.property_name),
    value: value.property_name,
  };
}

function getStaticValue(key) {
  if (key === 'conversation_channel') {
    return conversationChannelMasterList;
  } else if (key === 'conversation_status') {
    return conversationStatusMasterList;
  } else if (key === 'conversation_rating') {
    return conversationRatingMasterList;
  } else if (key === 'business_hours') {
    return conversationBusinessHoursMasterList;
  } else if (key === 'day_of_week') {
    return conversationDayOfWeekMasterList;
  } else {
    return [];
  }
}

export default function inboxAutomationReducer(state = initState, action) {
  let updatedRuleData = {};

  switch (action.type) {
    case actions.FETCH_INBOX_RULES_LIST_SUCCESS: {
      return {
        ...state,
        inboxRulesList: action.inboxRulesList,
        rulesListLoader: false,
      };
    }
    case actions.UPDATE_RULES_LIST_ORDER_SUCCESS: {
      return {
        ...state,
        inboxRulesList: {
          ...state.inboxRulesList,
          assignment_rules: action.assignmentRules,
        },
      };
    }
    case actions.UPDATE_RULE_LIST_STATUS: {
      const { id, status } = action.payload,
        ruleList = _.cloneDeep(state.inboxRulesList.assignment_rules),
        ruleIndex = _.findIndex(ruleList, { id: id });
      if (ruleIndex !== -1) {
        ruleList[ruleIndex].status = status;
      }
      return {
        ...state,
        inboxRulesList: { ...state.inboxRulesList, assignment_rules: ruleList },
        switchLoader: false,
      };
    }
    case actions.CREATE_INBOX_RULE_SUCCESS: {
      return {
        ...state,
        ruleData: action.ruleData,
        initialRuleData: { ...action.ruleData },
        buttonLoader: false,
        deletedTriggerIds: [],
        deletedActionIds: [],
      };
    }
    case actions.CHANGE_RULE_LOADER_STATUS: {
      return {
        ...state,
        ruleLoader: action.status,
      };
    }
    case actions.CHANGE_RULES_LIST_LOADER_STATUS: {
      return {
        ...state,
        rulesListLoader: action.status,
      };
    }
    case actions.CHANGE_SWITCH_LOADER_STATUS: {
      return {
        ...state,
        switchLoader: action.status,
      };
    }
    case actions.CHANGE_BUTTON_LOADER_STATUS: {
      return {
        ...state,
        buttonLoader: action.status,
      };
    }
    case actions.DELETE_INBOX_RULE_SUCCESS: {
      return {
        ...state,
        inboxRulesList: {
          ...state.inboxRulesList,
          assignment_rules: state.inboxRulesList.assignment_rules.filter(
            rule => rule.id !== action.ruleId,
          ),
          assignment_rules_count: action.rulesCount,
        },
      };
    }
    case actions.UPDATE_RULE_LIST_FILTER: {
      return {
        ...state,
        ruleFilters: action.payload,
      };
    }
    case actions.UPDATE_DEFAULT_ASSIGNEE_SUCCESS:
      return {
        ...state,
        inboxRulesList: {
          ...state.inboxRulesList,
          low_priority: action.payload,
        },
      };
    case actions.FETCH_SINGLE_RULE_DATA_SUCCESS: {
      updatedRuleData = action.ruleData;
      let conditionData = updatedRuleData.conditions;

      if (conditionData.length) {
        conditionData.forEach((data, index) => {
          data.criteria.forEach((t, i) => {
            state.allPropertiesListOfInboxAutomationConditions.forEach(
              (property, no) => {
                let filterProperty = conditionData[index].criteria[i];
                if (property.value === filterProperty.key) {
                  filterProperty.selectedType = property.property_type;
                  filterProperty.conditions =
                    state.displayRulesPropertiesMetaData[
                      property.property_type
                    ];
                  filterProperty.unit = property.unit;
                  if (
                    [
                      'conversation_channel',
                      'conversation_status',
                      'conversation_rating',
                      'business_hours',
                      'day_of_week',
                    ].includes(filterProperty.key)
                  ) {
                    filterProperty.staticValues = getStaticValue(
                      filterProperty.key,
                    );
                  }
                }
              },
            );
          });
        });
      }

      updatedRuleData.conditions = conditionData;

      return {
        ...state,
        ruleData: updatedRuleData,
        initialRuleData: _.cloneDeep(updatedRuleData),
        ruleLoader: false,
      };
    }
    case actions.UPDATE_RULE_NAME: {
      return {
        ...state,
        ruleData: { ...state.ruleData, rule_name: action.rule_name },
      };
    }
    case actions.UPDATE_RULE_STOP_PROCESSING_OPTION: {
      return {
        ...state,
        ruleData: {
          ...state.ruleData,
          stop_processing_other_rules: action.payload,
        },
      };
    }
    case actions.UPDATE_RULE_STATUS_SUCCESS: {
      return {
        ...state,
        ruleData: { ...state.ruleData, status: action.status },
        switchLoader: false,
      };
    }
    case actions.ADD_TRIGGER_ITEM: {
      return {
        ...state,
        ruleData: {
          ...state.ruleData,
          assignment_rule_triggers_attributes: [
            ...state.ruleData.assignment_rule_triggers_attributes,
            { trigger_name: '', trigger_data: {} },
          ],
        },
      };
    }
    case actions.DELETE_TRIGGER_ITEM: {
      updatedRuleData = _.cloneDeep(state.ruleData);

      if (action.index > -1) {
        updatedRuleData.assignment_rule_triggers_attributes.splice(
          action.index,
          1,
        );
      }

      return {
        ...state,
        ruleData: updatedRuleData,
        deletedTriggerIds: [
          ...state.deletedTriggerIds,
          { id: action.payload.id, _destroy: true },
        ],
      };
    }
    case actions.UPDATE_TRIGGER_DATA: {
      return {
        ...state,
        ruleData: action.ruleData,
      };
    }
    case actions.ADD_ACTION_ITEM: {
      return {
        ...state,
        ruleData: {
          ...state.ruleData,
          assignment_rule_actions_attributes: [
            ...state.ruleData.assignment_rule_actions_attributes,
            {},
          ],
        },
      };
    }
    case actions.DELETE_ACTION_ITEM: {
      updatedRuleData = _.cloneDeep(state.ruleData);

      if (action.index > -1) {
        updatedRuleData.assignment_rule_actions_attributes.splice(
          action.index,
          1,
        );
      }

      return {
        ...state,
        ruleData: updatedRuleData,
        deletedActionIds: [
          ...state.deletedActionIds,
          { id: action.payload.id, _destroy: true },
        ],
      };
    }
    case actions.UPDATE_RULE_ACTION_DATA: {
      return {
        ...state,
        ruleData: action.ruleData,
      };
    }
    case actions.ADD_CONDITION_BLOCK: {
      let conditionBlock = {
        criteria: [
          {
            value: '',
            key: state.allKeysOfDisplayRules[0].properties[0].value,
            condition:
              state.displayRulesPropertiesMetaData[
                state.allKeysOfDisplayRules[0].properties[0].property_type
              ][0].value,
            selectedType:
              state.allKeysOfDisplayRules[0].properties[0].property_type,
            conditions:
              state.displayRulesPropertiesMetaData[
                state.allKeysOfDisplayRules[0].properties[0].property_type
              ],
            unit: state.allKeysOfDisplayRules[0].properties[0].unit,
          },
        ],
      };

      if (
        [
          'conversation_channel',
          'conversation_status',
          'conversation_rating',
          'business_hours',
          'day_of_week',
        ].includes(conditionBlock.criteria[0].key)
      ) {
        conditionBlock.criteria[0].staticValues = getStaticValue(
          conditionBlock.criteria[0].key,
        );
        if (conditionBlock.criteria[0].value === '')
          conditionBlock.criteria[0].value = [];
      }

      return {
        ...state,
        ruleData: {
          ...state.ruleData,
          conditions: [...state.ruleData.conditions, conditionBlock],
        },
      };
    }
    case actions.ADD_CONDITION_ITEM: {
      let segment = state.allKeysOfDisplayRules[0].properties[0];
      let conditionItem = {
        key: segment.value,
        condition:
          state.displayRulesPropertiesMetaData[segment.property_type][0].value,
        value: '',
        selectedType: segment.property_type,
        conditions: state.displayRulesPropertiesMetaData[segment.property_type],
        unit: segment.unit,
      };

      if (
        [
          'conversation_channel',
          'conversation_status',
          'conversation_rating',
          'business_hours',
          'day_of_week',
        ].includes(conditionItem.key)
      ) {
        conditionItem.staticValues = getStaticValue(conditionItem.key);
        if (conditionItem.value === '') conditionItem.value = [];
      }

      updatedRuleData = _.cloneDeep(state.ruleData);
      let index = action.payload.index;

      updatedRuleData.conditions[index].criteria.push(conditionItem);

      return {
        ...state,
        ruleData: updatedRuleData,
      };
    }
    case actions.DELETE_CONDITION_ITEM: {
      const { externalIndex, index } = action.payload;

      updatedRuleData = _.cloneDeep(state.ruleData);

      if (index > -1) {
        updatedRuleData.conditions[externalIndex].criteria.splice(index, 1);
      }

      if (updatedRuleData.conditions[externalIndex].criteria.length === 0) {
        updatedRuleData.conditions.splice(externalIndex, 1);
      }

      return {
        ...state,
        ruleData: updatedRuleData,
      };
    }
    case actions.UPDATE_RULE_CONDITION_DATA: {
      updatedRuleData = _.cloneDeep(state.ruleData);
      let conditionsData = _.cloneDeep(state.ruleData.conditions);

      let { externalIndex, index, key, value, condition } = action.payload;

      let getProperty = _.find(
        state.allPropertiesListOfInboxAutomationConditions,
        function(property) {
          return (
            property.value ===
            (key ? key : conditionsData[externalIndex].criteria[index].key)
          );
        },
      );

      // Primary condition data
      if (key) {
        conditionsData[externalIndex].criteria[index] = {
          key: key,
          condition:
            state.displayRulesPropertiesMetaData[getProperty.property_type][0]
              .value,
          value: '',
          unit: getProperty.unit,
          selectedType: getProperty.property_type,
          conditions:
            state.displayRulesPropertiesMetaData[getProperty.property_type],
        };

        if (
          key === 'time_between_hours' &&
          _.isEmpty(conditionsData[externalIndex].criteria[index].value)
        ) {
          conditionsData[externalIndex].criteria[index].value = {
            from: '09:00',
            to: '17:00',
          };
        }
      }

      // Secondary condition data
      if (condition || condition === false) {
        if (['has_any_value', 'has_no_value'].includes(condition)) {
          conditionsData[externalIndex].criteria[index] = {
            key: conditionsData[externalIndex].criteria[index].key,
            value: conditionsData[externalIndex].criteria[index].value,
            condition: condition,
            unit: getProperty.unit,
            selectedType: getProperty.property_type,
            conditions:
              state.displayRulesPropertiesMetaData[getProperty.property_type],
          };
        } else if (
          getProperty &&
          getProperty.length !== 0 &&
          getProperty.property_type === 'boolean'
        ) {
          conditionsData[externalIndex].criteria[index] = {
            key: conditionsData[externalIndex].criteria[index].key,
            value: condition,
            condition: 'equals',
            unit: getProperty.unit,
            selectedType: getProperty.property_type,
            conditions:
              state.displayRulesPropertiesMetaData[getProperty.property_type],
          };
        } else {
          conditionsData[externalIndex].criteria[index].condition = condition;
        }
      }

      if (![undefined, null].includes(value)) {
        conditionsData[externalIndex].criteria[index].value = value;
      }

      if (
        [
          'conversation_channel',
          'conversation_status',
          'conversation_rating',
          'business_hours',
          'day_of_week',
        ].includes(conditionsData[externalIndex].criteria[index].key)
      ) {
        conditionsData[externalIndex].criteria[
          index
        ].staticValues = getStaticValue(getProperty.value);
        if (conditionsData[externalIndex].criteria[index].value === '')
          conditionsData[externalIndex].criteria[index].value = [];
      }

      updatedRuleData.conditions = conditionsData;

      return {
        ...state,
        ruleData: updatedRuleData,
      };
    }
    case actions.SET_RULE_CONDITION_INITIAL_DATA: {
      let properties = [];
      for (let category of conditionsMasterList) {
        properties = _.concat(properties, category.properties);
      }

      return {
        ...state,
        allKeysOfDisplayRules: conditionsMasterList,
        displayRulesPropertiesMetaData: conditionsDataTypes,
        allPropertiesListOfInboxAutomationConditions: properties,
      };
    }
    case actions.FETCH_ACTION_CHAT_TAGS_SUCCESS:
      return {
        ...state,
        chatTagsData: action.chatTagsData,
      };
    case actions.FETCH_ACTION_ASSIGNEE_LIST_SUCCESS:
      let { teams, teamMates } = addHyphenToTeamsTeammates(action.payload);

      return {
        ...state,
        teamMates: teamMates,
        teams: teams,
      };
    case actions.FETCH_CONTACT_TAGS_LIST_SUCCESS:
      return {
        ...state,
        contactTagsData: action.payload.data,
      };
    case actions.FETCH_CONDITION_CONTACT_SEGMENTS_LIST_SUCCESS:
      return {
        ...state,
        contactSegmentsData: action.payload.data,
      };
    case actions.FETCH_RULE_CONDITION_PROPERTIES_SUCCESS:
      let customProperties = _.map(action.payload.custom_properties, value =>
        constructProperties(value),
      );
      let allProperties = [];
      for (let category of action.payload.user_properties) {
        allProperties = _.concat(allProperties, category.properties);
      }
      allProperties = _.concat(allProperties, customProperties);
      return {
        ...state,
        contactPropertyData: {
          ...state.property,
          data: [...action.payload.custom_properties],
          userProperties: [...action.payload.user_properties],
          nonEditableKeys: [...action.payload.non_editable_properties],
          allProperties: allProperties,
        },
      };
    case actions.FETCH_RULE_CONDITION_PROPERTIES_META_DATA_SUCCESS:
      return {
        ...state,
        propertiesMetaData: action.payload.data,
      };
    case actions.EMPTY_RULE_DATA:
      return {
        ...state,
        ruleData: defaultRuleData.assignment_rule,
        initialRuleData: defaultRuleData.assignment_rule,
      };
    case actions.APPEND_RULE_CONTACT_TAGS:
      const newContactTag = [
        {
          id: action.payload.id,
          display_name: action.payload.tags,
        },
      ];
      return {
        ...state,
        contactTagsData: [...newContactTag, ...state.contactTagsData],
      };
    case actions.APPEND_RULE_CHAT_TAGS:
      const newChatTag = [
        {
          id: action.payload.id,
          tag_name: action.payload.tag_name,
        },
      ];
      return {
        ...state,
        chatTagsData: [...newChatTag, ...state.chatTagsData],
      };
    default:
      return state;
  }
}
