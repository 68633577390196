import actions from './actions';

const initState = {
  templateList: [],
  templateDetails: {
    id: null,
    template_name: null,
    form_type: null,
    html_content: null,
    css_content: null,
    components: [],
    styles: [],
    description: 'description',
    image_url: null,
  },
  formTypes: [],
  selectedFormType: 'popup',
  drawerVisible: false,
};

export default function templateReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templateList: action.payload,
      };
    case actions.SET_TEMPLATE_DETAILS:
      return {
        ...state,
        templateDetails: action.payload,
      };
    case actions.UPDATE_FORM_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateDetails: action.payload,
      };
    case actions.CREATE_FORM_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateDetails: action.payload,
      };
    case actions.GET_FORM_TYPES_SUCCESS:
      return {
        ...state,
        formTypes: action.payload,
      };
    case actions.SET_FORM_TYPE:
      return {
        ...state,
        selectedFormType: action.payload,
      };
    case actions.FORM_TEMPLATE_DRAWER_VISIBLE:
      return {
        ...state,
        drawerVisible: action.payload,
      };
    case actions.EMPTY_TEMPLATE_DETAILS:
      return {
        ...state,
        templateDetails: {
          id: null,
          template_name: null,
          form_type: null,
          html_content: null,
          css_content: null,
          components: [],
          styles: [],
          description: 'description',
          image_url: null,
        },
      };
    default:
      return state;
  }
}
