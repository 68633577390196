import React, { Component } from 'react';
import * as moment from 'moment';
import TimeAgo from 'react-timeago';
import Moment from 'react-moment';
import { Tooltip } from 'antd';

class CustomTimeAgo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reRender: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ reRender: true });
    }, 1000 * 60);
  }

  render() {
    const { data } = this.props,
      timeDifferance = moment().diff(moment.unix(data)),
      info =
        timeDifferance / 1000 <= 0 || timeDifferance / 1000 >= 59 ? (
          <TimeAgo date={moment.unix(data)} title={''} />
        ) : (
          'Just now'
        );

    return (
      <Tooltip
        title={
          <Moment date={moment.unix(data)} format={'MMM Do YYYY, h:mm:ss A'} />
        }
        placement={'bottom'}
      >
        {info}
      </Tooltip>
    );
  }
}

export default CustomTimeAgo;
