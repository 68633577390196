import { all, takeEvery, put, call } from 'redux-saga/effects';
import settings from '../../settings/index';
import actions from './actions';
import { message } from 'antd';
import {
  fetchProjectSecretKey,
  getPromise,
  postPromise,
  unAuthorisedRedirection,
} from '../../helpers/utility';

export function* fetchVisitors(params) {
  try {
    const { payload } = params,
      { searchText } = payload.data;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/live_people.json?search_text=${searchText}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_VISITORS_SUCCESS,
      payload: data,
      psk: fetchProjectSecretKey(),
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Visitors fetch failed');
    yield put({ type: actions.FETCH_VISITORS_FAILURE });
  }
}

export function* appendVisitors(params) {
  try {
    const { payload } = params,
      { lastVisitorId, searchText } = payload.data;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/live_people.json?last_person_id=${lastVisitorId}&search_text=${searchText}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_VISITORS_SUCCESS,
      payload: data,
      psk: fetchProjectSecretKey(),
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Visitors fetch failed');
    yield put({ type: actions.APPEND_VISITORS_FAILURE });
  }
}

export function* sendMessage(params) {
  try {
    const { payload } = params;
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/conversations.json`;
    yield call(() => postPromise(url, payload.data));
    yield put({ type: actions.SEND_MESSAGE_SUCCESS });
    message.success('Your message has been sent successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Message send failed');
    yield put({ type: actions.SEND_MESSAGE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_VISITORS, fetchVisitors),
    takeEvery(actions.APPEND_VISITORS, appendVisitors),
    takeEvery(actions.SEND_MESSAGE, sendMessage),
  ]);
}
