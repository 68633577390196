import React from 'react';
import TypingDetail from './TypingDetail';

const TypingBubble = props => {
  const { person, typingDetails, isFromAiAssist } = props;

  return (
    <div className={'typing-container'}>
      {typingDetails &&
        typingDetails.length &&
        typingDetails.map((details, index) => (
          <TypingDetail details={details} person={person} key={index} />
        ))}
      <div
        className={`typing-bubble ${
          isFromAiAssist ? 'aiassist-typing-dot' : ''
        }`}
      >
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
};

export default TypingBubble;
