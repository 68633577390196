import React from 'react';

function InboxStatusIndicator(props) {
  return (
    <span
      className={`active-status-dot active-status ${props.teamMate.status}`}
    />
  );
}

export default InboxStatusIndicator;
