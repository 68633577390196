import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
} from '../../helpers/utility';
import { message } from 'antd';
import emailActions from './autoEmails/actions';
import broadCastActions from './broadcast/actions';
import { AUTO_EMAIL_TYPE, BROADCAST_MAIL_TYPE } from '../constants';
import { store } from '../store';
import settingsActions from '../settings/actions';
import messagesActions from '../messages/actions';

export function* getPreviewEmailData(params) {
  try {
    yield put({
      type: actions.SET_PREVIEW_EMAIL_LOADER,
      payload: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/people_priority_list?query=${encodeURIComponent(
      params.payload,
    )}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_PREVIEW_USER_LIST_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SET_PREVIEW_EMAIL_LOADER,
      payload: false,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Preview list fetch failed');
    yield put({
      type: actions.GET_PREVIEW_USER_LIST_FAILURE,
      payload: '',
    });
    yield put({
      type: actions.SET_PREVIEW_EMAIL_LOADER,
      payload: false,
    });
  }
}
export function* updatePreviewEmailData(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/people/update_people_priority_list`;
    yield call(() => postPromise(url, { person_id: params.payload }));
  } catch (error) {
    unAuthorisedRedirection(error);
  }
}
export function* cancelSchedule(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/mail_filters/${
      params.id
    }/cancel_schedule`;
    const data = yield call(() => patchPromise(url, {}));
    message.success(data.message);
    yield put({
      type: actions.CANCEL_SCHEDULE_SUCCESS,
    });
    if (params.autoEmail) {
      yield put({
        type: emailActions.FETCH_AUTO_EMAIL,
        mailType: AUTO_EMAIL_TYPE,
        status: store.getState().AutoEmail.autoEmailStatus,
      });
      yield put({
        type: emailActions.AUTO_EMAIL_CANCEL_SCHEDULE_SUCCESS,
      });
    } else if (params.broadcast) {
      yield put({
        type: broadCastActions.FETCH_BROADCAST,
        mailType: BROADCAST_MAIL_TYPE,
        status: store.getState().BroadcastEmail.broadcastStatus,
      });
      yield put({
        type: broadCastActions.BROADCAST_CANCEL_SCHEDULE_SUCCESS,
      });
    } else {
      updateBrowserHistory('messages/email');
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CANCEL_SCHEDULE_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message.join());
    } else {
      message.error('Cancel a schedule failed');
    }
  }
}

export function* fetchEmailTags(param) {
  try {
    yield put({ type: settingsActions.SET_PAGE_LOADER, pageLoader: true });
    let search = '';
    if (param.emailTagSearch) {
      search = param.emailTagSearch.trim();
    } else if (param.requestFrom === 'settings') {
      search = store.getState().Email.emailTagSearch;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/feature_tags?search_text=${search}`;

    const params = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_TAG_LISTS_SUCCESS,
      data: params.data,
    });
    yield put({ type: settingsActions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('fetch email tags failed');
    }
    yield put({ type: settingsActions.SET_PAGE_LOADER, pageLoader: false });
  }
}

export function* createEmailTags(param) {
  try {
    yield put({
      type: actions.SET_EMAIL_LOADER,
      emailBtnLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/feature_tags`;
    const responseData = yield call(() => postPromise(url, param.data));
    message.success(responseData.message);
    yield put({
      type: actions.SET_EMAIL_LOADER,
      emailBtnLoader: false,
    });
    if (param.requestFrom === 'settings') {
      yield param.closeDrawer();
    } else if (param.requestFrom === 'messages') {
      yield put({
        type: messagesActions.SET_CREATED_MESSAGE_TAG,
        payload: responseData.data.feature_tag,
      });
    }
    yield put({
      type: actions.FETCH_EMAIL_TAG_LISTS,
      emailTagSearch:
        param.requestFrom === 'settings'
          ? store.getState().Email.emailTagSearch
          : responseData.data.feature_tag.name,
      requestFrom: param.requestFrom,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('create email tag failed');
    }
    yield put({
      type: actions.SET_EMAIL_LOADER,
      emailBtnLoader: false,
    });
  }
}

export function* updateEmailTags(param) {
  try {
    yield put({
      type: actions.SET_EMAIL_LOADER,
      emailBtnLoader: true,
    });
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/feature_tags/${
      param.emailTagId
    }`;
    const responseData = yield call(() => patchPromise(url, param.data));
    message.success(responseData.message);
    yield put({
      type: actions.FETCH_EMAIL_TAG_LISTS,
      requestFrom: 'settings',
    });
    yield put({
      type: actions.SET_EMAIL_LOADER,
      emailBtnLoader: false,
    });
    yield param.closeDrawer();
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Update email tag failed');
    }
    yield put({
      type: actions.SET_EMAIL_LOADER,
      emailBtnLoader: false,
    });
  }
}

export function* appendEmailTags(param) {
  try {
    yield put({ type: settingsActions.SET_PAGE_LOADER, pageLoader: true });
    let search = '',
      page = '';
    if (param.emailTagSearch) {
      search = param.emailTagSearch.trim();
    } else if (param.requestFrom === 'settings') {
      search = store.getState().Email.emailTagSearch;
    }
    if (param.page) {
      page = param.page;
    } else if (param.requestFrom === 'messages') {
      page = store.getState().MessagesReducer.messageTagsListPage;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/feature_tags?search_text=${search}&page=${page}`;
    const responseData = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_EMAIL_TAGS_SUCCESS,
      data: responseData.data,
    });
    if (!responseData.data.feature_tags.length) {
      yield put({
        type: messagesActions.SET_MESSAGE_TAGS_LIST_PAGINATION_VALUES,
        lastId: store.getState().MessagesReducer.messageTagsListLastId,
        page: Number(store.getState().MessagesReducer.messageTagsListPage) - 1,
      });
    }
    yield put({ type: settingsActions.SET_PAGE_LOADER, pageLoader: false });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('update email tags failed');
    }
    yield put({ type: settingsActions.SET_PAGE_LOADER, pageLoader: false });
  }
}

export function* removeEmailTags(param) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/remove_tag?feature_type=MailFilter&feature_id=${
      param.payload.id
    }&tag_id=${param.payload.tagId}`;
    const responseData = yield call(() => postPromise(url));
    if (param.payload.requestFrom === 'broadcast') {
      yield put({
        type: broadCastActions.FETCH_BROADCAST,
        mailType: BROADCAST_MAIL_TYPE,
        status: store.getState().BroadcastEmail.broadcastStatus,
      });
    } else if (param.payload.requestFrom === 'auto_mail') {
      yield put({
        type: emailActions.FETCH_AUTO_EMAIL,
        mailType: AUTO_EMAIL_TYPE,
        status: 'all',
      });
    }
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('remove email tags failed');
    }
  }
}

export function* applyEmailTags(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/apply_tag`;
    const responseData = yield call(() =>
      postPromise(url, params.payload.data),
    );
    if (params.payload.requestFrom === 'broadcast') {
      yield put({
        type: broadCastActions.FETCH_BROADCAST,
        mailType: BROADCAST_MAIL_TYPE,
        status: store.getState().BroadcastEmail.broadcastStatus,
      });
    } else if (params.payload.requestFrom === 'auto_mail') {
      yield put({
        type: emailActions.FETCH_AUTO_EMAIL,
        mailType: AUTO_EMAIL_TYPE,
        status: 'all',
      });
    }
    if (params.payload.isFromMessages) {
      yield put({
        type: messagesActions.SET_CREATED_MESSAGE_TAG,
        payload: {
          id: '',
        },
      });
    }
    message.success(responseData.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Email tags apply failed');
    }
  }
}

export function* deleteEmailTags(param) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/settings/feature_tags/${
      param.emailTagId
    }`;
    const data = yield call(() => deletePromise(url));
    message.success(data.message);
    yield put({
      type: actions.FETCH_EMAIL_TAG_LISTS,
      requestFrom: 'settings',
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Email tag delete failed');
    }
  }
}

export function* FetchTeamsUsersList() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?include_current_user=true`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TEAMS_USERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch assignee failed');
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PREVIEW_USER_LIST, getPreviewEmailData)]);
  yield all([
    takeEvery(actions.UPDATE_PREVIEW_USER_LIST, updatePreviewEmailData),
  ]);
  yield all([
    takeEvery(actions.CANCEL_SCHEDULE, cancelSchedule),
    takeEvery(actions.FETCH_EMAIL_TAG_LISTS, fetchEmailTags),
    takeEvery(actions.UPDATE_EMAIL_TAG, updateEmailTags),
    takeEvery(actions.CREATE_EMAIL_TAG, createEmailTags),
    takeEvery(actions.DELETE_EMAIL_TAG, deleteEmailTags),
    takeEvery(actions.APPEND_EMAIL_TAGS, appendEmailTags),
    takeEvery(actions.APPLY_EMAIL_TAG, applyEmailTags),
    takeEvery(actions.REMOVE_EMAIL_TAG, removeEmailTags),
    takeEvery(actions.FETCH_TEAMS_USERS_LIST, FetchTeamsUsersList),
  ]);
}
