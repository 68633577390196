import { BaseNodeComponent } from 'jsplumbtoolkit-react';
import { setDrawerVisible } from '../../../../containers/Messages/Bots/helper';
import {store} from '../../../../redux/store';
import actions from '../../../../redux/assistants/actions';

/**
 * The superclass for node types that wish to support label edit and removal - nodes.
 */
export class BaseEditableComponent extends BaseNodeComponent {
  remove() {
    let node = this.getNode(); // Get the current node
    if (node) {
      this.toolkit.removeNode(node); // Remove the node
      store.dispatch({
        type: actions.UPDATE_BOT_TIME_STAMP,
      });
    }
  }

  edit() {
    let node = this.getNode();
    if (node) {
      setDrawerVisible(node);
    }
  }
}
