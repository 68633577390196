import React from 'react';
import { Popover, Tag, Tooltip } from 'antd';

export function getStatusTags(languages) {
  const length = languages.length;
  const getStatus = (status) => (status ?? status ? 'success' : 'warning');
  const getTags = (lan, showCode) => {
    const { language_code, language, publish_status } = lan;
    if (showCode) {
      return (
        <Tooltip title={language}>
          <Tag key={language_code} className={getStatus(publish_status)}>
            {language_code}
          </Tag>
        </Tooltip>
      );
    }
    return (
      <Tag key={language_code} className={getStatus(publish_status)}>
        {language}
      </Tag>
    );
  };
  return (
    <span className={'tag'}>
      {length <= 2 ? (
        languages.map((lan) => getTags(lan, true))
      ) : (
        <Popover
          overlayClassName={'collection-item-popover'}
          content={languages.slice(2).map((lan) => getTags(lan))}
          trigger={'click'}
        >
          {languages.slice(0, 2).map((lan) => getTags(lan, true))}
          <span className="text-muted cursor-pointer">+{length - 2}</span>
        </Popover>
      )}
    </span>
  );
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}
