import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import chatActions from '../../../redux/chat/actions';
import sharedActions from '../../../redux/sharedReducers/actions';
import JungleSelect from 'react-jungle-select';

const TagConversationWidget = props => {
  const dispatch = useDispatch();
  const tags = useSelector(({ Chat }) => Chat.chatTagsData);
  const {
    message,
    omniSearchEventListener,
    removeOmniSearchListener,
    activeConversation,
  } = props;
  let tag_ids = (message && message.tags?.map(tag => tag.id)) || [];

  useEffect(() => {
    omniSearchEventListener();

    return () => {
      removeOmniSearchListener();
    };
  }, []);

  const handleSelect = tag => {
    dispatch({
      type: chatActions.ADD_MESSAGE_TAG,
      tags: [tag.tag_name],
      messageId: message.id,
      cid: activeConversation.conversation_identifier,
    });

    dispatch({
      type: sharedActions.SHOW_HIDE_OMNISEARCH,
      payload: false,
    });
  };

  const getCommandItem = item => (
    <div className="command-item">
      <div className="command-item-inner">
        <span className="command-content">
          <span className="command-title">
            <i className="fal fa-tag" /> {item.tag_name}
          </span>
        </span>
      </div>
    </div>
  );

  return (
    <JungleSelect
      items={tags.filter(tag => !tag_ids.includes(tag.id))}
      renderItem={item => getCommandItem(item)}
      placeholder="Tag message..."
      searchable={true}
      searchableAttributes={['tag_name']}
      listWrapper={list => <div className="command-list">{list}</div>}
      onChange={value => handleSelect(value)}
      mode="list"
      selectFirstItem={true}
      autofocus={true}
    />
  );
};
export default TagConversationWidget;
