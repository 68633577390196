import actions from './actions';

export const initState = {
  messageModalVisibility: false,
  currentPersonId: null,
  sendMessageLoading: false,
  searchText: '',
  messageDetails: {},
};

export default function ProactiveMessageReducer(state = initState, action) {
  switch (action.type) {
    case actions.PROACTIVE_OPEN_MESSAGE_MODAL:
      return {
        ...state,
        messageModalVisibility: true,
        currentPersonId: action.payload.personId,
      };
    case actions.PROACTIVE_CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        messageModalVisibility: false,
        currentPersonId: null,
      };
    case actions.PROACTIVE_SEND_MESSAGE:
      return {
        ...state,
        sendMessageLoading: true,
      };
    case actions.PROACTIVE_MESSAGE_DETAILS:
      return {
        ...state,
        messageDetails: action.payload.data,
      };

    case actions.PROACTIVE_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageModalVisibility: false,
        sendMessageLoading: false,
      };
    case actions.PROACTIVE_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        messageModalVisibility: false,
        sendMessageLoading: false,
      };
    case actions.UPDATE_PROACTIVE_MESSAGE_DETAILS:
      let message_content = '',
        triggered_user_id = '',
        assign_to = '',
        message_template = '',
        reply_type = '',
        reaction_set = '';
      if (action.message_content) {
        message_content = action.message_content;
      }
      if (action.triggered_user_id) {
        triggered_user_id = action.triggered_user_id;
      }
      if (action.assign_to || action.assign_to === '0') {
        assign_to = action.assign_to;
      }
      if (action.message_template) {
        message_template = action.message_template;
      }
      if (action.reply_type) {
        reply_type = action.reply_type;
      }
      if (action.reaction_set) {
        reaction_set = action.reaction_set;
      }
      return {
        ...state,
        messageDetails: {
          ...state.messageDetails,
          message_content: message_content
            ? message_content
            : state.messageDetails.message_content,
          triggered_user_id: triggered_user_id
            ? triggered_user_id
            : state.messageDetails.triggered_user_id,
          assign_to: assign_to ? assign_to : state.messageDetails.assign_to,
          message_template: message_template
            ? message_template
            : state.messageDetails.message_template,
          reply_type: reply_type ? reply_type : state.messageDetails.reply_type,
          reaction_set: reaction_set
            ? reaction_set
            : state.messageDetails.reaction_set,
          from_contact_owner: action.from_contact_owner
            ? action.from_contact_owner
            : state.messageDetails.from_contact_owner,
          assign_to_contact_owner: action.assign_to_contact_owner
            ? action.assign_to_contact_owner
            : state.messageDetails.assign_to_contact_owner,
        },
      };
    case actions.CLEAR_PROACTIVE_MESSAGE_DETAILS:
      return {
        ...state,
        messageDetails: {},
      };
    default:
      return state;
  }
}
