import { store } from '../../../../redux/store';
import {
  DefaultCloseTourActionData,
  DefaultEvalJSActionData,
  DefaultGoToStepActionData,
  DefaultNavigateActionData,
  TourActionTypes,
} from '../constants';
import { isValidUrl } from '../../../KnowledgeBase/helpers/utility';

export function getTableValues() {
  const { tableState } = store.getState().SharedReducers;
  let detailObj = {
    tableState,
  };
  return {
    ...detailObj,
    sortKey: store.getState().ToursReducer.sortKey,
    sortOrder: store.getState().ToursReducer.sortOrder,
    status: store.getState().ToursReducer.tourOnGoingStatus,
    searchValue: store.getState().ToursReducer.searchValue,
    tagIds: store.getState().ToursReducer.tourOnGoingTagId,
    tourType: 'on_going',
  };
}

export const getActionType = actionType => {
  switch (actionType) {
    case TourActionTypes.GO_TO_STEP.key:
      return DefaultGoToStepActionData;
    case TourActionTypes.CLOSE_TOUR.key:
      return DefaultCloseTourActionData;
    // case TourActionTypes.START_TOUR.key:
    //   return DefaultStartTourActionData;
    case TourActionTypes.NAVIGATE.key:
      return DefaultNavigateActionData;
    case TourActionTypes.EVAL_JS.key:
      return DefaultEvalJSActionData;
    default:
      return;
  }
};

export const getAllKeys = allRules => {
  const getCategory = allRules.filter(rule =>
    ['website', 'segment', 'visitor_behaviour'].includes(rule.category_name),
  );
  return getCategory.map(category => {
    category.properties = category.properties.filter(property =>
      [
        'current_url',
        'current_url_path',
        'segments',
        'tags',
        'visitors_device',
        'current_time',
      ].includes(property.value),
    );
    if (category.category_name === 'visitor_behaviour') {
      category.properties.push(
        ...[
          {
            property_name: 'Element',
            value: 'element',
            property_type: 'element',
            unit: null,
          },
          {
            property_name: 'Text input value',
            value: 'text_input',
            property_type: 'text_input',
            unit: null,
          },
        ],
      );
    }
    return category;
  });
};

export const updateActions = (actionType, deletedStepId) =>
  actionType.map(type => {
    const actionIdx = type.actions.findIndex(
      action =>
        action.type === 'GO_TO_STEP' && action.step_id === deletedStepId,
    );
    if (actionIdx !== -1) {
      type.actions.splice(actionIdx);
    }
    return type;
  });

export const checkActions = actions => {
  for (let action of actions) {
    if (!action.type) {
      return true;
    } else {
      if (action.type === 'GO_TO_STEP' && !action.step_id) {
        return true;
      } else if (action.type === 'NAVIGATE' && !isValidUrl(action.url)) {
        return true;
      }
    }
  }
  return false;
};