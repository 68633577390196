const actions = {
  CAMPAIGN_FETCH_TEMPLATES: 'CAMPAIGN_FETCH_TEMPLATES',
  OPEN_CREATE_CAMPAIGN_DRAWER: 'OPEN_CREATE_CAMPAIGN_DRAWER',
  CAMPAIGN_FETCH_TEMPLATES_SUCCESS: 'CAMPAIGN_FETCH_TEMPLATES_SUCCESS',
  CAMPAIGN_FETCH_CAMPAIGN_SUCCESS: 'CAMPAIGN_FETCH_CAMPAIGN_SUCCESS',
  CAMPAIGN_FETCH_CAMPAIGNS: 'CAMPAIGN_FETCH_CAMPAIGNS',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  SET_CAMPAIGN_ID: 'SET_CAMPAIGN_ID',
  FETCH_CAMPAIGN_EMAILS: 'FETCH_CAMPAIGN_EMAILS',
  FETCH_CAMPAIGN_EMAILS_SUCCESS: 'FETCH_CAMPAIGN_EMAILS_SUCCESS',
  UPDATE_CAMPAIGN_FILTERS: 'UPDATE_CAMPAIGN_FILTERS',
  UPDATE_CAMPAIGN_EMAIL_FILTERS: 'UPDATE_CAMPAIGN_EMAIL_FILTERS',
  FETCH_CAMPAIGN_DETAILS: 'FETCH_CAMPAIGN_DETAILS',
  FETCH_CAMPAIGN_DETAILS_SUCCESS: 'FETCH_FETCH_CAMPAIGN_DETAILS_SUCCESS',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_EMAIL: 'DELETE_CAMPAIGN_EMAIL',
  SET_SELECTED_TEMPLATE_ID: 'SET_SELECTED_TEMPLATE_ID',
  CREATE_CAMPAIGN_EMAIL: 'CREATE_CAMPAIGN_EMAIL',
  SET_CAMPAIGN_EMAIL_ID: 'SET_CAMPAIGN_EMAIL_ID',
  FETCH_CAMPAIGN_EMAIL_DETAILS: 'FETCH_CAMPAIGN_EMAIL_DETAILS',
  FETCH_CAMPAIGN_EMAIL_DETAILS_SUCCESS: 'FETCH_CAMPAIGN_EMAIL_DETAILS_SUCCESS',
  CREATE_CAMPAIGN_COMPOSER: 'CREATE_CAMPAIGN_COMPOSER',
  CAMPAIGN_SET_FROM_EMAIL_USER_ID: 'CAMPAIGN_SET_FROM_EMAIL_USER_ID',
  CAMPAIGN_SET_CAMPAIGN_EMAIL_NAME: 'CAMPAIGN_SET_CAMPAIGN_EMAIL_NAME',
  CAMPAIGN_SET_TO_EMAIL_USER_ID: 'CAMPAIGN_SET_TO_EMAIL_USER_ID',
  CAMPAIGN_SET_CAMPAIGN_EMAIL_SUBJECT: 'CAMPAIGN_SET_CAMPAIGN_EMAIL_SUBJECT',
  CAMPAIGN_SET_PRE_HEADER_TEXT: 'CAMPAIGN_SET_PRE_HEADER_TEXT',
  TEST_CAMPAIGN_EMAIL_MODAL_VISIBLE: 'TEST_CAMPAIGN_EMAIL_MODAL_VISIBLE',
  SEND_TEST_EMAIL: 'SEND_TEST_EMAIL',
  SET_CAMPAIGN_EMAIL_TEXT: 'SET_CAMPAIGN_EMAIL_TEXT',
  SET_CAMPAIGN_LAST_SAVE_TIME: 'SET_CAMPAIGN_LAST_SAVE_TIME',
  SEND_CAMPAIGN_TEST_EMAIL_SUCCESS: 'SEND_CAMPAIGN_TEST_EMAIL_SUCCESS',
  SEND_CAMPAIGN_TEST_EMAIL_FAILURE: 'SEND_CAMPAIGN_TEST_EMAIL_FAILURE',
  SET_CAMPAIGN_SORT_KEY_AND_VALUE: 'SET_CAMPAIGN_SORT_KEY_AND_VALUE',
  APPEND_CAMPAIGNS: 'APPEND_CAMPAIGNS',
  APPEND_CAMPAIGN_SUCCESS: 'APPEND_CAMPAIGN_SUCCESS',
  CHANGE_CAMPAIGN_STATUS: 'CHANGE_CAMPAIGN_STATUS',
  SET_CAMPAIGN_TAB_KEY: 'SET_CAMPAIGN_TAB_KEY',
  CHANGE_CAMPAIGN_STATUS_SUCCESS: 'CHANGE_CAMPAIGN_STATUS_SUCCESS',
  CHANGE_EMAIL_STATUS: 'CHANGE_EMAIL_STATUS',
  CHANGE_EMAIL_STATUS_SUCCESS: 'CHANGE_EMAIL_STATUS_SUCCESS',
  CREATE_DUPLICATE_CAMPAIGN: 'CREATE_DUPLICATE_CAMPAIGN',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  SET_LIVE_MODAL: 'SET_LIVE_MODAL',
  GET_CAMPAIGNS_RECIPIENTS_COUNT: 'GET_CAMPAIGNS_RECIPIENTS_COUNT',
  CAMPAIGN_FETCH_RECIPIENTS_SUCCESS: 'CAMPAIGN_FETCH_RECIPIENTS_SUCCESS',
  CAMPAIGN_FETCH_RECIPIENTS_FAILURE: 'CAMPAIGN_FETCH_RECIPIENTS_FAILURE',
  CHANGE_EMAIL_POSITION: 'CHANGE_EMAIL_POSITION',
  EMPTY_CAMPAIGN_EMAILS: 'EMPTY_CAMPAIGN_EMAILS',
  EMPTY_COMPOSER_DATA: 'EMPTY_COMPOSER_DATA',
  SET_DISABLE_CREATE_CAMPAIGN: 'SET_DISABLE_CREATE_CAMPAIGN',
  FETCH_CAMPAIGN_EMAILS_ERROR: 'FETCH_CAMPAIGN_EMAILS_ERROR',
  FETCH_CAMPAIGN_ERROR: 'FETCH_CAMPAIGN_ERROR',
  SET_AUTO_SAVE_LOADER: 'SET_AUTO_SAVE_LOADER',
  CAMPAIGN_SET_AFTER_TIME: 'CAMPAIGN_SET_AFTER_TIME',
  CAMPAIGN_SET_TIME_PERIOD: 'CAMPAIGN_SET_TIME_PERIOD',
  SET_VALUE_SETTINGS_CHANGES: 'SET_VALUE_SETTINGS_CHANGES',
  DISABLE_CAMPAIGN_EMAIL_CREATION: 'DISABLE_CAMPAIGN_EMAIL_CREATION',
  SET_CAMPAIGN_DATA: 'SET_CAMPAIGN_DATA',
  CAMPAIGN_CREATE_RECIPIENTS: 'CAMPAIGN_CREATE_RECIPIENTS',
  CHANGE_CAMPAIGN_EMAIL_STATUS_SUCCESS: 'CHANGE_CAMPAIGN_EMAIL_STATUS_SUCCESS',
  CHANGE_EMAIL_LIVE_STATUS: 'CHANGE_EMAIL_LIVE_STATUS',
  CHANGE_EMAIL_PAUSE_STATUS: 'CHANGE_EMAIL_PAUSE_STATUS',
  CHANGE_EMAIL_PUBLISH_STATUS: 'CHANGE_EMAIL_PUBLISH_STATUS',
  SET_CAMPAIGN_EMAIL_SUBJECT_VALIDATION:
    'SET_CAMPAIGN_EMAIL_SUBJECT_VALIDATION',
  SET_COMPOSER_VALUE_CHANGE: 'SET_COMPOSER_VALUE_CHANGE',
  SET_AUTO_SAVE_FAILURE: 'SET_AUTO_SAVE_FAILURE',
  UPDATE_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON:
    'UPDATE_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON',
  FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON:
    'FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON',
  FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON_SUCCESS:
    'FETCH_CAMPAIGN_EMAIL_VISUAL_BUILDER_JSON_SUCCESS',
  FETCH_CAMPAIGN_EMAIL_BEE_TOKEN: 'FETCH_CAMPAIGN_EMAIL_BEE_TOKEN',
  FETCH_CAMPAIGN_EMAIL_BEE_TOKEN_SUCCESS:
    'FETCH_CAMPAIGN_EMAIL_BEE_TOKEN_SUCCESS',
  SET_CAMPAIGN_EMAIL_TEMPLATE_TAB_KEY: 'SET_CAMPAIGN_EMAIL_TEMPLATE_TAB_KEY',
};

export default actions;
