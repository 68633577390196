import React, { Component } from 'react';
import { Button, Skeleton } from 'antd';
import { getPeriod, roundOffByTwoDecimal } from '../../helpers/utility';
import { times } from 'lodash';
import { shopifyPlanList } from '../../components/OnBoarding/helper';

class ShopifyPlanList extends Component {
  constructor(props) {
    super(props);
    this.state = { type: null };
  }

  selectPlanType = type => {
    this.setState({ type: type });
    this.props.connectShopifyStore(type);
  };

  render() {
    const { planDetail, loader, isFromPlansPage } = this.props;
    if (planDetail.length !== 0) {
      return planDetail.map((plan, key) => (
        <div className={'box'} key={key}>
          <div className={'avatar'}>
            <img src={shopifyPlanList[plan.slug_name].icon} alt={'profile'} />
          </div>
          <div className={'content'}>
            <h3>
              {plan.name} plan
              <span>
                From ${roundOffByTwoDecimal(plan.price)}/
                {getPeriod(plan.period_type)}
              </span>
            </h3>
            <span>{shopifyPlanList[plan.slug_name].description}</span>
          </div>
          <Button
            loading={loader && this.state.type === plan.slug_name}
            key="submit"
            className="btn btn-primary btn-md"
            onClick={() => {
              this.selectPlanType(plan.slug_name);
            }}
          >
            {isFromPlansPage ? 'Start Now' : 'Upgrade'}
          </Button>
        </div>
      ));
    }
    return times(3, key => (
      <div className={'skeleton'} key={key}>
        <Skeleton active title={false} paragraph={{ rows: 3, width: '100%' }} />
      </div>
    ));
  }
}

export default ShopifyPlanList;
