import { store } from '../../../../redux/store';
import { fetchProjectSecretKey } from '../../../../helpers/utility';
import settings from '../../../../settings';
import { html as beautify } from 'js-beautify';
import { qTypesMap, stepTypesMap } from '../constants';
import { cloneDeep } from 'lodash';

export function getTableValues() {
  const { tableState } = store.getState().SharedReducers;
  let detailObj = {
    tableState,
  };
  switch (tableState) {
    case 'surveyOnGoing':
      return {
        ...detailObj,
        sortKey: store.getState().surveyOnGoingReducer.sortKey,
        sortOrder: store.getState().surveyOnGoingReducer.sortOrder,
        status: store.getState().surveyOnGoingReducer.surveyOnGoingStatus,
        searchValue: store.getState().surveyOnGoingReducer.searchValue,
        tagIds: store.getState().surveyOnGoingReducer.surveyOnGoingTagId,
        surveyType: 'on_going',
      };
    case 'surveyOneOff':
      return {
        ...detailObj,
        sortKey: store.getState().surveyOneOffReducer.sortKey,
        sortOrder: store.getState().surveyOneOffReducer.sortOrder,
        status: store.getState().surveyOneOffReducer.surveyOneOffStatus,
        searchValue: store.getState().surveyOneOffReducer.searchValue,
        tagIds: store.getState().surveyOneOffReducer.surveyOneOffTagId,
        surveyType: 'one_off',
      };
    default:
      return detailObj;
  }
}

export function getWebEmbedSurveyContent(surveyId) {
  return `<div class="gist-embed-survey" data-survey-type="embedded" data-survey-key=${surveyId} data-workspace-id="${fetchProjectSecretKey()}"></div><script type="text/javascript" src="${
    settings.EMBEDDED_SURVEY_SCRIPTS_URL
  }" defer></script>`;
}

function EmailContentTemplate(content, mso = false) {
  if (!mso) {
    return `<!--[if !mso]><!--><table cellspacing="10" class="gist-embedded-survey" style="border-collapse: separate; box-sizing: border-box; display: block; max-width: 680px; width: 100%; margin: 0 auto; border: 1px solid #e8e8e8; border-radius: 6px; padding: 8px; font-size: 16px; color: #000000;"><tbody style="display: block; max-width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; scroll-behavior: smooth;">${content}</tbody></table><!--<![endif]-->`;
  } else {
    return `<!--[if mso]><table class="gist-embedded-survey"><tbody>${content}</tbody></table><![endif]-->`;
  }
}

function QuestionContentEmailContent(question) {
  return `
  <tr cellspacing="12"><td colspan="12" style="color: #000000;">${
    question.content
  }</td></tr>`;
}

function QuestionContentMSOEmailContent(question) {
  return `<tr cellspacing="12"><td colSpan="12">${question.content}</td></tr>`;
}

function SurveyURL(survey) {
  return `${settings.SURVEY_STAND_ALONE_URL}/${survey.secret_key}`;
}

function MultipleChoiceEmailContent(survey, question) {
  let getQuestionData = cloneDeep(question.data);
  let { options, additional_choice_text, include_other } = getQuestionData;
  if (include_other && options[options.length - 1] !== additional_choice_text) {
    options.push(additional_choice_text);
  }
  const content = `
  ${QuestionContentEmailContent(question)}
  <tr cellspacing="12">
    <td>
      <ul style="list-style-type: none; padding: 0; margin: 0;">
      ${options
        .map(
          option => `<li style="margin-bottom: 8px;text-align:left;">
          ${getQuestionValues(survey, question, option)}
        </li>`,
        )
        .join('')}
      </ul>
    </td>
  </tr>
  `;

  const mso_content = `${QuestionContentMSOEmailContent(
    question,
  )}<tr cellspacing="12"><td><ul>${question.data.options
    .map(
      option =>
        `<li><a href="${SurveyURL(survey)}" target="_blank">${option}</a></li>`,
    )
    .join('')}</ul></td></tr>`;

  return `
  ${EmailContentTemplate(content, false)}
  ${EmailContentTemplate(mso_content, true)}
  `;
}

function getQuestionValues(survey, question, value, emojiValue) {
  let isEmoji = question.question_type === qTypesMap.EMOJI,
    getStyle = isEmoji
      ? 'display: block; font-size: 26px; line-height: 1; text-decoration: none; text-align: center;'
      : 'display: inline-block; border: 1px solid #E8E8E8; text-decoration: none; border-radius: 5px; padding: 9px 17.5px 8px; color: #222222;';
  let isMultipleChoice = question.question_type === qTypesMap.MULTIPLE_CHOICE;
  let getQuestionId = question.id,
    modifiedQuestionId = getQuestionId
      .split('-')
      .splice(2)
      .join('-');
  let redirectSurveyLink = `${SurveyURL(survey)}?gt_${modifiedQuestionId}=${
    isEmoji ? emojiValue : encodeURIComponent(value)
  }&email={{contact.email}}`;
  return `
    <a
      height="${!isMultipleChoice ? '32' : 'auto'}"
      href="${redirectSurveyLink}"
      class='email'
      style="${getStyle}"
      target="_blank"
      width="${!isMultipleChoice ? '32' : 'auto'}"
    >
      ${value}
    </a>`;
}

// Rating
function RatingItem(survey, question, value, isEmpty = false, emojiValue) {
  return `<td style="display: table-cell; box-sizing: border-box; width: 32px; height: 32px; text-align: center; margin: 2px; padding: 0; background: #FFF;" width="32" height="32">
${
    isEmpty
      ? '<br />'
      : `${getQuestionValues(survey, question, value, emojiValue)}`
  }
</td>`;
}
function RatingEmailContent(survey, question) {
  const isEmoji = [qTypesMap.EMOJI].includes(question.question_type);
  const actualCount = question.data.scale_end - question.data.scale_start + 1;
  const totalCount = actualCount % 2 === 0 ? 10 : 11; // Check if even or odd to decide total
  const emptyCount = Math.floor((totalCount - question.data.scale_end) / 2); // Count of empty RatingItems on either side
  const defaultArray =
    question.data.scale_start === 0
      ? [...Array(totalCount).keys()]
      : Array.from({ length: totalCount }, (_, i) => i + 1);
  const ratingArray = defaultArray.map((value, index) => {
    value = value - emptyCount;
    return value;
  });

  let getEmojiContent;
  if (isEmoji) {
    getEmojiContent = question.data.options.filter(
      option =>
        question.data.scale_end === 5
          ? option.value
          : option.value > 1 && option.value < 5,
    );
  }

  const content = isEmoji
    ? `${QuestionContentEmailContent(question)}<tr>${ratingArray
      .map(
        (option, index) =>
          `${RatingItem(
            survey,
            question,
              getEmojiContent[option - 1] &&
                getEmojiContent[option - 1].unicodeEmoticon,
              option < question.data.scale_start ||
              option > question.data.scale_end
                ? true
                : false,
              option,
          )}`,
        )
      .join('')}</tr>
  `
    : `
      ${QuestionContentEmailContent(question)}
      <tr>${ratingArray
        .map(
          option =>
            `${RatingItem(
              survey,
              question,
              option,
              option < question.data.scale_start ||
              option > question.data.scale_end
                ? true
                : false,
            )}`,
        )
        .join('')}</tr>`;

  const mso_content = `${QuestionContentMSOEmailContent(
    question,
  )}<tr cellspacing="12">${
    isEmoji
      ? `${question.data.options
          .map(
            option =>
              `<td><a href="${SurveyURL(survey)}" target="_blank">${
                option.unicodeEmoticon
              }</a></td>`,
          )
          .join('')}`
      : `${[...Array(actualCount).keys()]
          .map(
            option =>
              `<td><a href="${SurveyURL(survey)}" target="_blank">${
                question.data.scale_start === 0 ? option : option + 1
              }</a></td>`,
          )
          .join('')}`
  }</tr>`;

  return `
      ${EmailContentTemplate(content, false)}
      ${EmailContentTemplate(mso_content, true)}
    `;
}

export function getEmailEmbedSurveyContent(survey) {
  const step = survey.content.steps.find(
    step => step.step_type === stepTypesMap.CONTENT,
  );
  if (step) {
    const question = step.questions[0];
    if (question) {
      switch (question.question_type) {
        case qTypesMap.MULTIPLE_CHOICE:
          return MultipleChoiceEmailContent(survey, question);
        case qTypesMap.OPINION_SCALE:
        case qTypesMap.NET_PROMOTER_SCORE:
        case qTypesMap.RATING:
        case qTypesMap.EMOJI:
          return RatingEmailContent(survey, question);
        default:
          break;
      }
    }
  }
}

export function beautifyHtml(code) {
  return beautify(code);
}
