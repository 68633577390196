const actions = {
  FETCH_BUILDER_DATA: 'FETCH_BUILDER_DATA',
  FETCH_BUILDER_DATA_SUCCESS: 'FETCH_BUILDER_DATA_SUCCESS',
  FETCH_BUILDER_DATA_FAILURE: 'FETCH_BUILDER_DATA_FAILURE',
  RESET_BUILDER_DATA: 'RESET_BUILDER_DATA',
  UPDATE_BUILDER_DATA: 'UPDATE_BUILDER_DATA',
  UPDATE_SELECTED_BUILDER_OBJECT: 'UPDATE_SELECTED_BUILDER_OBJECT',
  UPDATE_NEW_ELEMENT_OBJECT: 'UPDATE_NEW_ELEMENT_OBJECT',
  UPDATE_BUILDER_OBJECT: 'UPDATE_BUILDER_OBJECT',
  UPDATE_BUILDER_OBJECT_VALUE: 'UPDATE_BUILDER_OBJECT_VALUE',
  DELETE_BUILDER_OBJECT: 'DELETE_BUILDER_OBJECT',
  UPDATE_BUILDER_DEVICE: 'UPDATE_BUILDER_DEVICE',
  UNDO_BUILDER_HISTORY: 'UNDO_BUILDER_HISTORY',
  REDO_BUILDER_HISTORY: 'REDO_BUILDER_HISTORY',
  ADD_FORM_PRESET_FIELD: 'ADD_FORM_PRESET_FIELD',
  UPDATE_BUILDER_DATA_REFERENCE: 'UPDATE_BUILDER_DATA_REFERENCE',
  SET_FONT_OPTIONS: 'SET_FONT_OPTIONS',
  UPDATE_CREATE_NEW_ITEM_TYPE: 'UPDATE_CREATE_NEW_ITEM_TYPE',
};

export default actions;
