import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { fetchProjectSecretKey } from '../../helpers/utility';

function RedeemCoupon() {
  const plan = useSelector(({ Auth }) => Auth.plan);

  return (
    <Layout className={'settings-layout'}>
      <iframe
        title={'prime-plan'}
        className={'h-full w-full border-none'}
        src={`https://getgist.com/ltd-upgrade?secret_key=${fetchProjectSecretKey()}&plan=${plan}`}
      />
    </Layout>
  );
}

export default RedeemCoupon;
