import React, { Component } from 'react';
import {
  extractAvatarLetter,
  fetchProjectSecretKey,
  extractCurrentPersonId,
} from '../../../helpers/utility';
import { history } from '../../../redux/store';

export class ProfileAvatar extends Component {
  navigateToProfile = () => {
    history.push(
      `/projects/${fetchProjectSecretKey()}/contacts/${extractCurrentPersonId()}`,
    );
  };

  render() {
    const { className, profile, displayProfile, fromProfile } = this.props;

    if ([null, undefined].includes(profile)) {
      return '';
    }

    if (
      profile.avatar_url != null &&
      !profile.avatar_url.includes('assets.getgist.com/avatar')
    ) {
      if (fromProfile) {
        return (
          <div
            className={`profile-avatar ${className}`}
            onClick={this.navigateToProfile}
          >
            <img
              src={profile.avatar_url}
              alt="US"
              style={{
                visibility: displayProfile ? 'visible' : 'hidden',
                cursor: 'pointer',
              }}
            />
          </div>
        );
      } else {
        return (
          <div className={`profile-avatar ${className}`}>
            <img
              src={profile.avatar_url}
              alt="US"
              style={{
                visibility: displayProfile ? 'visible' : 'hidden',
              }}
            />
          </div>
        );
      }
    } else {
      if (fromProfile) {
        return (
          <div
            className={`profile-avatar text ${className}`}
            style={{
              visibility: displayProfile ? 'visible' : 'hidden',
              cursor: 'pointer',
            }}
            onClick={this.navigateToProfile}
          >
            {profile.avatar_letter
              ? profile.avatar_letter
              : extractAvatarLetter(profile.full_name)}
          </div>
        );
      } else {
        return (
          <div
            className={`profile-avatar text ${className}`}
            style={{ visibility: displayProfile ? 'visible' : 'hidden' }}
          >
            {profile.avatar_letter
              ? profile.avatar_letter
              : extractAvatarLetter(profile.full_name || profile.name)}
          </div>
        );
      }
    }
  }
}

export default ProfileAvatar;
