import React, { Component } from 'react';
import './style.scss';
import { Layout, List, message, Modal, Button } from 'antd';
import actions from '../../redux/auth/actions';
import connect from 'react-redux/es/connect/connect';
import logo from '../../image/logo.png';
import {
  fetchProjectSecretKey,
  getQueryParamsValue,
  updateBrowserHistory,
} from '../../helpers/utility';
import { store, history } from '../../redux/store';
import WorkSpaceCreate from '../../components/WorkSpace/WorkSpaceCreate';
import WorkSpaceListItem from './WorkSpaceListItem';
import { invalidContent } from '../../components/constants';
import ShopifyPlanList from './ShopifyPlanList';

const confirm = Modal.confirm;

class WorkSpaceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ButtonPositionDown: true,
      shopifyStoreId: getQueryParamsValue('shopify_store'),
    };
    if (!this.props.currentUserId && !this.props.authenticateLoader) {
      history.push(
        `/login?shopify_store=${getQueryParamsValue('shopify_store')}`,
      );
    } else {
      store.dispatch({
        type: actions.GET_ALL_PROJECTS,
      });
    }
  }

  componentDidUpdate(prevProps) {
    let element = document.getElementById('workSpaceList');
    if (element) {
      if (
        element.scrollHeight >= window.innerHeight &&
        this.state.ButtonPositionDown !== false
      ) {
        this.setState({
          ButtonPositionDown: false,
        });
      }
    }
    if (
      this.props.projects.length !== prevProps.projects.length &&
      !invalidContent.includes(this.state.shopifyStoreId)
    ) {
      if (this.props.projects.length === 1) {
        this.handleShopifyIntegration(this.props.projects[0]);
      }
    }
  }

  componentDidMount() {
    document.title = 'Workspaces - Gist';
    this.setState({ shopifyStoreId: getQueryParamsValue('shopify_store') });
  }

  showWorkSpaceDrawer = () => {
    store.dispatch({
      type: actions.OPEN_WORKSPACE_DRAWER,
    });
  };

  CreateWorkSpaceButton = () => (
    <Button
      onClick={() => this.showWorkSpaceDrawer()}
      className="btn btn-primary btn-sm"
    >
      New workspace
    </Button>
  );

  onRedirection = (e, project) => {
    if (!invalidContent.includes(this.state.shopifyStoreId)) {
      const tempThis = this,
        { project_name, secret_key } = project;
      e.preventDefault();
      confirm({
        title: `Are you sure, do you want to connect the shopify store to the workspace ${project_name} (${secret_key})`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          tempThis.handleShopifyIntegration(project);
        },
      });
    }
  };

  handleShopifyIntegration = project => {
    this.setState(
      { projectSecretKey: project.secret_key },
      this.connectShopifyStore,
    );
  };

  isEnabledShopifyIntegration = project =>
    project.shopify_integration_enabled &&
    project.can_manage_apps_and_integrations;

  connectShopifyStore = slugName => {
    const { shopifyStoreId, projectSecretKey } = this.state,
      { projects } = this.props,
      projectDetails = projects.find(
        ({ secret_key }) => projectSecretKey === secret_key,
      );
    if (this.isEnabledShopifyIntegration(projectDetails)) {
      store.dispatch({
        type: actions.CONNECT_SHOPIFY_FROM_APP,
        payload: {
          shopify_store: shopifyStoreId,
          slug_name: slugName ? slugName : null,
        },
        isRedirect: true,
        secretKey: projectSecretKey,
      });
    } else if (projectDetails.owner) {
      history.push({
        pathname: `/projects/${projectSecretKey}/settings/change-plan`,
        search: `?shopify_store=${shopifyStoreId}`,
      });
      window.location.reload();
    } else {
      message.info(
        'This feature is restricted, Ask one of your teammates to give you permission.',
      );
    }
  };
  render() {
    const {
        projects,
        workSpaceLoader,
        openWorkSpaceDrawer,
        createWorkSpaceLoader,
        planDetail,
        shopifyDrawerStatus,
        loader,
      } = this.props,
      { shopifyStoreId } = this.state;

    return (
      <Layout>
        <div className="workspace-list-layout">
          {fetchProjectSecretKey() &&
            invalidContent.includes(shopifyStoreId) && (
            <span
              onClick={() => updateBrowserHistory('contacts')}
              className="back-link"
            >
              <i className="fal fa-chevron-left" />
              Back to Current Workspace
            </span>
          )}
          <div
            className={`workspace-list ${
              projects.length === 0 && !workSpaceLoader ? 'no-workspace' : ''
            }`}
            id="workSpaceList"
          >
            <div className="branding">
              <img src={logo} alt="gist" className="logo" />
              <h1>GIST</h1>
            </div>
            {!invalidContent.includes(shopifyStoreId) ? (
              <h3>
                Which workspace do you want the shopify store to integrate with?
              </h3>
            ) : (
              <p className="info">
                Workspaces let you collaborate with team members, add
                permissions and share contacts across your whole team under a
                shared billing account..
              </p>
            )}
            {!this.state.ButtonPositionDown &&
              invalidContent.includes(shopifyStoreId) &&
              this.CreateWorkSpaceButton()}
            <List
              itemLayout="horizontal"
              dataSource={projects}
              loading={workSpaceLoader}
              locale={{ emptyText: <span /> }}
              renderItem={project => (
                <a
                  onClick={e => this.onRedirection(e, project)}
                  href={`/projects/${project.secret_key}/contacts`}
                >
                  <WorkSpaceListItem project={project} />
                </a>
              )}
            />
            {this.state.ButtonPositionDown &&
              invalidContent.includes(shopifyStoreId) &&
              this.CreateWorkSpaceButton()}
          </div>
        </div>
        <WorkSpaceCreate
          openWorkSpaceDrawer={openWorkSpaceDrawer}
          createWorkSpaceLoader={createWorkSpaceLoader}
        />
        {!invalidContent.includes(shopifyStoreId) && (
          <Modal
            closable={true}
            wrapClassName={'shopify-plan-modal'}
            width={720}
            visible={shopifyDrawerStatus}
            title={'Upgrade your plan'}
            footer={null}
          >
            <div>
              <ShopifyPlanList
                planDetail={planDetail}
                connectShopifyStore={this.connectShopifyStore}
                loader={loader}
              />
            </div>
          </Modal>
        )}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    projects,
    workSpaceLoader,
    createWorkSpaceLoader,
    openWorkSpaceDrawer,
    currentUserId,
    authenticateLoader,
    planDetail,
    shopifyDrawerStatus,
    loader,
  } = state.Auth;
  return {
    projects,
    workSpaceLoader,
    createWorkSpaceLoader,
    openWorkSpaceDrawer,
    currentUserId,
    authenticateLoader,
    planDetail,
    shopifyDrawerStatus,
    loader,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(WorkSpaceList);
