import actions from './actions';
import {
  getSatisfactionIndividualPercentage,
  getSatisfactionPercentage,
  getSatisfactionRatingTotal,
  previousDays,
} from '../../../components/Reports/helper';

let initState = {
  inboxReportsData: {
    newConversationReports: {},
    closedConversationReports: {},
    customersReachedReports: {},
  },
  productivitySummary: {
    medianFirstResponseReports: {},
    medianCloseReports: {},
  },
  conversationRepliesData: {
    conversationReliedTo: {},
    repliesSend: {},
  },
  articleFeedbackData: [],
  satisfactionRatingData: [],
  satisfactionRatingCount: {},
  satisfactionRatingPreviousCount: {},
  satisfactionRatingPercent: 0,
  satisfactionRatingPreviousPercent: 0,
  satisfactionRatingTotalItem: 1,
  satisfactionRatingCurrentPage: 1,
  supportBotReportsData: {
    resolved_conversations: {},
    covered: {},
    not_covered: {},
    resolution_rate: {},
    top_handoff_answers: [],
    popular_answers: [],
    training_opportunities: [],
  },
  emailReportsData: {
    overall_metrics: {},
    emails_sent: {},
    emails_opened: {},
    emails_clicked: {},
    emails_clicked_through_rate: {},
    emails_delivered: {},
    emails_bounced: {},
    emails_unsubscribed: {},
    emails_spammed: {},
  },
  loader: {
    newConversationReportsLoader: false,
    closedConversationReportsLoader: false,
    customersReachedReportsLoader: false,
    medianFirstResponseReportsLoader: false,
    medianCloseReportsLoader: false,
    repliesSendLoader: false,
    conversationReliedToLoader: false,
    articleTableLoader: false,
    supportBotCoverageReportsLoader: false,
    satisfactionRatingTableLoader: false,
  },
  currentDays: '7',
  inboxReportFilterChanges: {
    ...previousDays('7'),
    checkFor: 'allTeamMates',
    type: 'team',
  },
  authorList: [],
  articleCurrentDays: '7',
  articleFeedbackReportFilterChanges: {
    ...previousDays('7'),
    author: 'all',
    rating: 'all',
    has_comment: 'all',
    type: 'article_feedback',
  },
  satisfactionRatingCurrentDays: '90',
  satisfactionRatingFilters: {
    ...previousDays('90'),
    rating: 'all',
    has_comment: 'all',
    type: 'satisfaction_rating',
    user: 'all',
  },
  teamsAndTeammatesList: {
    teamsList: [],
    temmatesList: [],
  },
  teamsloader: false,
  teamsAndTeammatesData: {
    conversationReliedTo: {},
    closedConversationReports: {},
    repliesSend: {},
    medianFirstResponseReports: {},
    medianCloseReports: {},
  },
  teamsAndTeammatesDataLoader: {
    conversationReliedTo: {},
    repliesSend: {},
    closedConversationReports: {},
    medianFirstResponseReports: {},
    medianCloseReports: {},
  },
  reportSideBarActiveKey: '',
  teamName: '',
  teammateName: '',
};

export default function inboxReportsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_NEW_CONVERSATION_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          newConversationReportsLoader: true,
        },
      };
    case actions.FETCH_NEW_CONVERSATION_REPORTS_SUCCESS:
      return {
        ...state,
        inboxReportsData: {
          ...state.inboxReportsData,
          newConversationReports: action.payload,
        },
        loader: {
          ...state.loader,
          newConversationReportsLoader: false,
        },
      };
    case actions.FETCH_NEW_CONVERSATION_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          newConversationReportsLoader: false,
        },
      };
    case actions.FETCH_CLOSED_CONVERSATION_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          closedConversationReportsLoader: true,
        },
      };
    case actions.FETCH_CLOSED_CONVERSATION_REPORTS_SUCCESS:
      return {
        ...state,
        inboxReportsData: {
          ...state.inboxReportsData,
          closedConversationReports: action.payload,
        },
        loader: {
          ...state.loader,
          closedConversationReportsLoader: false,
        },
        teamsAndTeammatesData: {
          ...state.teamsAndTeammatesData,
          closedConversationReports: action.payload,
        },
      };
    case actions.FETCH_CLOSED_CONVERSATION_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          closedConversationReportsLoader: false,
        },
      };
    case actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          medianFirstResponseReportsLoader: true,
          medianCloseReportsLoader: true,
        },
      };
    case actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_SUCCESS:
      return {
        ...state,
        productivitySummary: {
          ...state.productivitySummary,
          medianFirstResponseReports: action.payload,
        },
        loader: {
          ...state.loader,
          medianFirstResponseReportsLoader: false,
          medianCloseReportsLoader: false,
        },
        teamsAndTeammatesData: {
          ...state.teamsAndTeammatesData,
          medianFirstResponseReports: action.payload,
        },
      };
    case actions.FETCH_MEDIAN_FIRST_RESPONSE_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          medianFirstResponseReportsLoader: false,
          medianCloseReportsLoader: false,
        },
      };
    case actions.FETCH_MEDIAN_CLOSE_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          medianCloseReportsLoader: true,
        },
      };
    case actions.FETCH_MEDIAN_CLOSE_REPORTS_SUCCESS:
      return {
        ...state,
        productivitySummary: {
          ...state.productivitySummary,
          medianCloseReports: action.payload,
        },
        loader: {
          ...state.loader,
          medianCloseReports: false,
        },
        teamsAndTeammatesData: {
          ...state.teamsAndTeammatesData,
          medianCloseReports: action.payload,
        },
      };
    case actions.FETCH_MEDIAN_CLOSE_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          medianCloseReportsLoader: false,
        },
      };
    case actions.UPDATE_INBOX_REPORT_FILTERS:
      return {
        ...state,
        currentDays: action.payload.currentDays,
        inboxReportFilterChanges: {
          ...state.inboxReportFilterChanges,
          ...action.payload.filterData,
        },
      };
    case actions.UPDATE_ARTICLE_FEEDBACK_REPORT_FILTERS:
      return {
        ...state,
        articleCurrentDays: action.payload.articleCurrentDays,
        articleFeedbackReportFilterChanges: {
          ...state.articleFeedbackReportFilterChanges,
          ...action.payload.filterData,
        },
      };
    case actions.UPDATE_SATISFACTION_RATING_REPORTS_FILTERS: {
      const { currentDays, filterData } = action.payload;
      return {
        ...state,
        satisfactionRatingCurrentDays: currentDays,
        satisfactionRatingFilters: {
          ...state.satisfactionRatingFilters,
          ...filterData,
        },
      };
    }
    case actions.FETCH_TEAMS_AND_TEAMMATES_LIST:
      return {
        ...state,
        teamsloader: true,
      };
    case actions.FETCH_TEAMS_AND_TEAMMATES_LIST_SUCCESS:
      return {
        ...state,
        teamsAndTeammatesList: {
          teamsList: action.payload.teams,
          temmatesList: action.payload.teammates,
        },
        teamsloader: false,
      };
    case actions.FETCH_TEAMS_AND_TEAMMATES_LIST_FAILURE:
      return {
        ...state,
        teamsloader: false,
      };
    case actions.FETCH_REPLIED_CONVERSATION_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          repliesSendLoader: true,
          conversationReliedToLoader: true,
        },
      };
    case actions.FETCH_REPLIED_CONVERSATION_REPORTS_SUCCESS:
      return {
        ...state,
        conversationRepliesData: {
          ...state.conversationRepliesData,
          repliesSend: action.payload.replies_sent,
          conversationReliedTo: action.payload.conversation_replied_to,
        },
        loader: {
          ...state.loader,
          repliesSendLoader: false,
          conversationReliedToLoader: false,
        },
        teamsAndTeammatesData: {
          ...state.teamsAndTeammatesData,
          repliesSend: action.payload.replies_sent,
          conversationReliedTo: action.payload.conversation_replied_to,
        },
        teamName: action.payload.team_name ? action.payload.team_name : '',
        teammateName: action.payload.teammate_name
          ? action.payload.teammate_name
          : '',
      };
    case actions.FETCH_REPLIED_CONVERSATION_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          repliesSendLoader: false,
          conversationReliedToLoader: false,
        },
      };
    case actions.FETCH_CUSTOMERS_REACHED_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          customersReachedReportsLoader: true,
        },
      };
    case actions.FETCH_CUSTOMERS_REACHED_REPORTS_SUCCESS:
      return {
        ...state,
        inboxReportsData: {
          ...state.inboxReportsData,
          customersReachedReports: action.payload,
        },
        loader: {
          ...state.loader,
          customersReachedReportsLoader: false,
        },
      };
    case actions.FETCH_CUSTOMERS_REACHED_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          customersReachedReportsLoader: false,
        },
      };
    case actions.FETCH_ARTICLE_FEEDBACK_AUTHOR_LIST_SUCCESS:
      return {
        ...state,
        authorList: [
          action.payload[0],
          ...action.payload
            .slice(1, action.payload.length)
            .sort((a, b) => a.full_name.localeCompare(b.full_name)),
        ],
      };
    case actions.FETCH_ARTICLE_FEEDBACK_REPORTS:
      return {
        ...state,
        loader: {
          ...state.loader,
          articleTableLoader: true,
        },
      };
    case actions.FETCH_ARTICLE_FEEDBACK_REPORTS_SUCCESS:
      return {
        ...state,
        articleFeedbackData: action.payload,
        loader: {
          ...state.loader,
          articleTableLoader: false,
        },
      };
    case actions.FETCH_ARTICLE_FEEDBACK_REPORTS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          articleTableLoader: false,
        },
      };
    case actions.FETCH_SATISFACTION_RATINGS_METRICS:
      return {
        ...state,
        loader: {
          ...state.loader,
          satisfactionRatingTableLoader: true,
        },
      };
    case actions.FETCH_SATISFACTION_RATINGS_METRICS_SUCCESS: {
      const { satisfaction_ratings } = action.payload;
      return {
        ...state,
        satisfactionRatingData: satisfaction_ratings,
        loader: {
          ...state.loader,
          satisfactionRatingTableLoader: false,
        },
        satisfactionRatingCurrentPage: action.page || 1,
      };
    }
    case actions.FETCH_SATISFACTION_RATINGS_COUNT_SUCCESS: {
      const { count, previous_count } = action.payload;
      return {
        ...state,
        satisfactionRatingTotalItem: getSatisfactionRatingTotal(count),
        satisfactionRatingCount: getSatisfactionIndividualPercentage(count),
        satisfactionRatingPercent: getSatisfactionPercentage(count),
        satisfactionRatingPreviousCount: getSatisfactionIndividualPercentage(
          previous_count,
        ),
        satisfactionRatingPreviousPercent: getSatisfactionPercentage(
          previous_count,
        ),
      };
    }
    case actions.FETCH_SATISFACTION_RATINGS_METRICS_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          satisfactionRatingTableLoader: false,
        },
      };

    case actions.SET_SIDEBAR_ACTIVE:
      return {
        ...state,
        reportSideBarActiveKey: action.key,
      };
    case actions.RESET_TEAM_AND_TEAMMATE_NAME:
      return {
        ...state,
        teamName: '',
        teammateName: '',
      };
    case actions.FETCH_SUPPORT_BOT_METRICS_REPORTS_SUCCESS:
      return {
        ...state,
        supportBotReportsData: {
          ...state.supportBotReportsData,
          resolved_conversations: action.payload.metrics,
        },
        loader: {
          ...state.loader,
          supportBotResolvedConversationsReportsLoader: false,
        },
      };
    case actions.FETCH_SUPPORT_BOT_COVERAGE_REPORTS_SUCCESS: {
      let actionType = action.action_type;

      return {
        ...state,
        supportBotReportsData: {
          ...state.supportBotReportsData,
          [actionType]: action.payload,
        },
        loader: {
          ...state.loader,
          supportBotCoverageReportsLoader: false,
        },
      };
    }
    case actions.FETCH_SUPPORT_BOT_RESOLUTION_RATE_REPORTS_SUCCESS:
      return {
        ...state,
        supportBotReportsData: {
          ...state.supportBotReportsData,
          resolution_rate: action.payload.metrics,
        },
        loader: {
          ...state.loader,
          supportBotResolutionRateReportsLoader: false,
        },
      };
    case actions.FETCH_SUPPORT_BOT_LIST_OF_ANSWERS_REPORTS_SUCCESS: {
      let actionType = action.action_type;
      let loaderLabel = '';

      if (actionType === 'top_handoff_answers') {
        loaderLabel = 'supportBotTopHandOffAnswersReportsLoader';
      } else if (actionType === 'popular_answers') {
        loaderLabel = 'supportBotPopularAnswersReportsLoader';
      } else if (actionType === 'training_opportunities') {
        loaderLabel = 'supportBotTrainingOpportunitiesReportsLoader';
      }

      return {
        ...state,
        supportBotReportsData: {
          ...state.supportBotReportsData,
          [actionType]: action.payload,
        },
        loader: {
          ...state.loader,
          [loaderLabel]: false,
        },
      };
    }

    // EMAIL HEALTH
    case actions.FETCH_EMAIL_HEALTH_REPORTS_SUCCESS:
      return {
        ...state,
        emailReportsData: {
          ...state.emailReportsData,
          overall_metrics: action.payload.metrics,
        },
      };
    case actions.FETCH_EMAIL_METRICS_REPORTS_SUCCESS: {
      let actionType = action.action_type;

      return {
        ...state,
        emailReportsData: {
          ...state.emailReportsData,
          [actionType]: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
