import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../../settings';
import {
  deletePromise,
  fetchProjectSecretKey,
  getPromise,
  patchPromise,
  postPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  trackEvent,
} from '../../../helpers/utility';
import { message } from 'antd';
import { store } from '../../store';

export function* fetchRulesList(params) {
  try {
    let sortKey = '';
    let sortOrder = '';
    let searchValue = '';
    let filterStatus = '';
    if (params.sortKey) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = store.getState().Rules.sortKey;
      sortOrder = store.getState().Rules.sortOrder;
    }
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Rules.searchValue;
    }
    if (params.status) {
      filterStatus = params.status;
    } else {
      filterStatus = store.getState().Rules.filterStatus;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules?status=${filterStatus}&sort_key=${sortKey}&sort_type=${sortOrder}&search_text=${searchValue}&last_rule_id=`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_RULES_LIST_SUCCESS,
      payload: data,
      searchValue: searchValue,
      filterStatus: filterStatus,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Rules table fetch failed');
    }
  }
}
export function* appendRulesList(params) {
  try {
    let sortKey = '';
    let sortOrder = '';
    let searchValue = '';
    let status = 'all';
    let lastRuleId = '';
    if (store.getState().Rules.sortKey && store.getState().Rules.sortOrder) {
      sortKey = store.getState().Rules.sortKey;
      sortOrder = store.getState().Rules.sortOrder;
    }
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().Rules.searchValue;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().Rules.filterStatus;
    }
    if (params.payload.data.lastRuleId) {
      lastRuleId = params.payload.data.lastRuleId;
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules?status=${status}&sort_key=${sortKey}&sort_type=${sortOrder}&search_text=${searchValue}&last_rule_id=${lastRuleId}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_RULES_LIST_SUCCESS,
      data: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Rules table append failed');
    }
  }
}

export function* addRuleTableContent(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules`;
    let data = yield call(() => postPromise(url, params.triggerName));
    trackEvent('Created automation rule');
    updateBrowserHistory(`rules/${data.data.automation_rule.id}`);
    message.success(data.message);
    yield put({
      type: actions.SET_TRIGGER_NAME,
      payload: params.triggerName.rule.name,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Automation rule add failed');
    }
  }
}

export function* deleteTableContent(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules/${params.id}`;
    let data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_TABLE_CONTENT_SUCCESS,
      id: params.id,
    });
    yield put({
      type: actions.FETCH_RULES_LIST,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Delete action failed');
    }
  }
}

export function* changeStatus(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules/${params.id}`;
    yield call(() => patchPromise(url, params.status));
    store.dispatch({
      type: actions.CHANGE_STATUS_SUCCESS,
      payload: {
        index: params.index,
        status: params.status.rule.active,
      },
    });
    message.success('Rules status changed successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Status change failed');
    }
  }
}

export function* fetchRulesEditor(params) {
  try {
    let data_url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules/${params.id}`;
    let existingData = yield call(() => getPromise(data_url));
    yield put({
      type: actions.SET_TRIGGER_NAME,
      payload: existingData.data.automation_rule.name,
    });
    yield put({
      type: actions.FETCH_RULES_EDITOR_SUCCESS,
      rulesStatus: existingData.data.automation_rule.active,
      triggerData: existingData.data.automation_rule.automation_rule_triggers,
      actionData: existingData.data.automation_rule.automation_rule_actions,
      existingData: existingData.data.automation_rule,
    });
    existingData.data.automation_rule.automation_rule_triggers.map(data => {
      if (data.trigger_type === 'clicked_link_from_email') {
        return store.dispatch({
          type: actions.FETCH_LINK,
          value: data.trigger_details.mail_type,
          campaignId: data.trigger_details.campaign_id,
        });
      } else {
        return null;
      }
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Rules editor fetch failed');
    }
  }
}

export function* fetchTriggerAndAction() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules/triggers_and_actions`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TRIGGER_AND_ACTION_SUCCESS,
      triggerOptions: data.data.triggers[0].options,
      actionOptions: data.data.actions[0].options,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Trigger and Action fetch failed ');
    }
  }
}

export function* fetchCampaignsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/campaigns_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CAMPAIGNS_LIST_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch campaign list failed');
    }
  }
}

export function* fetchFormsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/forms_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FORMS_LIST_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch form list failed');
    }
  }
}

export function* fetchWorkflowList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/workflows_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_RULES_WORKFLOW_LIST_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch workflow list failed');
    }
  }
}

export function* fetchEmailsList(params) {
  try {
    if (params.mailType === 'not_campaign') {
      let broadcast_url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/emails_list?mail_type=manual`;
      let auto_url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/emails_list?mail_type=auto`;
      let automation_url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/emails_list?mail_type=automation`;
      let broadCastList = yield call(() => getPromise(broadcast_url));
      let autoList = yield call(() => getPromise(auto_url));
      let automationEmailList = yield call(() => getPromise(automation_url));
      let emailsList = [
        { broadCastList },
        { autoList },
        { automationEmailList },
      ];

      yield put({
        type: actions.FETCH_EMAILS_LIST_SUCCESS,
        emailsList: emailsList,
      });
    }
    if (params.campaignId) {
      let campaign_list_url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/emails_list?mail_type=campaign&campaign_id=${
        params.campaignId
      }`;
      let campaignEmailList = yield call(() => getPromise(campaign_list_url));
      yield put({
        type: actions.CAMPAIGN_EMAIL_LIST,
        campaignEmailList: campaignEmailList.data,
        id: params.campaignId,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch Email list failed');
    }
  }
}

export function* fetchEmailLinks(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/email_links?mail_filter_id=${
      params.id
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMAIL_LINKS_SUCCESS,
      data: data.data,
      id: params.id,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch Email link list failed');
    }
  }
}

export function* fetchTagsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_RULES_TAGS_LIST_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch tags list failed');
    }
  }
}

export function* fetchContactList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/properties_list`;
    if (params.isFromSurveyTemplate) {
      url += '?default=true';
    }
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CONTACT_LIST_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch contact list failed');
    }
  }
}

export function* fetchCustomList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/custom_fields_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CUSTOM_LIST_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch custom fields failed');
    }
    message.error('Fetch custom fields failed');
  }
}

export function* fetchFromUser() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teammates_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_FROM_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch from user ID failed');
    }
  }
}

export function* patchRulesEditor(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/automation_rules/${params.id}`;
    let data = yield call(() => patchPromise(url, params.data));
    if (store.getState().Rules.rulesStatus) {
      message.success(data.message);
    }
    yield put({
      type: actions.SEND_EMAIL_VALIDATION,
      actionModalVisible: false,
    });
    if (params.triggerSection === false) {
      store.dispatch({
        type: actions.DELETE_ACTION_SECTION,
        actionDeleteId: params.actionDeleteId,
        id: params.deleteId,
      });
    } else if (params.triggerSection === true) {
      store.dispatch({
        type: actions.DELETE_TRIGGER_SECTION,
        triggerDeleteId: params.triggerDeleteId,
        id: params.deleteId,
      });
    }
    store.dispatch({ type: actions.PATCH_RULES_EDITOR_SUCCESS });
    yield put({
      type: actions.FETCH_RULES_EDITOR_SUCCESS,
      rulesStatus: data.data.automation_rule.active,
      triggerData: data.data.automation_rule.automation_rule_triggers,
      actionData: data.data.automation_rule.automation_rule_actions,
      existingData: data.data.automation_rule,
    });
  } catch (error) {
    store.dispatch({ type: actions.PATCH_RULES_EDITOR_FAILURE });
    unAuthorisedRedirection(error);
    yield put({
      type: actions.LOADER_STATUS,
      rulesTableLoader: false,
      buttonLoader: false,
      switchLoader: false,
      loader: false,
    });
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Update failed');
    }
  }
}

export function* fetchLink(params) {
  try {
    let url;
    if (params.value === 'any_email') {
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/email_links?mail_type`;
    } else if (params.campaignId) {
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/email_links?mail_type=campaign&campaign_id=${
        params.campaignId
      }`;
    } else if (
      params.value === 'auto' ||
      params.value === 'manual' ||
      params.value === 'campaign' ||
      params.value === 'automation'
    ) {
      url = `${
        settings.ROOT_URL
      }projects/${fetchProjectSecretKey()}/email_links?mail_type=${
        params.value
      }`;
    }
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_LINK_SUCCESS,
      payload: data.data,
      params,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('fetch link failed');
  }
}

export function* fetchToUsers() {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/teams-users-list?include_current_user=true&sort_by=email`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_TO_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Fetch user teammates failed');
  }
}
export function* fetchActions() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/imports/actions`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_ACTIONS_SUCCESS,
      actionOptions: data.data.actions[0].options,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Action fetch failed ');
    }
  }
}

export function* getProductLists(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/products_list`;
    if (params.storeId) {
      url += `?store_id=${params.storeId}`;
    }
    if (params.categoryId) {
      url += `&category_id=${params.categoryId}`;
    }
    if (params.page) {
      url += `&page=${params.page}`;
    }
    if (store.getState().Rules.productSearch) {
      url += `&search_text=${store.getState().Rules.productSearch}`;
    }
    let data = yield call(() => getPromise(url));
    if (params.page) {
      yield put({
        type: actions.RULES_UPDATE_PRODUCTS_LIST_SUCCESS,
        payload: { categoryId: params.categoryId, productListData: data.data },
      });
    } else {
      yield put({
        type: actions.RULES_GET_PRODUCTS_LIST_SUCCESS,
        payload: { categoryId: params.categoryId, productListData: data.data },
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Products list fetch failed');
  }
}

export function* getProductCategoriesLists(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/product_categories_list`;
    if (params.storeId) {
      url += `?store_id=${params.storeId}`;
    }
    if (params.page) {
      url += `&page=${params.page}`;
    }
    if (store.getState().Rules.categorySearch) {
      url += `&search_text=${store.getState().Rules.categorySearch}`;
    }
    let data = yield call(() => getPromise(url));
    if (params.page) {
      yield put({
        type: actions.RULES_UPDATE_PRODUCTS_CATEGORIES_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actions.RULES_GET_PRODUCT_CATEGORIES_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Product categories list fetch failed');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_RULES_LIST, fetchRulesList)]);
  yield all([takeEvery(actions.ADD_RULE_TABLE_CONTENT, addRuleTableContent)]);
  yield all([takeEvery(actions.DELETE_TABLE_CONTENT, deleteTableContent)]);
  yield all([takeEvery(actions.FETCH_RULES_EDITOR, fetchRulesEditor)]);
  yield all([takeEvery(actions.CHANGE_STATUS, changeStatus)]);
  yield all([takeEvery(actions.PATCH_RULES_EDITOR, patchRulesEditor)]);
  yield all([takeEvery(actions.APPEND_RULES_LIST, appendRulesList)]);
  yield all([
    takeEvery(actions.FETCH_TRIGGER_AND_ACTION, fetchTriggerAndAction),
  ]);
  yield all([takeEvery(actions.FETCH_CAMPAIGNS_LIST, fetchCampaignsList)]);
  yield all([takeEvery(actions.FETCH_FORMS_LIST, fetchFormsList)]);
  yield all([takeEvery(actions.FETCH_EMAILS_LIST, fetchEmailsList)]);
  yield all([takeEvery(actions.FETCH_RULES_TAGS_LIST, fetchTagsList)]);
  yield all([takeEvery(actions.FETCH_EMAIL_LINKS, fetchEmailLinks)]);
  yield all([takeEvery(actions.FETCH_CONTACT_LIST, fetchContactList)]);
  yield all([takeEvery(actions.FETCH_CUSTOM_LIST, fetchCustomList)]);
  yield all([takeEvery(actions.FETCH_FROM_USER, fetchFromUser)]);
  yield all([takeEvery(actions.FETCH_RULES_WORKFLOW_LIST, fetchWorkflowList)]);
  yield all([
    takeEvery(actions.FETCH_LINK, fetchLink),
    takeEvery(actions.FETCH_TO_USERS, fetchToUsers),
  ]);
  yield all([takeEvery(actions.FETCH_ACTIONS, fetchActions)]);
  yield all([takeEvery(actions.RULES_GET_PRODUCTS_LIST, getProductLists)]);
  yield all([
    takeEvery(
      actions.RULES_GET_PRODUCT_CATEGORIES_LIST,
      getProductCategoriesLists,
    ),
  ]);
}
