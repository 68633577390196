import React from 'react';
import { openLinkInNewTab } from '../../../../../helpers/utility';
import '../style.scss';

const ImageComponent = props => {
  const { component } = props;

  return (
    <div
      className={`image-container${
        component.align ? ` align-${component.align}` : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
    >
      <img
        className={`final-image${component.rounded ? ' round' : ''}`}
        {...(component.url ? { src: component.url } : {})}
        {...(component.width ? { width: component.width } : {})}
        {...(component.height ? { height: component.height } : {})}
        {...(component.action && component.action.type
          ? { 'data-action-type': component.action.type }
          : {})}
        {...(component.action && component.action.url
          ? { 'data-action-url': component.action.url }
          : {})}
        {...(component.action &&
        component.action.type === 'url' &&
        component.action.url
          ? {
            onClick: () => {
                openLinkInNewTab(component.action && component.action.url);
              },
            }
          : {})}
        alt={''}
      />
    </div>
  );
};

export default ImageComponent;
