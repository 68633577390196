import actions from './actions';
import { keyNameChange } from '../../helpers/utility';
import * as _ from 'lodash';

export const initState = {
  fbAccountList: [],
  fbCustomAudienceList: [],
  customAudienceProcess: false,
  customAudienceVisible: false,
  fbAudienceList: [],
  selectedId: null,
  fbCustomAudienceStatus: false,
  customFieldList: [],
  teammateList: [],
  attributes: {
    emailFromUserId: undefined,
    emailReplyToUserId: undefined,
    subject: '',
    mailText: '',
    mailTemplateContent: '',
    selectedTemplateId: undefined,
  },
  templateData: [],
  messageSideBarActiveKey: [],
  supportBotSidebarActiveKey: [],
  tableState: 'chatOnGoing',
  displayOmniSearch: false,
  displaySnoozeModal: false,
  currentOmniSearchState: 'default',
  displayKeyboardShortcuts: false,
  replyEditor: null,
  noteEditor: null,
  isComposerUndo: false,
  googleFonts: [],
  previewVisible: false,
  selectedArticleLanguage: null,
};

function getTemplateContent(data, id) {
  if (id) {
    return _.find(data.gist_templates, template => template.id === id)
      ? _.find(data.gist_templates, template => template.id === id)
          .email_content
      : _.find(data.custom_templates, template => template.id === id)
          .email_content;
  } else {
    return undefined;
  }
}
export default function sharedReducers(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_GOOGLE_FONTS_SHARED_REDUCER_SUCCESS: {
      return {
        ...state,
        googleFonts: action.payload,
      };
    }
    case actions.GET_FB_AUDIENCE_LIST:
      return {
        ...state,
        fbAccountList: [],
        fbCustomAudienceList: [],
      };
    case actions.GET_FB_AUDIENCE_LIST_SUCCESS:
      const listOfFbAudience = action.payload;
      let fbAccountList = [];
      listOfFbAudience.forEach((data, index) => {
        fbAccountList.push({
          id: data.fb_ad_account_id,
          display_name: data.fb_ad_account_name,
        });
      });
      return {
        ...state,
        fbAccountList: fbAccountList,
        fbCustomAudienceList: state.fbCustomAudienceList,
        fbAudienceList: action.payload,
        fbCustomAudienceStatus: true,
      };
    case actions.GET_FB_AUDIENCE_LIST_FAILURE:
      return {
        ...state,
        fbCustomAudienceStatus: action.payload,
      };
    case actions.GET_FB_CUSTOM_AUDIENCE_LIST:
      let getCustomAudienceList = [],
        customAudienceList = [];
      getCustomAudienceList = _.find(
        state.fbAudienceList,
        data => data.fb_ad_account_id === action.payload,
      );
      customAudienceList = keyNameChange(
        getCustomAudienceList.custom_audience_list,
      );
      return {
        ...state,
        fbCustomAudienceList: customAudienceList,
        selectedId: action.payload,
      };
    case actions.CREATE_CUSTOM_AUDIENCE_VISIBLE:
      return {
        ...state,
        customAudienceVisible: action.payload,
      };
    case actions.CREATE_CUSTOM_AUDIENCE:
      return {
        ...state,
        customAudienceProcess: true,
      };
    case actions.CREATE_CUSTOM_AUDIENCE_SUCCESS:
      return {
        ...state,
        customAudienceProcess: false,
        customAudienceVisible: false,
      };
    case actions.CREATE_CUSTOM_AUDIENCE_FAILURE:
      return {
        ...state,
        customAudienceProcess: false,
      };
    case actions.RESET_AUDIENCE_LIST:
      return {
        ...state,
        selectedId: null,
      };
    case actions.SET_SETTINGS_FORM_TAB_KEY:
      return {
        ...state,
        settingsFormTabKey: action.key,
      };
    case actions.FETCH_FORM_CUSTOM_FIELD_LIST_SUCCESS:
      return {
        ...state,
        customFieldList: action.payload,
      };
    // case actions.CLEAR_FORM_CUSTOM_FIELDS: Todo: Need to reset the follow up text editor after disable this, will work on later
    //   return {
    //     ...state,
    //     customFieldList: [],
    //   };
    case actions.SET_FORM_MAIL_TEXT:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          mailText: action.payload,
        },
      };
    case actions.FETCH_FORM_TEAMMATE_LIST_SUCCESS:
      let userId = action.payload;
      return {
        ...state,
        teammateList: action.payload,
        automationMailAttributes: {
          emailFromUserId: userId.out_bound_address[0]
            ? userId.out_bound_address[0].id
            : userId.team_mates[0].id,
          emailReplyToUserId: userId.out_bound_address[0]
            ? userId.out_bound_address[0].id
            : userId.team_mates[0].id,
        },
      };
    case actions.FETCH_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        templateData: action.payload,
      };
    case actions.UPDATE_TEMPLATES_FORM_DATA:
      return {
        ...state,
        attributes: {
          emailFromUserId: action.emailFromUserId,
          emailReplyToUserId: action.emailReplyToUserId,
          subject: action.subject,
          mailText: state.attributes.mailText,
          mailTemplateContent: getTemplateContent(
            state.templateData,
            action.template,
          ),
          selectedTemplateId: action.template,
        },
      };
    case actions.SET_FORM_TEMPLATE_DETAILS_SETTINGS:
      const response = action.payload;
      return {
        ...state,
        attributes: {
          emailFromUserId: response.form_confirmation_email_from_user_id,
          emailReplyToUserId: response.form_confirmation_email_reply_to_user_id,
          subject: response.form_confirmation_email_subject,
          mailText: response.form_confirmation_email_text,
          mailTemplateContent:
            response.form_confirmation_email_template_content,
          selectedTemplateId: response.form_confirmation_email_template_id,
        },
      };
    case actions.SET_FORM_TEMPLATE_DETAILS:
      const responseData = action.payload;
      return {
        ...state,
        attributes: {
          emailFromUserId: responseData.email_from_user_id,
          emailReplyToUserId: responseData.email_reply_to_user_id,
          subject: responseData.post_confirm_subject,
          mailText: responseData.post_confirm_content,
          mailTemplateContent: responseData.post_confirm_template_content,
          selectedTemplateId: responseData.post_confirm_template_id,
        },
      };
    case actions.BEE_EDITOR_REFERENCE:
      return {
        ...state,
        beeEditorReference: action.value,
      };
    case actions.SET_MESSAGE_SIDEBAR_ACTIVE:
      return {
        ...state,
        messageSideBarActiveKey: action.key,
      };
    case actions.SET_SUPPORT_BOT_SIDEBAR_ACTIVE:
      return {
        ...state,
        supportBotSidebarActiveKey: action.key,
      };
    case actions.SET_TABLE_STATE:
      return {
        ...state,
        tableState: action.payload,
      };
    case actions.SHOW_HIDE_OMNISEARCH:
      return {
        ...state,
        displayOmniSearch: action.payload,
        currentOmniSearchState: action.payload
          ? state.currentOmniSearchState
          : 'default',
      };
    case actions.SHOW_HIDE_CUSTOM_SNOOZE_MODAL:
      return {
        ...state,
        displaySnoozeModal: action.payload,
      };
    case actions.UPDATE_OMNISEARCH_STATE:
      return {
        ...state,
        currentOmniSearchState: action.payload,
      };
    case actions.SHOW_HIDE_KEYBOARD_SHORTCUTS:
      return {
        ...state,
        displayKeyboardShortcuts: action.payload,
      };
    case actions.SET_REPLY_EDITOR: {
      return {
        ...state,
        replyEditor: { ...state.replyEditor, [action.cid]: action.payload },
      };
    }
    case actions.SET_NOTE_EDITOR:
      return {
        ...state,
        noteEditor: { ...state.noteEditor, [action.cid]: action.payload },
      };
    case actions.CHANGE_COMPOSER_UNDO_STATUS:
      return {
        ...state,
        isComposerUndo: action.payload,
      };
    case actions.VISUAL_BUILDER_PREVIEW_VISIBLE:
      return {
        ...state,
        previewVisible: action.payload,
      };
    case actions.SET_SELECTED_ARTICLE_LANGUAGE:
      return {
        ...state,
        selectedArticleLanguage: action.payload,
      };
    default:
      return state;
  }
}
