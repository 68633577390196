const actions = {
  FETCH_SURVEY_ONEOFF_LIST_SUCCESS: 'FETCH_SURVEY_ONEOFF_LIST_SUCCESS',
  APPEND_SURVEY_ONEOFF_LIST_SUCCESS: 'APPEND_SURVEY_ONEOFF_LIST_SUCCESS',
  SET_SURVEY_ONEOFF_TABLE_LOADER: 'SET_SURVEY_ONEOFF_TABLE_LOADER',
  SET_SURVEY_ONEOFF_SORT_VALUES: 'SET_SURVEY_ONEOFF_SORT_VALUES',
  SET_SURVEY_ONEOFF_SEARCH_VALUE: 'SET_SURVEY_ONEOFF_SEARCH_VALUE',
  SET_SURVEY_ONEOFF_STATUS: 'SET_SURVEY_ONEOFF_STATUS',
  SET_CREATE_SURVEY_ONEOFF: 'SET_CREATE_SURVEY_ONEOFF',
  GET_SURVEY_ONEOFF_PROMPT_DETAILS: 'GET_SURVEY_ONEOFF_PROMPT_DETAILS',
  GET_SURVEY_ONEOFF_PROMPT_DETAILS_SUCCESS: 'GET_SURVEY_ONEOFF_PROMPT_DETAILS_SUCCESS',
  GET_SURVEY_ONEOFF_PROMPT_DETAILS_FAILURE: 'GET_SURVEY_ONEOFF_PROMPT_DETAILS_FAILURE',
  SET_SURVEY_ONEOFF_TAB_KEY: 'SET_SURVEY_ONEOFF_TAB_KEY',
  SET_SURVEY_ONEOFF_TAG_ID: 'SET_SURVEY_ONEOFF_TAG_ID',
  SET_SURVEY_ONEOFF_SCHEDULE_TIME: 'SET_SURVEY_ONEOFF_SCHEDULE_TIME',
  SET_SURVEY_ONEOFF_PROMOT_DETAILS: 'SET_SURVEY_ONEOFF_PROMOTDETAILS',
  SURVEY_ONEOFF_SCHEDULE_DETAILS: 'SURVEY_ONEOFF_SCHEDULE_DETAILS',
  SURVEY_ONEOFF_SCHEDULE_DETAILS_SUCCESS: 'SURVEY_ONEOFF_SCHEDULE_DETAILS_SUCCESS',
  UPDATE_MESSAGE_SURVEY_ONEOFF: 'UPDATE_MESSAGE_SURVEY_ONEOFF',
  UPDATE_MESSAGE_SURVEY_ONEOFF_SCHEDULE_DETAILS: 'UPDATE_MESSAGE_SURVEY_ONEOFF_SCHEDULE_DETAILS',
  DELETE_SURVEY_ONEOFF_DATA: 'DELETE_SURVEY_ONEOFF_DATA',
  SET_SURVEY_ONEOFF_SELECTED_ROWS: 'SET_SURVEY_ONEOFF_SELECTED_ROWS',
  REMOVE_DELETED_ONE_OFF_SURVEY_ID: 'REMOVE_DELETED_ONE_OFF_SURVEY_ID',
};

export default actions;