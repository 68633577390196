export const meetingScheduler = ['meeting_scheduler'];

export const joinCall = ['join_call'];

export const bot = [
  'lead_bot_greeting',
  'lead_bot_question',
  'lead_bot_response',
  'lead_bot_goal_message',
  'lead_bot_user_response',
  'lead_bot_simple_message',
  'lead_bot_capture_email',
  'lead_bot_open_response_question',
  'assignment_rule_response',
  'support_bot_acknowledgement',
  'support_bot_feedback',
  'support_bot_ai_feedback',
  'ai_bot_response',
  'ai_bot_user_response',
  'ai_bot_unanswered_response',
  'ai_bot_followup_message',
  'ai_bot_followup_acknowledgement',
];

export const botAssigmentRule = ['assignment_rule', 'assignment_rule_response'];

export const spamStatusContent = {
  block_list: 'The email address, IP or domain is on blocked list.',
  role_based: 'The email is a role-based address.',
  subscription_mail: 'The email contains an unsubscribe link.',
  marketing_mail: 'The email contains marketing content.',
  automated_response: 'The email is an automated reply.',
  sender_authentication: 'The email failed sender authentication.',
  dmarc_authentication: 'The email failed DMARC authentication.',
  no_reply: 'The email is from a "noreply" address.',
  spam_detection:
    'The email was flagged as spam by our email detection filters.',
  manually_marked: 'The conversation has been marked as spam manually.',
};

export const botStatuses = [
  'bot_conversation_ended',
  'bot_goal_reached',
  'lead_bot_attribute_mapping',
  'qualification_property',
];

export const nonBotStatuses = [
  'conversation_status_update',
  'conversation_assignment',
  'assignment_rule',
  'priority',
];

export const othersType = ['getEmail', 'bot_text', 'qualification_bot'];

export const status = ['scheduled', 'rescheduled', 'cancelled'];

// image format like heic will not support by browser so consider it as file https://caniuse.com/heif
export const unSupportedImageFormat = ['image/heic'];

export const unSupportedImageType = unSupportedImageFormat.join(', ');

export const fileType = `.txt, .xlsx,.xls, .doc, .docx,.ppt, .pptx, text/csv, .csv, video/mp4, audio/mp3, .pdf, text/plain, text/xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/x-iwork-pages-sffpages, application/msword, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, ${unSupportedImageType}`;

export const imageFileType = 'image/*';

export const gifOffset = 25;

export const leadBotHtmlMessageContent = [
  'lead_bot_greeting',
  'lead_bot_question',
  'lead_bot_response',
  'lead_bot_goal_message',
  'triggered_message',
  'lead_bot_simple_message',
  'lead_bot_capture_email',
  'lead_bot_open_response_question',
  'assignment_rule_response',
  'support_bot_feedback',
  'support_bot_ai_feedback',
  'support_bot_acknowledgement',
  'ai_bot_response',
  'ai_bot_followup_message',
  'ai_bot_unanswered_response',
  'ai_bot_followup_acknowledgement',
];

export const dateFormat = 'Do MMM YYYY, hh:mm A';

export const agentMessageTypes = [null, 'note'];

export const toolbarButtons = ['bold', 'italic', 'underline', 'insertLink'];

export const pluginsEnabled = ['link', 'image'];

export const imageEditButtons = [
  'imageRemove',
  'imageLink',
  'linkEdit',
  'linkRemove',
];

export const maxAttachmentQueueSize = 10;

export const satisfactionRatings = [
  {
    val: 'terrible.png',
    unicode: '1f622',
    rating: 1,
    name: 'Terrible',
    color: '#ea6359',
  },
  {
    val: 'bad.png',
    unicode: '1f615',
    rating: 2,
    name: 'Bad',
    color: '#f3cc08',
  },
  {
    val: 'average.png',
    unicode: '1f610',
    rating: 3,
    name: 'Ok',
    color: '#f7dd5a',
  },
  {
    val: 'good.png',
    unicode: '1f603',
    rating: 4,
    name: 'Great',
    color: '#83d598',
  },
  {
    val: 'awesome.png',
    unicode: '1f60d',
    rating: 5,
    name: 'Amazing',
    color: '#4eb664',
  },
];

export const keyCode = { esc: 27, enter: 13, up: 38, down: 40 };

export const shortcutsEnabled = [
  'show',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'indent',
  'outdent',
  'undo',
  'redo',
  'insertImage',
  'insertLink',
];

export const viewOriginalMessageFromDate = '15-Sep-2022';

export const defaultColumns = [
  'contact',
  'company',
  'subject',
  'preview',
  'priority',
  'waiting_since',
  'last_updated',
  'status',
  'assign',
  'tags',
];

export const maxSourceArticleCount = 5;
