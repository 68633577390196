import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { store } from '../../../store';
import settings from '../../../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  unAuthorisedRedirection,
} from '../../../../helpers/utility';
import { message } from 'antd';

export function* fetchChatOneOffList(params) {
  try {
    let searchValue = '',
      sortKey = '',
      sortOrder = '',
      tagID = '',
      status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().ChatOneOffReducer.searchValue;
    }
    if (params && params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = store.getState().ChatOneOffReducer.sortKey;
      sortOrder = store.getState().ChatOneOffReducer.sortOrder;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = store.getState().ChatOneOffReducer.chatOneOffTagId;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().ChatOneOffReducer.chatOneOffStatus;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats?status=${status}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&message_type=one_off_chat`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CHAT_ONEOFF_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_CHAT_ONEOFF_LIST_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch one-off messages failed');
    }
  }
}

export function* appendChatOneOffList(params) {
  try {
    let searchValue = '',
      sortKey = '',
      sortOrder = '',
      tagID = '',
      status = '';
    if (params.searchValue) {
      searchValue = params.searchValue;
    } else {
      searchValue = store.getState().ChatOneOffReducer.searchValue;
    }
    if (params && params.sortKey && params.sortOrder) {
      sortKey = params.sortKey;
      sortOrder = params.sortOrder;
    } else {
      sortKey = store.getState().ChatOneOffReducer.sortKey;
      sortOrder = store.getState().ChatOneOffReducer.sortOrder;
    }
    if (params.tagId) {
      tagID = params.tagId;
    } else {
      tagID = store.getState().ChatOneOffReducer.chatOneOffTagId;
    }
    if (params.status) {
      status = params.status;
    } else {
      status = store.getState().ChatOneOffReducer.chatOneOffStatus;
    }
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/triggered_chats?status=${status}&sort_key=${sortKey}&sort_order=${sortOrder}&search_text=${searchValue}&tag_ids=${tagID}&message_type=one_off_chat&last_triggered_chat_id=${
      params.lastId
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_CHAT_ONEOFF_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.APPEND_CHAT_ONEOFF_LIST_FAILURE,
    });
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Fetch one-off messages failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CHAT_ONEOFF_LIST, fetchChatOneOffList),
    takeEvery(actions.APPEND_CHAT_ONEOFF_LIST, appendChatOneOffList),
  ]);
}
