import actions from './actions';
import {
  extractAvatarLetter,
  fetchProjectSecretKey,
  removeUnderscore,
} from '../../helpers/utility';
import { Avatar, Tooltip } from 'antd';
import ReactTimeAgo from 'react-time-ago';
import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import Moment from 'react-moment';
import { store } from '../store';
import { joinRoom } from '../../components/LiveView/helper';

export const initState = {
  liveViewData: [],
  liveViewTableLoader: false,
  liveViewTableColumns: [],
  livePeopleCount: 0,
  knownPeopleCount: 0,
  messageModalVisibility: false,
  currentPersonId: null,
  sendMessageLoading: false,
  searchText: '',
  allKeys: [
    { property_name: 'name', property_type: 'string' },
    { property_name: 'action', property_type: 'icon' },
    { property_name: 'email', property_type: 'string' },
    { property_name: 'user_type', property_type: 'string' },
    { property_name: 'current_page_title', property_type: 'string' },
    { property_name: 'current_page_path', property_type: 'string' },
    { property_name: 'time_on_site', property_type: 'date' },
    { property_name: 'location', property_type: 'string' },
    { property_name: 'original_source', property_type: 'string' },
    {
      property_name: 'no_of_conversations_initiated_from_person',
      property_type: 'number',
    },
  ],
};

function constructVisitorsTable(value, key) {
  let contentWidth = '', alignContent='';
  switch (key) {
    case 'name':
      contentWidth = '350px';
      alignContent = 'left';
      break;
    case 'action':
      contentWidth = '200px';
      alignContent = 'center';
      break;
    case 'email':
      contentWidth = '350px';
      alignContent = 'left';
      break;
    case 'user_type':
      contentWidth = '150px';
      alignContent = 'center';
      break;
    case 'current_page_title':
      contentWidth = '400px';
      alignContent = 'left';
      break;
    case 'current_page_path':
      contentWidth = '400px';
      alignContent = 'left';
      break;
    case 'time_on_site':
      contentWidth = '150px';
      alignContent = 'center';
      break;
    case 'no_of_conversations_initiated_from_person':
      contentWidth = '200px';
      alignContent = 'center';
      break;
    case 'original_source':
      contentWidth = '300px';
      alignContent = 'left';
      break;
    case 'location':
      contentWidth = '250px';
      alignContent = 'center';
      break;
    default:
      contentWidth = '400px';
      alignContent = 'left';
      break;
  }

  return {
    title: () => {
      const title = removeUnderscore(key);
      return (
        <Tooltip title={title} placement={'bottom'}>
          {title}
        </Tooltip>
      );
    },
    // width:contentWidth,
    width:contentWidth,
    align:alignContent,
    ellipsis:true,
    dataIndex: key,
    render: (data, record) => {
      if (key === 'name') {
        let name;
        let avatar = <Avatar size="medium" src={record.avatar_url} />;
        if (
          record.avatar_url === undefined ||
          record.avatar_url === null ||
          record.avatar_url.includes('assets.getgist.com/avatar')
        ) {
          avatar = (
            <div className="visitors-profile">
              {record.avatar_letter
                ? record.avatar_letter
                : extractAvatarLetter(record.full_name)}
            </div>
          );
        }
        if (record.email) {
          name = (
            <a
              href={`/projects/${fetchProjectSecretKey()}/contacts/${
                record.person_id
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data ? data : 'Site Visitor'}
            </a>
          );
        } else {
          name = data ? data : 'Site Visitor';
        }
        return (
          <div className="visitors-cell ellipsis-column">
            {avatar}
            {name}
          </div>
        );
      }
      if (key === 'action') {
        return (
          <div className="visitors-cell" style={{justifyContent:'center'}}>
            <div
              className="chat"
              onClick={() =>
                store.dispatch({
                  type: actions.OPEN_MESSAGE_MODAL,
                  payload: { personId: record.person_id },
                })
              }
            >
              <i className={'fal fa-comment-lines'} />
            </div>
          </div>
        );
      }
      if (key === 'time_on_site') {
        return (
          <div className="visitors-cells">
            {record.online_since ? (
              <Tooltip
                title={
                  <Moment
                    date={moment.unix(record.online_since)}
                    format={'MMM Do YYYY, h:mm:ss A'}
                  />
                }
                placement={'bottom'}
              >
                <ReactTimeAgo
                  title=""
                  date={record.online_since * 1000}
                  timeStyle="time"
                />
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        );
      }
      if (['current_page_path', 'original_source'].includes(key)) {
        return (
          <div className="visitors-cells">
            {data ? (
              <Tooltip title={data} placement={'bottom'}>
                {data}
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        );
      }
      if (key === 'current_page_title' && !!data && !!record.current_page_url) {
        return (
          <div className="visitors-cells">
            <Tooltip title={data} placement={'bottom'}>
              <a
                href={record.current_page_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data}
              </a>
            </Tooltip>
          </div>
        );
      }
      if (key === 'location') {
        let location;
        if (!!record.city && !!record.country) {
          location = `${record.city}, ${record.country}`;
        } else if (!!record.city) {
          location = `${record.city}`;
        } else if (!!record.country) {
          location = `${record.country}`;
        } else {
          location = '-';
        }
        return <div className="visitors-cells">{location}</div>;
      }
      if (key === 'no_of_conversations_initiated_from_person') {
        return (
          <div className="visitors-cells">
            {record.no_of_times_chat_initiated}
          </div>
        );
      }
      return <div className="visitors-cells">{data ? data : '-'}</div>;
    },
  };
}

function updateLiveViewData(state, person) {
  const liveViewData = state.liveViewData,
    index = _.findIndex(
      liveViewData,
      e => e['person_id'] === person['person_id'],
    );

  if (index >= 0) {
    liveViewData[index] = person;
  } else if (state.searchText === '') {
    liveViewData.unshift(person);
  }

  return { liveViewData };
}

function updateDisplayedLiveViewData(state, data) {
  const liveViewData = state.liveViewData;
  _.remove(liveViewData, e => e['person_id'] === data['person_id']);

  return { liveViewData };
}

export default function liveViewReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_VISITORS:
      return {
        ...state,
        liveViewTableLoader: true,
      };
    case actions.FETCH_VISITORS_SUCCESS: {
      const liveViewTableColumns = _.map(state.allKeys, value =>
        constructVisitorsTable(value.property_type, value.property_name),
      );
      joinRoom(action.payload.data.live_people);
      return {
        ...state,
        liveViewData: action.payload.data.live_people,
        liveViewTableLoader: false,
        liveViewTableColumns,
        livePeopleCount: action.payload.data.live_people_count,
        knownPeopleCount: action.payload.data.known_people_count,
      };
    }
    case actions.FETCH_VISITORS_FAILURE:
      return {
        ...state,
        liveViewTableLoader: false,
      };
    case actions.APPEND_VISITORS:
      return {
        ...state,
        liveViewTableLoader: true,
      };
    case actions.APPEND_VISITORS_SUCCESS: {
      joinRoom(action.payload.data.live_people);
      return {
        ...state,
        liveViewData: _.uniqBy(
          state.liveViewData.concat(action.payload.data.live_people),
          'person_id',
        ),
        liveViewTableLoader: false,
        livePeopleCount: action.payload.data.live_people_count,
        knownPeopleCount: action.payload.data.known_people_count,
      };
    }
    case actions.APPEND_VISITORS_FAILURE:
      return {
        ...state,
        liveViewTableLoader: false,
      };
    case actions.UPDATE_VISITORS: {
      const {
          live_person,
          live_people_count,
          known_people_count,
        } = action.payload.data,
        { livePeopleCount, knownPeopleCount } = state,
        liveView = _.cloneDeep(state),
        updatedLiveView = updateLiveViewData(liveView, live_person);
      return {
        ...state,
        livePeopleCount: live_people_count
          ? live_people_count
          : livePeopleCount,
        knownPeopleCount: known_people_count
          ? known_people_count
          : knownPeopleCount,
        liveViewData: updatedLiveView.liveViewData,
      };
    }
    case actions.PREPEND_VISITORS: {
      const {
          live_person,
          live_people_count,
          known_people_count,
        } = action.payload.data,
        { livePeopleCount, knownPeopleCount } = state;
      return {
        ...state,
        livePeopleCount: live_people_count
          ? live_people_count
          : livePeopleCount,
        knownPeopleCount: known_people_count
          ? known_people_count
          : knownPeopleCount,
        liveViewData:
          state.searchText === ''
            ? _.uniqBy([live_person].concat(state.liveViewData), 'person_id')
            : state.liveViewData,
      };
    }
    case actions.UPDATE_DISPLAYED_VISITORS_STATUS: {
      const liveView = _.cloneDeep(state),
        { live_people_count, known_people_count } = action.payload,
        updatedLiveView = updateDisplayedLiveViewData(liveView, action.payload);
      return {
        ...state,
        liveViewData: updatedLiveView.liveViewData,
        livePeopleCount: live_people_count,
        knownPeopleCount: known_people_count,
      };
    }
    case actions.OPEN_MESSAGE_MODAL:
      return {
        ...state,
        messageModalVisibility: true,
        currentPersonId: action.payload.personId,
      };
    case actions.CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        messageModalVisibility: false,
        currentPersonId: null,
      };
    case actions.SEND_MESSAGE:
      return {
        ...state,
        sendMessageLoading: true,
      };
    case actions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageModalVisibility: false,
        sendMessageLoading: false,
      };
    case actions.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        messageModalVisibility: false,
        sendMessageLoading: false,
      };
    case actions.UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    default:
      return state;
  }
}
