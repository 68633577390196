import actions from './actions';

export const initState = {
  surveyOnGoingList: [],
  selectedTableData: [],
  list: {},
  surveyOnGoingTableLoader: false,
  sortKey: 'priority',
  sortOrder: 'asc',
  searchValue: '',
  surveyOnGoingTabKey: '1',
  surveyOnGoingTagId: '',
  surveyOnGoingStatus: 'all',
  surveyOnGoingScheduleTime: false,
  surveyDetails: {},
  initialSurveyDetails: {},
  scheduleDetails: {},
  isReOrderSurvey: false,
  surveyLoader: false,
  statusLoader: false,
};
export default function surveyOnGoingReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_SURVEY_ONGOING_LIST_SUCCESS:
      return {
        ...state,
        surveyOnGoingTableLoader: false,
        surveyOnGoingList: action.payload.surveys,
        list: action.payload.surveys_count,
      };
    case actions.APPEND_SURVEY_ONGOING_LIST_SUCCESS:
      let appendTableLists = [],
        appendCounts = [];
      if (action.payload) {
        appendTableLists =
          action.payload.surveys && action.payload.surveys.length
            ? action.payload.surveys
            : [];
        appendCounts =
          action.payload.surveys_count && action.payload.surveys_count.length
            ? action.payload.surveys_count
            : state.list;
      }
      return {
        ...state,
        surveyOnGoingList: appendTableLists.length
          ? state.surveyOnGoingList.concat(appendTableLists)
          : state.surveyOnGoingList,
        list: appendCounts.length ? appendCounts : state.list,
        surveyOnGoingTableLoader: false,
      };
    case actions.SET_SURVEY_ONGOING_TABLE_LOADER:
      return {
        ...state,
        surveyOnGoingTableLoader: action.payload,
      };
    case actions.SET_SURVEY_ONGOING_SELECTED_ROWS:
      return {
        ...state,
        selectedTableData: action.selectedRowKeys,
      };
    case actions.SET_SURVEY_ONGOING_SORT_VALUES:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    case actions.SET_SURVEY_ONGOING_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.SET_SURVEY_ONGOING_STATUS:
      return {
        ...state,
        surveyOnGoingStatus: action.payload,
      };
    case actions.GET_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        surveyDetails: action.payload,
        initialSurveyDetails: action.payload,
        surveyOnGoingScheduleTime:
          action.payload &&
          action.payload.schedule_details &&
          Object.keys(action.payload.schedule_details).length
            ? true
            : false,
        scheduleDetails:
          action.payload && action.payload.schedule_details
            ? action.payload.schedule_details
            : {},
        surveyOnGoingTabKey: action.surveyTabKey
          ? action.surveyTabKey
          : state.surveyOnGoingTabKey,
      };
    case actions.SET_SURVEY_ONGOING_TAB_KEY:
      return {
        ...state,
        surveyOnGoingTabKey: action.payload,
      };
    case actions.SET_SURVEY_ONGOING_TAG_ID:
      return {
        ...state,
        surveyOnGoingTagId: action.payload,
      };
    case actions.SET_SURVEY_ONGOING_SCHEDULE_TIME:
      return {
        ...state,
        surveyOnGoingScheduleTime: action.payload,
      };
    case actions.SURVEY_ONGOING_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: action.payload,
      };
    case actions.SURVEY_ONGOING_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
      };
    case actions.REORDER_SURVEYS_LIST:
      return {
        ...state,
        isReOrderSurvey: action.payload,
      };
    case actions.SET_SURVEY_LOADER:
      return {
        ...state,
        surveyLoader: action.payload,
      };
    case actions.SET_SURVEY_TABLE_DATA:
      return {
        ...state,
        surveyOnGoingList: action.payload,
      };
    case actions.UPDATE_SURVEY_DEADLINE_DETAILS:
      let updateSurveyDetails = {
        ...state.surveyDetails,
        ...action.payload,
      };
      return {
        ...state,
        surveyDetails: updateSurveyDetails,
      };
    case actions.SET_SURVEY_NAME: {
      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          name: action.payload,
        },
      };
    }
    case actions.UPDATE_SURVEY_STATUS: {
      return {
        ...state,
        statusLoader: true,
        surveyDetails: {
          ...state.surveyDetails,
          status:
            state.surveyDetails.has_schedule &&
            action.payload.survey.status === 'live'
              ? 'scheduled'
              : action.payload.survey.status,
        },
      };
    }
    case actions.UPDATE_SURVEY_STATUS_SUCCESS: {
      return {
        ...state,
        statusLoader: false,
      };
    }
    case actions.UPDATE_SURVEY_STATUS_FAILURE: {
      return {
        ...state,
        statusLoader: false,
        surveyDetails: {
          ...state.surveyDetails,
          status: action.payload === 'live' ? 'paused' : 'live',
        },
      };
    }
    case actions.UPDATE_SCHEDULE_DETAILS: {
      return {
        ...state,
        statusLoader: false,
        surveyDetails: {
          ...state.surveyDetails,
          ...action.payload,
        },
      };
    }
    case actions.UPDATE_SURVEY_DETAILS:
      return {
        ...state,
        surveyDetails: action.payload,
        initialSurveyDetails: action.payload,
      };
    case actions.REMOVE_DELETED_ON_GOING_SURVEY_ID:
      let selectedRowKeys = state.selectedTableData;
      if (selectedRowKeys && selectedRowKeys.length) {
        selectedRowKeys = selectedRowKeys.filter(
          id => id !== action.deletedSurveyId,
        );
      }
      return {
        ...state,
        selectedTableData: selectedRowKeys
          ? selectedRowKeys
          : state.selectedTableData,
      };
    default:
      return state;
  }
}
