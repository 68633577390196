import React from 'react';
import { InputTextArea } from '../Input';
import { ActionButtons } from '../ActionButtons';
import { BaseEditableComponent } from '../BaseComponent';

export class EmailCapture extends BaseEditableComponent {
  render() {
    const { data } = this.state;
    return (
      <div className="bot-component">
        <ActionButtons
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className="header">
          <h3>
            <span>
              <i className="fas fa-envelope" />
            </span>
            Ask for email
          </h3>
        </div>
        <div className="body">
          {data.messages &&
            data.messages.map((item, key) => (
              <div className="message-text-component" key={key}>
                <InputTextArea value={item.text} />
              </div>
            ))}
          <div className="buttons-list">
            {data.buttons.map((item, key) => (
              <div
                key={key}
                className="button-component"
                data-port-id={item.id}
              >
                <span
                  className={
                    item.response_type === 'valid_email'
                      ? 'outline-btn primary-btn'
                      : item.response_type === 'invalid_email'
                        ? 'outline-btn danger-btn'
                        : 'default-btn'
                  }
                >
                  {item.message}
                </span>
                <jtk-source port-id={item.id} endpoint={true} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
