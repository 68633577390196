import actions from './actions';

let initState = {
  templateList: [],
  isTemplateTableLoading: true,
  templateStatusList: [],
  searchValue: '',
  selectedTemplateStatus: 'all',
  categoryList: [],
  workflowTemplateID: '',
  statusOfWorkflow: '',
  isDisable: false,
  workflowTemplateDetails: {},
  loader: {
    workflowTemplateCategory: false,
    createTemplateLoading: false,
  },
  workflowTemplateNameSet: '',
  description: '',
  duplicateId: '',
  categoryId: undefined,
  defaultImage: '',
  filterCategoryId: '',
  templateSearchValue: '',
};

export default function workflowTemplateReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_WORKFLOW_TEMPLATE_LIST:
      return {
        ...state,
        isTemplateTableLoading: true,
      };
    case actions.FETCH_WORKFLOW_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        workflowTemplateList: action.payload.workflow_templates,
        templateStatusList:
          action.payload.workflow_templates_status || state.templateStatusList,
        isTemplateTableLoading: false,
        defaultImage: action.payload.build_from_scratch_overview,
      };
    case actions.FETCH_WORKFLOW_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        isTemplateTableLoading: false,
      };
    case actions.SET_CREATE_WORKFLOW_TEMPLATE_DRAWER_STATUS:
      let data = action.object;
      return {
        ...state,
        isDrawerOpen: action.payload,
        workflowTemplateNameSet: data ? `${data.name}(Copy)` : '',
        duplicateId: data ? data.id : '',
        description: data ? data.description : '',
        categoryId: data ? data.workflow_template_category_id : undefined,
      };
    case actions.CREATE_WORKFLOW_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.CREATE_WORKFLOW_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.CREATE_WORKFLOW_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.DUPLICATE_WORKFLOW_TEMPLATE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: true,
        },
      };
    case actions.DUPLICATE_WORKFLOW_TEMPLATE_SUCCESS:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: false,
      };
    case actions.DUPLICATE_WORKFLOW_TEMPLATE_FAILURE:
      return {
        ...state,
        loader: {
          ...state.loader,
          createTemplateLoading: false,
        },
        isDrawerOpen: true,
      };
    case actions.GET_CATEGORIES_LISTS_SUCCESS:
      return {
        ...state,
        categoryList: action.payload.workflow_template_categories,
      };
    case actions.SET_WORKFLOW_TEMPLATE_STATUS:
      return {
        ...state,
        selectedTemplateStatus: action.status,
      };
    case actions.SET_WORKFLOW_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.SET_WORKFLOW_TEMPLATE_ID:
      return {
        ...state,
        workflowTemplateID: action.id,
      };
    case actions.APPEND_WORKFLOW_LIST:
      return {
        ...state,
        isTemplateTableLoading: true,
      };
    case actions.APPEND_WORKFLOW_LIST_SUCCESS:
      return {
        ...state,
        workflowTemplateList: state.workflowTemplateList.concat(
          action.payload.workflow_templates,
        ),
        isTemplateTableLoading: false,
      };
    case actions.SET_WORKFLOW_OR_WORKFLOW_TEMPLATE:
      return {
        ...state,
        statusOfWorkflow: action.payload,
      };
    case actions.DELETE_WORKFLOW_TEMPLATE:
      return {
        ...state,
        isDisable: true,
      };
    case actions.DELETE_WORKFLOW_TEMPLATE_SUCCESS:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.DELETE_WORKFLOW_TEMPLATE_FAILURE:
      return {
        ...state,
        isDisable: action.payload,
      };
    case actions.CREATE_WORKFLOW_TEMPLATE_DETAILS:
      return {
        ...state,
        workflowTemplateDetails: action.payload.details,
      };
    case actions.APPEND_WORKFLOW_TEMPLATE_LIST:
      return {
        ...state,
        isTemplateTableLoading: true,
      };
    case actions.APPEND_WORKFLOW_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        workflowTemplateList: state.workflowTemplateList.concat(
          action.payload.workflow_templates,
        ),
        isTemplateTableLoading: false,
      };
    case actions.APPEND_WORKFLOW_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        isTemplateTableLoading: false,
      };
    case actions.SET_LOADER:
      return {
        ...state,
        loader: {
          ...state.loader,
          ...action.payload,
        },
      };
    case actions.SET_FILTER_CATEGORY_ID:
      return {
        ...state,
        filterCategoryId: action.payload,
      };
    case actions.SET_CHOOSE_WORKFLOW_TEMPLATE_SEARCH_VALUE:
      return {
        ...state,
        templateSearchValue: action.payload,
      };
    default:
      return state;
  }
}
