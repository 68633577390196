const actions = {
  SET_BROADCAST_EMAIL_SELECTED_ROW_KEYS:
    'SET_BROADCAST_EMAIL_SELECTED_ROW_KEYS',
  EDIT_BROADCAST: 'EDIT_BROADCAST',
  FETCH_BROADCAST: 'FETCH_BROADCAST',
  FETCH_BROADCAST_SUCCESS: 'FETCH_BROADCAST_SUCCESS',
  SET_BROADCAST_STATUS: 'SET_BROADCAST_STATUS',
  SET_BROADCAST_SEARCH_VALUE: 'SET_BROADCAST_SEARCH_VALUE',
  FETCH_TEMPLATES: 'FETCH_TEMPLATES',
  FETCH_TEMPLATES_SUCCESS: 'FETCH_TEMPLATES_SUCCESS',
  SET_BROADCAST_NAME: 'SET_BROADCAST_NAME',
  SET_SELECTED_TEMPLATE_ID: 'SET_SELECTED_TEMPLATE_ID',
  CREATE_BROADCAST: 'CREATE_BROADCAST',
  CREATE_BROADCAST_SUCCESS: 'CREATE_BROADCAST_SUCCESS',
  CREATE_BROADCAST_FAILURE: 'CREATE_BROADCAST_FAILURE',
  FETCH_BROADCAST_DATA: 'FETCH_BROADCAST_DATA',
  FETCH_BROADCAST_DATA_SUCCESS: 'FETCH_BROADCAST_DATA_SUCCESS',
  SET_BROADCAST_EMAIL_TEXT: 'SET_BROADCAST_EMAIL_TEXT',
  SEND_BROADCAST_TEST_EMAIL: 'SEND_BROADCAST_TEST_EMAIL',
  SEND_BROADCAST_TEST_EMAIL_SUCCESS: 'SEND_BROADCAST_TEST_EMAIL_SUCCESS',
  TEST_EMAIL_MODAL_VISIBLE: 'TEST_EMAIL_MODAL_VISIBLE',
  SEND_BROADCAST_TEST_EMAIL_FAILURE: 'SEND_BROADCAST_TEST_EMAIL_FAILURE',
  CREATE_BROADCAST_COMPOSER: 'CREATE_BROADCAST_COMPOSER',
  CREATE_BROADCAST_COMPOSER_SUCCESS: 'CREATE_BROADCAST_COMPOSER_SUCCESS',
  CREATE_BROADCAST_COMPOSER_FAILURE: 'CREATE_BROADCAST_COMPOSER_FAILURE',
  SET_BROADCAST_SORT_KEY_AND_VALUE: 'SET_BROADCAST_SORT_KEY_AND_VALUE',
  SCHEDULE_BROADCAST: 'SCHEDULE_BROADCAST',
  SET_BROADCAST_ID: 'SET_BROADCAST_ID',
  DELETE_BROADCAST: 'DELETE_BROADCAST',
  SET_FROM_EMAIL_USER_ID: 'SET_FROM_EMAIL_USER_ID',
  SET_TO_EMAIL_USER_ID: 'SET_TO_EMAIL_USER_ID',
  CREATE_BROADCAST_SETTINGS: 'CREATE_BROADCAST_SETTINGS',
  OPEN_CREATE_BROADCAST_DRAWER: 'OPEN_CREATE_BROADCAST_DRAWER',
  CLOSE_CREATE_BROADCAST_DRAWER: 'CLOSE_CREATE_BROADCAST_DRAWER',
  SET_DUPLICATE_BROADCAST_DATA: 'SET_DUPLICATE_BROADCAST_DATA',
  CREATE_DUPLICATE_BROADCAST: 'CREATE_DUPLICATE_BROADCAST',
  SET_BROADCAST_EMAIL_SUBJECT: 'SET_BROADCAST_EMAIL_SUBJECT',
  OPEN_BROADCAST_TEMPLATE_DRAWER: 'OPEN_BROADCAST_TEMPLATE_DRAWER',
  SET_BROADCAST_TAB_KEY: 'SET_BROADCAST_TAB_KEY',
  SET_DUPLICATE_BROADCAST_NAME: 'SET_DUPLICATE_BROADCAST_NAME',
  SET_PRE_HEADER_TEXT: 'SET_PRE_HEADER_TEXT',
  SET_BROADCAST_LAST_SAVE_TIME: 'SET_BROADCAST_LAST_SAVE_TIME',
  RESET_EMAIL_COMPOSER_FORM: 'RESET_EMAIL_COMPOSER_FORM',
  CREATE_RECIPIENTS: 'CREATE_RECIPIENTS',
  BROADCAST_FETCH_RECIPIENTS_SUCCESS: 'BROADCAST_FETCH_RECIPIENTS_SUCCESS',
  BROADCAST_FETCH_RECIPIENTS_FAILURE: 'BROADCAST_FETCH_RECIPIENTS_FAILURE',
  APPEND_BROADCAST: 'APPEND_BROADCAST',
  APPEND_BROADCAST_SUCCESS: 'APPEND_BROADCAST_SUCCESS',
  SET_BROADCAST_FILTER_OPTION: 'SET_BROADCAST_FILTER_OPTION',
  BROADCAST_SET_RECIPIENTS_LOADER: 'BROADCAST_SET_RECIPIENTS_LOADER',
  BROADCAST_FILTER_VALIDATION: 'BROADCAST_FILTER_VALIDATION',
  BROADCAST_RECIPIENTS_CREATE_SUCCESS: 'BROADCAST_RECIPIENTS_CREATE_SUCCESS',
  BROADCAST_RECIPIENTS_CREATE_FAILURE: 'BROADCAST_RECIPIENTS_CREATE_FAILURE',
  BROADCAST_GET_AUDIENCE_COUNT: 'BROADCAST_GET_AUDIENCE_COUNT',
  SET_BROADCAST_TEMPLATE_TAB_KEY: 'SET_BROADCAST_TEMPLATE_TAB_KEY',
  CLEAR_BROADCAST_CUSTOM_FIELDS: 'CLEAR_BROADCAST_CUSTOM_FIELDS',
  DELETE_BROADCAST_SUCCESS: 'DELETE_BROADCAST_SUCCESS',
  EMAIL_UPDATE_SUNSET_POLICY: 'EMAIL_UPDATE_SUNSET_POLICY',
  DELETE_BROADCAST_EMAIL_FOLDER_LOADING:
    'DELETE_BROADCAST_EMAIL_FOLDER_LOADING',
  FETCH_VISUAL_BUILDER_JSON: 'FETCH_VISUAL_BUILDER_JSON',
  FETCH_VISUAL_BUILDER_JSON_SUCCESS: 'FETCH_VISUAL_BUILDER_JSON_SUCCESS',
  SET_COMPOSER_VALUE_CHANGE: 'SET_COMPOSER_VALUE_CHANGE',
  UPDATE_VISUAL_BUILDER_JSON: 'UPDATE_VISUAL_BUILDER_JSON',
  FETCH_BEE_TOKEN: 'FETCH_BEE_TOKEN',
  FETCH_BEE_TOKEN_SUCCESS: 'FETCH_BEE_TOKEN_SUCCESS',
  SET_BROADCAST_EMAIL_SUBJECT_VALIDATION:
    'SET_BROADCAST_EMAIL_SUBJECT_VALIDATION',
  BROADCAST_CANCEL_SCHEDULE_SUCCESS: 'BROADCAST_CANCEL_SCHEDULE_SUCCESS',
  SET_BROADCAST_TAG_ID: 'SET_BROADCAST_TAG_ID',
  STOP_BROADCAST: 'STOP_BROADCAST',
  STOP_BROADCAST_SUCCESS: 'STOP_BROADCAST_SUCCESS',
  STOP_BROADCAST_FAILURE: 'STOP_BROADCAST_FAILURE',
  SET_BROADCAST_POSTAL_ADDRESS: 'SET_BROADCAST_POSTAL_ADDRESS',
  SET_BROADCAST_EMAIL_LOADING_INDICATOR:
    'SET_BROADCAST_EMAIL_LOADING_INDICATOR',
  CREATE_BROADCAST_SCHEDULE: 'CREATE_BROADCAST_SCHEDULE',
  SET_EMAIL_TEMPLATES_DETAILS: 'SET_EMAIL_TEMPLATES_DETAILS',
};

export default actions;
