import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { extractCurrentUserId } from '../../../helpers/utility';
import sharedActions from '../../../redux/sharedReducers/actions';
import chatActions from '../../../redux/chat/actions';
import { bindActionCreators } from 'redux';
import JunglePicker from './JunglePicker';

const MeetingWidget = props => {
  const dispatch = useDispatch();
  const { removeOmniSearchListener, omniSearchEventListener } = props;
  const [selectedTeammate, setSelectedTeammate] = useState({});
  const meetings = useSelector(({ Chat }) => Chat.meetingSchedulerList),
    meetingLinks = useSelector(({ Chat }) => Chat.agentTimingList),
    meetingAgentId = useSelector(({ Chat }) => Chat.meetingAgentId);
  const boundActionCreators = bindActionCreators(chatActions, dispatch);

  useEffect(() => {
    omniSearchEventListener();

    return () => {
      removeOmniSearchListener();
    };
  }, []);

  const getCommandItem = item => {
    if (Object.keys(item).includes('meeting_link_url')) {
      return (
        <div className="command-item">
          <div className="command-item-inner">
            <span className="command-content">
              <i className="fal fa-calendar-alt" />
              <span className="command-title">{item.name}</span>
              <span className="custom-text">{item.meeting_link_url}</span>
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="command-item">
          <div className="command-item-inner">
            <span className="command-content">
              <img src={item.avatar_url} className="avatar" alt={'user'} />
              <span className="command-title">{item.name}</span>
            </span>
          </div>
        </div>
      );
    }
  };

  const handleTeammateSelect = item => {
    if (Object.keys(item).includes('meeting_link_url')) {
      let scheduleData = {
        meeting_link_id: parseInt(item.id),
        user_id: extractCurrentUserId(),
        meeting_agent_id: meetingAgentId,
      };
      boundActionCreators.scheduleMeeting(scheduleData);
      dispatch({
        type: sharedActions.SHOW_HIDE_OMNISEARCH,
        payload: false,
      });
    } else {
      dispatch({ type: chatActions.FETCH_AGENT_TIMINGS, id: item.id });
      setSelectedTeammate(item);
    }
  };
  const baseJungleSelectProps = {
    searchable: true,
    searchableAttributes: ['name'],
    listWrapper: list => <div className="command-list">{list}</div>,
    onChange: value => handleTeammateSelect(value),
    mode: 'list',
    selectFirstItem: true,
    autofocus: true,
    placeholder: 'Search meeting links..',
  };
  const meetingLinksSelectedProps = {
    items: meetingLinks,
    renderItem: item => getCommandItem(item),
  };
  const meetingsSelectedProps = {
    items: meetings,
    renderItem: item => getCommandItem(item),
  };
  return (
    <>
      {selectedTeammate.id && (
        <JunglePicker
          jungleSelectProps={meetingLinksSelectedProps}
          baseJungleSelectProps={baseJungleSelectProps}
          omniSearchEventListener={omniSearchEventListener}
          removeOmniSearchListener={removeOmniSearchListener}
        />
      )}
      {!selectedTeammate.id && (
        <JunglePicker
          jungleSelectProps={meetingsSelectedProps}
          baseJungleSelectProps={baseJungleSelectProps}
          omniSearchEventListener={omniSearchEventListener}
          removeOmniSearchListener={removeOmniSearchListener}
        />
      )}
    </>
  );
};
export default MeetingWidget;
