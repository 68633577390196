import actions from './actions';

export const initState = {
  sortKey: 'name',
  sortOrder: 'asc',
  searchValue: '',
  chatOneOffTagId: '',
  chatOneOffStatus: 'all',
  chatOneOffList: [],
  chatOneOffScheduleTime: false,
  chatOneOffPromptDetails: {},
  chatOneOffScheduleDetails: {},
  pageLoader: false,
  list: [],
  chatOneOffSelectedRowKeys: [],
};

export default function ChatOneOffPost(state = initState, action) {
  switch (action.type) {
    case actions.SET_CHAT_ONEOFF_SORT_VALUES_IN_POST:
      return {
        ...state,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
      };
    case actions.SET_CHAT_ONEOFF_SEARCH_VALUE_IN_POST:
      return {
        ...state,
        searchValue: action.payload,
      };
    case actions.SET_CHAT_ONEOFF_STATUS_IN_POST:
      return {
        ...state,
        chatOneOffStatus: action.payload,
      };
    case actions.SET_CHAT_ONEOFF_TAG_ID_IN_POST:
      return {
        ...state,
        chatOneOffTagId: action.payload,
      };
    case actions.FETCH_CHAT_ONEOFF_LIST_IN_POST:
      return {
        ...state,
        pageLoader: true,
      };
    case actions.FETCH_CHAT_ONEOFF_LIST_SUCCESS_IN_POST:
      return {
        ...state,
        chatOneOffList:
          action.payload &&
          action.payload.triggered_chats &&
          action.payload.triggered_chats.length
            ? action.payload.triggered_chats
            : [],
        list:
          action.payload &&
          action.payload.triggered_chats_count &&
          action.payload.triggered_chats_count.length
            ? action.payload.triggered_chats_count
            : [],
        pageLoader: false,
      };
    case actions.FETCH_CHAT_ONEOFF_LIST_FAILURE_IN_POST:
      return {
        ...state,
        pageLoader: false,
      };
    case actions.APPEND_CHAT_ONEOFF_LIST_IN_POST:
      return {
        ...state,
        pageLoader: true,
      };
    case actions.APPEND_CHAT_ONEOFF_LIST_SUCCESS_IN_POST:
      let appendTableLists = [],
        appendCounts = [];
      if (action.payload) {
        appendTableLists =
          action.payload.triggered_chats &&
          action.payload.triggered_chats.length
            ? action.payload.triggered_chats
            : [];
        appendCounts =
          action.payload.triggered_chats_count &&
          action.payload.triggered_chats_count.length
            ? action.payload.triggered_chats_count
            : state.list;
      }
      return {
        ...state,
        chatOneOffList: appendTableLists.length ? state.chatOneOffList.concat(appendTableLists) : state.chatOneOffList,
        list: appendCounts.length ? appendCounts : state.list,
        pageLoader: false,
      };
    case actions.APPEND_CHAT_ONEOFF_LIST_FAILURE_IN_POST:
      return {
        ...state,
        pageLoader: false,
      };
    case actions.SET_CHAT_ONEOFF_SELECTED_ROWS_IN_POST:
      return {
        ...state,
        chatOneOffSelectedRowKeys: action.selectedRowKeys,
      };
    case actions.REMOVE_DELETED_ONE_OFF_CHAT_ID_IN_POST:
      let selectedRowKeys = [];
      if (
        state.chatOneOffSelectedRowKeys &&
        state.chatOneOffSelectedRowKeys.length
      ) {
        selectedRowKeys = state.chatOneOffSelectedRowKeys.filter(
          id => id !== action.deletedChatId,
        );
      }
      return {
        ...state,
        chatOneOffSelectedRowKeys: selectedRowKeys
          ? selectedRowKeys
          : state.chatOneOffSelectedRowKeys,
      };
    default:
      return state;
  }
}
