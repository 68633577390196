import actions from './actions';
import { cloneDeep } from 'lodash';

const initState = {
  dealDetails: null,
  dealLoading: false,
  activitiesLoading: false,
  activitiesAppendLoading: false,
  dealActivities: [],
  contacts: [],
  contactLoader: false,
  contactDrawerStatus: false,
  dealProperties: null,
  dealNonEditableKeys: [],
  isOpenDealDrawer: false,
  isFetchingdealNotes: false,
  dealNotes: [],
  noteAddLoading: false,
  dealMeetingList: [],
  dealMeetingLoader: false,
  dealMeetingModalStatus: false,
  dealMeetingModalLoader: false,
  teammateList: [],
  mailList: [],
  emailLoader: false,
  emailListLoader: false,
  activitiesFilter: [],
  dealEmailModalStatus: false,
  dealCompanyDrawerStatus: false,
  modalDealId: null,
};

export default function CRMDeal(state = initState, action) {
  switch (action.type) {
    case actions.GET_DEAL_DETAILS:
      return {
        ...state,
        dealLoading: true,
      };
    case actions.GET_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        dealDetails: action.payload,
        dealLoading: false,
      };
    case actions.GET_DEAL_DETAILS_FAILURE:
      return {
        ...state,
        dealLoading: false,
      };
    case actions.GET_DEAL_ACTIVITIES:
      return {
        ...state,
        activitiesLoading: true,
      };
    case actions.GET_DEAL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        dealActivities: action.payload,
        activitiesLoading: false,
      };
    case actions.GET_DEAL_ACTIVITIES_FAILURE:
      return {
        ...state,
        activitiesLoading: false,
      };
    case actions.APPEND_DEAL_ACTIVITIES:
      return {
        ...state,
        activitiesAppendLoading: true,
      };
    case actions.APPEND_DEAL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        dealActivities: [...state.dealActivities, ...action.payload],
        activitiesAppendLoading: false,
      };
    case actions.APPEND_DEAL_ACTIVITIES_FAILURE:
      return {
        ...state,
        activitiesAppendLoading: false,
      };
    case actions.FETCH_CONTACTS_LIST_SUCCESS:
      return {
        ...state,
        contacts: action.payload ? action.payload : state.contacts,
      };
    case actions.DEAL_REMOVE_CONTACT:
      return {
        ...state,
        contactLoader: true,
      };
    case actions.DEAL_REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        dealDetails: {
          ...state.dealDetails,
          associated_contacts: state.dealDetails.associated_contacts.filter(
            data => data.id !== action.contactID,
          ),
        },
        contactLoader: false,
      };
    case actions.DEAL_REMOVE_CONTACT_FAILURE:
      return {
        ...state,
        contactLoader: false,
      };
    case actions.DEAL_ADD_CONTACT:
      return {
        ...state,
        contactLoader: true,
      };
    case actions.DEAL_CONTACT_DRAWER_STATUS:
      return {
        ...state,
        contactDrawerStatus: action.payload,
      };
    case actions.DEAL_ADD_CONTACT_SUCCESS:
      return {
        ...state,
        dealDetails: {
          ...state.dealDetails,
          associated_contacts: action.payload,
        },
        contactLoader: false,
        contactDrawerStatus: false,
      };
    case actions.DEAL_ADD_CONTACT_FAILURE:
      return {
        ...state,
        contactLoader: false,
        contactDrawerStatus: true,
      };
    case actions.GET_DEAL_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        dealProperties: action.payload,
        dealNonEditableKeys: action.payload.non_editable_properties.map(
          data => data.name,
        ),
      };
    case actions.SET_DEAL_DRAWER_STATUS:
      return {
        ...state,
        isOpenDealDrawer: action.payload,
      };
    case actions.FETCH_DEALS_NOTES_SUCCESS:
      return {
        ...state,
        dealNotes: action.payload,
        isFetchingdealNotes: false,
      };
    case actions.ACTIVATE_DEALS_NOTES_LOADER:
      return {
        ...state,
        isFetchingdealNotes: true,
      };
    case actions.CREATE_DEALS_NOTES:
      return {
        ...state,
        noteAddLoading: true,
      };
    case actions.CREATE_DEAL_NOTES_SUCCESS:
      let dealNotes = cloneDeep(state.dealNotes);
      dealNotes.unshift(action.payload);
      return {
        ...state,
        dealNotes: dealNotes,
        noteAddLoading: false,
      };
    case actions.FETCH_DEALS_NOTES_FAILURE:
      return {
        ...state,
        isFetchingdealNotes: false,
      };
    case actions.CREATE_DEAL_NOTES_FAILURE:
      return {
        ...state,
        noteAddLoading: false,
      };
    case actions.EDIT_DEAL_NOTES_SUCCESS:
      let updateDealNotes = state.dealNotes.map(note => {
        if (note.id === action.payload.id) {
          note = action.payload;
        }
        return note;
      });
      return {
        ...state,
        dealNotes: updateDealNotes,
      };
    case actions.DELETE_DEAL_NOTES_SUCCESS:
      return {
        ...state,
        dealNotes: state.dealNotes.filter(note => note.id !== action.noteID),
      };
    case actions.GET_DEAL_MEETINGS:
      return {
        ...state,
        dealMeetingLoader: true,
      };
    case actions.GET_DEAL_MEETINGS_SUCCESS:
      return {
        ...state,
        dealMeetingList: action.payload,
        dealMeetingLoader: false,
      };
    case actions.GET_DEAL_MEETINGS_FAILURE:
      return {
        ...state,
        dealMeetingLoader: false,
      };
    case actions.DEAL_MEETING_MODAL_STATUS: {
      return {
        ...state,
        dealMeetingModalStatus: action.payload,
      };
    }
    case actions.ADD_DEAL_MEETING:
      return {
        ...state,
        dealMeetingModalLoader: true,
      };
    case actions.ADD_DEAL_MEETING_SUCCESS:
      const meetingList = cloneDeep(state.dealMeetingList);
      meetingList.unshift(action.payload);
      return {
        ...state,
        dealMeetingList: meetingList,
        dealMeetingModalLoader: false,
        dealMeetingModalStatus: false,
      };
    case actions.ADD_DEAL_MEETING_FAILURE:
      return {
        ...state,
        dealMeetingModalLoader: false,
      };
    case actions.CANCEL_DEAL_MEETING:
      return {
        ...state,
        dealMeetingModalLoader: true,
      };
    case actions.CANCEL_DEAL_MEETING_SUCCESS:
      return {
        ...state,
        dealMeetingList: state.dealMeetingList.filter(
          data => data.id !== action.meetingID,
        ),
        dealMeetingModalLoader: false,
      };
    case actions.CANCEL_DEAL_MEETING_FAILURE:
      return {
        ...state,
        dealMeetingModalLoader: false,
      };
    case actions.GET_DEAL_TEAMMATE_LIST_SUCCESS:
      return {
        ...state,
        teammateList: action.payload,
      };
    case actions.GET_DEAL_EMAIL_LIST:
      return {
        ...state,
        emailListLoader: true,
      };
    case actions.GET_DEAL_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        mailList: action.payload,
        emailListLoader: false,
      };
    case actions.GET_DEAL_EMAIL_LIST_FAILURE:
      return {
        ...state,
        emailListLoader: false,
      };
    case actions.CREATE_DEAL_EMAIL:
      return {
        ...state,
        emailLoader: true,
      };
    case actions.DEAL_EMAIL_MODEL_STATUS:
      return {
        ...state,
        dealEmailModalStatus: action.payload,
      };
    case actions.CREATE_DEAL_EMAIL_SUCCESS:
      let email = cloneDeep(state.mailList);
      email.unshift(action.payload);
      return {
        ...state,
        mailList: email,
        emailLoader: false,
        dealEmailModalStatus: false,
      };
    case actions.CREATE_DEAL_EMAIL_FAILURE:
      return {
        ...state,
        emailLoader: false,
      };
    case actions.SET_DEAL_ACTIVITIES_FILTER:
      return {
        ...state,
        activitiesFilter: action.payload,
      };
    case actions.ADD_DEAL_ACTIVITIES:
      const activities = cloneDeep(state.dealActivities);
      activities.unshift(action.payload);
      return {
        ...state,
        dealActivities: activities,
      };
    case actions.SET_DEAL_COMPANY_DRAWER_STATUS:
      return {
        ...state,
        dealCompanyDrawerStatus: action.payload,
      };
    case actions.UPDATE_MODAL_DEAL_ID:
      return {
        ...state,
        modalDealId: action.payload.deal_id,
      };
    case actions.RESET_MODAL_DEAL_ID:
      return {
        ...state,
        modalDealId: null,
      };
    default: {
      return state;
    }
  }
}
