import actions from './actions';
import authActions from '../auth/actions';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import settings from '../../settings';
import { patchPromise, unAuthorisedRedirection } from '../../helpers/utility';
import { message } from 'antd';

export function* updateProfileDetails(params) {
  try {
    let url = `${settings.ROOT_URL}users/profile`;
    let data = yield call(() => patchPromise(url, params.object));
    message.success(data.message);
    yield put({
      type: authActions.UPDATE_CHANGED_PROFILE_DETAILS,
      payload: params.object.user,
    });
    yield put({ type: actions.SET_DISABLE_PROFILE_LOADER });
  } catch (error) {
    yield put({ type: actions.SET_DISABLE_PROFILE_LOADER });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Profile update failed');
    }
  }
}

export function* updateNewPassword(params) {
  try {
    let url = `${settings.ROOT_URL}users/profile/update_password`;
    let data = yield call(() => patchPromise(url, params.values));
    message.success(data.message);
    yield put({ type: actions.SET_DISABLE_PROFILE_LOADER });
  } catch (error) {
    yield put({ type: actions.SET_DISABLE_PROFILE_LOADER });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.errors);
    } else {
      message.error('Password update failed');
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPDATE_PROFILE_DETAILS, updateProfileDetails)]);
  yield all([takeEvery(actions.UPDATE_NEW_PASSWORD, updateNewPassword)]);
}
