import React, { Component, Fragment } from 'react';
import { Icon, Input } from 'antd';
import {
  shortcutsList,
  shortcutsListGroups,
} from '../../containers/App/constants';
import { searchIcon } from '../../redux/constants';

class KeyboardShortcuts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
  }

  searchShortcuts = event => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  checkToClose = event => {
    if (event.keyCode === 27) {
      this.props.handleKeyboardShortcutsDisplay(false);
    }
  };

  render() {
    const result = () => {
      if (this.state.searchTerm) {
        var item = shortcutsList.filter(shortcutItems =>
          shortcutItems.name
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase()),
        );
        if (item.length === 0) {
          return true;
        }
      }
      return false;
    };
    return (
      <>
        <div
          className="keyboard-shortcuts-overlay"
          onClick={() => this.props.handleKeyboardShortcutsDisplay(false)}
        />
        <div className="keyboard-shortcuts-component">
          <div>
            <div className="keyboard-shortcuts-container">
              <div className="header">
                <span className="heading">Keyboard Shortcuts</span>
                <Icon
                  type="close-circle"
                  theme="filled"
                  className="delete-icon"
                  onClick={() =>
                    this.props.handleKeyboardShortcutsDisplay(false)
                  }
                />
              </div>

              <div className="search-container">
                <div className="search active">
                  <Input
                    placeholder="Search shortcuts..."
                    onChange={this.searchShortcuts}
                    onKeyDown={this.checkToClose}
                    suffix={searchIcon}
                    ref={input => (this.input = input) && input.focus()}
                  />
                </div>
              </div>

              <div className="keyboard-shortcuts-list">
                {shortcutsListGroups.map((shortcutGroup, groupIndex) => (
                  <div
                    className="keyboard-shortcuts-list-block"
                    key={groupIndex}
                  >
                    {shortcutsList
                      .filter(
                        shortcutItem => shortcutItem.shortcut_key.length > 0,
                      )
                      .filter(
                        shortcutItem =>
                          shortcutItem.groupId === shortcutGroup.id &&
                          (this.state.searchTerm
                            ? shortcutItem.name
                                .toLowerCase()
                                .includes(this.state.searchTerm.toLowerCase())
                            : true),
                      )
                      .map((shortcutItem, itemIndex) => (
                        <Fragment key={itemIndex}>
                          {itemIndex === 0 && (
                            <span className="category-heading">
                              {shortcutGroup.label}
                            </span>
                          )}
                          <div className="keyboard-shortcuts-list-item">
                            <div className="keyboard-shortcuts-list-item-inner">
                              <span className="command-title">
                                {shortcutItem.name}
                              </span>
                              <span className="shortcut-indicator">
                                {shortcutItem.shortcut_key.map(
                                  (shortcut, key) => (
                                    <span
                                      className={`${
                                        shortcut !== ''
                                          ? 'shortcut-key'
                                          : 'no-shortcut-key'
                                      }`}
                                      key={key}
                                    >
                                      {shortcut !== '' ? shortcut : 'then'}
                                    </span>
                                  ),
                                )}
                              </span>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                ))}
                {result() && (
                  <div className="shortcut-keys">No result found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default KeyboardShortcuts;
