import React from 'react';
import { ActionButtons } from '../ActionButtons';
import { getActionData } from '../../../../../containers/Messages/Bots/helper';
import { BaseEditableComponent } from '../BaseComponent';
import {
  disbledSourceLinkComponents,
  editNotAllowedComponents,
} from '../../../../../containers/Messages/Bots/constants';

export class Automation extends BaseEditableComponent {
  render() {
    const { action_type } = this.state,
      { type, content, icon } = getActionData(this.state);
    return (
      <div className="bot-component">
        <ActionButtons
          isNotAllowToEdit={editNotAllowedComponents.includes(action_type)}
          deleteNode={this.remove.bind(this)}
          editNote={this.edit.bind(this)}
        />
        <jtk-target />
        <div className={'header'}>
          <h3>
            <span>
              <i className={icon} />
            </span>
            {type}
          </h3>
        </div>
        <div className={'body'}>
          <div className={'message-text-component'} data-port-id={1}>
            {!disbledSourceLinkComponents.includes(action_type) && (
              <jtk-source port-id={1} endpoint={true} />
            )}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    );
  }
}
