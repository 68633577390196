const actions = {
  GET_COMPANIES_LIST: 'GET_COMPANIES_LIST',
  GET_COMPANIES_LIST_SUCCESS: 'GET_COMPANIES_LIST_SUCCESS',
  GET_COMPANIES_LIST_FAILURE: 'GET_COMPANIES_LIST_FAILURE',
  CREATE_COMPANY: 'CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',
  COMPANY_DRAWER_STATUS: 'COMPANY_DRAWER_STATUS',
  SET_COMPANIES_SEARCH_TEXT: 'SET_COMPANIES_SEARCH_TEXT',
  GET_COMPANY_INFO: 'GET_COMPANY_INFO',
  GET_COMPANY_INFO_SUCCESS: 'GET_COMPANY_INFO_SUCCESS',
  GET_COMPANY_INFO_FAILURE: 'GET_COMPANY_INFO_FAILURE',
  RESET_PAGE_VIEW: 'RESET_PAGE_VIEW'
};

export default actions;
