import React from 'react';
import { bot, botAssigmentRule } from '../Messages/constants';

export function isAttachmentKind(conversation) {
  if (conversation.last_conversation_message) {
    return (
      ![null, undefined, false].includes(
        conversation.last_conversation_message.is_attachment,
      ) ||
      ![null, undefined, false].includes(
        conversation.last_conversation_message.is_gif,
      )
    );
  } else {
    return false;
  }
}
export const isAssignmentRuleBot = (message) =>
  botAssigmentRule.includes(message.message_type) ||
  botAssigmentRule.includes(message.performed_by);

export const isBotMessage = (message) => bot.includes(message.action_type);
export function findCharLen(tags, conversationListWidth) {
  let tagsToDisplay = 0,
    currentWidth = 60,
    moreTagsWidth = 60,
    tagName = tags.map((data) => data.tag_name),
    tagCount = tags.length,
    tagCharCountIndividual = tagName.map((data) => data.length);

  // Need to change these values if the tag css is updated
  const charWidth = 8,
    closeIconWidth = 20,
    padding = 18,
    margin = 10;

  tagCharCountIndividual.every((charCount, index) => {
    // Calculates the width of the text in the tag by multiplying number of characters with width of a single character
    const textWidth = charCount * charWidth;
    // Ignores margin for the last tag
    const tagMargin = tagCount - 1 >= index ? margin : 0;
    const tagWidth = textWidth + closeIconWidth + padding + tagMargin;
    currentWidth += tagWidth;
    // Ignores the "More tags" width if the current tag is the last one
    const widthCheck =
      currentWidth + (tagCount - 1 >= index ? 0 : moreTagsWidth);

    // If cumulative width of tags thus far is less than available width, increment tag display count, else exit loop
    if (widthCheck < conversationListWidth) {
      tagsToDisplay++;
      return true;
    } else {
      return false;
    }
  });

  return tagsToDisplay ? tagsToDisplay : 1;
}

export function getIconFromMessage(data) {
  if (['email', 'emailreply'].includes(String(data).toLowerCase())) {
    return <i className={'fal fa-envelope'} />;
  } else if (String(data).toLowerCase() === 'facebook') {
    return <i className={'fab fa-square-facebook'} />;
  } else if (String(data).toLowerCase() === 'twitter') {
    return <i className={'fab fa-twitter'} />;
  } else if (String(data).toLowerCase() === 'gist-api') {
    return <i className="fa-sharp fa-light fa-globe"></i>;
  } else {
    return <i className={'fal fa-comment-lines'} />;
  }
}

export function getCurrentPersonId(personId, selectedChatRoom) {
  if (
    selectedChatRoom &&
    selectedChatRoom.person_id &&
    selectedChatRoom.person_id !== personId
  ) {
    return selectedChatRoom.person_id;
  }
  return personId;
}
