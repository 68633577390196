import actions from './actions';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fetchProjectSecretKey,
  getPromise,
  deletePromise,
  unAuthorisedRedirection,
  patchPromise,
  postPromise,
} from '../../../helpers/utility';
import settings from '../../../settings';
import { store } from '../../store';
import { addCompanyActivity } from '../../../components/Companies/helper';

export function* getCompanyDetails(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies/${params.companyID}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANY_DETAILS_SUCCESS,
      payload: data.data.company,
    });
    if (params.isPageLoad) {
      // Get activities
      yield put({
        type: actions.GET_COMPANY_ACTIVITIES,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch company failed');
    }
    yield put({ type: actions.GET_COMPANY_DETAILS_FAILURE });
  }
}

export function* fetchCompanyActivities(params) {
  try {
    const { id } = store.getState().Company.companyDetails;
    let filterData = '';
    if (params.activityTypes && params.activityTypes.length) {
      filterData = params.activityTypes.map(activityType =>
        activityType.toUpperCase().replace(' ', '_'),
      );
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm/activities?company_id=${id}&activity_types=${filterData}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANY_ACTIVITIES_SUCCESS,
      payload: data.data.activities,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch activities failed');
    }
    yield put({ type: actions.GET_COMPANY_ACTIVITIES_FAILURE });
  }
}

export function* appendCompanyActivities(params) {
  try {
    const { companyDetails, activitiesFilter } = store.getState().Company;
    const { id } = companyDetails;
    let activityTypes = '';
    if (activitiesFilter.length) {
      activityTypes = activitiesFilter.map(activityType =>
        activityType.toUpperCase().replace(' ', '_'),
      );
    }
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm/activities?company_id=${id}&last_activity_id=${
      params.lastActivityId
    }&activity_types=${activityTypes}`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.APPEND_COMPANY_ACTIVITIES_SUCCESS,
      payload: data.data.activities,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch activities failed');
    }
    yield put({ type: actions.APPEND_COMPANY_ACTIVITIES_FAILURE });
  }
}

export function* removeContact(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies/${
      params.companyID
    }/remove_contact`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.data.message);
    yield put({
      type: actions.COMPANY_REMOVE_CONTACT_SUCCESS,
      contactID: params.payload.company.associated_contact_ids[0],
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Remove contact failed');
    }
    yield put({ type: actions.COMPANY_REMOVE_CONTACT_FAILURE });
  }
}

export function* addContact(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies/${
      params.companyID
    }/add_contact`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.data.message);
    yield put({
      type: actions.COMPANY_ADD_CONTACT_SUCCESS,
      payload: data.data.company.associated_contacts,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add contact failed');
    }
    yield put({ type: actions.COMPANY_ADD_CONTACT_FAILURE });
  }
}

export function* getCompanyProperties(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/company_properties_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANY_PROPERTY_LIST_SUCCESS,
      payload: data.data.properties,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Company properties fetch failed');
    }
  }
}

export function* fetchCompanyNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/notes?company_id=${params.companyID}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_COMPANY_NOTES_SUCCESS,
      payload: data.data.company_notes,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_COMPANY_NOTES_FAILURE });
    unAuthorisedRedirection(error);
    message.error('Fetch notes failed');
  }
}

export function* createCompanyNotes(params) {
  try {
    const url = `${settings.ROOT_URL}projects/${fetchProjectSecretKey()}/notes`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_COMPANY_NOTES_SUCCESS,
      payload: data.data.company_note,
      params,
    });
    addCompanyActivity(data.data.company_note.activities, 'Notes');
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.CREATE_COMPANY_NOTES_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Create note failed');
    }
  }
}

export function* editCompanyNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/notes/${params.noteID}?company_id=${
      params.companyID
    }`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EDIT_COMPANY_NOTES_SUCCESS,
      payload: data.data.company_note,
    });
    // addCompanyActivity(data.data.company_note.activities, 'Notes');
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update note failed');
    }
  }
}

export function* deleteCompanyNotes(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/notes/${params.noteID}?company_id=${
      params.companyID
    }`;
    const data = yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_COMPANY_NOTES_SUCCESS,
      noteID: params.noteID,
    });
    message.success(data.message);
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Delete note failed');
  }
}

export function* getMeetingList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/meetings_list?company_id=${
      params.companyID
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANY_MEETINGS_SUCCESS,
      payload: data.data.scheduled_meetings,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status !== 403) {
      message.error('Fetch meetings failed');
    }
    yield put({ type: actions.GET_COMPANY_MEETINGS_FAILURE });
  }
}

export function* addCompanyMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/scheduled_meetings`;
    let data = yield call(() => postPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.ADD_COMPANY_MEETING_SUCCESS,
      payload: data.data.scheduled_meeting,
    });
    addCompanyActivity(data.data.scheduled_meeting.activities, 'Meetings');
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Add meeting failed');
    }
    yield put({ type: actions.ADD_COMPANY_MEETING_FAILURE });
  }
}

export function* removeCompanyMeeting(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/scheduled_meetings/${
      params.meetingID
    }/cancel_scheduled_meeting`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.message);
    yield put({
      type: actions.CANCEL_COMPANY_MEETING_SUCCESS,
      meetingID: params.meetingID,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Cancel meeting failed');
    }
    yield put({ type: actions.CANCEL_COMPANY_MEETING_FAILURE });
  }
}

function* fetchEmailList(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm_emails?company_id=${
      params.companyID
    }`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_COMPANY_EMAIL_LIST_SUCCESS,
      payload: data.data.crm_emails,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error('Email list fetch failed');
  }
}

function* createCompanyEmail(params) {
  try {
    const url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/crm_emails`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_COMPANY_EMAIL_SUCCESS,
      payload: data.data.crm_email,
    });
    addCompanyActivity(data.data.crm_email.activities, 'Emails');
    message.success(data.message);
  } catch (error) {
    yield put({
      type: actions.CREATE_COMPANY_EMAIL_FAILURE,
    });
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Email send failed');
    }
  }
}
export function* updateCompanyDetails(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/companies/${params.companyID}`;
    let data = yield call(() => patchPromise(url, params.payload));
    message.success(data.data.message);
    yield put({
      type: actions.UPDATE_COMPANY_SUCCESS,
      payload: data.data.company,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      if (error.response.data.error && error.response.data.error.message) {
        message.error(error.response.data.error.message);
      } else {
        message.error(error.response.data.errors);
      }
    } else {
      message.error('Update company failed');
    }
    yield put({ type: actions.UPDATE_COMPANY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_COMPANY_DETAILS, getCompanyDetails),
    takeEvery(actions.GET_COMPANY_ACTIVITIES, fetchCompanyActivities),
    takeEvery(actions.APPEND_COMPANY_ACTIVITIES, appendCompanyActivities),
    takeEvery(actions.COMPANY_REMOVE_CONTACT, removeContact),
    takeEvery(actions.COMPANY_ADD_CONTACT, addContact),
    takeEvery(actions.GET_COMPANY_PROPERTY_LIST, getCompanyProperties),
    takeEvery(actions.FETCH_COMPANY_NOTES, fetchCompanyNotes),
    takeEvery(actions.CREATE_COMPANY_NOTES, createCompanyNotes),
    takeEvery(actions.EDIT_COMPANY_NOTES, editCompanyNotes),
    takeEvery(actions.DELETE_COMPANY_NOTES, deleteCompanyNotes),
    takeEvery(actions.GET_COMPANY_MEETINGS, getMeetingList),
    takeEvery(actions.ADD_COMPANY_MEETING, addCompanyMeeting),
    takeEvery(actions.CANCEL_COMPANY_MEETING, removeCompanyMeeting),
    takeEvery(actions.GET_COMPANY_EMAIL_LIST, fetchEmailList),
    takeEvery(actions.CREATE_COMPANY_EMAIL, createCompanyEmail),
    takeEvery(actions.UPDATE_COMPANY, updateCompanyDetails),
  ]);
}
