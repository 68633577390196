import {
  config,
  gjsStylePrefix,
  panels,
  gdprText,
  embeddedType,
  defaultThankYouMessage,
} from './constants';
import { store } from '../../redux/store';
import $ from 'jquery';
import actions from '../../redux/forms/actions';
import _ from 'lodash';
import {
  fetchProjectSecretKey,
  removeUnderscore,
  trimFunction,
} from '../../helpers/utility';
import { addedOptInFields } from '../../redux/constants';
import { message } from 'antd';
import settings from '../../settings';
let beautify = require('js-beautify').html;

export function activatePanel(panel) {
  if (panel === 'none') {
    //This condition is to all panels inactive
    panels.forEach(id => {
      document.querySelector(id).classList.remove('active-panel');
    });
  } else {
    panels.forEach(id => {
      if (id === panel) {
        document.querySelector(id) &&
          document.querySelector(id).classList.add('active-panel');
      } else
        document.querySelector(id) &&
          document.querySelector(id).classList.remove('active-panel');
    });
  }
}

export function getOptinField(
  placeholder,
  name,
  required = true,
  propertyType,
) {
  return `<input type="text"
              class="gist-form-input form-input" placeholder="${placeholder}" name="${name}" data-required-field="${required}" ${
  name === 'email' ? 'fields-email' : ''
} property-type="${propertyType}"
            />`;
}

export function createTraitManager() {
  let traitsSector = $(
    '<div class="gist-sm-sector no-select" id="traits-container">' +
      '<div class="gist-sm-title">Settings<i id="gist-sm-caret" class="fa fa-caret-down"/></div>' +
      '<div class="gist-sm-properties" style="display: block;"></div></div>',
  );
  let traitsProps = traitsSector.find('.gist-sm-properties');
  traitsProps.append($('.gist-trt-traits'));
  $('.gist-sm-sectors').before(traitsSector);
  const dropdownIcon = document.getElementById('gist-sm-caret');
  const traitsContainer = document.getElementById('traits-container');
  traitsSector.find('.gist-sm-title').on('click', function() {
    let traitStyle = traitsProps.get(0).style;
    let hidden = traitStyle.display === 'none';
    if (hidden) {
      traitStyle.display = 'block';
      dropdownIcon.classList.remove('fa-caret-right');
      dropdownIcon.classList.add('fa-caret-down');
      traitsContainer.classList.add('gist-sm-open');
    } else {
      traitStyle.display = 'none';
      dropdownIcon.classList.remove('fa-caret-down');
      dropdownIcon.classList.add('fa-caret-right');
      traitsContainer.classList.remove('gist-sm-open');
    }
  });
}

export function createSelectorManager() {
  const selectorSector = document.createElement('div');
  selectorSector.classList.add('selector-sector');
  document.querySelector('.gist-sm-sectors') &&
    document
      .querySelector('.gist-sm-sectors')
      .insertAdjacentElement('afterend', selectorSector);
  selectorSector.after(document.querySelector('.gist-clm-tags'));
}

export function getIframe() {
  return document.querySelector('.gist-cv-canvas__frames iframe')
    .contentDocument;
}
export function resizeCanvas() {
  const iframe = getIframe();
  const wrapper = iframe.querySelector('.form-wrapper');
  const templateHeight = wrapper.offsetHeight;
  const templateWidth = wrapper.offsetWidth;
  iframe.querySelector('#wrapper').style.width = templateWidth;
  iframe.querySelector('#wrapper').style.minHeight = 'unset';
  iframe.querySelector('#wrapper').style.height = templateHeight;
}

export function resizeWrapper(component) {
  window.editor.select(window.editor.getComponents().models[0]);
  const style = {
    ...window.editor.getSelectedToStyle().getStyle(),
    height: 'auto',
  };
  window.editor.getSelectedToStyle().setStyle(style);
  window.editor.select(component);
}

export function onRemoveElement(removedElement, editor) {
  const { optInFields } = store.getState().Forms;
  if (removedElement.attributes.tagName === 'form') {
    store.dispatch({
      type: actions.UPDATE_ADDED_OPTIN_FIELD,
      payload: {
        updatedAddedOptInField: addedOptInFields,
        filteredOptInFields: getFilteredFields(_.cloneDeep(optInFields)),
      },
    });
    addFormBlock(editor.BlockManager, config.labelForm);
  } else if (removedElement.attributes.tagName === 'button') {
    store.dispatch({
      type: actions.REMOVE_SUBMIT_ACTION_TYPES,
      buttonId: removedElement.ccid,
    });
  }
}

export function addFormBlock(bm, lableForm) {
  const { addedOptInFields, editorPageDetails } = store.getState().Forms,
    { templateDetails } = store.getState().Templates;
  const form = `.${gjsStylePrefix}form { text-align:center; align-items:center; padding: 30px 20px 30px 20px;font-family: Arial, Helvetica, sans-serif;}
  .${gjsStylePrefix}form-input{
    width: 100%;
    border-radius: 4px;
    height: auto;
    border: 1px solid #dcdee5;
    box-shadow: none !important;
    font-size: $font-size-base;
    padding: 8.5px 15px;
    outline: none !important;
  }
    .${gjsStylePrefix}form-button {
      margin-top: 15px;
      width: 100%;
  }
  .${gjsStylePrefix}button  {
      width: 100%;
      background-color: #37a1f6;
      color: #ffffff;
      font-weight: 600;
      border: 1px solid #37a1f6;
      font-size: $font-size-base;
      padding: 7px 15px;
      border-radius: 4px;
      outline: none !important;
      box-shadow: none;
      display: block;
      cursor: pointer;
  } 
  .${gjsStylePrefix}form-input:not(:first-child) {
    margin-top: 10px;
  }
  .${gjsStylePrefix}form-span {
     display: block;
  }
  .${gjsStylePrefix}form div {
      width: 100%;
  }`;

  bm.add('form', {
    label: lableForm,
    category: 'Basic',
    content: `<form class="${gjsStylePrefix}form"><div id="input-group">${getOptinField(
      removeUnderscore(addedOptInFields[0].property_name),
      addedOptInFields[0].property_name,
      true,
      addedOptInFields[0].property_type,
    )}</div><button type="submit" class="${gjsStylePrefix}button ${gjsStylePrefix}form-button" gist-btn-type="form-button" action-type="${returnActionType(
      editorPageDetails,
      templateDetails,
      true,
    )}">Submit</button></form>
      <style>
      ${form}
      </style>`,
    attributes: { class: 'gist-fonts cus-fonts far fa-money-check-pen' },
  });
}

export function getGdprField(required) {
  return {
    tagName: 'div',
    type: 'gdpr',
    attributes: {
      name: 'gdpr',
    },
    classes: [{ name: `${gjsStylePrefix}gdpr` }, { name: 'gist-gdpr-content' }],
    components: [
      {
        tagName: 'input',
        type: 'checkbox',
        attributes: {
          type: 'checkbox',
          name: 'eu_consent',
          'data-required-field': `${required}`,
        },
        classes: [{ name: `${gjsStylePrefix}form-checkbox` }],
      },
      {
        type: 'text',
        classes: [{ name: `${gjsStylePrefix}gdpr-text` }],
        content: gdprText,
      },
    ],
  };
}

export function prePopulateAlreadyAddedOptInFields(editor) {
  const { optInFields } = store.getState().Forms;
  const canvasElement = editor.getWrapper();
  if (canvasElement.find('.gist-form')[0]) {
    const formInputs = canvasElement
      .find('.gist-form')[0]
      .getEl()
      .getElementsByTagName('input');
    let addedFields = [],
      filteredFields = [];
    for (const formInput of formInputs) {
      for (const optInField of optInFields) {
        for (const field of optInField.properties) {
          if (
            formInput.name === field.property_name &&
            formInput.type !== 'checkbox'
          ) {
            addedFields = addedFields.concat({
              ...field,
              placeholder: formInput.placeholder,
              required: formInput.getAttribute('data-required-field'),
            });
          }
        }
      }
    }
    addedFields = addedFields.length ? addedFields : addedOptInFields;
    filteredFields = _.cloneDeep(optInFields);
    filteredFields = filteredFields.map(optInField => {
      let filteredProperty = optInField;
      filteredProperty.properties = _.differenceBy(
        optInField.properties,
        addedFields,
        'property_name',
      );
      return filteredProperty;
    });
    store.dispatch({
      type: actions.UPDATE_ADDED_OPTIN_FIELD,
      payload: {
        updatedAddedOptInField: addedFields,
        filteredOptInFields: filteredFields,
      },
    });
    updateOptInFieldLabelsTrait(canvasElement.find('.gist-form')[0]);
  }
}

export function removeOptInField(editor, event) {
  const selectedElement = editor
      .getSelected()
      .components()
      .models[0].components().models,
    selectedId = event.target.id,
    { addedOptInFields } = store.getState().Forms;
  for (let element of selectedElement) {
    if (element.getEl().getAttribute('name') === selectedId) {
      element.remove();
    }
  }
  const removedFields = addedOptInFields.filter(
    optInField => optInField.property_name !== selectedId,
  );
  store.dispatch({
    type: actions.UPDATE_ADDED_OPTIN_FIELD,
    payload: {
      updatedAddedOptInField: removedFields,
    },
  });
  filterOptInFields();
}

export function filterOptInFields() {
  const { optInFields, addedOptInFields } = store.getState().Forms;
  let filteredFields = _.cloneDeep(optInFields);
  filteredFields = filteredFields.map(optInField => {
    let filteredProperty = optInField;
    filteredProperty.properties = _.differenceBy(
      optInField.properties,
      addedOptInFields,
      'property_name',
    );
    return filteredProperty;
  });
  store.dispatch({
    type: actions.UPDATE_ADDED_OPTIN_FIELD,
    payload: {
      filteredOptInFields: filteredFields,
    },
  });
}

export function updateOptInFieldLabelsTrait(element) {
  element.removeTrait('opt-in-list');
  element.addTrait(
    {
      type: 'opt-in-list',
      label: false,
      name: 'opt-in-list',
    },
    { at: 0 },
  );
}

export function deleteOptInFields(el, editor) {
  const deleteIcons = el.querySelectorAll('.delete-icon');
  if (deleteIcons.length) {
    for (let deleteIcon of deleteIcons) {
      deleteIcon.addEventListener('click', event => {
        let confirmation = window.confirm(
          `Remove ${event.target.getAttribute('placeholder')}?`,
        );
        if (confirmation) {
          removeOptInField(editor, event);
          selectFormElement();
          updateOptInFieldLabelsTrait(editor.getSelected());
        }
      });
    }
  }
}

export function onHoverOptInField(el) {
  const optInLabels = el.querySelectorAll('.gist-opt-in-field-label');
  if (optInLabels.length) {
    for (let optInLabel of optInLabels) {
      optInLabel.addEventListener('mouseover', event => {
        if (optInLabel.children[2]) {
          if (optInLabel.children[2].id !== 'email') {
            optInLabel.classList.add('hovered');
          } else {
            optInLabel.classList.add('edit-hovered');
          }
        }
      });
      optInLabel.addEventListener('mouseout', event => {
        optInLabel.classList.remove('hovered');
        optInLabel.classList.remove('edit-hovered');
      });
    }
  }
}

export function optinFieldModal(
  fields,
  value,
  placeholder,
  required = true,
  isFromAdd,
) {
  const { optInFields } = store.getState().Forms;
  fields = value === 'email' ? optInFields : fields;
  //the footer is in top because the last-child property is not applied since the footer is in absolute position.
  const data = {
    content: `<div class="modal-content"><div class="form-field">
                           <label>Select a contact property</label>
                           <select ${value === 'email' ? 'disabled' : ''}>
                           ${fields.map(
    optInField =>
      optInField.properties.length !== 0 &&
                               `<optgroup label="${optInField.category_name}">
                             ${optInField.properties.map(
    field =>
      `<option value=${field.property_name} ${
        value === field.property_name
          ? 'selected'
          : ''
      }>${removeUnderscore(
        field.property_name,
      )}</option>`,
  )}</optgroup>`,
  )}
                           </select>
                           </div>
                           <div class="form-field">
                           <label>Placeholder text</label>
                           <input type="text" class="form-item input-sm gist-form-input" id="placeholder" value="${removeUnderscore(
                             placeholder,
  )}" />
                           </div>
                           <div class="form-field form-switch">
                           <label class="label require-label">Make this field required</label>
                           <label class="switch ${
                             value === 'email' ? 'disabled' : ''
                           }"> 
  <input type="checkbox" id="redirect-option" ${
  required === 'true' || value === 'email' ? 'checked' : ''
} ${value === 'email' ? 'disabled' : ''}>
  <span class="knob"></span>
</label>
                           </div></div>
                           <div class="mdl-footer">
                          <button class="ant-btn btn btn-primary btn-sm">${
                            isFromAdd ? 'Save' : 'Update'
}</button>
</div>
                           `,
  };
  return data;
}

export function handlePropChange(element, placeholder, required) {
  const changedField = element,
    {
      optInFields,
      addedOptInFields,
      filteredOptInFields,
    } = store.getState().Forms;
  let externalIndex = -1,
    propertyType = null,
    internalIndex = -1;
  if (changedField) {
    for (const [outerIndex, optInField] of optInFields.entries()) {
      for (const [index, field] of optInField.properties.entries()) {
        if (changedField === field.property_name) {
          externalIndex = outerIndex;
          internalIndex = index;
          propertyType = field.property_type;
        }
      }
    }
  }
  if (externalIndex > -1 && internalIndex > -1) {
    const element = window.editor.getSelected(),
      model = window.editor.getSelected().components().models[0];
    const gdprElement = window.editor
      .getSelected()
      .getEl()
      .getElementsByClassName('gist-gdpr-content');
    const inputs = window.editor
      .getSelected()
      .components()
      .models[0].components().models;
    const fieldPosition = gdprElement.length
      ? inputs.length - 1
      : inputs.length;
    model.append(
      getOptinField(
        placeholder,
        optInFields[externalIndex].properties[internalIndex].property_name,
        required,
        propertyType,
      ),
      {
        at: fieldPosition,
      },
    );
    const content = {
      ...optInFields[externalIndex].properties[internalIndex],
      placeholder,
      required,
    };

    const updatedAddedOptInField = addedOptInFields.concat(content);
    const filteredFields = filteredOptInFields.map(userProperty => {
      let filteredProperty = userProperty;
      filteredProperty.properties = userProperty.properties.filter(
        property => property.property_name !== changedField,
      );
      return filteredProperty;
    });
    store.dispatch({
      type: actions.UPDATE_ADDED_OPTIN_FIELD,
      payload: { updatedAddedOptInField, filteredOptInFields: filteredFields },
    });
    // TODO: need to handle when properties empty
    // if (filteredOptInFields.length === 0) {
    // element.removeTrait('opt-in-field');
    // element.removeTrait('gist-add-optin-field-button');
    // }
    updateOptInFieldLabelsTrait(element);
    element.removeTrait('opt-in-field');
    // add opt-in-fields readonly mode
    disableInputFields();
  }
}

export function getFilteredFields(property) {
  const filteredFields = property.map(userProperty => {
    let filteredProperty = userProperty;
    filteredProperty.properties = userProperty.properties.filter(
      property => property.property_name !== 'email',
    );
    return filteredProperty;
  });
  return filteredFields;
}

export function createOptInEditModal(el, index) {
  let modal = window.editor.Modal,
    contentValue = el.getAttribute('value'),
    placeholder = el.innerText || el.textContent,
    requiredField = el.getAttribute('required');
  let content = createOptInContentModal(
    contentValue,
    placeholder,
    requiredField,
  );
  let select = content.getElementsByTagName('select')[0];
  select.addEventListener('change', event =>
    onModalSelectChange(event, content),
  );
  let button = content.getElementsByTagName('button')[0];
  button.addEventListener('click', e => onEditClick(e, content, index));
  modal.open({
    title: 'Edit form field',
    content: content,
  });
}

export function editOptInFields(el) {
  const editIcons = el.querySelectorAll('.edit-icon');
  if (editIcons.length) {
    for (let index = 0; index < editIcons.length; index++) {
      editIcons[index].addEventListener('click', event => {
        createOptInEditModal(editIcons[index].previousSibling, index);
      });
    }
  }
}

export function createOptInContentModal(value, placeholder, required) {
  const { filteredOptInFields, optInFields } = store.getState().Forms;
  const content = document.createElement('div');
  const filteredFields = optInFields.filter(optinField =>
    optinField.properties.find(property => property.property_name === value),
  );
  let updatedFields = _.cloneDeep(filteredOptInFields);
  if (filteredFields.length) {
    updatedFields = updatedFields.map(optinField => {
      if (optinField.category_name === filteredFields[0].category_name) {
        optinField.properties = optinField.properties.concat({
          property_name: value,
        });
      }
      return optinField;
    });
  }
  content.innerHTML = optinFieldModal(
    getFilteredFields(updatedFields),
    value,
    placeholder,
    required,
    false,
  ).content;
  return content;
}

export function onModalSelectChange(event, content) {
  const placeholder = content.getElementsByTagName('input')[0];
  placeholder.value = removeUnderscore(event.target.value);
}
export function onEditClick(event, modalContent, index) {
  const select = modalContent.getElementsByTagName('select')[0],
    placeholder = modalContent.getElementsByTagName('input')[0],
    required = modalContent.getElementsByTagName('input')[1];
  if (trimFunction(placeholder.value)) {
    processEditOptInField(
      select.value,
      placeholder.value,
      required.checked,
      index,
    );
    selectFormElement();
    window.editor.Modal.close();
  } else {
    message.error('Placeholder can\'t be blank');
  }
}

export function processEditOptInField(element, placeholder, required, index) {
  const changedField = element,
    { addedOptInFields, isGdprEnabled } = store.getState().Forms;
  let updatedAddedOptInField = [];
  if (changedField && index > -1) {
    addedOptInFields[index] = {
      ...addedOptInFields[index],
      property_name: changedField,
      placeholder,
      required,
    };
    updatedAddedOptInField = addedOptInFields;
    const selectedElement = window.editor.getSelected(),
      optInmodel = window.editor.getSelected().components().models[0];
    optInmodel.remove();
    let addedElement = '<div>';

    for (const field of updatedAddedOptInField) {
      addedElement += getOptinField(
        field.placeholder,
        field.property_name,
        field.required,
        field.property_type,
      );
    }
    addedElement += '</div>';
    selectedElement.append(addedElement, { at: 0 });
    if (isGdprEnabled) {
      selectedElement
        .components()
        .models[0].append(
          optInmodel.components().models[
            optInmodel.components().models.length - 1
          ],
          {
            at: selectedElement.components().models[0].components().models
              .length,
          },
        );
    }
    store.dispatch({
      type: actions.UPDATE_ADDED_OPTIN_FIELD,
      payload: { updatedAddedOptInField },
    });
    updateOptInFieldLabelsTrait(selectedElement);
    filterOptInFields();
  }
}

export function addWebsiteToCanvas(URL) {
  const websiteIframe = document.createElement('iframe');
  document
    .querySelector('.gist-cv-canvas__frames iframe')
    .contentDocument.body.insertAdjacentElement('beforebegin', websiteIframe);
  websiteIframe.style.height = '100%';
  websiteIframe.style.width = '100%';
  websiteIframe.style.border = 'none';
  websiteIframe.src = URL;
}

export function setSubmitActions(component) {
  const { formSubmitActions } = store.getState().Forms,
    selectedAction = formSubmitActions.filter(
      data => data.button_id === component.ccid,
    );
  store.dispatch({
    type: actions.UPDATE_SELECTED_ACTION,
    payload: selectedAction[0],
  });
}

export function setCanvasPosition(formType) {
  getIframe().body.style.background = 'rgba(0,0,0,0.6)';
  getIframe().body.style.margin = 'auto';
  //Ckeditor A tag issue
  let css = 'a { text-decoration: none; }';
  let head = getIframe().head || getIframe().getElementsByTagName('head')[0];
  let style = getIframe().createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
  if (style.styleSheet){
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  //Ckeditor A tag issue End.

  getIframe().querySelector('#wrapper').style.maxHeight = '100%';
  getIframe().querySelector('#wrapper').style.minHeight = '10%';
  getIframe().querySelector('#wrapper').style.maxWidth = '100%';
  getIframe().querySelector('#wrapper').style.minWidth = '10%';
  getIframe().querySelector('#wrapper').style.overflow = 'auto';

  if (formType === 'slide_in') {
    const { formAlignment } = store.getState().Forms;
    getIframe().body.style.display = 'flex';
    getIframe().body.style.alignItems = 'bottom';
    getIframe().querySelector('#wrapper').style.position = 'absolute';
    getIframe().querySelector('#wrapper').style.bottom = '0';
    getIframe().querySelector('#wrapper').style.maxHeight = 'calc(100% - 30px)';
    if (formAlignment === 'right') {
      getIframe().querySelector('#wrapper').style.right = '20px';
      getIframe().querySelector('#wrapper').style.left = 'unset';
    } else if (formAlignment === 'left') {
      getIframe().querySelector('#wrapper').style.left = '20px';
      getIframe().querySelector('#wrapper').style.right = 'unset';
    }
    if (
      document.getElementsByClassName('gist-field-formAlignment')[0] &&
      document
        .getElementsByClassName('gist-field-formAlignment')[0]
        .getElementsByTagName('select')[0]
    ) {
      document
        .getElementsByClassName('gist-field-formAlignment')[0]
        .getElementsByTagName('select')[0].value = formAlignment;
    }
  } else if (formType === 'popup' || formType === embeddedType) {
    getIframe().body.style.display = 'flex';
    getIframe().body.style.alignItems = 'center';
    getIframe().body.style.justifyContent = 'center';
  } else if (formType === 'sticky-bar') {
  } else if (formType === 'welcome-mat') {
  } else if (formType === 'fullscreen-takeover') {
  }
}

export function addGdprToCanvas(element) {
  const selectedElement = window.editor.getSelected().components().models[0];
  const changedElement = element.target.checked;
  if (changedElement) {
    selectedElement.append(getGdprField('false'), {
      at: selectedElement.length,
    });
    store.dispatch({
      type: actions.UPDATE_GDPR_ENABLED,
      payload: { gdprEnabled: true, isRequireGdpr: false },
    });
    document
      .getElementById('require-gdpr')
      .getElementsByTagName('input')[0].checked = false;
  } else {
    store.dispatch({
      type: actions.UPDATE_GDPR_ENABLED,
      payload: { gdprEnabled: false, isRequireGdpr: false },
    });
    for (const model of selectedElement.components().models) {
      if (model.attributes.type === 'gdpr') {
        model.remove();
      }
    }
  }
}

export function prePopulateGdprEnable(component) {
  let gdprEnabled = false,
    isRequireGdpr = false,
    gdprElement = component.getEl().getElementsByClassName('gist-gdpr-content');
  if (component.getEl().tagName === 'FORM' && component.getEl().length) {
    if (gdprElement.length) {
      if (
        gdprElement[0]
          .getElementsByTagName('input')[0]
          .getAttribute('data-required-field') === 'true'
      ) {
        isRequireGdpr = true;
      } else {
        isRequireGdpr = false;
      }
      gdprEnabled = true;
    } else {
      gdprEnabled = false;
    }
    store.dispatch({
      type: actions.UPDATE_GDPR_ENABLED,
      payload: { gdprEnabled, isRequireGdpr },
    });
    component.removeTrait('enable-heading');
    component.addTrait(
      {
        type: 'enable-heading',
        label: false,
        name: 'enable-heading',
      },
      { at: 2 },
    );
  }
}

export function addRequireGdprToCanvas(event) {
  const required = event.target.checked ? 'true' : 'false';
  const selectedElement = window.editor.getSelected().components().models[0];
  for (const model of selectedElement.components().models) {
    if (model.attributes.type === 'gdpr') {
      model
        .components()
        .models[0].addAttributes({ 'data-required-field': required });
    }
  }
}

export function updateImageUrlInEditor(url) {
  window.editor &&
    window.editor.AssetManager &&
    window.editor.AssetManager.add(url);
}

export function unSelectCanvas(element) {
  getIframe()
    .querySelector(element)
    .addEventListener('click', e => {
      let path = e.composedPath ? e.composedPath() : e.path;
      const formWrapper = path.filter(
        e => e.classList && e.classList.contains('form-wrapper'),
      );
      if (!formWrapper.length) {
        window.editor.select();
        //select the elements block when click outside of form
        triggerElementsBlock();
        // TODO: need to check this for "Select an element text"
        // document.getElementsByClassName(
        //   'gist-pn-views-container',
        // )[0].innerHTML += '<h3>Select an element</h3>';
      }
    });
}

export function triggerElementsBlock() {
  window.editor.selectRemove(window.editor.getSelected());
  const blBtn = window.editor.Panels.getButton('custom-view', 'open-blocks');
  blBtn.set('active', 1);
  activatePanel('#blocks');
}

// Get font families in particular DOM
// Reference URL for this (https://gist.github.com/macbookandrew/f33dbbc0aa582d0515919dc5fb95c00a)
export function getElementsStyle(css, verbose, element) {
  // polyfill getComputedStyle
  if (typeof window.getComputedStyle === 'undefined') {
    window.getComputedStyle = function(elem) {
      return elem.currentStyle;
    };
  }
  // set vars
  var style,
    thisNode,
    styleId,
    allStyles = [],
    nodes = element.getElementsByTagName('*');
  // loop over all elements
  for (var i = 0; i < nodes.length; i++) {
    thisNode = nodes[i];
    if (thisNode.style) {
      styleId = `#${thisNode.id || `${thisNode.nodeName}(${i})`}`;
      style =
        thisNode.style.fontFamily || window.getComputedStyle(thisNode, '')[css];

      // get element’s style
      if (style) {
        if (verbose) {
          allStyles.push([styleId, style]);
        } else if (allStyles.indexOf(style) === -1) {
          allStyles.push(style);
        }

        // add data-attribute with key for allStyles array
        thisNode.dataset.styleId = allStyles.indexOf(style);
      }

      // get element:before’s style
      let styleBefore = window.getComputedStyle(thisNode, ':before')[css];
      if (styleBefore) {
        if (verbose) {
          allStyles.push([styleId, styleBefore]);
        } else if (allStyles.indexOf(styleBefore) === -1) {
          allStyles.push(styleBefore);
        }

        // add data-attribute with key for allStyles array
        thisNode.dataset.styleId = allStyles.indexOf(styleBefore);
      }

      // get element:after’s style
      let styleAfter = window.getComputedStyle(thisNode, ':after')[css];
      if (styleAfter) {
        if (verbose) {
          allStyles.push([styleId, styleAfter]);
        } else if (allStyles.indexOf(styleAfter) === -1) {
          allStyles.push(styleAfter);
        }

        // add data-attribute with key for allStyles array
        thisNode.dataset.styleId = allStyles.indexOf(styleAfter);
      }
    }
  }
  return allStyles;
}

export function disableInputFields() {
  const formElements = getIframe().getElementsByTagName('form')[0];
  if (formElements) {
    const formInputs = formElements.getElementsByTagName('input');
    for (const element of formInputs) {
      element.setAttribute('readonly', true);
    }
  }
}
export function loadCKEditorScript() {
  const script = document.createElement('script');
  script.src = `${process.env.PUBLIC_URL}/ckeditor/ckeditor.js`;
  script.async = true;
  script.onload = onCKEditorScriptLoad;
  document.body.appendChild(script);
}
//change ckeditor target values as required
export function onCKEditorScriptLoad() {
  const{ $editable } = CKEDITOR.dtd
  $editable.span = 1;
  $editable.a = 1;
  $editable.strong = 1;
  $editable.u = 1;
  CKEDITOR.plugins.addExternal(
    'strinsert',
    '/ckeditor/plugins/strinsert/',
    'plugin.js',
  );
  CKEDITOR.on('dialogDefinition', function (ev) {
    try {
      let dialogName = ev.data.name;
      let dialogDefinition = ev.data.definition;
      if (dialogName === 'link') {
        let informationTab = dialogDefinition.getContents('target');
        let targetField = informationTab.get('linkTargetType');

        targetField['default'] = '_blank';
        targetField['items'] = [
          ['New Window (_blank)', '_blank'],
          ['Parent Window (_parent)', '_parent'],
        ];
        let infoTab = dialogDefinition.getContents('info');

        // Get a reference to the link type
        let linkOptions = infoTab.get('linkType');
        linkOptions['items'] = [['URL', 'url']];
      }
    } catch (exception) {
      alert(`Error ${ev.message}`);
    }
  });
}
let parent;
export function formatHTMLString(string, initial) {
  if (initial) parent = '';
  let position = string.indexOf('>') + 1;
  parent = `${parent + string.slice(0, position)}\n`;
  const remString = string.slice(position);
  if (remString.length !== 0) return formatHTMLString(remString);
  else return parent;
}

export function appendNewImages(newImages) {
  let images = [];
  window.editor.AssetManager.getAll().models.forEach(image => {
    images.push(image.attributes.src);
  });
  images.forEach(image => {
    window.editor.AssetManager.remove(image);
  });
  newImages.forEach(image => {
    images.push(image);
  });
  images.reverse().forEach(image => {
    updateImageUrlInEditor(image);
  });
}

export function manipulateOptinFieldsJson() {
  const canvasElement = window.editor.getWrapper();
  if (canvasElement.find('.gist-form')[0]) {
    const formInputs = canvasElement
      .find('.gist-form')[0]
      .getEl()
      .getElementsByTagName('input');
    return generateCustomFields(formInputs);
  }
}

export function generateCustomFields(formInputs) {
  let addedFields = [];
  for (const formInput of formInputs) {
    if (formInput.type !== 'checkbox' && formInput) {
      addedFields = addedFields.concat({
        key: formInput.name,
        label: formInput.placeholder,
        is_required: formInput.getAttribute('data-required-field'),
        type: formInput.getAttribute('property-type'),
      });
    }
  }
  return addedFields;
}

export function fetchSubmitActionsData(formInputs) {
  let addedFields = [];
  for (const formInput of formInputs) {
    addedFields = addedFields.concat({
      button_id: formInput.id,
      message: defaultThankYouMessage,
    });
  }
  return addedFields;
}

export function beautifyHtml(code) {
  const beginText = '<!-- Begin Gist Signup Form -->\n',
    endText = '<!-- End Gist Signup Form -->';
  return beautify(`${beginText}${code}${endText}`);
}

export function fetchHtmlFormsElement(formId, htmlFormData) {
  let htmlContent = document.createElement('div');
  htmlContent.innerHTML = htmlFormData;
  //Honey bot element added to restrict hacking the html forms using bots
  let formElement = htmlContent.getElementsByTagName('form')[0],
    honeyBotElement = document.createElement('div');
  if (formElement) {
    honeyBotElement.style.position = 'absolute';
    honeyBotElement.style.left = '-5000px';
    honeyBotElement.setAttribute('aria-hidden', 'true');
    honeyBotElement.innerHTML = `<input type="text"name=form_${formId} tabIndex="-1" value="">`;
    formElement.setAttribute(
      'action',
      `${settings.EMBEDDED_FORMS}subscribe/${formId}`,
    );
    formElement.setAttribute('method', 'post');
    formElement.removeAttribute('class');
    formElement.removeAttribute('id');
    if (formElement.getElementsByTagName('div')[0]) {
      formElement.getElementsByTagName('div')[0].innerHTML +=
        '<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->';
      formElement.getElementsByTagName('div')[0].appendChild(honeyBotElement);
    }
    for (const content of formElement.getElementsByTagName('*')) {
      if (
        content.tagName === 'INPUT' &&
        content.getAttribute('type') !== 'hidden'
      ) {
        content.setAttribute('name', `fields[${content.getAttribute('name')}]`);
        if (content.getAttribute('data-required-field') === 'true') {
          content.setAttribute('required', 'true');
        }
        if (content.getAttribute('type') === 'checkbox') {
          content.setAttribute('id', 'agree_condition');
          content.setAttribute('value', 'true');
        }
      }
      //remove all attributes in form elements
      content.removeAttribute('id');
      content.removeAttribute('class');
      content.removeAttribute('property-type');
      content.removeAttribute('data-required-field');
      content.removeAttribute('action-type');
      content.removeAttribute('fields-email');

      if (content.getAttribute('name') === 'gdpr') {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('id', 'new');
        hiddenField.setAttribute('name', 'fields[eu_consent]');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('value', 'false');
        content.prepend(hiddenField);
      }
      if (content.getAttribute('name') === 'gdpr') {
        content.getElementsByTagName('div')[0].style.display = 'inline';
      }
      if (content.tagName === 'BUTTON') {
        let buttonElement = document.createElement('input');
        buttonElement.setAttribute('type', 'submit');
        buttonElement.setAttribute('id', 'gist-form-subscribe');
        buttonElement.setAttribute('value', content.textContent);
        buttonElement.setAttribute('class', 'button');
        content.parentNode.removeChild(content);
        if (formElement.getElementsByTagName('div')[0]) {
          formElement.getElementsByTagName('div')[0].appendChild(buttonElement);
        }
      }
    }
  }
  return formElement;
}

export function selectFormElement() {
  const canvasElement = window.editor.getWrapper();
  if (canvasElement.find('.gist-form')[0]) {
    window.editor.select(canvasElement.find('.gist-form')[0]);
  }
}

export function fetchCkeditorOptions() {
  const { liquidVariables } = store.getState().Forms;
  return {
    'gjs-plugin-ckeditor': {
      options: {
        toolbar: [
          {
            name: 'styles',
            items: ['Font', 'FontSize'],
          },
          {
            name: 'colors',
            items: ['TextColor'],
          },
          ['strinsert'],
          '/',
          {
            name: 'alignment',
            items: [
              'JustifyLeft',
              'JustifyCenter',
              'JustifyRight',
              'JustifyBlock',
            ],
          },
          ['Bold', 'Italic', 'Underline', 'Strike'],
          {
            name: 'links',
            items: ['Link', 'Unlink'],
          },
        ],
        font_names:
          'Arial;Comic Sans MS;Courier New;Georgia;Lucida Sans Unicode;Tahoma;Times New Roman;Trebuchet MS;Verdana;Roboto Slab;Adamina;Crete Round;Domine;Libre Baskerville;Lora;Merriweather;Noto Serif;Playfair Display;Old Standard TT;Cinzel;Open Sans;Roboto;Lato;Montserrat;Fira Sans;Source Sans Pro;Ubuntu;Noto Sans;Oxygen;Oswald;Fjalla One;Poppins;Alegreya;Josefin Slab;Raleway;',
        autoParagraph: false,
        position: 'center',
        skin: 'moono-dark',
        removeButtons: '',
        extraPlugins: 'strinsert,font,justify,colorbutton',
        strinsert_strings: liquidVariables,
        strinsert_button_label: 'Personalise',
      },
    },
  };
}

export function fetchTemplatesCustomFields(templateHtml) {
  let htmlContent = document.createElement('div');
  htmlContent.innerHTML = templateHtml;
  let formElement = htmlContent.getElementsByTagName('form')[0];
  if (formElement) {
    return generateCustomFields(formElement.getElementsByTagName('input'));
  }
}

export function generateSubmitActions(formType, templateHtml) {
  let submitAction = [];
  if (formType === embeddedType) {
    let htmlContent = document.createElement('div');
    htmlContent.innerHTML = templateHtml;
    let formElement = htmlContent.getElementsByTagName('form')[0];
    if (formElement) {
      submitAction = fetchSubmitActionsData(
        formElement.getElementsByTagName('button'),
      );
    }
  }
  return submitAction;
}

export function updateComponentToolbar(component) {
  let toolbar = [];
  toolbar.push({
    attributes: { class: 'fa fa-arrow-up' },
    command: 'core:component-exit',
  });
  if (component.get('draggable')) {
    toolbar.push({
      attributes: { class: 'fa fa-arrows' },
      command: 'tlb-move',
    });
  }
  if (component.get('copyable')) {
    toolbar.push({
      attributes: { class: 'fa fa-clone' },
      command: 'tlb-clone',
    });
  }
  if (component.get('removable')) {
    // check for component properties before adding the button to the toolbar
    toolbar.push({
      attributes: { class: 'fas fa-trash-alt' }, // icon class from font awesome
      command: 'tlb-delete', //here you need to use your command
    });
  }
  component.set('toolbar', toolbar);
}

export function getEmbedFormContent(formId, formVersion) {
  return `<div class="gist-embed-form" data-form-type="embedded" data-form-id="${formId}" data-workspace-id="${fetchProjectSecretKey()}" version=${formVersion}></div>
<script type="text/javascript" src="${
  settings.EMBEDDED_FORMS_SCRIPT_URL
}" defer></script>
  `;
}

export function updateFormSubmitActions(object) {
  store.dispatch({
    type: actions.STORE_SUBMIT_ACTION_TYPES,
    payload: object,
  });
  store.dispatch({
    type: actions.UPDATE_SELECTED_ACTION,
    payload: object,
  });
}

export function addButtonBlock(bm, labelButton) {
  const { editorPageDetails } = store.getState().Forms,
    { templateDetails } = store.getState().Templates;
  const button = `.${gjsStylePrefix}button  {
      background-color: #37a1f6;
      color: #ffffff;
      font-weight: 600;
      border: 1px solid #37a1f6;
      font-size: $font-size-base;
      padding: 7px 15px;
      border-radius: 4px;
      outline: none !important;
      box-shadow: none;
      display: block;
      font-family: Arial, Helvetica, sans-serif;
  } 
  .${gjsStylePrefix}static-button {
  width: 300px;
  } `;

  bm.add('button', {
    label: labelButton,
    category: 'Basic',
    content: `<button class="${gjsStylePrefix}button ${gjsStylePrefix}static-button" gist-btn-type="static-button" action-type="${returnActionType(
      editorPageDetails,
      templateDetails,
      false,
    )}">Button</button>
      <style>
      ${button}
      </style>`,
    attributes: { class: 'gist-fonts gist-f-button' },
  });
}

export function returnActionType(
  editorPageDetails,
  templateDetails,
  isFormButton,
) {
  let actionType = 'submit';
  if (
    editorPageDetails.form_type === embeddedType ||
    templateDetails.form_type === embeddedType
  ) {
    if (isFormButton) {
      actionType = 'thank-you-message';
    } else {
      actionType = 'redirect';
    }
  }
  return actionType;
}

export function preFillThankYouContent(thankYouMessage, buttonId) {
  const object = {
    message: trimFunction(thankYouMessage),
    button_id: buttonId,
  };
  updateFormSubmitActions(object);
}

export function generateSubmitActionsOnDropForm(editorPageDetails, component) {
  if (
    editorPageDetails &&
    editorPageDetails.form_type === embeddedType &&
    component &&
    component.attributes &&
    component.attributes.tagName === 'form'
  ) {
    const buttonId = component
      .components()
      .models[1].getEl()
      .getAttribute('id');
    preFillThankYouContent(defaultThankYouMessage, buttonId);
  }
}

export function beautifyCode(code) {
  return beautify(code);
}
