import React, { Component, Fragment } from 'react';
import '../TopNavBar/style.scss';
import { fetchProjectSecretKey } from '../../helpers/utility';
import { Button } from 'antd';
import { store } from '../../redux/store';
import actions from '../../redux/chat/actions';
import settings from '../../settings';

export class NotificationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllowNotification:
        localStorage.getItem('isAllowNotification') === 'true'
          ? true
          : false,
    };
  }
  closeNotification = () => {
    this.setState({ isAllowNotification: true });
    localStorage.setItem('isAllowNotification', true);
  };

  notificationPermission = () => {
    let messaging;
    const This = this;
    if (window.firebase && window.firebase.messaging.isSupported()) {
      !window.firebase.apps.length
        ? window.firebase.initializeApp(settings.FIRE_BASE_CONFIG)
        : window.firebase.app();
      messaging = window.firebase.messaging();
      messaging
        .requestPermission()
        .then(function() {
          return messaging.getToken();
        })
        .then(function(token) {
          This.setState({ isAllowNotification: true });
          localStorage.setItem('isAllowNotification', true);
          store.dispatch({
            type: actions.SEND_FIRE_BASE_TOKEN,
            token,
          });
        })
        .catch(function(err) {
          This.setState({ isAllowNotification: true });
          localStorage.setItem('isAllowNotification', true);
        });
      messaging.onMessage(function(payload) {
        const { pathname } = window.location;
        if (
          !pathname.includes('conversations') &&
          payload.data.click_action.includes(fetchProjectSecretKey())
        ) {
          let notificationTitle = payload.data.title;
          let notificationOptions = {
            body: payload.data.body,
            requireInteraction: true,
            tag: payload.data.click_action,
            icon: payload.data.icon,
          };
          new Notification(notificationTitle, notificationOptions);
        }
      });
    }
  };

  render() {
    const permission = Notification.permission,
      location = this.props.location.pathname;
    return (
      <Fragment>
        {!this.state.isAllowNotification && permission === 'default' && location.includes('conversations') ? (
          <div
            className={'notification-layout allow-notification'}
            style={{
              display: this.state.isAllowNotification ? 'none' : '',
            }}
          >
            <p>
              <b>One more thing. </b> Please give us permission to send you
              browser notifications for new incoming messages.
            </p>
            <Button
              className={'btn btn-md btn-primary'}
              onClick={this.notificationPermission}
            >
              Allow notifications
            </Button>
            <i
              className={'fas fa-xmark list-item cancel'}
              onClick={() => this.closeNotification()}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}
