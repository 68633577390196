import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  fetchProjectSecretKey,
  getPromise,
  unAuthorisedRedirection,
} from '../../helpers/utility';
import { message } from 'antd';
import assistantActions from '../assistants/actions';
import chatbotActions from '../automation/chatbotTemplate/actions';
import formTemplateAction from '../automation/formTemplate/actions';
import formActions from '../../redux/forms/actions';

export function* fetchDisplayRules(params) {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/form_segments`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_DISPLAY_RULES_SUCCESS,
      payload: data.data,
    });
    if (params.isFromFormTemplate) {
      yield put({
        type: formTemplateAction.GET_DETAILS_OF_FORM_EDITOR_TEMPLATE_PAGE,
        id: params.id,
        isFromFormTemplate: params.isFromFormTemplate,
      });
    } else if (params.assistantId) {
      yield put({
        type: assistantActions.FETCH_ASSISTANT_BOT_DETAILS,
        assistantId: params.assistantId,
      });
    } else if (params.botTemplateId) {
      yield put({
        type: chatbotActions.FETCH_CHATBOT_TEMPLATE_DETAILS,
        botTemplateId: params.botTemplateId,
      });
    } else if (!params.isFromFormTemplate && params.id) {
      yield put({
        type: formActions.GET_DETAILS_OF_FORM_EDITOR_PAGE,
        id: params.id,
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Display rules fetch failed');
    }
  }
}

export function* fetchTagsList() {
  try {
    let url = `${
      settings.ROOT_URL
    }projects/${fetchProjectSecretKey()}/tags_list`;
    let data = yield call(() => getPromise(url));
    yield put({
      type: actions.FILTER_FETCH_TAGS_LIST_SUCCESS,
      data: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.error.message);
    } else {
      message.error('Fetch tags list failed');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DISPLAY_RULES, fetchDisplayRules),
    takeEvery(actions.FILTER_FETCH_TAGS_LIST, fetchTagsList),
  ]);
}
