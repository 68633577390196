import React from 'react';
import { history, store } from '../redux/store';
// import { fetchProjectSecretKey } from '../helpers/utility';
import { Spin } from 'antd';
import actions from '../redux/auth/actions';
import { antIcon } from './constants';

export class Auth extends React.Component {
  constructor(props) {
    super(props);
    if (
      !store.getState().Auth.currentUserId &&
      !store.getState().Auth.authenticateLoader
    ) {
      history.push('/login');
    }
    if (
      store.getState().Auth.currentUserId &&
      !store.getState().Auth.authenticateLoader &&
      store.getState().Auth.currentProjectSecretKey === null
    ) {
      history.push('/workspaces');
    }
  }
  componentDidMount() {
    const referrerPaths = document.location.pathname.split('/');
    if (
      referrerPaths[1] === 'invites' ||
      referrerPaths[2] === 'password' ||
      referrerPaths[2] === 'rating' ||
      referrerPaths[2] === 'email-confirmation'
    ) {
      store.dispatch({
        type: actions.AUTH_LOADER,
      });
    }
  }

  //
  // componentWillReceiveProps() {
  //   if (store.getState().Auth.currentUserId === null) {
  //     history.push('/login');
  //   } else if (store.getState().Auth.currentUserId !== null) {
  //     if (store.getState().Auth.currentProjectSecretKey === null) {
  //       history.push('/workspaces');
  //     } else {
  //       const referrerPath = localStorage.getItem('referrerPath');
  //       if (referrerPath === '/' || referrerPath === null) {
  //         history.push(`/projects/${fetchProjectSecretKey()}/contacts`);
  //       } else {
  //         history.push(referrerPath);
  //       }
  //     }
  //   }
  // }

  render() {
    return <Spin indicator={antIcon} className={'initial-loading'} />;
  }
}

export default Auth;
