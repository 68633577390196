const actions = {
  FETCH_SURVEY_ONGOING_LIST_SUCCESS: 'FETCH_SURVEY_ONGOING_LIST_SUCCESS',
  APPEND_SURVEY_ONGOING_LIST_SUCCESS: 'APPEND_SURVEY_ONGOING_LIST_SUCCESS',
  SET_SURVEY_ONGOING_TABLE_LOADER: 'SET_SURVEY_ONGOING_TABLE_LOADER',
  SET_SURVEY_ONGOING_SORT_VALUES: 'SET_SURVEY_ONGOING_SORT_VALUES',
  SET_SURVEY_ONGOING_SEARCH_VALUE: 'SET_SURVEY_ONGOING_SEARCH_VALUE',
  SET_SURVEY_ONGOING_STATUS: 'SET_SURVEY_ONGOING_STATUS',
  SET_SURVEY_ONGOING_SELECTED_ROWS: 'SET_SURVEY_ONGOING_SELECTED_ROWS',
  GET_SURVEY_DETAILS: 'GET_SURVEY_DETAILS',
  GET_SURVEY_DETAILS_SUCCESS: 'GET_SURVEY_DETAILS_SUCCESS',
  SET_SURVEY_ONGOING_TAB_KEY: 'SET_SURVEY_ONGOING_TAB_KEY',
  SET_SURVEY_ONGOING_TAG_ID: 'SET_SURVEY_ONGOING_TAG_ID',
  SET_SURVEY_ONGOING_SCHEDULE_TIME: 'SET_SURVEY_ONGOING_SCHEDULE_TIME',
  SURVEY_ONGOING_SCHEDULE_DETAILS: 'SURVEY_ONGOING_SCHEDULE_DETAILS',
  SURVEY_ONGOING_SCHEDULE_DETAILS_SUCCESS:
    'SURVEY_ONGOING_SCHEDULE_DETAILS_SUCCESS',
  REORDER_SURVEYS_LIST: 'REORDER_SURVEYS_LIST',
  SET_SURVEY_LOADER: 'SET_SURVEY_LOADER',
  UPDATE_SCHEDULE_DETAILS: 'UPDATE_SCHEDULE_DETAILS',
  CHANGE_SURVEY_POSITION: 'CHANGE_SURVEY_POSITION',
  SET_SURVEY_TABLE_DATA: 'SET_SURVEY_TABLE_DATA',
  UPDATE_SURVEY_DEADLINE_DETAILS: 'UPDATE_SURVEY_DEADLINE_DETAILS',
  SET_SURVEY_NAME: 'SET_SURVEY_NAME',
  UPDATE_SURVEY_STATUS: 'UPDATE_SURVEY_STATUS',
  UPDATE_SURVEY_STATUS_SUCCESS: 'UPDATE_SURVEY_STATUS_SUCCESS',
  UPDATE_SURVEY_STATUS_FAILURE: 'UPDATE_SURVEY_STATUS_FAILURE',
  UPDATE_SURVEY_DETAILS: 'UPDATE_SURVEY_DETAILS',
  REMOVE_DELETED_ON_GOING_SURVEY_ID: 'REMOVE_DELETED_ON_GOING_SURVEY_ID',
};

export default actions;
