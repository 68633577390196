import { store } from '../redux/store';
import chatActions from '../redux/chat/actions';
import settings from '../settings'
import { getToken } from 'firebase/messaging';

export const requestPermission = (messaging) => {
  Notification.requestPermission()
    .then((permission) => {
      if (permission === 'granted') {
        getToken(messaging, {
          vapidKey: settings.FIREBASE_VAPID_KEY,
        })
          .then((token) => {
            store.dispatch({
              type: chatActions.SEND_FIRE_BASE_TOKEN,
              token,
            });
          })
          .catch((error) => {
            console.error('Error getting token:', error);
          });
      }
    })
    .catch((error) => {
      console.error('Error with notification permission:', error);
    });
};
