export const qTypesMap = {
  PHONE_NUMBER: 'phone_number',
  EMAIL: 'email',
  WEBSITE: 'website',
  SHORT_TEXT: 'short_text',
  LONG_TEXT: 'long_text',
  NUMBER: 'number',
  DATE: 'date',
  MULTIPLE_CHOICE: 'multiple_choice',
  DROPDOWN: 'dropdown',
  OPINION_SCALE: 'opinion_scale',
  NET_PROMOTER_SCORE: 'net_promoter_scale',
  RATING: 'rating',
  EMOJI: 'emoji',
};

export const stepTypesMap = {
  CONTENT: 'content',
  INTRO: 'intro',
  ENDING: 'ending',
};

export const questionTypes = [
  {
    name: 'Contact info',
    blocks: [
      {
        id: qTypesMap.PHONE_NUMBER,
        name: 'Phone Number',
        type: 'PHONE_NUMBER',
        bg_color: '#adebe4',
        icon: 'fas fa-phone-alt',
      },
      {
        id: qTypesMap.EMAIL,
        name: 'Email',
        type: 'EMAIL',
        bg_color: '#9bcefd',
        icon: 'fas fa-envelope',
      },
      {
        id: qTypesMap.WEBSITE,
        name: 'Website',
        type: 'WEBSITE',
        bg_color: '#379cfb',
        icon: 'fas fa-link',
      },
    ],
  },
  {
    name: 'Rating & Ranking',
    blocks: [
      {
        id: qTypesMap.OPINION_SCALE,
        name: 'Opinion Scale',
        type: 'OPINION_SCALE',
        bg_color: '#fcda69',
        icon: 'fas fa-signal',
      },
      {
        id: qTypesMap.NET_PROMOTER_SCORE,
        name: 'Net Promoter Score',
        type: 'NET_PROMOTER_SCORE',
        bg_color: '#fcda69',
        icon: 'fas fa-tachometer-fast',
      },
      {
        id: qTypesMap.RATING,
        name: 'Rating',
        type: 'RATING',
        bg_color: '#fcda69',
        icon: 'fas fa-star',
      },
      {
        id: qTypesMap.EMOJI,
        name: 'Emoji',
        type: 'EMOJI',
        bg_color: '#fcda69',
        icon: 'fas fa-grin-stars',
      },
    ],
  },
  {
    name: 'Choices',
    blocks: [
      {
        id: qTypesMap.MULTIPLE_CHOICE,
        name: 'Multiple Choice',
        type: 'MULTIPLE_CHOICE',
        bg_color: '#d65c99',
        icon: 'fas fa-check',
      },
      {
        id: qTypesMap.DROPDOWN,
        name: 'Dropdown/List',
        type: 'DROPDOWN',
        bg_color: '#d65c99',
        icon: 'fas fa-angle-down',
      },
    ],
  },
  {
    name: 'Text',
    blocks: [
      {
        id: qTypesMap.SHORT_TEXT,
        name: 'Short Text',
        type: 'SHORT_TEXT',
        bg_color: '#379cfb',
        icon: 'fas fa-grip-lines',
      },
      {
        id: qTypesMap.LONG_TEXT,
        name: 'Long Text',
        type: 'LONG_TEXT',
        bg_color: '#379cfb',
        icon: 'fas fa-line-columns',
      },
    ],
  },
  {
    name: 'Number',
    blocks: [
      {
        id: qTypesMap.NUMBER,
        name: 'Number',
        type: 'NUMBER',
        bg_color: '#5cd6c8',
        icon: 'fas fa-hashtag',
      },
    ],
  },

  {
    name: 'Date & Scheduling',
    blocks: [
      {
        id: qTypesMap.DATE,
        name: 'Date',
        type: 'DATE',
        bg_color: '#85e0d5',
        icon: 'fas fa-calendar',
      },
    ],
  },
];

export const RatingStyles = [
  {
    name: 'Stars',
    key: 'stars',
    iconClass: 'fal fa-star',
  },
  {
    name: 'Hearts',
    key: 'hearts',
    iconClass: 'fal fa-heart',
  },
  {
    name: 'Users',
    key: 'users',
    iconClass: 'fal fa-user',
  },
  {
    name: 'Thumbs up',
    key: 'thumbs-up',
    iconClass: 'fal fa-thumbs-up',
  },
  {
    name: 'Crowns',
    key: 'crowns',
    iconClass: 'fal fa-crown',
  },
  {
    name: 'Cats',
    key: 'cats',
    iconClass: 'fal fa-cat',
  },
  {
    name: 'Dogs',
    key: 'dogs',
    iconClass: 'fal fa-dog',
  },
  {
    name: 'Circles',
    key: 'circles',
    iconClass: 'fal fa-circle',
  },
  {
    name: 'Flags',
    key: 'flags',
    iconClass: 'fal fa-flag',
  },
  {
    name: 'Droplets',
    key: 'droplets',
    iconClass: 'fal fa-tint',
  },
  {
    name: 'Ticks',
    key: 'ticks',
    iconClass: 'fal fa-check',
  },
  {
    name: 'Lightbulbs',
    key: 'lightbulbs',
    iconClass: 'fal fa-lightbulb',
  },
  {
    name: 'Trophies',
    key: 'trophies',
    iconClass: 'fal fa-trophy',
  },
  {
    name: 'clouds',
    key: 'clouds',
    iconClass: 'fal fa-cloud',
  },
  {
    name: 'Thunderbolt',
    key: 'thunderbolt',
    iconClass: 'fal fa-bolt-lightning',
  },
  {
    name: 'Pencils',
    key: 'pencils',
    iconClass: 'fal fa-pencil',
  },
  {
    name: 'Skulls',
    key: 'skulls',
    iconClass: 'fal fa-skull',
  },
];

export const questionSampleData = {
  PHONE_NUMBER: {
    question_type: qTypesMap.PHONE_NUMBER,
    content: "What's your phone number?",
    data: {
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      contact_property: undefined,
    },
  },
  EMAIL: {
    question_type: qTypesMap.EMAIL,
    content: "What's your work email?",
    data: {
      required: true,
      save_response_to_attribute: true,
      map_variable: false,
      attribute_identifier: null,
      placeholder: 'Your work email...',
      contact_property: 'email',
      skip_if_email_present: false,
      block_free_email: false,
      block_disposable_email: false,
    },
  },
  WEBSITE: {
    question_type: qTypesMap.WEBSITE,
    content: "What's your company website?",
    data: {
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      placeholder: 'Your company domain...',
      contact_property: undefined,
    },
  },
  SHORT_TEXT: {
    question_type: qTypesMap.SHORT_TEXT,
    content: "What's your company name?",
    data: {
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      placeholder: 'Enter text...',
      character_limit: 999,
      contact_property: undefined,
    },
  },
  LONG_TEXT: {
    question_type: qTypesMap.LONG_TEXT,
    content: 'What are your thoughts about the product?',
    data: {
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      placeholder: 'Enter text...',
      character_limit: null,
      contact_property: undefined,
    },
  },
  NUMBER: {
    question_type: qTypesMap.NUMBER,
    content: 'How many employees work at your company?',
    data: {
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      minimum_value: null,
      maximum_value: null,
      contact_property: undefined,
    },
  },
  DATE: {
    question_type: qTypesMap.DATE,
    content: 'When was your company founded?',
    data: {
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      contact_property: undefined,
    },
  },
  MULTIPLE_CHOICE: {
    question_type: qTypesMap.MULTIPLE_CHOICE,
    content:
      '<p>Which of these features would you most like us to add next?</p>',
    data: {
      options: ['Feature A', 'Feature B', 'Feature C'],
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      randomize: false,
      sort_alphabetically: false,
      multi_select: false,
      include_other: false,
      contact_property: undefined,
      additional_choice_text: 'Other',
    },
  },
  DROPDOWN: {
    question_type: qTypesMap.DROPDOWN,
    content:
      '<p>If the opportunity arises would you be interested in this?</p>',
    data: {
      options: ['Option A', 'Option B', 'Option C'],
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      randomize: false,
      sort_alphabetically: false,
      placeholder: 'Select one...',
      contact_property: undefined,
    },
  },
  OPINION_SCALE: {
    question_type: qTypesMap.OPINION_SCALE,
    content: '<p>How satisfied are you with our product?</p>',
    data: {
      options: [],
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      lower_label: 'Not satisfied',
      upper_label: 'Very satisfied',
      scale_start: 1,
      scale_end: 5,
      contact_property: undefined,
    },
  },
  NET_PROMOTER_SCORE: {
    question_type: qTypesMap.NET_PROMOTER_SCORE,
    content: '<p>How likely are you to recommend us to family and friends?</p>',
    data: {
      options: [],
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      lower_label: 'Not likely',
      upper_label: 'Very likely',
      scale_start: 0,
      scale_end: 10,
      contact_property: undefined,
    },
  },
  RATING: {
    question_type: qTypesMap.RATING,
    content: '<p>How satisfied are you with our product?</p>',
    data: {
      options: [],
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      lower_label: '',
      upper_label: '',
      scale_start: 1,
      scale_end: 5,
      rating_style: 'stars',
      contact_property: undefined,
    },
  },
  EMOJI: {
    question_type: qTypesMap.EMOJI,
    content: '<p>How satisfied are you with our product?</p>',
    data: {
      options: [
        {
          value: 1,
          unicodeEmoticon: '😠',
        },
        {
          value: 2,
          unicodeEmoticon: '🙁',
        },
        {
          value: 3,
          unicodeEmoticon: '😑',
        },
        {
          value: 4,
          unicodeEmoticon: '😄',
        },
        {
          value: 5,
          unicodeEmoticon: '😍',
        },
      ],
      required: true,
      save_response_to_attribute: false,
      map_variable: false,
      attribute_identifier: null,
      lower_label: '',
      upper_label: '',
      scale_start: 1,
      scale_end: 5,
      contact_property: undefined,
    },
  },
};

export const themes = [
  {
    font: 'Karla',
    name: 'Default Theme',
    colors: {
      question: '#000000',
      answer: '#0445AF',
      button: '#0445AF',
      button_text: '#FFFFFF',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Blue',
    colors: {
      question: '#3D3D3D',
      answer: '#4FB0AE',
      button: '#4FB0AE',
      button_text: '#FFFFFF',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Dark',
    colors: {
      question: '#37404a',
      answer: '#5c5c5c',
      button: '#37404a',
      button_text: '#FFFFFF',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Brown',
    colors: {
      question: '#9f5318',
      answer: '#cb732b',
      button: '#cb732b',
      button_text: '#FFFFFF',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Yellow',
    colors: {
      question: '#b89837',
      answer: '#e4ba3f',
      button: '#e4ba3f',
      button_text: '#201904',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Green',
    colors: {
      question: '#5b9d6f',
      answer: '#7dbb91',
      button: '#7dbb91',
      button_text: '#142519',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Pink',
    colors: {
      question: '#8b3249',
      answer: '#c75875',
      button: '#c75875',
      button_text: '#18080c',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Plain Purple',
    colors: {
      question: '#7a3d7c',
      answer: '#c384c5',
      button: '#c384c5',
      button_text: '#321832',
      background: '#FFFFFF',
    },
  },
  {
    font: 'Karla',
    name: 'Splash Sea',
    colors: {
      question: '#eeeeee',
      answer: '#eeeeee',
      button: '#eeeeee',
      button_text: '#6f6f6f',
      background: '#408e91',
    },
  },
  {
    font: 'Karla',
    name: 'Splash Blue',
    colors: {
      question: '#eeeeee',
      answer: '#eeeeee',
      button: '#eeeeee',
      button_text: '#6f6f6f',
      background: '#4fb0ae',
    },
  },
  {
    font: 'Karla',
    name: 'Splash Mud',
    colors: {
      question: '#eeeeee',
      answer: '#eeeeee',
      button: '#eeeeee',
      button_text: '#6f6f6f',
      background: '#cb732b',
    },
  },
  {
    font: 'Karla',
    name: 'Splash Green',
    colors: {
      question: '#eeeeee',
      answer: '#eeeeee',
      button: '#eeeeee',
      button_text: '#6f6f6f',
      background: '#7dbb91',
    },
  },
  {
    font: 'Karla',
    name: 'Splash Purple',
    colors: {
      question: '#eeeeee',
      answer: '#eeeeee',
      button: '#eeeeee',
      button_text: '#6f6f6f',
      background: '#c384c5',
    },
  },
  {
    font: 'Karla',
    name: 'Washed Jungle ',
    colors: {
      question: '#6cbf2c',
      answer: '#89bc62',
      button: '#c6dfb2',
      button_text: '#46672b',
      background: '#f3f9ef',
    },
  },
  {
    font: 'Karla',
    name: 'Washed Mud',
    colors: {
      question: '#e66902',
      answer: '#cb732b',
      button: '#e6bb98',
      button_text: '#663c19',
      background: '#faf1ea',
    },
  },
  {
    font: 'Karla',
    name: 'Washed Sun',
    colors: {
      question: '#e6ac00',
      answer: '#e4ba3f',
      button: '#EDD59A',
      button_text: '#735815',
      background: '#fdf8ec',
    },
  },
  {
    font: 'Karla',
    name: 'Washed Raspberry',
    colors: {
      question: '#bf395d',
      answer: '#c75875',
      button: '#e4adbc',
      button_text: '#6e2438',
      background: '#faeef1',
    },
  },
  {
    font: 'Karla',
    name: 'Washed Purple',
    colors: {
      question: '#c968cc',
      answer: '#c384c5',
      button: '#e2c3e3',
      button_text: '#703572',
      background: '#f9f3fa',
    },
  },
  {
    font: 'Karla',
    name: 'Washed Blue',
    colors: {
      question: '#38bdcf',
      answer: '#73bec8',
      button: '#bbe0e5',
      button_text: '#2d6b75',
      background: '#f1f9fa',
    },
  },
  {
    font: 'Karla',
    name: 'Filo Fax',
    colors: {
      question: '#262626',
      answer: '#262626',
      button: '#262626',
      button_text: '#e5e5e5',
      background: '#F1ECE2',
    },
  },
  {
    font: 'Karla',
    name: 'Smart Ash',
    colors: {
      question: '#F1ECE2',
      answer: '#F1ECE2',
      button: '#F1ECE2',
      button_text: '#8f7645',
      background: '#262626',
    },
  },
  {
    font: 'Roboto',
    name: 'Taxi',
    colors: {
      question: '#040404',
      answer: '#000000',
      button: '#252525',
      button_text: '#e4e4e4',
      background: '#F9CD48',
    },
  },
  {
    font: 'Lekton',
    name: 'Techie',
    colors: {
      question: '#040404',
      answer: '#7E7E7E',
      button: '#5182E0',
      button_text: '#08142a',
      background: '#F3F3F3',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#1d2b5f',
      answer: '#000123',
      button: '#cc2024',
      button_text: '#fff7ed',
      background: '#fff7ed',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#fd7121',
      answer: '#8c99ae',
      button: '#4777ca',
      button_text: '#fdfdfe',
      background: '#010e27',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#4700a8',
      answer: '#072b00',
      button: '#ff5628',
      button_text: '#fceade',
      background: '#f4f8fd',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#e193c0',
      answer: '#efeeed',
      button: '#0a061a',
      button_text: '#efeeed',
      background: '#370a8a',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#0cbc98',
      answer: '#f5eac9',
      button: '#b72055',
      button_text: '#f5eac9',
      background: '#010b16',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#1a151f',
      answer: '#1a151f',
      button: '#8c1207',
      button_text: '#fffefe',
      background: '#fcc3c3',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#181411',
      answer: '#181411',
      button: '#380fcd',
      button_text: '#fefafa',
      background: '#bbffff',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#eacf19',
      answer: '#eacf19',
      button: '#63926f',
      button_text: '#021732',
      background: '#422a2b',
    },
  },
  {
    font: 'Roboto',
    name: 'Temp',
    colors: {
      question: '#131414',
      answer: '#131414',
      button: '#ed9c28',
      button_text: '#fafefb',
      background: '#68d6d1',
    },
  },
];

export const topFonts = [
  'Alegreya Sans',
  'Alegreya',
  'Source Sans Pro',
  'Source Serif Pro',
  'Roboto',
  'Roboto Slab',
  'BioRhyme',
  'Inknut Antiqua',
  'Poppins',
  'Archivo Narrow',
  'Libre Bakserville',
  'Playfair Display',
  'Karla',
  'Lora',
  'Proza Libre',
  'Spectral',
  'IBM Plex Sans',
  'Manrope',
  'Montserrat',
  'Lato',
  'PT Sans',
  'PT Serif',
  'Cardo',
  'Chivo',
  'Neuton',
  'Rubik',
  'Open Sans',
  'Inconsolata',
  'Raleway',
  'Merriweather',
];

export const topColors = [
  [[70, 70, 255], [253, 253, 254], [2, 2, 2]],
  [[35, 32, 43], [253, 252, 252], [70, 18, 233]],
  [[253, 253, 253], [87, 91, 141], [242, 171, 70]],
  [[0, 0, 0], [250, 97, 97], [254, 251, 251]],
  [[33, 32, 33], [241, 241, 241], [156, 85, 159]],
  [[255, 255, 255], [251, 72, 43], [27, 15, 14]],
  [[255, 255, 255], [18, 183, 254], [106, 14, 138]],
  [[246, 246, 246], [50, 61, 143], [15, 173, 231]],
  [[254, 2, 24], [19, 16, 18], [237, 235, 54]],
  [[5, 1, 17], [255, 253, 255], [186, 61, 89]],
  [[2, 2, 2], [207, 52, 58], [247, 245, 245]],
  [[30, 30, 30], [251, 251, 251], [212, 54, 63]],
  [[246, 228, 171], [9, 2, 253], [8, 7, 6]],
  [[0, 0, 0], [130, 110, 97], [204, 204, 204]],
  [[0, 0, 0], [246, 246, 246], [244, 190, 37]],
  [[219, 219, 219], [67, 59, 58], [239, 107, 56]],
  [[16, 16, 16], [242, 242, 242], [165, 165, 165]],
  [[255, 255, 255], [5, 4, 4], [202, 50, 36]],
  [[251, 251, 252], [114, 96, 209], [233, 143, 176]],
  [[1, 12, 66], [237, 73, 136], [225, 222, 229]],
  [[255, 255, 255], [55, 55, 55], [233, 82, 1]],
  [[236, 234, 229], [92, 107, 170], [53, 44, 42]],
  [[14, 54, 79], [228, 226, 222], [171, 112, 72]],
  [[254, 254, 254], [142, 198, 66], [86, 76, 67]],
  [[66, 28, 27], [201, 125, 72], [238, 235, 188]],
  [[254, 254, 254], [251, 184, 21], [42, 37, 35]],
  [[0, 0, 1], [7, 7, 254], [252, 252, 254]],
  [[248, 135, 120], [254, 252, 251], [50, 76, 102]],
  [[0, 0, 0], [251, 250, 250], [231, 3, 3]],
  [[0, 31, 79], [76, 157, 169], [195, 203, 213]],
  [[255, 255, 255], [4, 4, 4], [241, 43, 123]],
  [[0, 0, 0], [245, 241, 243], [227, 73, 133]],
  [[255, 255, 255], [15, 175, 239], [10, 40, 50]],
  [[250, 61, 115], [251, 251, 251], [51, 39, 47]],
  [[255, 255, 255], [41, 183, 119], [240, 58, 58]],
  [[250, 246, 241], [48, 3, 40], [246, 7, 62]],
  [[236, 236, 234], [4, 47, 192], [5, 7, 65]],
  [[238, 238, 238], [10, 8, 51], [248, 31, 117]],
  [[30, 55, 94], [246, 247, 249], [23, 157, 234]],
  [[252, 252, 252], [39, 38, 38], [235, 91, 46]],
  [[255, 100, 125], [254, 249, 250], [82, 62, 65]],
  [[255, 255, 255], [44, 53, 59], [95, 169, 180]],
  [[255, 255, 255], [1, 1, 1], [253, 118, 93]],
  [[235, 134, 111], [137, 201, 229], [71, 68, 67]],
  [[33, 49, 92], [234, 79, 81], [246, 246, 248]],
  [[255, 255, 255], [153, 149, 2], [67, 57, 43]],
  [[239, 87, 37], [251, 246, 243], [34, 57, 62]],
  [[237, 32, 36], [251, 249, 249], [9, 4, 4]],
  [[98, 57, 0], [252, 234, 206], [84, 150, 1]],
  [[253, 253, 253], [222, 73, 46], [28, 28, 28]],
  [[248, 244, 232], [2, 85, 126], [37, 33, 34]],
  [[70, 92, 123], [193, 126, 137], [250, 168, 92]],
  [[255, 236, 157], [238, 150, 129], [7, 94, 251]],
  [[239, 228, 200], [33, 33, 30], [228, 105, 75]],
  [[224, 31, 40], [240, 239, 240], [38, 52, 67]],
  [[251, 155, 156], [164, 58, 108], [247, 197, 100]],
  [[252, 249, 240], [66, 118, 50], [124, 194, 66]],
  [[255, 183, 68], [152, 53, 89], [253, 251, 252]],
  [[232, 131, 137], [28, 36, 75], [243, 229, 108]],
  [[230, 27, 35], [5, 4, 5], [250, 250, 250]],
  [[33, 44, 96], [253, 253, 254], [55, 203, 125]],
  [[250, 250, 250], [83, 170, 236], [28, 59, 70]],
  [[253, 252, 232], [30, 21, 74], [145, 103, 40]],
  [[25, 126, 192], [58, 22, 75], [204, 225, 239]],
  [[51, 203, 204], [185, 31, 104], [250, 210, 5]],
  [[30, 30, 30], [234, 194, 24], [252, 251, 250]],
  [[0, 187, 151], [2, 6, 5], [243, 249, 247]],
  [[249, 249, 249], [31, 28, 29], [218, 80, 80]],
  [[251, 251, 251], [66, 65, 77], [53, 172, 253]],
  [[105, 81, 107], [255, 255, 255], [230, 185, 211]],
  [[231, 231, 231], [30, 30, 28], [228, 168, 40]],
  [[48, 48, 48], [236, 236, 236], [45, 200, 252]],
  [[254, 239, 234], [15, 13, 12], [230, 86, 70]],
  [[255, 255, 255], [76, 162, 213], [6, 70, 105]],
  [[255, 255, 255], [100, 139, 26], [45, 99, 57]],
  [[243, 242, 245], [229, 10, 60], [35, 14, 68]],
  [[0, 42, 66], [251, 60, 2], [244, 246, 247]],
  [[255, 255, 255], [6, 6, 5], [233, 148, 35]],
  [[43, 43, 52], [228, 41, 68], [252, 252, 252]],
  [[19, 23, 35], [219, 0, 80], [182, 193, 216]],
  [[43, 0, 74], [251, 249, 252], [24, 104, 225]],
  [[249, 105, 105], [253, 253, 253], [6, 5, 5]],
  [[246, 238, 225], [211, 79, 41], [37, 33, 34]],
  [[255, 255, 255], [5, 66, 112], [34, 172, 143]],
  [[243, 242, 238], [229, 108, 108], [32, 81, 90]],
  [[255, 255, 255], [140, 198, 65], [66, 66, 67]],
  [[227, 225, 209], [143, 33, 25], [177, 132, 19]],
  [[196, 231, 230], [239, 134, 176], [204, 44, 107]],
  [[245, 245, 245], [43, 39, 41], [229, 73, 47]],
  [[255, 255, 254], [93, 87, 107], [244, 86, 124]],
  [[255, 255, 254], [39, 96, 168], [253, 76, 9]],
  [[35, 31, 32], [253, 253, 253], [237, 28, 36]],
  [[36, 36, 36], [231, 4, 21], [243, 243, 243]],
  [[52, 48, 48], [223, 207, 157], [230, 90, 22]],
  [[205, 58, 39], [62, 36, 20], [200, 180, 144]],
  [[255, 255, 255], [14, 13, 13], [234, 37, 57]],
  [[255, 255, 255], [232, 55, 77], [103, 89, 89]],
  [[255, 255, 255], [13, 63, 109], [1, 181, 204]],
  [[252, 190, 18], [33, 27, 25], [247, 53, 2]],
  [[255, 198, 62], [59, 58, 62], [229, 183, 183]],
  [[238, 57, 50], [58, 34, 30], [231, 204, 154]],
  [[249, 249, 250], [255, 84, 56], [255, 32, 94]],
  [[255, 255, 255], [47, 58, 49], [168, 222, 64]],
  [[232, 248, 246], [27, 24, 15], [236, 189, 50]],
  [[255, 255, 255], [13, 13, 13], [95, 95, 95]],
  [[255, 255, 255], [255, 0, 84], [66, 59, 61]],
  [[255, 162, 0], [254, 254, 254], [2, 2, 2]],
  [[68, 78, 93], [239, 241, 245], [79, 168, 96]],
  [[255, 255, 255], [28, 11, 81], [218, 35, 49]],
  [[255, 255, 255], [44, 61, 63], [1, 161, 94]],
  [[15, 44, 84], [243, 82, 43], [251, 251, 252]],
  [[71, 43, 47], [253, 247, 222], [110, 193, 174]],
  [[253, 252, 251], [49, 193, 46], [243, 66, 66]],
  [[252, 252, 252], [8, 5, 251], [17, 16, 17]],
  [[1, 120, 126], [188, 188, 188], [5, 4, 129]],
  [[239, 118, 107], [253, 245, 244], [51, 45, 44]],
  [[232, 225, 215], [59, 22, 8], [207, 109, 30]],
  [[2, 2, 2], [252, 252, 252], [2, 78, 212]],
  [[254, 254, 254], [9, 9, 9], [238, 173, 56]],
  [[56, 71, 69], [241, 241, 241], [41, 125, 247]],
  [[255, 255, 255], [252, 204, 43], [42, 33, 30]],
  [[247, 233, 219], [69, 54, 58], [152, 54, 64]],
  [[255, 112, 62], [255, 254, 254], [3, 2, 1]],
  [[23, 23, 23], [217, 212, 201], [174, 106, 60]],
  [[255, 190, 46], [1, 1, 1], [218, 240, 240]],
  [[255, 255, 255], [22, 107, 134], [29, 165, 183]],
  [[61, 177, 184], [247, 188, 9], [67, 82, 84]],
  [[84, 193, 187], [250, 228, 1], [37, 33, 32]],
  [[252, 116, 0], [40, 35, 33], [251, 245, 242]],
  [[207, 21, 45], [254, 252, 253], [158, 161, 172]],
  [[0, 0, 0], [255, 254, 254], [219, 148, 176]],
  [[0, 0, 0], [246, 244, 244], [177, 22, 22]],
  [[255, 255, 255], [227, 117, 84], [76, 43, 20]],
  [[242, 245, 247], [19, 24, 36], [63, 218, 37]],
  [[244, 243, 241], [3, 55, 78], [0, 0, 1]],
  [[238, 69, 89], [255, 255, 254], [2, 1, 2]],
  [[255, 255, 255], [13, 13, 13], [203, 143, 97]],
  [[54, 50, 51], [214, 202, 161], [227, 86, 32]],
  [[25, 53, 126], [248, 249, 251], [6, 146, 214]],
  [[204, 8, 32], [95, 192, 162], [59, 62, 61]],
  [[0, 208, 194], [27, 61, 89], [182, 204, 53]],
  [[44, 41, 70], [254, 254, 254], [222, 90, 45]],
  [[255, 255, 255], [60, 168, 214], [34, 108, 139]],
  [[8, 42, 43], [154, 123, 15], [187, 197, 185]],
  [[242, 239, 233], [35, 36, 34], [225, 100, 77]],
  [[255, 255, 255], [216, 145, 49], [64, 13, 63]],
  [[33, 42, 45], [254, 254, 254], [241, 103, 151]],
  [[253, 198, 51], [27, 20, 100], [241, 63, 59]],
  [[237, 237, 237], [236, 108, 146], [9, 36, 83]],
  [[107, 173, 66], [253, 254, 252], [25, 90, 16]],
  [[43, 49, 69], [46, 203, 245], [247, 249, 250]],
  [[89, 77, 75], [238, 240, 240], [4, 182, 234]],
  [[255, 255, 255], [164, 148, 80], [15, 79, 40]],
  [[255, 255, 255], [254, 174, 70], [99, 8, 114]],
  [[255, 255, 255], [245, 100, 84], [95, 32, 27]],
  [[15, 117, 189], [208, 209, 202], [111, 59, 107]],
  [[36, 35, 35], [195, 183, 169], [152, 100, 44]],
  [[254, 254, 254], [30, 113, 164], [15, 30, 48]],
  [[252, 249, 240], [22, 25, 43], [236, 99, 51]],
  [[0, 0, 0], [231, 227, 219], [29, 172, 156]],
  [[255, 255, 255], [238, 78, 77], [66, 58, 105]],
  [[255, 255, 255], [234, 93, 62], [64, 107, 62]],
  [[121, 148, 79], [38, 41, 31], [239, 241, 235]],
  [[255, 245, 230], [215, 91, 73], [75, 62, 86]],
  [[255, 255, 255], [5, 5, 5], [189, 79, 142]],
  [[247, 234, 209], [73, 41, 69], [251, 108, 84]],
  [[0, 0, 0], [144, 79, 66], [243, 182, 108]],
  [[38, 15, 71], [250, 18, 140], [247, 240, 244]],
  [[236, 163, 35], [43, 44, 94], [249, 247, 243]],
  [[4, 57, 115], [249, 251, 252], [104, 159, 190]],
  [[255, 255, 255], [241, 134, 224], [39, 26, 40]],
  [[232, 242, 215], [79, 139, 138], [253, 109, 88]],
  [[68, 68, 68], [233, 75, 136], [238, 221, 228]],
  [[182, 187, 38], [27, 52, 58], [246, 247, 248]],
  [[22, 24, 26], [226, 174, 44], [231, 227, 211]],
  [[33, 33, 35], [249, 248, 245], [239, 204, 7]],
  [[255, 255, 255], [50, 38, 39], [249, 56, 79]],
  [[255, 255, 255], [248, 119, 61], [7, 5, 4]],
  [[255, 157, 21], [255, 252, 247], [60, 61, 61]],
  [[255, 255, 255], [223, 49, 44], [238, 139, 137]],
  [[229, 176, 192], [145, 173, 88], [178, 60, 98]],
  [[40, 32, 30], [233, 171, 81], [173, 66, 27]],
  [[20, 39, 29], [241, 243, 241], [155, 133, 46]],
  [[254, 248, 234], [88, 36, 52], [232, 61, 55]],
  [[237, 235, 212], [53, 123, 183], [206, 61, 47]],
  [[255, 255, 255], [66, 165, 246], [3, 3, 3]],
  [[254, 254, 255], [109, 57, 161], [226, 78, 108]],
  [[254, 254, 254], [8, 10, 10], [236, 56, 59]],
  [[255, 254, 254], [255, 176, 1], [255, 3, 73]],
  [[238, 237, 238], [110, 111, 114], [61, 165, 165]],
  [[255, 255, 255], [239, 161, 38], [130, 81, 64]],
  [[243, 243, 243], [45, 84, 161], [45, 155, 215]],
  [[7, 83, 158], [245, 132, 62], [244, 223, 209]],
  [[255, 255, 255], [248, 148, 15], [41, 35, 31]],
  [[255, 255, 255], [51, 51, 51], [251, 193, 51]],
  [[255, 255, 255], [250, 164, 24], [17, 53, 126]],
  [[224, 45, 48], [230, 233, 171], [74, 71, 43]],
  [[243, 243, 243], [229, 88, 10], [245, 154, 5]],
  [[237, 237, 237], [97, 98, 100], [159, 176, 50]],
  [[47, 54, 108], [253, 253, 254], [157, 164, 193]],
  [[41, 39, 68], [8, 213, 201], [246, 152, 118]],
  [[245, 162, 28], [254, 252, 249], [51, 32, 93]],
  [[203, 156, 136], [192, 37, 10], [91, 25, 13]],
  [[213, 213, 213], [232, 90, 92], [16, 24, 51]],
  [[237, 247, 246], [195, 68, 121], [56, 102, 106]],
  [[244, 244, 243], [42, 38, 38], [231, 37, 43]],
  [[255, 255, 255], [251, 202, 5], [81, 135, 190]],
  [[53, 106, 123], [219, 94, 59], [206, 200, 197]],
  [[230, 35, 41], [38, 32, 32], [250, 246, 245]],
  [[185, 7, 35], [176, 196, 202], [249, 249, 249]],
  [[237, 232, 212], [228, 69, 66], [57, 106, 146]],
  [[255, 255, 255], [21, 165, 223], [80, 108, 147]],
  [[52, 53, 48], [228, 72, 71], [231, 233, 232]],
  [[255, 255, 255], [51, 27, 112], [110, 92, 189]],
  [[13, 105, 54], [240, 247, 242], [146, 201, 76]],
  [[245, 242, 239], [218, 62, 21], [46, 23, 39]],
  [[34, 41, 134], [251, 245, 226], [121, 186, 167]],
  [[254, 250, 234], [249, 85, 132], [169, 57, 88]],
  [[254, 254, 254], [57, 57, 57], [254, 190, 32]],
  [[255, 255, 255], [35, 31, 32], [247, 94, 142]],
  [[29, 27, 40], [238, 236, 239], [237, 16, 98]],
  [[0, 82, 79], [253, 254, 255], [52, 202, 104]],
  [[255, 255, 255], [1, 0, 1], [191, 8, 78]],
  [[255, 255, 255], [255, 119, 11], [10, 8, 6]],
  [[255, 255, 255], [31, 176, 196], [235, 104, 63]],
  [[19, 34, 48], [245, 248, 254], [102, 153, 255]],
  [[6, 6, 255], [238, 239, 40], [6, 6, 5]],
  [[255, 255, 255], [29, 67, 115], [238, 57, 66]],
  [[41, 62, 64], [253, 253, 253], [133, 180, 162]],
  [[254, 254, 254], [2, 1, 1], [228, 80, 15]],
  [[251, 251, 251], [19, 114, 113], [34, 8, 78]],
  [[255, 255, 255], [253, 88, 98], [22, 16, 16]],
  [[255, 255, 255], [37, 37, 47], [65, 183, 80]],
  [[0, 0, 0], [230, 34, 36], [171, 143, 48]],
  [[55, 35, 109], [255, 71, 60], [255, 255, 255]],
  [[255, 255, 255], [35, 35, 35], [247, 2, 2]],
  [[255, 255, 255], [1, 1, 129], [255, 153, 52]],
  [[0, 0, 0], [249, 249, 249], [251, 0, 160]],
  [[63, 62, 62], [121, 199, 211], [243, 244, 244]],
  [[42, 155, 169], [236, 233, 225], [38, 47, 51]],
  [[204, 220, 228], [42, 40, 39], [235, 180, 24]],
  [[254, 254, 254], [0, 28, 102], [16, 143, 211]],
  [[233, 254, 206], [4, 5, 4], [156, 123, 82]],
  [[53, 60, 64], [106, 199, 185], [252, 254, 254]],
  [[243, 242, 233], [31, 25, 25], [254, 56, 12]],
  [[2, 180, 170], [248, 251, 252], [0, 149, 255]],
  [[255, 255, 255], [5, 5, 4], [253, 189, 12]],
  [[27, 173, 75], [8, 11, 11], [245, 246, 245]],
  [[15, 27, 46], [65, 161, 162], [210, 213, 214]],
  [[35, 31, 32], [248, 247, 247], [222, 34, 41]],
  [[31, 31, 27], [227, 184, 73], [247, 246, 239]],
  [[73, 47, 181], [253, 222, 81], [252, 252, 250]],
  [[114, 128, 143], [206, 73, 83], [242, 208, 212]],
  [[255, 255, 255], [194, 57, 57], [31, 28, 30]],
  [[250, 250, 251], [225, 96, 39], [44, 47, 50]],
  [[255, 255, 255], [255, 61, 0], [86, 81, 80]],
  [[241, 89, 42], [247, 241, 240], [22, 33, 75]],
  [[255, 255, 255], [4, 155, 203], [55, 53, 77]],
  [[164, 62, 24], [235, 230, 199], [232, 187, 79]],
  [[37, 54, 70], [233, 136, 7], [248, 248, 247]],
  [[99, 99, 169], [20, 177, 175], [108, 205, 82]],
  [[255, 254, 254], [208, 112, 56], [56, 18, 27]],
  [[255, 255, 255], [6, 6, 6], [191, 153, 90]],
  [[50, 46, 49], [254, 254, 254], [229, 164, 65]],
  [[44, 44, 44], [235, 235, 235], [8, 235, 176]],
  [[18, 18, 49], [217, 61, 121], [226, 202, 214]],
  [[50, 50, 50], [245, 123, 3], [232, 231, 230]],
  [[44, 64, 79], [192, 180, 169], [194, 93, 77]],
  [[255, 255, 255], [22, 163, 203], [39, 39, 39]],
  [[255, 255, 255], [91, 83, 132], [105, 156, 171]],
  [[22, 22, 33], [66, 90, 42], [99, 133, 49]],
  [[246, 246, 246], [6, 6, 6], [241, 147, 36]],
  [[45, 31, 67], [254, 254, 254], [228, 42, 48]],
  [[62, 60, 242], [251, 171, 41], [1, 216, 167]],
  [[232, 78, 27], [50, 26, 18], [246, 226, 195]],
  [[254, 255, 255], [3, 183, 191], [0, 96, 91]],
  [[35, 35, 35], [253, 253, 253], [11, 210, 171]],
  [[223, 223, 223], [17, 7, 34], [208, 30, 114]],
  [[0, 161, 192], [104, 78, 68], [238, 244, 236]],
  [[252, 252, 252], [43, 199, 138], [48, 48, 50]],
  [[255, 255, 255], [41, 175, 178], [88, 91, 92]],
  [[245, 240, 236], [2, 44, 107], [233, 140, 14]],
  [[255, 255, 255], [31, 28, 29], [225, 92, 52]],
  [[36, 33, 65], [253, 98, 98], [255, 236, 236]],
  [[203, 39, 1], [16, 4, 3], [250, 241, 237]],
  [[36, 32, 32], [215, 211, 206], [252, 182, 24]],
  [[47, 72, 88], [255, 255, 255], [254, 129, 1]],
  [[252, 4, 55], [204, 247, 114], [57, 75, 62]],
  [[228, 212, 178], [55, 83, 209], [245, 50, 30]],
  [[255, 255, 255], [239, 173, 10], [233, 106, 12]],
  [[250, 252, 252], [54, 57, 59], [40, 136, 239]],
  [[57, 31, 8], [254, 86, 1], [246, 247, 246]],
  [[255, 255, 255], [254, 1, 7], [20, 16, 17]],
  [[41, 62, 68], [233, 133, 34], [122, 196, 185]],
  [[246, 138, 39], [22, 77, 43], [213, 221, 58]],
  [[253, 254, 254], [254, 198, 0], [43, 52, 72]],
  [[232, 230, 230], [38, 56, 80], [211, 32, 48]],
  [[0, 0, 0], [252, 252, 252], [191, 134, 43]],
  [[57, 76, 90], [253, 253, 253], [35, 182, 150]],
  [[244, 244, 246], [49, 93, 143], [207, 113, 108]],
  [[0, 175, 163], [242, 196, 161], [59, 63, 62]],
  [[27, 20, 100], [244, 152, 246], [251, 251, 253]],
  [[255, 255, 255], [24, 103, 180], [18, 14, 16]],
  [[22, 16, 46], [158, 35, 248], [149, 133, 225]],
  [[243, 238, 218], [9, 50, 76], [242, 146, 5]],
  [[53, 54, 53], [114, 185, 144], [193, 205, 198]],
  [[253, 253, 254], [12, 3, 71], [53, 178, 208]],
  [[24, 41, 53], [223, 213, 210], [177, 115, 103]],
  [[238, 235, 228], [237, 97, 107], [108, 118, 130]],
  [[240, 234, 255], [235, 43, 58], [15, 29, 85]],
  [[255, 255, 255], [255, 186, 33], [36, 32, 33]],
  [[239, 64, 35], [84, 8, 2], [252, 236, 3]],
  [[4, 4, 4], [248, 248, 248], [230, 40, 58]],
  [[255, 255, 255], [67, 68, 70], [7, 160, 218]],
  [[31, 31, 31], [229, 65, 45], [178, 178, 178]],
  [[53, 0, 56], [251, 14, 103], [248, 230, 238]],
  [[255, 255, 255], [43, 181, 115], [12, 110, 64]],
  [[255, 255, 255], [121, 61, 11], [253, 149, 9]],
  [[255, 255, 255], [5, 5, 5], [244, 146, 122]],
  [[242, 241, 237], [35, 10, 10], [241, 113, 37]],
  [[94, 89, 32], [1, 1, 1], [241, 221, 196]],
  [[255, 255, 255], [62, 57, 57], [243, 112, 87]],
  [[30, 25, 22], [254, 254, 253], [214, 176, 115]],
  [[0, 23, 65], [3, 205, 248], [233, 239, 242]],
  [[1, 51, 52], [192, 159, 115], [238, 221, 200]],
  [[254, 249, 233], [4, 3, 3], [253, 95, 2]],
  [[255, 253, 242], [248, 57, 29], [55, 122, 173]],
  [[25, 22, 17], [249, 156, 59], [228, 82, 43]],
  [[0, 0, 0], [254, 254, 254], [104, 199, 237]],
  [[255, 255, 255], [255, 9, 9], [8, 8, 8]],
  [[37, 37, 37], [189, 138, 247], [231, 231, 232]],
  [[41, 48, 58], [251, 250, 250], [239, 73, 47]],
  [[227, 111, 111], [155, 218, 191], [82, 117, 106]],
  [[2, 1, 8], [255, 162, 0], [251, 251, 250]],
  [[245, 244, 249], [60, 81, 108], [47, 182, 234]],
  [[194, 15, 47], [253, 253, 253], [20, 55, 138]],
  [[223, 74, 74], [232, 239, 223], [25, 76, 95]],
  [[255, 0, 146], [202, 237, 233], [12, 4, 9]],
  [[34, 51, 69], [242, 238, 227], [227, 87, 31]],
  [[233, 81, 78], [222, 217, 189], [85, 72, 64]],
  [[0, 0, 0], [247, 247, 247], [215, 32, 40]],
  [[255, 255, 255], [41, 38, 79], [92, 81, 209]],
  [[16, 16, 16], [242, 242, 242], [243, 187, 1]],
  [[255, 255, 255], [1, 105, 250], [230, 113, 33]],
  [[255, 255, 255], [52, 88, 156], [208, 118, 68]],
  [[71, 184, 185], [251, 251, 252], [62, 68, 79]],
  [[255, 255, 255], [17, 31, 68], [37, 127, 197]],
  [[22, 44, 85], [1, 180, 201], [198, 238, 243]],
  [[32, 75, 102], [235, 238, 243], [230, 124, 73]],
  [[251, 176, 59], [184, 41, 45], [8, 4, 3]],
  [[50, 50, 50], [225, 227, 228], [0, 111, 186]],
  [[3, 30, 38], [206, 143, 101], [152, 73, 51]],
  [[33, 24, 57], [225, 162, 60], [231, 16, 151]],
  [[42, 41, 46], [253, 253, 253], [208, 209, 45]],
  [[251, 251, 251], [250, 202, 4], [7, 54, 53]],
  [[20, 28, 17], [248, 248, 248], [162, 135, 87]],
  [[235, 231, 220], [46, 40, 28], [141, 118, 60]],
  [[242, 242, 242], [12, 12, 11], [243, 163, 32]],
  [[255, 255, 255], [3, 3, 3], [95, 95, 95]],
  [[241, 241, 241], [33, 42, 52], [216, 166, 49]],
  [[250, 250, 250], [6, 6, 6], [96, 96, 96]],
  [[0, 1, 1], [135, 210, 218], [225, 228, 222]],
  [[251, 76, 81], [239, 238, 238], [27, 21, 20]],
  [[255, 255, 255], [176, 78, 152], [2, 157, 54]],
  [[255, 255, 255], [5, 5, 5], [190, 36, 50]],
  [[38, 40, 76], [254, 195, 160], [233, 244, 184]],
  [[254, 207, 13], [65, 190, 251], [23, 104, 196]],
  [[255, 255, 255], [48, 59, 115], [203, 94, 124]],
  [[241, 89, 59], [255, 252, 252], [65, 61, 62]],
  [[1, 0, 0], [128, 13, 0], [251, 249, 249]],
  [[15, 29, 64], [249, 204, 4], [236, 237, 233]],
  [[193, 185, 138], [5, 5, 5], [96, 91, 68]],
  [[241, 242, 242], [249, 83, 50], [34, 48, 127]],
  [[1, 59, 105], [225, 220, 190], [241, 101, 35]],
  [[255, 232, 232], [97, 206, 214], [43, 80, 174]],
  [[226, 77, 135], [254, 251, 252], [67, 53, 59]],
  [[244, 244, 244], [219, 132, 59], [80, 64, 152]],
  [[6, 6, 6], [229, 150, 73], [242, 242, 242]],
  [[22, 40, 43], [251, 253, 253], [143, 191, 186]],
  [[44, 34, 31], [164, 127, 75], [238, 231, 206]],
  [[15, 40, 55], [225, 223, 217], [159, 35, 41]],
  [[24, 51, 94], [248, 247, 247], [224, 57, 68]],
  [[239, 86, 36], [6, 4, 3], [247, 243, 239]],
  [[254, 254, 254], [234, 80, 139], [68, 67, 67]],
  [[42, 42, 42], [191, 205, 196], [148, 203, 120]],
  [[44, 85, 77], [182, 214, 191], [66, 178, 76]],
  [[0, 0, 0], [252, 250, 227], [252, 100, 100]],
  [[37, 37, 39], [253, 253, 253], [243, 71, 71]],
  [[0, 0, 0], [170, 170, 170], [248, 41, 108]],
  [[58, 58, 60], [241, 177, 28], [244, 242, 236]],
  [[255, 255, 255], [1, 1, 1], [255, 205, 3]],
  [[255, 255, 255], [76, 76, 226], [244, 174, 36]],
  [[255, 255, 255], [239, 13, 3], [220, 176, 54]],
  [[249, 177, 43], [41, 25, 26], [176, 38, 39]],
  [[33, 32, 45], [156, 252, 173], [214, 29, 88]],
  [[255, 255, 255], [234, 174, 41], [70, 69, 68]],
  [[254, 252, 231], [242, 48, 1], [40, 34, 34]],
  [[255, 255, 255], [190, 56, 47], [21, 33, 65]],
  [[255, 255, 255], [10, 97, 163], [8, 156, 203]],
  [[8, 36, 60], [246, 247, 248], [61, 154, 200]],
  [[255, 255, 255], [186, 80, 211], [175, 157, 246]],
  [[0, 20, 104], [242, 243, 248], [220, 179, 33]],
  [[0, 170, 179], [255, 255, 255], [1, 71, 76]],
  [[241, 241, 241], [169, 37, 167], [35, 5, 85]],
  [[255, 255, 255], [244, 55, 2], [73, 52, 52]],
  [[0, 0, 0], [242, 243, 151], [36, 175, 161]],
  [[42, 42, 42], [239, 181, 75], [253, 252, 250]],
  [[36, 56, 67], [249, 249, 250], [29, 195, 146]],
  [[27, 43, 58], [253, 253, 253], [2, 179, 221]],
  [[255, 255, 255], [93, 136, 213], [154, 188, 50]],
  [[0, 0, 0], [16, 115, 185], [230, 32, 44]],
  [[227, 227, 229], [39, 21, 4], [240, 101, 68]],
  [[21, 5, 82], [197, 32, 43], [208, 177, 145]],
  [[63, 62, 78], [234, 78, 135], [254, 253, 253]],
  [[24, 24, 24], [254, 254, 254], [229, 183, 122]],
  [[255, 255, 255], [221, 33, 33], [7, 7, 7]],
  [[27, 56, 83], [119, 151, 247], [239, 241, 243]],
  [[251, 250, 251], [2, 147, 221], [220, 19, 123]],
  [[246, 249, 238], [42, 43, 41], [100, 174, 46]],
  [[251, 176, 59], [31, 51, 69], [250, 250, 251]],
  [[28, 28, 30], [117, 91, 247], [116, 167, 240]],
  [[1, 116, 153], [173, 69, 54], [239, 217, 35]],
  [[246, 240, 228], [52, 49, 44], [115, 189, 72]],
  [[7, 81, 96], [202, 218, 42], [1, 183, 231]],
  [[0, 0, 0], [233, 166, 176], [245, 246, 248]],
  [[0, 144, 203], [0, 44, 80], [235, 244, 247]],
  [[255, 255, 255], [79, 79, 79], [5, 152, 255]],
  [[45, 32, 38], [235, 167, 66], [208, 84, 60]],
  [[255, 255, 255], [10, 3, 19], [228, 5, 118]],
  [[0, 124, 194], [253, 254, 253], [137, 198, 8]],
  [[72, 48, 135], [2, 209, 129], [243, 242, 246]],
  [[240, 90, 40], [252, 251, 251], [70, 67, 67]],
  [[255, 255, 255], [206, 26, 51], [9, 62, 75]],
  [[242, 242, 242], [29, 168, 102], [30, 51, 51]],
  [[0, 0, 0], [246, 240, 221], [252, 101, 2]],
  [[242, 84, 43], [252, 248, 209], [42, 40, 30]],
  [[83, 172, 186], [35, 31, 32], [247, 242, 238]],
  [[242, 244, 241], [31, 31, 31], [236, 33, 39]],
  [[255, 255, 255], [255, 69, 87], [55, 62, 166]],
  [[255, 255, 255], [243, 212, 14], [44, 53, 143]],
  [[144, 39, 142], [4, 2, 4], [238, 216, 180]],
  [[235, 73, 59], [19, 7, 6], [233, 215, 210]],
  [[35, 165, 172], [240, 239, 209], [246, 121, 34]],
  [[255, 0, 36], [24, 8, 36], [238, 234, 237]],
  [[255, 255, 255], [247, 90, 47], [6, 6, 6]],
  [[251, 251, 251], [60, 114, 157], [9, 5, 5]],
  [[255, 231, 10], [40, 62, 117], [220, 222, 230]],
  [[206, 69, 53], [27, 42, 49], [208, 198, 177]],
  [[230, 231, 233], [254, 126, 102], [64, 69, 75]],
  [[50, 71, 92], [192, 223, 244], [107, 169, 217]],
  [[0, 0, 0], [223, 223, 223], [226, 48, 59]],
  [[255, 255, 255], [6, 6, 6], [186, 18, 47]],
  [[255, 255, 255], [226, 79, 141], [15, 15, 15]],
  [[5, 0, 59], [253, 253, 253], [189, 42, 49]],
  [[52, 53, 48], [136, 197, 241], [228, 79, 81]],
  [[255, 255, 255], [3, 4, 6], [228, 7, 20]],
  [[40, 40, 40], [241, 39, 39], [243, 243, 239]],
  [[255, 255, 255], [255, 210, 7], [237, 9, 81]],
  [[16, 27, 44], [169, 130, 95], [112, 89, 70]],
  [[58, 58, 60], [193, 152, 107], [252, 251, 248]],
  [[24, 24, 25], [248, 248, 248], [205, 158, 57]],
  [[47, 44, 96], [255, 255, 255], [46, 203, 25]],
  [[22, 22, 22], [254, 0, 0], [233, 228, 228]],
  [[62, 134, 255], [253, 253, 253], [232, 63, 12]],
  [[255, 31, 81], [11, 37, 51], [255, 251, 252]],
  [[174, 174, 174], [51, 108, 213], [65, 65, 66]],
  [[254, 255, 225], [42, 56, 92], [236, 45, 60]],
  [[255, 255, 255], [26, 117, 187], [66, 68, 72]],
  [[233, 78, 56], [253, 252, 252], [30, 64, 31]],
  [[220, 70, 84], [253, 251, 251], [47, 81, 146]],
  [[255, 255, 255], [53, 17, 239], [250, 18, 30]],
  [[255, 238, 2], [20, 56, 108], [43, 41, 2]],
  [[254, 254, 253], [47, 167, 206], [250, 176, 60]],
  [[231, 231, 231], [147, 138, 89], [111, 39, 49]],
  [[220, 57, 57], [14, 6, 5], [200, 194, 135]],
  [[248, 248, 248], [0, 92, 187], [87, 87, 88]],
  [[255, 255, 255], [7, 5, 5], [251, 5, 6]],
  [[167, 255, 25], [255, 47, 1], [255, 252, 251]],
  [[51, 51, 71], [253, 253, 253], [254, 5, 191]],
  [[255, 217, 0], [82, 43, 181], [249, 247, 248]],
  [[255, 255, 255], [235, 82, 145], [56, 50, 53]],
  [[255, 255, 255], [2, 170, 234], [26, 22, 25]],
  [[255, 255, 255], [255, 54, 71], [32, 60, 91]],
  [[255, 255, 255], [38, 33, 34], [207, 23, 70]],
  [[0, 46, 86], [254, 254, 255], [44, 190, 249]],
  [[34, 31, 31], [233, 64, 53], [250, 250, 250]],
  [[212, 0, 7], [4, 2, 2], [250, 250, 250]],
  [[253, 253, 253], [8, 7, 7], [159, 132, 93]],
  [[237, 237, 237], [233, 15, 15], [14, 6, 6]],
  [[35, 72, 108], [249, 251, 250], [133, 123, 107]],
  [[240, 90, 40], [77, 70, 93], [242, 234, 229]],
  [[238, 116, 40], [52, 60, 71], [242, 232, 204]],
  [[236, 28, 26], [69, 15, 7], [249, 246, 223]],
  [[254, 223, 166], [123, 84, 50], [229, 78, 50]],
  [[199, 211, 218], [207, 82, 43], [20, 30, 9]],
  [[62, 255, 202], [254, 218, 121], [133, 94, 61]],
  [[1, 191, 156], [252, 252, 252], [68, 57, 56]],
  [[255, 255, 254], [254, 80, 2], [54, 45, 43]],
  [[245, 144, 136], [84, 113, 147], [250, 250, 183]],
  [[254, 253, 199], [245, 183, 173], [130, 81, 71]],

  [[31, 42, 91], [119, 210, 212], [253, 166, 208]],
  [[202, 28, 64], [61, 115, 163], [231, 167, 52]],
  [[168, 62, 3], [249, 252, 251], [134, 124, 117]],
  [[223, 59, 174], [117, 219, 186], [73, 1, 95]],
  [[197, 215, 219], [110, 102, 59], [155, 150, 67]],
  [[236, 235, 106], [86, 54, 12], [0, 0, 0]],
  [[85, 67, 86], [45, 82, 19], [15, 48, 27]],
  [[70, 140, 250], [59, 111, 121], [222, 222, 232]],
  [[173, 156, 188], [85, 176, 209], [44, 71, 11]],
  [[37, 151, 243], [242, 243, 243], [11, 185, 133]],
  [[245, 134, 223], [50, 149, 125], [107, 97, 82]],
  [[35, 114, 122], [253, 232, 138], [243, 46, 251]],
  [[28, 45, 38], [87, 106, 143], [202, 220, 230]],
  [[26, 126, 35], [187, 200, 168], [4, 37, 40]],
  [[173, 207, 210], [27, 81, 72], [53, 134, 118]],
  [[233, 201, 208], [200, 254, 246], [153, 169, 246]],
  [[244, 231, 237], [243, 247, 195], [112, 201, 170]],
  [[54, 32, 18], [103, 104, 104], [229, 204, 44]],
  [[115, 116, 120], [219, 178, 104], [249, 91, 53]],
  [[60, 152, 205], [197, 40, 179], [254, 195, 190]],
  [[239, 187, 238], [104, 156, 118], [31, 35, 8]],
  [[32, 95, 72], [125, 92, 102], [250, 220, 161]],
  [[51, 5, 42], [18, 65, 139], [54, 58, 46]],
  [[118, 87, 73], [114, 10, 98], [225, 157, 178]],
  [[82, 103, 39], [197, 203, 136], [158, 127, 183]],
  [[190, 216, 191], [176, 147, 47], [236, 204, 210]],
  [[107, 24, 81], [155, 110, 211], [11, 63, 89]],
  [[110, 234, 228], [83, 26, 112], [120, 124, 133]],
  [[80, 31, 48], [3, 100, 95], [122, 109, 123]],
  [[49, 69, 67], [144, 144, 144], [151, 216, 94]],
  [[233, 189, 166], [85, 84, 82], [126, 79, 76]],
  [[23, 147, 129], [171, 143, 95], [137, 211, 243]],
  [[198, 10, 126], [189, 167, 145], [48, 7, 46]],
  [[90, 61, 56], [22, 27, 14], [247, 139, 193]],
  [[42, 140, 134], [47, 94, 142], [249, 252, 211]],
  [[249, 98, 214], [22, 111, 233], [109, 60, 5]],
  [[174, 115, 209], [2, 19, 19], [206, 39, 78]],
  [[91, 22, 66], [162, 133, 119], [250, 201, 141]],
  [[71, 209, 237], [24, 25, 31], [249, 233, 241]],
  [[58, 198, 70], [251, 246, 230], [205, 206, 214]],
  [[131, 95, 31], [32, 90, 185], [229, 242, 251]],
  [[141, 141, 150], [55, 160, 147], [140, 98, 41]],
  [[170, 137, 129], [12, 35, 34], [57, 42, 42]],
  [[118, 4, 109], [34, 173, 160], [144, 80, 41]],
  [[104, 102, 113], [32, 40, 97], [217, 37, 77]],
  [[111, 49, 104], [143, 128, 72], [8, 29, 38]],
  [[153, 109, 71], [237, 211, 213], [180, 179, 129]],
  [[251, 252, 254], [59, 20, 29], [81, 95, 84]],
  [[96, 103, 169], [57, 79, 38], [233, 98, 242]],
  [[148, 109, 33], [137, 58, 182], [97, 59, 96]],
  [[192, 251, 248], [216, 204, 56], [204, 102, 55]],
  [[27, 76, 130], [245, 209, 204], [248, 155, 237]],
  [[86, 84, 81], [51, 130, 0], [241, 224, 190]],
  [[164, 156, 147], [73, 68, 82], [186, 224, 136]],
  [[34, 162, 75], [175, 141, 248], [216, 198, 118]],
  [[160, 151, 129], [131, 90, 224], [181, 253, 211]],
  [[94, 83, 97], [169, 227, 244], [87, 139, 242]],
  [[35, 47, 55], [208, 220, 251], [252, 239, 250]],
  [[23, 69, 56], [119, 10, 76], [128, 74, 107]],
  [[26, 28, 16], [246, 138, 198], [225, 222, 227]],
  [[252, 252, 251], [166, 69, 88], [51, 24, 0]],
  [[131, 146, 190], [125, 84, 102], [245, 227, 234]],
  [[139, 116, 53], [96, 79, 94], [30, 98, 212]],
  [[225, 161, 53], [126, 135, 147], [250, 241, 241]],
  [[30, 99, 41], [37, 26, 27], [184, 184, 66]],
  [[42, 35, 4], [73, 32, 55], [204, 94, 12]],
  [[90, 170, 80], [214, 185, 195], [26, 41, 98]],
  [[239, 213, 142], [39, 67, 82], [106, 236, 210]],
  [[246, 136, 70], [127, 199, 199], [242, 234, 217]],
  [[189, 190, 190], [83, 157, 97], [227, 94, 62]],
  [[162, 38, 126], [176, 170, 165], [29, 60, 50]],
  [[161, 182, 156], [238, 82, 163], [185, 51, 137]],
  [[55, 84, 89], [161, 246, 213], [207, 155, 186]],
  [[166, 83, 96], [86, 90, 29], [54, 93, 91]],
  [[245, 247, 194], [205, 130, 46], [93, 127, 9]],
  [[135, 40, 60], [215, 245, 221], [62, 232, 253]],
  [[248, 233, 247], [199, 133, 153], [0, 0, 0]],
  [[130, 87, 38], [110, 203, 220], [248, 116, 58]],
  [[229, 254, 140], [227, 229, 229], [216, 47, 9]],
  [[125, 124, 113], [125, 65, 90], [95, 2, 118]],
  [[112, 245, 252], [53, 55, 54], [205, 205, 131]],
  [[29, 74, 55], [97, 76, 85], [182, 165, 221]],
  [[252, 107, 122], [251, 219, 253], [42, 11, 3]],
  [[179, 175, 174], [218, 141, 0], [78, 250, 236]],
  [[247, 143, 226], [137, 132, 21], [46, 55, 65]],
  [[187, 202, 57], [43, 59, 68], [153, 131, 138]],
  [[166, 79, 121], [104, 70, 33], [26, 17, 3]],
  [[254, 156, 48], [184, 91, 236], [106, 92, 129]],
  [[165, 55, 62], [212, 182, 152], [127, 161, 192]],
  [[219, 215, 231], [68, 51, 44], [139, 45, 14]],
  [[71, 193, 222], [45, 117, 162], [248, 217, 241]],
  [[118, 122, 116], [176, 65, 34], [4, 38, 27]],
  [[0, 93, 12], [212, 220, 206], [224, 103, 41]],
  [[120, 118, 116], [253, 0, 7], [153, 47, 111]],
  [[29, 59, 14], [29, 161, 113], [10, 170, 160]],
  [[202, 234, 160], [36, 129, 172], [246, 114, 101]],
  [[251, 18, 101], [81, 88, 230], [234, 169, 221]],
  [[153, 216, 237], [86, 133, 120], [250, 230, 187]],
  [[8, 92, 87], [242, 19, 126], [130, 96, 83]],
  [[199, 23, 106], [30, 39, 39], [245, 245, 0]],
  [[164, 105, 146], [217, 194, 208], [195, 178, 12]],
  [[251, 174, 142], [183, 179, 199], [156, 175, 127]],
  [[214, 178, 180], [100, 118, 49], [80, 50, 21]],
  [[246, 81, 253], [208, 234, 120], [73, 54, 53]],
  [[157, 151, 181], [91, 98, 93], [145, 43, 131]],
  [[177, 137, 202], [166, 253, 243], [145, 62, 13]],
  [[122, 139, 107], [57, 41, 140], [54, 136, 203]],
  [[124, 174, 87], [246, 152, 171], [163, 93, 102]],
  [[233, 145, 231], [159, 71, 139], [215, 229, 188]],
  [[31, 56, 18], [200, 72, 69], [188, 199, 216]],
  [[148, 116, 66], [218, 230, 70], [87, 42, 103]],
  [[36, 16, 6], [214, 135, 151], [227, 80, 212]],
  [[136, 46, 167], [214, 136, 94], [106, 97, 27]],
  [[155, 228, 110], [243, 243, 243], [56, 93, 216]],
  [[200, 156, 235], [143, 91, 107], [82, 56, 66]],
  [[27, 155, 110], [253, 164, 79], [143, 147, 157]],
  [[77, 247, 194], [196, 181, 179], [220, 251, 83]],
  [[41, 130, 43], [22, 72, 82], [7, 34, 44]],
  [[41, 4, 44], [219, 176, 141], [109, 118, 73]],
  [[202, 175, 177], [175, 73, 166], [124, 93, 223]],
  [[72, 93, 54], [225, 118, 21], [248, 107, 210]],
  [[36, 75, 0], [79, 4, 58], [9, 43, 54]],
  [[149, 101, 102], [117, 221, 204], [247, 250, 129]],
  [[246, 236, 225], [105, 78, 100], [129, 154, 20]],
  [[36, 39, 45], [149, 76, 32], [95, 60, 203]],
  [[147, 165, 91], [95, 150, 156], [195, 9, 116]],
  [[22, 27, 13], [201, 227, 247], [208, 101, 175]],
  [[124, 103, 64], [206, 174, 176], [249, 134, 68]],
  [[243, 93, 0], [207, 166, 233], [140, 202, 62]],
  [[33, 125, 157], [65, 250, 244], [9, 9, 20]],
  [[66, 106, 187], [88, 202, 248], [106, 65, 46]],
  [[46, 222, 186], [142, 100, 135], [196, 102, 73]],
  [[226, 215, 216], [108, 131, 204], [117, 112, 145]],
  [[218, 248, 217], [107, 102, 67], [0, 98, 31]],
  [[200, 75, 99], [19, 62, 69], [207, 217, 208]],
  [[42, 42, 92], [248, 244, 170], [213, 239, 60]],
  [[144, 60, 95], [132, 180, 233], [201, 196, 163]],
  [[120, 193, 206], [235, 184, 157], [0, 222, 80]],
  [[81, 73, 74], [251, 225, 233], [39, 74, 14]],
  [[240, 194, 163], [58, 19, 45], [195, 201, 249]],
  [[255, 255, 255], [229, 162, 192], [175, 216, 15]],
  [[115, 26, 208], [138, 132, 167], [178, 186, 247]],
  [[110, 166, 180], [182, 117, 0], [238, 234, 232]],
  [[243, 95, 138], [162, 193, 154], [129, 214, 63]],
  [[67, 133, 169], [4, 69, 6], [39, 16, 20]],
  [[100, 69, 66], [15, 17, 6], [158, 149, 38]],
  [[143, 205, 183], [36, 43, 42], [239, 66, 127]],
  [[117, 170, 162], [115, 80, 187], [213, 196, 99]],
  [[75, 74, 143], [212, 189, 204], [40, 39, 0]],
  [[211, 127, 8], [76, 152, 193], [68, 141, 124]],
  [[87, 165, 109], [187, 134, 47], [195, 36, 37]],
  [[17, 168, 144], [60, 4, 44], [57, 97, 79]],
  [[42, 41, 5], [201, 186, 190], [149, 131, 111]],
  [[65, 20, 53], [153, 116, 228], [181, 226, 183]],
  [[169, 245, 43], [40, 18, 26], [17, 44, 38]],
  [[55, 30, 74], [201, 205, 227], [127, 80, 56]],
  [[206, 213, 254], [70, 221, 23], [165, 117, 51]],
  [[62, 6, 23], [225, 116, 222], [121, 127, 131]],
  [[27, 83, 78], [43, 25, 5], [233, 119, 92]],
  [[109, 108, 236], [105, 146, 52], [183, 62, 101]],
  [[106, 86, 97], [24, 3, 24], [158, 55, 2]],
  [[107, 200, 245], [49, 33, 152], [206, 226, 148]],
  [[93, 197, 197], [140, 249, 160], [209, 169, 128]],
  [[29, 50, 38], [31, 5, 12], [75, 143, 131]],
  [[72, 118, 4], [222, 252, 248], [153, 248, 195]],
  [[0, 231, 173], [78, 144, 180], [133, 150, 36]],
  [[210, 226, 227], [14, 26, 105], [229, 166, 102]],
  [[133, 5, 192], [160, 106, 117], [255, 170, 172]],
  [[52, 99, 149], [59, 36, 28], [190, 186, 216]],
  [[85, 119, 96], [233, 91, 146], [241, 27, 75]],
  [[196, 254, 146], [65, 115, 103], [244, 239, 234]],
  [[14, 95, 168], [240, 139, 94], [41, 32, 19]],
  [[193, 41, 95], [129, 197, 177], [177, 131, 60]],
  [[159, 93, 80], [249, 254, 110], [106, 136, 217]],
  [[86, 145, 249], [108, 99, 98], [47, 109, 101]],
  [[161, 138, 111], [13, 16, 24], [98, 153, 164]],
  [[219, 238, 245], [114, 248, 177], [186, 161, 172]],
  [[156, 151, 153], [184, 53, 133], [84, 182, 221]],
  [[179, 146, 33], [254, 226, 215], [83, 104, 119]],
  [[44, 185, 160], [174, 66, 253], [153, 92, 0]],
  [[163, 81, 15], [184, 66, 218], [136, 138, 121]],
  [[5, 30, 1], [89, 75, 56], [38, 71, 94]],
  [[45, 104, 18], [199, 188, 244], [31, 85, 133]],
  [[93, 68, 105], [70, 204, 189], [216, 172, 153]],
  [[44, 61, 140], [246, 242, 238], [123, 31, 79]],
  [[254, 167, 99], [24, 15, 31], [169, 61, 141]],
  [[164, 140, 113], [227, 240, 236], [227, 244, 200]],
  [[100, 147, 49], [247, 143, 226], [175, 132, 49]],
  [[207, 82, 92], [181, 207, 24], [238, 210, 112]],
  [[82, 168, 246], [51, 44, 46], [200, 63, 97]],
  [[56, 53, 61], [178, 118, 121], [33, 96, 53]],
  [[176, 183, 182], [246, 142, 123], [44, 15, 61]],
  [[252, 203, 200], [43, 221, 231], [223, 50, 65]],
  [[172, 169, 137], [88, 89, 89], [19, 42, 49]],
  [[75, 185, 227], [228, 208, 237], [154, 161, 64]],
  [[163, 104, 236], [159, 201, 61], [161, 66, 45]],
  [[227, 232, 242], [245, 253, 144], [191, 90, 241]],
  [[33, 131, 47], [73, 51, 55], [232, 251, 235]],
  [[143, 147, 108], [12, 85, 117], [221, 210, 26]],
  [[125, 130, 129], [176, 144, 235], [114, 185, 84]],
  [[50, 41, 49], [243, 156, 63], [150, 141, 157]],
  [[239, 254, 223], [101, 124, 168], [49, 126, 137]],
  [[150, 112, 55], [25, 172, 173], [16, 2, 22]],
  [[99, 154, 149], [12, 73, 48], [173, 154, 171]],
  [[90, 64, 56], [192, 28, 174], [124, 247, 219]],
  [[23, 15, 1], [175, 113, 194], [199, 220, 59]],
  [[204, 230, 206], [161, 154, 201], [131, 118, 120]],
  [[206, 207, 237], [7, 6, 14], [5, 104, 173]],
  [[228, 133, 82], [224, 189, 202], [36, 35, 34]],
  [[166, 19, 58], [200, 237, 217], [95, 138, 61]],
  [[77, 146, 133], [248, 241, 204], [170, 240, 221]],
  [[136, 142, 106], [83, 17, 12], [192, 215, 49]],
  [[149, 113, 99], [79, 137, 65], [250, 97, 239]],
  [[107, 15, 176], [64, 92, 91], [82, 84, 56]],
  [[119, 72, 65], [28, 47, 166], [7, 2, 0]],
  [[211, 228, 247], [217, 181, 66], [209, 132, 158]],
  [[147, 60, 74], [126, 134, 180], [39, 22, 29]],
  [[216, 185, 183], [81, 27, 102], [224, 28, 96]],
  [[11, 79, 25], [138, 75, 12], [141, 112, 54]],
  [[28, 122, 138], [63, 34, 116], [186, 176, 218]],
  [[114, 114, 64], [53, 35, 58], [171, 50, 95]],
  [[86, 31, 167], [112, 140, 123], [78, 3, 10]],
  [[5, 7, 8], [231, 146, 204], [49, 159, 58]],
  [[92, 132, 108], [119, 74, 28], [139, 158, 193]],
  [[209, 232, 43], [161, 13, 16], [77, 6, 78]],
  [[252, 169, 225], [18, 66, 22], [108, 117, 87]],
  [[65, 65, 33], [150, 136, 127], [123, 172, 166]],
  [[3, 3, 3], [242, 47, 21], [218, 252, 47]],
  [[91, 147, 164], [233, 147, 192], [53, 219, 176]],
  [[47, 105, 65], [152, 148, 151], [182, 134, 229]],
  [[12, 28, 26], [218, 35, 214], [221, 83, 59]],
  [[36, 22, 11], [190, 111, 70], [40, 71, 72]],
  [[162, 138, 94], [93, 162, 145], [72, 96, 41]],
  [[83, 199, 145], [52, 83, 100], [85, 80, 54]],
  [[249, 236, 253], [57, 24, 61], [156, 230, 0]],
  [[194, 135, 161], [113, 42, 152], [146, 174, 249]],
  [[217, 81, 51], [255, 209, 215], [147, 135, 150]],
  [[235, 241, 244], [251, 93, 247], [189, 158, 108]],
  [[168, 98, 68], [171, 252, 19], [128, 129, 85]],
  [[64, 131, 95], [107, 85, 124], [187, 109, 176]],
  [[227, 43, 213], [18, 8, 11], [67, 197, 148]],
  [[96, 103, 125], [249, 254, 97], [52, 18, 21]],
  [[254, 210, 209], [112, 42, 57], [69, 46, 77]],
  [[240, 212, 238], [185, 158, 64], [83, 99, 66]],
  [[45, 129, 167], [46, 39, 12], [191, 129, 181]],
  [[75, 76, 113], [220, 113, 250], [71, 29, 22]],
  [[146, 92, 0], [246, 141, 78], [107, 54, 61]],
  [[128, 92, 21], [203, 180, 164], [59, 3, 64]],
  [[165, 128, 43], [109, 118, 114], [175, 79, 60]],
  [[231, 197, 179], [66, 57, 103], [29, 106, 5]],
  [[250, 221, 238], [143, 168, 35], [66, 21, 39]],
  [[245, 245, 0], [151, 127, 99], [156, 118, 26]],
  [[3, 25, 20], [183, 88, 248], [154, 177, 145]],
  [[52, 2, 31], [118, 94, 139], [110, 93, 17]],
  [[146, 194, 247], [99, 6, 86], [53, 53, 70]],
  [[144, 132, 68], [126, 99, 106], [31, 125, 98]],
  [[96, 174, 100], [156, 122, 89], [241, 213, 116]],
  [[113, 109, 170], [227, 207, 169], [250, 248, 253]],
  [[44, 85, 121], [32, 56, 2], [181, 187, 108]],
  [[233, 225, 251], [109, 217, 187], [90, 200, 36]],
  [[234, 188, 120], [141, 150, 219], [133, 101, 214]],
  [[238, 197, 188], [207, 125, 180], [83, 161, 134]],
  [[232, 142, 122], [159, 46, 33], [240, 222, 254]],
  [[204, 237, 164], [43, 128, 93], [66, 60, 59]],
  [[110, 188, 195], [253, 162, 107], [15, 45, 76]],
  [[85, 195, 224], [167, 226, 169], [138, 30, 225]],
  [[177, 152, 144], [155, 78, 121], [25, 25, 25]],
  [[99, 54, 30], [241, 149, 131], [61, 145, 182]],
  [[151, 98, 78], [84, 2, 39], [54, 18, 2]],
  [[26, 66, 82], [136, 254, 205], [205, 18, 56]],
  [[0, 24, 33], [43, 14, 37], [40, 71, 24]],
  [[32, 52, 42], [59, 114, 7], [51, 26, 30]],
  [[208, 132, 62], [239, 106, 124], [104, 137, 137]],
  [[240, 207, 163], [154, 44, 54], [16, 64, 78]],
  [[36, 105, 117], [248, 191, 169], [253, 232, 135]],
  [[205, 178, 52], [39, 56, 94], [186, 82, 39]],
  [[192, 126, 135], [245, 246, 247], [76, 11, 75]],
  [[185, 168, 149], [203, 148, 248], [113, 69, 84]],
  [[53, 59, 31], [85, 107, 182], [3, 96, 108]],
  [[241, 217, 229], [149, 250, 175], [0, 0, 0]],
  [[73, 123, 135], [161, 169, 204], [251, 243, 218]],
  [[200, 106, 45], [222, 176, 241], [60, 114, 172]],
  [[188, 172, 55], [68, 51, 71], [23, 10, 15]],
  [[184, 154, 207], [221, 89, 41], [120, 139, 109]],
  [[53, 166, 148], [188, 67, 221], [60, 2, 76]],
  [[10, 18, 10], [137, 146, 146], [97, 103, 41]],
  [[184, 196, 213], [53, 12, 141], [36, 60, 34]],
  [[169, 230, 165], [183, 148, 156], [137, 83, 247]],
  [[78, 198, 209], [142, 48, 0], [181, 83, 152]],
  [[149, 140, 135], [101, 112, 38], [246, 205, 86]],
  [[111, 212, 160], [47, 25, 24], [249, 249, 249]],
  [[238, 33, 95], [88, 100, 112], [77, 66, 53]],
  [[252, 184, 172], [74, 63, 5], [114, 114, 100]],
  [[114, 206, 143], [105, 137, 138], [37, 15, 9]],
  [[68, 176, 198], [250, 115, 158], [222, 209, 97]],
  [[75, 129, 55], [140, 103, 75], [248, 225, 244]],
  [[190, 79, 99], [234, 234, 238], [243, 151, 249]],
  [[73, 12, 1], [43, 70, 131], [243, 225, 180]],
  [[118, 133, 42], [48, 70, 68], [0, 187, 149]],
  [[43, 34, 21], [134, 245, 183], [178, 101, 66]],
  [[56, 34, 25], [109, 18, 158], [161, 221, 97]],
  [[167, 128, 148], [134, 179, 66], [68, 58, 90]],
  [[24, 98, 169], [80, 92, 83], [91, 235, 0]],
  [[57, 128, 210], [117, 76, 130], [246, 123, 28]],
  [[205, 254, 104], [85, 44, 54], [79, 117, 154]],
  [[201, 198, 182], [123, 40, 226], [56, 28, 15]],
  [[250, 207, 90], [110, 130, 89], [228, 162, 231]],
  [[199, 109, 234], [220, 129, 129], [40, 128, 127]],
  [[47, 113, 139], [103, 64, 16], [66, 60, 57]],
  [[241, 78, 255], [129, 119, 145], [138, 78, 102]],
  [[244, 192, 171], [174, 146, 228], [228, 240, 234]],
  [[5, 17, 30], [107, 57, 138], [219, 158, 250]],
  [[223, 191, 180], [95, 41, 16], [27, 157, 231]],
  [[77, 26, 66], [244, 146, 221], [70, 63, 42]],
  [[138, 134, 150], [20, 154, 230], [248, 249, 250]],
  [[0, 196, 102], [194, 100, 62], [0, 0, 0]],
  [[117, 121, 0], [201, 182, 193], [14, 48, 5]],
  [[69, 175, 207], [146, 238, 209], [178, 198, 114]],
  [[221, 187, 253], [213, 242, 220], [38, 112, 110]],
  [[248, 25, 62], [205, 146, 227], [4, 3, 1]],
  [[65, 99, 104], [111, 41, 27], [46, 168, 219]],
  [[86, 90, 85], [23, 11, 1], [172, 125, 226]],
  [[114, 37, 71], [188, 124, 92], [107, 101, 217]],
  [[230, 84, 213], [162, 242, 212], [118, 116, 49]],
  [[201, 203, 249], [46, 42, 7], [216, 193, 139]],
  [[47, 4, 19], [55, 116, 61], [55, 152, 62]],
  [[95, 91, 34], [132, 189, 246], [170, 137, 159]],
  [[149, 121, 191], [6, 0, 22], [77, 111, 94]],
  [[192, 132, 160], [230, 246, 212], [46, 61, 20]],
  [[158, 125, 91], [158, 134, 188], [191, 101, 124]],
  [[128, 130, 173], [223, 223, 177], [109, 100, 7]],
  [[5, 10, 75], [14, 10, 5], [173, 131, 131]],
  [[120, 62, 0], [168, 238, 240], [69, 48, 60]],
  [[129, 37, 208], [72, 138, 63], [255, 255, 255]],
  [[229, 222, 212], [126, 96, 126], [150, 153, 253]],
  [[78, 187, 141], [134, 156, 240], [182, 72, 225]],
  [[41, 54, 59], [163, 83, 164], [150, 240, 254]],
  [[61, 65, 104], [244, 222, 66], [143, 76, 43]],
  [[144, 217, 104], [121, 57, 100], [45, 41, 119]],
  [[93, 91, 39], [146, 254, 175], [244, 157, 129]],
  [[54, 68, 20], [65, 122, 133], [10, 0, 12]],
  [[60, 5, 103], [155, 125, 150], [196, 195, 195]],
  [[142, 234, 222], [145, 13, 214], [213, 159, 120]],
  [[21, 95, 83], [122, 205, 246], [202, 194, 143]],
  [[230, 195, 197], [121, 121, 121], [67, 215, 215]],
  [[90, 106, 81], [32, 127, 169], [233, 135, 77]],
  [[237, 220, 205], [95, 245, 0], [69, 85, 41]],
  [[68, 254, 197], [193, 87, 133], [73, 20, 39]],
  [[160, 91, 134], [66, 111, 81], [253, 211, 154]],
  [[144, 83, 101], [182, 149, 106], [226, 190, 182]],
  [[192, 172, 254], [21, 102, 127], [238, 250, 223]],
  [[48, 176, 154], [227, 230, 254], [213, 187, 29]],
  [[30, 6, 11], [69, 77, 23], [157, 96, 113]],
  [[62, 31, 24], [40, 157, 153], [251, 240, 204]],
  [[246, 164, 140], [244, 178, 30], [107, 104, 116]],
  [[209, 99, 151], [74, 48, 41], [224, 198, 189]],
  [[40, 153, 125], [39, 3, 39], [254, 217, 174]],
  [[205, 207, 163], [149, 127, 113], [55, 17, 15]],
  [[81, 129, 96], [122, 110, 121], [57, 82, 107]],
  [[108, 115, 114], [5, 29, 25], [197, 136, 141]],
  [[64, 66, 24], [136, 26, 81], [134, 152, 122]],
  [[34, 95, 164], [160, 152, 57], [252, 243, 47]],
  [[131, 199, 231], [96, 49, 13], [12, 15, 6]],
  [[88, 77, 73], [186, 165, 205], [165, 62, 205]],
  [[248, 152, 94], [232, 226, 242], [95, 31, 217]],
  [[12, 24, 4], [99, 47, 20], [124, 135, 148]],
  [[170, 212, 206], [97, 95, 134], [7, 7, 7]],
  [[201, 173, 102], [181, 53, 21], [123, 126, 135]],
  [[31, 64, 67], [206, 248, 212], [60, 242, 122]],
  [[165, 254, 122], [108, 196, 249], [155, 181, 242]],
  [[243, 171, 175], [182, 200, 23], [186, 28, 211]],
  [[60, 143, 150], [152, 48, 229], [220, 220, 221]],
  [[247, 245, 252], [77, 113, 103], [48, 189, 91]],
  [[250, 228, 245], [5, 87, 104], [173, 61, 172]],
  [[193, 119, 154], [115, 101, 92], [127, 96, 46]],
  [[78, 68, 62], [172, 163, 162], [38, 49, 109]],
  [[64, 146, 108], [205, 163, 165], [178, 98, 27]],
  [[121, 176, 9], [97, 69, 41], [246, 103, 210]],
  [[142, 133, 147], [147, 209, 242], [100, 203, 204]],
  [[61, 42, 20], [190, 123, 89], [195, 202, 158]],
  [[101, 247, 241], [38, 134, 159], [82, 58, 50]],
  [[185, 158, 75], [36, 21, 59], [112, 129, 187]],
  [[150, 141, 254], [85, 175, 163], [175, 169, 169]],
  [[22, 73, 113], [255, 214, 73], [161, 191, 216]],
  [[143, 222, 15], [207, 206, 205], [80, 242, 208]],
  [[174, 54, 28], [252, 228, 242], [65, 200, 93]],
  [[250, 188, 47], [122, 102, 125], [105, 48, 51]],
  [[207, 116, 177], [208, 224, 253], [252, 212, 196]],
  [[93, 180, 169], [194, 183, 206], [129, 36, 172]],
  [[101, 59, 87], [232, 80, 237], [250, 229, 145]],
  [[185, 203, 215], [65, 200, 98], [157, 166, 78]],
  [[70, 7, 53], [95, 141, 59], [148, 190, 241]],
  [[119, 193, 206], [132, 126, 107], [116, 121, 136]],
  [[39, 145, 200], [148, 195, 160], [232, 176, 174]],
  [[229, 156, 35], [243, 170, 185], [107, 177, 133]],
  [[134, 203, 248], [13, 138, 138], [219, 25, 28]],
  [[102, 95, 21], [39, 160, 121], [36, 45, 47]],
  [[219, 242, 235], [105, 96, 63], [40, 100, 149]],
  [[255, 255, 255], [141, 171, 68], [161, 154, 229]],
  [[195, 99, 72], [191, 189, 147], [79, 102, 111]],
  [[96, 95, 69], [165, 242, 58], [73, 84, 110]],
  [[107, 130, 30], [87, 118, 132], [223, 223, 175]],
  [[219, 60, 98], [16, 114, 59], [35, 34, 177]],
  [[117, 14, 102], [205, 178, 179], [226, 142, 151]],
  [[158, 31, 200], [183, 195, 143], [179, 115, 24]],
  [[214, 226, 218], [82, 121, 140], [35, 39, 73]],
  [[36, 33, 21], [152, 145, 206], [112, 81, 116]],
  [[81, 99, 106], [118, 145, 239], [118, 120, 49]],
  [[99, 41, 22], [184, 150, 136], [127, 121, 103]],
  [[167, 57, 21], [143, 152, 135], [83, 139, 121]],
  [[4, 24, 21], [187, 38, 255], [50, 20, 42]],
  [[203, 219, 242], [202, 18, 121], [215, 158, 127]],
  [[145, 172, 244], [45, 37, 6], [20, 13, 29]],
  [[3, 39, 19], [134, 24, 29], [97, 222, 216]],
  [[49, 106, 44], [0, 22, 23], [245, 156, 54]],
  [[159, 159, 134], [218, 161, 237], [255, 189, 157]],
  [[213, 211, 210], [231, 97, 44], [12, 85, 24]],
  [[137, 79, 231], [233, 239, 107], [242, 166, 182]],
  [[90, 89, 13], [230, 210, 250], [128, 252, 124]],
  [[97, 127, 118], [160, 188, 27], [88, 74, 23]],
  [[176, 184, 167], [222, 29, 224], [121, 146, 181]],
  [[1, 37, 9], [255, 70, 45], [0, 98, 112]],
  [[205, 180, 197], [204, 117, 39], [202, 41, 49]],
  [[74, 59, 68], [109, 162, 15], [77, 129, 55]],
  [[30, 64, 69], [123, 188, 236], [27, 88, 255]],
  [[110, 130, 130], [121, 164, 255], [226, 88, 80]],
  [[110, 112, 105], [180, 187, 112], [106, 42, 201]],
  [[124, 152, 113], [1, 8, 14], [189, 208, 230]],
  [[235, 186, 254], [34, 85, 77], [239, 13, 121]],
  [[134, 125, 107], [252, 252, 252], [40, 204, 235]],
  [[98, 131, 142], [247, 155, 128], [219, 60, 187]],
  [[84, 42, 183], [120, 155, 164], [12, 107, 115]],
  [[53, 45, 7], [248, 253, 201], [8, 91, 101]],
  [[196, 196, 248], [234, 75, 57], [5, 59, 83]],
  [[79, 123, 90], [61, 49, 11], [39, 12, 19]],
  [[213, 116, 115], [114, 95, 13], [178, 171, 243]],
  [[80, 147, 224], [45, 91, 101], [151, 109, 85]],
  [[137, 216, 141], [0, 30, 10], [189, 155, 210]],
  [[62, 22, 34], [242, 193, 251], [58, 185, 141]],
  [[70, 41, 25], [254, 243, 248], [75, 13, 100]],
  [[30, 149, 218], [108, 69, 8], [165, 231, 155]],
  [[118, 118, 197], [188, 57, 125], [114, 169, 146]],
  [[55, 47, 22], [242, 218, 248], [153, 54, 8]],
  [[146, 39, 62], [129, 55, 175], [19, 139, 151]],
  [[251, 235, 215], [181, 197, 244], [63, 7, 74]],
  [[210, 26, 212], [23, 110, 108], [189, 131, 106]],
  [[0, 126, 42], [157, 109, 140], [131, 22, 0]],
  [[141, 61, 130], [158, 166, 64], [84, 193, 80]],
  [[158, 139, 148], [105, 73, 31], [55, 250, 243]],
  [[22, 22, 6], [135, 47, 88], [45, 3, 73]],
  [[76, 33, 21], [0, 16, 24], [156, 104, 67]],
  [[214, 151, 163], [24, 51, 31], [144, 76, 23]],
  [[71, 165, 178], [225, 102, 36], [161, 146, 106]],
  [[172, 89, 178], [46, 150, 120], [11, 83, 147]],
  [[179, 193, 146], [226, 62, 179], [238, 95, 72]],
  [[156, 156, 135], [83, 26, 37], [4, 103, 123]],
  [[150, 13, 216], [238, 233, 236], [33, 22, 46]],
  [[233, 252, 218], [39, 64, 0], [159, 151, 135]],
  [[204, 99, 6], [153, 3, 110], [40, 115, 98]],
  [[75, 175, 174], [198, 87, 172], [44, 99, 172]],
  [[80, 131, 231], [128, 34, 118], [63, 38, 2]],
  [[48, 73, 94], [215, 187, 113], [201, 79, 106]],
  [[143, 50, 94], [43, 120, 116], [15, 37, 29]],
  [[32, 140, 25], [30, 13, 78], [32, 32, 17]],
  [[219, 158, 102], [58, 59, 57], [243, 118, 231]],
  [[57, 125, 106], [196, 119, 95], [172, 183, 153]],
  [[217, 171, 232], [20, 20, 0], [76, 32, 31]],
  [[10, 36, 21], [43, 25, 39], [229, 220, 233]],
  [[6, 69, 105], [134, 172, 75], [74, 55, 36]],
  [[92, 178, 157], [173, 138, 87], [61, 98, 163]],
  [[87, 10, 88], [81, 80, 4], [109, 96, 87]],
  [[33, 38, 1], [217, 110, 144], [143, 64, 70]],
  [[76, 95, 195], [138, 187, 226], [234, 232, 76]],
  [[4, 28, 8], [87, 197, 166], [58, 52, 89]],
  [[24, 90, 139], [30, 176, 155], [95, 50, 106]],
  [[84, 136, 124], [245, 241, 63], [163, 160, 180]],
  [[239, 219, 158], [96, 30, 14], [62, 54, 28]],
  [[180, 179, 36], [42, 5, 63], [128, 40, 101]],
  [[26, 25, 17], [182, 235, 36], [134, 136, 112]],
  [[99, 104, 136], [219, 46, 67], [127, 137, 64]],
  [[25, 59, 63], [249, 172, 122], [200, 107, 12]],
  [[200, 186, 75], [82, 197, 182], [123, 143, 221]],
  [[16, 13, 15], [244, 246, 252], [40, 88, 102]],
  [[90, 247, 101], [100, 250, 254], [199, 156, 139]],
  [[92, 45, 169], [175, 253, 176], [124, 135, 105]],
  [[188, 185, 201], [148, 33, 23], [49, 56, 53]],
  [[54, 68, 20], [0, 120, 212], [208, 189, 173]],
  [[255, 180, 88], [93, 147, 79], [129, 72, 36]],
  [[94, 79, 72], [166, 161, 197], [156, 210, 249]],
  [[242, 207, 66], [216, 110, 147], [146, 146, 122]],
  [[112, 207, 244], [130, 101, 66], [235, 193, 234]],
  [[245, 148, 123], [78, 9, 69], [114, 113, 118]],
  [[76, 61, 32], [135, 206, 226], [117, 213, 179]],
  [[56, 162, 123], [73, 79, 95], [21, 127, 150]],
  [[243, 138, 134], [30, 47, 45], [174, 87, 156]],
  [[79, 54, 20], [60, 3, 46], [4, 11, 42]],
  [[112, 216, 226], [147, 130, 163], [224, 98, 183]],
  [[181, 143, 177], [58, 3, 54], [91, 181, 112]],
  [[205, 154, 128], [152, 46, 94], [255, 255, 255]],
  [[165, 62, 151], [80, 173, 237], [250, 147, 222]],
];

export const fullResponse = {
  id: 13340267,
  completed_time: '2022-03-25T18:09:56.686Z',
  start_time: '2022-03-25T18:02:33.056Z',
  created_at: '2022-03-25T18:07:33.284Z',
  updated_at: '2022-03-25T18:14:25.144Z',
  language: 'en',
  time_taken: '443.00',
  status: 'COMPLETED',
  channel_type: 'EMBED',
  device_type: 'DESKTOP',
  ip: '109.43.112.160',
  survey_id: 31351,
  submission_id: 13355166,
  contact: {
    id: null,
    name: null,
    email: null,
  },
  meta_data: {
    os: 'Windows NT 10.0',
    browser: 'Firefox 98.0',
    time_zone: 'Europe/Berlin',
    browser_language: 'de',
  },
  responses: {
    411113: 'Carsten',
    411125: 'support@getgist.com',
  },
};

export const response = {
  id: 13340267,
  411113: 'Carsten',
  411125: 'support@getgist.com',
};

export const surveyQuestions = [
  {
    id: 411121,
    summary: {
      count: 45,
      total: 45,
    },
    title: "What's your phone number?",
    type: 'PHONE_NUMBER',
  },
  {
    id: 411122,
    summary: {
      count: 45,
      total: 45,
    },
    title: "What's your email address?",
    type: 'EMAIL',
  },
  {
    id: 411123,
    summary: {
      count: 45,
      total: 45,
    },
    title: "What's your company domain?",
    type: 'WEBSITE',
  },
  {
    id: 411124,
    summary: {
      count: 43,
      total: 45,
    },
    title: "What's your name?",
    type: 'SHORT_TEXT',
  },
  {
    id: 411125,
    summary: {
      count: 43,
      total: 45,
    },
    title: 'How would you describe your experience?',
    type: 'LONG_TEXT',
  },
  {
    id: 411126,
    summary: {
      count: 43,
      total: 45,
    },
    title: 'How many employees work at your company?',
    type: 'NUMBER',
  },
  {
    id: 411127,
    summary: {
      count: 43,
      total: 45,
    },
    title: 'When was your company founded?',
    type: 'DATE',
  },
  {
    id: 411128,
    summary: {
      count: 43,
      total: 45,
    },
    title: 'What is your favourite fruit?',
    type: 'MULTIPLE_CHOICE',
  },
];

const phoneResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: 'jacob@kundetjek.dk',
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: '+91 8293054356',
    respondent: {
      display_name: 'Danny',
    },
  },
];

const emailResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: 'yordan_ss@abv.bg',
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: 'adam@sosocial.se',
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: 'dimitris@albasolution.gr',
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: 'lucie@applegebruikenmetgemak.nl',
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: 'jacob@kundetjek.dk',
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: 'lilleberre@gmail.com',
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: 'bassemkhozam@gmail.com',
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: 'mcleed.fr@gmail.com',
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: 'dsantos@credito.com.do',
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: 'epsilon.mi@gmail.com',
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: 'dannyfoo@gmail.com',
    respondent: {
      display_name: 'Danny',
    },
  },
];

const websiteResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: 'abv.bg',
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: 'sosocial.se',
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: 'albasolution.gr',
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: 'applegebruikenmetgemak.nl',
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: 'kundetjek.dk',
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: 'gmail.com',
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: 'gmail.com',
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: 'gmail.com',
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: 'credito.com.do',
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: 'gmail.com',
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: 'gmail.com',
    respondent: {
      display_name: 'Danny',
    },
  },
];

const shortTextResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: 'Yordan',
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: 'Adam',
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: 'Dimitris',
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: 'Lucie',
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: 'Jacob',
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: 'Lille Berre',
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: 'Bassem',
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: 'Mc Leed',
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: 'Santos',
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: 'Epsilon',
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: 'Danny',
    respondent: {
      display_name: 'Danny',
    },
  },
];

const numberResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: 7,
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: 8,
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: 1,
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: 5,
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: '6',
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: 8,
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: 2,
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: 4,
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: 3,
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: 8,
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: 9,
    respondent: {
      display_name: 'Danny',
    },
  },
];

const dateResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: '7',
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: '8',
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: '1',
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: '5',
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: '6',
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: '8',
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: '2',
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: '4',
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: '3',
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: '8',
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: '9',
    respondent: {
      display_name: 'Danny',
    },
  },
];

const multipleChoiceResponses = [
  {
    submitted_at: 1515611530,
    id: '3bbcfda0ceb37c1142544a91196a4407',
    value: ['Apple'],
    respondent: {
      display_name: 'Yordan',
    },
  },
  {
    submitted_at: 1515150479,
    id: 'eb7dc4d4a772e6f21a6aad8eecb97acd',
    value: ['Orange'],
    respondent: {
      display_name: 'Adam',
    },
  },
  {
    submitted_at: 1514568066,
    id: '8e0f8ddde9aede601db2435bbcc83d42',
    value: ['Apple'],
    respondent: {
      display_name: 'Dimitris',
    },
  },
  {
    submitted_at: 1514540100,
    id: '63c2828d171e0eae7f6b0707b92f0324',
    value: ['Banana'],
    respondent: {
      display_name: 'Lucie',
    },
  },
  {
    submitted_at: 1514401500,
    id: 'bd0ab9af5636e0800fc38072cc96d249',
    value: ['Mango'],
    respondent: {
      display_name: 'Jacob',
    },
  },
  {
    submitted_at: 1514056759,
    id: 'afb301d956dcac03164a0f7f39cfa4bf',
    value: ['Grape'],
    respondent: {
      display_name: 'Lille Berre',
    },
  },
  {
    submitted_at: 1514022794,
    id: 'dd0dad0dd60bcf2b25fb92f7a632ed7f',
    value: ['Papaya'],
    respondent: {
      display_name: 'Bassem',
    },
  },
  {
    submitted_at: 1513943869,
    id: 'b72619c71e94df38181298416602c05b',
    value: ['Grape'],
    respondent: {
      display_name: 'Mc Leed',
    },
  },
  {
    submitted_at: 1513831218,
    id: '6d7fda0d56dbb44173904ddafeb99035',
    value: ['Mango'],
    respondent: {
      display_name: 'Santos',
    },
  },
  {
    submitted_at: 1513814158,
    id: '94bddcaf235da54ad653a3ba1772cdf0',
    value: ['Guava'],
    respondent: {
      display_name: 'Epsilon',
    },
  },
  {
    submitted_at: 1513792365,
    id: '80ca13da12a23de51583009c061eea11',
    value: ['Strawberry'],
    respondent: {
      display_name: 'Danny',
    },
  },
];

// For Responses Table
export const surveyResponses = {
  submissions: [
    response,
    response,
    response,
    response,
    response,
    response,
    response,
    response,
    response,
    response,
    response,
    response,
  ],
};

export const surveySummary = {
  411121: phoneResponses,
  411122: websiteResponses,
  411123: emailResponses,
  411124: shortTextResponses,
  411125: shortTextResponses,
  411126: numberResponses,
  411127: dateResponses,
  411128: multipleChoiceResponses,
};

export const imageEditButtonsSurvey = [
  'imageLink',
  'imageRemove',
  'linkEdit',
  'imageAlign',
  'linkRemove',
];
