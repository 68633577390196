import React, { Component } from 'react';
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  Row,
  Divider,
  Col,
} from 'antd';
import '../../style/_drawer.scss';
import './style.scss';
import moment from 'moment';
import actions from '../../redux/CRM/actions';
import { store } from '../../redux/store';
import {
  fetchProjectSecretKey,
  extractCurrentUserId,
} from '../../helpers/utility';
import { NavLink } from 'react-router-dom';
import { debounce } from 'lodash';
import ProfileAvatar from '../Chat/Conversations/ProfileAvatar';
import { getLoaderText } from './helper';

const { Option } = Select,
  { TextArea } = Input;

class CreateDealDrawerForm extends Component {
  constructor(props) {
    super(props);
    this.selectedId = [];
    this.searchValue = '';
    this.companySearchValue = '';
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form, dealDetails, fetchContactOrCompanyDeals } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const data = {
          deal: {
            name: values.name,
            user_id: values.user_id,
            amount: values.amount,
            company_id: values.company_id ? values.company_id : null,
            description: values.description,
            associated_contact_ids: values.associated_contact_ids
              ? values.associated_contact_ids.map(contact => Number(contact))
              : null,
            close_at: moment(values.close_at).format(),
            stage_id: values.stage_id,
          },
        };
        if (dealDetails) {
          if (dealDetails.pipeline_id !== values.pipeline_id) {
            data.pipeline_changed = true;
          }
          store.dispatch({
            type: actions.UPDATE_DEAL,
            payload: data,
            id: dealDetails.id,
            pipeline_id: dealDetails.pipeline_id,
            stage_id: dealDetails.stage_id,
          });
        } else {
          store.dispatch({
            type: actions.CREATE_DEAL,
            payload: data,
            pipeline_id: values.pipeline_id,
            stage_id: values.stage_id,
            fetchContactOrCompanyDeals,
          });
        }
      }
    });
  };

  handlePipelineChange = value => {
    this.props.form.setFieldsValue({
      stage_id: undefined,
    });
    store.dispatch({
      type: actions.GET_STAGES_LIST,
      pipelineID: value,
    });
  };

  handleCompanySearch = value => {
    if (value) {
      this.debounceCompanyList(value);
    }
  };

  debounceCompanyList = debounce(searchText => {
    store.dispatch({
      type: actions.GET_DEAL_COMPANY_LIST,
      searchText,
    });
    this.companySearchValue = searchText;
  }, 500);

  handleSearch = value => {
    this.debounce(value);
  };

  debounce = debounce(searchText => {
    let Id = this.selectedId.join(',');
    store.dispatch({
      type: actions.GET_CRM_CONTACT,
      payload: { searchText, Id },
    });
    this.searchValue = searchText;
  }, 500);

  contactChange = values => {
    this.selectedId = values;
  };

  render() {
    const {
      visible,
      pipelineList,
      stages,
      dealDetails,
      form,
      isEdit,
      contacts,
      contactListLoader,
      createDealBtnLoader,
      currentPipeline,
      userList,
      companyList,
      companyListLoader,
      isFromContacts,
      isFromCompany,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Drawer
        width={520}
        closable={false}
        onClose={() => this.props.handleCreateDealDrawer(false)}
        visible={visible}
        className={'deal-drawer'}
        destroyOnClose={true}
      >
        <div className="heading">
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <h2>{`${isEdit ? 'Edit' : 'Create'} Deal`}</h2>
            </Col>
            <Col span={6} style={{ fontSize: '14px' }}>
              {dealDetails && (
                <NavLink
                  to={`/projects/${fetchProjectSecretKey()}/deal/${
                    dealDetails.secret_key
                  }`}
                >
                  View deal profile
                </NavLink>
              )}
            </Col>
          </Row>
        </div>
        <div className="content">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <label className={'select-label'}>Name</label>
              <Form.Item>
                {getFieldDecorator('name', {
                  initialValue:
                    dealDetails && dealDetails.name
                      ? dealDetails.name
                      : undefined,
                  rules: [
                    { required: true, message: 'Please input your deal name!' },
                  ],
                })(<Input className={'form-item input-md'} maxLength={120} />)}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Description</label>
              <Form.Item>
                {getFieldDecorator('description', {
                  initialValue:
                    dealDetails &&
                    dealDetails.data &&
                    dealDetails.data.description
                      ? dealDetails.data.description
                      : undefined,
                })(<TextArea rows={2} className={'form-item input-md'} />)}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Pipeline</label>
              <Form.Item>
                {getFieldDecorator('pipeline_id', {
                  initialValue:
                    dealDetails && dealDetails.pipeline_id
                      ? dealDetails.pipeline_id
                      : currentPipeline && currentPipeline.id,
                  rules: [
                    { required: true, message: 'Please select your pipeline!' },
                  ],
                })(
                  <Select
                    placeholder="Select pipeline"
                    className={'form-item select-md'}
                    suffixIcon={<i className={'fas fa-caret-down'} />}
                    onChange={this.handlePipelineChange}
                  >
                    {pipelineList.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Stage</label>
              <Form.Item>
                {getFieldDecorator('stage_id', {
                  initialValue:
                    dealDetails && dealDetails.stage_id
                      ? dealDetails.stage_id
                      : stages.length
                        ? stages[0].id
                        : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Please select your deal stage!',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select stage"
                    className={'form-item select-md'}
                    suffixIcon={<i className={'fas fa-caret-down'} />}
                  >
                    {stages.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Amount</label>
              <Form.Item>
                {getFieldDecorator('amount', {
                  initialValue:
                    dealDetails && dealDetails.data && dealDetails.data.amount
                      ? dealDetails.data.amount
                      : undefined,
                  rules: [
                    { required: true, message: 'Please input your amount!' },
                  ],
                })(
                  <InputNumber
                    min={0}
                    formatter={value =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    className={'form-item input-number-md'}
                  />,
                )}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Close date</label>
              <Form.Item>
                {getFieldDecorator('close_at', {
                  initialValue:
                    dealDetails && dealDetails.data && dealDetails.data.close_at
                      ? moment.unix(dealDetails.data.close_at)
                      : moment().add(5, 'd'),
                  rules: [
                    {
                      required: true,
                      message: 'Please select your close date!',
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format="MMM DD, YYYY"
                    style={{ width: '100%' }}
                    className={'form-item input-date-picker-md'}
                    suffixIcon={<i className={'fal fa-calendar'} />}
                  />,
                )}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Deal owner</label>
              <Form.Item>
                {getFieldDecorator('user_id', {
                  initialValue:
                    dealDetails && dealDetails.user_id
                      ? dealDetails.user_id
                      : extractCurrentUserId(),
                  rules: [
                    {
                      required: true,
                      message: 'Please select your deal owner!',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select deal owner"
                    className={'form-item select-md w-full deal-owner-select'}
                    suffixIcon={<i className={'fas fa-caret-down'} />}
                    showSearch
                    optionFilterProp="name"
                    dropdownClassName={'profile-dropdown'}
                  >
                    {userList && userList.map((teamMate, index) => (
                      <Option
                        value={teamMate.id}
                        key={index}
                        name={teamMate.name}
                      >
                        <ProfileAvatar
                          profile={teamMate}
                          className={'sm'}
                          displayProfile={true}
                        />
                        <span className={'truncate'}>{teamMate.name || teamMate.full_name}</span>
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Divider className="mt-0" />
            <Row>
              <h3>Associate deal with</h3>
            </Row>
            <Row>
              <label className={'select-label'}>Company</label>
              <Form.Item>
                {getFieldDecorator('company_id', {
                  initialValue:
                    dealDetails && dealDetails.company_id
                      ? dealDetails.company_id
                      : isFromCompany
                      ? companyList[0].id
                      : undefined,
                })(
                  <Select
                    loading={companyListLoader}
                    placeholder="Select company"
                    className={'form-item select-md'}
                    suffixIcon={<i className={'fas fa-caret-down'} />}
                    showSearch={true}
                    onSearch={this.handleCompanySearch}
                    optionFilterProp="name"
                    dropdownRender={menu => (
                      <div className={'select-dropdown-list'}>
                        {getLoaderText({
                          loader: companyListLoader,
                          data: menu,
                          searchValue: this.companySearchValue,
                          placeholder: 'Type to search a companies...',
                          list: companyList,
                        })}
                      </div>
                    )}
                  >
                    {companyList.map((company, index) => (
                      <Option
                        value={company.id}
                        key={index}
                        name={company.name}
                      >
                        <span className={'truncate'}>{company.name}</span>
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Row>
              <label className={'select-label'}>Contact</label>
              <Form.Item>
                {getFieldDecorator('associated_contact_ids', {
                  initialValue:
                    dealDetails && dealDetails.associated_contacts
                      ? dealDetails.associated_contacts.map(contact =>
                          contact.id.toString(),
                        )
                      : isFromContacts
                      ? [contacts[0].id.toString()]
                      : undefined,
                })(
                  <Select
                    mode="multiple"
                    placeholder="Select contact"
                    className={'form-item select-md deal-owner-select'}
                    suffixIcon={<i className={'fas fa-caret-down'} />}
                    showSearch
                    optionFilterProp="name"
                    onSearch={this.handleSearch}
                    onChange={this.contactChange}
                    dropdownRender={menu => (
                      <div className={'select-dropdown-list'}>
                        {getLoaderText({
                          loader: contactListLoader,
                          data: menu,
                          searchValue: this.searchValue,
                          placeholder: 'Type to search a contacts...',
                          list: contacts,
                        })}
                      </div>
                    )}
                  >
                    {contacts.map((contact, index) => (
                      <Option
                        value={contact.id.toString()}
                        key={index}
                        name={contact.email}
                      >
                        <img src={contact.avatar_url} alt={'user'} />
                        <span className={'truncate'}>
                          {contact.email} ({contact.person_chat_name})
                        </span>
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Form.Item>
              <Button
                htmlType={'submit'}
                className="btn btn-primary btn-sm"
                loading={createDealBtnLoader}
                style={{
                  marginRight: 8,
                }}
              >
                {`${isEdit ? 'Save' : 'Create'}`}
              </Button>
              <Button
                className="btn btn-secondary-outline btn-md"
                onClick={() => this.props.handleCreateDealDrawer(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    );
  }
}
const CreateDealDrawer = Form.create()(CreateDealDrawerForm);
export default CreateDealDrawer;
