import React from 'react';
import { Popover, Tooltip } from 'antd';
import Emojis from './Emojis';

const EmojisHolder = props => (
  <Tooltip title="Emoji" placement={'top'}>
    <Popover
      content={<Emojis onSelectEmojis={props.onSelectEmojis} />}
      trigger="click"
      visible={props.visible}
      onVisibleChange={props.onVisibleChange}
      overlayClassName="emojis"
    >
      <i className={'fal fa-face-smile'} />
    </Popover>
  </Tooltip>
);

export default EmojisHolder;
