import React from 'react';
import '../style.scss';

const DropdownComponent = props => {
  const { component, onChange } = props;

  return (
    <div
      className={`dropdown-container${component.disabled ? ' disabled' : ''}${
        component.save_state === 'failed' ? ' dropdown-failed' : ''
      }`}
      {...(component.id ? { 'data-component-id': component.id } : {})}
    >
      <label className="label-dropdown-container">{component.label}</label>

      <div className="dropdown-options-container">
        <select
          className="dropdown-options"
          onChange={event => {
            onChange(component.id, event.target.value);
          }}
        >
          <option
            className="dropdown-option"
            {...(!component.value ? { selected: true } : {})}
            {...{ disabled: true }}
            {...{ hidden: true }}
          >
            Choose one...
          </option>
          {component.options &&
            component.options.map((option, index) => (
              <option
                className="dropdown-option"
                key={index}
                value={option.value}
                {...(option.id ? { 'data-component-id': option.id } : {})}
                {...(component.value && component.value === option.value
                  ? { selected: true }
                  : {})}
                {...(option.disabled || component.disabled
                  ? { disabled: true }
                  : {})}
              >
                {option.text}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownComponent;
