import { store } from '../../../redux/store';
import { updateBrowserHistory } from '../../../helpers/utility';

export function getTableValues() {
  const { tableState } = store.getState().SharedReducers;
  let detailObj = {
    tableState,
    lastId: 'last_triggered_chat_id',
    api: 'triggered_chats',
  };
  switch (tableState) {
    case 'chatOnGoing':
      return {
        ...detailObj,
        sortKey: store.getState().ChatOngoingReducer.sortKey,
        sortOrder: store.getState().ChatOngoingReducer.sortOrder,
        status: store.getState().ChatOngoingReducer.status,
        searchValue: store.getState().ChatOngoingReducer.searchValue,
        messageType: 'on_going_chat',
      };
    case 'chatOneOff':
      return {
        ...detailObj,
        sortKey: store.getState().ChatOneOffReducer.sortKey,
        sortOrder: store.getState().ChatOneOffReducer.sortOrder,
        status: store.getState().ChatOneOffReducer.status,
        searchValue: store.getState().ChatOneOffReducer.searchValue,
        messageType: 'one_off_chat',
      };
    default:
      return detailObj;
  }
}

export function chatDrawerVisibility() {
  updateBrowserHistory('chat/choose-template');
}
