import React, { useEffect, useRef, useState } from 'react';
import { timeDifference } from '../../helpers/utility';
import { timeAgoInterval } from './Conversations/constants';
import Moment from 'react-moment';
import { Tooltip } from 'antd';

const LiveTimeAgo = props => {
  const [timeValue, setTimeValue] = useState(
    timeDifference(props.time, props.displayAgo),
  );
  const { time, displayAgo, isShowInBottomLeft } = props;
  useEffect(
    () => {
      const intervalId = setInterval(() => {
        setTimeValue(timeDifference(time, displayAgo));
      }, timeAgoInterval);
      return () => {
        clearInterval(intervalId);
      };
    },
    [time, displayAgo],
  );

  useEffect(
    () => {
      setTimeValue(timeDifference(time, displayAgo));
    },
    [time, displayAgo],
  );
  return (
    <Tooltip
      placement={isShowInBottomLeft ? 'bottomLeft' : 'bottom'}
      title={<Moment date={time} format={'Do MMM YYYY, hh:mm A'} />}
    >
      {timeValue}
    </Tooltip>
  );
};

export default LiveTimeAgo;
