export const repliesReport = {
  conversationReliedTo: {
    color: '#a291ff',
    title: 'Conversations replied to',
    case: 'direct',
    isTime: false,
    tooltip:
      'The number of conversations replied to for each day of the currently selected time period.',
  },
  repliesSend: {
    color: '#ffc95b',
    title: 'Replies sent',
    case: 'direct',
    isTime: false,
    tooltip:
      'The number of replies for each day of the currently selected time period.',
  },
};
export const reportSummary = {
  medianFirstResponseReports: {
    color: '#ff9261',
    title: 'Median first response time',
    isTime: true,
    case: 'in-direct',
    tooltip:
      'The median time between a conversation being created and the first response sent by a teammate.',
  },
  medianCloseReports: {
    color: '#ff6d8b',
    title: 'Median time to close',
    isTime: true,
    case: 'in-direct',
    tooltip:
      'The median time between a conversation’s first contact initiated message and it being closed for the final time.',
  },
};

export const compareList = {
  newConversations: 'New conversations',
  closedConversations: 'Closed conversations',
  customersReached: 'Customers reached',
  avgConversations: 'Avg conversations per day',
  avgResolutions: 'Avg resolutions per day',
  avgCustomers: 'Avg customers per day',
};

export const exportSummaryList = {
  medianFirstResponseTime: 'Median first response time',
  medianResponseTime: 'Median response time',
  medianTimeToClose: 'Median time to close',
  avgRepliesToResolution: 'Avg replies to resolution',
};

export const temmatesAndTeamsDataList = {
  conversationReliedTo: {
    color: '#a291ff',
    title: 'Conversations replied to',
    isTime: false,
    case: 'direct',
    tooltip:
      'The number of conversations replied to for each day of the currently selected time period.',
  },
  repliesSend: {
    color: '#ffc95b',
    title: 'Replies sent',
    isTime: false,
    case: 'direct',
    tooltip:
      'The number of replies for each day of the currently selected time period.',
  },
  closedConversationReports: {
    color: '#24e1c0',
    title: 'Closed conversations',
    isTime: false,
    case: 'direct',
    tooltip:
      'The number of conversations that were closed, and have not been reopened since, for the currently selected time period.',
  },
  medianFirstResponseReports: {
    color: '#ff9261',
    title: 'Median first response time',
    isTime: true,
    case: 'in-direct',
    tooltip:
      'The median time between a conversation being created and the first response sent by a teammate.',
  },
  medianCloseReports: {
    color: '#ff6d8b',
    title: 'Median time to close',
    isTime: true,
    case: 'in-direct',
    tooltip:
      'The median time between a conversation’s first contact initiated message and it being closed for the final time.',
  },
};

export const sideBarData = {
  support: [
    { name: 'Conversations', path: 'conversations', id: 'conversations' },
    { name: 'Responsiveness', path: 'responsiveness', id: 'responsiveness' },
    {
      name: 'Team performance',
      path: 'team-performance',
      id: 'team-performance',
    },
    {
      name: 'Customer satisfaction',
      path: 'customer-satisfaction',
      id: 'customer-satisfaction',
    },
    {
      name: 'Article feedback',
      path: 'article-feedback',
      id: 'article-feedback',
    },
    {
      name: 'Emma',
      path: 'emma',
      id: 'support-bot',
    },
  ],
  marketing: [
    { name: 'Email health', path: 'email-health', id: 'email-health' },
  ],
};

export const stableReportsDate = '2018-01-01';

export const momentDateFormat = 'YYYY-MM-DD';

export const regularDateFormat = 'MMM DD, YYYY';

function getDays() {
  return [...Array(24).keys()];
}

function getBusiestPeriodValues() {
  return Array.from({ length: 24 }, () => Math.floor(Math.random() * 101));
}

function getBusiestPeriodGroupData() {
  let data = [];

  [...Array(7).keys()].forEach(day => {
    data.push({
      name: day,
      type: 'time',
      groups: {
        aggregations: [
          {
            name: '0',
            values: getBusiestPeriodValues(),
          },
        ],
        values: getDays(),
      },
    });
  });

  return data;
}

export const busiestPeriodNewConversationsData = {
  groups: getBusiestPeriodGroupData(),
};

export function convertSecondsToTime(seconds) {
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + 'y';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + 'm';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + 'd';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + 'h';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + 'm';
  }
  return Math.floor(seconds) + 's';
}

function getMedianResponseTimeValues() {
  return Array.from({ length: 24 }, () => Math.floor(Math.random() * 101));
}

function getMedianResponseTimeGroupData() {
  let data = [];

  [...Array(7).keys()].forEach(day => {
    data.push({
      name: day,
      type: 'time',
      groups: {
        aggregations: [
          {
            name: '0',
            values: getMedianResponseTimeValues(),
          },
        ],
        values: getDays(),
      },
    });
  });

  return data;
}

export const medianResponseTimeData = {
  groups: getMedianResponseTimeGroupData(),
};
